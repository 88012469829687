import merge from 'lodash/merge';

import { getFetchHeaders } from "../js/getToken";
import { Provider } from '../models/providerClass';


export async function getReport(entity, report) {
    try {
        const headers = await getFetchHeaders();
    
        let urlBase = '/api/';
        
        if (entity instanceof Provider)
            urlBase += 'npi/';
        else
            urlBase += 'community/';
            
        urlBase += entity.valueOf();
        
        var httpOptions = merge({
            method: 'GET',
            // url: urlBase
        }, headers);
        
        switch (report) {
            case 'geoflow':
                // httpOptions.url += '/geoflow/';
                urlBase += '/geoflow/';
                break;
            case 'flow':
                // httpOptions.url += '/flow/';
                urlBase += '/flow/';
                httpOptions.method = 'POST';
                break;
            case 'specialtyflow':
                urlBase += '/specialty_flow/';
                httpOptions.url += '/specialty_flow/';
                break;
        }
        
        const req = await fetch(urlBase, httpOptions);
        const reqData = await req.json();
        return reqData;

    } catch(err) {
        console.log('Report Retrieval Error:', report, err, );
        // notify.error({
        //     title: 'ERROR',
        //     text: 'Error fetching Provider report data.',
        //     delay: 30000
        // });
        return Promise.reject(err);
    }
}