import angular from 'angular';

import template from './yearoveryeardashboard.html';
import controller from './yearoveryeardashboard.controller';

import api from '../../services/api';

const componentName = 'physicianYearOverYearDashboard';
const moduleName = 'app.reports.dashboards.yearoveryeardashboard.physician';

angular.module(moduleName, [
    api
])
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName
};