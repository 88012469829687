import React, { useState, useEffect } from 'react'
import { useRouteLoaderData } from "react-router-dom";
import { PhtableComponent } from '../popoverMenu/popoverMenu'; 
import { PopoverDetails }  from '../popoverMenu/popoverMenu'; 
import { PopoverMenu }  from '../popoverMenu/popoverMenu'; 
import { useConfig, useUser, useAuth, ServicesProvider, useLogging } from '../../services/servicesContext';
import { result } from 'lodash';
import user from '../../services/user';
import * as data from "./faq.json";
import Accordion from './Accordion';
import './faq.scss';

export function FaqComponent(props) {

    const logging = useLogging();

    useEffect(() => {
        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.faq'
        });
        
    }, []);

    return (
        <>
         { data ?
         <div>
            <h1>Frequently Asked Questions</h1>

            <div clasNames="faq-container">
                {data.faqs.map((e, i) => {
                    return (
                        <div>
                            <div className='accordion'>
                                <h4>{e.category}</h4>
                                {e.content.map((main, i) => {
                                    return(
                                    <Accordion title={main.title} content={main.body.join("\n")} />
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
                <p>Didn't see what you were looking for? <a href="https://perceptionhealth.atlassian.net/servicedesk/customer/portals" target="_blank">Contact Us</a></p>
            </div>
         </div>
         : "" }
         </>
       )
}