import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$http', '$localStorage', 'tour', 'notify', 'api', function($scope, $http, $localstorage, tour, notify, api) {
    var $ctrl = this;
    
    $ctrl.$onInit = function() {
        $scope.provider = $ctrl.npis[0];
        $scope.miles = $ctrl.miles;
        
        getAndFormatData($scope.provider.npi);

        var tourService = $ctrl.tour || tour;
        tourService.setScript(tourConfig);

        $ctrl.$onChanges = function(changes) {
            if (changes.miles) {
                $scope.miles = changes.miles.currentValue;
                getAndFormatData($scope.provider.npi);
            }
        };
    };
    
    function getAndFormatData(npi) { 
        $http.get('/api/npi/' + npi + '/summary-leakage?miles=' + $scope.miles, api.options())
        .then(function(res) {
            $scope.loaded = true;
            res.data.links.push({
                'source': npi,
                'target': npi,
                'flow': 0
            });
            
            $scope.data = res.data;
        }, function(err){
            console.log('Error fetching Summary Leakage Flow Graph data: ', err);
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Summary Leakage Flow Graph data.',
                delay: 30000
            });
        });
    }
}];