function script($timeout) {
    return {
        config: {},
        steps: [{
            content: "This is the Community Market Makers Report"
        },{
            content: "This report functions very similarly to the Organization Market Makers Report. However there are a few caveats. The community must consist only of organizations (it cannot be a physician-only or hybrid community). The graph will compare the market share of the providers to all other types of organizations in your community. For example if there are hospitals and clinics included, the graph will calculate that provider's volumes to all other hospitals and clinics against their volumes to the hospitals and clinics included in the community."
        },{
            target: 'organization-marketmap',
            content: "The purpose of this report is to quickly visualize providers' patient volumes and their loyalty to the facilities in the selected community."
        },{
            target: 'horizontal-color-legend',
            content: "The boxes are sized by patient volumes, and colored by market share via they legend below."
        },{
            target: 'ul.custom-tab-set',
            content: "You can navigate between groups of providers based on loyalty..."
        },{
            target: 'li#splittersTab',
            content: "Splitters (25%-75% market share),"
        },{
            target: 'li#loyalistsTab',
            content: "Loyalists (75%+ market share),"
        },{
            target: 'li#allPhysiciansTab',
            content: "and all physicians."
        },{
            target: 'treemap#splittersChart svg g g g.children rect.parent',
            content: "You can drill into provider types by clicking the appropriate box, a hover over will give you details on that group's volumes/market share.",
            before: function() {
                return new Promise((resolve, reject) => {
                    
                    var tabToClick = document.querySelector('li#splittersTab');
                    var clickEvent = new Event('click');
                    tabToClick.dispatchEvent(clickEvent);
                    
                    $timeout(()=>{
                        resolve();
                    });
                    
                }).then(() => {
                    var elem = document.querySelector('treemap#splittersChart svg g g g.children rect.parent');
                    var moveEvent = new Event('mousemove');
                    
                    var rectCalc = elem.getBoundingClientRect();
                        
                    moveEvent.pageX = (rectCalc.width/2) + rectCalc.left;
                    moveEvent.pageY = (rectCalc.height/2) + rectCalc.top;
                    
                    elem.dispatchEvent(moveEvent);
                    
                });
            },
            after: function() {
                return new Promise((resolve, reject) => {
                    var elem = document.querySelector('treemap#splittersChart svg g g g.children rect.parent');
                    var moveEvent = new Event('mouseleave');
                    elem.dispatchEvent(moveEvent);
                    
                    $timeout(()=>{
                        resolve();
                    });
                });
            }
        },{
            target: 'treemap#splittersChart svg g g.grandparent rect',
            content: "You can use the taxonomy tree to drill down into specialized provider types, you can also go backwards by clicking up a level on the grey navigation bar on the top.",
            before: function() {
                return new Promise((resolve, reject) => {
                    var elem = document.querySelector('treemap#splittersChart svg g g g.children');
                    var clickEvent = new Event('click');
                    
                    elem.dispatchEvent(clickEvent);
                    
                    $timeout(()=>{
                        resolve();
                    });
                });
            },
            after: function() {
                return new Promise((resolve, reject) => {
                    var elem = document.querySelector('treemap#splittersChart svg g g.grandparent');
                    var clickEvent = new Event('click');
                    
                    elem.dispatchEvent(clickEvent);
                    
                    $timeout(()=>{
                        resolve();
                    });
                });
            }
        },{
            target: 'organization-marketmap div#marketmap ul.custom-tab-set div.pull-right span[uib-dropdown]',
            content: "The graph defaults to using Shared Visits as the default measure of patient volumes..."
        },{
            target: 'organization-marketmap div#marketmap ul.custom-tab-set div.pull-right span[uib-dropdown] ul[uib-dropdown-menu]',
            content: "...but you can change to Unique Patients or Same Day Visits by using this drop down.",
            before: function() {
                return new Promise((resolve, reject) => {
                    var elementToClick = document.querySelector('organization-marketmap div#marketmap ul.custom-tab-set div.pull-right span[uib-dropdown] button[uib-dropdown-toggle]');
                    var clickEvent = new Event('click');
                    $timeout(()=>{
                        elementToClick.dispatchEvent(clickEvent);
                        $timeout(()=>{
                            resolve();
                        });
                    });
                });
            },
            after: function() {
                return new Promise((resolve, reject) => {
                    var elementToClick = document.querySelector('organization-marketmap div#marketmap ul.custom-tab-set div.pull-right span[uib-dropdown] button[uib-dropdown-toggle]');
                    var clickEvent = new Event('click');
                    $timeout(()=>{
                        elementToClick.dispatchEvent(clickEvent);
                        $timeout(()=>{
                            resolve();
                        });
                    });
                });
            }
        },{
            target: 'organization-marketmap div#marketmap ul.custom-tab-set div.pull-right a#report',
            content: "You can also export a report showing the providers' information as well as volumes and market share to excel by clicking the \"Generate Report\" button"
        }]
    };
}


export default script;