import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";

import { useApi, usePic, useTemplate } from '../../services/servicesContext';

import './synopsis.scss';

export default function SynopsisComponent({ notify }) {

    const [loading, setLoading] = useState(true);
    const [Provider, setProvider] = useState([]);
    const [topPhysicians, setTopPhysicians] = useState([]);
    const [dominantHospital, setDominantHospitals] = useState([]);
    const [serviceCount, setServiceCount] = useState([]);
    const [compBlurb, setCompBlurb] = useState([]);
    const [busyBlurb, setBusyBlurb] = useState([]);
    const [physician, setPhysician] = useState({});
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const api = useApi();

    useEffect(() => {
        onInit();
    }, [npis])

   async function onInit() {
        setLoading(true);
        const toTitleCase = (str) => {
            return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        }

        const provider = npis;
        setProvider(provider);
        const name = toTitleCase(provider.name.display);
        const city = toTitleCase(provider.location.city);
        const state = provider.location.state;
        setPhysician({ name: name, city: city, state: state }) 
        
        await fetch('/api/npi/' + provider.npi + '/brief/', api.options())
            .then(res => res.json())
            .then(function(res) {

                var types = res.top3PhysicianTypes.slice().map((t) => t.specialization || t.classification);
                
                if (types.length > 2) {
                    var last = types.pop();
                    setTopPhysicians(types.join(', ') + ' and ' + last);
                } else {
                    setTopPhysicians(types.join(' and '));
                }
                
                if (provider instanceof api.Physician) {
                        
                    const dominantHospital = res.npiInfo.dominant_organization ? toTitleCase(res.npiInfo.dominant_organization) : '';
                    const serviceCount = res.npiInfo.sum_srvc_count.toLocaleString("en-US");
                    setDominantHospitals(dominantHospital);
                    setServiceCount(serviceCount);

                    let compBlurb = ''

                    if (res.marketActivity.similar_count < 25) {
                        compBlurb = 'in the market of';
                    } else if (res.marketActivity.similar_count >= 25 && res.marketActivity.similar_count < 150) {
                        compBlurb = 'in the moderately competitive market of';
                    } else if (res.marketActivity.similar_count >= 150) {
                        compBlurb = 'in the highly competitive market of';
                    }
                    setCompBlurb(compBlurb);

                    let busyBlurb = ''
                    if (res.marketActivity.similar_count > res.npiInfo.avg_srvc_count) {
                        busyBlurb = 'While the practice is busier than average, more commercial patients can be attracted by';
                    } else {
                        busyBlurb = 'While the facility is busy, more commercial patients can be attracted by';
                    }
                    setBusyBlurb(busyBlurb);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log('Error fetching Provider Synopsis data: ', err);
                setLoading(true);
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Provider Synopsis data.',
                    delay: 30000
                });
            });
    };
    

  return (
        <div className="container">
            { loading
                ? <div className="loading-sm" style={{ display : 'inline', height: '1px', marginTop: '-24px' }}></div>
                : <>
                    { (Provider && Provider.entitytype == '2' && topPhysicians) && <p> {physician.name} is located in the market of {physician.city}, {physician.state}. The facility is primarily driven by {topPhysicians}. While the facility is busy, more commercial patients can be attracted by forging new relationships with these physician types and reinforcing existing relationships to improve volumes. </p> }
                    { (Provider && Provider.entitytype == '1' && topPhysicians) && <p> {physician.name} is located {compBlurb} {physician.city}, {physician.state}. They are active with {serviceCount} procedures in the office and facilities where they practice. {physician.name}'s primary organization is {dominantHospital}. {physician.name}'s practice is primarily driven by {topPhysicians}. {busyBlurb} forging new relationships with these physician types and reinforcing existing relationships to improve volumes.</p>}
                </>
            } 
      </div>
  )
}
