import * as parseD from 'd-path-parser';

function script($timeout) {
    return {
        config: {},
        steps: [
            {
                content: "The purpose of the Organization Care Journey graph is to understand where patients are treated before seeing a specific provider, then who they see after seeing the provider."
            },{
                target: 'g.nodes g.node rect:not([data-side])',
                content: "Starting with the organization, {{provider.name.display}} displayed as the middle node."
            },{
                target: 'g.nodes g.node rect[data-side="left"]',
                content: "Sorted by Provider type and ordered by patient volume, the nodes on the left show what type of Provider saw patients before {{provider.name.display}}. The thicker the gray line, the more shared visits the Provider has with that type of provider."
            },{
                target: 'g.nodes g.node rect[data-side="right"]',
                content: "Sorted by Provider type and ordered by patient volume, the nodes on the right show what type of Provider saw patients after {{provider.name.display}}."
            },{
                target: 'div.sankeyTooltip',
                content: "Hovering over the gray lines reveals the quantity of shared visits.",
                before: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var pathD= parseD(elem.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        $timeout(()=>{
                            resolve();
                        });
                        
                    });
                },
                after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var moveEvent = new Event('mouseleave');
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                }
            },{
                target: 'g.nodes g.node rect[data-side]',
                content: "Clicking on a \"node\" (color bar to the left or right)..."
            },{
                target: 'div.modal-dialog',
                content: "... will expand to reveal which providers make up that grouping. Also clicking the icon in the bottom left will quickly add the providers displayed to a Community.",
                before: function() {
                    return new Promise((resolve, reject) => {
                        
                        var nodeToClick = document.querySelector('g.nodes g.node rect[data-side]').parentElement;
                        var clickEvent = new Event('click');
                        nodeToClick.dispatchEvent(clickEvent);
                        
                        resolve();
                    });
                },
                after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var nodeToClick = document.querySelector('div.modal-dialog button.close');
                        var clickEvent = new Event('click');
                        if (nodeToClick) {
                            nodeToClick.dispatchEvent(clickEvent);
                        }
                        resolve();
                    });
                }
            }
        ]
    };
}

export default script;