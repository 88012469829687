var script = {
    config: {},
    steps: [{
        content: "This report shows total visits, unique patients, and same day visits from both a pre- and post-acute viewpoint and filtered by organizations. Organizations include ambulance services, labs, SNF’s, other facilities, and more. This report is meant to help you understand where your patients originate from and end up, allowing you to forge relationships with those organizations.<br><br>Use left/right arrow keys to navigate."
    },{
        target: "#tourscript-header",
        content: "For example, {{tourName}} saw..."
    },{
        target: ".tourscript-uniquefrom",
        content: "this many pre-acute patients..."
    },{
        target: ".tourscript-sharedfrom",
        content: "which equaled this many pre-acute total vists."
    },{
        target: "#tourscript-header",
        content: "{{tourName}} also saw...",
    },{
        target: ".tourscript-uniqueto",
        content: "this many post-acute care patients..."
    },{
        target: ".tourscript-sharedto",
        content: "which equaled this many post-acute total vists..."
    },{
        target: ".tourscript-facility",
        content: "from this facility."
    },{
        target: "organization ph-table thead",
        content: "You can sort on any column by simply clicking on the header name."
    },{
        target: "#tourscript-filter",
        content: "Also, you can filter results here by searching for any value in the row."
    },{
        target: "#tourscript-buttons",
        content: "Use this button to download the data in the table."
    }]
};

export default script;