import * as parseD from 'd-path-parser';

function script($timeout) {
    return {
        config: {},
        steps: [
            {
                content: "The purpose of the Physician General Flow graphic is to help you visualize a Physician's affinity for the Hospitals with whom they share patients."
            },
            {
                target: 'g.nodes g.node rect:not([data-side])',
                content: "The selected Physician, {{provider.name.display}} is seen here on the left side of the graph."
            },{
                target: 'g.nodes g.node rect[data-side="right"]',
                content: "The hospitals that see patients after {{provider.name.display}} are shown as the right-side \"nodes.\""
            },{
                target: 'div.sankeyTooltip',
                content: "Patient volumes are represented by the gray lines and can be hovered over to provide more detail.",
                before: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var pathD= parseD(elem.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        $timeout(()=>{
                            resolve();
                        });
                        
                    });
                },
                after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var moveEvent = new Event('mouseleave');
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                }
            }
        ]
    };
}

export default script;