import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { useTourManager, useLogging } from '../../services/servicesContext';

export function SummaryComponent({ api, opts, pic, download, notify }) {
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const tourManager = useTourManager();
    const logging = useLogging();

    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    
    useEffect(() => {     

        tourManager.createTour([
            {
                id: 1,
                title: 'Summary Report - 1/2',
                text: "This is the Summary Report. It shows the type of patients by the number of visits and the provider's specialty.",
            },{
                id: 2,
                title: 'Summary Report - 2/2',
                text: "This Report provides insight into which specialities are driving volumes to your facilities.",
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.summary`
        });

        function init(){
            const npi = npis.npi;
            getAndFormatData(npi)
        }     
        init();
        return () => {
            tourManager.clearTour();
        }
    }, [npis]);

async function getAndFormatData(npi) {   
    setLoading(true);    
      await fetch('/api/npi/' + npi + '/summary_report/', api.options())
        .then(res => res.json())
        .then(function(res) {
            
            const response = res.map(function(datum) {
                return {
                    'totals': datum.Totals,
                    'fagm': datum['Family and General Medicine'] || 0,
                    'im': datum['Internal Medicine (non specialty)'] || 0,
                    'tpc': datum['Total Primary Care'] || 0,
                    'emergency': datum.Emergency || 0,
                    'cardiology': datum.Cardiology || 0,
                    'orthopedics': datum.Orthopedics || 0,
                    'urology': datum.Urology || 0,
                    'pulmonary': datum.Pulmonary || 0,
                    'neurology': datum.Neurology || 0,
                    'ts': datum['Total Specialist'] || 0,
                    'tap': datum['Total All Physicians'] || 0
                };
            });
            setData(response);
            setLoading(false);
        }).catch(function(err){
            console.log('Error fetching Summary report data: ', err);
            setLoading(false);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Summary report data.',
                delay: 30000
            });
    })
}

  const reportConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col1",
                    "accessor": "totals",
                    "content": "Totals",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                },

                {
                    "header": {
                        "id": "col2",
                        "accessor": "fagm",
                        "content": "Family and General Medicine",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "im",
                        "content": "Internal Medicine (non specialty)",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "tpc",
                        "content": "Total Primary Care",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "emergency",
                        "content": "Emergency",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "cardiology",
                        "content": "Cardiology",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "orthopedics",
                        "content": "Orthopedics",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "urology",
                        "content": "Urology",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "pulmonary",
                        "content": "Pulmonary",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 9                 
                    }
                },
                {
                    "header": {
                        "id": "col10",
                        "accessor": "neurology",
                        "content": "Neurology",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 10                    
                    }
                },
                {
                    "header": {
                        "id": "col11",
                        "accessor": "ts",
                        "content": "Total Specialist",
                       "defaultVisibilty": true,
                        "export": true,
                       "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 11
                   }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "tap",
                        "content": "Total All Physicians",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                }
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: false,
        csvdownload: true,
        pagination: false,
        reportTitle: `PCP Specialist Volumes Report for ${npis && npis.name && npis.name.display} (${npis.npi})`,
        localStorageName: 'app.phy.reports.summary'
        }
    }

  

  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download} />  : "No Data found for report"}
    </>
  )
}
