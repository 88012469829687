import React, { useEffect, useState, useRef } from 'react';
import ReactDom from 'react-dom';
import { useRouteLoaderData } from "react-router-dom";

import * as crossfilter from 'crossfilter2';
import * as d3 from 'd3v3';
import * as dc from 'dc';

import { useApi, usePic, useTemplate , useConfig, useAuth} from '../../services/servicesContext';
import { toast } from 'react-toastify';

import { useTourManager, useLogging } from '../../services/servicesContext';

import '../../js/dc.patches.js';
import 'react-toastify/dist/ReactToastify.css';

export function PhysicianTrendingComponent({
    api, notify, config, auth, user, template, codes
}) {
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const tourManager = useTourManager();
    const logging = useLogging(); 
    const toastId = React.useRef(null);
    const [dateRange, setDateRange] = useState(null);
    const [filterInstance, setFilterIntstance] = useState(null);
    const [activeCodesTab, setActiveCodesTab] = useState('codes');
    const [activePieTab, setActivePieTab] = useState('default');
    const [activeNumberDisplayTab, setActiveNumberDisplayTab] = useState('charges');

    const codesRowChartRef = useRef(null);
    const deptRowChartRef = useRef(null);
    const srvcRowChartRef = useRef(null);
    const anatRowChartRef = useRef(null);
    const payertypePieChartRef = useRef(null);
    const locationPieChartRef = useRef(null);
    const billingPieChartRef = useRef(null);
    const chargesNumberDisplayRef = useRef(null);
    const avgNumberDisplayRef = useRef(null);
    const proceduresNumberDisplayRef = useRef(null);
    const patientsNumberDisplayRef = useRef(null);
    const codesByChargeAmountDisplayRef = useRef(null);
    const timelineDisplayRef = useRef(null);

    const allChartsRef = useRef(null);

    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'Clinical Dashboard - 1/7',
                text: "This is the Physician Trending Report. The purpose of this interactive visualization is to allow you to take a deeper dive into a physician's clinical practice. It's important to note that this visualization only contains commercial data for services rendered in the last year (following the current TTM on the system)."
            },
            {
                id: 2,
                title: 'Clinical Dashboard - 2/7',
                attachTo: { element: '.tourscript-codes', on: 'right' },
                text: "To start, the selected physician's top CPT/HCPCS codes will be displayed, these can be filtered by Department, Service Line, or Anatomy by clicking on the respective tab.  It's important to note that once you select something, the rest of the page will change to only show data for that specific selection."
            },
            {
                id: 3,
                title: 'Clinical Dashboard - 3/7',
                attachTo: { element: '.tourscript-payertype', on: 'bottom' },
                text: "Here you can filter by the payer type, location of services, and the billing provider for the service, again the rest of the page will change to only show data filtered by your selection."
            },
            {
                id: 4,
                title: 'Clinical Dashboard - 4/7',
                attachTo: { element: '.tourscript-totals', on: 'top' },
                text: "Here you can see the total amount charged, the average amount charged per procedure, the number of procedures (at the cpt/hcpcs level), and the distinct patients those were rendered on. This is especially valuable when looking at one specific procedure code when trying to get information on pricing"
            },
            {
                id: 5,
                title: 'Clinical Dashboard - 5/7',
                attachTo: { element: '.tourscript-stackedbar', on: 'left' },
                text: "Here the selected codes will be shown by their total charged amount and colored by the location provider for each"
            },
            {
                id: 6,
                title: 'Clinical Dashboard - 6/7',
                attachTo: { element: '.tourscript-timeline', on: 'top' },
                text: "Here you can filter to a specific date of service by dragging and dropping a box over the timeline. You can also slide the box around on the timelime"
            },
            {
                id: 7,
                title: 'Clinical Dashboard - 7/7',
                attachTo: { element: '.tourscript-reset', on: 'left' },
                text: "You can reset any filter by clicking the arrow in the top right corner of each graph."
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.phy.graphs.trending`
        });

        async function init() {
            try {
                toastId.current = toast("Requesting", { type: toast.TYPE.INFO, autoClose: 1 });
                const models = config.models();
                const user = await auth.currentUser();
                const dateRange = models.filter(function(model){
                    return model.name === user.dataModel().name;
                })[0].date_range;
                const rangeFormat = d3.time.format('%d %b %Y');
                let minDate;
                let maxDate;
                if (dateRange) {
                    minDate = rangeFormat.parse(dateRange[0].slice(5,16));
                    maxDate = rangeFormat.parse(dateRange[1].slice(5,16));
                }
                var timeFormat = d3.time.format("%Y-%m-%d %H:%M:%S");  // 2016-03-22 00:00:00
                // Note: for graph simplicity, times are assumed local TZ

                const location = await fetch('/api/npi/' + npis.npi + '/provider_trending/',{
                    headers: api.options().headers
                });
                const locationJson = await location.json();
                const csvRet = await fetch(locationJson.location);
                // 1: get the reader
                const reader = csvRet.body.getReader();
                // 2: get total length
                const contentLength = +csvRet.headers.get('Content-Length');
                // Step 3: read the data
                let receivedLength = 0; // received that many bytes at the moment
                let chunks = []; // array of received binary chunks (comprises the body)
                while (true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        break;
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    toast.update(toastId.current, {render: `Received ${Math.ceil((receivedLength/contentLength) * 100)}%`, type: toast.TYPE.INFO, autoClose: 3000 });
                }
                // Step 4: concatenate chunks into single Uint8Array
                let chunksAll = new Uint8Array(receivedLength); // (4.1)
                let position = 0;
                for(let chunk of chunks) {
                    chunksAll.set(chunk, position); // (4.2)
                    position += chunk.length;
                }

                // Step 5: decode into a string
                let result = new TextDecoder("utf-8").decode(chunksAll);

                // We're done!
                toast.update(toastId.current, {render: () => <div>Processing <i className="fa fa-spinner fa-spin fa-fw"></i></div>, type: toast.TYPE.INFO, autoClose: 3000 });

                result = result.replaceAll('"','');//remove all extra double quotes in new handling

                var records = result.split('\n').map(function (line) {
                    // var parts = line.split(',').map(function (part) { return part.slice(1, -1); });//prior handling of splitting columns
                    var parts= line.split(',');                    
                    parts[(parts.length - 1)] = parts[(parts.length - 1)].replace(/(\r\n|\n|\r)/gm,"");//handle windows and any other line break returns that show up in last column in new process
                    
                    var record;
                    try {
                        var date = timeFormat.parse(parts[2]);
                        record = {
                            pid:            parts[0],
                            payertype:      parts[1] || 'Unspecified',
                            servicedate:    date,
                            serviceweek:    d3.time.week(date),
                            servicemonth:   d3.time.month(date),
                            serviceyear:    d3.time.year(date),
                            rendering:      parts[3],
                            code:           parts[4],
                            charge:        +parts[5],
                            billing:        parts[6],
                            location:       parts[7] || parts[6],
                            diagnosis:      parts[8]
                        };
                    } catch(e) { }

                    if (!record || !record.rendering || !(record.servicedate instanceof Date)) 
                        return;
                    if (minDate && maxDate && (record.servicedate < minDate || record.servicedate > maxDate))
                        return;
                    if (!record.billing) {
                        return;
                    }

                    return record;

                });

                var promises = [];
                var promisesLookup = {};
                var codesToGet = [];

                var allProviderNPIsSet = new Set();
                records.forEach(rec => {
                    if (!rec) {
                        return;
                    }
                    if (rec.rendering) {
                        allProviderNPIsSet.add(rec.rendering);
                    }
                    if (rec.billing) {
                        allProviderNPIsSet.add(rec.billing);
                    }
                    if (rec.location) {
                        allProviderNPIsSet.add(rec.location);
                    }
                });
                await Promise.all([...allProviderNPIsSet].map(npi => api.GetProvider(npi)));

                for (var i=0; i< records.length; i++) {
                    (function(record) {
                        if (!record) return;

                        if (!record.rendering) return;
                        if (!record.location && !record.billing) return;
                        var renderingPromise = api.GetProvider(record.rendering);

                        renderingPromise.then(function(npi) {
                            record.rendering = Object.assign({
                                name: { primary: 'Undefined' },
                                location: { city: 'Undefined', state: 'NA' }
                            }, npi);
                            return record.rendering;
                        });
                        
                        // if (promises.indexOf(renderingPromise) == -1)
                        //     promises.push(renderingPromise);
                        if (!promisesLookup[record.rendering]) {
                            promisesLookup[record.rendering] = renderingPromise;
                        }

                        var billingPromise = api.GetProvider(record.billing);

                        billingPromise.then(function(npi) {
                            record.billing = Object.assign({
                                name: { primary: 'Undefined', full: `Name not found ${record.billing}` },
                                location: { city: 'Undefined', state: 'NA' }
                            }, npi);
                            return record.billing;
                        });
                        
                        // if (promises.indexOf(billingPromise) == -1) {
                        //     promises.push(billingPromise);
                        // }
                        if (!promisesLookup[record.billing]) {
                            promisesLookup[record.billing] = billingPromise;
                        }
                        
                        var locationPromise = api.GetProvider(record.location);

                        locationPromise.then(function(npi) {
                            record.location = Object.assign({
                                name: { primary: 'Undefined', full: `Name not found ${record.location}` },
                                location: { city: 'Undefined', state: 'NA' }
                            }, npi);
                            return record.location;
                        });
                                                            
                        // if (promises.indexOf(locationPromise) == -1) {
                        //     promises.push(locationPromise);
                        // }
                        if (!promisesLookup[record.location]) {
                            promisesLookup[record.location] = locationPromise;
                        }

                        if (codesToGet.indexOf(record.code) === -1) {
                            codesToGet.push(record.code)
                        }

                        if (codesToGet.indexOf(record.diagnosis) === -1) {
                            codesToGet.push(record.diagnosis)
                        }

                    })(records[i]);
                }
                toast.update(toastId.current, {render: () => <div>Done.</div>, type: toast.TYPE.INFO, autoClose: 3000 });

                if (codesToGet.length > 0) {
                    if (codesToGet.length > 1750) {
                        var codesResp = await codes.searchCodesGetBatched(codesToGet);
                    }
                    else {
                        var codesResp = await codes.searchCodesGet(codesToGet);
                    }
                    var codesLookup = codesResp.reduce((acc, val) => {
                        if (!acc[val.code]) {
                            acc[val.code] = val;
                        }
                        return acc;
                    },{});
                    records.forEach(record => {
                        if (!record) return;
                        if (codesLookup[record.code]) {
                            record.code = {
                                code: record.code,
                                codeset: codesLookup[record.code].codeset,
                                description: codesLookup[record.code].description || '',
                                department: codesLookup[record.code].department ? [codesLookup[record.code].department] : ['Undefined'],
                                serviceline: codesLookup[record.code].serviceline ? [codesLookup[record.code].serviceline] : ['Undefined'],
                                anatomy: codesLookup[record.code].anatomy ? [codesLookup[record.code].anatomy] : ['Undefined']
                            };
                        } else {
                            record.code = {
                                code: record.code,
                                department: ['Undefined'],
                                serviceline: ['Undefined'],
                                anatomy: ['Undefined'],
                                description: ''
                            };
                        }
                        if (codesLookup[record.diagnosis]) {
                            record.diagnosis = {
                                code: record.diagnosis,
                                codeset: codesLookup[record.diagnosis].codeset,
                                description: codesLookup[record.diagnosis].description || '',
                                department: codesLookup[record.diagnosis].department ? [codesLookup[record.diagnosis].department] : ['Undefined'],
                                serviceline: codesLookup[record.diagnosis].serviceline ? [codesLookup[record.diagnosis].serviceline] : ['Undefined'],
                                anatomy: codesLookup[record.diagnosis].anatomy ? [codesLookup[record.diagnosis].anatomy] : ['Undefined']
                            };
                        } else {
                            record.diagnosis = {
                                code: record.diagnosis,
                                department: ['Undefined'],
                                serviceline: ['Undefined'],
                                anatomy: ['Undefined'],
                                description: ''
                            };
                        }
                    });
                }

                if (promisesLookup[""]) {
                    delete promisesLookup[""]
                }

                // await Promise.all(promises);
                await Promise.all(Object.values(promisesLookup));

                let recordsFinal = records.reduce(function (l, r) { if (r) l.push(r); return l; }, []);
                if (recordsFinal && recordsFinal.length) {
                        const localCrossfilterInstance = crossfilter();
                        localCrossfilterInstance.add(recordsFinal);
                        setFilterIntstance(localCrossfilterInstance);
                } else {
                    template.nonReportingNpi(providers[0]);
                    // notification.update({
                    //     type: 'notice',
                    //     text: 'No data available',
                    //     hide: true
                    // });
                }


            } catch(err) {
                console.error('error in phsiciantTrendingComponent', err);
            }

        }

        init();

        return () => {
            tourManager.clearTour();
        };

    }, [npis]);

    useEffect(() => {
        if (filterInstance) {

            const ndx = filterInstance;
            const all = ndx.groupAll();
            
            const codesRowChart = new dc.rowChart(codesRowChartRef.current);
            const codesDimension = ndx.dimension(row => row.code.code + (row.code.description ? ' - ' + row.code.description : ''));
            const codesGroup = codesDimension.group();
            codesRowChart
                .width(codesRowChartRef.current.parentElement.offsetWidth)
                .height(codesRowChartRef.current.parentElement.offsetHeight)
                .dimension(codesDimension)
                .group(codesGroup)
                .ordering(function (d) { return -d.value; })
                .cap(Math.floor(codesRowChartRef.current.parentElement.offsetHeight/25))
                .othersGrouper(null)
                .colors('#6baed6')
                .elasticX(true)
                .labelOffsetY(10)
                .margins({top: 5, right: 5, bottom: 35, left: 5})
                .xAxis().ticks(4).tickFormat(d3.format(',f')).scale(d3.scale.log())
            ;

            const deptRowChart = new dc.rowChart(deptRowChartRef.current);
            const deptDimension = ndx.dimension(row => row.code.department);
            const deptGroup = deptDimension.group();
            deptRowChart
                .width(deptRowChartRef.current.parentElement.offsetWidth)
                .height(deptRowChartRef.current.parentElement.offsetHeight)
                .dimension(deptDimension)
                .group(deptGroup)
                .ordering(function (d) { return -d.value; })
                .cap(Math.floor(deptRowChartRef.current.parentElement.offsetHeight/25))
                .othersGrouper(null)
                .colors('#6baed6')
                .elasticX(true)
                .labelOffsetY(10)
                .margins({top: 5, right: 5, bottom: 35, left: 5})
                .xAxis().ticks(4).tickFormat(d3.format(',f')).scale(d3.scale.log())
            ;

            const srvcRowChart = new dc.rowChart(srvcRowChartRef.current);
            const srvcDimension = ndx.dimension(row => row.code.serviceline);
            const srvcGroup = srvcDimension.group();
            srvcRowChart
                .width(srvcRowChartRef.current.parentElement.offsetWidth)
                .height(srvcRowChartRef.current.parentElement.offsetHeight)
                .dimension(srvcDimension)
                .group(srvcGroup)
                .ordering(function (d) { return -d.value; })
                .cap(Math.floor(srvcRowChartRef.current.parentElement.offsetHeight/25))
                .othersGrouper(null)
                .colors('#6baed6')
                .elasticX(true)
                .labelOffsetY(10)
                .margins({top: 5, right: 5, bottom: 35, left: 5})
                .xAxis().ticks(4).tickFormat(d3.format(',f')).scale(d3.scale.log())
            ;

            const anatRowChart = new dc.rowChart(anatRowChartRef.current);
            const anatDimension = ndx.dimension(row => row.code.anatomy);
            const anatGroup = anatDimension.group();
            anatRowChart
                .width(anatRowChartRef.current.parentElement.offsetWidth)
                .height(anatRowChartRef.current.parentElement.offsetHeight)
                .dimension(anatDimension)
                .group(anatGroup)
                .ordering(function (d) { return -d.value; })
                .cap(Math.floor(anatRowChartRef.current.parentElement.offsetHeight/25))
                .othersGrouper(null)
                .colors('#6baed6')
                .elasticX(true)
                .labelOffsetY(10)
                .margins({top: 5, right: 5, bottom: 35, left: 5})
                .xAxis().ticks(4).tickFormat(d3.format(',f')).scale(d3.scale.log())
            ;
            
            const payertypePieChart = new dc.pieChart(payertypePieChartRef.current);
            const payertypeDimension = ndx.dimension(row => row.payertype);
            const payertypeGroup = payertypeDimension.group();
            payertypePieChart
                .height(payertypePieChartRef.current.parentElement.offsetHeight)
                .cy(payertypePieChartRef.current.parentElement.offsetHeight / 16 * 10)
                .radius(payertypePieChartRef.current.parentElement.offsetHeight / 2.5)
                .innerRadius(payertypePieChartRef.current.parentElement.offsetHeight / 10)
                .slicesCap(8)
                .externalRadiusPadding(15)
                .drawPaths(false)
                .legend(dc.legend())
                .dimension(payertypeDimension)
                .group(payertypeGroup)
            ;

            const locationPieChart = new dc.pieChart(locationPieChartRef.current);
            const locationDimension = ndx.dimension(row => row.location.name.full);
            const locationGroup = locationDimension.group();
            locationPieChart
                .height(locationPieChartRef.current.parentElement.offsetHeight)
                .cy(locationPieChartRef.current.parentElement.offsetHeight / 16 * 10)
                .radius(locationPieChartRef.current.parentElement.offsetHeight / 2.5)
                .innerRadius(locationPieChartRef.current.parentElement.offsetHeight / 10)
                .slicesCap(8)
                .externalRadiusPadding(15)
                .drawPaths(false)
                .legend(dc.legend())
                .dimension(locationDimension)
                .group(locationGroup)
            ;

            const billingPieChart = new dc.pieChart(billingPieChartRef.current);
            const billingDimension = ndx.dimension(row => row.billing.name.full);
            const billingGroup = billingDimension.group();
            billingPieChart
                .height(billingPieChartRef.current.parentElement.offsetHeight)
                .cy(billingPieChartRef.current.parentElement.offsetHeight / 16 * 10)
                .radius(billingPieChartRef.current.parentElement.offsetHeight / 2.5)
                .innerRadius(billingPieChartRef.current.parentElement.offsetHeight / 10)
                .slicesCap(8)
                .externalRadiusPadding(15)
                .drawPaths(false)
                .legend(dc.legend())
                .dimension(billingDimension)
                .group(billingGroup)
            ;

            const chargesNumberDisplay = new dc.numberDisplay(chargesNumberDisplayRef.current);
            const chargesGroup = ndx.groupAll().reduce(
                function (p, v) {
                    p.tot += v.charge;
                    return p;
                }, function (p, v) {
                    p.tot -= v.charge;
                    return p;
                }, function () { return { tot: 0 }; }
            );
            chargesNumberDisplay
                .formatNumber(d3.format('$,f'))
                .valueAccessor(function (d) { return d.tot; })
                .group(chargesGroup)
            ;

            const avgNumberDisplay = new dc.numberDisplay(avgNumberDisplayRef.current);
            const avgGroup = ndx.groupAll().reduce(
                function (p, v) {
                    p.tot += v.charge;
                    p.cnt += 1;
                    return p;
                }, function (p, v) {
                    p.tot -= v.charge;
                    p.cnt -= 1;
                    return p;
                }, function () { return { tot: 0, cnt: 0 }; }
            );
            avgNumberDisplay
                .formatNumber(d3.format('$,f'))
                .valueAccessor(function (d) { return d.tot / d.cnt })
                .group(avgGroup)
            ;

            const proceduresNumberDisplay = new dc.numberDisplay(proceduresNumberDisplayRef.current);
            const proceduresGroup = ndx.groupAll();
            proceduresNumberDisplay
                .formatNumber(d3.format(',f'))
                .valueAccessor(function (d) { return d; })
                .group(proceduresGroup)
            ;

            const patientsNumberDisplay = new dc.numberDisplay(patientsNumberDisplayRef.current);
            const patientGroup = ndx.groupAll().reduce(
                function (p, v) {
                    var key = v.pid;
                    p[key] = (p[key] || 0) + 1;
                    return p;
                },
                function (p, v) {
                    var key = v.pid;
                    p[key] = (p[key] || 0) - 1;

                    return p;
                },
                function () { return {}; }
            );
            patientsNumberDisplay
                .formatNumber(d3.format(',f'))
                .valueAccessor(function (d) {
                    return Object.keys(d).reduce(function (l, key) {
                        if (d[key] > 0) l.push(d);
                        return l;
                    }, []).length;
                })
                .group(patientGroup)
            ;

            const codesByChargeAmountStackedBarChart = new dc.barChart(codesByChargeAmountDisplayRef.current);
            const codesByChargeAmountDimension = ndx.dimension(row => row.code.code);

            const codesByChargeAmountGroup = codesByChargeAmountDimension.group().reduce((p, v) => {
                p[v.location.name.full] = (p[v.location.name.full] || 0) + v.charge;
                return p;
            }, (p, v) => {
                p[v.location.name.full] = (p[v.location.name.full] || 0) - v.charge;
                return p;
            }, () => ({}));
            
            var stacks = codesByChargeAmountDimension.top(Infinity).map(function(d) {
                return d.location.name.full + '';
            })
            .filter(function(value, index, self) { 
                return self.indexOf(value) === index; 
            }).sort(function(a,b) {
                return b.length - a.length || a > b;
            });

            codesByChargeAmountGroup.reduce(function add(r, d) {
                var index = d.location.name.full;
                r[index] += d.charge; 

                return r;
            }, function sub(r, d) {
                var index = d.location.name.full;
                r[index] -= d.charge;                     
                
                return r;
            }, function init() {
                return stacks.reduce(function(o, s) { 
                    o[s] = 0;
                    return o;
                }, {});
            });

            codesByChargeAmountGroup.order(function(p) {
                return Object.keys(p).reduce(function(s, k) {
                    return p[k] + s;
                }, 0);
            });
            
            function stacker(stack) {
                return function(g) {
                    return g.value[stack];
                };
            }

            // have to monkey-patch group.all() in this instance of a crossfilter group due to the lack of .cap() method on the stacked bar chart
            const codesByChargeAmountGroupPatched = Object.create(codesByChargeAmountGroup);
            codesByChargeAmountGroupPatched.all = function() {
                return codesByChargeAmountGroup.top(Math.floor(codesByChargeAmountDisplayRef.current.parentElement.offsetWidth/40));
            };

            codesByChargeAmountStackedBarChart
                .width(codesByChargeAmountDisplayRef.current.parentElement.offsetWidth)
                .height(codesByChargeAmountDisplayRef.current.parentElement.offsetHeight)
                .dimension(codesByChargeAmountDimension)
                .ordering(function (d) { return -d.value; })
                .colors(d3.scale.category10())
                .x(d3.scale.ordinal())
                .y(d3.scale.linear())
                .xUnits(dc.units.ordinal)
                .elasticX(true)
                .elasticY(true)
                .legend(dc.legend().x(85))
                .xAxisLabel('Procedure Code')
                .yAxisLabel('Sum Charges')
                .margins({top: 10, right: 10, bottom: 45, left: 60})
                .yAxis().tickFormat(d3.format('$,f'))
            ;

            var titleFormat = d3.format('$,f');

            var defaultKey, stackParts = {};
            stacks.forEach(function (stack) {
                if (stackParts[stack]) return;
                if (!defaultKey) {
                    defaultKey = stack;
                    codesByChargeAmountStackedBarChart.group(codesByChargeAmountGroupPatched, stack, stacker(stack));
                    
                } else {
                    codesByChargeAmountStackedBarChart.stack(
                        codesByChargeAmountGroupPatched,
                        stack,
                        stackParts[stack] = stacker(stack)
                    );
                }

                codesByChargeAmountStackedBarChart.title(stack, function(d) {
                    return d.key + ': ' + titleFormat(d.value[stack]);
                });
            });

            const timelineChart = new dc.compositeChart(timelineDisplayRef.current);
            const itemsByServiceWeekDimension = ndx.dimension(row => row.serviceweek);
            const serviceWeekChargesGroup = itemsByServiceWeekDimension.group().reduce(
                function add(p,v) {
                    if (v){
                        p['Charges'] += v.charge;
                    }
                    return p; 
                },
                function sub(p, v) {
                    if (v) {
                        p['Charges'] -= v.charge;
                    }
                    return p;
                },
                function init(o) {
                    return {Charges: 0};
                }
            );
            const serviceWeekClaimsGroup = itemsByServiceWeekDimension.group().reduce(
                function add(p,v) {
                    if (v) {
                        p['Claims'] += 1;
                    }
                    return p;
                },
                function sub(p,v) {
                    if (v) {
                        p['Claims'] -= 1;
                    }
                    return p;
                },
                function init(o) {
                    return {Claims: 0};
                }
            );
            const serviceWeekPatientsGroup = itemsByServiceWeekDimension.group().reduce(
                function add(p, v) {
                    if (v) {
                        p[v.pid] = (p[v.pid] || 0) + 1;
                    }
                    return p;
                },
                function sub(p, v) {
                    if (v) {
                        p[v.pid] -= (p[v.pid] || 0) - 1;
                    }
                    return p;
                },
                function(o) {
                    return {};
                }
            );

            timelineChart
                .width(timelineDisplayRef.current.parentElement.offsetWidth)
                .height(timelineDisplayRef.current.parentElement.offsetHeight)
                .transitionDuration(500)
                .xUnits(d3.time.weeks)
                .x(d3.time.scale())
                .elasticX(true)
                .elasticY(true)
                .yAxisLabel('Claims & Patients')
                .rightYAxisLabel('Charges')
                .legend(dc.legend().x(timelineDisplayRef.current.parentElement.offsetWidth - 145))
                .renderHorizontalGridLines(true)
                .shareColors(true)
                .dimension(itemsByServiceWeekDimension)
                .margins({top: 10, right: 85, bottom: 20, left: 45})
                .compose([
                    new dc.lineChart(timelineChart)
                        .renderArea(true)
                        .useRightYAxis(true)
                        .group(serviceWeekChargesGroup, 'Charges')
                        .valueAccessor(d => {
                            return d.value.Charges;
                        })
                        .height(timelineDisplayRef.current.parentElement.offsetHeight)
                        .width(timelineDisplayRef.current.parentElement.offsetHeight)
                    ,
                    new dc.lineChart(timelineChart)
                        .renderArea(true)
                        .useRightYAxis(false)
                        .group(serviceWeekClaimsGroup, 'Claims')
                        .valueAccessor(d => {
                            return d.value.Claims;
                        })
                        .height(timelineDisplayRef.current.parentElement.offsetHeight)
                        .width(timelineDisplayRef.current.parentElement.offsetHeight)
                    ,
                    new dc.lineChart(timelineChart)
                        .renderArea(true)
                        .useRightYAxis(false)
                        .group(serviceWeekPatientsGroup, 'Patients')
                        .valueAccessor(function(group) { 
                            var o = group.value; 
                            return Object.keys(o).reduce(function (l, key) {
                                if (o[key] > 0) {
                                    l.push(key);
                                }
                                return l;
                            }, []).length;
                        })
                        .height(timelineDisplayRef.current.parentElement.offsetHeight)
                        .width(timelineDisplayRef.current.parentElement.offsetHeight)
                ])
                .brushOn(true)
            ;
            
            timelineChart.xAxis().ticks(15);
            timelineChart.yAxis().ticks(6);


            // render all charts
            codesRowChart.render();
            deptRowChart.render();
            srvcRowChart.render();
            anatRowChart.render();
            payertypePieChart.render();
            locationPieChart.render();
            billingPieChart.render();
            chargesNumberDisplay.render();
            avgNumberDisplay.render();
            proceduresNumberDisplay.render();
            patientsNumberDisplay.render();

            codesByChargeAmountStackedBarChart.render();

            timelineChart.render();

            allChartsRef.current = {
                codesRowChart,
                deptRowChart,
                srvcRowChart,
                anatRowChart,
                payertypePieChart,
                locationPieChart,
                billingPieChart,
                chargesNumberDisplay,
                avgNumberDisplay,
                proceduresNumberDisplay,
                patientsNumberDisplay,
                codesByChargeAmountStackedBarChart,
                timelineChart
            };
        }
    } ,[filterInstance]);

    return (<div id="physician-trending" style={{height:'97%'}}>
        {/* <ToastContainer /> */}
        <div className="row-sm-12">
            <div className="row-sm-8 grouping col-xs-12 col-sm-6 col-md-3 tourscript-codes">
                {/* Codes */}
                <a className="btn-default btn btn-md pull-right reset" onClick={()=>{
                    allChartsRef.current.codesRowChart.filterAll();
                    allChartsRef.current.deptRowChart.filterAll();
                    allChartsRef.current.srvcRowChart.filterAll();
                    allChartsRef.current.anatRowChart.filterAll();
                    Object.keys(allChartsRef.current).forEach(key => {
                        allChartsRef.current[key].redraw();
                    });
                }}><span className="fa fa-reply"></span></a>
                <div className="panel panel-default" >
                    <div className="panel-heading">
                        <ul className="nav nav-pills">
                            <li className={`nav-item ${activeCodesTab === 'codes' ? 'active' : null}`} onClick={e => setActiveCodesTab('codes')}><a>Codes</a></li>
                            <li className={`nav-item ${activeCodesTab === 'dept' ? 'active' : null}`} onClick={e => setActiveCodesTab('dept')}><a>Dept</a></li>
                            <li className={`nav-item ${activeCodesTab === 'srvc' ? 'active' : null}`} onClick={e => setActiveCodesTab('srvc')}><a>Srvc</a></li>
                            <li className={`nav-item ${activeCodesTab === 'anat' ? 'active' : null}`} onClick={e => setActiveCodesTab('anat')}><a>Anat</a></li>
                        </ul>
                    </div>
                    <div className="panel-body chart-stage row-sm-12">
                        <div ref={codesRowChartRef} style={{ height:'100%', width:'100%', display: activeCodesTab === 'codes' ? 'block': 'none'}}></div>
                        <div ref={deptRowChartRef} style={{ height:'100%', width:'100%', display: activeCodesTab === 'dept' ? 'block': 'none'}}></div>
                        <div ref={srvcRowChartRef} style={{ height:'100%', width:'100%', display: activeCodesTab === 'srvc' ? 'block': 'none'}}></div>
                        <div ref={anatRowChartRef} style={{ height:'100%', width:'100%', display: activeCodesTab === 'anat' ? 'block': 'none'}}></div>
                    </div>
                </div>
            </div>
            <div className="row-sm-8 grouping col-xs-12 col-sm-6 col-md-3">
                <div className="row-sm-8 tourscript-payertype" style={{paddingBottom: '10px', paddingTop: '0px'}}>
                    {/* Pie */}
                    <a className="btn-default btn btn-md pull-right reset" onClick={()=>{
                        allChartsRef.current.payertypePieChart.filterAll();
                        allChartsRef.current.locationPieChart.filterAll();
                        allChartsRef.current.billingPieChart.filterAll();
                        Object.keys(allChartsRef.current).forEach(key => {
                            allChartsRef.current[key].redraw();
                        });
                    }}><span className="fa fa-reply"></span></a>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <ul className="nav nav-pills">
                                <li className={`nav-item ${(activePieTab === 'payertype' || activePieTab === 'default') ? 'active' : null}`} onClick={e => setActivePieTab('payertype')}><a>Payer Type</a></li>
                                <li className={`nav-item ${activePieTab === 'location' ? 'active' : null}`} onClick={e => setActivePieTab('location')}><a>Location</a></li>
                                <li className={`nav-item ${activePieTab === 'billing' ? 'active' : null}`} onClick={e => setActivePieTab('billing')}><a>Billing</a></li>
                            </ul>
                        </div>
                        <div className="panel-body">
                            <div ref={payertypePieChartRef} style={{ height: '100%', width: '100%',
                                visibility: (activePieTab === 'payertype' || activePieTab === 'default') ? 'visible' : 'hidden',
                                display: (activePieTab === 'location' || activePieTab === 'billing') ? 'none' : 'block' }}>
                            </div>
                            <div ref={locationPieChartRef} style={{ height: '100%', width: '100%',
                                visibility: activePieTab === 'location' ? 'visible' : 'hidden',
                                display: (activePieTab === 'default' || activePieTab === 'location') ? 'block' : 'none' }}>
                            </div>
                            <div ref={billingPieChartRef} style={{ height: '100%', width: '100%',
                                visibility: activePieTab === 'billing' ? 'visible' : 'hidden',
                                display: (activePieTab === 'default' || activePieTab === 'billing') ? 'block' : 'none' }}>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-sm-4 tourscript-totals" style={{paddingTop: '0px', paddingBottom: '0px'}}>
                    {/* Totals */}
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <ul className="nav nav-pills">
                                <li className={`nav-item ${activeNumberDisplayTab === 'charges' ? 'active' : null}`} onClick={e => setActiveNumberDisplayTab('charges')}><a>Charges</a></li>
                                <li className={`nav-item ${activeNumberDisplayTab === 'avg' ? 'active' : null}`} onClick={e => setActiveNumberDisplayTab('avg')}><a>Avg</a></li>
                                <li className={`nav-item ${activeNumberDisplayTab === 'procedures' ? 'active' : null}`} onClick={e => setActiveNumberDisplayTab('procedures')}><a>Procedures</a></li>
                                <li className={`nav-item ${activeNumberDisplayTab === 'patients' ? 'active' : null}`} onClick={e => setActiveNumberDisplayTab('patients')}><a>Patients</a></li>
                            </ul>
                        </div>
                        <div className="panel-body">
                            <div className="numberDiv" ref={chargesNumberDisplayRef} style={{height: '100%', width: '100%', display: activeNumberDisplayTab === 'charges' ? 'block': 'none'}}></div>
                            <div className="numberDiv" ref={avgNumberDisplayRef} style={{height: '100%', width: '100%', display: activeNumberDisplayTab === 'avg' ? 'block': 'none'}}></div>
                            <div className="numberDiv" ref={proceduresNumberDisplayRef} style={{height: '100%', width: '100%', display: activeNumberDisplayTab === 'procedures' ? 'block': 'none'}}></div>
                            <div className="numberDiv" ref={patientsNumberDisplayRef} style={{height: '100%', width: '100%', display: activeNumberDisplayTab === 'patients' ? 'block': 'none'}}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-sm-8 grouping col-xs-12 col-md-6 tourscript-stackedbar">
                {/* Stacked Bar */}
                <a className="btn-default btn btn-md pull-right reset" onClick={()=>{
                    allChartsRef.current.codesByChargeAmountStackedBarChart.filterAll();
                    Object.keys(allChartsRef.current).forEach(key => {
                        allChartsRef.current[key].redraw();
                    });
                }}><span className="fa fa-reply"></span></a>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <ul className="nav nav-pills">
                            <li className="nav-item active"><a>Codes by Charge Amount</a></li>
                        </ul>
                    </div>
                    <div className="panel-body">
                        <div className="stacked-bars-div" ref={codesByChargeAmountDisplayRef} style={{ height:'100%', width:'100%', display: 'block'}}></div>
                    </div>
                </div>
            </div>
            <div className="row-sm-4 grouping col-xs-12 tourscript-timeline">
                {/* Timeline */}
                <a className="btn-default btn btn-md pull-right reset tourscript-reset" onClick={()=>{
                    allChartsRef.current.timelineChart.filterAll();
                    Object.keys(allChartsRef.current).forEach(key => {
                        allChartsRef.current[key].redraw();
                    });
                }}><span className="fa fa-reply"></span></a>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <ul className="nav nav-pills">
                            <li className="nav-item active"><a>Timeline</a></li>
                        </ul>
                    </div>
                    <div className="panel-body">
                        <div className="timeline-div" ref={timelineDisplayRef} style={{ height:'100%', width:'100%', display: 'block'}}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}