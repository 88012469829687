/* global L  */
import * as d3 from 'd3v6';

import '../../libs/leaflet-shapefile/leaflet.shpfile';// extend global L as side effect

export default function init(mapId, opts) {
  var mymap = L.map(mapId).setView([39.8283, -98.5795], 4);
  var activeDataLayer;
  var missingColor = '#d9d9d9';
  var lastHighlight;
  var infoDiv;
  var scale;
  var layerThresholdScale;
  var lookup = {};
  var legend;
  var currentLegend;
  var legends = {};
  var tileLayer;

  function powerRound(x, dir) {
    if (x == 0) 
      return x;
    let odr = Math.pow(10, dir( Math.log10(x)));
    return dir(x / odr) * odr;
  }

  function highlightFeature(e, nameAccessor, layerName, labelAccessor) {
    var layer = e.target;
    lastHighlight = e;

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    info.update(layer.feature, nameAccessor, layerName, labelAccessor);
  }
  
	tileLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		maxZoom: 10,
		attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
	}).addTo(mymap);

  /***
   * Info Panel
   ***/
  var info = L.control();
  info.onAdd = function (map) {
    this.infoDiv = L.DomUtil.create('div', 'info');
    this.update();
    return this.infoDiv;
  };
  
  info.update = function(feature, nameAccessor, layerName, labelAccessor) {
    
    var lookupName = (feature && nameAccessor) ? nameAccessor(feature) : '';
    
    if (!labelAccessor) {
      this.infoDiv.innerHTML = `
      <div class="info-div">
        <p>Select one or more codes<br>and a geography type<br>to add a map layer.</p>
      </div>
      `;
    } else {
      this.infoDiv.innerHTML = `
      <div class="info-div">
        <h4>${opts.infoHeaderText || 'Estimated Expected Volumes'}</h4>
        <p>${labelAccessor(feature)}: ${lookup[layerName][lookupName].toLocaleString()} distinct claims</p>
      </div>
      `;
    }
    
  };
  info.addTo(mymap);
  var lc = null;

  mymap.on('baselayerchange', function (eventLayer) {
    if (currentLegend) {
      currentLegend.remove();
    }
    currentLegend = legends[eventLayer.name];
    currentLegend.addTo(mymap);
  });
  
  return {
    clearLayers: function() {
      mymap.eachLayer(function (layer) {
        mymap.removeLayer(layer);
      });
      tileLayer.remove();
      tileLayer = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 10,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(mymap);
      lc.remove();
      info.update();
      if (currentLegend) {
        currentLegend.remove();
      }
      legends = {};
      lc = null;
      lookup= {};
    },
    addData: function(addedData, addedShapefile, layerName, {shapeFileNameAccessor, dataNameAccessor, labelAccessor}) {
      lookup[layerName] = {};
      var localLayerThresholdScale;
      function localGetColor(val) {
        if (val == 0) {
          return 'transparent';  
        } else if ( typeof val === 'undefined' || !(typeof val === 'number')) {
          return missingColor;
        }
        
        return localLayerThresholdScale(val);
      }
      
      function localComputeStyle(feature, shapeFileNameAccessor) {
        var ret = {
          weight: 0,
          opacity: 1,
          color: 'white',
          dashArray: '3',
          fillOpacity: 0.4,
          fillColor: 'blue'
        };
        
        ret.fillColor = localGetColor(lookup[layerName][shapeFileNameAccessor(feature)]);
        
        return ret;
      }
      
      var formats = (function () {
        var units = d3.format(".3~s");
        var percent = d3.format(",.1%");
        var norm = d3.format(",.2~f");
        
        function factory(fmt) {
          return function(val) {
            if (!(typeof val === 'number')) {
              if (val == '')
                return '&nbsp;';
              return val;
            }
            return fmt(val);
          };
        }
        
        return {
          units: factory(units),
          percent: factory(percent),
          normal: factory(norm)
        };
      })();
      
      addedData[addedData.type].forEach( val =>{
        lookup[layerName][dataNameAccessor(val)] = val.value;
      });
      
      if (!Array.isArray(addedShapefile)) {//single shapefile
        var newShpfile = new L.Shapefile(addedShapefile, {
          style: (e) => {
            return localComputeStyle(e, shapeFileNameAccessor);
          },
          onEachFeature: function(feature, layer) {
            
            if (feature.properties) {
              layer.bindPopup(`${labelAccessor ? labelAccessor(feature) : shapeFileNameAccessor(feature)}<br />Expected Volumes: ${lookup[layerName][shapeFileNameAccessor(feature)]} distinct claims`);
            }
            
            layer.on({
              mouseover: (e) => {
                highlightFeature(e, shapeFileNameAccessor, layerName, labelAccessor);
              },
              click: e => {
                newShpfile.resetStyle(e.target);
                highlightFeature(e, shapeFileNameAccessor, layerName, labelAccessor);
              },
              mouseout: e => {
                newShpfile.resetStyle(e.target);
              }
            });
          }
        });
      } else {//array of shapefiles
      
        var stateLayerZipsShapefiles = addedShapefile.map(shapefile => {
          var retShapefile = new L.Shapefile(shapefile, {
            style: (e) => {
              return localComputeStyle(e, shapeFileNameAccessor);
            },
            onEachFeature: function(feature, layer) {
              
              if (feature.properties) {
                layer.bindPopup(`${labelAccessor ? labelAccessor(feature) : shapeFileNameAccessor(feature)}<br />Expected Volumes: ${lookup[layerName][shapeFileNameAccessor(feature)]}`);
              }
              
              layer.on({
                mouseover: (e) => {
                  highlightFeature(e, shapeFileNameAccessor, layerName, labelAccessor);
                },
                click: e => {
                  retShapefile.resetStyle(e.target);
                  highlightFeature(e, shapeFileNameAccessor, layerName, labelAccessor);
                },
                mouseout: e => {
                  retShapefile.resetStyle(e.target);
                }
              });
            }
          });
          return retShapefile;
        });
        
        newShpfile = L.layerGroup(stateLayerZipsShapefiles);
        
      }
      
      
      function initLegend() {
        
        legends[layerName] = L.control({position: 'bottomleft'});
        legends[layerName].onAdd = function () {
          var div = L.DomUtil.create('div', 'info legend');
          
          var labels = [];
          
          let scale = localLayerThresholdScale;
          let n = scale.range().length;
          var min;
          scale.range().forEach( (color, i) => {
            let [ from, to ] = scale.invertExtent(color);
            
            if (!min)
              min = Math.min(1, Math.pow(10, Math.floor(Math.log10(from))));
            
            if (!from) {
              labels.push([`<i style="background:${localGetColor(undefined)}"></i>`]);
            } else if ( i == n - 1 ) {
              labels.push([`<i style="background:${localGetColor(from)}"></i>`, `${formats.normal(from)}+`]);
            } else {
              labels.push([`<i style="background:${localGetColor(from)}"></i>`, `${formats.normal(from)}`, `${formats.normal(to-min)}`]);
            }
          });
          let innerHTML = '<table class="legend-table" cellpadding="0">';
          innerHTML += labels.map( r => {
            if (r.length == 1)
              return `<tr><td>${r[0]}</td><td colspan="3">No Data</td></tr>`;
            if (r.length == 2)
              return `<tr><td>${r[0]}</td><td style="text-align: right;">${r[1]}</td><td colspan="2"></td></tr>`;
            return `<tr><td>${r[0]}</td><td style="text-align: right;">${r[1]}</td><td>&mdash;</td><td>${r[2]}</td></tr>`;
          }).join('\n');
          innerHTML += '</table>';
          
          div.innerHTML = innerHTML;
          
          return div;
        };
        
      }
      
      newShpfile.once("data:loaded", function() {
        
      });
      
      // Build scales
      if (["scaleLog", "scaleSymlog"].indexOf(opts.scale.type) > -1) {
        var values = addedData[addedData.type].map(row => {
          return row.value;
        });
        
        let n = opts.scale.range.length;
        
        let scale = d3[opts.scale.type]()
          .domain([
            d3.min(values.filter(v=>v>0)),
            d3.max(values)
          ])
          .range([1,n+1]);
        
        var steps = Array.from({ length: n+1 }, (x,i)=>i+1).map( i => powerRound(scale.invert(i), Math.floor) );

        let range = ['transparent'].concat(opts.scale.range);
        
        localLayerThresholdScale = d3.scaleThreshold().range( range ).domain(steps);
      } else {
        throw new Error('Scale type not yet supported');
      }
      
      initLegend();
      
      if (lc) {
        var addedBaseLayer = lc.addBaseLayer(newShpfile, layerName);
        
        return {
          name: layerName,
          layer: newShpfile,
          control: lc,
          map: mymap
        };
      } else {
        lc = L.control.layers({},null,{collapsed:false}).addTo(mymap);
        var addedBaseLayer = lc.addBaseLayer(newShpfile, layerName);
        
        return {
          name: layerName,
          layer: newShpfile,
          control: lc,
          map: mymap
        };
      }
      
    }
  };
  
}
