import angular from 'angular';
// import React from "react";
import { react2angular } from 'react2angular';

import {
    moduleName as sankeyModule,
    PhSankeyComponent
} from '../../components/sankey/sankeyComponent.js';

import api from '../../services/api';
import pic from '../../services/pic';
import template from '../../services/template';
import uirouter from '@uirouter/angularjs';

import ngtemplate from './generalflow.html';
import controller from './generalflow.controller';
import './generalflow.scss';

import { PhysicianGeneralflowComponent } from './generalflowComponent.js';

const componentName = 'physicianGeneralflow';
const updatedComponentName = 'physicianGeneralflowComponent';
const moduleName = 'app.reports.generalflow.physician';

angular.module(moduleName, [
    api, uirouter, pic, template, sankeyModule
])
.component(componentName, {
    require: {
        reportSankey: '^?sankey'
    },
    bindings: {
        npis: '<',
        opts: '<?'
    },
    template: ngtemplate,
    controller,
    controllerAs: '$ctrl',
})
.component(
    'physicianGeneralflowComponent',
    react2angular(PhysicianGeneralflowComponent, ['npis', 'opts'], ['api', '$state', 'pic', 'template'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};