//need to explicitly define url for marker icons

//further updated needed .default added to require to resolve img src=[module object] in markup
//see https://stackoverflow.com/questions/59070216/webpack-file-loader-outputs-object-module

// delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  // iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconRetinaUrl: require('leaflet-v171/dist/images/marker-icon-2x.png').default,
  // iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  iconUrl: require('leaflet-v171/dist/images/marker-icon.png').default,
  // shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
  shadowUrl: require('leaflet-v171/dist/images/marker-shadow.png').default,
  
});