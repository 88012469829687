import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import { moduleName as sankeyModule, componentName as sankeyComponent } from '../reports/sankey/sankey';
import { moduleName as carepathwaysModule, componentName as carepathwaysComponent } from '../reports/carepathways.provider/carepathways';
import { moduleName as specialtyFlowModule, componentName as specialtyFlowComponent } from '../reports/specialtyflow.provider/specialtyflow';
import { moduleName as marketMapModule, componentName as marketMapComponent } from '../reports/marketmap.organization/marketmap';
import { moduleName as quadrantAnalysisModule, /* componentName */updatedComponentName as quadrantAnalysisComponent } from '../reports/quadrantanalysis.organization/quadrantanalysis';
import { moduleName as geoflowModule, /* componentName */updatedComponentName as geoflowComponent } from '../reports/geoflow.provider/geoflow';
import { moduleName as flowModule, componentName as flowComponent } from '../reports/flow/flow';
// import { moduleName as trendingGraphModule, componentName as trendingGraphComponent } from '../reports/trendinggraph/trending';
import { moduleName as trendingGraphModule, componentName as trendingGraphComponent } from '../reports/trendinggraph/trending';

export default angular.module('app.pages.orgs.graphs', [
    uirouter, carepathwaysModule, specialtyFlowModule, marketMapModule, quadrantAnalysisModule, geoflowModule, flowModule, sankeyModule, trendingGraphModule
]).config(['$stateProvider',
function($stateProvider) {

    $stateProvider.state({
        name: 'root.app.org.graphs',
        abstract: true,
        template: '<ui-view class="app.org.graphs"/>',
        url: ''
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.sankey',
        abstract: true,
        component: sankeyComponent
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.sankey.carepathways',
        url: '/carejourney',
        altUrls: ['^/organization/butterfly/:npis','^/organization/:npis/carepathways'],
        component: carepathwaysComponent,
        data: {
            name: 'Care Journey'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.sankey.specialtyflow',
        url: '/SpecialtyGraph',
        altUrls: ['^/organization/specialty_flow/:npis', '^/organization/specialtyflow/:npis', '^/organization/:npis/specialtyflow'],
        component: specialtyFlowComponent,
        data: {
            name: 'Specialty'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.marketmap',
        url: '/MarketMakers',
        altUrls: ['^/organization/marketmap/:npis', '^/organization/:npis/marketmap'],
        component: marketMapComponent,
        data: {
            name: 'Market Makers'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.quadrantanalysis',
        url: '/ShareByVolume',
        altUrls: ['^/organization/quadrant-analysis/:npis', '^/organization/quadrantanalysis/:npis', '^/organization/:npis/quadrantanalysis'],
        component: quadrantAnalysisComponent,
        data: {
            name: 'Share/Volume'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.geoflow',
        url: '/ReferralMap',
        altUrls: ['^/organization/geoflow/:npis', '^/organization/:npis/geoflow'],
        component: geoflowComponent,
        data: {
            name: 'Referral Map'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.sankey.flow',
        url: '/flow',
        altUrls: ['^/organization/flow/:npis'],
        component: flowComponent,
        data: {
            name: 'Flow'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.graphs.trendinggraph',
        url: '/trendinggraph',
        component: trendingGraphComponent,
        data: {
            name: 'Trending'
        }
    });

}]).name;