import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";

import localStorage from '../../services/localStorageModule';
import { useLogging } from '../../services/servicesContext';

import { SummaryLeakageReportComponent } from '../summaryleakageReport.organization/summaryleakageReportComponent';
import { SummaryLeakageFlowComponent } from '../summaryleakageflow.organization/summaryleakageFlowComponent';
import { get } from 'lodash';

export function LeakageProvider({
   
    api,
    codes,
    notify,
    download,
    tour,
    pic,
    Taxonomy,
    CommunityList
}){

    const [ currentTab, setCurrentTab] = useState("1");
    const [ loading, setLoading] = useState(true);
    const [ miles, setMiles ] = useState('10');
    const [ data, setData ] = useState(null);
    const [ geoflowReportData, setGeoFlowReportData ] = useState(null);
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const logging = useLogging();
    
    useEffect(() => {
        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.summaryleakage`
        });
        if (currentTab === "2") {// on load of new provider, reset table to the report instead of the flow map
            setCurrentTab("1");
        }
        init();
    }, [npis]);

    async function init() {
        // setLoading(true);
        try {
            const defaultMiles = localStorage.get('leakageReport.miles') || '20'
            setMiles(defaultMiles);

            getData(defaultMiles);
            
            // setLoading(false);
        } catch (err) {
            console.warn('init error', err);
        } 
    }

    async function getData(milesVal) {
        setLoading(true);
        const initApiRequest = await fetch(
            `/api/npi/${npis.npi}/summary-leakage?miles=${milesVal}`,
            api.options()
        ).then(res => res.json());
        const formattedData = initApiRequest.links.map(function(datum) {
            return {
                'npi': datum.source,
                'sourceNpi': datum.source,
                'sourceEntityType': initApiRequest.nodes.features[datum.source].entitytype,
                'sourceCommunities': pic(datum.source),
                'sourceName': initApiRequest.nodes.features[datum.source].name,
                'sourceTaxonomy': initApiRequest.nodes.features[datum.source].classification,
                'sourceCity': initApiRequest.nodes.features[datum.source].city,
                'sourceState': initApiRequest.nodes.features[datum.source].state,
                'sourceZip': initApiRequest.nodes.features[datum.source].postalcode,
                'sourcePhone': initApiRequest.nodes.features[datum.source].phone_number,
                'sourceTaxonomyType': initApiRequest.nodes.features[datum.source].taxonomytype,
                'targetNpi': datum.target,
                'targetEntityType': initApiRequest.nodes.features[datum.target].entitytype,
                'targetCommunities': pic(datum.target),
                'targetName': initApiRequest.nodes.features[datum.target].name,
                'targetTaxonomy': initApiRequest.nodes.features[datum.target].classification,
                'targetCity': initApiRequest.nodes.features[datum.target].city,
                'targetState': initApiRequest.nodes.features[datum.target].state,
                'targetZip': initApiRequest.nodes.features[datum.target].postalcode,
                'targetPhone': initApiRequest.nodes.features[datum.target].phone_number,
                'targetTaxonomyType': initApiRequest.nodes.features[datum.target].taxonomytype,
                'visits': datum.flow
            };
        });
        setData(formattedData);
        setGeoFlowReportData(initApiRequest);
        
        setLoading(false);
    }

    function renderTabs() {
        if (currentTab === "1") {
            return <SummaryLeakageReportComponent
                data={data}
                npis={npis}
                api={api}
                codes={codes}
                notify={notify}
                download={download}
                tour={tour}
                CommunityList={CommunityList}
            />
        } else if (currentTab === "2") {
            return <SummaryLeakageFlowComponent
                data={geoflowReportData}
                npis={npis}
                api={api}
                codes={codes}
                notify={notify}
                download={download}
                tour={tour}
                miles={miles}
            />;
        } else {
            return null;
        }
    }

    function changeTabs(e) {
        setCurrentTab(e.target.id);
    }

    return <>   
        <ul className="nav nav-tabs">
            <li id="summary_leakage_report" className={currentTab === "1" ? "active" : ""}><a id="1" onClick={(e) => changeTabs(e)}>Summary Network Integrity Report</a></li>
            <li id="service_funnel" className={currentTab === "2" ? "active" : ""}><a id="2" onClick={(e) => changeTabs(e)}>Summary Network Integrity Flow</a></li>
            <li className="list-unstyled pull-right">
                <div className="form-group form-inline pull-right">
                    <label>Miles: </label>
                    <select
                        className="form-control"
                        name="leakageMiles"
                        id="leakageMiles"
                        value={miles}
                        onChange={e => {
                            localStorage.set('leakageReport.miles', e.target.value);
                            setMiles(e.target.value);
                            getData(e.target.value);
                        }}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                    </select>
                </div>
            </li>
        </ul>
        <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
        {renderTabs()}
    </>
}