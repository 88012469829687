import angular from 'angular';

import { react2angular } from 'react2angular';

import api from '../../services/api';
import pic from '../../services/pic';
import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';
import localStorage from '../../services/localStorage';
import notify from '../../services/notify';
import codes from '../../services/codes';
import download from '../../services/download';

import { PerformanceProvider } from './performance.provider';

const componentName = 'providerPerformanceProvider';
const updatedComponentName = 'performanceProvider';
const moduleName = 'app.reports.performance.provider';

angular.module(moduleName, [
    api, pic, tour, localStorage, phTable, notify
])
.component(
    updatedComponentName,
    react2angular(PerformanceProvider, ['npis', 'opts'], ['api', '$localStorage', 'pic', 'notify', 'codes', 'download', 'tour'])
);
export {
    moduleName,
    updatedComponentName,
};