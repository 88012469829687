import angular from 'angular';

// import React from "react";
import { react2angular } from 'react2angular';

import api from '../../services/api';
import pic from '../../services/pic';
import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';
import localStorage from '../../services/localStorage';
import notify from '../../services/notify';
import download from '../../services/download';


import { LeakageProvider } from './leakage.provider';

const componentName = 'leakageProvider';
const moduleName = 'app.reports.leakage.provider';

angular.module(moduleName, [
    api, pic, tour, localStorage, phTable, notify, download
])
.component(
    componentName,
    react2angular(LeakageProvider, ['npis'], ['api', '$localStorage', 'pic', 'notify', 'download', 'tour', 'Taxonomy'])
);
export {
    moduleName,
    componentName,
};