import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$http', 'pic', 'tour', 'notify', 'Taxonomy', 'api', function($scope, $http, pic, tour, notify, Taxonomy, api) {
    var $ctrl = this;
    $scope.pic = pic;
        
    $ctrl.$onInit = function() {
        
        var entity = $ctrl.community || $ctrl.npis[0];
        
        $scope.tourName = $ctrl.community ? $ctrl.community.name : $ctrl.npis[0].name.display;
        
        tour.setScript(tourConfig, $scope);
        
        var reportType = $ctrl.community ? 'community' : 'npi';
        getAndFormatData(entity, reportType);
    };
        
    function getAndFormatData(entity, reportType) {
            
            var entityName = $scope.entityName = entity.name.display || entity.name;
            var entityId = entity.npi || entity.id;
            
            $scope.reportOptions = { 
                export: { 
                    name: 'by Organization for ' + entityName 
                },
                communityList: {
                    accessor: function(datum) { return datum.npi; }
                }
            };
            
            $http.get('/api/'+ reportType + '/' + entityId + '/organization/', api.options())
            .then(function(res) {
                $scope.loaded = true;
                if (res.data.length === 0) {
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Organization report data found for given entity.',
                        delay: 30000
                    });
                } 
                $scope.data = res.data.map(function(datum) {
                    return {
                        'npi': datum.npi,
                        'communities': pic(datum.npi).map(function(community) { return community.abbr }),
                        'organizationName': datum.businessname,
                        'taxonomy': new Taxonomy(datum),
                        'sharedFrom': datum.fromsharedtransactioncount,
                        'uniqueFrom': datum.frompatienttotal,
                        'sameday': datum.samedaytotal,
                        'uniqueTo': datum.topatienttotal,
                        'sharedTo': datum.tosharedtransactioncount,
                        'city': datum.city,
                        'state': datum.state,
                        'phone': datum.phonenumber,
                        'zip': datum.postalcode
                    };
                });
            }, function(err){
                console.log('Error fetching Organization Report data: ', err);
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Organization Report data.',
                    delay: 30000
                });
            });
        }
}];