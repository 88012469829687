import React, { useEffect, useState } from "react";
import ReactDom from 'react-dom';
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails } from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu } from '../../components/popoverMenu/popoverMenu';
    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '90vh',
        minWidth: '960px',
        overflowY: 'auto',
        padding: '25px 20px'
    } 
    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
    }

const Modal = ({ open, onClose, config, modaldata, loading, CommunityList, download }) => {

    if (!open) return null
    
    function fixModalData(rows) {
        return rows.filter(row => !row._failed).map(row => {
            row.displayName = row?.name?.display || '';
            return row;
        });
    }

    const reportConfigHospitals = function() { 
            try {
            return {  
                tableConfig :  [
                        {
                        "header": {
                            "id": "col1",
                            "accessor": "npi",
                            "content": "NPI",
                            "defaultVisibilty": true,
                            "export": true,
                            "template": function(content, rowObject) { 
                                if (!rowObject || !rowObject.location) return
                                const npi_popover = {
                                    provider : {
                                        name : { 
                                            display : rowObject.name && rowObject.name.display || ""
                                        },
                                    location : {
                                        city: rowObject.location.city || "",
                                        state: rowObject.location.state || "",
                                        postal_code: rowObject.location.postal_code || "",
                                        phone_number: rowObject.location.phone_number || ""
                                }
                            }
                            }
                                return <PopoverDetails {...npi_popover}><Link to={`/${rowObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails> 
                            }
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 1
                            }
                        },
                        {
                            "header": {
                                "id": "col2",
                                "accessor": "displayName",
                                "content": "Name",
                                "defaultVisibilty": true,
                                "export": true,
                                "template": function(content, rowObject) {
                                    const provider = { name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                                    return <PopoverMenu provider={provider}>
                                        <span className='tourscript-name'>{content}</span>
                                        <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </span>
                                    </PopoverMenu> 
                                }
                            },
                            "column": {
                                    "class": "'text-center'",
                                    "content": {},
                                    "style": "",
                                    "id": 2
                                }
                        },
                        {
                            "header": {
                                "id": "col3",
                                "accessor": "taxonomy",
                                "content": "Specialty",
                                "defaultVisibilty": true,
                                "export": true,
                                "template": function(content) {
                                    return content && content.toString();
                                }
                            },
                            "column": {
                                    "class": "'text-center'",
                                    "content": {},
                                    "style": "",
                                    "id": 3
                                }
                        },
                        {
                            "header": {
                                "id": "col4",
                                "accessor": "source",
                                "content": "Source",
                                "defaultVisibilty": true,
                                "export": true,
                                "template": function(content) { return content }
                            },
                            "column": {
                                    "class": "'text-center'",
                                    "content": {},
                                    "style": "",
                                    "id": 4
                                }
                        }
                    ],
                configure : true,
                showSelected: false,
                filterInput: true,
                csvdownload: true,
                reportTitle: `${(config.opts.affiliationtype === 'hospital') ? "Hospital Affiliations" : "Group Affiliations"} for ${config.opts.name}`
            }
            } catch (error) {
                console.error(error) 
            }
        }

    const createCommunity = function () {
        // CommunityList.openCommunityModal();
    };

    const updateCommunityList = function(affiliationsToUse) {
        // CommunityList.update([...new Set(affiliationsToUse.map(afl => afl.npi).filter(afl => afl))]);
    };

    updateCommunityList(modaldata.filter(d => !d._failed));

    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header ng-scope">
                <h3 className="modal-title" id="modal-title">{(config.opts.affiliationtype === 'hospital') ? "Hospital Affiliations" : "Group Affiliations"} for {config.opts.name} </h3>
            </div>
            <div className="modal-body container-fluid">
                <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
                { modaldata.length > 0
                    ? <PhtableComponent download={download} data={fixModalData(modaldata)} reportconfig={reportConfigHospitals}></PhtableComponent>
                    : null
                }
            </div>
            <div className="modal-footer">
                <div className="modal-create-community pointer" onClick={() => createCommunity()}>
                    <a><i className="fa fa-users fa-2x" aria-hidden="true"></i></a>
                </div>
                <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={onClose}>Close</button>
            </div>
        </div></>, document.body);
}
export default Modal;
