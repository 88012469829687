import * as parseD from 'd-path-parser';

function script($timeout) {
    return {
        config: {},
        steps: [
            {
                content: "The purpose of the Physician Flow graph is to help quantify the patient flow FROM the Physician to other providers and BACK to the original Physician."
            },{
                target: 'g.nodes g.node rect:not([data-side])',
                content: "The selected Physician is represented by the color-coded \"node\" in the  middle of the graph. In this case, we are looking at the flow of patients to and from {{provider.name.display}}."
            },{
                target: 'g.nodes g.node rect[data-side="right"]',
                content: "Here, you can see that other Providers who have relationships with {{provider.name.display}} are listed on the left and right side."
                
            },{
                target: 'div.sankeyTooltip',
                content: "Hovering over a left or right side \"node\" reveals the volume of referrals sent and received. There is no distinction between providers on the left and providers on the right.",
                before: function() {
                    return new Promise((resolve, reject) => {
                        var elementToHover = document.querySelector('g.nodes g.node rect[data-side="right"]').parentElement;
                        var bcr = elementToHover.getBoundingClientRect();
                        
                        var avgX = (bcr.x + bcr.width/2);
                        var avgY = (bcr.y + bcr.height/2);
                        
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = parseInt(avgX.toFixed());
                        moveEvent.pageY = parseInt(avgY.toFixed());
                        elementToHover.dispatchEvent(moveEvent);
                        
                        $timeout(()=>{
                            resolve();
                        });
                    });
                },
                after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('g.nodes g.node rect[data-side="right"]').parentElement;
                        var moveEvent = new Event('mouseleave');
                        elem.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                }
            },
            {
                target: 'flow ul.dropdown-menu',
                content: "You can filter the nodes by specialty using the taxonomy filter.",
                before: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('flow div.form-inline button');
                        var clickEvent = new Event('click');
                        $timeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            $timeout(()=>{
                                resolve();
                            });
                        });
                    });
                },
                after: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('flow div.form-inline button');
                        var clickEvent = new Event('click');
                        $timeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            $timeout(()=>{
                                resolve();
                            });
                        });
                    });
                }
            },
            {
                target: 'div.sankey-graph svg g.legend rect',
                content: "The legend provides a key for each specialty's designated color. Click and drag to move this key to get a better look at the graph."
            },
            {
                target: 'div.sankeyTooltip',
                content: "The dashed lines indicate the directionality of the relationship. Also, patient volumes can be seen by hovering over the line.",
                before: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elemToHover = document.querySelector('g.link-container.reversed');
                        var elemPath = document.querySelector('g.link-container.reversed path.link');
                        var pathD= parseD(elemPath.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elemToHover.dispatchEvent(moveEvent);
                        
                        $timeout(()=>{
                            resolve();
                        });
                        
                    });
                },
                after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elemToHover = document.querySelector('g.link-container.reversed');
                        var moveEvent = new Event('mouseleave');
                        elemToHover.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                }
            }
        ]
    };
}

export default script;