import React, { useState, useEffect } from 'react'
import { useRouteLoaderData } from "react-router-dom";
import { PhtableComponent } from '../popoverMenu/popoverMenu'; 
import { PopoverDetails }  from '../popoverMenu/popoverMenu'; 
import { PopoverMenu }  from '../popoverMenu/popoverMenu'; 
import { useConfig, useUser, useAuth, ServicesProvider, useLogging } from '../../services/servicesContext';
import { result } from 'lodash';
import user from '../../services/user';
import dompurify from 'dompurify';

export function UserProfileComponent(props) {

    const [ profileUser, setProfileUser] = useState(null);
    const [ accounts, setAccounts] = useState(null);
    const [ loading, setLoading] = useState(false);
    const [ uid, setUid] = useState(null);
    const account = props.config.account();
    const logging = useLogging();

    useEffect(() => {
        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.userProfile'
        });
        setLoading(true);
        async function init() {
            await props.auth.currentUser().then(function(currentuser){

                setUid(currentuser.username);

                props.user.getUserById(currentuser.username)
                .then(user => {
                    setProfileUser(user);
                    setAccounts(user.accounts.map(account => `${account.name} (${account.role})`).join(', '));
                });
                setLoading(false);
            });
        }
        init();
    }, []);

    return (
        <>
         { !loading ?
         <div>
            <div className="row">
                <h2>Profile Details <a href={dompurify.sanitize(account.ui)} target="_blank"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></a></h2>
                <hr/>
                <dl className="dl-horizontal">
                    { (profileUser ? <dt>User Id</dt> : "")}
                    { (profileUser ? <dd>{uid}</dd> : "")}

                    { (profileUser ? <dt>Name</dt> : "")}
                    { (profileUser ? <dd>{profileUser.name}</dd> : "")}

                    { (profileUser ? <dt>Email</dt> : "")}
                    { (profileUser ? <dd>{profileUser.email}</dd> : "")}

                    { (accounts ? ( accounts.length > 1 ? <dt>Accounts</dt> : <dt>Account</dt>) : "")}
                    { (accounts ? <dd>{accounts}</dd> : "")}
                </dl>
            </div>
            <div className="row">
                <h2>Need Help?</h2>
                <hr/>
                <dl className="dl-horizontal">
                    <dt>Customer Experience</dt>
                    <dd>
                        <a href="mailto:ce@perceptionhealth.com">TEAM Customer Experience</a>
                    </dd>
                </dl>
            </div>
        </div>
         : "" }
         <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
         </>
       )
}