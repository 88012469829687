import angular from 'angular';

import { react2angular } from 'react2angular';
import api from '../../services/api';
import notify from '../../services/notify';
import { ClinicalComponent } from './clinicalComponent'

import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';
import codes from '../../services/codes';
import download from '../../services/download';

const componentName = 'physicianClinical';
const moduleName = 'app.reports.clinical.physician';
const updatedComponentName = 'clinicalComponent';

angular.module(moduleName, [
    phTable, tour, codes
])
.component(
    updatedComponentName,
    react2angular(ClinicalComponent, ['npis', 'opts', 'community'], ['api', 'notify', 'codes', 'download'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};