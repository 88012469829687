import angular from 'angular';

import template from './specialty.html';
import controller from './specialty.controller';
import { react2angular } from 'react2angular';
import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';

import { SpecialtyComponent } from './specialtyComponent';
import api from '../../services/api';
import notify from '../../services/notify';
import download from '../../services/download';

const componentName = 'specialty';
const updatedComponentName = 'specialtyComponent';
const moduleName = 'app.reports.specialty';

angular.module(moduleName, [
    phTable, tour, notify, api
])
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(SpecialtyComponent, ['npis', 'opts', 'community'], ['api', 'notify', 'download'])
);
export {
    moduleName,
    componentName,
    updatedComponentName
};