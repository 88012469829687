import React, {useRef, useEffect, useState} from 'react';

import { d3Bubble } from '../../js/d3bubbleModule.js';
import { datumlist } from '../../js/datumlistModule.js';

export function ScatterPlotComponent(props) {

    const graphRef = useRef();
    const graphInstanceRef = useRef();
    const listRef = useRef();
    const listInstanceRef = useRef();

    useEffect(() => {
        if (props.data && props.graphopts && props.listopts) {
            graphInstanceRef.current = d3Bubble(graphRef.current);
            graphInstanceRef.current('options', props.graphopts);
            graphInstanceRef.current('data', props.data);
            listInstanceRef.current = datumlist(listRef.current);
            listInstanceRef.current('options', props.listopts);
            listInstanceRef.current('data', props.data);
        }
    },[props.data, props.graphopts, props.listopts]);

    return (<div id="organizationquadrantanalysis" style={{display:'inline'}}>
        <div id="quad" className="row">
            <div className="col-md-10" id="d3-bubble" ref={graphRef} style={{height:'100%'}}>
            </div>
            <div className="col-md-2" id="legend-list" ref={listRef}>
            </div>
        </div>
    </div>);
}