import angular from 'angular';

import { react2angular } from 'react2angular';

import './trending.scss';
import template from './trending.html';
import controller from './trending.controller';

import api from '../../services/api';

import lineGraphDirective from '../../directives/lineGraph/lineGraphDirective';
import { TrendingGraphComponent } from './trendingGraphReportComponent.js';

const componentName = 'trendingGraph';
const updatedComponentName = 'trendingGraphComponent';
const moduleName = 'app.reports.trendingGraph';

angular.module( moduleName, [api] )
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<',
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(
        TrendingGraphComponent,
        ['npis', 'community'],
        ['api','template']
    )
)
.directive('lineGraphDirective', lineGraphDirective);

export {
    moduleName,
    componentName,
    updatedComponentName
};