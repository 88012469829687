import angular from 'angular';

import { react2angular } from 'react2angular';

import './geoflow.scss';
import template from './geoflow.html';
import controller from './geoflow.controller';

import uirouter from '@uirouter/angularjs';

import geoFlow from '../../directives/geoFlow/geoFlow';

import pic from '../../services/pic';
import templateService from '../../services/template';

import { GeoflowComponent } from './geoflowComponent.js';

const componentName = 'providerGeoflow';
const updatedComponentName = 'providerGeoflowComponent';
const moduleName = 'app.reports.geoflow.provider';

angular.module(moduleName, [
    geoFlow, pic, uirouter, templateService
])
.component(componentName, {
    bindings: {
        npis: '<',
        data: '<?'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(updatedComponentName, react2angular(GeoflowComponent, ['opts','npis'], ['pic','$state']));

export {
    moduleName,
    componentName,
    updatedComponentName
};