import angular from 'angular';

import { react2angular } from 'react2angular';

import {
    moduleName as sankeyModule,
    PhSankeyComponent
} from '../../components/sankey/sankeyComponent.js';


import api from '../../services/api';
import pic from '../../services/pic';
import template from '../../services/template';
import uirouter from '@uirouter/angularjs';

import ngtemplate from './generalflow.html';
import controller from './generalflow.controller';
import './generalflow.scss';

import { CommunityGeneralflowComponent } from './generalflowComponent';

const componentName = 'communityGeneralflow';
const updatedComponentName = 'communityGeneralflowComponent';
const moduleName = 'app.reports.generalflow.community';

angular.module(moduleName, [
    api, uirouter, pic, template, sankeyModule
])
.component(componentName, {
    require: {
        reportSankey: '^?sankey'
    },
    bindings: {
        npis: '<',
        opts: '<?',
        community: '<',
    },
    template: ngtemplate,
    controller,
    controllerAs: '$ctrl',
})
.component(
    updatedComponentName,
    react2angular(
        CommunityGeneralflowComponent,
        ['community', 'opts'],
        ['api', '$state', 'pic', 'template']
    )
);

export {
    moduleName,
    componentName,
    updatedComponentName
};