import angular from 'angular';
import { react2angular } from 'react2angular';
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect
} from "react-router-dom";
import uirouter from '@uirouter/angularjs';

import api from '../services/api';
import recentlySelected from '../services/recentlySelected';
import affiliations from '../services/affiliations';
import template from '../services/template';
import codes from '../services/codes';
import config from '../services/config';
import auth from '../services/auth';
import models from '../models';
import CommunityList from '../services/communityList';

import home from './provider.home';
import brief from './physician.brief';
import graphs from './physician.graphs';
import reports from './physician.reports';
import benchmarks from './physician.dashboards';

import { ServicesProvider, useConfig, useApi } from '../services/servicesContext';

import { moduleName as breadcrumbModule } from '../components/breadcrumb/breadcrumb';

import { HomeComponent } from '../reports/home.provider/homeComponent';

import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumbComponent';
import { SharedVisitsComponent } from '../reports/sharedvisits.provider/sharedvisitsComponent';
import { SpecialtyComponent } from '../reports/specialty/specialtyComponent';
import { OrganizationComponent } from '../reports/organization/organizationComponent';
import { ClinicalComponent } from '../reports/clinical.physician/clinicalComponent';
import { PerformanceProvider } from '../reports/performance.provider/performance.provider';
import { SummaryComponent } from '../reports/summary.provider/summaryComponent';
import { PartdutilizationComponent } from '../reports/partdutilization.physician/partdutilizationComponent';
import { ServiceLineSummaryComponent } from '../reports/servicelinesummary.provider/serviceLineSummaryComponent';

import { CarepathwaysComponent, PhysiciancarepathwaysComponent } from '../reports/carepathways.provider/carepathwaysComponent';
import { PhysicianGeneralflowComponent } from '../reports/generalflow.physician/generalflowComponent';
import { ProviderSpecialtyFlowComponent } from '../reports/specialtyflow.provider/specialtyFlowComponent';
import { GeoflowComponent } from '../reports/geoflow.provider/geoflowComponent';
import { FlowComponent } from '../reports/flow/flowComponent';
import { PhysicianTrendingComponent } from '../reports/trending.physician/physicianTrendingComponent';
import { PhysicianTrendingGraphComponent } from '../reports/trendinggraph.physician/physicianTrendingGraphComponent';

import { DashboardsectionComponent } from '../reports/dashboardssection/dashboardsectionComponent';
import { MarketbenchmarkComponent } from '../reports/marketbenchmark.physician/marketbenchmarkComponent';
import { GeographicbenchmarkComponent } from '../reports/geographicbenchmark.physician/geographicbenchmarkComponent';
import { YearOverYearDashboardComponent } from '../reports/yearoveryeardashboard.physician/yearoveryeardashboardComponent';
import { AmbiguousComponent } from '../reports/ambiguous/ambiguousComponent';

import { PhysicianBriefComponent } from './physician.brief';
import eventBus from "../services/eventBus";

export function PhysicianRoutingComponent(props) {
    console.log('rendering PhysicianRoutingComponent', props);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="/:npis"
                    id="physician.npis"
                    loader={async ({params}) => {
                        let npisArray = params.npis.split(',');
                        if (npisArray.length == 1) {
                            let provider = Promise.resolve(props.api.GetProvider(params.npis));
                            provider.then(value => {
                                props.recentlySelected.store(value); 
                                eventBus.dispatch("recentlySelectedChangeProviders");
                                eventBus.dispatch("resetCommunityList");
                                eventBus.dispatch("pageChange");
                            })
                            return props.api.GetProvider(params.npis);
                        }
                        else if (npisArray.length > 1) {
                            return npisArray;
                        }
                        else {
                            return null;
                        }
                    }}
                    element={<>
                        <BreadcrumbComponent
                            api={props.api}
                            recentlySelected={props.recentlySelected}
                        />
                        <Outlet/>
                    </>}

                >
                    <Route
                        path="home"
                        loader={async ({params}) => {
                            let npisArray = params.npis.split(',');
                            if (npisArray.length > 1) {
                                    return redirect('/' + params.npis + '/ambiguous');
                            }
                            else {
                                return null;
                            }
                        }}
                        element={<HomeComponent
                            api={props.api}
                            pic={props.pic}
                            CommunityList={props.CommunityList}
                            codes={props.codes}
                            notify={props.notify}
                            download={props.download}
                            affiliations={props.affiliations}
                        />}
                    />
                    <Route
                        path="ambiguous"
                        element={<AmbiguousComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            pic={props.pic}
                            tour={props.tour}
                            path={"home"}
                            entitytype={1}
                        />}
                    />
                    <Route
                        path="brief"
                        element={<PhysicianBriefComponent
                            api={props.api}
                            pic={props.pic}
                            tour={props.tour}
                        />}
                    />

                    <Route
                        path="SharedVisitsReport"
                        element={<SharedVisitsComponent
                            api={props.api}
                            pic={props.pic}
                            notify={props.notify}
                            download={props.download}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="bySpecialty"
                        element={<SpecialtyComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            pic={props.pic}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="byOrganization"
                        element={<OrganizationComponent
                            api={props.api}
                            notify={props.notify}
                            pic={props.pic}
                            download={props.download}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="ClinicalReport"
                        element={<ClinicalComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            codes={props.codes}
                            pic={props.pic}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="Services"
                        element={<PerformanceProvider
                            api={props.api}
                            pic={props.pic}
                            codes={props.codes}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="summary"
                        element={<SummaryComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="Prescriptions"
                        element={<PartdutilizationComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="ServiceLine"
                        element={<ServiceLineSummaryComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="CareJourney"
                        element={<CarepathwaysComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="GeneralFlow"
                        element={<PhysicianGeneralflowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="SpecialtyGraph"
                        element={<ProviderSpecialtyFlowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="ReferralMap"
                        element={<GeoflowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="Flow"
                        element={<FlowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="clinicaldashboard"
                        element={<PhysicianTrendingComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            config={props.config}
                            auth={props.auth}
                            codes={props.codes}
                        />}
                    />
                    <Route
                        path="TrendingGraph"
                        element={<PhysicianTrendingGraphComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                        />}
                    />
                    <Route
                        path="yoy"
                        element={<YearOverYearDashboardComponent
                            
                        />}
                    />
                    <Route
                        path="dashboards"
                        element={<DashboardsectionComponent
                            elementName='physician'
                            name='Physician Volume Benchmarks'
                            dashboards={[
                                {
                                  name: 'Volume of Procedures by Geography',
                                  paramkey: 'NPI',
                                  description: "Physician benchmarks compare a provider to their peers on both a local, regional, and national scale. Navigate the above tabs to analyze the physician's volumes and relative performance in each of the four code sets listed above"
                                },
                                {
                                  name: 'CBSA (Core Based Statistical Area) Market Volumes',
                                  paramkey: 'npis',
                                  description: "Compare a provider to other providers of the same taxonomy in the same CBSA by clinical volume data"
                                }
                              ]}
                        />}
                    />
                    <Route
                        path="geobenchmark"
                        element={<GeographicbenchmarkComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                            codes={props.codes}
                            
                        />}
                    />
                    <Route
                        path="marketbenchmarks"
                        element={<MarketbenchmarkComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                            codes={props.codes}
                            
                        />}
                    />
                </Route>
            </>
        ),
        { basename: '/physician'}
    );

    return (
        <ServicesProvider>
            <RouterProvider router={router} />
        </ServicesProvider>
    );
    
}

export default angular.module('app.pages.phys', [
    uirouter, api, models, recentlySelected, template, codes, affiliations, CommunityList, config, auth,
    /* home, brief, graphs, reports, benchmarks, */ breadcrumbModule
])
.component(
    'physicianRoutesComponent',
    react2angular(
        PhysicianRoutingComponent,
        ['npis'],
        ['api', 'pic','notify','download','tour','CommunityList','recentlySelected', 'affiliations','codes','config','auth','template']
    )
)
.run(['$stateRegistry', '$rootScope', '$transitions', 'template', '$state',
function($stateRegistry, $rootScope, $transitions, template, $state) {
    
    $stateRegistry.register({
        name: 'root.app.phy',
        url: '/physician/:npis/:reportname',
        component: 'physicianRoutesComponent',
    });
    
}]).name;