import angular from 'angular';

export default angular.module('download', [])
.service('download', function() {
    var service = this;
    
    service.downloadCSV = function(JSONData, reportTitle, ShowLabel, columnOrder) {
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
        var CSV = '';    
    
        if (ShowLabel) {
            var row = columnOrder.map(function(d){return '"'+(d[0]+'').replace(/"/, '""')+'"';}).join(",");
            CSV += row + '\r\n';
        }
        
        // //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var addRow = columnOrder.map(function(d){return '"'+(arrData[i][d[0]] +'').replace(/"/, '""') + '"'; }).join(',');
            CSV += addRow + '\r\n';
        }
    
        var fileName = reportTitle.replace(/ /g,"_") + ".csv";
        var blob = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            
            // this trick will generate a temp <a /> tag
            var link = document.createElement("a");
            
            // then set it's href;
            link.href = URL.createObjectURL(blob, {type: 'text/plain'});
            
            // set the visibility hidden so it will not effect on your web-layout
            link.style = "visibility:hidden";
            
            // set dowload attribute to the the filename
            link.setAttribute("download", fileName);
            
            // this part will append the anchor tag and remove it after automatic click
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
        }
    };
    
    return service;
    
}).name;