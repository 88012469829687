import React, { useState, useEffect, useRef } from 'react';
import { useRouteLoaderData, Link } from "react-router-dom";
import './trending.scss';
import dompurify from 'dompurify';
import * as d3Base from 'd3';
import { legendColor } from 'd3-svg-legend';
import { TrendingLineGraph } from './trendingGraphComponent.js';

const d3 = angular.extend({}, d3Base, { legendColor });

export function PhysicianTrendingGraphComponent(props) {

    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const [yoyloading, setYoyloading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [activeAxis, setActiveAxis] = useState(null);
    const [taxonomies, setTaxonomies] = useState(null);
    const [options, setOptions] = useState(null);
    const [finalData, setFinalData] = useState(null);

    var defaultOptions = {
        scales: {
            x: {
                fn: d3.scaleTime,
                accessor: 'date'
            },
            y: {
                fn: d3.scaleLinear,
                accessor: 'patients'
            },
            color: {
                fn: d3.scaleOrdinal,
                accessor: 'dataSource'
            }
        },
        axis: {
            x: {
                fn: d3.axisBottom,
                ticks: d3.timeMonth,
                tickFormat: {
                    formatFn: d3.timeFormat,
                    format: "%b %Y"
                }
            },
            y: {
                fn: d3.axisLeft,
                tickFormat: currentYAxisKey => currentYAxisKey === "charges" ? d3.format("$,.0f") : d3.format(",.0f")
            }
        }
    };

    function defaultAxis() {
        if (props.community || npis?.length > 1) {//don't sum unique patients (bad math), so don't provide the patients axis with multiple providers
            return {
                claims: true,
                charges: false
            };
        } else {
            return {
                patients: true,
                claims: false,
                charges: false
            };
        }
    }

    function toggleAxis(axis) {
        var axisObj = defaultAxis();
        
        Object.keys(axisObj).forEach(key => {
            if (key === axis) {
                axisObj[key] = true;
            } else {
                axisObj[key] = false;
            }
        });
        
        setActiveAxis(axisObj);
    };

    function toggleFilters(classSpecName) {

        var augmentedGraphData = aggregateData.reduce((acc, cur) => {
            
            acc.push(cur);//include current month standard dataSource
            
            var applicableCurMonthTaxonomies = cur.providers.reduce((taxonomyList, provider) => {
                var providerClassSpec = provider.taxonomy.toString();
                if ($scope.taxonomies[providerClassSpec] && taxonomyList.indexOf(providerClassSpec) === -1) {
                    taxonomyList.push(providerClassSpec);
                }
                return taxonomyList;
            }, []);
            
            if (applicableCurMonthTaxonomies.length > 0) {
                
                let applicableSeeds = applicableCurMonthTaxonomies.map(taxonomy => {
                    return {
                        patients: 0,
                        claims: 0,
                        charges: 0,
                        date: cur.date,
                        dataSource: `${cur.dataSource} - ${taxonomy}`,
                        providers: [],
                        npis: []
                    };
                });
                
                var taxonomyMonthDataSources = cur.sources.reduce((monthTaxonomiesAcc, curMonthSrc) => {
                    
                    var providerClassSpec = curMonthSrc.provider.taxonomy.toString();
                    
                    if ($scope.taxonomies[curMonthSrc.provider.taxonomy.toString()]) {
                        let seedIdx = monthTaxonomiesAcc.findIndex(taxonomySeed => taxonomySeed.dataSource === `${cur.dataSource} - ${providerClassSpec}`);
                        monthTaxonomiesAcc[seedIdx].patients += curMonthSrc.patients;
                        monthTaxonomiesAcc[seedIdx].claims += curMonthSrc.claims;
                        monthTaxonomiesAcc[seedIdx].charges += curMonthSrc.charges;
                        monthTaxonomiesAcc[seedIdx].providers.push(curMonthSrc.provider);
                        monthTaxonomiesAcc[seedIdx].npis.push(curMonthSrc.provider.npi);
                    }
                    
                    return monthTaxonomiesAcc;
                    
                }, applicableSeeds);
                
                return acc.concat(taxonomyMonthDataSources);
            } else {
                return acc;
            }

        }, []);

        var monthsByDataSourceAndTaxonomy = groupByProperty(augmentedGraphData, 'dataSource');

        monthsByDataSourceAndTaxonomy = fillMonthGaps(monthsByDataSourceAndTaxonomy);

        setFinalData(monthsByDataSourceAndTaxonomy);
    };

    function createColorDomain(dataSources, taxonomies) {
        var colorDomainSet = new Set();
        dataSources.forEach(ds => colorDomainSet.add(ds));
        taxonomies.forEach(tax => {
            dataSources.forEach(ds => {
                colorDomainSet.add(`${ds} - ${tax}`);
            });
        });
        return [...colorDomainSet];
    }

    function groupByProperty(list, propertyKey) {// nest arrays in object { key: string, values: array }
        return list.reduce((acc, cur) => {
            if (acc.indexOf(cur[propertyKey]) === -1 ) {
                acc.push(cur[propertyKey]);
            }
            return acc;
        },[]).map(propertyValue => {
            return {
                key: propertyValue,
                values: list.filter(obj => obj[propertyKey] === propertyValue)
            };
        });
    }
    
    function createMonthsRange(data) {
        
        var flattenedData = data.flatMap(lineObj => lineObj.values);
        var dateExtent = d3.extent(flattenedData, d => d.date);
        
        //https://github.com/d3/d3-time/tree/v1.0.8#interval_range
        //note: .range() Returns every an array of dates representing every interval boundary after or equal to start (inclusive) and before stop (exclusive)
        var monthsRange = d3.timeMonth
            .range(dateExtent[0], dateExtent[1])
            .concat(dateExtent[1]);//since stop month is excluded, add it to the end
            
        return monthsRange;
    }
    
    function fillMonthGaps(data, monthsRange) {
        
        return data.map((lineObj, dataSourceIdx, originalArr) => {
            return {
                key: lineObj.key,
                values: monthsRange.map(monthDate => {
                    var originalValueIdx = originalArr[dataSourceIdx].values.findIndex(month => {
                        //is original month found in the overall months
                        return `${month.date.getFullYear()}-${month.date.getMonth()+1}` === `${monthDate.getFullYear()}-${monthDate.getMonth()+1}`;
                    });
                    if (originalValueIdx === -1) {//empty month
                        return {
                            patients: null,
                            claims: null,
                            charges: null,
                            dataSource: lineObj.key,
                            date: monthDate
                        };
                    } else {//month exists
                        return originalArr[dataSourceIdx].values[originalValueIdx];
                    }
                })
            };
        });
    }

    useEffect(() => {
        async function init() {

            var localtaxonomies = {};
            var providers = {};
            var providersUrlSection;
            var endpointRequest;
            var localactiveAxis;

            var aggregateData;
            var flattenedData;

            var monthsRange;
            
            if (npis) {//physician or organization

                endpointRequest = fetch(`/api/npi/${npis.npi}/trending/`, props.api.options()).then(res => res.json());

            } else if (props.community) {// community

                endpointRequest = Promise.all(props.community.npis.map(npi => props.api.GetProvider(npi)))// communities only have a list of npis, we need to resolve them to providers
                .then(res => {
                    res.forEach(provider => {
                        providers[provider.npi] = provider;//add to provider lookup
                        if (provider.taxonomy && !localtaxonomies[provider.taxonomy.toString()]) {
                            localtaxonomies[provider.taxonomy.toString()] = false;
                        }
                    });
                    return fetch(`/api/cid/${props.community.id}/trending/`, props.api.options()).then(res => res.json());
                });

            } else {
                console.log('not a provider or community');
                props.notify.error({
                    text: 'Invalid Provider',
                    hide: false
                });
                setLoaded(true);
                return;
            }

            if (props.community || npis.length > 1) {//don't sum unique patients (bad math), so don't provide the patients axis with multiple providers
                localactiveAxis = {
                    claims: true,
                    charges: false
                };
            } else {
                localactiveAxis = {
                    patients: true,
                    claims: false,
                    charges: false
                };
            }

            endpointRequest
                .then(res => {

                    const sanitizedTrendingData = {};
                    for (const [npi, npiVal] of Object.entries(res)) {
                        const sanitizedNpiKey = dompurify.sanitize(npi);
                        sanitizedTrendingData[sanitizedNpiKey] = {};
                        for (const [dataSource, dataSourceVal] of Object.entries(res[npi])) {
                            const sanitizedDataSourceKey = dompurify.sanitize(dataSource);
                            sanitizedTrendingData[sanitizedNpiKey][sanitizedDataSourceKey] = {};
                            for (const [yearMonth, yearMonthVal] of Object.entries(res[npi][dataSource])) {
                                const sanitizedMonthYearKey = dompurify.sanitize(yearMonth);
                                sanitizedTrendingData[sanitizedNpiKey][sanitizedDataSourceKey][sanitizedMonthYearKey] = {
                                    charges: Number(dompurify.sanitize(res[npi][dataSource][yearMonth].charges)),
                                    claims: Number(dompurify.sanitize(res[npi][dataSource][yearMonth].claims)),
                                    patients: Number(dompurify.sanitize(res[npi][dataSource][yearMonth].patients))
                                };
                            }
                        }
                    }

                    var trendingData = sanitizedTrendingData;

                    flattenedData = Object.keys(trendingData).map(npi => {
                        return Object.keys(trendingData[npi]).map(dataSource => {
                            return Object.keys(trendingData[npi][dataSource]).map(yearMonth => {
                                var ret = trendingData[npi][dataSource][yearMonth];
                                ret.npi = npi;
                                ret.provider = providers[npi];
                                ret.date = new Date(
                                    parseInt(yearMonth.slice(0,4)),
                                    (parseInt(yearMonth.slice(5,7)) - 1)
                                );
                                ret.dataSource = dataSource.charAt(0).toUpperCase()+dataSource.slice(1);
                                return ret;
                            });
                        });
                    })
                    .flat(Infinity);

                    aggregateData = Object.values(flattenedData.reduce((acc, cur) => {
                        var index = cur.date.getTime() + cur.dataSource;
                        var bin = acc[index];
                    
                        if (!bin) {
                            acc[index] = bin = {
                                patients: 0,
                                claims: 0,
                                charges: 0,
                                providers: [],
                                npis: [],
                                dataSource: cur.dataSource,
                                date: cur.date,
                                sources: []
                            };
                        }

                        bin.patients += cur.patients;
                        bin.claims += cur.claims;
                        bin.charges += cur.charges;
                        bin.providers.push(cur.provider);
                        bin.npis.push(cur.npi);
                        bin.sources.push(cur);
                    
                    return acc;
                    }, {}));

                    var localfinalData = groupByProperty(aggregateData, 'dataSource');

                    monthsRange = createMonthsRange(localfinalData);
                    localfinalData = fillMonthGaps(localfinalData, monthsRange);

                    var localdataSources = localfinalData.map(dataSource => dataSource.key);
                    var tmptaxonomies = Object.keys(localtaxonomies);

                    var localoptions = Object.assign({}, defaultOptions);
                    localoptions.scales.color.domain = createColorDomain(localdataSources, tmptaxonomies);
                    
                    setTaxonomies(localtaxonomies)
                    setOptions(localoptions);
                    setActiveAxis(localactiveAxis);
                    setFinalData(localfinalData);
                    setYoyloading(false);
                    setLoaded(true);
                    
                })
                .catch(err => console.log('err', err));
        }
        init();
    }, []);
    
    return (<>
        { activeAxis && taxonomies && finalData && options ? <div className="physician-trending-graph-container"><div className="line-graph-container">
            <div className="line-graph-control form-inline">
                <div className="btn-group">
                    {Object.entries(activeAxis).map(([key, value]) => {
                        return (
                            <button
                                key={key}
                                className={`btn btn-default ${ activeAxis[key] ? 'primary-product-button' : ''}`}
                                onClick={() => toggleAxis(key)}
                                type="button"
                                style={{textTransform: 'capitalize'}}
                            >
                                {key}
                            </button>
                        );
                    })}
                </div>
                { npis.entitytype == 1 ? <Link className="btn btn-default" onClick={()=> setYoyloading(true)} to={`/physician/${npis.npi}/yoy`}>
                    Year over Year Graph { yoyloading ? <i className="fa fa-spinner fa-spin fa-fw"></i> : null }
                    </Link> : null}
                { props.community || npis.length > 1 ? <span className="open">
                    <button type="button" className="btn btn-default btn-md btn-block">
                        Specialties <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style="max-height: 600px;overflow:auto;margin-top:1em;">
                        { Object.entries(taxonomies).map(([key, value]) => {
                            return <li className="checkbox" style={{display:'block'}}>
                                <label style={{whiteSpace: 'nowrap', display: 'block', padding: '0px 20px'}}>
                                    <input type="checkbox" ng-model="taxonomies[key]" ng-change="$ctrl.toggleFilters(key)" style="top:2px;"></input>
                                    <div style={{width:'10px', height:'10px', display:'inline-block'}}></div>
                                    {{key}} 
                                </label>
                            </li>;
                        })}
                    </ul>
                </span> : null}
            </div>
            <div className="line-graph">
                <TrendingLineGraph
                    graphdata={finalData}
                    currentaxis={activeAxis}
                    options={options}
                    template={props.template}
                ></TrendingLineGraph>
            </div>
        </div></div> : <div className="loading-lg"></div> }
    </>);
}