import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$http', 'pic', '$localStorage', 'tour', 'notify', 'Taxonomy', 'api', function($scope, $http, pic, $localstorage, tour, notify, Taxonomy, api) {
    var $ctrl = this;
    $scope.pic = pic;
    
    $ctrl.$onInit = function() {
        
        var provider = $ctrl.npis[0];
        $scope.miles = $ctrl.miles;
        
        $scope.reportOptions = { 
            export: { 
                name: 'Leakage Report for ' + provider.name.display + ' (' + provider.npi + ')'
            },
            communityList: {
                accessor: function(datum) { return datum.sourceNpi; }
            }
        };
        
        getAndFormatData(provider.npi);
        
        var tourService = $ctrl.tour || tour;
        tourService.setScript(tourConfig);
       
        $ctrl.$onChanges = function(changes) {
            if (changes.miles) {
                $scope.miles = changes.miles.currentValue;
                getAndFormatData(provider.npi);
            }
        };
    };
    
    function getAndFormatData(npi) { 
        $http.get('/api/npi/' + npi + '/summary-leakage?miles=' + $scope.miles, api.options())
        .then(function(res) {
            $scope.loaded = true;
            $scope.data = res.data.links.map(function(datum) {
                return {
                    'sourceNpi': datum.source,
                    'sourceCommunities': pic(datum.source),
                    'sourceName': res.data.nodes.features[datum.source].name,
                    'sourceTaxonomy': new Taxonomy(res.data.nodes.features[datum.source]),
                    'sourceCity': res.data.nodes.features[datum.source].city,
                    'sourceState': res.data.nodes.features[datum.source].state,
                    'sourceZip': res.data.nodes.features[datum.source].postalcode,
                    'sourcePhone': res.data.nodes.features[datum.source].phone_number,
                    'sourceTaxonomyType': res.data.nodes.features[datum.source].taxonomytype,
                    'targetNpi': datum.target,
                    'targetCommunities': pic(datum.target),
                    'targetName': res.data.nodes.features[datum.target].name,
                    'targetTaxonomy': new Taxonomy(res.data.nodes.features[datum.target]),
                    'targetCity': res.data.nodes.features[datum.target].city,
                    'targetState': res.data.nodes.features[datum.target].state,
                    'targetZip': res.data.nodes.features[datum.target].postalcode,
                    'targetPhone': res.data.nodes.features[datum.target].phone_number,
                    'targetTaxonomyType': res.data.nodes.features[datum.target].taxonomytype,
                    'visits': datum.flow
                };
            });
        }, function(err){
            console.log('Error fetching Summary Leakage Report data: ', err);
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Summary Leakage Report data.',
                delay: 30000
            });
        });
    }
}];