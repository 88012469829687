import angular from 'angular';
import { react2angular } from 'react2angular';
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    Outlet
} from "react-router-dom";

import uirouter from '@uirouter/angularjs';

import api from '../services/api';
import recentlySelected from '../services/recentlySelected';
import affiliations from '../services/affiliations';
import template from '../services/template';
import models from '../models';

import home from './provider.home';
import brief from './organization.brief';
import graphs from './organization.graphs';
import reports from './organization.reports';
import CommunityList from '../services/communityList';
import { ServicesProvider, useConfig, useApi } from '../services/servicesContext';

import { moduleName as breadcrumbModule } from '../components/breadcrumb/breadcrumb';
import { HomeComponent } from '../reports/home.provider/homeComponent';

import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumbComponent';
import { SharedVisitsComponent } from '../reports/sharedvisits.provider/sharedvisitsComponent';
import { SpecialtyComponent } from '../reports/specialty/specialtyComponent';
import { OrganizationComponent } from '../reports/organization/organizationComponent';
import { PostacuteLeakageComponent } from '../reports/postacuteleakage.organization/postacuteleakageComponent';
import { PreacuteLeakageComponent } from '../reports/preacuteleakage.organization/preacuteleakageComponent';
import { PerformanceProvider } from '../reports/performance.provider/performance.provider';
import { SummaryComponent } from '../reports/summary.provider/summaryComponent';
import { LeakageProvider } from '../reports/leakage.provider/leakage.provider';
import { ServiceLineSummaryComponent } from '../reports/servicelinesummary.provider/serviceLineSummaryComponent';
import { MarketSharebyZipComponent } from '../reports/marketsharebyzip.organization/marketsharebyzipComponent';

import { CarepathwaysComponent } from '../reports/carepathways.provider/carepathwaysComponent';
import { ProviderSpecialtyFlowComponent } from '../reports/specialtyflow.provider/specialtyFlowComponent';
// TODO: get 251 ticket branch merged
import { MarketMapComponent } from '../reports/marketmap.organization/marketmapComponent';

import { QuadrantAnalysisComponent } from '../reports/quadrantanalysis.organization/quadrantAnalysisComponent';
import { GeoflowComponent } from '../reports/geoflow.provider/geoflowComponent';
import { FlowComponent } from '../reports/flow/flowComponent';
import { TrendingGraphComponent as OrganizationTrendingGraphComponent } from '../reports/trendinggraph/trendingGraphReportComponent';

import { OrganizationBriefComponent } from './organization.brief';
import eventBus from "../services/eventBus";

export function OrganizationRoutingComponent(props) {
    console.log('rendering OrganizationRoutingComponent', props);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="/:npis"
                    id="organization.npis"
                    loader={async ({params}) => {
                        let provider = Promise.resolve(props.api.GetProvider(params.npis));
                        provider.then(value => {
                            props.recentlySelected.store(value); 
                            eventBus.dispatch("recentlySelectedChangeProviders");
                            eventBus.dispatch("resetCommunityList");
                            eventBus.dispatch("pageChange");
                          })
                        return props.api.GetProvider(params.npis);
                    }}
                    element={<>
                        <BreadcrumbComponent
                            api={props.api}
                            recentlySelected={props.recentlySelected}
                        />
                        <Outlet/>
                    </>}

                >
                <Route
                    path="home"
                    element={<HomeComponent
                        api={props.api}
                        pic={props.pic}
                        CommunityList={props.CommunityList}
                        codes={props.codes}
                        notify={props.notify}
                        download={props.download}
                        affiliations={props.affiliations}
                    />}
                />
                <Route
                    path="brief"
                    element={<OrganizationBriefComponent
                        api={props.api}
                        pic={props.pic}
                        tour={props.tour}
                    />}
                />
                    <Route
                        path="SharedVisitsReport"
                        element={<SharedVisitsComponent
                            api={props.api}
                            pic={props.pic}
                            notify={props.notify}
                            download={props.download}
                            CommunityList={props.CommunityList}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="bySpecialty"
                        element={<SpecialtyComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            pic={props.pic}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="byOrganization"
                        element={<OrganizationComponent
                            api={props.api}
                            notify={props.notify}
                            pic={props.pic}
                            download={props.download}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="PreAcuteLeakage"
                        element={<PreacuteLeakageComponent
                            api={props.api}
                            notify={props.notify}
                            pic={props.pic}
                            download={props.download}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="PostAcuteLeakage"
                        element={<PostacuteLeakageComponent
                            api={props.api}
                            notify={props.notify}
                            pic={props.pic}
                            download={props.download}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="Services"
                        element={<PerformanceProvider
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                            pic={props.pic}
                            codes={props.codes}
                        />}
                    />
                    <Route
                        path="PCPSpecialistVolumes"
                        element={<SummaryComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                            pic={props.pic}
                        />}
                    />
                    {/* <Route
                        path="Leakage"
                        element={<SummaryLeakageReportModule
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                            pic={props.pic}
                        />}
                    /> */}
                    <Route
                        path="Leakage"
                        element={<LeakageProvider
                            api={props.api}
                            codes={props.codes}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                            pic={props.pic}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="ServiceLine"
                        element={<ServiceLineSummaryComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="marketsharebyzip"
                        element={<MarketSharebyZipComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="carejourney"
                        element={<CarepathwaysComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="SpecialtyGraph"
                        element={<ProviderSpecialtyFlowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            config={props.config}
                            template={props.template}
                        />}
                    />
                    {<Route
                        path="MarketMakers"
                        element={<MarketMapComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />}
                    <Route
                        path="ShareByVolume"
                        element={<QuadrantAnalysisComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="ReferralMap"
                        element={<GeoflowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="flow"
                        element={<FlowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="trendinggraph"
                        element={<OrganizationTrendingGraphComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                        />}
                    />
                </Route>
            </>
        ),
        { basename: '/organization'}
    );

    return (
        <ServicesProvider>
            <RouterProvider router={router} />
        </ServicesProvider>
    );
    
}

export default angular.module('app.pages.orgs', [
    uirouter, api, models, recentlySelected, template, affiliations, CommunityList,
    /*home, brief, graphs, reports,*/ breadcrumbModule
])
.component(
    'organizationRoutesComponent',
    react2angular(
        OrganizationRoutingComponent,
        ['npis'],
        ['api', 'pic','notify','download','tour','CommunityList','recentlySelected', 'affiliations','codes','template','CommunityList','config']
    )
)
.run(['$stateRegistry', '$rootScope', '$transitions', 'template', '$state',
function($stateRegistry, $rootScope, $transitions, template, $state) {

    $stateRegistry.register({
        name: 'root.app.org',
        url: '/organization/:npis/:reportname',
        component: 'organizationRoutesComponent',
    });
    
    
}]).name;