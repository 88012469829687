import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import { moduleName as sharedVisitsModule, updatedComponentName as sharedVisitsComponent } from '../reports/sharedvisits.provider/sharedvisits';
import { moduleName as specialtyModule, updatedComponentName as specialtyComponent } from '../reports/specialty/specialty';
import { moduleName as organizationModule, updatedComponentName as organizationComponent } from '../reports/organization/organization';
import { moduleName as clinicalModule, updatedComponentName as clinicalComponent } from '../reports/clinical.physician/clinical';
import { moduleName as performanceReportModule } from '../reports/performanceReport.provider/performanceReport';
import { moduleName as performancefunnelModule } from '../reports/performancefunnel.provider/performancefunnel';
import { moduleName as providerPerformanceModule, updatedComponentName as providerComponent } from '../reports/performance.provider/performance.provider.wrapper';
import { moduleName as summaryModule, updatedComponentName as summaryComponent } from '../reports/summary.provider/summary';
import { moduleName as partdutilizationModule, updatedComponentName as partdutilizationComponent } from '../reports/partdutilization.physician/partdutilization';
import { moduleName as serviceLineSummaryModule, updatedComponentName as serviceLineSummaryComponent } from '../reports/servicelinesummary.provider/servicelinesummary';

export default angular.module('app.pages.phys.reports', [
    uirouter, sharedVisitsModule, specialtyModule, organizationModule, clinicalModule, performanceReportModule,
    performancefunnelModule, summaryModule, partdutilizationModule, serviceLineSummaryModule, providerPerformanceModule
]).config(['$stateProvider', 'authProvider', 
function($stateProvider, authProvider) {
    
    $stateProvider.state({
        name: 'root.app.phy.reports',
        abstract: true,
        template: '<ui-view class="app.phy.reports"/>',
        url: ''
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.sharedvisits',
        url: '/SharedVisitsReport',
        altUrls: ['^/physician/sharedvisits/:npis', '^/physician/:npis/sharedvisits' ],
        component: sharedVisitsComponent,
        data: {
            name: 'Shared Visits'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.specialty',
        url: '/bySpecialty',
        altUrls: ['^/physician/taxonomy/:npis', '^/physician/specialty/:npis', '^/physician/:npis/specialty' ],
        component: specialtyComponent,
        data: {
            name: 'by Specialty'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.organization',
        url: '/byOrganization',
        altUrls: ['^/physician/organization/:npis', '^/physician/:npis/organization'],
        component: organizationComponent,
        data: {
            name: 'by Organization'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.clinical',
        url: '/ClinicalReport',
        altUrls: ['^/physician/clinical/:npis', '^/physician/:npis/clinical' ],
        component: clinicalComponent,
        data: {
            name: 'Clinical Report'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.performance',
        url: '/Services',
        altUrls: ['^/physician/performance/:npis', '^/physician/:npis/performance'],
        component: providerComponent,
        data: {
            name: 'Services'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.summary',
        url: '/summary',
        altUrls: ['^/physician/summary/:npis'],
        component: summaryComponent,
        data: {
            name: 'Summary'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.reports.partdutilization',
        url: '/Prescriptions',
        altUrls: ['^/physician/partD_utilizaton/:npis', '^/physician/partdutilizaton/:npis', '^/physician/:npis/partdutilizaton' ],
        component: partdutilizationComponent,
        data: {
            name: 'Prescriptions',
            hideTTM: true
        }
    });
    
    $stateProvider.state({    
        name: 'root.app.phy.reports.servicelinesummary',
        url: '/ServiceLine',
        altUrls: ['^/physician/:npis/servicelinesummary'],
        component: serviceLineSummaryComponent,
        data: {
            name: 'Service Line',
            isActive: function() {
                return authProvider.service.loggedIn().then(user => {
                    return user.dataModel().features.indexOf('SERVICE LINE SUMMARY') > -1;
                });
            }
        }
    });

}]).name;