import angular from 'angular';

import tableDataTemplate from './tableData.html';
import tableDataAccessor from './tableDataAccessor.html';

import CommunityList from '../../services/communityList';
import download from '../../services/download';

export default 'tableData';

angular.module('tableData', [
    CommunityList, download
])
.directive('tableData', ['$filter', '$parse', 'api', 'CommunityList', 'download', function($filter, $parse, api, CommunityList, download) {

    var defaults = {
        report: {
            name: 'report'
        },
        sort: {
            column: false,
            disable: false,
            reverse: true
        },
        pagination: {
            disable: false
        },
        export: {
            disable: false
        },
        filterInput: {
            disable: false
        },
        communityList: {
            accessor: false,
            description: 'Toggle between the list of providers on the page you were viewing and the list of providers from the full report.'
        }
    };
    
    return {
        restrict: 'A',
        template: tableDataTemplate,
        transclude: {
            'buttons': '?buttons'
        },
        scope: {
            source: '<tableData',
            class: '@',
            opts: '<'
        },
        link: function(scope, elem, attrs, controller, transclude) {

            scope.filter = '';
            
            var fullList = [];

            var transcludeScope = scope.$parent.$new();
            
            transclude(transcludeScope, function(clone, parentScope) {
                elem.find('[transcludescopeloc]').append(clone);
              });
            
            transcludeScope[attrs.tableData] = [];
            
            function getSortedAndFilteredData() {
                var datums = scope.source || [];
                if (scope.filter) {
                    datums = $filter('filter')(datums, scope.filter);
                }
                if (controller.sort && controller.sort.accessor) {
                    datums = $filter('orderBy')(datums, controller.sort.accessor, controller.sort.reverse);
                }
                fullList = checkForNpi(datums);
                return datums;
            }
            
            controller.updateData = function() {
                var datums = scope.tableDataResults = getSortedAndFilteredData();
                if (!scope.mergedOpts.pagination.disable) {
                    datums = datums.slice(((scope.page.current-1)*scope.page.items), scope.page.items*scope.page.current);
                }
                updateCommunityList(datums);
                transcludeScope[attrs.tableData] = datums;
            };
            
            scope.$watchGroup([
                'source',
                'page.current',
                'page.items',
                'filter',
                'opts',
            ], controller.updateData);
            
            controller.export = function() {
                
                var datums = getSortedAndFilteredData();
                
                var title = scope.mergedOpts.report.name;
                
                var columns = [];
                elem.find('th').each(function(index, el) {
                    if(angular.element(el).attr('table-data-accessor')) {
                        var columnInfo = {
                            text: angular.element(el).text().trim(),
                            accessor: angular.element(el).attr('table-data-accessor'),
                            get: $parse(angular.element(el).attr('table-data-accessor'))
                        };
                        columns.push(columnInfo);
                    }
                });
                
                var columnOrder = columns.map(function(col, index) { return [col.text, index]; });
                
                var CSVdata = datums.map(function(row) {
                    
                    var data = {};
                    
                    columns.forEach(function(col) {
                        var val = col.get(row);
                        if (Array.isArray(val) )
                        val = val.join(',');
                        data[col.text] = val;
                    });
                    
                    return data;
                });
                
                download.downloadCSV(CSVdata, title, true, columnOrder);
            };
            
            function checkForNpi(datums) {
                var list = [];
                datums.forEach(function(datum) {
                    if (scope.mergedOpts.communityList.accessor) {
                        var npi = scope.mergedOpts.communityList.accessor(datum);
                        if (list.indexOf(npi) == -1 ) {
                            list.push(npi);
                        }
                    }
                });
                return list;
            }
            
            function updateCommunityList(datums) {
                var list = checkForNpi(datums);
                if (scope.mergedOpts.communityList.accessor) {
                    controller.communityList.update(list, fullList, scope.mergedOpts.communityList.description);
                }
            }
        },
        controller: ['$scope', function($scope) {

            $scope.mergedOpts = this.opts = angular.merge({}, defaults, $scope.opts);
            
            this.communityList = this.opts.communityList.service || CommunityList;
            
            var defaultAccessor = $parse($scope.mergedOpts.sort.column);
            
            this.sort = { accessor: defaultAccessor, reverse: $scope.mergedOpts.sort.reverse };
            
            this.sortColumn = function(accessor, reverse) {
                this.sort.accessor = accessor;
                this.sort.reverse = reverse;
                $scope.page.current = 1;
                this.updateData();
            };

            $scope.page = {
                current: 1,
                items: '10'
            };
            
            this.stateToggle = function() {
                return true;
            };
        }],
        controllerAs: '$ctrl'
    };
}])
.directive('tableDataAccessor', ['$parse', function($parse) {
    return {
        restrict: 'A',
        require: '^tableData',
        transclude: true,
        template: tableDataAccessor,
        scope: true,
        link: function(scope, elem, attrs, tableCtrl) {

            var parser = scope.parser = $parse(attrs.tableDataAccessor);
            
            scope.disableSort = tableCtrl.opts.sort.disable;
            
            if (!scope.disableSort) {
                scope.sort = tableCtrl.sort;
                scope.sorted = function() {
                    if (tableCtrl.sort.accessor === parser) {
                        tableCtrl.sort.reverse = !tableCtrl.sort.reverse;
                    } else {
                        tableCtrl.sort.reverse = true;
                    }
                    tableCtrl.sortColumn(parser, tableCtrl.sort.reverse);
                };
            }
            
        }
    };
}]);