import angular from 'angular';

export default angular.module('user', [])
.provider('user', function() {
    
    this.$get = ['$http', 'api','config', function($http, api, config) {
        
        return {
            getUserById: function(userId) {
                return $http.get(`${config.account().api}/profile/${userId}`, { headers: { Authorization: api.options().headers.Authorization}})
                .then(res => {
                    return res.data;
                }, err => {
                    console.log('error getting profile', err);
                });
            },
            getCurrentUserProfile: function() {
                return $http.get(`${config.account().api}/profile/`, { headers: { Authorization: api.options().headers.Authorization}})
                .then(res => {
                    return res.data;
                }, err => {
                    console.log('error getting profile', err);
                });
            }
        };
        
    }];
    
}).name;