import angular from 'angular';

var PNotify = require("pnotify/dist/es/PNotify");
var PNotifyButtons = require("pnotify/dist/es/PNotifyButtons");

export default angular.module('app.service.notify', [])
.provider('notify', [function() {
    // Set Configs
    PNotify.default.defaults.styling = 'bootstrap3';
    PNotify.default.defaults.icons = 'bootstrap3';
    PNotify.default.defaults.hide = true;
    PNotify.default.defaults.delay = 5000;
    PNotify.default.modules.Buttons.defaults.closer = true;
    PNotify.default.modules.Buttons.defaults.closerHover = false;
    
    return {
        $get: function() {
            return {
                __proto__: PNotify.default
            };
        }
    };
    
}]).name;