import angular from 'angular';

import { react2angular } from 'react2angular';

import './trending.scss';
import '../../../node_modules/dc/dc.min.css';// deep linking to css in dc package

import crossfilter from '../../directives/dc-charts/crossfilter';
import grouping from '../../directives/dc-charts/grouping';
import numberDisplay from '../../directives/dc-charts/numberDisplay';
import chart from '../../directives/dc-charts/charts';
import serviceTemplate from '../../services/template';
import codes from '../../services/codes';

import { physicianTrendingComponent } from './physicianTrendingComponent.js';

const componentName = 'physicianTrending';
const updatedComponentName = 'physicianTrendingComponent';
const moduleName = 'app.reports.trending.physician';

angular.module(moduleName, [
    'ngCookies', 'ui.bootstrap', 'angular.filter', 'ngAnimate', crossfilter, grouping, numberDisplay, chart, serviceTemplate, codes
])
.component(updatedComponentName, react2angular(physicianTrendingComponent, ['npis'], ['api', 'notify', 'config', 'auth', 'user', 'template', 'tour', 'codes']))
.config(function () {
    String.prototype.isNumeric = function () {
        return !isNaN(parseFloat(this)) && isFinite(this);
    };
});

export {
    moduleName,
    updatedComponentName
};