import * as d3 from 'd3v6';

export function phHorizontalBarGraph() {
  
  let providers = [];
  let seedProvider;
  let communityMembers = [];
  let width = 300;
  let height = 300;
  let xScale;
  let yScale;
  let xAxis;
  let xAxisContainer;
  let parent;
  let selection;
  let tooltip;
  let selectionTranslate;
  let navToProviderHome;
  let lockTooltip;
  let margin = {
    top: 30,
    right: 20,
    bottom: 30,
    left: 20
  };
  
  function computeFillColor(barDatum) {
    var communityColor = communityMembers.indexOf(barDatum.npi) > -1 ? '#E24444' : '';
    var seedColor = barDatum.npi == seedProvider ? '#EDDC3C' : '';
    if (seedColor) {
      return seedColor;
    } else if (communityColor) {
      return communityColor;
    } else {
      return '#1f77b4';
    }
  }
  
  function render(data, selector) {
    if (data) {
      width = data.width ? data.width : width;
      height = data.height ? data.height : height;
      providers = data.data || providers || [];
      seedProvider = data.seedProvider || seedProvider;
      communityMembers = data.communityMembers || communityMembers;
      navToProviderHome = data.navToProviderHome || navToProviderHome;
    }
    
    if (communityMembers.length > 0) {
      communityMembers = communityMembers.map(mem => `${mem}`);
    }
    if (seedProvider) {
      seedProvider = `${seedProvider}`;
    }
    
    providers.sort(function(a, b) {
      return a.procedures - b.procedures;
    });
    
    // Define the div for the tooltip
    if (!tooltip) {
      tooltip = d3.select("body").append("div")	
        .attr("class", "horizontal-bar-graph-tooltip")
        .style('position', 'absolute')
        .style('test-align', 'center')
        .style('padding', '5px')
        .style('font', '12px sans-serif')
        .style('background', 'white')
        .style('box-shadow', '0 6px 12px rgb(0 0 0 / 18%)')
        .style('border-radius', '5px')
        .style('opacity', 0);
      
      tooltip.append('p')
        .attr('class', 'horizontal-bar-graph-tooltip-provider')
        .style('margin', '0')
        .style('display', 'inline');
        
      tooltip.append('button')
        .attr('class', 'close-horizontalBarGraph-tooltip btn btn-default btn-xs')
        .style('display', 'inline')
        .style('cursor', 'pointer')
        .on('click', function(event, d){
          tooltip.transition()
            .duration(100)
            .style("opacity", 0)
            .style('pointer-events', 'none');
          lockTooltip = false;
        })
        .text('X');
      
      tooltip.append('p')
        .attr('class', 'horizontal-bar-graph-tooltip-procedures')
        .style('margin', '0');
    }
    
    if (!xScale) {
      xScale = d3.scaleLinear();
    }
    xScale
      .range([(width - margin.left - margin.right), 0])
      .domain([0, d3.max(providers, function(d) { return d.procedures; })]);
    
    xAxis = g => g
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .call(d3.axisTop(xScale).ticks(width / 80 ).tickSizeOuter(0))
      .call(g => g.append("text")
        .attr("x", width - margin.left)
        .attr("y", -4)
        .attr("fill", "currentColor")
        .attr("font-weight", "bold")
        .attr("text-anchor", "end")
        .text(data.x));

    if (!yScale) {
      yScale = d3.scaleBand();
    }
    yScale
      .range([(height - margin.top - margin.bottom), 0])
      .domain(providers.map(function(d) { return d.npi; }))//set placement on y axis since providers has been pre sorted
        .paddingInner(0.1);
    
    if (!parent) {
      parent = d3.select(selector);
    }
    
    if (!selection) {
      selection = parent.append('svg');
    }
    selection
      .attr("width", width)
      .attr("height", height);
    
    if (!selectionTranslate) {
      selectionTranslate = selection.append("g");
    }
    selectionTranslate
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
    selectionTranslate.selectAll(".bar")
      .data(providers)
      .join("rect")
        .attr("class", "bar")
        .attr("fill", computeFillColor )
        .attr("x", function(d) {
          
          return xScale(d.procedures);

        })
        .attr("height", yScale.bandwidth())
        .attr("y", function(d) { return yScale(d.npi); })
        .attr("width", function(d) { return width - margin.left - margin.right - xScale(d.procedures); })
        .on('mouseover', function(event, d) {
          
          if (!lockTooltip) {
            d3.select(this)
              .attr('stroke', 'black')
              .attr('stroke-width', 1);
            tooltip.select('p.horizontal-bar-graph-tooltip-provider')
              .style('color', computeFillColor(d))
              .style('cursor', 'pointer')
              .on('click', (e) => {
                navToProviderHome(d.provider.npi);
              })
              .text(`${d.provider.name.display} (${d.npi})`);
              
            tooltip.select('p.horizontal-bar-graph-tooltip-procedures')
              .text(`${d.procedures} Procedures`);
              
            tooltip.transition()		
              .duration(200)		
              .style("opacity", .9);	
            tooltip
              .style("left", (event.pageX - 224 - 30) + "px")		
              .style("top", (event.pageY - 28) + "px")
              .style('pointer-events', 'all');
          }
          
        })
        .on('click', function(event, d) {
          lockTooltip = lockTooltip ? false : true;
        })
        .on('mousemove', function(event, d){
          if (!lockTooltip) {
            tooltip
              .style("left", (event.pageX - 224 - 30) + "px")		
              .style("top", (event.pageY - 28) + "px")
              .style('pointer-events', 'all');
          }
        })
        .on('mouseout', function(d) {
          d3.select(this)
            .attr('fill', computeFillColor)
            .attr('stroke', '')
            .attr('stroke-width', '');
          
          if (!lockTooltip) {
            tooltip.transition()
              .duration(500)
              .style("opacity", 0)
              .style('pointer-events', 'none');
          }
        });
    
    if (!xAxisContainer) {
      xAxisContainer = selection.append("g").attr('class','xAxisContainer');
      xAxisContainer
        .call(xAxis)
        .append('text')
          .attr('class', 'x-axis-label')
          .attr('fill', 'currentcolor')
          .attr('x', width/2)
          .attr('y', -20)
          .style('text-anchor', 'middle')
          .text('Number of Procedures');
    } else {
      xAxisContainer.remove();
      xAxisContainer = selection.append("g").attr('class','xAxisContainer');
      xAxisContainer
        .call(xAxis)
        .append('text')
          .attr('class', 'x-axis-label')
          .attr('fill', 'currentcolor')
          .attr('x', width/2)
          .attr('y', -20)
          .style('text-anchor', 'middle')
          .text('Number of Procedures');
    }
  }
  
  function chart(selector, data) {
    render(data, selector);
  }
  
  chart.dimensions = function(values) {
    if (!arguments.length) return {width: width, height: height};
    render({
      width: values.width,
      height: values.height
    });
    return chart;
  };
  
  chart.providers = function(value) {
    if (!arguments.length) return providers;
    render({data: value});
    return chart;
  };
  
  chart.destroy = function() {
    
    tooltip.remove();
    tooltip = null;
    
    selection.remove();
    selection = null;
    
    return true;
  };
  
  return chart;
  
}