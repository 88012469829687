import React, {useState, useEffect} from 'react';
import { useRouteLoaderData } from "react-router-dom";

import { useLogging } from '../../services/servicesContext';

import { DashboardsEmbedWrapper } from '../dashboards/dashboard.embed';

export function YearOverYearDashboardComponent(props) {

    const [ loading, setLoading ] = useState(true);//default true prevents the default embed wrapper from using its own logger
    const npis = useRouteLoaderData('physician.npis');
    const logging = useLogging();

    useEffect(() => {
        setLoading(true);
        logging.routeLoad({//log page here before the the embed wrapper uses its own logger
            pathname: location.pathname,
            npis: [npis.npi],
            statename: 'root.app.phy.dashboards.yoy'
        });
        setLoading(false);//having already logged for this path, the default embed logger won't run
    }, [npis]);

    const params = {
        NPI: npis.npi,
        CBSA: npis.cbsa.point,
        Taxonomy: `${npis.taxonomy.classification}${npis.taxonomy.specialization ? ' '+npis.taxonomy.specialization : ''}`
    };

    return (<>{ !loading
        ? <DashboardsEmbedWrapper dashboards="" params={params} report="yoy" />
        : <div className="loading-lg"></div>
    }</>);
}