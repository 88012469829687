import angular from 'angular';
import React, {useState, useEffect} from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { useApi, usePic, useTemplate, useTourManager, useLogging } from '../services/servicesContext';

import { react2angular } from 'react2angular';

import uirouter from '@uirouter/angularjs';

import briefTemplate from './templates/brief.physician.html';

import tour from '../services/tour';
import api from '../services/api';
import notify from '../services/notify';

import SynopsisComponent from '../reports/synopsis.provider/synopsisComponent.js';
import SummarySharedvisitsComponent from '../reports/summarySharedvisits.provider/summarySharedvisitsComponent.js';
import SummaryPanelsComponent from '../reports/summaryPanels.physician/summaryPanelsComponent.js';
// import CodingupliftComponent from '../reports/codinguplift.physician/codingupliftComponent.js';
import CodingUpliftComponentRevised from '../reports/codinguplift.physician/codingUpliftComponentRevised.js';

export default angular.module('app.pages.phys.brief', [
    uirouter, tour, api, notify
])
.component('physicianBriefComponent', react2angular(PhysicianBriefComponent, ['npis'], ['tour','api', 'notify']))
.config(['$stateProvider', 
function($stateProvider) {
    
    $stateProvider.state({
        name: 'root.app.phy.brief',
        url: '/brief',
        altUrls: ['^/physician/brief/:npis'],
        data: {
            name: 'Physician Brief'    
        },
        component: 'physicianBriefComponent'
        
    });
    
}]).name;

export function PhysicianBriefComponent(props) {
    const npis = useRouteLoaderData('physician.npis')
    const api = useApi();
    const tourManager = useTourManager();
    const logging = useLogging();

    useEffect(() => {
        tourManager.createTour([
            {
                id: 1,
                title: 'Physician Brief - 1/8',
                text: "The purpose of the Physician Brief is to give a quick overview of a physician’s hospital preferences and total shared patient volume. It also provides a quick overview of their Evaluation and Management coding as compared to their peers."
            },{
                id: 2,
                title: 'Physician Brief - 2/8',
                attachTo: { element:'.jumbotron p', on: 'bottom'},
                scrollTo: true,
                text: `Here we see a brief overview of ${npis.name.display}'s practice.`
            },{
                id: 3,
                title: 'Physician Brief - 3/8',
                attachTo: { element: '#physician-brief-summary-panels', on: 'top'},
                scrollTo: true,
                text: `This shows the shared patient volume TO and FROM ${npis.name.display}.`
            },{
                id: 4,
                title: 'Physician Brief - 4/8',
                attachTo: { element: 'div.summary-panels div:nth-child(2) div.panel', on: 'top'},
                scrollTo: true,
                text: `This is how much share to competitors ${npis.name.display} has to other hospitals.`
            },{
                id: 5,
                title: 'Physician Brief - 5/8',
                attachTo: { element: 'div.summary-panels div:nth-child(3) div.panel', on: 'top'},
                scrollTo: true,
                text: `This shows the total amount of charges ${npis.name.display} billed throughout the year.`
            },{
                id: 6,
                title: 'Physician Brief - 6/8',
                attachTo: { element: 'div.summary-panels div:nth-child(4) div.panel', on: 'top' },
                scrollTo: true,
                text: `This shows the procedure code used more than any other by ${npis.name.display}.`
            },{
                id: 7,
                title: 'Physician Brief - 7/8',
                attachTo: { element: '.physician-codinguplift div#newpatients', on: 'top' },
                scrollTo: true,
                text: `The following is a quick overview of ${npis.name.display}'s E&M coding distribution against that of their peers.For new patient office visits (E&M Codes 99201 - 99205) the Physician's coding distribution is shown in orange while other providers of the same taxonomy ${npis.taxonomy.classification} ${npis.taxonomy.specialization ? '('+npis.taxonomy.specialization+')': ''} are shown in blue.`
            },{
                id: 8,
                title: 'Physician Brief - 8/8',
                attachTo: { element: '.physician-codinguplift div#estpatients', on: 'top' },
                scrollTo: true,
                text: `For established patient office visits (E&M codes 99211-99215), the Physician's coding distribution is shown in orange, while other providers of the same taxonomy ${npis.taxonomy.classification} ${npis.taxonomy.specialization ? '('+npis.taxonomy.specialization+')': ''} are shown in blue.`
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: 'root.app.phy.brief'
        });

        return () => {
            tourManager.clearTour();
        };
    }, [npis]);

    return (<>
        <div className="bottom50">
            <div className="jumbotron">
                <SynopsisComponent
                    npis={npis}
                    api={api}
                    notify={props.notify}
                />
            </div>
        </div>

        <div className="bottom50">
            <SummarySharedvisitsComponent
                npis={npis}
                api={api}
                notify={props.notify}
            />
        </div>

        <div className="bottom50" id="physician-brief-summary-panels">
            <SummaryPanelsComponent
                npis={npis}
                api={api}
                notify={props.notify}
            />
        </div>

        {/* <div className="bottom50">
            <CodingupliftComponent
                npis={npis}
                api={api}
                notify={notify}
            />
        </div> */}
        
        <div className="bottom50" >
            <CodingUpliftComponentRevised
                notify={props.notify}
            />
        </div>

    </>);
}