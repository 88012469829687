import * as d3 from 'd3v6';

export function phHistogram() {
  
  let providers = [];
  let seedProvider;
  let communityMembers = [];
  let width = 300;
  let height = 300;
  let bins;
  let xScale;
  let yScale;
  let xAxis;
  let yAxis;
  let xAxisContainer;
  let yAxisContainer;
  let parent;
  let selection;
  let tooltip;
  let rectContainer;
  let legend;
  let legendContainerRect;
  let legendKeyLengths;
  let navToProviderHome;
  let lockTooltip;
  let margin = {
    top: 20,
    right: 20,
    bottom: 30,
    left: 40
  };
  
  function computeFillColor(binDatum) {
    var communityColor = binDatum.filter(datum => communityMembers.indexOf(datum.npi) > -1).length > 0 ? '#E24444' : '';
    var seedColor = binDatum.filter(datum => datum.npi == seedProvider).length > 0 ? '#EDDC3C' : '';
    if (seedColor) {
      return seedColor;
    } else if (communityColor) {
      return communityColor;
    } else {
      return '#1f77b4';
    }
  }
  
  function computeTooltipTextColor(providerDatum) {
    var communityColor = communityMembers.indexOf(providerDatum.npi) > -1 ? '#E24444' : '';
    var seedColor = providerDatum.npi == seedProvider ? '#EDDC3C' : '';
    if (seedColor) {
      return seedColor;
    } else if (communityColor) {
      return communityColor;
    } else {
      return '#1f77b4';
    }
  }
  
  function render(data, selector) {
    if (data) {
      width = data.width || width;
      height = data.height || height;
      providers = data.data || providers || [];
      seedProvider = data.seedProvider || seedProvider;
      communityMembers = data.communityMembers || communityMembers;
      navToProviderHome = data.navToProviderHome || navToProviderHome;
    }
    if (communityMembers.length > 0) {
      communityMembers = communityMembers.map(mem => `${mem}`);
    }
    if (seedProvider) {
      seedProvider = `${seedProvider}`;
    }
    
    // Define the div for the tooltip
    if (!tooltip) {
      tooltip = d3.select("body").append("div")	
        .attr("class", "histogram-tooltip")
        .style('position', 'absolute')
        .style('test-align', 'center')
        .style('padding', '5px')
        .style('font', '12px sans-serif')
        .style('background', 'white')
        .style('border-radius', '5px')
        .style('pointer-events', 'none')
        .style('opacity', 0)
        .style('box-shadow', '0 6px 12px rgb(0 0 0 / 18%)')
        .style('z-index', 1000);
      
      let tooltipHeader = tooltip.append('div');
      
      tooltipHeader.append('button')
        .attr('class', 'close-histogram-tooltip btn btn-default btn-xs')
        .style('position', 'absolute')
        .style('right', '5px')
        .style('top', '5px')
        .style('cursor', 'pointer')
        .on('click', function(event, d){
          tooltip.transition()
            .duration(100)
            .style("opacity", 0)
            .style('pointer-events', 'none');
          lockTooltip = false;
        })
        .text('X');
      
      tooltipHeader.append('h4')
        .attr('class', 'histogram-tooltip-heading')
        .style('margin', '0 0 10px 0');
        
      tooltip.append('ul')
        .style('list-style-type', 'none')
        .style('margin', 0)
        .style('padding', 0)
        .style('max-height', '250px')
        .style('overflow-y', 'scroll');
    }
    
    bins = d3.bin()
      .thresholds(20)
      .value(d => d.procedures)
      (providers);
    
    xScale = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([margin.left, width - margin.right]);
    
    yScale = d3.scaleLinear()
      .domain([0, d3.max(bins, d => d.length)]).nice()
      .range([height - margin.bottom, margin.top]);
      
    xAxis = g => g
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale).ticks(width / 80 ).tickSizeOuter(0))
      .call(g => g.append("text")
        .attr("x", width - margin.right)
        .attr("y", -4)
        .attr("fill", "currentColor")
        .attr("font-weight", "bold")
        .attr("text-anchor", "end")
        .text(data.x));
    
    yAxis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale).ticks(height / 40))
      .call(g => g.select(".domain").remove())
      .call(g => g.select(".tick:last-of-type text").clone()
        .attr("x", 4)
        .attr("text-anchor", "start")
        .attr("font-weight", "bold")
        .text(data.y));
    
    if (!parent) {
      parent = d3.select(selector);
    }
    
    if (!selection) {
      selection = parent.append('svg');
    }
    
    selection
      .attr("viewBox", [0, 0, width, height]);
    
    if (!legend) {
      
      let keys = [
        { color: '#1f77b4', name: 'Provider' },
        { color: '#e24444', name: 'Community Member'},
        { color: '#eddc3c', name: 'Report Provider'}
      ];
      legendKeyLengths = [];
      legend = selection.append('g')
        .attr('class', 'histogram-legend');
        
      legendContainerRect = legend.append('rect')
        .attr('class', 'histogram-container-rect')
        .attr('height', (10 + (keys.length * 20)))
        .style('fill', '#fff')
        .style('stroke', '#333')
        .style('opacity', .8);
      
      legend.selectAll('rect.color')
        .data(keys)
        .join('rect')
          .attr('class', 'color')
          .attr('width', 10)
          .attr('height', 10)
          .attr('x', 10)
          .attr('y', (d,i) => (10 + (i*20)))
          .attr('fill', d => d.color);
      
      legend.selectAll('text.color')
        .data(keys)
        .join('text')
          .attr('class', 'color')
          .attr('x', 30)
          .attr('y', (d,i) => (10 + (i*20)))
          .attr('dy', 10)
          .text(d => d.name);
      
      legend.selectAll('text.color').each(function(){
        legendKeyLengths.push(this.getComputedTextLength());
      });
      
      legendContainerRect
        .attr('width', ((Math.max(...legendKeyLengths)) + 40));

      
    }
    legend.attr('transform', 'translate('+(
      width - (
        (Math.max(...legendKeyLengths))//longest name text length
        + 41
      )//legend rect container width
    )+',1)');
    

    if (!rectContainer) {
      rectContainer = selection.append("g")
        .attr("fill", "#1f77b4");
    }
    
    rectContainer
      .selectAll("rect")
      .data(bins)
      .join("rect")
        .attr("x", d => xScale(d.x0) + 1)
        .attr("width", d => Math.max(0, xScale(d.x1) - xScale(d.x0) - 1))
        .attr("y", d => yScale(d.length))
        .attr("height", d => yScale(0) - yScale(d.length))
        .attr("fill", computeFillColor)
        .on('mouseover', function(event, d) {
          if (!lockTooltip) {
            d3.select(this)
              .attr('stroke', 'black')
              .attr('stroke-width', 2);
              
            tooltip.select('h4')
              .text(`${d['x0']}-${d['x1']} Procedures`);
              
            tooltip
              .select('ul')
              .selectAll('li')
              .data(d.slice().sort((a,b) => a.procedures - b.procedures))
              .join('li')
                .style('color', computeTooltipTextColor)
                .style('cursor', 'pointer')
                .on('click', (e,d) => {
                  navToProviderHome(d.provider.npi);
                })
                .text(d => {
                  return `${d.procedures.toLocaleString()} - ${d.provider.name.display} (${d.npi})`;
                });
            
            tooltip.transition()
              .duration(200)
              .style("opacity", .9);
            tooltip
              .style("left", (event.pageX + 25) + "px")// offset to right by 25
              .style("top", (event.pageY - 125) + "px")
              .style('pointer-events', 'all');
          }
          
        })
        .on('click', function(event, d) {
          lockTooltip = lockTooltip ? false : true;
        })
        .on('mousemove', function(event, d){
          if (!lockTooltip) {
            tooltip
            .style("left", (event.pageX  + 25) + "px")// offset to right by 25
            .style("top", (event.pageY - 125) + "px")
            .style('pointer-events', 'all');
          }
          
        })
        .on('mouseout', function(d) {
          
          d3.select(this)
            .attr('fill', computeFillColor)
            .attr('stroke', '')
            .attr('stroke-width', '');
          
          if (!lockTooltip) {
            
            tooltip.transition()
              .duration(500)
              .style("opacity", 0)
              .style('pointer-events', 'none');
          }
        });
    
    if (!xAxisContainer) {
      xAxisContainer = selection.append("g").attr('class','xAxisContainer');
      xAxisContainer
        .call(xAxis)
        .append('text')
          .attr('fill', 'currentcolor')
          .attr('x', width/2)
          .attr('y', 30)
          .style('text-anchor', 'middle')
          .text('Number of Procedures');
    } else {
      xAxisContainer.remove();
      xAxisContainer = selection.append("g").attr('class','xAxisContainer');
      xAxisContainer
        .call(xAxis)
        .append('text')
          .attr('fill', 'currentcolor')
          .attr('x', width/2)
          .attr('y', 30)
          .style('text-anchor', 'middle')
          .text('Number of Procedures');
    }
    
    if (!yAxisContainer) {
      yAxisContainer = selection.append("g").attr('class','yAxisContainer');
      yAxisContainer
        .call(yAxis)
        .append('text')
          .attr('fill', 'currentcolor')
          .attr('x', -(height/2))
          .attr('dy', -30)
          .attr('transform', 'rotate(-90)')
          .style('text-anchor', 'middle')
          .text('Number of Providers');
    } else {
      yAxisContainer.remove();
      yAxisContainer = selection.append("g").attr('class','yAxisContainer');
      yAxisContainer
        .call(yAxis)
        .append('text')
          .attr('fill', 'currentcolor')
          .attr('x', -(height/2))
          .attr('dy', -30)
          .attr('transform', 'rotate(-90)')
          .style('text-anchor', 'middle')
          .text('Number of Providers');
    }
    
  }
  
  function chart(selector, data) {
    render(data, selector);
  }
  
  chart.providers = function(value) {
    if (!arguments.length) return providers;
    providers = value;
    render({data: providers});
    return chart;
  };
  
  chart.dimensions = function(values) {
    if (!arguments.length) return {width: width, height: height};
    width = values.width ? values.width : width;
    height = values.height ? values.height : height;
    render({
      width: width,
      height: height
    });
    return chart;
  };
  
  chart.destroy = function() {
    
    tooltip.remove();
    tooltip = null;
    
    legend.remove();
    legend = null;
    
    rectContainer.remove();
    rectContainer = null;
    
    xAxisContainer.remove();
    xAxisContainer = null;
    
    yAxisContainer.remove();
    yAxisContainer = null;
    
    selection.remove();
    selection = null;
    
    parent.remove();
    parent = null;
    
    return true;
  };
  
  return chart;
  
}