import angular from 'angular';
import uirouter from '@uirouter/angularjs';
import localStorage from './../services/localStorage';

import { moduleName as sharedVisitsModule, updatedComponentName as sharedVisitsComponent } from '../reports/sharedvisits.provider/sharedvisits';
import { moduleName as specialtyModule, updatedComponentName as specialtyComponent } from '../reports/specialty/specialty';
import { moduleName as organizationModule, updatedComponentName as organizationComponent } from '../reports/organization/organization';
import { moduleName as postAcuteLeakageModule, componentName as postAcuteLeakageComponent } from '../reports/postacuteleakage.organization/postacuteleakage';
import { moduleName as preAcuteLeakageModule, componentName as preAcuteLeakageComponent } from '../reports/preacuteleakage.organization/preacuteleakage';
import { moduleName as performanceReportModule } from '../reports/performanceReport.provider/performanceReport';
import { moduleName as performancefunnelModule } from '../reports/performancefunnel.provider/performancefunnel';
import { moduleName as providerPerformanceModule, updatedComponentName as providerComponent } from '../reports/performance.provider/performance.provider.wrapper';
import { moduleName as summaryModule, updatedComponentName as summaryComponent } from '../reports/summary.provider/summary';
import { moduleName as summaryLeakageReportModule } from '../reports/summaryleakageReport.organization/summaryleakageReport';
import { moduleName as summaryLeakageFlowModule } from '../reports/summaryleakageflow.organization/summaryleakageflow';
import { moduleName as serviceLineSummaryModule, updatedComponentName as serviceLineSummaryComponent } from '../reports/servicelinesummary.provider/servicelinesummary';
import { moduleName as marketShareByZipModule, updatedComponentName as marketShareByZipComponent } from '../reports/marketsharebyzip.organization/marketsharebyzip';

import { moduleName as leakageModule, componentName as leakageComponent } from '../reports/leakage.provider/leakage.provider.wrapper';

import performanceTemplate from './templates/performance.provider.html';
import summaryleakageTemplate from './templates/summaryleakage.organization.html';

export default angular.module('app.pages.orgs.reports', [
    uirouter, localStorage, sharedVisitsModule, specialtyModule, organizationModule, postAcuteLeakageModule, preAcuteLeakageModule, 
    performanceReportModule, performancefunnelModule, summaryModule, summaryLeakageReportModule, summaryLeakageFlowModule, serviceLineSummaryModule,
    marketShareByZipModule, providerPerformanceModule, leakageModule
]).config(['$stateProvider', 'authProvider', 
function($stateProvider, authProvider) {
    
    $stateProvider.state({
        name: 'root.app.org.reports',
        abstract: true,
        template: '<ui-view class="app.org.reports"/>',
        url: ''
    });
    
    $stateProvider.state({    
        name: 'root.app.org.reports.sharedvisits',
        url: '/SharedVisitsReport',
        altUrls: ['^/organization/sharedvisits/:npis', '^/organization/:npis/sharedvisits'],
        component: sharedVisitsComponent,
        data: {
            name: 'Shared Visits'
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.specialty',
        url: '/bySpecialty',
        altUrls: ['^/organization/taxonomy/:npis', '^/organization/specialty/:npis', '^/organization/:npis/specialty'],
        component: specialtyComponent,
        data: {
            name: 'by Specialty'
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.organization',
        url: '/byOrganization',
        altUrls: ['^/organization/organization/:npis', '^/organization/:npis/organization'],
        component: organizationComponent,
        data: {
            name: 'by Organization'    
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.preacuteleakage',
        url: '/preacuteleakage',
        altUrls: ['^/organization/preacuteleakage/:npis'],
        component: preAcuteLeakageComponent,
        data: {
            name: 'Pre-Acute Leakage'    
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.postacuteleakage',
        url: '/postacuteleakage',
        altUrls: ['^/organization/postacuteleakage/:npis'],
        component: postAcuteLeakageComponent,
        data: {
            name: 'Post-Acute Leakage'    
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.performance',
        url: '/services',
        altUrls: ['^/organization/performance_report/:npis', '^/organization/performance/:npis', '^/organization/:npis/performance'],
        template: performanceTemplate,
        controller: ['$scope', 'tour', '$q', function($scope, tour, $q) {
            
            $scope.activeTab = 1;
            
            var reportScript, graphScript;
            
            function setPageScript() {
                
                var finalScript = {
                    config: {}
                };
                
                var midStep = [{
                    target: ".tourscript-tabs",
                    content: "Use the tabs to move between reports."
                }];
                
                if (reportScript) {
                    finalScript.config = angular.extend(finalScript.config, reportScript.config);
                    var reportSteps = reportScript.steps.map(function(step) {
                        var newStep = angular.copy(step);
                        newStep.before = function() {
                            return $q.resolve().then(function() { 
                                $scope.activeTab = 1;
                            }); 
                        };
                        return newStep;
                    });
                }
                
                if (graphScript) {
                    finalScript.config = angular.extend(finalScript.config, graphScript.config);
                    var graphSteps = graphScript.steps.map(function(step) {
                        var newStep = angular.copy(step);
                        newStep.before = function() {
                            return $q.resolve().then(function() { 
                                $scope.activeTab = 2;
                            }); 
                        };
                        return newStep;
                    });
                }
                
                if (reportScript && graphScript) {
                    finalScript.steps = reportSteps.concat(midStep, graphSteps);
                } else if ( reportScript ) {
                    finalScript.steps = reportSteps;   
                } else if ( graphScript ) {
                    finalScript.steps = graphSteps;
                }
                
                tour.setScript(finalScript);
            }
            
            $scope.tour = {
                report: {
                    setScript: function(script) {
                        reportScript = script;
                        setPageScript();
                    }
                },
                graph: {
                    setScript: function(script) {
                        graphScript = script;
                        setPageScript();
                    }
                }
            };
        }],
        // component: providerComponent,
        data: {
            name: 'Services'    
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.summary',
        url: '/PCPSpecialistVolumes',
        altUrls: ['^/organization/summary_report/:npis', '^/organization/summary/:npis', '^/organization/:npis/summary'],
        component: summaryComponent,
        data: {
            name: 'PCP/Specialist Volumes'    
        }
    });

    $stateProvider.state({    
        name: 'root.app.org.reports.summaryleakage',
        url: '/Leakage',
        altUrls: ['^/organization/summary-leakage/:npis', '^/organization/summaryleakage/:npis', '^/organization/:npis/summaryleakage' ],
        // template: summaryleakageTemplate,
        // controller: ['$scope', '$localStorage', '$q', 'tour', function($scope, $localStorage, $q, tour) {
            
        //     $scope.activeTab = 1;
            
        //     $scope.leakage = {
        //         miles: $localStorage.get('leakageReport.miles') || '20'
        //     };
            
        //     $scope.$watch('leakage.miles', function() {
        //         $localStorage.set('leakageReport.miles', $scope.leakage.miles);
        //     });
            
        //     $scope.$watch('activeTab', function(tab) {
        //         if (tab == 2) // Leaflet    
        //             $scope.$broadcast('leaflet.redraw');
        //     });
            
        //     var reportScript, graphScript;
            
        //     function setPageScript() {
                
        //         var finalScript = {
        //             config: {}
        //         };
                
        //         var midStep = [{
        //             target: ".tourscript-tabs",
        //             content: "Use the tabs to move between reports."
        //         }];
                
        //         if (reportScript) {
        //             finalScript.config = angular.extend(finalScript.config, reportScript.config);
        //             var reportSteps = reportScript.steps.map(function(step) {
        //                 var newStep = angular.copy(step);
        //                 newStep.before = function() {
        //                     return $q.resolve().then(function() { 
        //                         $scope.activeTab = 1;
        //                     }); 
        //                 };
        //                 return newStep;
        //             });
        //         }
                
        //         if (graphScript) {
        //             finalScript.config = angular.extend(finalScript.config, graphScript.config);
        //             var graphSteps = graphScript.steps.map(function(step) {
        //                 var newStep = angular.copy(step);
        //                 newStep.before = function() {
        //                     return $q.resolve().then(function() { 
        //                         $scope.activeTab = 2;
        //                     }); 
        //                 };
        //                 return newStep;
        //             });
        //         }
                
        //         if (reportScript && graphScript) {
        //             finalScript.steps = reportSteps.concat(midStep, graphSteps);
        //         } else if ( reportScript ) {
        //             finalScript.steps = reportSteps;   
        //         } else if ( graphScript ) {
        //             finalScript.steps = graphSteps;
        //         }
                
        //         tour.setScript(finalScript);
        //     }
            
        //     $scope.tour = {
        //         report: {
        //             setScript: function(script) {
        //                 reportScript = script;
        //                 setPageScript();
        //             }
        //         },
        //         graph: {
        //             setScript: function(script) {
        //                 graphScript = script;
        //                 setPageScript();
        //             }
        //         }
        //     };
        // }],
        component: leakageComponent,
        data: {
            name: 'Leakage'    
        }
    });
    
    $stateProvider.state({    
        name: 'root.app.org.reports.servicelinesummary',
        url: '/ServiceLine',
        altUrls: ['^/organization/:npis/servicelinesummary'],
        component: serviceLineSummaryComponent,
        data: {
            name: 'Service Line',
            isActive: function() {
                return authProvider.service.loggedIn().then(user => {
                    return user.dataModel().features.indexOf('SERVICE LINE SUMMARY') > -1;
                });
            }
        }
    });
    
    $stateProvider.state({
        name: 'root.app.org.reports.marketsharebyzip',
        url: '/marketsharebyzip',
        component: marketShareByZipComponent,
        data: {
            name: 'Market Share by ZIP'
        }
    });
    
}]).name;