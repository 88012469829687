import angular from 'angular';

import api from '../services/api';
import user from '../services/user';
import pic from '../services/pic';
import recentlySelected from '../services/recentlySelected';

import tableData from '../directives/tableData/tableData';

import uirouter from '@uirouter/angularjs';
import uibModal from 'angular-ui-bootstrap/src/modal';

import modalConfirmationTemplate from './templates/modalConfirmation.html';
import modalEditCommunityNameTemplate from './templates/modalEditCommunityName.html';

export default angular.module('models.community', [
    api, pic, recentlySelected, uirouter, uibModal, user, tableData
]).run(['$q', 'Community', 'api', 'pic', 'notify', '$uibModal', '$state', 'recentlySelected', '$rootScope', '$transitions', 'user',
function($q, Community, api, pic, notify, $uibModal, $state, recentlySelected, $rootScope, $transitions, user) {
    
    var modalInstances = [];
    
    $transitions.onExit({ from: 'root.app.**' }, function() {
        while ( modalInstances.length ) { 
            modalInstances.pop().dismiss('transition');
        } 
    });
    
    Community.prototype.valueOf = function() {
        return this.id || this.name;
    };
    
    Community.prototype.toString = function() {
        return this.name;    
    };
    
    Community.prototype.ResolveMembers = function() {
        var community = this;
        community._npis = community.npis.slice(0);
        return $q.all(
            community.npis.map(function(npi) {
                return api.GetProvider(npi);
            })
        );
    };
    
    Community.prototype.Update = function(obj) {
        var community = this;
        
        if (obj) {
            angular.extend(community, obj);
        }
        community.updating = true;
        
        return api.updateCommunity(community)
        .then(function(updatedCommunity) {
            community.updating = false;

            notify.alert({
                title:  'Community Updated Successfully',
                text: updatedCommunity.name + ' has been successfully updated.',
                type: 'success'
            });
            
            return pic.Update().then(function() {
                $rootScope.$broadcast('recentlySelectedChange', 'community');//used to properly update sidebar menu and breadcrumb menus after modifying community
                return updatedCommunity;
            });
            
        }, function(err) {
            community.updating = false;
            
            notify.alert({
                title:  'Community Not Updated',
                text: 'There was a problem updating ' + community.name + '.',
                type: 'error'
            });
            
            return err;
            
        });
    };
    
    Community.prototype.Copy = function() {
        var community = this;
        var newCommunity = new api.Community(community);
        newCommunity.id = null;
        newCommunity.name = 'Copy of ' + newCommunity.name;
        
        return newCommunity.Update();
    };
    
    Community.prototype.EditName = function() {
        var community = this;
        
        var modalInstance = $uibModal.open({
            template: modalEditCommunityNameTemplate,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                var $modalCtrl = this;

                $scope.editableCommunity = angular.copy(community);
                    
                $modalCtrl.editCommunityName = function() {
                    $scope.makingEdit = true;
                    $scope.editableCommunity.Update()
                    .then(function(updatedCommunity) {
                        $uibModalInstance.close(updatedCommunity);
                    }, function(err) {
                        $uibModalInstance.close();
                        console.log('error', err);
                    });
                };
                
                $modalCtrl.close = $uibModalInstance.dismiss;
            }],
            controllerAs: '$modalCtrl'
        });
        
        modalInstances.push(modalInstance);
        
        return modalInstance.result.then(function(updatedCommunity) {
            var index = modalInstances.indexOf(modalInstance);
            if (index > -1) {
                modalInstances.splice(index, 1);
            }
            return  updatedCommunity;
        });
    };
    
    Community.prototype.Delete = function() {
        var community = this;
        
        var modalInstance = $uibModal.open({
            template: modalConfirmationTemplate,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                var $modalCtrl = this;
                $scope.message = "Are you sure you want to delete " + community.name + '?';
                $modalCtrl.action = function() {
                    $scope.confirming = true;
                    api.deleteCommunity(community)
                    .then(function(res) {
                        $uibModalInstance.close();
                        $scope.confirming = false;
                        pic.Update();
                        recentlySelected.remove(community);
                        $rootScope.$broadcast('recentlySelectedChange', 'community');
                        notify.alert({
                            title:  'Successfully Removed Community',
                            text:  community.name + ' has been removed from your communities.',
                            type: 'success'
                        });
                    }, function(err) {
                        notify.alert({
                            title:  'Could Not Remove Community',
                            text:  'There was a problem removing ' + community.name + '.',
                            type: 'error'
                        });
                        console.log('error', err);
                    });
                };
            }],
            controllerAs: '$modalCtrl',
        });
        
        modalInstances.push(modalInstance);
        
        return modalInstance.result.then(function() {
            var index = modalInstances.indexOf(modalInstance);
            if (index > -1) {
                modalInstances.splice(index, 1);
            }
            return;
        });
    };
    
    Community.prototype.RemoveMember = function(provider) {
        var community = this;
        
        var modalInstance = $uibModal.open({
            template: modalConfirmationTemplate,
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                var $modalCtrl = this;
                $scope.community = community;
                $scope.message = 'Are you sure you want to remove ' + provider.name.display + ' from ' + community.name + '?';
                $modalCtrl.action = function() {
                    $scope.confirming = true;
                    community.npis.splice(community.npis.indexOf(provider.npi), 1);
                    community.Update()
                    .then(function(updatedCommunity) {
                        $uibModalInstance.close(updatedCommunity);
                        $scope.confirming = false;
                    }, function(err) {
                        $uibModalInstance.close();
                        console.log('error', err);
                    });
                };
                
                $modalCtrl.close = $uibModalInstance.dismiss;
            }],
            controllerAs: '$modalCtrl',
        });
        
        modalInstances.push(modalInstance);
            
        return modalInstance.result.then(function(updatedCommunity) {
            var index = modalInstances.indexOf(modalInstance);
            if (index > -1) {
                modalInstances.splice(index, 1);
            }
            return  updatedCommunity;
        });
    };
    
    Community.prototype.type = function() {
        if(this.npis) {
            var ret = $q.all(
                this.npis.map(function(npi) {
                    return api.GetProvider(npi);
                })
            )
            .then(providers => {
                var communityTypes = providers.reduce((acc, provider) => {
                    if (acc.indexOf(provider.entitytype) === -1) {
                        acc.push(provider.entitytype);
                    }
                    return acc;
                },[]);
                if (communityTypes.length === 1) {
                    return communityTypes[0];
                } else if (communityTypes.length === 0) {
                    return '0';
                } else {
                    return '3';
                }
            });
            
            return ret;
        }
    };
    
}]).name;