import React, {useState, useEffect, useRef} from "react";
import { D3TreeMapComponent } from './treemapComponent';
import { useRouteLoaderData } from "react-router-dom";
import { useApi, useConfig, usePic, useTemplate, useTourManager, useLogging } from '../../services/servicesContext';
import "./marketmap.scss";

export function MarketMapComponent(props) {
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const community = useRouteLoaderData('community.cid');

    const api = useApi();
    const config = useConfig();
    const pic = usePic();
    const template = useTemplate();
    const tourManager = useTourManager();
    const logging = useLogging();

    const [ openDropdown, setOpenDropdown ] = useState(null);
    const [ leakageOptVal, setleakageOptVal ] = useState('shared');
    const [ relationsWithCharges, setRelationsWithCharges ] = useState([]);
    const [ activeTab, setActiveTab ] = useState("1");
    const [ originalRelations, setOriginalRelations ] = useState(null);
    const [ splittersData, setSplittersData ] = useState(null);
    const [ allDocsData, setAllDocsData ] = useState(null);
    const [ loyalistsData, setLoyalistsData ] = useState(null);
    const [ toggleLeakageCall, setToggleLeakageCall] = useState(false);
    const drillLevel = useRef();

    const [ loaded, setLoaded ] = useState(false);

    const handleDropdownClick = function(col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
        
    };

    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        sameday: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };

    const leakageOpts = [
        { name: 'Shared Visits', property: 'shared', active: true },
        { name: 'Unique Patients', property: 'unique', active: false },
        { name: 'Same Day Visits', property: 'sameday', active: false }
    ];

    const treeMapStates = [
        ["Market Makers"],
        ["Market Makers"],
        ["Market Makers"]
    ];

    function handleDrillUpdate(val) {
        drillLevel.current = val;
    }

    const toggleLeakageOpt = function(property) {
        setOpenDropdown(null);
        leakageOpts.forEach((option, i) => {
            option.property === property ? option.active = true : option.active = false;
        });
        setToggleLeakageCall(true);
        render(originalRelations, treeMapStates.slice());
        setleakageOptVal(property);
    };

    function generateReport() {
        var activeAxis = leakageOpts.filter(option => option.property === leakageOptVal)[0];
        var titleValueType = `${activeAxis.name.split(" ").join("_")}_`;
        var marketShareHeader = `${activeAxis.name} to ${community ? 'community' : 'organization'}`;
        var leakageHeader = `${activeAxis.name} to other organizations`;
        
        var reportData = relationsWithCharges.map(relation => {
            return {
                NPI: `${relation.source.npi}`,
                'Provider Name': relation.source.toString(),
                Taxonomy: relation.source.taxonomy.type,
                Classification: `${relation.source.taxonomy.classification}${relation.source.taxonomy.specialization ? '' : ' (non-specialists)'}`,
                Specialization: relation.source.taxonomy.specialization || 'None',
                City: relation.source.location.city,
                State: relation.source.location.state,
                'Postal Code': `${relation.source.location.postal_code}`.slice(0,5),
                Telephone: relation.source.location.phone_number,
                [marketShareHeader]: relation.values[activeAxis.property],
                [leakageHeader]: relation.values.leakage[activeAxis.property],
                Share: relation.values[activeAxis.property]/(relation.values[activeAxis.property]+relation.values.leakage[activeAxis.property]),
                'Total Charges': relation['Total Charges']
            };
        }).filter(row => {
            switch (activeTab) {
                case "1":
                    return (row.Share >= .25 && row.Share < .75);
                case "3":
                    return row.Share >= .75;
                case "2":
                default:
                    return true;
            }
        }).sort((a,b) => b.Share - a.Share);
        
        var filter, chartType;

        filter = document.getElementById("grandparent-text").textContent.split(" > ");
        
        if (activeTab === "3") {
            chartType = "Loyalists_";
        } else if (activeTab === "2") {
            chartType = "AllPhysicians_";
        } else if (activeTab === "1") {
            chartType = "Splitters_";
        }
        
        var drillDown = filter.length-1;
        var title = filter[drillDown];
        var nonSpecialists;
        var level;
        
        for (var i = 0; i < filter.length; i++) {
            if (filter[i].indexOf("non-specialists") > -1){
                nonSpecialists = true;
            }
        }
        
        if (drillDown == 0) {
            level = "Market Makers";
        } else if (drillDown == 1) {
            level = "Taxonomy";
        } else if (drillDown == 2) {
            level = "Classification";
        } else if (drillDown == 4 || nonSpecialists) {
            level = "Provider Name";
        } else if (drillDown == 3) {
            level = "Specialization";
        }
        
        var generateReport = [];
        if (title == "Market Makers") {
            generateReport = reportData;
        } else {
            for (var i = 0; i < reportData.length; i++) {
                if (reportData[i][level] == title){
                    generateReport.push(reportData[i]);
                }
            }
        }
        
        title = chartType + titleValueType + filter[drillDown];
        
        var columnOrder = [
            ['NPI', 0],
            ['Provider Name', 1],
            ['Taxonomy', 2],
            ['Classification', 3],
            ['Specialization', 4],
            ['City', 5],
            ['State', 6],
            ['Postal Code', 7],
            ['Telephone', 8],
            [marketShareHeader, 9],
            [leakageHeader, 10],
            ['Share', 11],
            ['Total Charges', 12]
        ];
        
        props.download.downloadCSV(generateReport, title, true, columnOrder);
    };

    const handleActiveTabChange = function(e) {
        let val = e.target.value;
        setActiveTab(val);
    };

    function accumulate(d) {
        if (d.children) {
            return d.leakage = d.children.reduce(function(p, v) { return p + accumulate(v); }, 0);
        }

        if ( d.name.indexOf('to other organizations') == -1 ) {
            d.leakage = d.total - d.value;
            return 0;
        } else { 
            return d.leakage = d.value;
        }
    }
    
    function setParent(datum) {
        if (datum.children) {
            datum.children.forEach(function(child) {
                child.parent = datum;
                setParent(child);
            });
        }
    }

    function buildNestedLookup(relations){
        var lookupObj = relations.reduce((acc, relation) => {
            
            if (!acc[relation.source.taxonomy.type]) {//type not created yet
                acc[relation.source.taxonomy.type] = {};
            }
            
            if (!acc[relation.source.taxonomy.type][relation.source.taxonomy.classification] && relation.source.taxonomy.specialization) {//classification not created yet
                acc[relation.source.taxonomy.type][relation.source.taxonomy.classification] = {};
            }
            
            if (!relation.source.taxonomy.specialization) {
                if (!acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`]) {// non-specialization classifiction not created yet
                    acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`] = {};
                }
            } else {
                if (!acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization]) {// specialization not created yet
                    acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization] = {};
                }
            }
            
            if (!relation.source.taxonomy.specialization) {//add non-specialist
                if (!acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`][relation.source.npi]) {
                    acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`][relation.source.npi] = relation;
                } else {
                    console.log('duplicate provider at tree', relation.source);
                }
            } else {
                if (!acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization][relation.source.npi]) {
                    acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization][relation.source.npi] = relation;
                } else {
                    console.log('duplicate provider at tree', relation.source);
                }
                
            }
            return acc;
        },{});
        return lookupObj;
    }
    
    function getValue(relation, property) {
        return relation.values[property];
    }
    
    function getLeakage(relation, property){
        return relation.values.leakage[property];
    }

    function filterRelationsByLeakageRange(relations, min, max) {
        var valProp = leakageOpts.filter(option => option.active)[0].property;

        return relations.filter(relation => {
            var relationLeakage = getLeakage(relation, valProp) / (getLeakage(relation, valProp) + getValue(relation, valProp));
            return relationLeakage > (min || 0) && relationLeakage < (max || 1);
        });
    }

    function processTree(obj, label) {
        var valProp = leakageOpts.filter(option => option.active)[0].property;

        if (obj instanceof props.api.Relation) {
            return {
                name: obj.source.toString(),
                npi: obj.source.npi,
                leakageOpts: leakageOpts,
                children: [
                    {
                        name: `${obj.source.toString()} to ${obj.target.toString()}`,
                        value: getValue(obj, valProp),
                        url: `/${obj.source.npi}/home`,
                        total: (getValue(obj, valProp) + getLeakage(obj, valProp)),
                        npi: obj.source.npi,
                        leakageOpts: leakageOpts
                    },
                    {
                        name: `${obj.source.toString()} to other organizations`,
                        value: getLeakage(obj, valProp),
                        url: `/${obj.source.npi}/home`,
                        total: (getValue(obj, valProp) + getLeakage(obj, valProp)),
                        npi: obj.source.npi,
                        leakageOpts: leakageOpts
                    }
                ]
            };
        } else {
            return {
                name: label,
                leakageOpts: leakageOpts,
                children: Object.keys(obj).map(key => {
                    return processTree(obj[key], key);
                })
            };
        }
    }

    function adjustRenderDepth(tree, treeMapState, index) {
            
        if (index === undefined) {//undefined on first call
            index = 0;
        }
        
        treeMapState.shift();// remove first level on state array 
        index++;//increment to calculate eventual slice point
        
        if (
            treeMapState.length > 0 &&
            tree[treeMapState[0]]
        ) {//recurse if additional nesting and next state exists
            return adjustRenderDepth(tree[treeMapState[0]], treeMapState, index);
        } else if (
            treeMapState.length > 0 &&
            Object.values(tree).every(item => item instanceof props.api.Relation) &&
            Object.values(tree).filter(relation => relation.source.toString() === treeMapState[0]).length > 0
        ) {//recurse if additional nesting and the next state indicates a provider relation
            return adjustRenderDepth(Object.values(tree).filter(relation => relation.source.toString() === treeMapState[0])[0], treeMapState, index);
        } else {// else return the calulated index for slice point in drilldown
            return index;
        }
        
    }

    function drillToChild(marketTree, treeMapState){
            
        if (!treeMapState || treeMapState.length === 1) {
            return marketTree;
        } else {
            var nameToRemove = treeMapState.shift();
            return drillToChild(marketTree.children.filter(child => {
                return child.name === treeMapState[0];
            })[0], treeMapState);
        }
    }

    function render(relations, treeMapStates) {
            
        var relationsCopy = relations.slice();
        
        var splittersRelations = filterRelationsByLeakageRange(relationsCopy, .25, .75);
        var loyalistRelations = filterRelationsByLeakageRange(relationsCopy, 0, .25);
        
        var allDocsTree = buildNestedLookup(relationsCopy);
        var splittersTree = buildNestedLookup(splittersRelations);
        var loyalistsTree = buildNestedLookup(loyalistRelations);
        
        var completeAllDocs = processTree(allDocsTree, 'Market Makers');
        var completeSplitters = processTree(splittersTree, 'Market Makers');
        var completeLoyalists = processTree(loyalistsTree, 'Market Makers');
        
        accumulate(completeAllDocs);
        accumulate(completeSplitters);
        accumulate(completeLoyalists);
        
        setParent(completeAllDocs);
        setParent(completeSplitters);
        setParent(completeLoyalists);
        
        if (treeMapStates) {
            
            var splittersDepth = adjustRenderDepth(splittersTree, treeMapStates[0].slice());
            setSplittersData(drillToChild(completeSplitters, treeMapStates[0].slice(0, splittersDepth)));
            
            var allDocsDepth = adjustRenderDepth(allDocsTree, treeMapStates[1].slice());
            setAllDocsData(drillToChild(completeAllDocs, treeMapStates[1].slice(0, allDocsDepth)));
            
            var loyalistsDepth = adjustRenderDepth(allDocsTree, treeMapStates[2].slice());
            setLoyalistsData(drillToChild(completeLoyalists, treeMapStates[2].slice(0, loyalistsDepth)));
            
        } else {
            setSplittersData(completeSplitters);
            setAllDocsData(completeAllDocs);
            setLoyalistsData(completeLoyalists);
        }
        
    }

    async function getData() {
        var relationsRequest;
        var originalRelationsTemp;
        var relationsWithChargesTemp;
        setLoaded(false);
        setActiveTab("1");
        setleakageOptVal('shared');
        try {
            if (community) {
                relationsRequest = await props.api.CommunityRelations(community.id, 'inbound', {leakage: 'all', entitytype: '2'});
            }
            else {
                relationsRequest = await props.api.Relations(npis.npi, 'inbound', { leakage: 'all' });
            }
        } catch (err) {
            console.log('Error fetching Marketmap data: ', err);
            setLoaded(true);
            props.notify.error({text: 'Error fetching Marketmap data.', delay: 30000 });
        }
        

        var taxonomyCodeExclusions = config.marketMapTaxonomyCodes();
        relationsRequest = relationsRequest.filter(relation => taxonomyCodeExclusions.indexOf(relation.source.taxonomy.code) === -1);//filter out all excluded taxonomy codes
        relationsRequest = relationsRequest.filter(relation => {
            return (
                taxonomyCodeExclusions.indexOf(relation.source.taxonomy.code) === -1 //filter out all excluded taxonomy codes
                &&
                relation.source.entitytype == '1'//filter out orgs (entitytype 2)
            );
        });

        if (community) {
            var providersHash = relationsRequest.reduce((hash, relation) => {
                if (!hash[relation.source.npi]) {
                    hash[relation.source.npi] = new props.api.Relation({
                        source: relation.source,
                        target: community,
                        values: {
                            leakage: {
                                sameday: relation.values.leakage.sameday,
                                shared: relation.values.leakage.shared,
                                unique: relation.values.leakage.unique
                            },
                            sameday: relation.values.sameday,
                            shared: relation.values.shared,
                            unique: relation.values.unique
                        },
                        relations: [relation]
                    });
                } else {
                    hash[relation.source.npi].values.sameday += relation.values.sameday;
                    hash[relation.source.npi].values.shared += relation.values.shared;
                    hash[relation.source.npi].values.unique += relation.values.unique;
                    hash[relation.source.npi].relations.push(relation);
                }
                return hash;
            }, {});
            relationsRequest = Object.values(providersHash);
        }

        render(relationsRequest);

        originalRelationsTemp = relationsRequest;

        const relPromises = Promise.all(originalRelationsTemp.map(relation => props.api.Charges(`${relation.source.npi}`)))
        setLoaded(true);
        await relPromises
        .then(function(res) {

            var totalChargesLookup = res.reduce((lookUpObj, nodeCode) => {
                
                if (nodeCode._failed) {
                    return lookUpObj;
                }
                    
                if (!lookUpObj[nodeCode.npi]) {
                    lookUpObj[nodeCode.npi] = 0;
                }
                    
                lookUpObj[nodeCode.npi] += nodeCode.totalCharges;
                    
                return lookUpObj;
            }, {});

            relationsWithChargesTemp = originalRelationsTemp.reduce((acc, relation) => {
                if (relation.source.entitytype == '1' && totalChargesLookup[relation.source.npi]) {// only add relation if physician entitytype and total charges found
                    var updatedRelation = Object.create(relation);
                    updatedRelation['Total Charges'] = totalChargesLookup[relation.source.npi];
                    acc.push(updatedRelation);
                }
                return acc;
            }, []);

            setOriginalRelations(originalRelationsTemp);
            setRelationsWithCharges(relationsWithChargesTemp);
        });

    };

    function removeAllChildNodes(parent) {
        while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
        }
    }

    useEffect(() => {

        if (npis) {// org marketmap tour and logging
            tourManager.createTour([{
                id: 1,
                title: 'Market Makers - 1/12',
                text: "This is the Organization Market Makers Report"
            },{
                id: 2,
                title: 'Market Makers - 2/12',
                attachTo: { element: 'organization-marketmap', on: 'left' },
                text: "The purpose of this report is to quickly visualize providers' patient volumes and their loyalty to the selected facility."
            },{
                id: 3,
                title: 'Market Makers - 3/12',
                attachTo: { element: '#legend', on: 'top' },
                scrollTo: true,
                text: "The boxes are sized by patient volumes, and colored by market share via they legend below."
            },{
                id: 4,
                title: 'Market Makers - 4/12',
                attachTo: { element: '.toggle_radio', on: 'bottom' },
                scrollTo: true,
                text: "You can navigate between groups of providers based on loyalty..."
            },{
                id: 5,
                title: 'Market Makers - 5/12',
                attachTo: { element: 'label[for="first_toggle"]', on: 'bottom' },
                text: "Splitters (25%-75% market share),"
            },{
                id: 6,
                title: 'Market Makers - 6/12',
                attachTo: { element: 'label[for="third_toggle"]', on: 'bottom' },
                text: "Loyalists (75%+ market share),"
            },{
                id: 7,
                title: 'Market Makers - 7/12',
                attachTo: { element: 'label[for="second_toggle"]', on: 'bottom' },
                text: "and all physicians."
            },{
                id: 8,
                title: 'Market Makers - 8/12',
                attachTo: { element: '#graphContainer #treemap svg g g g.children rect.parent', on: 'bottom' },
                text: "You can drill into provider types by clicking the appropriate box, a hover over will give you details on that group's volumes/market share.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var tabToClick = document.querySelector('label[for="first_toggle"]');
                        var clickEvent = new Event('click');
                        tabToClick.dispatchEvent(clickEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                        
                    }).then(() => {
                        var elem = document.querySelector('#graphContainer #treemap svg g g g.children rect.parent');
                        var moveEvent = new Event('mousemove');
                        
                        var rectCalc = elem.getBoundingClientRect();
                            
                        moveEvent.pageX = (rectCalc.width/2) + rectCalc.left;
                        moveEvent.pageY = (rectCalc.height/2) + rectCalc.top;
                        
                        elem.dispatchEvent(moveEvent);
                        
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        var elem = document.querySelector('treemap#splittersChart svg g g g.children rect.parent');
                        var moveEvent = new Event('mouseleave');
                        elem.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                } */
            },{
                id: 9,
                title: 'Market Makers - 9/12',
                attachTo: { element: '#graphContainer #treemap svg g g.grandparent rect', on: 'bottom' },
                text: "You can use the taxonomy tree to drill down into specialized provider types, you can also go backwards by clicking up a level on the grey navigation bar on the top.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        var elem = document.querySelector('#graphContainer #treemap svg g g g.children');
                        var moveEvent = new Event('click');
                        
                        elem.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        var elem = document.querySelector('treemap#splittersChart svg g g.grandparent');
                        var clickEvent = new Event('click');
                        
                        elem.dispatchEvent(clickEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                } */
            },{
                id: 10,
                title: 'Market Makers - 10/12',
                attachTo: { element: '.provider-axis-dropdown', on: 'bottom' },
                text: "The graph defaults to using Shared Visits as the default measure of patient volumes..."
            },{
                id: 11,
                title: 'Market Makers - 11/12',
                attachTo: { element: '.provider-axis-dropdown ul.dropdown-menu', on: 'bottom' },
                text: "...but you can change to Unique Patients or Same Day Visits by using this drop down.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('button#values-axis');
                        elementToClick.click();
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('organization-marketmap div#marketmap ul.custom-tab-set div.pull-right span[uib-dropdown] button[uib-dropdown-toggle]');
                        var clickEvent = new Event('click');
                        setTimeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            setTimeout(()=>{
                                resolve();
                            });
                        });
                    });
                } */
            },{
                id: 12,
                title: 'Market Makers - 12/12',
                attachTo: { element: 'button#report', on: 'bottom'},
                text: "You can also export a report showing the providers' information as well as volumes and market share to excel by clicking the \"Generate Report\" button"
            }]);

            logging.routeLoad({
                pathname: location.pathname,
                npis: [npis.npi],
                statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.graphs.marketmap`
            });
        }

        if (community) {// comm marketmap tour and logging
            tourManager.createTour([{
                id: 1,
                title: 'Market Makers - 1/13',
                text: "This is the Community Market Makers Report"
            },{
                id: 2,
                title: 'Market Makers - 2/13',
                text: "This report functions very similarly to the Organization Market Makers Report. However there are a few caveats. The community must consist only of organizations (it cannot be a physician-only or hybrid community). The graph will compare the market share of the providers to all other types of organizations in your community. For example if there are hospitals and clinics included, the graph will calculate that provider's volumes to all other hospitals and clinics against their volumes to the hospitals and clinics included in the community."
            },{
                id: 3,
                title: 'Market Makers - 3/13',
                attachTo: {element: '#treemap', on: 'left' },
                text: "The purpose of this report is to quickly visualize providers' patient volumes and their loyalty to the facilities in the selected community."
            },{
                id: 4,
                title: 'Market Makers - 4/13',
                scrollTo: true,
                attachTo: { element: '#legend', on: 'top' },
                text: "The boxes are sized by patient volumes, and colored by market share via they legend below."
            },{
                id: 5,
                title: 'Market Makers - 5/13',
                attachTo: { element: '.toggle_radio', on: 'bottom' },
                scrollTo: true,
                text: "You can navigate between groups of providers based on loyalty..."
            },{
                id: 6,
                title: 'Market Makers - 6/13',
                attachTo: { element: 'label[for="first_toggle"]', on: 'bottom' },
                text: "Splitters (25%-75% market share),"
            },{
                id: 7,
                title: 'Market Makers - 7/13',
                attachTo: { element: 'label[for="third_toggle"]', on: 'bottom' },
                text: "Loyalists (75%+ market share),"
            },{
                id: 8,
                title: 'Market Makers - 8/13',
                attachTo: { element: 'label[for="second_toggle"]', on: 'bottom' },
                text: "and all physicians."
            },{
                id: 9,
                title: 'Market Makers - 9/13',
                attachTo: { element: '#graphContainer #treemap svg g g g.children rect.parent', on: 'bottom' },
                text: "You can drill into provider types by clicking the appropriate box, a hover over will give you details on that group's volumes/market share.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var tabToClick = document.querySelector('label[for="first_toggle"]');
                        var clickEvent = new Event('click');
                        tabToClick.dispatchEvent(clickEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                        
                    }).then(() => {
                        var elem = document.querySelector('#graphContainer #treemap svg g g g.children rect.parent');
                        var moveEvent = new Event('mousemove');
                        
                        var rectCalc = elem.getBoundingClientRect();
                            
                        moveEvent.pageX = (rectCalc.width/2) + rectCalc.left;
                        moveEvent.pageY = (rectCalc.height/2) + rectCalc.top;
                        
                        elem.dispatchEvent(moveEvent);
                        
                    });
                },
                // after: function() {
                //     return new Promise((resolve, reject) => {
                //         var elem = document.querySelector('treemap#splittersChart svg g g g.children rect.parent');
                //         var moveEvent = new Event('mouseleave');
                //         elem.dispatchEvent(moveEvent);
                        
                //         $timeout(()=>{
                //             resolve();
                //         });
                //     });
                // }
            },{
                id: 10,
                title: 'Market Makers - 10/13',
                attachTo: { element: '#graphContainer #treemap svg g g.grandparent rect', on: 'bottom' },
                text: "You can use the taxonomy tree to drill down into specialized provider types, you can also go backwards by clicking up a level on the grey navigation bar on the top.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        var elem = document.querySelector('#graphContainer #treemap svg g g g.children');
                        var clickEvent = new Event('click');
                        
                        elem.dispatchEvent(clickEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
                // after: function() {
                //     return new Promise((resolve, reject) => {
                //         var elem = document.querySelector('treemap#splittersChart svg g g.grandparent');
                //         var clickEvent = new Event('click');
                        
                //         elem.dispatchEvent(clickEvent);
                        
                //         $timeout(()=>{
                //             resolve();
                //         });
                //     });
                // }
            },{
                id: 11,
                title: 'Market Makers - 11/13',
                attachTo: { element: '.provider-axis-dropdown', on: 'bottom' },
                text: "The graph defaults to using Shared Visits as the default measure of patient volumes..."
            },{
                id: 12,
                title: 'Market Makers - 12/13',
                attachTo: { element: '.provider-axis-dropdown ul.dropdown-menu', on: 'bottom' },
                text: "...but you can change to Unique Patients or Same Day Visits by using this drop down.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('button#values-axis');
                        elementToClick.click();
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
                // after: function() {
                //     return new Promise((resolve, reject) => {
                //         var elementToClick = document.querySelector('organization-marketmap div#marketmap ul.custom-tab-set div.pull-right span[uib-dropdown] button[uib-dropdown-toggle]');
                //         var clickEvent = new Event('click');
                //         $timeout(()=>{
                //             elementToClick.dispatchEvent(clickEvent);
                //             $timeout(()=>{
                //                 resolve();
                //             });
                //         });
                //     });
                // }
            },{
                id: 13,
                title: 'Market Makers - 13/13',
                attachTo: { element: 'button#report', on: 'bottom' },
                text: "You can also export a report showing the providers' information as well as volumes and market share to excel by clicking the \"Generate Report\" button"
            }]);

            logging.routeLoad({
                pathname: location.pathname,
                npis: [],
                statename: `root.app.com.graphs.marketmap`
            });
        }

        getData();
        
        return () => {
            tourManager.clearTour();
        };
        
    }, [npis, community]);

    return (
    <>
    { loaded ? <div id="marketmap-container">
        <div id="marketmap">
        <div className="col-sm-8">
            <div className="toggle_radio tourscript-input-type">
                <input type="radio" className="toggle_option" id="first_toggle" name="first_toggle" value="1" checked={activeTab === "1"} onChange={(e) => handleActiveTabChange(e)}/>
                <input type="radio" className="toggle_option" id="second_toggle" name="second_toggle" value="2" checked={activeTab === "2"} onChange={(e) => handleActiveTabChange(e)}/>
                <input type="radio" className="toggle_option" id="third_toggle" name="third_toggle" value="3" checked={activeTab === "3"} onChange={(e) => handleActiveTabChange(e)}/>
                <label htmlFor="first_toggle"><p>Splitters</p></label>
                <label htmlFor="second_toggle"><p>All Physicians</p></label>
                <label htmlFor="third_toggle"><p>Loyalists</p></label>
                <div className="toggle_option_slider"></div>
            </div>
        </div>
            <div className="col-sm-4 secondary-button-container" style={{padding:0}}>
                            <div className={`provider-axis-dropdown col-md-6 axis-toggle ${ openDropdown === 'axis' ? 'open' : ''}`} style={{padding:0}}>
                                <button id="values-axis" type="button" onClick={() => {handleDropdownClick('axis')} } className="btn btn-default btn-md btn-block">{ mapping[leakageOptVal].units } <span className="caret"></span></button>
                                <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis">
                                    <li className="radio">
                                        <label>
                                            <input type="radio" checked={leakageOptVal === 'shared'} value="shared" onChange={() => {toggleLeakageOpt('shared')}}></input>
                                            { mapping.shared.units }
                                        </label>
                                    </li>
                                    <li className="radio">
                                        <label>
                                            <input type="radio" checked={leakageOptVal === 'sameday'} value="sameday" onChange={() => {toggleLeakageOpt('sameday')}}></input>
                                            { mapping.sameday.units }
                                        </label>
                                    </li>
                                    <li className="radio">
                                        <label>
                                            <input type="radio" checked={leakageOptVal === 'unique'} value="unique" onChange={() => {toggleLeakageOpt('unique')}}></input>
                                            { mapping.unique.units }
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        <div className="col-md-6 reset" style={{padding:0}}>
                            <button id="report" type="button"  className="btn btn-default btn-med" onClick={() => generateReport()} disabled={relationsWithCharges.length === 0}>Generate Report</button>
                        </div>
            </div>
        </div>
        <div id="graphContainer" className="graph-container">
            {splittersData && activeTab === "1" && leakageOptVal === "shared" ? <D3TreeMapComponent data={splittersData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
            {splittersData && activeTab === "1" && leakageOptVal === "sameday" ? <D3TreeMapComponent data={splittersData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
            {splittersData && activeTab === "1" && leakageOptVal === "unique" ? <D3TreeMapComponent data={splittersData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}

            {allDocsData && activeTab === "2" && leakageOptVal === "shared" ? <D3TreeMapComponent data={allDocsData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
            {allDocsData && activeTab === "2" && leakageOptVal === "sameday" ? <D3TreeMapComponent data={allDocsData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
            {allDocsData && activeTab === "2" && leakageOptVal === "unique" ? <D3TreeMapComponent data={allDocsData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}

            {loyalistsData && activeTab === "3" && leakageOptVal === "shared" ? <D3TreeMapComponent data={loyalistsData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
            {loyalistsData && activeTab === "3" && leakageOptVal === "sameday" ? <D3TreeMapComponent data={loyalistsData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
            {loyalistsData && activeTab === "3" && leakageOptVal === "unique" ? <D3TreeMapComponent data={loyalistsData} npis={npis} community={community} opts="{root: { title: 'Market Makers' } }" drillLevel={drillLevel} handleDrillUpdate={handleDrillUpdate}/> : ""}
        </div>
    </div>
    : <div className="loading-lg"></div>}
    </>
    )
}