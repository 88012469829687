import React, { useEffect, useState } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails } from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu } from '../../components/popoverMenu/popoverMenu';
export default function SharedCommunities({provider, api, affiliations, pic, CommunityList, notify }) {
    const [ myCommunities, setCommunities ] = useState('');
    const [ sharedCommunities, setsharedCommunities ] = useState([]);
    const [ sharing, updateSharing] = useState(null);
    const [ watching, updateWatching] = useState(null);
    const [ copying, updateCopying] = useState(null);
    const [ originalCommunities, setoriginalCommunities ] = useState({});

    useEffect(() => {
        if (pic.ready) {
            updateList();
            updateCommunityList([provider]);
        }
        
    }, [pic.ready, provider])

    const reportConfigSharedCommunities = function() { 
            return {  
            tableConfig :  [
                    {
                    "header": {
                        "id": "col1b",
                        "accessor": "name",
                        "content": "Name (Abbreviation)",
                        "defaultVisibilty": true,
                        "export": false,
                        "template": function(content, rowObject) { 
                            if (!rowObject) return
                            const name_popover = {
                                providerCollection: 
                                    { name :  rowObject.name || '' , insert_date : rowObject.insert_date || "", insert_user: rowObject.insert_user || rowObject.owner || "", npis : rowObject.npis || '' }
                            }
                            return <>
                            <PopoverDetails {...name_popover}> <Link to={`/community/${rowObject.id}/home`}>{content} ({rowObject.abbr})</Link></PopoverDetails> 
                            <PopoverMenu providerCollection={rowObject}>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span></PopoverMenu>
                            </>
                        },
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 1
                        }
                    },
                    {
                        "header": {
                            "id": "col2b",
                            "accessor": "owner",
                            "content": "Owned By",
                            "defaultVisibilty": true,
                            "export": false,
                            "template": function(content) {
                                return content;
                            }
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 2
                            }
                    },
                    {
                        "header": {
                            "id": "col3b",
                            "accessor": "copy",
                            "content": "Copy",
                            "defaultVisibilty": true,
                            "export": false,
                            "headerTooltip": "Copy this community to 'My Communities'.",
                            "template": function(content, community) { return (<>
                                <button className="btn btn-default btn-sm" onClick={() => copy(originalCommunities[community.id])}>
                                <span style={{ 'display' : (copying == community.id) ? 'none' : 'inline' }}><i className="fa fa-files-o fa-lg" aria-hidden="true"></i></span>
                                <span style={{ 'display' : (copying == community.id) ? 'inline' : 'none' }}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
                                </button>
                            </>) }
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 3
                            }
                    },
                    {
                        "header": {
                            "id": "col4b",
                            "accessor": "watched",
                            "content": "Watch",
                            "defaultVisibilty": true,
                            "export": false,
                            "headerTooltip": "Identify members of this community in vizualizatons and reports.",
                            "template": function(content, community) { return (
                                <button className={`btn btn-default btn-sm ${(community.watched) ? 'select-true' : '' }`} onClick={() => watch(originalCommunities[community.id])}>
                                    <span style={{ 'display' : (watching == community.id) ? 'none' : 'inline' }}><i className="fa fa-eye-slash fa-lg" aria-hidden="true"></i></span>
                                    <span style={{ 'display' : (watching == community.id) ? 'inline' : 'none' }}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
                                    <span>{community.watched}</span>
                                </button>
                        )}
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 4
                            }
                    }
                ],
            configure : false,
            showSelected: false,
            filterInput: true,
            csvdownload: false
            }
    }

    function getformattedCommunities() {
        const Provider = provider;
        var communities = pic(Provider.npi, true).map(function(community) {
            originalCommunities[community.id] = community;
            return {
                'name': community.name,
                'abbr': community.abbr,
                'id': community.id,
                'owner': community.insert_user || community.owner,
                'is_public': community.is_public,
                'watched': community.watched,
                'owned': community.owned,
                'insert_date': community.insert_date ? new Date(community.insert_date).toDateString() : null,
                'npis': community.npis || null
            };
        });
        return communities;
    }

    function getMyCommunities() {
        const myCommunities = getformattedCommunities().filter(community  => community.owned );
        setCommunities(myCommunities);
        return myCommunities;
    }

    function getSharedCommunities() {
        const shared = getformattedCommunities().filter(community => community.is_public );
        setsharedCommunities(shared);
    }

    function updateList() {
        getMyCommunities();
        getSharedCommunities();
    }

    const copy = function(community) {
        updateCopying(community.id);
        community.Copy({api, notify, pic})
        .then(function() {
            updateCopying(false);
            updateList();
        });
    };

    const watch = function(community) {
        updateWatching(community.id);
        community.Update({watched: !community.watched}, {api, notify, pic})
        .then(function() {
            updateWatching(null);
            updateList();
        });
    };

    const updateCommunityList = function(affiliationsToUse) {
        // CommunityList.update([...new Set(affiliationsToUse.map(afl => afl.npi).filter(afl => afl))]);
    };

    return (
        (sharedCommunities && sharedCommunities.length > 0)
            ? <PhtableComponent data={sharedCommunities} reportconfig={reportConfigSharedCommunities}></PhtableComponent>
            : <div style={{ 'padding' : '20px'}}>No Results.</div>
    )
}

