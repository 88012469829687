import angular from 'angular';

export default angular.module('localStorage', [])
.factory('$localStorage', ['$window', function($window) {
    return {
        set: function(key, value) {
            $window.localStorage[key] = value;
        },
        get: function(key, defaultValue) {
            return $window.localStorage[key] || defaultValue || false;
        },
        setObj: function(key, value) {
            $window.localStorage[key] = JSON.stringify(value);
        },
        getObj: function(key, defaultValue) {
            if($window.localStorage[key] != undefined) {
                return JSON.parse($window.localStorage[key]);
            } else {
                return defaultValue || false;
            }
        },
        remove: function(key) {
            $window.localStorage.removeItem(key);
        },
        clear: function() {
            $window.localStorage.clear();
        }
    };
}]).name;