import React, { useEffect, useState } from 'react';
import { PhtableComponent } from '../../components/phTable/phtableComponent';

import { useRouteLoaderData } from "react-router-dom";

import {  useTourManager, useLogging } from '../../services/servicesContext';

export function SpecialtyComponent({ api, opts, notify, download }) {
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(null);

    const tourManager = useTourManager();
    const logging = useLogging();

    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const community = useRouteLoaderData('community.cid');

    const entityName = npis ? (npis.name.display || npis.name) : community ? community.name : "";
    
    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'by Specialty - 1/10',
            text: `This report shows total visits, unique patients and same day visits from both a pre- and post-acute viewpoint and sorted by specialty. It helps you visualize from which specialty ${entityName} receives referrals and whether ${entityName} is the last episode of care for these patients.<br><br>Use left/right arrow keys to navigate.`,
        },{
            id: 2,
            title: 'by Specialty - 2/10',
            text: `For example, ${entityName} saw...`,
        },{
            id: 3,
            attachTo: { element: ".tourscript-uniquefrom", on: 'bottom' },
            title: 'by Specialty - 3/10',
            text: "this many pre-acute patients...",
        },{
            id: 4,
            attachTo: { element: ".tourscript-sharedfrom", on: 'bottom' },
            title: 'by Specialty - 4/10',
            text: "which equaled this many pre-acute total vists."
        },{
            id: 5,
            attachTo: { element: ".tourscript-uniqueto", on: 'bottom' },
            title: 'by Specialty - 5/10',
            text: `${ entityName } also saw this many post-acute care patients...`,
        },{
            id: 6,
            attachTo: { element: ".tourscript-sharedto", on: 'bottom'},
            title: 'by Specialty - 6/10',
            text: "which equaled this many post-acute total vists...",
        },{
            id: 7,
            attachTo: { element: ".tourscript-specialty", on: 'bottom'},
            title: 'by Specialty - 7/10',
            text: "from this specialty.",
        },{
            id: 8,
            attachTo: { element: ".content-container thead", on: 'bottom' },
            title: 'by Specialty - 8/10',
            text: "You can sort on any column by simply clicking on the header name.",
        },{
            id: 9,
            attachTo: { element: '.content-container .table-controls-input .filter-box', on: 'bottom' },
            title: 'by Specialty - 9/10',
            text: "Also, you can filter results here by searching for any value in the row.",
        },{
            id: 10,
            attachTo: { element: '.content-container .table-controls-input #tourscript-buttons', on: 'bottom' },
            title: 'by Specialty - 10/10',
            text: "Use this button to download the data in the table.",
        }]);

        if (npis) {
            logging.routeLoad({
                pathname: location.pathname,
                npis: [npis.npi],
                statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.specialty`
            });
        }

        if (community) {
            logging.routeLoad({
                pathname: location.pathname,
                npis: [],
                statename: `root.app.com.reports.specialty`
            });
        }

        let entity = community || npis;                          
        let reportType = community ? 'community' : 'npi';
        buildTable(entity, reportType);
        return () => {
            tourManager.clearTour();
        }
    }, [npis, community])
        
    async function buildTable(entity, reportType) {
            var entityId = entity.npi || entity.id;
            setLoading(true)          
            await fetch('/api/' + reportType + '/' + entityId + '/taxonomy/', api.options())
            .then(res => res.json())
            .then(function(res) {
                if (res.length === 0) {
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Specialty Report Data found for entity.',
                        delay: 30000
                    });
                }

                const data = res.map(function(datum) {
                        return {
                            'taxonomy': datum.classification,
                            'sharedFrom': datum.fromsharedtransactioncount,
                            'uniqueFrom': datum.frompatienttotal,
                            'sameday': datum.samedaytotal,
                            'uniqueTo': datum.topatienttotal,
                            'sharedTo': datum.tosharedtransactioncount,
                            'taxonomyType': datum.taxonomytype,
                            'specialization': datum.specialization
                        };
                });
                setData(data);
                setLoading(false)

            }).catch(err => {
                console.log('Error fetching Speciality report data: ', err);
                setLoading(false);
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Speciality report data.',
                    delay: 30000
                });
            });
        }

  const reportConfig = function() { 
        return {  
          tableConfig :  [
    
                {
                    "header": {
                        "id": "col7",
                        "accessor": "taxonomyType",
                        "content": "Taxonomy Type",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "taxonomy",
                        "content": "Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, taxonomyObject) {  
                            const specialization = taxonomyObject && taxonomyObject.specialization
                            return <span className='tourscript-specialty'>{ content }{(specialization) ? ` (${specialization})` : '' }</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "sharedFrom",
                        "content": "Shared Visits From",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-sharedfrom'>{ content.toLocaleString() }</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                    }
                },   
                {
                    "header": {
                        "id": "col4",
                        "accessor": "uniqueFrom",
                        "content": "Unique Patients From",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-uniquefrom'>{ content.toLocaleString() }</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "sameday",
                        "content": "Same Day Visits",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => content.toLocaleString()
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                   },
                   {
                    "header": {
                        "id": "col2",
                        "accessor": "uniqueTo",
                        "content": "Unique Patients To",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-uniqueto'>{ content.toLocaleString() }</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col1",
                        "accessor": "sharedTo",
                        "content": "Shared Visits To",
                        "defaultVisibilty": true,
                        "sort": 'desc',
                        "export": true,
                        "template": content => <span className='tourscript-sharedto'>{ content.toLocaleString() }</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 1
                        }
                   }
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        reportTitle: `by Speciality for ${entityName}`,
        localStorageName: 'app.phy.reports.specialty'
        }
    }

  

  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download} />  : null}
    </>
  )
}
