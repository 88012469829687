var script = {
    config: {},
    steps: [{
        content: "This report shows where patients end up within 30 days before/after an acute care episode, helping you understand where mutually beneficial relationships with pre- and post-acute care facilities and physicians can be developed.<br><br>Use left/right arrow keys to navigate."
    },{
        target: ".tourscript-name",
        content: "For example, this provider saw..."
    },{
        target: '.tourscript-uniquefrom',
        content: "this many patients..."
    },{
        target: '#tourscript-header',
        content: "after {{provider.name.display}}"
    },{
        target: '.tourscript-sharedfrom',
        content: "which equaled this many total visits..."
    },{
        target: '.tourscript-sameday',
        content: "and this many same day visits."
    },{
        target: '.tourscript-uniqueto',
        content: "And this many patients..."
    },{
        target: '#tourscript-header',
        content: "before {{provider.name.display}}"
    },{
        target: '.tourscript-sharedto',
        content: "which equaled this many total visits..."
    },{
        target: "provider-sharedvisits ph-table thead",
        content: "You can sort on any column by simply clicking on the header name."
    },{
        target: "#tourscript-filter",
        content: "Also, you can filter results here by searching for any value in the row."
    },{
        target: "#tourscript-buttons",
        content: "Use this button to download the data in the table."
    }]
};

export default script;