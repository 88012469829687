import React, { useEffect, useState } from 'react';

import { useRouteLoaderData, Link } from "react-router-dom";

import { PhtableComponent } from '../../components/phTable/phtableComponent';

import { useTourManager, useLogging } from '../../services/servicesContext';

import { PopoverMenu } from '../../components/popoverMenu/popoverMenu';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import eventBus from "../../services/eventBus";

export function OrganizationComponent({ api, opts, notify, pic, download, tour, CommunityList }) {
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const tourManager = useTourManager();
    const logging = useLogging();
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const community = useRouteLoaderData('community.cid');

    const entityName = npis ? (npis.name.display || npis.name) : community ? community.name : "";

    useEffect(() => {

        if (npis) {
            logging.routeLoad({
                pathname: location.pathname,
                npis: [npis.npi],
                statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.organization`
            });
        }
        
        if (community) {
            logging.routeLoad({
                pathname: location.pathname,
                npis: [],
                statename: `root.app.com.reports.organization`
            });
        }

        tourManager.createTour([{
            id: 1,
            title: 'by Organization - 1/11',
            text: "This report shows total visits, unique patients, and same day visits from both a pre- and post-acute viewpoint and filtered by organizations. Organizations include ambulance services, labs, SNF’s, other facilities, and more. This report is meant to help you understand where your patients originate from and end up, allowing you to forge relationships with those organizations.<br><br>Use left/right arrow keys to navigate.",
        },{
            id: 2,
            title: 'by Organization - 2/11',
            text: `For example, ${entityName} saw...`,
        },{
            id: 3,
            attachTo: { element: ".tourscript-uniquefrom", on: 'bottom' },
            title: 'by Organization - 3/11',
            text: "this many pre-acute patients...",
        },{
            id: 4,
            attachTo: { element: ".tourscript-sharedfrom", on: 'bottom'},
            title: 'by Organization - 4/11',
            text: "which equaled this many pre-acute total vists.",
        },{
            id: 5,
            title: 'by Organization - 5/11',
            text: `${entityName} also saw...`,
        },{
            id: 6,
            attachTo: { element: ".tourscript-uniqueto", on: 'bottom' },
            title: 'by Organization - 6/11',
            text: "this many post-acute care patients...",
        },{
            id: 7,
            attachTo: { element: ".tourscript-sharedto", on: 'bottom' },
            title: 'by Organization - 7/11',
            text: "which equaled this many post-acute total vists...",
        },{
            id: 8,
            attachTo: { element: ".tourscript-name", on: 'bottom' },
            title: 'by Organization - 8/11',
            text: "from this facility.",
        },{
            id: 9,
            attachTo: { element: ".content-container thead", on: 'bottom' },
            title: 'by Organization - 9/11',
            text: "You can sort on any column by simply clicking on the header name.",
        },{
            id: 10,
            attachTo: { element: ".content-container .table-controls-input .filter-box", on: 'bottom' },
            title: 'by Organization - 10/10',
            text: "Also, you can filter results here by searching for any value in the row.",
        },{
            id: 11,
            attachTo: { element: ".content-container .table-controls-input #tourscript-buttons", on: 'bottom' },
            title: 'by Organization - 11/11',
            text: "Use this button to download the data in the table.",
        }]);
    
        let entity = community || npis;
        let reportType = community ? 'community' : 'npi';
        getAndFormatData(entity, reportType);
    
        return () => {
            tourManager.clearTour();
        }
    }, [entityName]);
      
    async function getAndFormatData(entity, reportType) {
          
          let entityId = entity.npi || entity.id;
          setLoading(true);
          fetch('/api/'+ reportType + '/' + entityId + '/organization/', api.options())
            .then(res => res.json())
            .then(function(res) {
                if (res.length === 0) {
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Organization report data found for given entity.',
                        delay: 30000
                    });
                } 
              const data = res.map(function(datum) {
                  return {
                      'npi': datum.npi,
                      'communities': pic(datum.npi),
                      'organizationName': datum.businessname,
                      'taxonomy': datum.classification,
                      'sharedFrom': datum.fromsharedtransactioncount,
                      'uniqueFrom': datum.frompatienttotal,
                      'sameday': datum.samedaytotal,
                      'uniqueTo': datum.topatienttotal,
                      'sharedTo': datum.tosharedtransactioncount,
                      'city': datum.city,
                      'state': datum.state,
                      'phone': datum.phonenumber,
                      'zip': datum.postalcode,
                      'entitytype': datum.entitytype
                  };
              });
              
              setData(data);
              setLoading(false);
              npis ? eventBus.dispatch("recentlySelectedChangeProviders") : eventBus.dispatch("recentlySelectedChangeCommunities");

          }).catch(err => {
              console.log('Error fetching Organization Report data: ', err);
              notify.error({
                  title: 'ERROR',
                  text: 'Error fetching Organization Report data.',
                  delay: 30000
              });
          });
    }
 
    const reportConfig = function() { 
      return {  
        tableConfig :  [
                {
                  "header": {
                      "id": "col10",
                      "accessor": "city",
                      "content": "City",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 10
                      }
              },
              {
                  "header": {
                      "id": "col11",
                      "accessor": "state",
                      "content": "State",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 11
                      }
              },
              {
                  "header": {
                      "id": "col12",
                      "accessor": "phone",
                      "content": "Phone",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { 
                          const formatPhoneNum = content && content.toString();
                          return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                    }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 12
                      }
              },
              {
                  "header": {
                      "id": "col13",
                      "accessor": "zip",
                      "content": "ZIP",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 13
                      }
              },
              {
                "header": {
                    "id": "col1",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "tooltip" : true,
                    'tooltipType': 'details',
                    "export": true,
                    "template": function(content, rowDataObject) { 
                        if (!rowDataObject) return
                        const formatPhoneNum = rowDataObject.phone && rowDataObject.phone.toString();
                        const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                        const provider = { 
                            location : {
                              'postal_code' : rowDataObject.zip,
                              'phone_number': phone,
                              'city': rowDataObject.city,
                              'state': rowDataObject.state
                             },
                            'name': {'display' : rowDataObject.organizationName }
                          } 
                        return <PopoverDetails provider={provider}><Link to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>  
                    } 
                },
                "column": {
                    "class": "'text-center'",
                    "content": "link",
                    "style": "",
                    "id": 1                    
                }
            },
            {
                  "header": {
                      "id": "col2",
                      "accessor": "communities",
                      "content": "Communities",
                      "defaultVisibilty": true,
                      "tooltip" : true,
                      "export": true,
                      "template": function(content) {    
                        if (content && content.length === 0) return                   
                        const arrContent = content.map((item) => { 
                            const { name, insert_date, owner, npis } = item;
                            const providerCollection = {
                                name: name || "",
                                insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                insert_user: owner || "",
                                npis: npis || ""
                            }
                            return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                        }) 
                        return arrContent
                   }   
                  },
                  "column": {
                      "class": "'text-center'",
                      "content": "link",
                      "style": "",
                      "id": 2                    
                  }
              },
              {
                "header": {
                    "id": "col3",
                    "accessor": "organizationName",
                    "content": "Organization",
                   "defaultVisibilty": true,
                    "export": true,
                    "template": function(content, rowObject) {
                        let entitytype;
                        if (rowObject.entitytype === 'physician') {entitytype = '1'}
                        if (rowObject.entitytype === 'organization') {entitytype = '2'}
                        const provider = { name : content, npi : rowObject.npi, entitytype };
                        return <> 
                            <PopoverMenu provider={provider}>
                                <span className='tourscript-name'>{content}</span>
                                <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </span>
                            </PopoverMenu>
                        </> 
                    }
                },
                "column": {
                        "class": "'text-center'",
                       "content": {},
                        "style": "",
                        "id": 3
               }
            
        },
              {
                  "header": {
                      "id": "col4",
                      "accessor": "taxonomy",
                      "content": "Specialty",
                      "defaultVisibilty": true,
                      "export": true,
                      "template": function(content) { return <span className='specialty'>{content}</span> }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 4
                 }
              },
              {
                "header": {
                    "id": "col5",
                    "accessor": "sharedFrom",
                    "content": "Shared Visits From",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return <span className='tourscript-sharedfrom'>{content && content.toLocaleString()}</span> }
                },
                "column": {
                        "class": "'text-center'",
                       "content": {},
                        "style": "",
                        "id": 5
               }
            },
              {
                "header": {
                    "id": "col6",
                    "accessor": "uniqueFrom",
                    "content": "Unique Patients From",
                   "defaultVisibilty": true,
                    "export": true,
                   "template": function(content) { return <span className="tourscript-uniquefrom">{content && content.toLocaleString()}</span> }
                },
                "column": {
                        "class": "'text-center'",
                       "content": {},
                        "style": "",
                        "id": 6
               }
            },
              {
                  "header": {
                      "id": "col7",
                      "accessor": "sameday",
                      "content": "Same Day Visits",
                      "defaultVisibilty": true,
                      "export": true,
                      "template": function(content) { return <span className='tourscript-sameday'>{content && content.toLocaleString()}</span> }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 7
                      }
              },
              {
                  "header": {
                      "id": "col8",
                      "accessor": "uniqueTo",
                      "content": "Unique Patients To",
                      "defaultVisibilty": true,
                      "export": true,
                      "template": function(content) {  return <span className='tourscript-uniqueto'>{content && content.toLocaleString()}</span> }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 8
                      }
              },
              {
                "header": {
                    "id": "col9",
                    "accessor": "sharedTo",
                    "content": "Shared Visits To",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return <span className='tourscript-sharedto'>{content && content.toLocaleString()}</span> }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 9
                    }
            }    
        ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        localStorageName: 'app.phy.reports.organization',
        reportTitle: `by Organization for ${npis && npis.name ? npis.name.display : community ? community.name : ""}`
      }
  }

   return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download} needCommUpdate={true} CommunityList={CommunityList}/>  : null}
    </>
  )
}
