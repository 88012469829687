import React, { useEffect, useState } from 'react';
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';

export function SummaryLeakageReportComponent(props) {

    const reportConfig = function() {
        return {
            tableConfig: [
                {
                    "header": {
                        "id": "col1",
                        "accessor": "sourceNpi",
                        "content": "Source NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "template": function(content, rowDataObject) { 
                            if (!rowDataObject) return
                            const formatPhoneNum = rowDataObject.sourcePhone && rowDataObject.sourcePhone.toString();
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject.sourceZip,
                                  'phone_number': phone,
                                  'city': rowDataObject.sourceCity,
                                  'state': rowDataObject.sourceState
                                 },
                                'name': {'display' : rowDataObject.sourceName }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/${rowDataObject.sourceEntityType == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>  
                        } 
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                    
                    }
                },
                {
                    "header": {
                        "id": 'col2',
                        "accessor": 'sourceCommunities',
                        "content": 'Source Communities',
                        "defaultVisibilty": true,
                        "template": content => {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => { 
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    'name': name || "",
                                    'insert_date': insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    'insert_user': owner || "",
                                    'npis': npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent;
                        }
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 2                    
                    }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "sourceName",
                        "content": "Source Name",
                       "defaultVisibilty": true,
                        "export": true,
                        "sortable": false,
                        "template": function(content, rowObject) {
                            const provider = {  name : content, npi : rowObject.sourceNpi, entitytype: rowObject.sourceEntityType };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 3
                   }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "sourceTaxonomy",
                        "content": "Source Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span className='specialty'>{content}</span> }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 4
                   }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "targetNpi",
                        "content": "Target NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "template": function(content, rowDataObject) { 
                            if (!rowDataObject) return
                            const formatPhoneNum = rowDataObject.targetPhone && rowDataObject.targetPhone.toString();
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject.targetZip,
                                  'phone_number': phone,
                                  'city': rowDataObject.targetCity,
                                  'state': rowDataObject.targetState
                                 },
                                'name': {'display' : rowDataObject.targetName }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/${rowDataObject.targetEntityType == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverDetails>  
                        } 
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 5                    
                    }
                },
                {
                    "header": {
                        "id": 'col6',
                        "accessor": 'targetCommunities',
                        "content": 'Target Communities',
                        "defaultVisibilty": true,
                        "template": content => {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => { 
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    'name': name || "",
                                    'insert_date': insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    'insert_user': owner || "",
                                    'npis': npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent;
                        }
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 6                    
                    }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "targetName",
                        "content": "Target Name",
                       "defaultVisibilty": true,
                        "export": true,
                        "sortable": false,
                        "template": function(content, rowObject) {
                            const provider = {  name : content, npi : rowObject.targetNpi, entitytype: rowObject.targetEntityType };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 7
                   }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "targetTaxonomy",
                        "content": "Target Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span className='specialty'>{content}</span> }
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 8
                   }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "visits",
                        "content": "Visits",
                        "sort": "desc",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                  },
                  {
                    "header": {
                        "id": "col10",
                        "accessor": "sourceCity",
                        "content": "Source City",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col11",
                        "accessor": "sourceState",
                        "content": "Source State",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "sourcePhone",
                        "content": "Source Phone",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            const formatPhoneNum = content && content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                      }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col13",
                        "accessor": "sourceZip",
                        "content": "Source ZIP",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col14",
                        "accessor": "sourceTaxonomyType",
                        "content": "Source Taxonomy Type",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                  "header": {
                      "id": "col15",
                      "accessor": "targetCity",
                      "content": "Target City",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 15
                      }
              },
              {
                  "header": {
                      "id": "col16",
                      "accessor": "targetState",
                      "content": "Target State",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 16
                      }
              },
              {
                  "header": {
                      "id": "col17",
                      "accessor": "targetPhone",
                      "content": "Target Phone",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { 
                          const formatPhoneNum = content && content.toString();
                          return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                    }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 17
                      }
              },
              {
                  "header": {
                      "id": "col18",
                      "accessor": "targetZip",
                      "content": "Target ZIP",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 18
                      }
              },
              {
                  "header": {
                      "id": "col19",
                      "accessor": "targetTaxonomyType",
                      "content": "Target Taxonomy Type",
                      "defaultVisibilty": false,
                      "export": false,
                      "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 19
                      }
              }
            ],
            configure : true,
            configureButton: true,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            localStorageName: 'app.phy.reports.summaryleakage.v2',
            reportTitle: `Network Integrity Report for ${props.npis.name.display} (${props.npis.npi})`
        }
    }

    return <>
        { props?.data ? <PhtableComponent
            data={props.data}
            reportconfig={reportConfig}
            download={props.download}
            needCommUpdate={true} 
            CommunityList={props.CommunityList}
        /> :  <div className="loading-lg"></div> }
    </>
}