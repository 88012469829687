import React, {useState, useEffect, useRef} from "react";
import * as d3 from 'd3';
import * as parseD from 'd-path-parser';
import { useRouteLoaderData } from "react-router-dom";
import merge from 'lodash/merge';
import { useApi, usePic, useTemplate, useTourManager, useLogging } from '../../services/servicesContext';

import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';

export function FlowComponent(props) {

    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const community = useRouteLoaderData('community.cid');
    const tourManager = useTourManager();
    const logging = useLogging();
    const api = useApi();
    const pic = usePic();
    const template = useTemplate();
    const [loading, setLoading] = useState(true);
    const [seed, setSeedProvider] = useState(null);
    const [taxonomyFilterControls, setTaxonomyFilterControls] = useState(null);
    const [data, setData] = useState(null);
    const [opts, setOpts] = useState(null);
    const [taxonomyFilterOpen, setTaxonomyFilterOpen] = useState(false);

    const [ axis, setAxis ] = useState('shared');
    const prevAxis = useRef('shared');
    const [ openDropdown, setOpenDropdown ] = useState(null);

    const allStringVal = '-- All Filters Below --';

    const mapping = {
        shared: 'Shared Visits',
        sameday: 'Same Day Visits',
        unique: 'Unique Patients'
    };

    const options = {
        colorLegend: true,
        color: {
            scale:  d3.scaleOrdinal().range(d3.schemeCategory20),//set range here set domain after we have data to get consistent color after filtering
            value: function(d) {
                return this.scale(d);
            }
        },
        node: {
            colorValue: function(d){
                if (d.taxonomy) {// a provider
                    return d.taxonomy.toString();
                } else if (d.name) {// a community
                    return d.name;
                } else {// fallback
                    return '';
                }
            },
            label: function(d){
                if (d.entitytype == 2) {
                    return d.name.display+' ('+d.npi+')';
                } else if (d.entitytype == 1) {
                    return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                } else {// community
                    return d.name;
                }
            }
        },
        link: {
            value: function(d) {
                return d.values[axis];
            },
            valueSet: function(d, val) {
                d.values[axis] = val;
            },
            reversedClass: 'reversed',
            arrows: {
                directedOffset: 20,
                calcArrowHeadPoints: function(d){
                    var startPoint;
                    if (d._reversed) {
                        startPoint = parseD(d.path)[0].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x - options.link.arrows.directedOffset},${startPoint.y} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    } else {
                        startPoint = parseD(d.path)[1].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x + options.link.arrows.directedOffset},${startPoint.y} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    }
                },
                calcArrowTailPoints: function(d){
                    var startPoint;
                    if (d._reversed) {
                        startPoint = parseD(d.path)[1].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x + options.link.arrows.directedOffset},${startPoint.y - (d.width/2)} ${startPoint.x + options.link.arrows.directedOffset},${startPoint.y + (d.width/2)} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    } else {
                        startPoint = parseD(d.path)[0].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x - options.link.arrows.directedOffset},${startPoint.y - (d.width/2)} ${startPoint.x - options.link.arrows.directedOffset},${startPoint.y + (d.width/2)} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    }
                },
                appendArrowHeads: function(selection){
                    return selection.append('polygon')
                        .attr('class', 'link-arrow-head')
                        .attr('points', options.link.arrows.calcArrowHeadPoints);
                },
                appendArrowTails: function(selection){
                    return selection.append('polygon')
                        .attr('class', 'link-arrow-tail')
                        .attr('points', options.link.arrows.calcArrowTailPoints);
                }
            },
            animatedDashes: {
                duration: 5,
                maxOffset: 10,
                percentageOffset: 1,
                sankeyPath: function(link) {
                  let path = '';
                  if (link.circular) {
                    path = link.circularPathData.path;
                  } else {
                    var normalPath = d3.linkHorizontal()
                      .source(function (d) {
                        let x = d.source.x0 + (d.source.x1 - d.source.x0);
                        let y = d.y0;
                        return [x, y];
                      })
                      .target(function (d) {
                        let x = d.target.x0;
                        let y = d.y1;
                        return [x, y];
                      });
                    path = normalPath(link);
                  }
                  return path;
                },
                appendDashes: function (selection) {
                    
                    let dashes = selection.append("path")
                        .attr("d", options.link.animatedDashes.sankeyPath)
                        .style("stroke-width", d => {
                            if (d.width/10 > 10) {
                                return 10;
                            } else if (d.width/10 < 1) {
                                return  1;
                            } else {
                                return d.width/10;
                            }
                            
                        });
                    
                }
            },
            class: function(d){
                if (d._reversed) {
                    return 'reversed';
                } else {
                    return '';
                }
            }
        },
        toolTip: {
            units: 'Shared Visits',
            value: function(d) {
                return d.values.shared;
            },
            sourceName: function(d){
                if (d._reversed) {
                    return d.source_name || d.target.toString();
                } else {
                    return d.source_name || d.source.toString();
                }
            },
            targetName: function(d){
                if (d._reversed) {
                    return d.target_name || d.source.toString();
                } else {
                    return d.target_name || d.target.toString();
                }
            },
            arrow: function(d){
                if (d._reversed) {
                    return `<span>&larr;</span>`;
                } else {
                    return `<span>&rarr;</span>`;
                }
            },
            hasReversedLinks: function(d) {
                if (d) {
                    return (d.sourceLinks.filter(link => link._reversed).length > 0) || (d.targetLinks.filter(link => link._reversed).length > 0);
                } else {
                    return false;
                }
            },
            calcSharedTotal: function(d, sharedVisitType){
                if (sharedVisitType == 'recieves') {
                    const ret = [d.targetLinks, d.sourceLinks].reduce((total, relations) => {
                        total += relations.reduce((relationsTotal, relation) => {
                            if (relation._reversed && (d == relation.source)) {
                                relationsTotal += relation.valueOf();
                            }
                            if (!relation._reversed && (d == relation.target)) {
                                relationsTotal += relation.valueOf();
                            }
                            return relationsTotal;
                        }, 0);
                        return total;
                    }, 0);
                    return ret;
                } else if (sharedVisitType == 'refers') {
                    const ret = [d.targetLinks, d.sourceLinks].reduce((total, relations) => {
                        total += relations.reduce((relationsTotal, relation) => {
                            if (relation._reversed && (d == relation.target)) {
                                relationsTotal += relation.valueOf();
                            }
                            if (!relation._reversed && (d == relation.source)) {
                                relationsTotal += relation.valueOf();
                            }
                            return relationsTotal;
                        }, 0);
                        return total;
                    }, 0);
                    return ret;
                } else {
                    return false;
                }
            }
        }
    };

    const handleDropdownClick = function(col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
        
    };

    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'Flow - 1/7',
                text: `The purpose of the Physician Flow graph is to help quantify the patient flow FROM the ${ npis ? 'Provider': 'Community'} to other providers and BACK to the original ${ npis ? 'Provider': 'Community'}.`,
            },{
                id: 2,
                attachTo: { element: 'g.nodes g.node rect:not([data-side])', on: 'right' },
                title: 'Flow - 2/7',
                text: `The selected ${ npis ? 'Provider': 'Community'} is represented by the color-coded "node" in the  middle of the graph. In this case, we are looking at the flow of patients to and from ${npis?.name?.display || community?.name }.`,
            },{
                id: 3,
                attachTo: { element: 'g.nodes g.node rect[data-side="right"]', on: 'left' },
                title: 'Flow - 3/7',
                text: `Here, you can see that other Providers who have relationships with ${npis?.name?.display || community?.name } are listed on the left and right side.`,
            },{
                id: 4,
                attachTo: { element: 'div.sankeyTooltip', on: 'bottom' },
                title: 'Flow - 4/7',
                text: `Hovering over a left or right side "node" reveals the volume of referrals sent and received. There is no distinction between providers on the left and providers on the right.`,
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        var elementToHover = document.querySelector('g.nodes g.node rect[data-side="right"]').parentElement;
                        var bcr = elementToHover.getBoundingClientRect();
                        
                        var avgX = (bcr.x + bcr.width/2);
                        var avgY = (bcr.y + bcr.height/2);
                        
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = parseInt(avgX.toFixed());
                        moveEvent.pageY = parseInt(avgY.toFixed());
                        elementToHover.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('g.nodes g.node rect[data-side="right"]').parentElement;
                        var moveEvent = new Event('mouseleave');
                        elem.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                } */
            },
            {
                id: 5,
                attachTo: { element: '.content-container ul.dropdown-menu', on: 'bottom' },
                title: 'Flow - 5/7',
                text: "You can filter the nodes by specialty using the taxonomy filter.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('.content-container div.form-inline button');
                        var clickEvent = new Event('click');
                        setTimeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            setTimeout(()=>{
                                resolve();
                            });
                        });
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('flow div.form-inline button');
                        var clickEvent = new Event('click');
                        $timeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            $timeout(()=>{
                                resolve();
                            });
                        });
                    });
                } */
            },
            {
                id: 6,
                attachTo: 'div.sankey-graph svg g.legend rect',
                title: 'Flow - 6/7',
                text: "The legend provides a key for each specialty's designated color. Click and drag to move this key to get a better look at the graph.",
            },
            {
                id: 7,
                attachTo: { element: 'div.sankeyTooltip', on: 'bottom' },
                title: 'Flow - 7/7',
                text: "The dashed lines indicate the directionality of the relationship. Also, patient volumes can be seen by hovering over the line.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elemToHover = document.querySelector('g.link-container.reversed');
                        var elemPath = document.querySelector('g.link-container.reversed path.link');
                        var pathD= parseD(elemPath.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elemToHover.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                        
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elemToHover = document.querySelector('g.link-container.reversed');
                        var moveEvent = new Event('mouseleave');
                        elemToHover.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                } */
            }
        ]);

        if (npis) {
            logging.routeLoad({
                pathname: location.pathname,
                npis: [npis.npi],
                statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.graphs.sankey.flow`
            });
            setSeedProvider(npis);
        } else if (community) {
            logging.routeLoad({
                pathname: location.pathname,
                npis: [],
                statename: `root.app.com.graphs.sankey.flow`
            });
            setSeedProvider(community);
        } else {
            console.error('no provider found', props);
        }

        return () => {
            tourManager.clearTour();
        };

    }, [npis, community]);

    useEffect(() => {
        if (seed) {
            async function init() {
                try {
                    setLoading(true);
                    const taxonomyNameSet = new Set();
                    const relations = await api.Relations(seed.npi || seed.npis.join());
                    const initialTaxonomyFilterControls = relations.reduce(function(hash, relation){
                        
                        var name;
                        
                        if (relation.source == seed || (seed.npis && seed.npis.indexOf(relation.source.valueOf()) > -1)) {//relation.source in seed
                            name = relation.target.taxonomy.toString();
                            if (!hash[name]) {
                                hash[name] = {
                                    relations: [],
                                    active: false,
                                    name: name
                                };
                            }
                        } else {//relation.target in seed
                            name = relation.source.taxonomy.toString();
                            if (!hash[name]) {
                                hash[name] = {
                                    relations: [],
                                    active: false,
                                    name: name
                                };
                            }
                        }
                        taxonomyNameSet.add(name);
                        hash[name].relations.push(relation);
                        hash[allStringVal].relations.push(relation);
                        return hash;
                    },{
                        [allStringVal]: {
                            relations: [],
                            active: true, //all is active on route load
                            name: allStringVal
                        }
                    });
                    options.color.scale.domain([...taxonomyNameSet]);// set domain only once so we avoid changing the color range on refiltering
                    const dataSubsetToRender = buildGraphRelations(relations);
                    setLoading(false);
                    setTaxonomyFilterControls(initialTaxonomyFilterControls);
                    setOpts(options);
                    setData(dataSubsetToRender);
                    
                } catch(err) {
                    setLoading(false);
                    console.error('error in component init', err);
                }
    
            }
            
            init();
        }
    }, [seed]);

    useEffect(() => {

        if (axis !== prevAxis.current) {

            const optsClone = {...opts};
            optsClone.link.value = (d) => {
                return d.values[axis];
            };
            optsClone.link.valueSet = (d, val) => {
                d.values[axis] = val;
            };

            optsClone.toolTip.units = mapping[axis];
            optsClone.toolTip.value = (d) => d.values[axis];

            optsClone.toolTip.calcSharedTotal = function(d, sharedVisitType){
                if (sharedVisitType == 'recieves') {
                    const ret = [d.targetLinks, d.sourceLinks].reduce((total, relations) => {
                        total += relations.reduce((relationsTotal, relation) => {
                            if (relation._reversed && (d == relation.source)) {
                                relationsTotal += relation.values[axis];
                            }
                            if (!relation._reversed && (d == relation.target)) {
                                relationsTotal += relation.values[axis];
                            }
                            return relationsTotal;
                        }, 0);
                        return total;
                    }, 0);
                    return ret;
                } else if (sharedVisitType == 'refers') {
                    const ret = [d.targetLinks, d.sourceLinks].reduce((total, relations) => {
                        total += relations.reduce((relationsTotal, relation) => {
                            if (relation._reversed && (d == relation.target)) {
                                relationsTotal += relation.values[axis];
                            }
                            if (!relation._reversed && (d == relation.source)) {
                                relationsTotal += relation.values[axis];
                            }
                            return relationsTotal;
                        }, 0);
                        return total;
                    }, 0);
                    return ret;
                } else {
                    return false;
                }
            }

            setOpts(optsClone);
            //set prevAxis for next time
            prevAxis.current = axis;
        }

        if (taxonomyFilterControls) {
            let activeRelations = Object.keys(taxonomyFilterControls).reduce((acc, key) => {
                if (taxonomyFilterControls[key].active) {
                    taxonomyFilterControls[key].relations.forEach(relation => {
                        acc.push(relation);
                    });
                }
                return acc;
            },[]);
            setData(buildGraphRelations(activeRelations));
        }
    }, [taxonomyFilterControls, axis]);

    const hasName = function(){
        return function(value){
            return value.name !== undefined;
        };
    };

    const getBgColor = function(specClass){
        if (specClass !== allStringVal && opts?.color?.value(specClass)) {
            return opts.color.value(specClass);
        } else {
            return 'none';
        }
        
    };

    function areNonAllControlsInactive(controlObj) {// returns true if there aren't any active filters other than All
        return Object.keys(controlObj).filter(function(key) {
            return key !== allStringVal && controlObj[key].active === true;
        }).length === 0;
    }

    const toggleFilters = function(classSpecName){
        
        setFilterState(classSpecName);
        
    };

    function setFilterState(classSpecName){
        
        setTaxonomyFilterControls(prevTaxonomyFilterControls => {
            const taxonomyFilterControlsClone = {...prevTaxonomyFilterControls};
            taxonomyFilterControlsClone[classSpecName].active = !taxonomyFilterControlsClone[classSpecName].active;

            if (classSpecName === allStringVal && taxonomyFilterControlsClone[allStringVal].active === true) {
                for (var item in taxonomyFilterControlsClone) {
                    if (item !== allStringVal && taxonomyFilterControlsClone[item].active === true) {
                        taxonomyFilterControlsClone[item].active = false;
                    }
                }
                return taxonomyFilterControlsClone;
            } else if (taxonomyFilterControlsClone[allStringVal].active === true && taxonomyFilterControlsClone[classSpecName].active === true) {
                taxonomyFilterControlsClone[allStringVal].active = false;
                return taxonomyFilterControlsClone;
            } else if (taxonomyFilterControlsClone[allStringVal].active === false && areNonAllControlsInactive(taxonomyFilterControlsClone)) {
                taxonomyFilterControlsClone[allStringVal].active = true;
                return taxonomyFilterControlsClone;
            }
            return taxonomyFilterControlsClone;
        });
    }

    const toggleAxis = function(property) {
        setAxis(property);
        setOpenDropdown(null);
    };

    const resetFilters = function() {
        //reset taxonomies to all
        setTaxonomyFilterControls(prevTaxonomyFilterControls => {
            const taxonomyFilterControlsClone = {...prevTaxonomyFilterControls};

            for (var item in taxonomyFilterControlsClone) {
                if (item === allStringVal) {
                    taxonomyFilterControlsClone[item].active = true;
                } else {
                    taxonomyFilterControlsClone[item].active = false;
                }
            }
            
            return taxonomyFilterControlsClone;
        });
        //reset axis to shared
        toggleAxis('shared');
    };

    function balanceRelations(relations) {
        
        var balancedRelations = relations.reduce((providers, relation) => {
            
            relation.source._outbound = ( relation.source._outbound || 0 ) + options.link.value(relation);
            relation.target._inbound = ( relation.target._inbound || 0 ) + options.link.value(relation);
            
            if (providers.indexOf(relation.source) > -1 && relation.source.valueOf() != seed.valueOf() ) {
                relation.source._relations.push(relation);
            }
            
            if (providers.indexOf(relation.target) > -1 && relation.target.valueOf() != seed.valueOf() ) {
                relation.target._relations.push(relation);
            }
        
            if (providers.indexOf(relation.source) == -1 && relation.source.valueOf() != seed.valueOf() ) {
                relation.source._relations = [relation];
                providers.push(relation.source);
            }
                
            if (providers.indexOf(relation.target) == -1 && relation.target.valueOf() != seed.valueOf() ) {
                relation.target._relations = [relation];
                providers.push(relation.target);
            }
            
            return providers;
        }, []).reduce((sets, provider, index) => {//alternate dumping in left or right side
            if (index % 2 === 0) {
                sets.left.push(provider);
            } else {
                sets.right.push(provider);
            }
            return sets;
        }, {
            left: [],
            right: []
        });
        
        balancedRelations.left.sort((a, b) => {
            return ((a._outbound || 0) + (a._inbound || 0)) < ((b._outbound || 0) + (b._inbound || 0)) ? 1 : -1;
        });
        
        balancedRelations.right.sort((a, b) => {
            return ((a._inbound || 0) + (a._outbound || 0)) < ((b._inbound ||0) + (b._outbound || 0)) ? 1 : -1;
        });
        
        return balancedRelations;
        
    }

    function filterDisplayRelations(providers) {
        var myVisibleProviders = {};
        myVisibleProviders.left = providers.left.reduce((obj, provider) => {
            
            obj.total += provider._outbound ? provider._outbound : ((provider._outbound || 0) + (provider._inbound || 0));
            
            if ( ((provider._outbound || (provider._outbound || 0) + (provider._inbound || 0)) / obj.total) > .05 )
                obj.providers.push(provider);
            
            return obj;
        }, { 
            total: 0.0, 
            providers: []
        }).providers;
        
        myVisibleProviders.right = providers.right.reduce((obj, provider) => {
            
            obj.total +=  provider._inbound ? provider._inbound : ((provider._outbound || 0) + (provider._inbound || 0));
            
            if ( ((provider._inbound || (provider._outbound || 0) + (provider._inbound || 0)) / obj.total) > .05 )
                obj.providers.push(provider);
            
            return obj;
        }, {
            total: 0.0,
            providers: []
        }).providers;
        
        return myVisibleProviders;
    }

    function buildGraphRelations(data) {//data is all qualifying relations before balance and filter
        
        if (!npis && community) {
            
            data = data.reduce((acc, relation) => {
                if (seed.npis.indexOf(relation.source.valueOf()) > -1) {// source is the seed
                    let accKey = `${seed.valueOf()}${relation.target.valueOf()}`;
                    if (!acc[accKey]) {
                        acc[accKey] = new api.Relation({
                            source: seed,
                            target: relation.target,
                            values: {
                                sameday: relation.values.sameday,
                                shared: relation.values.shared,
                                unique: relation.values.unique,
                            },
                            relations: [relation]
                        });
                    } else {
                        acc[accKey].values.sameday += relation.values.sameday;
                        acc[accKey].values.shared += relation.values.shared;
                        acc[accKey].values.unique += relation.values.unique;
                        acc[accKey].relations.push(relation);
                    }
                } else {// target is the seed
                    let accKey = `${relation.source.valueOf()}${seed.valueOf()}`;
                    if (!acc[accKey]) {
                        acc[accKey] = new api.Relation({
                            source: relation.source,
                            target: seed,
                            values: {
                                sameday: relation.values.sameday,
                                shared: relation.values.shared,
                                unique: relation.values.unique,
                            },
                            relations: [relation]
                        });
                    } else {
                        acc[accKey].values.sameday += relation.values.sameday;
                        acc[accKey].values.shared += relation.values.shared;
                        acc[accKey].values.unique += relation.values.unique;
                        acc[accKey].relations.push(relation);
                    }
                }
                return acc;
            },{});
            
            data = Object.keys(data).map(key => data[key]);
            
        }
        
        
        var balancedRelations = balanceRelations(data);
        var balancedAndFilteredRelations = filterDisplayRelations(balancedRelations);
        
        [balancedAndFilteredRelations.left, balancedAndFilteredRelations.right].forEach((side, i) => {
            if (i === 0) {//left
                side.forEach(leftProvider => {
                    leftProvider.side = 'left';
                });
            } else {//right
                 side.forEach(rightProvider => {
                    rightProvider.side = 'right';
                });
            }
        });
        
        var dataSubsetReversed = [balancedAndFilteredRelations.left, balancedAndFilteredRelations.right].reduce((acc, providers, i) => {
            
            var reversedRelations = providers.reduce((relsAcc, provider) => {
                var providerRels = provider._relations.map(relation => {
                    if (i == 0 && relation.source.npi == seed.npi) {
                        return {
                            _reversed: true,
                            source: provider,
                            // target: seed,
                            target: relation.source,
                            __proto__: relation
                        };
                    } else if (i == 1 && relation.target.npi == seed.npi ) {
                        return {
                            _reversed: true,
                            source: relation.target,
                            target: provider,
                            __proto__: relation
                        };
                    } else {
                        return relation;
                    }
                });
                providerRels.forEach(relation => relsAcc.push(relation));
                return relsAcc;
            }, []);
            
            reversedRelations.forEach(relation => acc.push(relation));
            return acc;
            
        }, []);
        
        return dataSubsetReversed;
    
    }

    function numberOfFilters(columnHash){//returns number of active filters
        return Object.keys(columnHash).filter(function(key){
            return columnHash[key].active;
        }).length;
    };
    
    return (<>
        {(data && opts && pic && template && taxonomyFilterControls && !loading)
            ? <div className="sankey-report-container">
                <div style={{flex: 1}}>
                    <div className={taxonomyFilterOpen ? 'form-inline col-sm-8 open' : 'col-sm-8'} style={{padding: 0}}>
                        <button id="single-button" onClick={()=>{setTaxonomyFilterOpen(bool => bool ? false : true)}} type="button" className="btn btn-default btn-md">
                            Taxonomy ({`${numberOfFilters(taxonomyFilterControls)} filter${numberOfFilters(taxonomyFilterControls) > 1 ? 's' : ''} active`}) <span className="caret"></span>
                        </button>
                        { taxonomyFilterOpen ? <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight:'600px',overflow:'auto'}}>
                            {Object.keys(taxonomyFilterControls).sort().map(filterKey => (<li key={filterKey} className="checkbox" style={{display:'block'}}>
                                <label style={{whiteSpace: 'nowrap',display:'block',padding:'0px 20px'}}>
                                    <input type="checkbox" checked={taxonomyFilterControls[filterKey].active} onChange={() => toggleFilters(taxonomyFilterControls[filterKey].name)} ng-model="columnItem.active" ng-change="$ctrl.toggleFilters(columnItem.name)" style={{top:'2px'}}></input>
                                    <div style={{width:'10px',height:'10px',marginLeft: '5px', marginRight: '5px', display:'inline-block', backgroundColor: getBgColor(taxonomyFilterControls[filterKey].name)}} ></div>
                                    {taxonomyFilterControls[filterKey].name} 
                                </label>
                            </li>))}
                        </ul> : null}
                    </div>
                    <div className="col-sm-4" style={{padding:0}}>
                        <div className={`provider-axis-dropdown col-md-6 axis-toggle ${ openDropdown === 'axis' ? 'open' : ''}`} style={{padding:0}}>
                            <button id="values-axis" type="button" onClick={() => {handleDropdownClick('axis')} } className="btn btn-default btn-md btn-block">{ mapping[axis] } <span className="caret"></span></button>
                            <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis" style={{ width: '100%' }}>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => {toggleAxis('shared')}}></input>
                                        { 'Shared Visits' }
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'sameday'} value="sameday" onChange={() => {toggleAxis('sameday')}}></input>
                                        { 'Same Day Visits' }
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => {toggleAxis('unique')}}></input>
                                        { 'Unique Patients' }
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 reset" style={{padding:0}}>
                            <button type="button" onClick={()=> {resetFilters()}} className="btn btn-default btn-md btn-block">Reset</button>
                        </div>
                    </div>
                </div>
                <div style={{flex:19}}>
                    <PhSankeyComponent
                        data={data}
                        opts={opts}
                        pic={pic}
                        template={template}
                    />
                </div>
                
            </div>
            : <div className="loading-lg"></div>
        }
    </>);
};