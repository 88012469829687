import angular from 'angular';
import { react2angular } from 'react2angular';
import './marketbenchmark.scss';
import template from './marketbenchmark.html';
import controller from './marketbenchmark.controller';

import api from '../../services/api';
import download from '../../services/download';
import phHistogram from '../../directives/histogram/histogramDirective';
import phHorizontalBarBraph from '../../directives/horizontalBarGraph/horizontalBarGraphDirective';

import MarketbenchmarkComponent from './marketbenchmarkComponent';

const componentName = 'physicianMarketDashboard';
const moduleName = 'app.reports.dashboards.marketbenchmark.physician';
const updatedComponentName = 'marketbenchmarkComponent';

angular.module(moduleName, [
    api, phHistogram, phHorizontalBarBraph, download
])
.component(
    updatedComponentName,
    react2angular(MarketbenchmarkComponent, ['api', 'npis', 'notify', 'pic', 'codes', 'download'], ['api', '$http', 'notify', 'pic', 'codes', 'download'])
)
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName,
    updatedComponentName
};