import angular from 'angular';
import uirouter from '@uirouter/angularjs';
import CommunityList from '../services/communityList';
import tour from '../services/tour';

import { moduleName as sharedVisitsGroupedModule, updatedComponentName as sharedVisitsComponent} from '../reports/sharedvisits.community/sharedvisits';
import { moduleName as sharedVisitsExpandedModule} from '../reports/sharedvisits.community/sharedvisits';
import { moduleName as specialtyModule, updatedComponentName as specialtyComponent } from '../reports/specialty/specialty';
import { moduleName as organizationModule, componentName as organizationComponent } from '../reports/organization/organization';
import { moduleName as clinicalModule, updatedComponentName as clinicalComponent } from '../reports/clinical.community/clinical';

import sharedVisitsTemplate from './templates/sharedvisits.community.html';

export default angular.module('app.pages.community.reports', [
    uirouter, sharedVisitsGroupedModule, sharedVisitsExpandedModule, specialtyModule, organizationModule, clinicalModule, CommunityList, tour
]).config(['$stateProvider', '$qProvider',
function($stateProvider, $q) {

    $stateProvider.state({
        name: 'root.app.com.reports',
        abstract: true,
        template: '<ui-view class="app.com.reports"/>',
        url: ''
    });
    
    $stateProvider.state({
        name: 'root.app.com.reports.sharedvisits',
        url: '/SharedVisitsReport',
        altUrls: ['^/community/sharedvisits/:cid', '^/community/:cid/sharedvisits' ],
        component: sharedVisitsComponent,
        data: {
            name: 'Shared Visits'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.reports.specialty',
        url: '/bySpecialty',
        altUrls: ['^/community/taxonomy/:cid', '^/community/specialty/:cid', '^/community/:cid/specialty' ],
        component: specialtyComponent,
        data: {
            name: 'by Specialty'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.reports.organization',
        url: '/byOrganization',
        altUrls: ['^/community/organization/:cid', '^/community/:cid/organization' ],
        component: organizationComponent,
        data: {
            name: 'by Organization'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.reports.clinical',
        url: '/ClinicalReport',
        altUrls: ['^/community/clinical/:cid', '^/community/:cid/clinical' ],
        component: clinicalComponent,
        data: {
            name: 'Clinical Report'
        }
    });
    
    function setTourForTwoTabs($scope, tour) {
        var firstTabScript, secondTabScript;
            
        function setPageScript() {
            var finalScript = {
                config: {}
            };
            
            var midStep = [{
                target: ".tourscript-tabs",
                content: "Use the tabs to move between reports."
            }];
            
            if (firstTabScript) {
                finalScript.config = angular.extend(finalScript.config, firstTabScript.config);
                var reportSteps = firstTabScript.steps.map(function(step) {
                    var newStep = angular.copy(step);
                    newStep.before = function() {
                        return new Promise((resolve, reject) => {
                            $scope.currentTab = 0;
                            resolve();
                        }); 
                    };
                    return newStep;
                });
            }
            
            if (secondTabScript) {
                finalScript.config = angular.extend(finalScript.config, secondTabScript.config);
                var graphSteps = secondTabScript.steps.map(function(step) {
                    var newStep = angular.copy(step);
                    newStep.before = function() {
                        return new Promise((resolve, reject) => {
                            $scope.currentTab = 1;
                            resolve();

                        }); 
                    };
                    return newStep;
                });
            }
            
            if (firstTabScript && secondTabScript) {
                finalScript.steps = reportSteps.concat(midStep, graphSteps);
            } else if ( firstTabScript ) {
                finalScript.steps = reportSteps;   
            } else if ( secondTabScript ) {
                finalScript.steps = graphSteps;
            }
            
            tour.setScript(finalScript);
        }
        
        $scope.tour = {
            firstTab: {
                setScript: function(script) {
                    firstTabScript = script;
                    setPageScript();
                }
            },
            secondTab: {
                setScript: function(script) {
                    secondTabScript = script;
                    setPageScript();
                }
            }
        };
    }

}]).name;