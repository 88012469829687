export default class Relation {
    
    constructor (data) {
        Object.assign(this, data);
    }

    valueOf () {
        return this.values.shared;
    }

}