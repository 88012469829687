import '../../js/d3bubble';
import template from '../../services/template';

export default 'd3Bubble';

angular.module('d3Bubble', [template])
.directive('d3Bubble', ['template', '$timeout', function(template, $timeout){
    return {
        scope: {
            data: '=',
            opts: '=',
        },
        link: function(scope, elem, attrs){
            
            elem.d3Bubble(scope.opts);
            
            scope.$watch('opts', function(newObj, oldObj){
                if (newObj) {
                    elem.d3Bubble('options', newObj);
                }
            });
            
            scope.$watchCollection('data', function(newObj, oldObj){
                if (newObj) {
                    elem.d3Bubble('data', newObj);
                }
            });
            
            scope.$watch('opts.back', function(newObj, oldObj){
                if (newObj) {
                    elem.d3Bubble('button', newObj);
                }
            }, true);
            
            scope.$watch('opts.title', function(newObj, oldObj){
                if (newObj) {
                    elem.d3Bubble('title', newObj);
                }
            });
            
            scope.$on('nodeMouseEnter', function(event, data){
                elem.d3Bubble('getNode', data).select('circle').dispatch('mouseenter');
            });
            
            scope.$on('nodeMouseLeave', function(event, data){
                elem.d3Bubble('getNode', data).select('circle').dispatch('mouseleave'); 
            });
            
            scope.$on('nodeMouseClick', function(event, data){
                elem.d3Bubble('getNode', data).select('circle').dispatch('click');
            });
            
            var resizeDisposal = template.contentResize(function(){
                $timeout(function() {
                    elem.d3Bubble('resize');
                }, 300);
            });
            
            scope.$on('$destroy', resizeDisposal);
        }
    };
}]);