import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import template from '../services/template';

import { moduleName as PhysicianDashboardsModule, updatedComponentName as PhysicianDashboardsComponent } from '../reports/dashboardssection/dashboardssection';
import { moduleName as MarketBenchmarkModule, updatedComponentName as MarketBenchmarkComponent } from '../reports/marketbenchmark.physician/marketbenchmark';
import { moduleName as GeographicBenchmarkModule, updatedComponentName as GeographicBenchmarkComponent } from '../reports/geographicbenchmark.physician/geographicbenchmark';
import { moduleName as YearOverYearDashboardModule, componentName as YearOverYearDashboardComponent } from '../reports/yearoveryeardashboard.physician/yearoveryeardashboard';

export default angular.module('app.pages.phys.dashboards', [
    uirouter, template, PhysicianDashboardsModule, MarketBenchmarkModule, GeographicBenchmarkModule, YearOverYearDashboardModule
]).config(['$stateProvider', 'authProvider',
function($stateProvider, authProvider) {
  
  $stateProvider.state({
    name: 'root.app.phy.dashboards',
    abstract: true,
    template: '<ui-view class="app.phy.dashboards"/>',
    url: ''
  });
  
  $stateProvider.state({
    name: 'root.app.phy.dashboards.home',
    url: '/dashboards',
    data: {
      name: 'Physician Volume Benchmarks',
      dashboards: [
        {
          name: 'Volume of Procedures by Geography',
          stateId: 'root.app.phy.dashboards.geobenchmark',
          paramkey: 'NPI',
          description: "Physician benchmarks compare a provider to their peers on both a local, regional, and national scale. Navigate the above tabs to analyze the physician's volumes and relative performance in each of the four code sets listed above"
        },
        {
          name: 'CBSA (Core Based Statistical Area) Market Volumes',
          stateId: 'root.app.phy.dashboards.marketbenchmark',
          paramkey: 'npis',
          description: "Compare a provider to other providers of the same taxonomy in the same CBSA by clinical volume data"
        }
      ]
    },
    component: PhysicianDashboardsComponent
  });
  
  $stateProvider.state({
    name: 'root.app.phy.dashboards.marketbenchmark',
    url: '/marketbenchmarks',
    data: {
      name: 'Benchmarks - CBSA (Core Based Statistical Area) Market Volumes'
    },
    component: MarketBenchmarkComponent
  });

  $stateProvider.state({
    name: 'root.app.phy.dashboards.geobenchmark',
    url: '/geobenchmark',
    data: {
      name: 'Dashboard - Geographic Benchmarks',
      nameSearch: 'geobenchmark',
      hideTTM: true
    },
    component: GeographicBenchmarkComponent
  });
  
  $stateProvider.state({
    name: 'root.app.phy.dashboards.yoy',
    url: '/yoy',
    component: YearOverYearDashboardComponent,
    data: {
      name: 'Dashboard - Year Over Year',
      nameSearch: 'yoy',
      hideTTM: true
    }
  });
    
}]).name;