import React, { useState } from "react";
import { usePopper }  from 'react-popper';
import api from '../../services/api';

import './popoverRelations.scss';

export function PopoverRelations(props) {

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [showPopperElem, setShowPopperElem] = useState(false);
    const [relationsArray, setRelationsArray] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        minWidth: '200px',
        maxWidth: '250px',
        marginBottom: '5px'
    };

    async function relations() {
        try {
            setShowPopperElem(true);
            const relationsArray = { sameday: 0, shared: 0, unique: 0, total: 0, name: name };
            const relations = await props.api.Relations(props.npi);
            relationsArray.total = relations.length;
            relations.forEach((relation) => {
                relationsArray['sameday'] += relation.values.sameday;
                relationsArray['shared'] += relation.values.shared;
                relationsArray['unique'] += relation.values.unique;
            });
            setRelationsArray(relationsArray);
        } catch(err) {
            console.log('error getting relations', err);
        }
    }

    return (<>
        <span
            ref={setReferenceElement}
            onMouseEnter={relations}
            onMouseLeave={() => setShowPopperElem(false)}
        >{ props.children }</span>
        { showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
            <div className="panel-heading">
                <h5 style={{margin:0}}>
                    {props.name}
                </h5>
            </div>
            <ul className="panel-body list-group" style={{padding:0}}>
                <li className="list-group-item" style={{padding:'5px 15px'}}>
                    <p className="small" style={{margin:0, float:'left'}}>
                        <strong>Total Relations :</strong>
                    </p>
                    <p className="small" style={{margin:0, float:'right'}}>
                        { relationsArray && relationsArray.total >= 0 ? relationsArray.total.toLocaleString() : <i className="fa fa-spinner fa-spin fa-fw"></i> }
                    </p>
                    <div style={{clear:'both'}}></div>
                </li>
                <li className="list-group-item" style={{padding:'5px 15px'}}>
                    <p className="small" style={{margin:0, float:'left'}}>
                        <strong>Total Same day Visits :</strong>
                    </p>
                    <p className="small" style={{margin:0, float:'right'}}>
                        {relationsArray && relationsArray.sameday >= 0 ? relationsArray.sameday.toLocaleString() : <i className="fa fa-spinner fa-spin fa-fw"></i> }
                    </p>
                    <div style={{clear:'both'}}></div>
                </li>
                <li className="list-group-item" style={{padding:'5px 15px'}}>
                    <p className="small" style={{margin:0, float:'left'}}>
                        <strong>Total Shared Visit :</strong>
                    </p>
                    <p className="small" style={{margin:0, float:'right'}}>
                        {relationsArray && relationsArray.shared >= 0 ? relationsArray.shared.toLocaleString() : <i className="fa fa-spinner fa-spin fa-fw"></i> }
                    </p>
                    <div style={{clear:'both'}}></div>
                </li>
                <li className="list-group-item" style={{padding:'5px 15px'}}>
                    <p className="small" style={{margin:0, float:'left'}}>
                        <strong>Total Unique Patient :</strong>
                    </p>
                    <p className="small" style={{margin:0, float:'right'}}>
                        {relationsArray && relationsArray.unique >= 0 ? relationsArray.unique.toLocaleString() : <i className="fa fa-spinner fa-spin fa-fw"></i>}
                    </p>
                    <div style={{clear:'both'}}></div>
                </li>
            </ul>
            <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
        </div> : null}
    </>);
}