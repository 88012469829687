import angular from 'angular';
import * as d3 from 'd3v3.1';

export default 'horizontalColorLegend';

angular.module('horizontalColorLegend', [])
.directive('horizontalColorLegend', function() {
    var defaults = {
        title: 'Legend: '
    };
    return {
        restrict: 'E',
        scope: {
            opts: '<opts',
        },
        link: function(scope, elem, attrs) {
            
            var options = angular.merge({}, defaults, scope.opts);
            
            var leakageColorScale = ["#337ab7", "#63a0d4", "#9fc5e5", "#dbe9f5", "#f9e2e2", "#f4cecd", "#e7908e", "#d9534f"];
            var leakageLabels = ['100% - 87.5%', '87.5% - 75%', '75% - 62.5%', '62.5% - 50%', '50% - 37.5%', '37.5% - 25%', '25% - 12.5%', '12.5% - 0'];
            
            var colors = d3.scale.quantize()
            .domain([100, 0])
            .range(leakageColorScale);
            
            elem[0].innerHTML = options.title;

            var legend = d3.select(elem[0])
            .append('div')
            .attr('class', 'row');
        
            var keys = legend.selectAll('div.key')
            .data(colors.range());
        
            keys.enter().append('div')
            .attr('class', 'key col-xs-1')
            .style('border-top-color', String)
            .text(function(d, i) {
                return leakageLabels[i];
            });
        }
    };
});