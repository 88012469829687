import angular from 'angular';

import './marketmap.scss';
import template from './marketmap.html';
import controller from './marketmap.controller';
import treemap from './treemap';
import horizontalColorLegend from './legend';
import api from '../../services/api';
import download from '../../services/download';
import uibModal from 'angular-ui-bootstrap/src/modal';

import { moduleName as generalFlowModule } from '../generalflow.physician/generalflow';

const componentName = 'organizationMarketmap';
const moduleName = 'app.reports.marketmap.organization';

angular.module(moduleName, [
    treemap, api, uibModal, generalFlowModule, horizontalColorLegend, download
])
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName
};