import React, { useState, useEffect, useRef, useReducer } from 'react';
import ReactDom from 'react-dom';

import { PhtableComponent } from '../../components/phTable/phtableComponent';
import localStorage from '../../services/localStorageModule';
import './geographicForecasting.scss';
import mapModule from './geographicForecasting';

import { useLogging } from '../../services/servicesContext';

//these imports necessary for file to end up in build at dist/src/public/shapefiles for file requests
import statesShapeFile from '../../public/shapefiles/cb_2018_us_state_5m.zip';
import cbsasShapeFile from '../../public/shapefiles/cb_2018_us_cbsa_5m.zip';
import countiesShapefile from '../../public/shapefiles/counties_shapes.zip';

import alabama from '../../public/shapefiles/alabama.zip';
import alaska from '../../public/shapefiles/alaska.zip';
import arizona from '../../public/shapefiles/arizona.zip';
import arkansas from '../../public/shapefiles/arkansas.zip';
import california from '../../public/shapefiles/california.zip';
import colorado from '../../public/shapefiles/colorado.zip';
import connecticut from '../../public/shapefiles/connecticut.zip';
import dc from '../../public/shapefiles/dc.zip';
import delaware from '../../public/shapefiles/delaware.zip';
import florida from '../../public/shapefiles/florida.zip';
import georgia from '../../public/shapefiles/georgia.zip';
import hawaii from '../../public/shapefiles/hawaii.zip';
import idaho from '../../public/shapefiles/idaho.zip';
import illinois from '../../public/shapefiles/illinois.zip';
import indiana from '../../public/shapefiles/indiana.zip';
import iowa from '../../public/shapefiles/iowa.zip';
import kansas from '../../public/shapefiles/kansas.zip';
import kentucky from '../../public/shapefiles/kentucky.zip';
import louisiana from '../../public/shapefiles/louisiana.zip';
import maine from '../../public/shapefiles/maine.zip';
import maryland from '../../public/shapefiles/maryland.zip';
import massachusetts from '../../public/shapefiles/massachusetts.zip';
import michigan from '../../public/shapefiles/michigan.zip';
import minnesota from '../../public/shapefiles/minnesota.zip';
import mississippi from '../../public/shapefiles/mississippi.zip';
import missouri from '../../public/shapefiles/missouri.zip';
import montana from '../../public/shapefiles/montana.zip';
import nebraska from '../../public/shapefiles/nebraska.zip';
import nevada from '../../public/shapefiles/nevada.zip';
import newHampshire from '../../public/shapefiles/new hampshire.zip';
import newJersey from '../../public/shapefiles/new jersey.zip';
import newMexico from '../../public/shapefiles/new mexico.zip';
import newYork from '../../public/shapefiles/new york.zip';
import northCarolina from '../../public/shapefiles/north carolina.zip';
import northDakota from '../../public/shapefiles/north dakota.zip';
import ohio from '../../public/shapefiles/ohio.zip';
import oklahoma from '../../public/shapefiles/oklahoma.zip';
import oregon from '../../public/shapefiles/oregon.zip';
import pennsylvania from '../../public/shapefiles/pennsylvania.zip';
import puertoRico from '../../public/shapefiles/puerto rico.zip';
import rhodeIsland from '../../public/shapefiles/rhode island.zip';
import southCarolina from '../../public/shapefiles/south carolina.zip';
import southDakota from '../../public/shapefiles/south dakota.zip';
import tennessee from '../../public/shapefiles/tennessee.zip';
import texas from '../../public/shapefiles/texas.zip';
import utah from '../../public/shapefiles/utah.zip';
import vermont from '../../public/shapefiles/vermont.zip';
import virginia from '../../public/shapefiles/virginia.zip';
import washington from '../../public/shapefiles/washington.zip';
import westVirginia from '../../public/shapefiles/west virginia.zip';
import wisconsin from '../../public/shapefiles/wisconsin.zip';
import wyoming from '../../public/shapefiles/wyoming.zip';
import config from '../../services/config';
import init from './geographicForecasting';
import { func } from 'prop-types';

const zipStateLookup = {
    Alabama: alabama,
    Alaska: alaska,
    Arizona: arizona,
    Arkansas: arkansas,
    California: california,
    Colorado: colorado,
    Connecticut: connecticut,
    'District of Columbia': dc,
    Delaware: delaware,
    Florida: florida,
    Georgia: georgia,
    Hawaii: hawaii,
    Idaho: idaho,
    Illinois: illinois,
    Indiana: indiana,
    Iowa: iowa,
    Kansas: kansas,
    Kentucky: kentucky,
    Louisiana: louisiana,
    Maine: maine,
    Maryland: maryland,
    Massachusetts: massachusetts,
    Michigan: michigan,
    Minnesota: minnesota,
    Mississippi: mississippi,
    Missouri: missouri,
    Montana: montana,
    Nebraska: nebraska,
    Nevada: nevada,
    'New Hampshire': newHampshire,
    'New Jersey': newJersey,
    'New Mexico': newMexico,
    'New York': newYork,
    'North Carolina': northCarolina,
    'North Dakota': northDakota,
    Ohio: ohio,
    Oklahoma: oklahoma,
    Oregon: oregon,
    Pennsylvania: pennsylvania,
    'Puerto Rico': puertoRico,
    'Rhode Island': rhodeIsland,
    'South Carolina': southCarolina,
    'South Dakota': southDakota,
    Tennessee: tennessee,
    Texas: texas,
    Utah: utah,
    Vermont: vermont,
    Virginia: virginia,
    Washington: washington,
    'West Virginia': westVirginia,
    Wisconsin: wisconsin,
    Wyoming: wyoming
};

/*

state: two digit abbreviation, e.g. 'TN'
cbsa: 5 digit, e.g. '34980' (Nashville-Davidson--Murfreesboro--Franklin, TN)
county: 5 digit fips, e.g. '47037' (Davidson, TN)
zip: 5 digit zip

*/

export function GeoForecastingComponent(props) {
    
    const routeStateName = 'root.app.forecasting.geo';

    const mapDivRef = useRef(null);
    const mapInstance = useRef(null);
    const logging = useLogging();
    const [ infoModalOpen, setInfoModalOpen ] = useState(false);
    const [ searchModalOpen, setSearchModalOpen ] = useState(false);
    const [ loading, setLoading ] = useState(null);
    const [ selectedCodes, setSelectedCodes ] = useState([]);
    const [ mapControlLayers, setMapControlLayers ] = useState([]);
    const [ zipcodeStates, setZipcodeStates ] = useState(props.config.states());
    const [ geographyTypeDropdownOpen, setGeographyTypeDropdownOpen ] = useState(false);
    const [ zipcodeStatesDropdownOpen, setZipcodeStatesDropdownOpen ] = useState(false);
    const [ geographyTypes, setGeographyTypes ] = useState([
        {
          label: 'States',
          value: 'state',
          active: false
        },
        {
          label: 'CBSAs',
          value: 'cbsa',
          active: false
        },
        {
          label: 'Counties',
          value: 'county',
          active: false
        },
        {
          label: 'ZIP Codes',
          value: 'zipcode',
          active: false
        }
    ]);

    useEffect(() => {
        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: routeStateName
        });
        function init() {
            mapInstance.current = mapModule(mapDivRef.current.id, {
                infoHeaderText: 'Estimated 2027 Expected Volumes',
                scale: {
                    type: "scaleSymlog",
                    range: ["#f7fcf5","#e5f5e0","#c7e9c0","#a1d99b","#74c476","#41ab5d","#238b45","#006d2c","#00441b"]
                }
            });
            if (!localStorage.get(`${routeStateName}/preventAutoOpenInfoModal`)) {
                setInfoModalOpen(true);
            }
        }
        init();
    },[]);

    function canAddLayer() {
        if (// the can values of the inputs request a valid forecasting layer
          selectedCodes.length > 0 &&
          (
            (geographyTypes[3].active && zipcodeStates.filter(zipcodeState => zipcodeState.active).length > 0)
            ||
            (geographyTypes[0].active || geographyTypes[1].active || geographyTypes[2].active )
          )
        ) {
          return true;
        } else {
          return false;
        }
    }

    function handleGeotypeChange(e) {
        const updatedGeographyTypes = geographyTypes.map(geotype => {
            return geotype.value === e.target.value ? {...geotype, active: true} : {...geotype, active: false};
        });
        setGeographyTypes(updatedGeographyTypes);
    }

    function requestForecast() {
        setLoading('addingLayer');
        
        var geographies = geographyTypes.filter(geotype => geotype.active)[0].value;
        var codesetCodes = selectedCodes.map(activeCode => `${activeCode.codeset}_${activeCode.code}`).join(',');
        
        props.forecasting.getForecastingData(geographies, codesetCodes)
        .then(res => {
          
          setLoading(null);
          
          var layersToAdd = [];
          var retShapeFile;
          
          if (Object.keys(res.usage).length  === 0) {
            console.error('NO FORECAST USAGE DATA');
            props.notify.alert({
                title: 'NO USAGE DATA',
                text: `No expected volumes data found for ${codesetCodes} for ${geographies}s`,
                delay: 10000
            });
            
            return;
          }
          
          function dataNameAccessor(datum) {
            return `${datum.id}`;
          }
          
          res.geographyTypes.forEach(geotype => {
            res.codes.forEach(code => {
              
              var layerShapeFile;
              
              if (geotype === 'state') {
                layerShapeFile = statesShapeFile;
              } else if (geotype === 'cbsa') {
                layerShapeFile = cbsasShapeFile;
              } else if (geotype === 'county') {
                layerShapeFile = countiesShapefile;
              } else if (geotype === 'zipcode') {
                layerShapeFile = zipcodeStates.filter(state => state.active).map(activeState => {
                  return zipStateLookup[`${activeState.name}`];
                });
              }
              
              var layerDataToAdd = {};
              layerDataToAdd.type = geotype;
              layerDataToAdd[geotype] = [];
              
              res.usage[geotype].forEach(geoIdObj => {
                var geoId = Object.keys(geoIdObj)[0];
                Object.keys(geoIdObj[geoId]).forEach(codesetCodeKey => {
                  if (codesetCodeKey === code) {
                    layerDataToAdd[geotype].push({
                      id: geoId,
                      value: Math.round(parseFloat( geoIdObj[geoId][codesetCodeKey] ))
                    });
                  }
                });
              });
              layersToAdd.push({
                forecastData: layerDataToAdd,
                layerShapeFile,
                layername: `${geotype}${geotype === 'zipcode' ? '-'+zipcodeStates.filter(state => state.active).map(activeState => activeState.value).join(',') : ''}-${code}`,
                accessors: {
                  dataNameAccessor,
                  shapeFileNameAccessor: function (feature) {
                    switch (geotype) {
                      case 'state':
                        return feature.properties.STUSPS;
                      case 'cbsa':
                        return feature.properties.CBSAFP;
                      case 'county':
                        return feature.properties.FIPS;
                      case 'zipcode':
                        return feature.properties.ZCTA5CE10;
                      default:
                        console.error('geography not found for shapefile');
                        return feature.properties.NAME;
                    }
                  },
                  labelAccessor: function (feature) {
                    switch (geotype) {
                      case 'state':
                        return `${feature.properties.NAME} (${feature.properties.STUSPS})`;
                      case 'cbsa':
                        return feature.properties.NAME;
                      case 'county':
                        return `${feature.properties.NAME}, ${feature.properties.STATE_NAME}`;
                      case 'zipcode':
                        return feature.properties.ZCTA5CE10;
                      default:
                        console.error('geography not found for label');
                        return feature.properties.NAME;
                    }
                  }
                }
              });
            });
          });
          
          let controlLayersToSet = [];
          layersToAdd.forEach(layer => {
            var addedControlLayer = mapInstance.current.addData(layer.forecastData, layer.layerShapeFile, layer.layername, layer.accessors);
            controlLayersToSet.push(addedControlLayer);
          });
          const updatedMapControlLayers = [].concat(mapControlLayers, controlLayersToSet);
          setMapControlLayers(updatedMapControlLayers);
          setSelectedCodes([]);
        })
        .catch(err => {
          console.error('forecast request err', err);
        });
    }
    
    function clearLayers() {
        mapInstance.current.clearLayers();
        setMapControlLayers([]);
        const resetGeographyTypes = geographyTypes.map(geotype => ({...geotype, active: false}));
        setGeographyTypes(resetGeographyTypes);
        const updatedZipcodeStates = zipcodeStates.map(zipcodeState => ({...zipcodeState, active: undefined}))
        setZipcodeStates(updatedZipcodeStates);
    }
    
    return <div className="forecastingGeoComponent" style={{display:'inline', height:'auto'}}>
        <GeoForecastingInfoModalComponent
            open={infoModalOpen}
            routeStateName={routeStateName}
            localStorage={localStorage}
            notify={props.notify}
            onClose={() => setInfoModalOpen(false)}
        />
        <GeoForecastingCodeModalComponent
            open={searchModalOpen}
            codes={props.codes}
            forecasting={props.forecasting}
            setSelectedCodes={setSelectedCodes}
            onClose={() => setSearchModalOpen(false)}
        />
        <div className="row">
            <div id="geo-forecasting-controls" className="col-md-12">
                <button type="button" className="btn btn-default btn-md" onClick={() => setInfoModalOpen(true)} uib-tooltip="Report Information" tooltip-placement="bottom-left"><i className="fa fa-info" aria-hidden="true"></i></button>
                <button type="button" className="btn btn-default btn-md" onClick={() => setSearchModalOpen(true)}><i className="fa fa-search" aria-hidden="true"></i> Search Codes</button>
                <div className={`btn-group ${geographyTypeDropdownOpen ? 'open' : ''}`} style={{padding: 0}}>
                    <button type="button" onClick={()=> setGeographyTypeDropdownOpen(!geographyTypeDropdownOpen)} className="btn btn-default btn-md dropdown-toggle" ng-disabled="$ctrl.setFilterColumnClass($index, true)" aria-haspopup="true" aria-expanded="true">
                        <span>Geography Type </span>
                        <span ng-hide="$ctrl.setFilterColumnClass($index, true)" className="caret"></span>
                    </button>
                    <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight:'600px', overflow:'auto'}}>
                        { geographyTypes.map(geotype => {
                            return <li className="radio" key={geotype.label} style={{display:'block', margin:'10px'}}>
                                <label style={{whiteSpace:'nowrap', display:'block', padding:'0px 20px'}}>
                                    <input type="radio" name="geographyTypes" value={geotype.value} checked={geotype.active} onChange={handleGeotypeChange} style={{top:'2px'}} />
                                    {geotype.label}
                                </label>
                            </li>
                        })}
                    </ul>
                </div>
                { geographyTypes[3].active && <div ng-if="$ctrl.currentGeotype == 'zipcode'" className={`btn-group ${zipcodeStatesDropdownOpen ? 'open' : ''}`} style={{padding:0}}>
                    <button type="button" onClick={()=> {setZipcodeStatesDropdownOpen(!zipcodeStatesDropdownOpen); setGeographyTypeDropdownOpen(false)}} className="btn btn-default btn-md dropdown-toggle" ng-disabled="$ctrl.setFilterColumnClass($index, true)" aria-haspopup="true" aria-expanded="true">
                        <span>State </span>
                        <span ng-hide="$ctrl.setFilterColumnClass($index, true)" className="caret"></span>
                    </button>
                    <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight:'600px', overflow: 'auto'}}>
                        { zipcodeStates.map(zipcodeState => {
                            return <li className="checkbox" key={zipcodeState.name} style={{display:'block', margin:'10px'}}>
                                <label style={{whiteSpace: 'nowrap', display: 'block', padding: '0px 20px'}}>
                                    <input
                                        type="checkbox"
                                        style={{top:'2px'}}
                                        checked={zipcodeState.active}
                                        onChange={e => {
                                            const updatedZipcodeStates = zipcodeStates.map(state => {
                                                if (state.name === zipcodeState.name) {
                                                    return {...state, active: e.target.checked};
                                                } else {
                                                    return {...state};
                                                }
                                            });
                                            setZipcodeStates(updatedZipcodeStates);
                                        }}
                                    />
                                    {zipcodeState.name}
                                </label>
                            </li>;
                        })}
                    </ul>
                </div>}
                <button
                    className="btn btn-primary" disabled={!canAddLayer()} onClick={() => {requestForecast(); setGeographyTypeDropdownOpen(false); setZipcodeStatesDropdownOpen(false)}}>
                    { loading === 'addingLayer' && <span>
                        <i className="fa fa-spinner fa-spin fa-fw"></i>
                        <span className="sr-only">Loading...</span>
                    </span>}
                    Add Layers
                </button>
                { mapControlLayers.length > 0 && <button className="btn btn-warning" onClick={clearLayers}>
                    Clear Layers
                </button>}
                { selectedCodes.map((code, outerIndex) => {
                    return <button className="btn btn-secondary">
                        {code.codeset}-{code.code} 
                        <span
                            className="badge"
                            onClick={ e => {
                                const updatedSelectedCodes = selectedCodes.filter((selectedCode, innerIndex) => innerIndex !== outerIndex);
                                setSelectedCodes(updatedSelectedCodes);
                            }}
                        >
                            X
                        </span>
                    </button>;
                })}
            </div>
        </div>
        <div className="row" style={{height:'100%', padding: '0 15px'}}>
            <div id="myMap" ref={mapDivRef} className="col-md-12" style={{width:'100%',height:'90%'}}></div>
        </div>
    </div>;

}

function GeoForecastingCodeModalComponent(props) {

    if (!props?.open) return null;

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '90vh',
        width: '900px',
        overflowY: 'auto',
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7)',
         zIndex: 1000
    };

    const searchResultsOpts = function() {
        return {
            filterInput: true,
            csvdownload: false,
            pagination: true,
            configureButton: false,
            tableConfig: [
                {
                    header: {
                        id: 'col0',
                        class: 'text-center',
                        accessor: 'checked',
                        content: 'Select',
                        defaultVisibilty: true,
                        export: false,
                        template: (content, rowObject) => {
                            return <div style={{textAlign:'center'}} >
                                <input type="checkbox" checked={rowObject.checked} onChange={(event) => updateChecked(event, rowObject)} />
                            </div>;
                        }
                    }
                },
                {
                    header: {
                        id: 'col1',
                        accessor: 'codeset',
                        content: 'Codeset',
                        defaultVisibilty: true,
                        export: false,
                        sortable: true,
                        template: content => content
                    }
                },
                {
                    header: {
                        id: 'col2',
                        accessor: 'code',
                        content: 'Code',
                        defaultVisibilty: true,
                        export: false,
                        sortable: true,
                        template: content => content
                    }
                },
                {
                    header: {
                        id: 'col3',
                        accessor: 'description',
                        content: 'Description',
                        defaultVisibilty: true,
                        export: false,
                        sortable: true,
                        template: content => content
                    }
                },
                {
                    header: {
                        id: 'col4',
                        accessor: 'serviceline',
                        content: 'Serviceline',
                        defaultVisibilty: true,
                        export: false,
                        sortable: true,
                        template: content => content
                    }
                }
            ]
        };
    };

    const validCodesets = ['CPT','ICD10CM','ICD10PCS'];
    const [codesetsControlList, setCodesetsControlList] = useState([
        { key: 'CPT', active: false },
        { key: 'ICD10CM', active: false },
        { key: 'ICD10PCS', active: false },
    ]);
    const [servicelinesControlList, setServicelinesControlList] = useState(null);
    const [codesControlList, setCodesControlList] = useState([]);
    const previousCodesets = useRef([]);
    const previousServicelines = useRef([]);
    const [servicelineSearchCodesLoading, setServicelineSearchCodesLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(null);
    const [currentTab, setCurrentTab] = useState('search');

    const [searchText, setSearchText] = useState(null);

    const [searchResults, setSearchResults] = useState(null);
    const [servicelineSearchResults, setServicelineSearchResults] = useState(null);

    const [codesetDropdownOpen, setCodesetDropdownOpen] = useState(false);
    const [servicelineDropdownOpen, setServicelineDropdownOpen] = useState(false);
    const [showServiceLineDropdown, setShowServiceLineDropdown] = useState(false);

    function getAllCheckedCodes() {
          
        var retSearchResults = searchResults ? searchResults.filter(code => code.checked).map(filteredCode => {
            return {
                codeset: filteredCode.codeset,
                code: filteredCode.code
            };
        }) : [];
        
        var retServicelineCodes = servicelineSearchResults ? servicelineSearchResults.filter(code => code.checked).map(filteredCode => {
            return {
                codeset: filteredCode.codeset,
                code: filteredCode.code
            };
        }) : [];
        
        const ret = [].concat(retSearchResults, retServicelineCodes);
        return ret;
    }



    function validateCodesUsage(codes) {
        /*
          post method with body like:
          { "codes": ["msdrg_004","msdrg_025","msdrg_005","cpt_S5566"] }
        */
        
        var postBody = {
          codes: codes.map(code => `${code.codeset.toLowerCase()}_${code.clean_code}`)
        };
        
        return props.forecasting.validateCodesUsage(postBody)
        .then(res => {
        //   return res.data;
          return res;
        }).catch(err => {
          console.error('error requesting forecasting usage validation data', err);
        });
        
    }

    function updateChecked(event, rowObject) {
        if (event.target.checked) {
            if (currentTab === 'search') {
                const updatedSearchResults = searchResults.map(row => {
                    if ((row.codeset === rowObject.codeset) && (row.code === rowObject.code)) {
                        row.checked = true;
                    }
                    return {...row};
                });
                setSearchResults(updatedSearchResults);
            }
            if (currentTab === 'serviceline') {
                const updatedServicelineSearchResults = servicelineSearchResults.map(row => {
                    if ((row.codeset === rowObject.codeset) && (row.code === rowObject.code)) {
                        row.checked = true;
                    }
                    return {...row};
                });
                setServicelineSearchResults(updatedServicelineSearchResults);
            }
        } else {
            if (currentTab === 'search') {
                const updatedSearchResults = searchResults.map(row => {
                    if ((row.codeset === rowObject.codeset) && (row.code === rowObject.code)) {
                        row.checked = false;
                    }
                    return {...row};
                });
                setSearchResults(updatedSearchResults);
            }
            if (currentTab === 'serviceline') {
                const updatedServicelineSearchResults = servicelineSearchResults.map(row => {
                    if ((row.codeset === rowObject.codeset) && (row.code === rowObject.code)) {
                        row.checked = false;
                    }
                    return {...row};
                });
                setSearchResults(updatedServicelineSearchResults);
            }
        }

    }

    function updateCodesetFilters(open) {
        if (open) {//return early if opening, continue if closing
          return;
        }
        
        var codesetsToGetServicelinesFor = codesetsControlList.filter(codeset => codeset.active).map(activeCodeset => activeCodeset.key);
        
        var isSameCodesetLengths = previousCodesets.current.length === codesetsToGetServicelinesFor.length;
        var isSameCodesets = codesetsToGetServicelinesFor.reduce((acc, val) => {
          if (acc === false) {
            return acc;
          } else if (previousCodesets.current.indexOf(val) === -1) {
            return false;
          } else {
            return acc;
          }
        }, true);
        
        if (
          isSameCodesetLengths &&
          isSameCodesets
        ) {
          return;
        }
        
        previousCodesets.current = codesetsControlList.filter(codeset => codeset.active).map(activeCodeset => activeCodeset.key);
        if (codesetsToGetServicelinesFor.length > 0) {
          setModalLoading('servicelines');
          Promise.all(codesetsToGetServicelinesFor.map(code => {
            return props.codes.searchCodesetServicelines(code);
          }))
          .then(servicelinesArr => {
            var servicelinesSet = new Set();
            servicelinesArr.forEach(codesetServicelines => {
              codesetServicelines.data.forEach(serviceline => {
                servicelinesSet.add(serviceline);
              });
            });
            const updatedServicelinesControlList = [...servicelinesSet].map(serviceline => {
              return {
                key: serviceline
              };
            });
            
            setServicelinesControlList(updatedServicelinesControlList);
            setModalLoading(null);
          });
        } else {
          setServicelinesControlList([]);
          setCodesControlList([]);
        }
    }

    function updateServicelineFilters(open) {
        if (open) {//return early if opening, continue if closing
          return;
        }
        
        var newServicelines = servicelinesControlList.filter(serviceline => serviceline.active).map(serviceline => serviceline.key);
        var isSameServicelineLengths = previousServicelines.current.length === newServicelines.length;
        var isSameServicelines = newServicelines.reduce((acc, val) => {
          if (acc === false) {
            return acc;
          } else if (previousServicelines.current.indexOf(val) === -1) {
            return false;
          } else {
            return acc;
          }
        }, true);
        
        if (
          isSameServicelineLengths &&
          isSameServicelines
        ) {
          return;
        }
        
        var unverifiedCodes = [];
        let localCodesControlList = [];
        
        previousServicelines.current = servicelinesControlList.filter(serviceline => serviceline.active).map(serviceline => serviceline.key);
        if (newServicelines.length > 0) {
          setModalLoading('servicelines');
          setServicelineSearchCodesLoading(true);
          props.codes.searchCodesPost({
            serviceline: newServicelines
              .join(','),
            codeset: codesetsControlList
              .filter(codesetItem => codesetItem.active)
              .map(activeCodeset => activeCodeset.key)
              .join(',')
          })//search for codes by servicelines
          .then(res => {
            if (!res) {
              notify.error({
                  title: 'Codes Search Error',
                  text: `error searching for codes`,
                  delay: 10000
              });
              setModalLoading(null);
            }
            
            var codes = [];
            
            res.data.forEach(code => {
              unverifiedCodes.push(code);
              codes.push(Object.assign(code, {visible:false, key: code.code}));
            });
            
            localCodesControlList = codes;
            return validateCodesUsage(res.data);
            
          })
          .then(validationData => {
            
            if (validationData.existance.exists.length === 0) {
              notify.alert({
                  title: 'NO USAGE DATA',
                  text: `No codes with expected volumes data found for ${newServicelines.join(',')} for ${codesetsControlList.filter(codesetItem => codesetItem.active).map(activeCodeset => activeCodeset.key).join(',')}`,
                  delay: 10000
              });
              setModalLoading(null);
            }
            
            const updatedCodesControlList = localCodesControlList.filter(code => {
              return validationData.existance.exists.indexOf(`${code.codeset}_${code.code}`.toLowerCase()) > -1;
            });

            setServicelineSearchCodesLoading(false);
            setModalLoading(null);
            
            setServicelineSearchResults(updatedCodesControlList);
          })
          .catch(err => {
            console.error('err', err);
            setModalLoading(null);
          });
        }
    }

    async function initSearch(e) {
        e.preventDefault();//handle being in a form
        try {
            let unvalidatedCodes = [];
            setModalLoading('searchingCodes');
            const codeSearchResults = await props.codes.searchCodesPost({search: searchText});
            unvalidatedCodes = codeSearchResults.data.filter(code => ['CPT','ICD10CM','ICD10PCS'].indexOf(code.codeset) > -1);//limit codes the these codesets
            const validationData = await validateCodesUsage(unvalidatedCodes);
            if (validationData.existance.exists.length === 0) {
                props.notify.alert({
                    title: 'NO USAGE DATA',
                    text: `No codes with expected volumes data found for codes found as a result for your search of: ${searchText}`,
                    delay: 10000
                });
                setModalLoading(null);
            }
            const searchResultsToSet = unvalidatedCodes.filter(code => {
                return validationData.existance.exists.indexOf(`${code.codeset}_${code.clean_code}`.toLowerCase()) > -1;
            }).map(code => ({...code, checked: false}));
            setSearchResults(searchResultsToSet);
            setModalLoading(null);
        } catch(err) {
            setModalLoading(null);
            console.error('error initializing search', err);
        }

    }
    
    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={props.onClose}>×</button>
                <h3 className="modal-title" id="modal-title">Search for Codes</h3>
            </div>
            <div className="modal-body">
                <button
                    className={`btn btn-${currentTab === 'search' ? 'primary' : 'secondary'}`}
                    onClick={() => setCurrentTab('search')}
                >
                    Search by Keyword
                </button>
                <button
                    className={`btn btn-${currentTab === 'serviceline' ? 'primary' : 'secondary'}`}
                    onClick={() => setCurrentTab('serviceline')}
                >
                    Search by Serviceline
                </button>
                <div className="panel panel-default">
                    <div className="panel-body">
                        { currentTab === 'search' && <form className="form-horizontal">
                            <div className="form-group form">
                                <div className="col-sm-10">
                                    <input type="text" className="form-control col-sm-10" id="code-search-input" placeholder="enter code search terms..." onChange={e => setSearchText(e.target.value)} />
                                </div>
                                <div className="col-sm-2" style={{paddingLeft:0}}>
                                    <button className="btn btn-primary" type="submit" onClick={initSearch}>
                                        { modalLoading === 'searchingCodes' ? <span ng-show="$ctrl.modalLoading === 'searchingCodes'">
                                            <i className="fa fa-spinner fa-spin fa-fw"></i>
                                            <span className="sr-only">Loading...</span>
                                        </span> : <span ng-show="$ctrl.modalLoading !== 'searchingCodes'">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </span>}
                                            {' Search Codes'}
                                    </button>
                                </div>
                            </div>
                        </form>}
                        { currentTab === 'serviceline' && <>
                            <div className={`btn-group ${codesetDropdownOpen ? 'open' : ''}`} style={{padding:0}}>
                                <button type="button"
                                    className="btn btn-default btn-md dropdown-toggle"
                                    onClick={() => {
                                        updateCodesetFilters(!codesetDropdownOpen);
                                        setCodesetDropdownOpen(!codesetDropdownOpen);
                                        setServicelineDropdownOpen(false)
                                    }}
                                    ng-disabled="$ctrl.modalLoading === 'servicelines'"
                                >
                                    { modalLoading === 'servicelines' && <span>
                                        <i className="fa fa-spinner fa-spin fa-fw"></i>
                                        <span className="sr-only">Loading...</span>
                                    </span>}
                                    <span>Codesets </span>
                                    <span className="caret"></span>
                                </button>
                                <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight: '600px', overflow:'auto'}}>
                                    {codesetsControlList.map(codeset => (<li key={codeset.key} className="checkbox" style={{display:'block', margin:'10px'}}>
                                        <label style={{whiteSpace:'nowrap', display:'block', padding:'0px 20px'}}>
                                            <input
                                                type="checkbox"
                                                checked={codeset.active}
                                                onChange={e => {
                                                    const updatedCodesetsControlList = codesetsControlList.map(codesetItem => {
                                                        return codesetItem.key === codeset.key ? {key: codesetItem.key, active: e.target.checked} : codesetItem;
                                                    });
                                                    setCodesetsControlList(updatedCodesetsControlList);
                                                    if (updatedCodesetsControlList.filter(e => e.active === true).length > 0) {
                                                        setShowServiceLineDropdown(true);
                                                    }
                                                    else {
                                                        setShowServiceLineDropdown(false);
                                                    }
                                                }}
                                                style={{top:'2px'}}
                                            />
                                            {codeset.key}
                                        </label>
                                    </li>))}
                                </ul>
                            </div>
                            {showServiceLineDropdown ? 
                            <div className={`btn-group ${servicelineDropdownOpen ? 'open' : ''}`} style={{padding:0}}>
                                <button
                                    type="button"
                                    className="btn btn-default btn-md dropdown-toggle"
                                    onClick={()=> {
                                        updateServicelineFilters(!servicelineDropdownOpen)
                                        setServicelineDropdownOpen(!servicelineDropdownOpen)
                                        setCodesetDropdownOpen(false);
                                        updateCodesetFilters(!codesetDropdownOpen);
                                    }}
                                >
                                    { modalLoading === 'servicelines' && <span>
                                        <i className="fa fa-spinner fa-spin fa-fw"></i>
                                        <span className="sr-only">Loading...</span>
                                    </span>}
                                    <span>Service Line </span>
                                    <span className="caret"></span>
                                </button>
                                <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight:'600px', overflow:'auto'}}>
                                    {servicelinesControlList && servicelinesControlList.map(serviceline => (<li className="checkbox" key={serviceline.key} style={{display:'block', margin:'10px'}}>
                                        <label style={{whiteSpace: 'nowrap', display:'block', padding:'0px 20px'}}>
                                            <input
                                                type="checkbox"
                                                checked={serviceline.active}
                                                onChange={e => {
                                                    const updatedservicelinesControlList = servicelinesControlList.map(servicelineItem => {
                                                        return servicelineItem.key === serviceline.key ? {key: servicelineItem.key, active: e.target.checked} : servicelineItem;
                                                    });
                                                    setServicelinesControlList(updatedservicelinesControlList);
                                                }}
                                                style={{top:'2px'}}
                                            />
                                            {serviceline.key}
                                        </label>
                                    </li>))}
                                </ul>
                            </div>
                            : ""}
                        </>}
                        <hr />
                        { servicelineSearchCodesLoading && <div>
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                            <span className="sr-only">Loading...</span>
                        </div>}
                        { currentTab === 'search' && searchResults && <PhtableComponent
                            data={searchResults}
                            reportconfig={searchResultsOpts}
                        />}
                        { currentTab === 'serviceline' && servicelineSearchResults && <PhtableComponent
                            data={servicelineSearchResults}
                            reportconfig={searchResultsOpts}
                        />}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    style={{ 'margin': '5px' }}
                    className="btn btn-primary"
                    disabled={getAllCheckedCodes().length === 0}
                    onClick={() => {
                        const checkedCodes = getAllCheckedCodes();
                        props.setSelectedCodes(checkedCodes);
                        props.onClose();
                    }}
                >
                    OK
                </button>
                <button style={{ 'margin': '5px' }} className="btn btn-warning" onClick={props.onClose}>Close</button>
            </div>
        </div>
    </>, document.body);
}

function GeoForecastingInfoModalComponent(props) {
    
    if (!props?.open) return null;

    const [ autoDontOpenInfoChecked, setDontAutoOpenInfoChecked ] = useState(false);

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '90vh',
        overflowY: 'auto',
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
    };

    useEffect(() => {
        if (localStorage.get(`${props.routeStateName}/preventAutoOpenInfoModal`)) {
            setDontAutoOpenInfoChecked(true);
        }
    }, []);

    function handlePreventCheckbox(e) {
        if (e.target.checked) {
            localStorage.set(`${props.routeStateName}/preventAutoOpenInfoModal`, true);
            setDontAutoOpenInfoChecked(true);
        } else {
            localStorage.remove(`${props.routeStateName}/preventAutoOpenInfoModal`, true);
            setDontAutoOpenInfoChecked(false);
        }
    }

    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <h3 className="modal-title" id="modal-title">
                    Geographic Forecasting
                    <button type="button" className="close" onClick={props.onClose}>×</button>
                </h3>
                
            </div>
            <div className="modal-body" id="modal-body">
                
                <p>Forecasting provides our users with the ability to estimate demand in the markets they serve five years from now. This allows for effective service line planning and helps inform new site selection when planning for future growth. By estimating demand for individual procedures, users can build out an ROI model based on the methodology described below. Users can self guide any analysis by utilizing the code search in the top menu to find projections for the code of their choice.</p>
                
                <p>After finding the code, users will then select a geographic level they want to see projected on the web map - options include: <i>State, CBSA (Core Based Statistical Area), County, and ZIP Code</i>. Users will then click "Add Layers" which will populate the webmap with their selection. Layers can be toggled on and off using the layer control in the top right.</p>
                
                <h4>Basic Use Rate Calculation</h4>
                
                <p>A <i>Utilization Rate</i> is the foundation for this Forecasting Methodology. In essence, by quantifying the number of patients in a given population (denominator) and the number in that population who received a diagnosis or procedure of interest (numerator), we can come up with a basic utilization rate for any given medical procedure or diagnosis and apply it to any population projection. This calculation was performed on both Perception Health’s <strong>Commercial Claims</strong> dataset and <strong>Medicare</strong> dataset for 2021.</p>
                
                <p>Deriving the use rates as described above is the first step in forecasting, however they can only tell us about what is occurring today. In order to arm our clients with the intelligence to plan for future growth we need an understanding of how these clinical events will look 5 years in the future. Here we take our use rates (updated yearly with our freshest Medicare and Commercial datasets) and apply them to population projections in 5  year increments provided by our data partners.</p>
                
            </div>
            <div className="modal-footer" style={{textAlign:'left'}}>
                <div className="checkbox" style={{display:'inline-block'}}>
                    <label>
                    <input type="checkbox" checked={autoDontOpenInfoChecked} onChange={handlePreventCheckbox} />
                        Don't automatically show this message on page load
                    </label>
                </div>
                <button type="button" className="btn btn-warning" style={{float:'right'}} onClick={props.onClose}>Close</button>
            </div>
        </div>
    </>, document.body);

}