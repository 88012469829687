import React, {useState, useEffect } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent'
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import download from '../../services/download';
import api from '../../services/api';

import { Modal } from './modal'; 

export default function PanelComponent({ optsObject, communityList, download={download}, api }) { 
        const [isCollapsed, setIsCollapsed] = useState(false);
        const [modalconfig, setModalconfig] = useState({});
        const [isOpen, setIsOpen] = useState(false);
        const [data, setData] = useState([]);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            
        }, []);

        const reportConfig = function() { 
            return {  
              tableConfig :  [
                {
                    "header": {
                        "id": "col4",
                        "accessor": "Provider Name",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "export": false,
                        "sortable": false,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.Telephone.toString();
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject["Postal Code"],
                                  'phone_number': phone,
                                  'city': rowDataObject.City,
                                  'state': rowDataObject.State
                                 },
                                'name': {'display' : rowDataObject["Provider Name"] }
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/physician/${rowDataObject.NPI}/home`}>{content}</Link></PopoverDetails>  
                        } 
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 16
                        }
                    },
    
                    {
                        "header": {
                            "id": "col3",
                            "accessor": "Total Charges",
                            "content": "Total Charges",
                            "defaultVisibilty": true,
                            "export": false,
                            "sortable": false,
                            "template": function(content) { return `$${content.toLocaleString()}` }
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 17
                            }
                    },
                    {
                        "header": {
                            "id": "col2",
                            "accessor": "Share",
                            "content": "Market Share",
                            "defaultVisibilty": true,
                            "export": false,
                            "sortable": false,
                            "template": function(content, rowObject) { return `${(rowObject.Share*100).toFixed(2)}%`  }
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 15
                            }
                    },
                    {
                        "header": {
                            "id": "col1",
                            "accessor": "NPI",
                            "content": "Share to Competitors",
                            "defaultVisibilty": true,
                            "export": false,
                            "sortable": false,
                            "template": function(content, rowObject) { return `${((1 - rowObject.Share)*100).toFixed(2)}%` }
                        },
                        "column": {
                                "class": "'text-center'",
                                "content": {},
                                "style": "",
                                "id": 14
                            }
                    }
                ],
            configure : false,
            configureButton: false,
            showSelected: false,
            filterInput: false,
            csvdownload: false,
            pagination: false
          }
        }

        const setModalConfiguration = (data) => {
            setIsOpen(true);
            setLoading(true);
            setModalconfig({ data : optsObject, api: api, download: download, communityList: communityList });
        }

        const closeModal = (currentState) => {
            setIsOpen(currentState);
        }

        const onCollapseViewAll = () => {
            setIsCollapsed(!isCollapsed);
            if (isCollapsed) {
                updateCommunityList(optsObject.top10Providers)
            }
        }

        const updateCommunityList = function(data) {
            if (data) {
                communityList.update({listVal: [...new Set(data.map(dt => dt.NPI).filter(dt => dt))]});
            }
        };
        
    
  return (
<div>
 { optsObject ? 
    <div className={`panel panel-${optsObject.color}`}>
    <div className="panel-heading">
            <div className="row">
            <div className="col-xs-3">
                <i className={`fa fa-5x ${optsObject.icon}`}></i>
            </div>
            <div className="col-xs-9 text-right">
                <div className="huge">${optsObject.top10Charges.toLocaleString()}</div>
                <div>{optsObject.title}</div>
            </div>
        </div> 
    </div>

    <div className="panel-default" onClick={() => onCollapseViewAll()}>
        <div className="panel-heading">
            <h4 className="panel-title">
                View Details
                <span className="pull-right">
                    <i className="fa fa-arrow-circle-down"></i>
                </span>
                <div className="clearfix"></div>
            </h4>
        </div>
    </div>
    <div className={`panel-body ${isCollapsed ? 'collapse' : ''}`}>
    <PhtableComponent data={optsObject.top10Providers} reportconfig={reportConfig} download={download}></PhtableComponent>
    { modalconfig.data && communityList ? <Modal download={download} api={api} open={isOpen} CommunityList={communityList} config={modalconfig} onCloseModal={() => closeModal(!isOpen)} /> : ""}
    <a className="btn btn-default" onClick={() => {setModalConfiguration(optsObject.allProviders); updateCommunityList();}}>See All</a>
    </div>
    </div> : null }
  </div>
  )
}
