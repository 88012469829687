import React, { useEffect, useRef } from 'react'
import { phHorizontalBarGraph } from '../../js/horizontalBarGraph';

export function HorizontalBarGraphComponent({ data, sidebarOpts }) {
    const horizontalBarGraphContainer = useRef(null);
    useEffect(() => {
        renderChart();
    }, [data]);

    function renderChart(){
        removeAllChildNodes();
        createHorizontalBarGraph();
    }

    const createHorizontalBarGraph = () => {
        const elem = horizontalBarGraphContainer.current;
        var elemDims = elem.getBoundingClientRect();
        var instance = phHorizontalBarGraph();
        
        instance(elem, {
            data: data.slice(),
            width: elemDims.width,
            height: elemDims.height,
            seedProvider: sidebarOpts.seedProvider.npi,
            communityMembers: sidebarOpts.communityMembers,
            navToProviderHome: sidebarOpts.navToProviderHome
        });
    } 


    window.onresize = debounce(() => renderChart());

    function removeAllChildNodes() {
        const parent = horizontalBarGraphContainer.current;
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }

    function debounce(func, timeout = 500){
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }


  return (
      <>
       <div ref={horizontalBarGraphContainer} id="horizontalBarGraphContainer" style={{ height : '550px'}}></div>
      </>
  )
}
