var script = {
    config: {
        needMenu: true
    },
    steps: [{
        content: "This is the {{entitytype}}'s home page.",
    }, {
        target: "#tourscript-{{entitytype}}dropdown",
        content: "Notice that the dropdown options change once you have a selected {{entitytype}}.",
    }, {
        target: "#tourscript-{{entitytype}}recent",
        content: "Here you can view recently selected {{entitytype}}s.",
    }, {
        target: "#tourscript-{{entitytype}}graphs",
        content: "There are plenty of graphs here to give you insight into the loyalty patterns, referral trends and general behavior of the {{entitytype}}.",
    }, {
        target: "#tourscript-{{entitytype}}reports",
        content: "There are also many reports to keep you busy with insighful information.",
    },
    {
        target: "#tourscript-tour",
        content: "If you need help throughout the site, click the <span class='glyphicon glyphicon-question-sign' aria-hidden='true'></span> button"
    }]
};

export default script;
