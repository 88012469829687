import angular from 'angular';
import localStorage from './localStorage';
import config from './config';

export default angular.module('storage', [
    localStorage, config
])
.provider('storage', function() {
    
    var srvc = this;
    
    this.$get = ['$http', '$state', '$q', '$interval', /*'auth',*/ 'config', 'api', '$localStorage',
        function($http, $state, $q, $interval, /*auth,*/ config, api, $localStorage) {
            
            srvc.data = {};
            var update = function update() {
                
                return $http.get(`${config.account().api}/profile/${srvc.userId}/config/TEAM`, { headers: { Authorization: api.options().headers.Authorization}})
                .then(res => {
                    if (res.data) {
                        srvc.data = res.data;
                        api.options({
                            headers: { 
                                "X-TEAM-TTM": srvc.data.ttm
                            }
                        });
                        $localStorage.setObj('team.storage', srvc.data);
                    } else {
                        $http.put(`${config.account().api}/profile/${srvc.userId}/config/TEAM`, {ttm: config.models()[0].name}, { headers: { Authorization: api.options().headers.Authorization}})
                        .then(response => {
                            srvc.data.ttm = config.models()[0].name;
                            $localStorage.setObj('team.storage', config.models()[1].name);
                            api.options({
                                headers: { 
                                    "X-TEAM-TTM": srvc.data.ttm
                                }
                            });
                        }, err => {
                            console.error('error uploading ttm', err);
                        });
                    }
                }, err => {
                    console.error('error initially fetching storage data', err);
                    if (config.models().length > 0) {
                        $http.put(`${config.account().api}/profile/${srvc.userId}/config/TEAM`, {ttm: config.models()[0].name}, { headers: { Authorization: api.options().headers.Authorization}})
                        .then(response => {
                            srvc.data.ttm = config.models()[0].name;
                            $localStorage.setObj('team.storage', config.models()[1].name);
                            api.options({
                                headers: { 
                                    "X-TEAM-TTM": srvc.data.ttm
                                }
                            });
                        }, err => {
                            console.error('error uploading ttm', err);
                        });
                    }
                    
                    
                });
            };
            
            return {
                init: function () {
                    return update();
                },
                getStorage: function (key) {
                    
                    if (srvc.data && srvc.data[key]) {
                        return srvc.data[key];
                    } else if ($localStorage.getObj('team.storage'))  {
                        return $localStorage.getObj('team.storage')[key];
                    } else {
                        return false;
                    }
                },
                fetchStorage: function (key) {
                    return $http.get(`${config.account().api}/profile/${srvc.userId}/config/TEAM`, { headers: { Authorization: api.options().headers.Authorization}})
                    .then(res => {
                        srvc.data[key] = res.data;
                        let wholeObj = angular.merge({}, $localStorage.getObj('team.storage'), {[key]: res.data});
                        $localStorage.setObj('team.storage', wholeObj);
                        return srvc.data[key];
                    }, err => {
                        console.error('error fetching storage data', err);
                    });
                },
                setStorage: function (key, value) {
                    return $http.put(`${config.account().api}/profile/${srvc.userId}/config/TEAM`, {[key]: value}, { headers: { Authorization: api.options().headers.Authorization}})
                    .then(res => {
                        srvc.data[key] = res.data[key];
                        $localStorage.setObj('team.storage', angular.merge({}, $localStorage.getObj('team.storage'), {[key]: value}));
                        api.options({
                            headers: { 
                                "X-TEAM-TTM": srvc.data.ttm
                            }
                        });
                        return srvc.data[key];
                    }, err => {
                        console.error('error setting storage data', err);
                    });
                },
                setStorageUserId: function(userId) {
                    srvc.userId = userId;
                }
            };
        }
    ];
    
}).name;