import angular from 'angular';

import './sankey.scss';
import template from './sankey.html';
import controller from './sankey.controller';

import uirouter from '@uirouter/angularjs';

import sankeyDirective from './sankeyDirective';

const componentName = 'sankey';
const moduleName = 'app.reports.sankey';

import api from '../../services/api';
import models from '../../models';

angular.module(moduleName, [
    uirouter, api, models
])
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
}).directive('sankeyDirective', sankeyDirective);

export {
    moduleName,
    componentName
};