export default ['$scope', '$state', 'pic', '$timeout', 'template', function($scope, $state, pic, $timeout, template) {
    var $ctrl = this;
    
    $ctrl.$onInit = function() {
        
        $ctrl.provider = $ctrl.npis[0];
        
        var leafletInstance;
        
        $ctrl.geoFlowOpts = {
            providername: $ctrl.provider.name.display,
            NPI: $ctrl.provider.npi,
            center: $ctrl.provider.location.coordinates,
            entitytypecode: $ctrl.provider.entitytype,
            filterOpen: true,
            legend: true,
            recentLegend: true,
            zoom: 4,
            pic: pic,
            generateHomeUrl: function(npi){
                return $state.href('root.app.provider', {npis: [npi], path:'home'});
            },
            geoFlowComponentElementName: 'provider-geoflow',
            mapSizeCallback: function(map) {
                leafletInstance = map;
            }
        };
        
        $scope.$on('leaflet.redraw', function() {
            $timeout(function() {
                leafletInstance.invalidateSize(true);
            });
        });
        
        var resizeDisposal = template.contentResize(function(){
            $timeout(function() {
                if (leafletInstance) {
                    leafletInstance.invalidateSize(true);
                }
            }, 300);
        });
        $scope.$on('$destroy', resizeDisposal);
        
        $ctrl.$onChanges = function(changes) {
            if (changes.data) {
                $ctrl.geoFlowOpts.data = changes.data.currentValue;
            }
        };
        
        if ($ctrl.data) {
            $scope.loaded = true;
            $ctrl.geoFlowOpts.data = $ctrl.data;
        } else {
            $ctrl.npis[0].Report('geoflow')
            .then(function(data) {
                $ctrl.geoFlowOpts.data = data;
            }).finally(function() {
                $scope.loaded = true;
            });
        }
    };
    
}];