import React, { useEffect, useState } from 'react'
import { useRouteLoaderData } from "react-router-dom";
import { useLogging } from '../../services/servicesContext';
import { DashboardsEmbedWrapper } from '../dashboards/dashboard.embed';

export function GeographicbenchmarkComponent({$state, $timeout }) {
  const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
  const [ loading, setLoading ] = useState(true);//default true prevents the default embed wrapper from using its own logger
  const logging = useLogging();
  
  useEffect(() => {
    setLoading(true);
    logging.routeLoad({//log page here before the the embed wrapper uses its own logger
      pathname: location.pathname,
      npis: [npis.npi],
      statename: 'root.app.phy.dashboards.geobenchmark'
    });
    setLoading(false);//having already logged for this path, the default embed logger won't run
  }, [npis]);

  const params = { NPI: npis.npi };
  return (<>
    { !loading
      ? <DashboardsEmbedWrapper $state={$state} $timeout={$timeout} report="geobenchmark" params={params} style={{ 'height': '100%' }} />
      : <div className="loading-lg"></div>
    }
  </>)
}
