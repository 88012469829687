import angular from 'angular';
import { react2angular } from 'react2angular';

import controller from './carepathways.controller';

import api from '../../services/api';
import pic from '../../services/pic';
import communityList from '../../services/communityList';
import template from '../../services/template';
import uirouter from '@uirouter/angularjs';
import uibModal from 'angular-ui-bootstrap/src/modal';

import { CarepathwaysComponent } from './carepathwaysComponent.js';

const componentName = 'providerCarepathways';
const physicianComponentName = 'physicianCarepathways';
const moduleName = 'app.reports.sankey.carepathways.provider';

angular.module(moduleName, [
    api, uirouter, uibModal, communityList, pic, template
])
.component(componentName, {
    require: {
        reportSankey: '^sankey'
    },
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl'
})
.component(
    physicianComponentName,
    react2angular(CarepathwaysComponent, ['npis', 'opts'], ['api', '$state', 'pic', 'template', 'CommunityList'])
);

export {
    moduleName,
    componentName,
    physicianComponentName
};