import React, { useState, useEffect } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import Auth from '@aws-amplify/auth';
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';
import { useTourManager, useLogging } from '../../services/servicesContext';

export function PreacuteLeakageComponent({ pic, download, npis, notify, api, CommunityList }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const tourManager = useTourManager();
    const logging = useLogging();
    const provider = useRouteLoaderData('organization.npis');

    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'Pre Acute Share - 1/12',
                text: "This report shows the facilities that shared vists with the providers that..",
            },
            {
                id: 2,
                title: 'Pre Acute Share - 2/12',
                text: `${provider.name.display} also shared visits with.<br><br>Use left/right arrow keys to navigate.`,
            },
            {
                id: 3,
                attachTo: { element: "#fromProviderName", on: 'bottom' },
                title: 'Pre Acute Share - 3/12',
                text: `For example, every provider in this column has shared visits with ${provider.name.display}.`,
            },
            {
                id: 4,
                attachTo: { element: ".tourscript-fromname", on: 'bottom' },
                title: 'Pre Acute Share - 4/12',
                text: `Thus, this provider, who shared visits with ${provider.name.display}, also shared...`,
            },
            {
                id: 5,
                attachTo: { element: '.tourscript-uniqueto', on: 'bottom' },
                title: 'Pre Acute Share - 5/12',
                text: "this many patients...",
            },
            {
                id: 6,
                attachTo: { element: '.tourscript-toname', on: 'bottom' },
                title: 'Pre Acute Share - 6/12',
                text: "with this provider...",
            },
            {
                id: 7,
                attachTo: { element: '.tourscript-shared', on: 'bottom' },
                title: 'Pre Acute Share - 7/12',
                text: "which totaled up to this many visits.",
            },
            {
                id: 8,
                attachTo: { element: ".content-container thead", on: 'bottom' },
                title: 'Pre Acute Share - 8/12',
                text: "You can sort on any column by simply clicking on the header name.",
            },
            {
                id: 9,
                attachTo: { element: ".tourscript-fromname", on: 'bottom' },
                title: 'Pre Acute Share - 9/12',
                text: "Click on the physician's name to view their share graph.",
            },
            {
                id: 10,
                attachTo: { element: ".tourscript-npi", on: 'bottom' },
                title: 'Pre Acute Share - 10/12',
                text: "Hover over the NPI to view additional info about the provider.",
            },
            {
                id: 11,
                attachTo: { element: ".content-container .table-controls-input .filter-box", on: 'bottom' },
                title: 'Pre Acute Share - 11/12',
                text: "Also, you can filter results here by searching for any value in the row.",
            },
            {
                id: 12,
                attachTo: { element: "#tourscript-buttons", on: 'bottom' },
                title: 'Pre Acute Share 12/12',
                text: "Use this button to download the data in the table.",
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [provider.npi],
            statename: `root.app.org.reports.preacuteleakage`
        });

        onInit();

        return () => {
            tourManager.clearTour();
        };

    }, [provider])

    const onInit = function() {
        const reportOptions = { 
            export: { 
                name: 'Pre-acute share for ' + provider.name.display + ' (' + provider.npi + ')' 
            }
        };
        getAndFormatData(provider);
    };
    
    const getAndFormatData = async (provider) => {
        setLoading(true);
        await fetch('/api/npi/' + provider.npi + '/preacuteleakage/', api.options())
                .then((res) => { 
                return res.json()
              })
                .then((res) => {
                const data = res.map(function(datum) {
                    return {
                        'npi': datum.secondnpi,
                        'fromNpi': datum.firstnpi,
                        'fromCommunities': pic(datum.firstnpi),
                        'fromProviderName': datum.fromname,
                        'fromTaxonomy': datum.fromspecialization ? datum.fromclassification + " (" + datum.fromspecialization + ")" : datum.fromclassification,
                        'fromCity': datum.fromcity,
                        'fromState': datum.fromstate,
                        'fromZip': datum.fromzip,
                        'fromPhone': datum.fromnumber,
                        'toNpi': datum.secondnpi,
                        'toCommunities': pic(datum.secondnpi),
                        'toProviderName': datum.toname,
                        'toTaxonomy': datum.tospecialization ? datum.toclassification + " (" + datum.tospecialization + ")" : datum.toclassification,
                        'toCity': datum.tocity,
                        'toState': datum.tostate,
                        'toZip': datum.tozip,
                        'toPhone': datum.tonumber,
                        'shared': datum.sharedtransactioncount,
                        'unique': datum.patienttotal,
                        'sameday': datum.samedaytotal
                    };
            });
            setData(data);
            setLoading(false);
        }).catch((err) => {
            console.log('Error fetching Pre Acute Share data: ', err);
            setLoading(false);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Pre Acute Share data.',
                delay: 30000
            });
        });
    }

    const reportConfig = function() { 
        return {  
          tableConfig :  [
    
                {
                    "header": {
                        "id": "col11",
                        "accessor": "fromNpi",
                        "content": "From NPI",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.fromPhone && rowDataObject.fromPhone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject && rowDataObject.fromZip || "",
                                  'phone_number': phone,
                                  'city': rowDataObject && rowDataObject.fromCity || "",
                                  'state': rowDataObject && rowDataObject.fromState || ""
                                 },
                                'name': {'display' : rowDataObject && rowDataObject.fromProviderName || ""}
                              } 
                            return <PopoverDetails provider={provider}><Link className='tourscript-npi' to={`/physician/${content}/home`}>{content}</Link></PopoverDetails>  
                        } 
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col10",
                        "accessor": "fromCommunities",
                        "content": "From Communities",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => { 
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "fromProviderName",
                        "content": "From Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.fromNpi, entitytype : 1 }
                            return <><span className='tourscript-fromname'>{content}</span> 
                                     <PopoverMenu provider={provider}>
                                      {<span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                      </span>}
                                    </PopoverMenu>
                                   </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                    }
                },   
                {
                    "header": {
                        "id": "col8",
                        "accessor": "fromTaxonomy",
                        "content": "From Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "toNpi",
                        "content": "To NPI",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.fromPhone && rowDataObject.toPhone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                  'postal_code' : rowDataObject && rowDataObject.toZip || "",
                                  'phone_number': phone,
                                  'city': rowDataObject && rowDataObject.toCity || "",
                                  'state': rowDataObject && rowDataObject.toState || ""
                                 },
                                'name': {'display' : rowDataObject && rowDataObject.toProviderName || ""}
                              } 
                            return <PopoverDetails provider={provider}><Link to={`/organization/${content}/home`}>{content}</Link></PopoverDetails>  
                        } 
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                   },
                   {
                    "header": {
                        "id": "col6",
                        "accessor": "toCommunities",
                        "content": "To Communities",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => { 
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "toProviderName",
                        "content": "To Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowObject) { 
                            const providerCollection = {  name : content, npi : rowObject.toNpi }
                            const provider = {  name : content, npi : rowObject.toNpi, entitytype : 2  }
                            return <><span className='tourscript-toname'>{content}</span> 
                                <PopoverMenu providerCollection={providerCollection} provider={provider}>
                                    {<span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>}
                                </PopoverMenu>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                   },
                   {
                    "header": {
                        "id": "col4",
                        "accessor": "toTaxonomy",
                        "content": "To Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                   },
                   {
                    "header": {
                        "id": "col3",
                        "accessor": "shared",
                        "content": "Patient Visits",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => content && <span className='tourscript-shared'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                   },
                   {
                    "header": {
                        "id": "col2",
                        "accessor": "unique",
                        "content": "Unique Patients",
                        "sort": "desc",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => content && <span className='tourscript-uniqueto'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                   },
                   {
                    "header": {
                        "id": "col1",
                        "accessor": "sameday",
                        "content": "Same Day Visits",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 1
                        }
                   },
                   {
                    "header": {
                        "id": "col12",
                        "accessor": "fromCity",
                        "content": "From City",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                   },
                   {
                    "header": {
                        "id": "col13",
                        "accessor": "fromState",
                        "content": "From State",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                   },
                   {
                    "header": {
                        "id": "col14",
                        "accessor": "fromZip",
                        "content": "From ZIP",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                   },
                   {
                    "header": {
                        "id": "col15",
                        "accessor": "fromPhone",
                        "content": "From Phone",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            const formatPhoneNum = content && content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                   },
                   {
                    "header": {
                        "id": "col16",
                        "accessor": "toCity",
                        "content": "To City",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 16
                        }
                   },
                   {
                    "header": {
                        "id": "col17",
                        "accessor": "toState",
                        "content": "To State",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 17
                        }
                   },
                   {
                    "header": {
                        "id": "col18",
                        "accessor": "toZip",
                        "content": "To ZIP",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 18
                        }
                   },
                   {
                    "header": {
                        "id": "col19",
                        "accessor": "toPhone",
                        "content": "To Phone",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            const formatPhoneNum = content && content.toString();
                            return <span className="text-nowrap">({formatPhoneNum.substring(0, 3)}) {formatPhoneNum.substring(3, 6)}-{formatPhoneNum.substring(6, 10)}</span> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 19
                        }
                   }
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        reportTitle:  'Pre-acute Share for ' + provider.name.display + ' (' + provider.npi + ')',
        localStorageName: 'app.phy.reports.preacuteleakage',
        }
    }
    
  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download}  needCommUpdate={true} CommunityList={CommunityList}/>  : null}
    </>
  )
}
