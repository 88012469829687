import angular from 'angular';

export default angular.module('grouping',[])
.directive('grouping', ['$timeout', function ($timeout) {
    
    return {
        scope: true,
        restrict: 'C',
        require: '^^crossfilter',
        link: function (scope, element, attrs, crossfilter) {
            scope.reset = function () {
                scope.$broadcast('reset');
            };

            crossfilter.crossfilter().then(function (filter) {
                filter.onChange(function (type) {
                    if (type == 'filtered') {
                        $timeout(function () {
                            scope.filtered = scope.hasFilter();
                        });
                    }
                });
            });
        },
        controllerAs: '$grp',
        controller: ['$scope', function ($scope) {
            var graphs = $scope.graphs = [];

            this.name = 'grouping';

            this.register = function(graph) {
                graphs.push(graph);
            };

            $scope.hasFilter = this.hasFilter = function (filter) {
                return this.filtered = graphs.reduce(function (v, g) {
                    return v || g.hasFilter(filter);
                }, false);
            };
        }]
    };
}]).name;
