import angular from 'angular';

import template from './summary.html';
import controller from './summary.controller';
import { react2angular } from 'react2angular';
import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';
import { SummaryComponent } from './summaryComponent';
import download from '../../services/download';
const componentName = 'providerSummary';
const moduleName = 'app.reports.summary.provider';
const updatedComponentName = 'summaryComponent';

angular.module(moduleName, [
    phTable, tour
])
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(SummaryComponent, ['npis', 'opts', 'community'], ['api', 'notify', 'download'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};