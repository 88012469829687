import React, {useState, useEffect, useRef} from "react";
import { useRouteLoaderData } from "react-router-dom";
import * as d3 from 'd3';
import * as parseD from 'd-path-parser';
import merge from 'lodash/merge';

import { useApi, useConfig, usePic, useTemplate, useLogging } from '../../services/servicesContext';

import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';
import { set } from "lodash";

export function SpecialtyFlowComponent(props) {
    const community = useRouteLoaderData('community.cid');

    const api = useApi();
    const config = useConfig();
    const pic = usePic();
    const template = useTemplate();
    const logging = useLogging();

    const [ loading, setLoading ] = useState(null);
    const [ axis, setAxis ] = useState('shared');
    const [ openDropdown, setOpenDropdown ] = useState(null);
    const [ relations, setRelations ] = useState(null);
    const [ containsPcp, setContainsPcp ] = useState(false);
    const [ containsSpecialist, setContainsSpecialist ] = useState(false);
    const [ containsOrg, setContansOrg ] = useState(false);
    const [ pcpTaxonomyCodes, setPcpTaxonomyCodes ] = useState(null);
    const [ providerFilter, setProviderFilter ] = useState(null);

    const [ options, setOptions ] = useState({
        colorLegend: true,
            toolTip: {
                sourceName: function(d){
                    return d.origin.toString();
                },
                targetName: function(d){
                    return d.destination.toString();
                },
                units: 'Shared Visits',
                value: function(d) {
                    return d.values.shared;
                }
            },
            color: {
                scale: d3.scaleOrdinal(d3.schemeCategory20),
                value: function(d) {
                    return this.scale(d);
                }
            },
            node: {
                colorValue: function(d){
                    if (d.taxonomy) {
                        return d.taxonomy.toString();
                    } else if (d.entitytype != 2 && d.entitytype != 1) {
                        return d.name;
                    }
                    else {
                        return d.id;
                    }
                },
                label: function(d){
                    if (d.entitytype == 2) {
                        return d.name.display+' ('+d.npi+')';
                    } else if (d.entitytype == 1) {
                        return d.name.primary[0]+'. '+d.name.secondary+' ('+d.npi+')'; 
                    } else {
                        return d.name;
                    }
                }
            },
            link: {
                hoverClassCheck: function(currentDatum, hoverDatum){
                    if (currentDatum.origin == hoverDatum.origin && currentDatum.destination == hoverDatum.destination) {
                        return false;
                    } else {
                        return true;
                    }
                },
                value: function(d) {
                    return d.values.shared;
                }
            }
    });

    const allStringVal = '-- All Filters Below --';

    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        same: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };

    const endpoints = {
        pcp: '/api/community/'+community.id+'/specialtyflow/left/',
        specialist: '/api/community/'+community.id+'/specialtyflow/middle/',
        organization: '/api/community/'+community.id+'/organizationspecialtyflow/'
    };

    const payloads = {
        pcp: {"initial":true},
        specialist: {"classification":null,"specialization":null,"initial":true},
        organization: {"initial":false}
    };

    const toggleAxis = function(property) {
        fetchEndPointData(providerFilter.type === 'organization' ? endpoints[providerFilter.type] : `${endpoints[providerFilter.type]}${property && property === "same" ? "sameday" : property}/`,payloads[providerFilter.type],providerFilter.type).then(data => {
            getRelations(data, providerFilter.type, property ? property : axis).then(function(){
                let optionsClone = {...options};

                optionsClone.toolTip.units = mapping[property].units;
                optionsClone.toolTip.value = function(d) {
                    return d.values[property];
                };
                optionsClone.link.value = function(d) {
                    return d.values[property];
                };

                setOptions(optionsClone);
                setAxis(property);
                setOpenDropdown(null);
            });
          });
    };

    const handleDropdownClick = function(col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
        
    };

    const handleFilterChange = function(e) {
        let filterVal = {type: e.target.value};
        setProviderFilter(filterVal);
        fetchEndPointData(filterVal.type === 'organization' ? endpoints[filterVal.type] : `${endpoints[filterVal.type]}${axis && axis === "same" ? "sameday" : axis}/`,payloads[filterVal.type],filterVal.type).then(data => {
            getRelations(data, filterVal.type, axis ? axis : 'shared');
          });
    };

    async function fetchEndPointData(endpoint, payload, type){

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': props.api.options().headers.Authorization, 'X-TEAM-TTM': props.api.options().headers["X-TEAM-TTM"]},
            body: JSON.stringify(payload)
        };

        const response = await fetch(endpoint, requestOptions)

        const data = response.json()

        return data;
    };

    async function getRelations(data,type,axis) {
        
        let communityNode = new props.api.Community({
            name: community,
            id: community.id
        });

        const dataLinks = data.links ? data.links.map(function(ref) {
            return ref;
        }) : null;

        const dataRel = data.relations ? data.relations.map(function(ref) {
            return ref;
        }) : null;
        
        const relPromises = [];

        if (dataLinks && type === 'specialist') {
            for (var ref of dataLinks) {
                let rel = await new props.api.Relation({ values: { [axis]: ref.value } });
                rel.origin = ref.sourceName;
                rel.destination = ref.targetName;
                rel.$rel = ref;

                if (ref.sourceType === "community") {
                    rel.source = community;
                    await props.api.GetProvider(ref.targetNpi)
                    .then(function(val) {
                        rel.target = val;
                    });
                }
                else {
                    rel.target = community;
                    await props.api.GetProvider(ref.sourceNpi)
                    .then(function(val) {
                        rel.source = val;
                    });
                }

                relPromises.push(rel);
            };
        }
        else if (dataLinks && type === 'pcp') {
            for (var ref of dataLinks) {
                let rel = await new props.api.Relation({ values: { [axis]: ref.value } });
                rel.origin = ref.sourceName;
                rel.destination = ref.targetName;
                rel.$rel = ref;

                if (ref.sourceType === "community") {
                    rel.source = community;
                    await props.api.GetProvider(ref.sourceNpi)
                    .then(function(val) {
                        rel.origin = val.toString();
                    });
                    await props.api.GetProvider(ref.targetNpi)
                    .then(function(val) {
                        rel.target = val;
                        rel.destination = val.toString();
                    });
                }
                else {
                    await props.api.GetProvider(ref.sourceNpi)
                    .then(function(val) {
                        rel.source = val;
                        rel.origin = val.toString();
                    });
                    await props.api.GetProvider(ref.targetNpi)
                    .then(function(val) {
                        rel.target = val;
                        rel.destination = val.toString();
                    });
                }

                relPromises.push(rel);
            };
        }
        else if (dataRel) {
            for (var ref of dataRel) {
                let rel = await new props.api.Relation({ values: ref.values });
                rel.$rel = ref;

                if (community.npis.indexOf(ref.target) > -1) {
                    rel.target = community;
                    await props.api.GetProvider(ref.source)
                    .then(function(val) {
                        rel.source = val;
                        rel.origin = val.toString();
                    });
                    await props.api.GetProvider(ref.target)
                    .then(function(val) {
                        rel.destination = val.toString();
                    });
                }
                else {
                    await props.api.GetProvider(ref.target)
                    .then(function(val) {
                        rel.target = val;
                        rel.destination = val.toString();
                    });
                    await props.api.GetProvider(ref.source)
                    .then(function(val) {
                        rel.source = val;
                        rel.origin = val.toString();
                    });
                }

                relPromises.push(rel);
            };
        }

        setRelations(relPromises);
    };

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.com.graphs.sankey.specialtyflow'
        });

        setLoading(true);
        let pcpTaxonomyCodesVal = config.pcpTaxonomyCodes();
        let filterVal;
        setPcpTaxonomyCodes(pcpTaxonomyCodesVal);

        community.ResolveMembers(api)
        .then(members => {

            let containsOrgVal;
            let containsPcpVal;
            let containsSpecialistVal;

            members.forEach(function (member) {
                if (member.entitytype == 2) {
                    setContansOrg(true);
                    containsOrgVal = true;
                }
                else if (pcpTaxonomyCodesVal.indexOf(member.taxonomy.code) > -1) {
                    setContainsPcp(true);
                    containsPcpVal = true;
                }
                else {
                    setContainsSpecialist(true);
                    containsSpecialistVal = true;
                }
            });

            if (containsSpecialistVal) {
                setProviderFilter({type:'specialist'});
                filterVal = {type:'specialist'};
            }
            else if (containsPcpVal) {
                setProviderFilter({type:'pcp'});
                filterVal = {type:'pcp'};
            }
            else if (containsOrgVal) {
                setProviderFilter({type:'organization'})
                filterVal = {type:'organization'};
            }

            if (providerFilter || filterVal) {
                fetchEndPointData(filterVal.type === 'organization' ? endpoints[filterVal.type] : `${endpoints[filterVal.type]}${axis && axis === "same" ? "sameday" : axis}/`,payloads[filterVal.type],filterVal.type).then(data => {
                    getRelations(data, filterVal.type, axis ? axis : 'shared');
                  });
            }

            setLoading(false);
        }, err => {
            console.log('error resoving community members', err);
        });

    }, [community]);

    return (
    <div className="specialtyflow-graph" style={{display:'inline',height:'auto',width:'auto'}}>
    {(relations && options && pic && template && pcpTaxonomyCodes)
            ? 
    <div className="sankey-report-container">
        <div className="form-inline" style={{flex:1}}>
            <div className="col-md-9">
                {providerFilter ?
                    <div className="toggle_radio tourscript-input-type">
                        <input type="radio" className="toggle_option" id="first_toggle" name="first_toggle" disabled={!containsPcp} value="pcp" checked={providerFilter.type === "pcp"} onChange={(e) => handleFilterChange(e)}/>
                        <input type="radio" className="toggle_option" id="second_toggle" name="second_toggle" disabled={!containsSpecialist} value="specialist" checked={providerFilter.type === "specialist"} onChange={(e) => handleFilterChange(e)}/>
                        <input type="radio" className="toggle_option" id="third_toggle" name="third_toggle" disabled={!containsOrg} value="organization" checked={providerFilter.type === "organization"} onChange={(e) => handleFilterChange(e)}/>
                        <label htmlFor="first_toggle"><p className={!containsPcp ? "disabled" : ""}  style={!containsPcp ? {color:'#eee'} : {color:'black'} }>Primary Care Physicians</p></label>
                        <label htmlFor="second_toggle"><p className={!containsSpecialist ? "disabled" : ""} style={!containsSpecialist ? {color:'#eee'} : {color:'black'} }>Specialists</p></label>
                        <label htmlFor="third_toggle"><p className={!containsOrg ? "disabled" : ""} style={!containsOrg ? {color:'#eee'} : {color:'black'} }>Organizations</p></label>
                        <div className="toggle_option_slider"></div>
                    </div>
                : ""}
            </div>
            <div className="col-md-3 secondary-button-container" style={{padding:0}}>
                        <div className={`provider-axis-dropdown col-md-6 axis-toggle ${ openDropdown === 'axis' ? 'open' : ''}`} style={{padding:0}}>
                            <button id="values-axis" type="button" onClick={() => {handleDropdownClick('axis')} } className="btn btn-default btn-md btn-block">{ mapping[axis].units } <span className="caret"></span></button>
                            <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis">
                                <li className="radio">
                                    <label>
                                        <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => {toggleAxis('shared')}}></input>
                                        { mapping.shared.units }
                                    </label>
                                </li>
                                <li className="radio">
                                    <label>
                                        <input type="radio" checked={axis === 'same'} value="same" onChange={() => {toggleAxis('same')}}></input>
                                        { mapping.same.units }
                                    </label>
                                </li>
                                <li className="radio">
                                    <label>
                                        <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => {toggleAxis('unique')}}></input>
                                        { mapping.unique.units }
                                    </label>
                                </li>
                            </ul>
                        </div>
            </div>   
        </div>
        <div style={{flex:19}}>
            <PhSankeyComponent
                data={relations}
                opts={options}
                pic={pic}
                template={template}
            />
        </div>
    </div>: <div className="loading-lg"></div>
        }
    </div>)
}