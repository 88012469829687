import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import { react2angular } from 'react2angular';

import { QuadrantAnalysisComponent } from './quadrantAnalysisComponent.js';
import { ScatterPlotComponent } from './scatterPlotComponent.js';

import './quadrantanalysis.scss';
import template from './quadrantanalysis.html';
import controller from './quadrantanalysis.controller';

import d3Bubble from '../../directives/d3Bubble/d3Bubble';
import legendList from '../../directives/legendList/legendList';

const componentName = 'organizationQuadrantanalysis';
const updatedComponentName = 'organizationQuadrantanalysisComponent';
const moduleName = 'app.reports.quadrantanalysis.organization';

angular.module(moduleName, [
    uirouter, d3Bubble, legendList
])
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(
        QuadrantAnalysisComponent,
        ['npis'],
        ['api']
    )
)
.component(
    'scatterplotComponent',
    react2angular(
        ScatterPlotComponent,
        ['data','graphopts','listopts'],
        []
    )
);

export {
    moduleName,
    componentName,
    updatedComponentName
};