import angular from 'angular';

import api from './api';
import localStorage from './localStorage';

export default angular.module('recentlySelected', [
    api, localStorage
])
.service('recentlySelected', ['$localStorage', 'api', '$q', function($localstorage, api, $q) {
    
    var service = this;
    var entitytype;
    
    function determineEntityType(obj) {
        if (obj instanceof api.Organization) {
            entitytype = 'organization';
        } else if (obj instanceof api.Physician) {
            entitytype = 'physician';
        } else {
            entitytype = 'community';
        }
    }
    
    // Full Retrieval
    function fullRetrieval(entitytype) {
        var list = $localstorage.getObj('recentlySelected.' + entitytype) || [];
        return $q.all(list.map(function(obj) {
            return api['Get'+ obj.type](obj.id);
        })).then(function(list) {
            var filteredList = list.filter(function(item) {
                if (item._failed) {
                    service.remove(item);
                }
                return !item._failed;
            });
            return filteredList;
        });
    }
    
    // Top N Retrieval
    service.getRecent = function(entitytype, n) {
        return fullRetrieval(entitytype, n)
        .then(function(list) {
            return list.slice(0, n || 1);
        });
    };
    
    // Storage
    service.store = function(obj) {
        if (obj._failed) {
            return;
        }
        determineEntityType(obj);
        var list = $localstorage.getObj('recentlySelected.' + entitytype) || [];
        list = list.filter(function(listObj) {
            return listObj.id != ( obj.npi || obj.id );
        });
        list.unshift({
            id: obj.npi || obj.id,
            type: obj.npi ? 'Provider' : 'Community'
        });
        $localstorage.setObj('recentlySelected.' + entitytype, list);
    };
    
    service.remove = function(obj) {
        var key = 'recentlySelected.';
        
        if      (obj instanceof api.Community   ) { key += 'community';    }
        else if (obj instanceof api.Physician   ) { key += 'physician';    }
        else if (obj instanceof api.Organization) { key += 'organization'; }
        else                                      { return; }
        
        var list = $localstorage.getObj(key) || [];
        
        list = list.filter(function(item) {
            if (obj.npi) {
                return obj.npi != item.id;
            } else {
                return obj.id != item.id;
            }
        });
        
        $localstorage.setObj(key, list);
    };
    
    return service;
    
}]).name;