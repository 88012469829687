import angular from 'angular';

import uirouter from '@uirouter/angularjs';
import uibModal from 'angular-ui-bootstrap/src/modal';

import './specialtyflow.scss';
import template from './specialtyflow.html';
import controller from './specialtyflow.controller';

const componentName = 'specialtyflow';
const moduleName = 'app.reports.specialtyflow';

angular.module(moduleName, [
    uirouter, uibModal
])
.component(componentName, {
    require: {
        reportSankey: '?^sankey'
    },
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName
};