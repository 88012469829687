import React, {useState, useEffect, useRef} from "react";
import ReactDom from 'react-dom';
import * as parseD from 'd-path-parser';
import { useRouteLoaderData } from "react-router-dom";
import { useApi, usePic, useTemplate, useTourManager, useLogging } from '../../services/servicesContext';
import merge from 'lodash/merge';
import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';

export function PhysicianGeneralflowComponent({notify}) {
    
    const npis = useRouteLoaderData('physician.npis');

    const api = useApi();
    const pic = usePic();
    const template = useTemplate();
    const tourManager = useTourManager();
    const logging = useLogging();
    const [loading, setLoading] = useState(false);
    const [axis, setAxis] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        sameday: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };
    const handleDropdownClick = function (col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
    };

    const toggleAxis = function (property) {
        let optionsClone = { ...options };
        optionsClone.link = {
            value: function (d) {
                return d.values[property];
            }
        };
        merge(optionsClone.toolTip, {
            units: mapping[property].units,
            value: function (d) {
                return d.values[property];
            }
        });
        optionsClone.node.sortAxis = mapping[property].sortAxis;
        setAxis(property);
        setOptions(optionsClone);
        setOpenDropdown(null);
    };

    const graphOptions = {
        node: {
            label: function(d){
                if (d.entitytype == 2) {
                    return d.name.display+' ('+d.npi+')';
                } else if (d.entitytype == 1) {
                    return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                } else { // Community AKA 'Others'
                    return d.name; 
                }
            }
        },
        toolTip: {
            units: 'Shared Visits',
            value: function (d) {
                return d.values.shared;
            }
        }
    };

    const [ relations, setRelations ] = useState(null);
    const [ options, setOptions ] = useState(null);
    
    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'General Flow - 1/4',
                text: "The purpose of the Physician General Flow graphic is to help you visualize a Physician's affinity for the Hospitals with whom they share patients.",
            },
            {
                id: 2,
                attachTo: { element: 'g.nodes g.node rect:not([data-side])', on: 'right' },
                title: 'General Flow - 2/4',
                text: `The selected Physician, ${npis.name.display} is seen here on the left side of the graph.`,
            },{
                id: 3,
                attachTo: { element: 'g.nodes g.node rect[data-side="right"]', on: 'left' },
                title: 'General Flow - 3/4',
                text: `The hospitals that see patients after ${npis.name.display} are shown as the right-side "nodes."`,
            },{
                id: 4,
                attachTo: { element: 'div.sankeyTooltip', on: 'bottom' },
                title: 'General Flow - 4/4',
                text: "Patient volumes are represented by the gray lines and can be hovered over to provide more detail.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var pathD= parseD(elem.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                        
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var moveEvent = new Event('mouseleave');
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                } */
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.phy.graphs.sankey.generalflow`
        });

        async function init() {
            setLoading(true);
            try {
                let data = await api.Relations(npis.npi, 'outbound', { classification: 'General Acute Care Hospital' });
                
                if (data.length === 0) {
                    console.log('No Provider General Flow data found: ', data);
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Provider General Flow data found.',
                        delay: 30000
                    });
                }
                
                data = data.map(relation => {
                    relation.target = {
                        side: 'right',
                        __proto__: relation.target
                    }
                    return relation;
                });
                
                let topN = data.sort(function(a, b) {
                    if (graphOptions?.link?.value) {
                        return graphOptions.link.value(b) - graphOptions.link.value(a);
                    } else {
                         return b.values.shared - a.values.shared;
                    }
                }).splice(0, 19);
                
                if (data.length > 0) {
                    let others = data.reduce(function(rel, item) {
                        rel.values.shared += item.values.shared;
                        rel.values.sameday += item.values.sameday;
                        rel.values.unique += item.values.unique;
                        rel.target.members.push(item.target);
                        
                        return rel;
        
                    }, new api.Relation({
                        source: npis, 
                        target: new api.Community({name: 'others', members: []}),
                        values: {
                            shared: 0,
                            sameday: 0,
                            unique: 0
                        },
                    }));
                    
                    topN.push(others);
                }

                setAxis('shared');
                setOptions(graphOptions);
                setRelations(topN);
                setLoading(false);

            } catch (err) {
                setLoading(false);
                console.error('error requesting relations', err);
            }
        }

        if (!relations) {
            init();
        }

        return () => {
            tourManager.clearTour();
        };

    }, [npis, api]);
    
    return (<>
        {(!loading && relations && options && pic && template)
            ? <div className="sankey-report-container">
                <div style={{ display: 'contents' }}>
                    <div className="col-sm-4 secondary-button-container" style={{ marginRight: 0, marginLeft: 'auto' }}>
                        <div className={`provider-axis-dropdown col-md-6 axis-toggle ${openDropdown === 'axis' ? 'open' : ''}`} style={{ padding: 0 }}>
                            <button id="values-axis" type="button" onClick={() => { handleDropdownClick('axis') }} className="btn btn-default btn-md btn-block">{mapping[axis].units} <span className="caret"></span></button>
                            <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis" style={{ width: '100%' }}>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => { toggleAxis('shared') }}></input>
                                        {mapping.shared.units}
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'sameday'} value="sameday" onChange={() => { toggleAxis('sameday') }}></input>
                                        {mapping.sameday.units}
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => { toggleAxis('unique') }}></input>
                                        {mapping.unique.units}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 reset" style={{ padding: 0 }}>
                            <button type="button" onClick={() => { toggleAxis('shared') }} className="btn btn-default btn-md btn-block">Reset</button>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 19 }}>
                    <PhSankeyComponent
                        data={relations}
                        opts={options}
                        pic={pic}
                        template={template}
                    />
                </div>
            </div>
            : <div className="loading-lg"></div>
        }
    </>);

}