import angular from 'angular';

export default ['$scope', 'api', '$state', '$transitions', 'recentlySelected', 'config', function($scope, api, $state, $transitions, recentlySelected, config) {
    var $ctrl = this;
    
    function setStateVariables(stateName, reportName) {
        
        var stateNameArr = stateName.split('.');
    
        switch(stateNameArr[2]) {
            case 'org':
                $scope.entityType = 'Organizations';
                recentlySelected.getRecent('organization', 11)
                .then(function(recents) {
                    recents.shift();
                    $scope.recentlySelected = recents;
                });
                break;
            case 'phy':
                $scope.entityType = 'Physicians';
                recentlySelected.getRecent('physician', 11)
                .then(function(recents) {
                    recents.shift();
                    $scope.recentlySelected = recents;
                });
                break;
            case 'com':
                $scope.entityType = 'Communities';
                recentlySelected.getRecent('community', 11)
                .then(function(recents) {
                    recents.shift();
                    $scope.recentlySelected = recents;
                });
                break;
            default:
                $scope.entityType = '';
        }
        
        switch(stateNameArr[3]) {
            case 'graphs':
                $scope.reportType = 'Graphs';
                break;
            case 'reports':
                $scope.reportType = 'Reports';
                break;
            default:
                $scope.reportType = undefined;
        }
        
        $scope.reportName = reportName;
    }
    
    setStateVariables($state.current.name, $state.current.data.name);
    
    $ctrl.$onDestroy = $transitions.onEnter({entering: ['root.app.org.**', 'root.app.phy.**', 'root.app.com.**', 'root.app.dbs.**']}, function(transition, state) {
        
        if (transition.to().name != state.name) {
            return;
        }
        
        if (state.data) {
            setStateVariables(state.name, state.data.name);
        }
    });
    
    function updateBreadcrumbMenuOptions() {
        if ($state.params.npis) {
            api.GetProvider($state.params.npis)
            .then(function(provider) {
                $scope.provider = provider;
                return config.getMenuOptions(provider);
            })
            .then(function(menuOpts){
                $scope.menuOptions = menuOpts;
            });
        } else if ($state.params.cid) {
            api.GetCommunity($state.params.cid)
            .then(function(community) {
                $scope.community = community;
                return config.getMenuOptions(community);
            })
            .then(function(menuOpts){
                $scope.menuOptions = menuOpts;
            });
        }
    }
    
    updateBreadcrumbMenuOptions();
    
    $scope.limitMenu = "Graphs, Reports"; 
    
    $scope.containsComparator = function(expected, actual){  
        return actual.indexOf(expected) > -1;
    };
    
    $scope.$on('recentlySelectedChange', updateBreadcrumbMenuOptions);
    
    $ctrl.closePopover = function() {
        $scope.isOpenReport = false;
    };

}];