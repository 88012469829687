import angular from 'angular';
import { react2angular } from 'react2angular';
// import './marketbenchmark.scss';
import template from './geographicbenchmark.html';
import controller from './geographicbenchmark.controller';

import api from '../../services/api';
import { GeographicbenchmarkComponent } from './geographicbenchmarkComponent';

const componentName = 'physicianGeographicBenchmarksDashboard';
const moduleName = 'app.reports.dashboards.geographicbenchmark.physician';
const updatedComponentName = 'geographicbenchmarkComponent';

angular.module(moduleName, [
    api
])
.component(
    updatedComponentName,
    react2angular(GeographicbenchmarkComponent, ['api', 'npis', 'notify', 'download'], ['api', 'notify', 'download', '$state', '$timeout'])
)
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName,
    updatedComponentName
};