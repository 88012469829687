import React, { useState, useEffect } from 'react'
import { useRouteLoaderData } from "react-router-dom";

import { useApi, usePic, useTemplate } from '../../services/servicesContext';
import merge from 'lodash.merge';

export default function SummarySharedvisitsComponent({ notify }) {
    const [Provider, setProvider] = useState([]);
    const [loading, setLoading] = useState(true);
    const [SharedVisits, setSharedVisits] = useState({});
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const api = useApi();

    useEffect(() => {
        onInit();
    }, [npis])
    
    const onInit = async () => {
        if (!npis) return;
        setLoading(true)
        const provider = npis;
        setProvider(provider);
        var accumulator = {
            sharedFrom: 0,
            uniqueFrom: 0,
            sameday: 0,
            sharedTo: 0,
            uniqueTo: 0,
            otherNpis: []
        };

        await api.Relations(provider.npi)
        .then(function(relations) {
            
            if (relations.length === 0) {
                console.log('No Provider Brief Shared Visits data found: ', relations);
                notify.alert({
                    title: 'NO DATA',
                    text: 'No Provider Brief Shared Visits data found.',
                    delay: 30000
                });
            }

            var sharedVisits = relations.reduce((obj, rel) => {
                if (rel.source.npi == provider.npi) {
                    obj.sharedFrom += rel.values.shared;
                    obj.uniqueFrom += rel.values.unique;
                    if (obj.otherNpis.indexOf(rel.target.npi) == -1) {
                        obj.sameday += rel.values.sameday;
                        obj.otherNpis.push(rel.target.npi);
                    }
                } else {
                    obj.sharedTo += rel.values.shared;
                    obj.uniqueTo += rel.values.unique;
                    if (obj.otherNpis.indexOf(rel.source.npi) == -1) {
                        obj.sameday += rel.values.sameday;
                        obj.otherNpis.push(rel.source.npi);
                    }
                }
                return obj;
            }, accumulator);
            
            setSharedVisits(sharedVisits);
            setLoading(false);
            
        }).catch(err => {
            console.log('Error fetching Provider Brief Shared Visits data: ', err);
            setLoading(false);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Provider Brief Shared Visits data.',
                delay: 30000
            });
        });
    };
  return (
    <>
    {/* <div className="loading-sm" style={{ 'display' : (loading) ? 'inline' : 'none' }}></div> */}
    <table className="table table-bordered">
        <thead>
            <tr>
                <th className="text-right">Shared Visits From</th>
                <th className="text-right">Unique Patients From</th>
                <th className="text-right">Same Day Visits</th>
                <th className="text-right">Shared Visits To</th>
                <th className="text-right">Unique Patients To</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className="text-right">
                    { SharedVisits?.sharedFrom && !loading
                        ? SharedVisits.sharedFrom.toLocaleString()
                        : <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i>
                    }
                </td>
                <td className="text-right">
                    { SharedVisits?.uniqueFrom && !loading
                        ? SharedVisits.uniqueFrom.toLocaleString()
                        : <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i>
                    }
                </td>
                <td className="text-right">
                    { SharedVisits?.sameday && !loading
                        ? SharedVisits.sameday.toLocaleString()
                        : <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i>
                    }
                </td>
                <td className="text-right">
                    { SharedVisits?.sharedTo && !loading
                        ? SharedVisits.sharedTo.toLocaleString()
                        : <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i>
                    }
                </td>
                <td className="text-right">
                    { SharedVisits?.uniqueTo && !loading
                        ? SharedVisits.uniqueTo.toLocaleString()
                        : <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i>
                    }
                </td>
            </tr>
        </tbody>
    </table>
    </>
  )
}
