import angular from 'angular';

import { react2angular } from 'react2angular';

import template from './breadcrumb.html';
import controller from './breadcrumb.controller';
import './breadcrumb.scss';

import api from '../../services/api';
import recentlySelected from '../../services/recentlySelected';
import config from '../../services/config';

import { BreadcrumbComponent } from './breadcrumbComponent';

const componentName = 'breadcrumb';
const updatedComponentName = 'breadcrumbComponent';
const moduleName = 'app.breadcrumb';
import { ServicesProvider, useConfig, useApi } from '../../services/servicesContext';

function breadCrumbComponentRoute(props) {
    return (
        <ServicesProvider>
            <BreadcrumbComponent
                config={props.config}
                recentlySelected={props.recentlySelected}
                api={props.api}
                tour={props.tour}
                template={props.template}
                $timeout={props.$timeout}
                auth={props.auth}
            />
        </ServicesProvider>
    )
}


angular.module(moduleName, [
    api, recentlySelected, config
])
.run(['$rootScope', '$state', '$stateParams', function ($rootScope, $state, $stateParams) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams; 
}])
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(
        breadCrumbComponentRoute,
        ['npis'],
        ['api', 'recentlySelected', 'config']
    )
);

export {
    moduleName,
    componentName,
    updatedComponentName
};