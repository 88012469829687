export default ['$scope', '$state', '$transitions', '$timeout', function($scope, $state, $transitions, $timeout) {
    var $ctrl = this;
        
    $ctrl.$onInit = function() {
        $ctrl.name = $state.current.data.name;
        $scope.npis = $ctrl.npis;
    };
    
    $ctrl.$onDestroy = $transitions.onEnter({entering: ['root.app.org.graphs.sankey.**', 'root.app.phy.graphs.sankey.**', 'root.app.com.graphs.sankey.**']}, function(transition, state) {
        if(state.data) $ctrl.name = state.data.name;
        
        $ctrl.sankey = $scope.sankey;
        $scope.options = {};
        $scope.loaded = false;
    });
    
    $ctrl.data = function(relations) {
        $timeout(function() {
            $scope.loaded = true;
            $scope.data = relations;//directive is watching this
        });
    };
    
    $ctrl.options = function(opts) {
        $scope.options = opts;
    };
    
    $scope.bindSankeyMouseClick = function(){
        
        $scope.$on('sankey.link.click', function(event, d3Event, datum, context) {
            if (event.targetScope != $scope) {
                $scope.$broadcast('sankey.link.click', d3Event, datum, context);
            }
        });
        
        $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
            if (event.targetScope != $scope) {
                $scope.$broadcast('sankey.node.click', d3Event, datum, context);
            }
        });
        
    };
    
    $scope.bindSankeyMouseClick($scope);
}];