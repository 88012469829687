import React, { useState, useReducer, useEffect, useContext, useRef, useMemo } from "react";
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import merge from 'lodash/merge';
import {
    createSearchParams,
    json,
    useLocation
} from "react-router-dom";
import { toast } from "react-toastify";
import Shepherd from 'shepherd.js';
//end library imports

//our imports
import localStorage from "./localStorageModule";
import { Provider }  from '../models/providerClass';
import { Physician }  from '../models/physicianClass';
import { Organization }  from '../models/organizationClass';
import { Community }  from '../models/communityClass';
import Taxonomy from '../models/taxonomyClass';
import Relation from '../models/relationClass';

import { getToken, getFetchHeaders } from "../js/getToken";
import { ClinicalComponent } from "../reports/clinical.physician/clinicalComponent";

//begin hardcoded values
const statesArr = [
    { value: "AL", name: "Alabama"},
    { value: "AK", name: "Alaska"},
    { value: "AZ", name: "Arizona"},
    { value: "AR", name: "Arkansas"},
    { value: "CA", name: "California"},
    { value: "CO", name: "Colorado"},
    { value: "CT", name: "Connecticut"},
    { value: "DE", name: "Delaware"},
    { value: "DC", name: "District Of Columbia"},
    { value: "FL", name: "Florida"},
    { value: "GA", name: "Georgia"},
    { value: "HI", name: "Hawaii"},
    { value: "ID", name: "Idaho"},
    { value: "IL", name: "Illinois"},
    { value: "IN", name: "Indiana"},
    { value: "IA", name: "Iowa"},
    { value: "KS", name: "Kansas"},
    { value: "KY", name: "Kentucky"},
    { value: "LA", name: "Louisiana"},
    { value: "ME", name: "Maine"},
    { value: "MD", name: "Maryland"},
    { value: "MA", name: "Massachusetts"},
    { value: "MI", name: "Michigan"},
    { value: "MN", name: "Minnesota"},
    { value: "MS", name: "Mississippi"},
    { value: "MO", name: "Missouri"},
    { value: "MT", name: "Montana"},
    { value: "NE", name: "Nebraska"},
    { value: "NV", name: "Nevada"},
    { value: "NH", name: "New Hampshire"},
    { value: "NJ", name: "New Jersey"},
    { value: "NM", name: "New Mexico"},
    { value: "NY", name: "New York"},
    { value: "NC", name: "North Carolina"},
    { value: "ND", name: "North Dakota"},
    { value: "OH", name: "Ohio"},
    { value: "OK", name: "Oklahoma"},
    { value: "OR", name: "Oregon"},
    { value: "PA", name: "Pennsylvania"},
    { value: "RI", name: "Rhode Island"},
    { value: "SC", name: "South Carolina"},
    { value: "SD", name: "South Dakota"},
    { value: "TN", name: "Tennessee"},
    { value: "TX", name: "Texas"},
    { value: "UT", name: "Utah"},
    { value: "VT", name: "Vermont"},
    { value: "VA", name: "Virginia"},
    { value: "WA", name: "Washington"},
    { value: "WV", name: "West Virginia"},
    { value: "WI", name: "Wisconsin"},
    { value: "WY", name: "Wyoming"},
    { value: "PR", name: "Puerto Rico"}
];

const allRoles = [
    'Global Admin',
    'Brand Admin',
    'Admin'
];

var list = [];
var altList = [];
var description = 'Toggle between the list of providers visible in the content area and the full list of provders in the graph or report.';
var temporaryList = false;
var message = 'Toggle between the list of providers visible in the content area and the full list of provders in the graph or report.';
var source = "";
//end hardcoded values


//function for binding config api data
function bindData(data, stateSetter) {
    stateSetter({
        brand: data.brand,
        accounts: data.accounts,
        models: data.models,
        version: data.version,
        manual: data.manual,
        pcpTaxonomyCodesArr: data.taxonomy.pcp,
        radiologyTaxonomyCodesArr: data.taxonomy.radiology,
        laboratoryTaxonomyCodesArr: data.taxonomy.laboratory,
        gacTaxonomyCodesArr: data.taxonomy.gac,
        postAcuteTaxonomies: data.taxonomy.report.postacute,
        marketMapTaxonomies: data.taxonomy.report.marketmap,
        cognito: data.cognito,
        affiliations_api: data.affiliations_api,
        codes_api: data.codes_api,
        account: data.account,
        forecasting_api: data.forecasting_api                   
    });
}

// deferred is effectively a promise that can be externally rejected or resolved
// used withing batching system
function createDeferred() {
    let resolve;
    let reject;
    const p = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    p.resolve = resolve;
    p.reject = reject;
    return p;
}

//setup contexts for eventually nested wrapping for single provider
const ConfigContext = React.createContext();
const AuthContext = React.createContext();
const StorageContext = React.createContext();
const ApiContext = React.createContext();
const UserContext = React.createContext();
const TemplateContext = React.createContext();
const ForecastingContext = React.createContext();
const PicContext = React.createContext();
const RecentlySelectedContext = React.createContext();
const AffiliationsContext = React.createContext();
const CommunityListContext = React.createContext();
const CodesContext = React.createContext();
const NotifyContext = React.createContext();
const TourManagerContext = React.createContext();
const LoggingContext = React.createContext();

// begin servicesprovider custom hooks
function useConfig() {
    const context = React.useContext(ConfigContext);
    if (context === undefined) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
}

function useStorage() {
    const context = React.useContext(StorageContext);
    if (context === undefined) {
        throw new Error('useStorage must be used within a StorageProvider');
    }
    return context;
}

function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

function useApi() {
    const context = React.useContext(ApiContext);
    if (context === undefined) {
        throw new Error('useApi must be used within a ApiProvider');
    }
    return context;
}

function useTemplate() {
    const context = React.useContext(TemplateContext);
    if (context === undefined) {
        throw new Error('useTemplate must be used within a TemplateProvider');
    }
    return context;
}

function useForecasting() {
    const context = React.useContext(ForecastingContext);
    if (context === undefined) {
        throw new Error('useForecasting must be used within a ForecastingProvider');
    }
    return context;
}

function usePic() {
    const context = React.useContext(PicContext);
    if (context === undefined) {
        throw new Error('usePic must be used within a PicProvider');
    }
    return context;
}

function useRecentlySelected() {
    const context = React.useContext(RecentlySelectedContext);
    if (context === undefined) {
        throw new Error('useRecentlySelected must be used within a RecentlySelectedProvider');
    }
    return context;
}

function useCommunityList() {
    const context = React.useContext(CommunityListContext);
    if (context === undefined) {
        throw new Error('useCommunityList must be used within a CommunityList');
    }
    return context;
}

function useCodes() {
    const context = React.useContext(CodesContext);
    if (context === undefined) {
        throw new Error('useCodes must be used within a CodesContext');
    }
    return context;
}

function useAffiations() {
    const context = React.useContext(AffiliationsContext);
    if (context === undefined) {
        throw new Error('useAffiations must be used within a AffiliationsContextProvider');
    }
    return context;
}

function useNotify() {
    const context = React.useContext(NotifyContext);
    if (context === undefined) {
        throw new Error('useNotify must be used within a NotifyContextProvider');
    }
    return context;
}

function useTourManager() {
    const context = React.useContext(TourManagerContext);
    if (context === undefined) {
        throw new Error('tourManager must be used within a TourManagerProvider');
    }
    return context;
}

function useLogging() {
    const context = React.useContext(LoggingContext);
    if (context === undefined) {
        throw new Error('logging must be used within a LoggingProvider');
    }
    return context;
}
// end servicesprovider custom hooks

function ServicesProvider(props) {

    //state that will finally be set to true on main useeeffect hook after all async bootstraping of services provider
    const [ ready, setReady ] = useState(false);

    // config context state
    const [configState, setConfigState] = useState({});

    // user context state
    const [currentUser, setCurrentUser] = useState(null);

    // storage context state
    const [storageData, setStorageData] = useState({});
    const [storageUserId, setStorageUserId] = useState(null);



    // Community List context state
    const [listFinal, setListFinal] = useState([]);
    const [altListFinal, setAltListFinal] = useState([]);
    const [descriptionFinal, setDescriptionFinal] = useState('Toggle between the list of providers visible in the content area and the full list of provders in the graph or report.');
    const [temporaryListFinal, setTemporaryListFinal] = useState(false);
    const [messageFinal, setMessageFinal] = useState('Toggle between the list of providers visible in the content area and the full list of provders in the graph or report.');

    // template context state
    const [toggleNavSidebar, setToggleNavSidebar] = useState(false);
    const [nonReportingProvider, setNonReportingProvider] = useState(null);
    const [templateTitle, setTemplateTitle] = useState({
        prefix: '',
        value: '',
        suffix: '',
        delimiter: ''
    });
    const [templateHeader, setTemplateHeader] = useState({
        title: '',
        url: ''
    });

    const tourInstance = useRef(null);

    //initialize cached api request data
    const cache = useRef({
        config: {},
        taxonomies: {},
        groups: {},
        community: {},
        npi: {},
        cbsa: {},
    });

    //initialize batching api queues
    const batchQueues = useRef({
        npi: [],
        cbsa: [],
        clinical: [],
        charges: []
    });

    const options = useRef({});

    const resizeCallbacks = useRef({});
    
    // pic context state
    const communities = useRef({});
    const memberships = useRef({});

    // recentlySelected state
    const entitytype = useRef(null);

    // logging pathname previous path cache
    const pathname = useRef(null);

    function updateToken() {
        Auth.currentSession()
            .then(res => {
                var idToken = res.getIdToken().getJwtToken();
                optionsFn({ headers : {
                    Authorization: `Bearer ${idToken}`
                }});
                return true;
            })
            .catch(err => {
                console.log('Auth.currentSession() error', err);
            });
    }

    function updateCache(obj) {
        if (obj instanceof Provider) cache.current['npi'][obj.npi] = Promise.resolve(obj);
        else if (obj instanceof Community) cache.current['community'][obj.id] = Promise.resolve(obj); 
    }

    function updateStorage() {
        return fetch(
            `${configState.account.api}/profile/${ storageUserId }/config/TEAM`,
            {
                headers: {
                    Authorization: optionsFn().headers.Authorization
                }
            }
        )
        .then(req => req.json())    
        .then(res => {
            if (res) {
                setStorageData(res);
                optionsFn({
                    headers: { 
                        "X-TEAM-TTM": res.ttm
                    }
                });
                localStorage.setObj('team.storage', res);
            } else {
                fetch(
                    `${configState.account.api}/profile/${ storageUserId }/config/TEAM`,
                    {
                        method: 'PUT',
                        body: JSON.stringify({ttm: configState.models[0].name}),
                        headers: {
                            Authorization: optionsFn().headers.Authorization
                        }
                    }
                )
                .then(req => req.json())
                .then(response => {
                    setStorageData({ttm: configState.models[0].name});
                    localStorage.setObj('team.storage', {ttm: configState.models[0].name});
                    optionsFn({
                        headers: { 
                            "X-TEAM-TTM": configState.models[0].name
                        }
                    });
                }, err => {
                    console.error('error uploading ttm', err);
                });
            }
        }, err => {
            console.error('error initially fetching storage data', err);
            if (configState.models.length > 0) {
                fetch(
                    `${configState.account.api}/profile/${ storageUserId }/config/TEAM`,
                    {
                        method: 'PUT',
                        body: JSON.stringify({ttm: configState.models[0].name}),
                        headers: {
                            Authorization: optionsFn().headers.Authorization
                        }
                    }
                )
                .then(req => req.json())
                .then(response => {
                    setStorageData({ttm: configState.models[0].name});
                    localStorage.setObj('team.storage', {ttm: configState.models[0].name});
                    optionsFn({
                        headers: { 
                            "X-TEAM-TTM": configState.models[0].name
                        }
                    });
                }, err => {
                    console.error('error uploading ttm', err);
                });
            }
            
            
        });
    }

    function initStorage({userId, accountApi, token, models}) {
        return fetch(
            `${ accountApi }/profile/${ userId }/config/TEAM`,
            {
                headers: token
            }
        )
        .then(req => req.json())    
        .then(res => {
            if (res) {
                setStorageData(res);
                optionsFn({
                    headers: { 
                        "X-TEAM-TTM": res.ttm
                    }
                });
                localStorage.setObj('team.storage', res);
            } else {
                fetch(
                    `${ accountApi }/profile/${ userId }/config/TEAM`,
                    {
                        method: 'PUT',
                        body: JSON.stringify({ttm: models[0].name}),
                        headers: token
                    }
                )
                .then(req => req.json())
                .then(response => {
                    setStorageData({ttm: models[0].name});
                    localStorage.setObj('team.storage', {ttm: models[0].name});
                    optionsFn({
                        headers: { 
                            "X-TEAM-TTM": models[0].name
                        }
                    });
                }, err => {
                    console.error('error uploading ttm', err);
                });
            }
        }, err => {
            console.error('error initially fetching storage data', err);
            if (models.length > 0) {
                fetch(
                    `${ accountApi }/profile/${ userId }/config/TEAM`,
                    {
                        method: 'PUT',
                        body: JSON.stringify({ttm: models[0].name}),
                        headers: token
                    }
                )
                .then(req => req.json())
                .then(response => {
                    setStorageData({ttm: models[0].name});
                    localStorage.setObj('team.storage', {ttm: models[0].name});
                    optionsFn({
                        headers: { 
                            "X-TEAM-TTM": models[0].name
                        }
                    });
                }, err => {
                    console.error('error uploading ttm', err);
                });
            }
        });
    }

    function getStorage(key) {
        if (storageData && storageData[key]) {
            return storageData[key];
        } else if (localStorage.getObj('team.storage'))  {
            return localStorage.getObj('team.storage')[key];
        } else {
            return false;
        }
    }

    function setStorageUserIdFn(userId) {
        setStorageUserId(userId);
    }

    function fetchStorage(key) {
        return fetch(`${configState.account.api}/profile/${storageUserId}/config/TEAM`, { headers: { Authorization: optionsFn().headers.Authorization}})
        .then(req => req.json())
        .then(res => {
            setStorageData(prev => {
               return {...prev, ...res}; 
            });
            let wholeObj = merge({}, localStorage.getObj('team.storage'), {[key]: res});
            localStorage.setObj('team.storage', wholeObj);
            return res[key];
        }, err => {
            console.error('error fetching storage data', err);
        });
    }

    function setStorage(key, value) {
        return fetch(`${configState.account.api}/profile/${storageUserId}/config/TEAM`, { method: 'PUT', body: JSON.stringify({[key]: value}), headers: { Authorization: optionsFn().headers.Authorization}})
        .then(req => req.json())
        .then(res => {
            setStorageData(prev => {
                return {...prev, key: res[key]};
            });
            localStorage.setObj('team.storage', merge({}, localStorage.getObj('team.storage'), {[key]: value}));
            optionsFn({
                headers: { 
                    "X-TEAM-TTM": key === 'ttm' ? value : storageData.ttm 
                }
            });
            return res[key];
        }, err => {
            console.error('error setting storage data', err);
        });
    }

    function currentUserFn() {
        return new Promise((resolve, reject) => {
            if (currentUser) {
                return resolve(currentUser);
            } else {
                return reject(null);
            }
        });
    }

    function cognitoLogout() {
        Auth.signOut()
        .then(res => {
            notifyToast({ text: 'You have successfully logged out.', type: 'info'});
            setCurrentUser(null);
            window.location.assign(configState.account.ui);
        }, err => {
            console.log('log out error', err);
            notifyToast({ text: 'You have successfully logged out.', type: 'error'});
        });
    }

    function getUserById(userId) {
        return fetch(`${configState.account.api}/profile/${userId}`, { headers: { Authorization: optionsFn().headers.Authorization}})
            .then(req => req.json())
            .then(res => {
                return res;
            }, err => {
                console.log('error getting profile', err);
            });
    }

    function getCurrentUserProfile() {
        return fetch(`${configState.account.api}/profile/`, { headers: { Authorization: optionsFn().headers.Authorization}})
            .then(req => req.json())
            .then(res => {
                return res;
            }, err => {
                console.log('error getting profile', err);
            });
    }

    function validateCodesUsage(postBody) {
        /*
          post method with body like:
          { "codes": ["msdrg_004","msdrg_025","msdrg_005","cpt_S5566"] }
        */
          if (configState.forecasting_api) {
            return fetch(
              `${configState.forecasting_api}/codes`,
              {
                method: 'POST',
                body: JSON.stringify(postBody),
                headers: {
                  Authorization: optionsFn().headers.Authorization
                }
              }
            )
            .then(req => req.json());
          } else {
            notifyToast({ text: 'Unable to retrieve forecasting data.', type: 'error', delay: 30000});
            return Promise.reject('forecasting endpoint not found');
          }
    }

    function getForecastingData(geographies, codesetCodes) {
        if (configState.forecasting_api) {
            return fetch(
              `${configState.forecasting_api}/usage?geos=${geographies}&codes=${codesetCodes}`,
              {
                  headers: {
                      Authorization: optionsFn().headers.Authorization
                  }
              }
            )
            .then(req => req.json());
        } else {
            notifyToast({ text: 'Unable to retrieve forecasting data.', type: 'error', delay: 30000});
            return Promise.reject('forecasting endpoint not found');
        }
    }

    /* Community List */
    var defaultDescription = 'Toggle between the list of providers visible in the content area and the full list of provders in the graph or report.'

    function get() {
        let val = {};
        val.list = list;
        val.altList = altList;
        val.description = description;
        val.message = message;
        val.temporaryList = temporaryList;
        val.source = source;
        
        return val;
    }

    function update ({listVal, altListVal, descriptionVal, sourceVal}) {
        list = listVal || [];
        altList = altListVal || [];
        source = sourceVal || "";
        
        description = descriptionVal || defaultDescription;

        /*setListFinal(listVal || []);
        setAltListFinal(altListVal || []);
        setDescriptionFinal(descriptionVal || defaultDescription);*/
    }

    function reset () {
        list = [];
        altList = [];
        description = defaultDescription;
        source = "";

        /*setListFinal([]);
        setAltListFinal([]);
        setDescriptionFinal(defaultDescription);*/
    }

    /* Codes */
    function searchCodesGet(codes) {
        if (configState.codes_api) {
            return fetch(
            `${configState.codesEndpoint}/codes/search/${codes.join(',')}/`,
            {
                headers: {
                    Authorization: optionsFn().headers.Authorization
                }
            }
            )
            .then(res => res.json())
            .then(res => {
                return res;
            });
        } else {
            return Promise.reject('codes endpoint not found');
        }
    }

    function searchCodesPost(searchObj) {
        if (configState.codes_api) {
            return fetch(
            `${configState.codesEndpoint}/codes/search/`,
            {
                method: 'POST',
                headers: {
                    Authorization: optionsFn().headers.Authorization
                },
                // body: searchObj
                body: JSON.stringify(searchObj)
            }
            )
            .then(res => res.json())
            .then(res => {
                return res;
            });
        } else {
            return Promise.reject('codes endpoint not found');
        }
    }

    function searchCodesetServicelines(codesetStr) {
        if (configState.codes_api) {
            return fetch(
            `${configState.codesEndpoint}/servicelines/${codesetStr}`,
            {
                headers: {
                    Authorization: optionsFn().headers.Authorization
                }
            }
            )
            .then(res => res.json())
            .then(res => {
                return res;
            });
        } else {
            return Promise.reject('codes endpoint not found');
        }
    }

    /* Recently Selected */
    
    function determineEntityType(obj) {
        if (obj instanceof Organization) {
            entitytype.current = 'organization';
        } else if (obj instanceof Physician) {
            entitytype.current = 'physician';
        } else {
            entitytype.current = 'community';
        }
    }

    async function fullRetrieval({entitytype, n, auth}) {

        var context = {};
        context["GetProvider"] = GetProvider;
        context["GetCommunity"] = GetCommunity;

        function execFn(fnName, ctx /*, args */) 
        {
        var args = Array.prototype.slice.call(arguments, 2);
        return ctx[fnName].apply(ctx, args);
        }

        var list = localStorage.getObj('recentlySelected.' + entitytype) || [];
        const listPromises = [];
        for (var obj of list) {
            let functionVal = 'Get' + obj.type;
            let listVal;
            if (obj.type == "Community" && obj.id) {
                await execFn(functionVal, context, obj.id, auth)
                .then(val => {
                    listVal = val;
                })
            }
            else {
                if (obj.id) {
                    await execFn(functionVal, context, obj.id)
                    .then(val => {
                        listVal = val;
                    });
                }
            }

            listPromises.push(listVal);
        };

        if (listPromises.length > 0) {
            var filteredList = listPromises.filter(function(item) {
                if (item != undefined) {
                    if (item._failed) {
                        remove(item);
                    }
                    return !item._failed;
                }
            });
            return filteredList;
        }

    }

    function getRecent({entitytype, n, auth}) {
        return fullRetrieval({entitytype:entitytype, n:n, auth:auth})
        .then(function(list) {
            let returnVal;
            if (entitytype == "community") {
                // returnVal = list.slice(0, 1);
                returnVal = list.slice(0, n || 1);
            }
            else {
                returnVal = list.slice(0, n || 1);
            }
            return returnVal;
        });
    };

    
    function store(obj) {
        if (obj._failed) {
            return;
        }
        determineEntityType(obj);
        let listEntityTypeCheck = ["physician", "organization", "community"];
        var list = localStorage.getObj('recentlySelected.' + entitytype.current) || [];
        list = list.filter(function(listObj) {
            return listObj.id != ( obj.npi || obj.id );
        });

        //Additional logic to ensure local storage is limited to 10 entries for physician, organization, and community
        if (listEntityTypeCheck.includes(entitytype.current)) {
            list = list.slice(0,10);
        }

        list.unshift({
            id: obj.npi || obj.id,
            type: obj.npi ? 'Provider' : 'Community'
        });
        localStorage.setObj('recentlySelected.' + entitytype.current, list);
    };

    function remove(obj) {
        var key = 'recentlySelected.';
        
        if      (obj instanceof Community   ) { key += 'community';    }
        else if (obj instanceof Physician   ) { key += 'physician';    }
        else if (obj instanceof Organization) { key += 'organization'; }
        else                                      { return; }
        
        var list = localStorage.getObj(key) || [];
        
        list = list.filter(function(item) {
            if (obj.npi) {
                return obj.npi != item.id;
            } else {
                return obj.id != item.id;
            }
        });
        
        localStorage.setObj(key, list);
    };

    function picUpdate() {
        
        memberships.current = {};
        communities.current = {};
        
        return Groups()    
        .then(function(groups) {
            groups.forEach(function(group) {
                communities.current[group.id] = group;
                
                group.npis.forEach(function(npi) {
                    if (!memberships.current[npi]) {
                        memberships.current[npi] = [ group.id ];
                    } else {
                        memberships.current[npi].push(group.id);
                    }
                });
            });
            
        }).catch( function(err) {
            console.log('Pic Service Error:', err);
        });

    };

    // pic
    function pic(provider, getAll) {
        //no arguments mean get all USERS communities, provider argument means that get provider's watched communities, provider and getAll arguments mean get all provider's communities
        if (!provider && !getAll) {
            var communitiesArr = [];
            
            Object.keys(communities.current).forEach(function(key) {
                communitiesArr.push(communities.current[key]);
            });

            return communitiesArr;
        } 

        var index = provider + '';
        if (provider instanceof Provider) {
            index = provider.npi;
        }
        var membership = memberships.current[index];
        
        //provider argument means that get provider's watched communities
        if (!membership) {
            return [];
        } else {
            return membership.map(function(cid) {
                return communities.current[cid];
            }).filter(function(community) { 
                return getAll || community.watched;
            });
        }
    }
    pic.Update = picUpdate;
    pic.ready = ready;

    // template.title
    function title(value) {
        if ( typeof value === 'undefined' ) { // getter
            var title = '';
            if ( templateTitle.prefix && templateTitle.value) {
                title += templateTitle.prefix + templateTitle.delimiter;
            }
            title += templateTitle.value;
            if ( templateTitle.suffix && templateTitle.value) {
                title += templateTitle.delimiter + templateTitle.suffix;
            }
            return title;
        } else { // setter
            // srvc.title.value = value;
            setTemplateTitle(prev => {
                return {...prev, value: value};
            });
        }
    }

    // template.header
    function header(value) {
        if (typeof value === 'undefined') {//getter
            var header = {};
            header = templateHeader;
            return header;
        } else {//setter
            setTemplateHeader(value);
            return value;
        }
    }

    // template.nonReportingNpi
    function nonReportingNpi(provider) {
        setNonReportingProvider(provider);
    }

    // template.toggleNavSidebar
    function toggleNavSidebarFn() {
        Object.keys(resizeCallbacks.current).forEach(key => {
            resizeCallbacks.current[key]();
        });
        window.dispatchEvent(new Event('resize'));
        setToggleNavSidebar(!toggleNavSidebar);
    }

    // template.contentResize
    function contentResize(callback) {
        //TODO: handle adding and removing callback event listeners to toggle and window resize events
        const callbackId = crypto.randomUUID();
        window.addEventListener('resize', callback);
        resizeCallbacks.current[callbackId] = callback;
        return () => {
            window.removeEventListener('resize', callback);
            delete resizeCallbacks.current[callbackId];
        };
    }

    // template.openNavSidebar
    function openNavSidebar() {
        if (toggleNavSidebar) {
            Object.keys(resizeCallbacks.current).forEach(key => {
                resizeCallbacks.current[key]();
            });
            window.dispatchEvent(new Event('resize'));
            setToggleNavSidebar(false);
        } 
    }

    function optionsFn(opts) {
        if (opts) {
            options.current = merge(options.current, opts);
        } else {
            return options.current;
        }
    }

    function getOptions() {
        return JSON.parse(JSON.stringify(options.current))
    }

    async function getReport(entity, report, opts) {
        try {
            var urlBase = '/api/';

            if (entity instanceof Provider)
                urlBase += 'npi/';
            else
                urlBase += 'community/';
                
            urlBase += entity.valueOf();
            
            var httpOptions = merge({
                method: 'GET',
                url: urlBase
            }, options.current, opts);
            
            switch (report) {
                case 'geoflow':
                    httpOptions.url += '/geoflow/';
                    break;
                case 'flow':
                    httpOptions.url += '/flow/';
                    httpOptions.method = 'POST';
                    break;
                case 'specialtyflow':
                    httpOptions.url += '/specialty_flow/';
                    break;
            }
            
            const req = await fetch(httpOptions);
            const res = await req.json();
            return res;

        } catch (err) {
            console.log('Report Retrieval Error:', report, err, );
            notifyToast({ text: 'Error fetching Provider report data.', type: 'error', delay: 30000});
            return Promise.reject(err);
        }
    }

    function updateCommunity(community) {
        if (community.id) {
            return new Promise(function(resolve, reject) {
                fetch(community['@href'], merge({}, options.current, {
                    method: 'PUT',
                    url: community['@href'],
                    body: JSON.stringify(community.serialize()),
                    headers: {'Content-Type':'application/json'}
                }))
                .then(req => req.json())
                .then(res => {
                    Community.call(community, res);
                    resolve(community);
                })
                .catch(err => {
                    reject(err);
                });
            });
        } else {
            return new Promise(function(resolve, reject) {
                fetch('/api/community/', merge({}, options.current, {
                    method: 'POST',
                    body: JSON.stringify(community.serialize()),
                    headers: {'Content-Type':'application/json'}
                }))
                .then(res => res.json())
                .then(res => {
                    Community.call(community, res);
                    updateCache(community);
                    resolve(community);
                })
                .catch( err => {
                    reject(err);
                });
            });
        }
    }

    function deleteCommunity(community) {
        return fetch(community['@href'], merge({}, options.current, {
            method: 'DELETE',
        }))
        .then(req => req.json())
        .then(res => {
            delete cache.current['community'][community.id];
            remove(community);
            return res;
        })
        .catch(err => Promise.reject(err));
    }

    function getServiceArea(provider) {
        return fetch(merge({}, options.current, {
            method: 'GET',
            url: 'api/npi/' + provider.npi + '/service-area/'
        }))
        .then(req => req.json())
        .then(function(res) {
            return res;
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    function Relations(npi, direction, opts) {
        var url = "/api/npi/" + npi + "/relations/";

        if (direction) {
            url = url + direction + "/";
        }
        
        if (!opts) {
            opts = {};
        } else {
            url = url + `?${createSearchParams(opts).toString()}`
        }
        
        return fetch(url, merge({}, options.current, { params: opts }))
        .then(req => req.json())
        .then(res => {
            return buildRelations(res);
        })
        .catch(err => {
            return Promise.reject(err);
        });
    }

    function CommunityRelations(cid, direction, options) {

        var url = "/api/cid/" + cid + "/relations/";
        
        if (direction) {
            url = url + direction + "/";
        }
        
        if (!options) {
            options = {};
        }
        else {
            url = url + `?${createSearchParams(options).toString()}`
        }
        
        return fetch(url, merge({}, optionsFn(), { params: options }))
        .then(req => req.json())
        .then(res => {
            return buildRelations(res);
        })
        .catch(err => {
            return Promise.reject(err);
        });
    }

    function buildRelations(data) {
        Object.keys(data.npis).forEach(function(npi) {
            if (cache.current['npi'][npi]) {
                return;
            }
            var provider;
            if (data.npis[npi].entitytype == 1) {
                provider = new Physician(data.npis[npi]);
            } else if (data.npis[npi].entitytype == 2) {
                provider = new Organization(data.npis[npi]);
            }
            updateCache(provider);
        });
        
        var promises = data.relations.map(function(relation) {
            return new Promise(function(resolve, reject) {
                relation = new Relation(relation);
                
                Promise.all([GetProvider(relation.source), GetProvider(relation.target)])
                .then(function(npis) {
                    relation.source = npis[0];
                    relation.target = npis[1];
                    resolve(relation);
                });
            });
            
        });
        
        return Promise.all(promises);
    }

    function getAffiliations(npi) {

        let ttm;

        return currentUserFn()
        .then(currentUser => {
            ttm = currentUser.dataModel().name.split('_').pop();
            return Promise.all([fetch(
                `${configState.affiliations_api}/hospital-affiliation/search/`,
                {
                    method: 'POST',
                    body: JSON.stringify({ npis: [npi]}),
                    headers: { Authorization: optionsFn().headers.Authorization}
                }
            )
            .then(resp => resp.json())
            .then(res => {
                const rawData = res?.data.filter(hospital => (hospital.ttm === ttm));
                return rawData.map(hospital => {
                    if (hospital.hospitalnpi) {
                        return GetProvider(hospital.hospitalnpi)
                        .then(provider => {
                            return Object.assign({
                                hospitalName: hospital.hospitalname,
                                facilityId: hospital.hospitalproviderid,
                                source: hospital.source
                            }, provider);
                        }, err => {
                            console.log('error resolving hospital', err);
                            return {};
                        });
                    } else {
                        return {
                            facilityId: hospital.hospitalproviderid,
                            name: {
                                display: hospital.hospitalname
                            },
                            source: hospital.source
                        };
                    }
                });
            }),
            fetch(
                `${configState.affiliations_api}/group-affiliation/search/`,
                {
                    method: 'POST',
                    body: JSON.stringify({ npis: [npi] }),
                    headers: { Authorization: optionsFn().headers.Authorization }
                }
            )
            .then(resp => resp.json())
            .then(res => {
                const rawData = res?.data.filter(group => (group.ttm === ttm));
                return rawData.map(group => {
                    if (group.groupnpi) {
                        return GetProvider(group.groupnpi)
                        .then(provider => {
                            return Object.assign({
                                groupName: group.groupname,
                                grouppracticeid: group.grouppracticeid,
                                source: group.source
                            }, provider);
                        }, err => {
                            console.log('error resolving hospital', err);
                            return {};
                        });
                    } else {
                        return {
                            id: group.grouppracticeid,
                            name: {
                                display: group.groupname
                            },
                            source: group.source
                        };
                    }
                    
                });
            })
            ]);
        })
        .then(results => {
            return {
                hospitalAffiliations: Promise.all(results[0]).then(hospitals => hospitals),
                groupAffiliations: Promise.all(results[1]).then(groups => groups)
            };
        })
        .catch(err => {
            console.error('error retreiving affiliations', err);
        });
    }

    function getHospitalAffiliations(params) {

        let ttm;

        return currentUserFn()
            .then(currentUser => {
                ttm = currentUser.dataModel().name.split('_').pop();
                return fetch(
                    `${configState.affiliations_api}/hospital-affiliation/search/`,
                    {
                        method: 'POST',
                        body: JSON.stringify(params),
                        headers: { Authorization: optionsFn().headers.Authorization }
                    }
                )
            })
            .then(resp => resp.json())
            .then(res => {
                const rawData = res?.data;
                const filteredDeduppedRawData = Object.values(rawData.reduce((acc, provider) => {
                    if (provider.ttm !== ttm) {//not in ttm
                        return acc;
                    } else if (!acc[provider.npi]) {// new in lookup
                        acc[provider.npi] = provider;
                    } else {//already in lookup
                        if (acc[provider.npi].source === 'CMS') {
                            acc[provider.npi].source = `${acc[provider.npi].source},${provider.source}`; 
                        } else {
                            acc[provider.npi].source = `${provider.source},${acc[provider.npi].source}`;
                        }
                    }
                    return acc;
                },{}));
                return filteredDeduppedRawData;
            }, err => {
                console.error('error calling NPI api', err);
            });
    }

    function getGroupAffiliations(params) {

        let ttm;

        return currentUserFn()
            .then(currentUser => {
                ttm = currentUser.dataModel().name.split('_').pop();
                return fetch(
                    `${configState.affiliations_api}/group-affiliation/search/`,
                    {
                        method: 'POST',
                        body: JSON.stringify(params),
                        headers: { Authorization: optionsFn().headers.Authorization }
                    }
                )
            })
            .then(resp => resp.json())
            .then(res => {
                const rawData = res?.data;
                const filteredRawData = rawData.filter(result => result.ttm === ttm);
                return filteredRawData;
            }, err => {
                console.error('error calling NPI api', err);
            });
    }

    async function searchCodesGetBatched(codes) {
        const half = Math.ceil(codes.length / 2);
        const firstHalf = codes.slice(0, half);
        const secondHalf = codes.slice(half);
        
        const firstReponse = await searchCodesGet(firstHalf);
        const secondReponse = await searchCodesGet(secondHalf);

        return (await Promise.all([firstReponse, secondReponse])).reduce((a, b) => {
            return a.concat(b)
        }, []);
    }

    function searchCodesGet(codes) {
        return fetch(
            `${configState.codes_api}/codes/search/${codes.join(',')}/`,
            {
                method: 'GET',
                headers: { Authorization: optionsFn().headers.Authorization }
            }
        )
        .then(resp => resp.json())
        .then(res => {
            const rawData = res?.data;
            return rawData;
        })
        .catch(err => {
            console.log('error fetching codes', err);
        });
    }

    function searchCodesPost(searchObj) {
        return fetch(
            `${configState.codes_api}/codes/search/`,
            {
                method: 'POST',
                body: JSON.stringify(searchObj),
                headers: { Authorization: optionsFn().headers.Authorization }
            }
        )
        .then(res => res.json())
        .then(res => {
            const rawData = res;
            return rawData;
        })
        .catch(err => {
            console.log('error fetching codes', err);
        });
    }

    function searchCodesetServicelines(codesetStr) {
        return fetch(
            `${configState.codes_api}/servicelines/${codesetStr}`,
            {
                method: 'GET',
                headers: { Authorization: optionsFn().headers.Authorization }
            }
        )
        .then(resp => resp.json())
        .then(res => {
            return res;
        }).catch(err => {
            console.error('error fetching servicelines for codeset', err);
        });
    }

    function notifyToast({text, delay, type}) {
        const opts = {};
        switch (type) {
            case 'success':
                opts.type = toast.TYPE.SUCCESS;
                break;
            case 'error':
                opts.type = toast.TYPE.ERROR;
                break;
            case 'warning':
                opts.type = toast.TYPE.WARNING;
                break;
            case 'info':
                opts.type = toast.TYPE.INFO;
                break;
            default:
                opts.type = toast.TYPE.INFO;
        }
        opts.autoClose = delay || 3000;
        toast(text, opts);
    }

    // tourManager.createTour
    function createTour(steps) {
        tourInstance.current =  new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'phtour',
                cancelIcon: {
                    enabled: true
                }
            }
        });

        const tourButtons = {
            next: {text:'Next', classes: 'shepherd-button-primary', action: tourInstance.current.next},
            back: {text:'Prev', classes: 'shepherd-button-secondary', action: tourInstance.current.back}
        };

        const stepsToSet = steps.map((step, idx, arr) => {
            if (idx === 0) {
                step.buttons = [ tourButtons.next ];
            } else if ( idx === (arr.length - 1)) {
                step.buttons = [ tourButtons.back ];
            } else {
                step.buttons = [ tourButtons.back, tourButtons.next ]
            }
            return step;
        });

        tourInstance.current.addSteps(stepsToSet);

    }

    // tourManager.startTour
    function startTour() {
        if (tourInstance.current) {
            tourInstance.current.start();
        } else {
            return false;
        }
    }

    // tourManager.hasTour
    function hasTour() {
        return tourInstance.current;
    }

    //tourManager.clearTour
    function clearTour() {
        tourInstance.current = null;
    }

    // tourManager.executeTour
    function executeTour(steps) {

        const execTour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                classes: 'phtour',
                cancelIcon: {
                    enabled: true
                }
            }
        });

        const tourButtons = {
            next: {text:'Next', classes: 'shepherd-button-primary', action: execTour.next},
            back: {text:'Prev', classes: 'shepherd-button-secondary', action: execTour.back}
        };

        const stepsToSet = steps.map((step, idx, arr) => {
            if (idx === 0) {
                step.buttons = [ tourButtons.next ];
            } else if ( idx === (arr.length - 1)) {
                step.buttons = [ tourButtons.back ];
            } else {
                step.buttons = [ tourButtons.back, tourButtons.next ]
            }
            return step;
        });

        execTour.addSteps(stepsToSet);
        execTour.start();

    }

    //logging.routeLoad
    function routeLoad(data) {
        if (data.pathname === pathname.current) return;//sometimes routes reload, prevent extra logging calls with a check to the last pathname ref
        pathname.current = data.pathname;
        const payload = {
            npis: data.npis,
            state: data.statename,
            url: data.pathname
        };
        const opts = merge({body: JSON.stringify(payload), method: 'POST'},{headers: {'Content-Type': 'application/json'}}, optionsFn());
        fetch('/api/logging/', opts)
        .then(res => {
            return;
        }, err => {
            console.log('error logging page view', err);
        });
    }

    async function Config(id) {
        try {
            if (cache.current['config'][id]) {
                return cache.current['config'][id];
            }
            const configRequest = await fetch( '/api/config', options.current);
            const configRequestData = await req.json();
            cache.current['config'][id] = configRequestData;
            return configRequestData;
        } catch(err) {
            delete cache.current['config'][id];
            return id;
        }
    }
    Config.id = 'config';    
    Config.cacheable = true;

    async function Taxonomies(id) {
        try {
            if (cache.current['taxonomies'][id]) {
                return cache.current['taxonomies'][id];
            }
            const taxonomiesRequest = await fetch( '/api/taxonomies/', options.current);
            const taxonomiesRequestData = await taxonomiesRequest.json();
            cache.current['taxonomies'][id] = taxonomiesRequestData;
            return taxonomiesRequestData;
        } catch(err) {
            delete cache.current['taxonomies'][id];
            return id;
        }
    }
    Taxonomies.id = 'taxonomies';
    Taxonomies.cacheable = true;

    async function Groups(id,auth) {
        try {
            
            return fetch( '/api/community/', auth ? { headers: { Authorization: "Bearer " + auth}} : options.current)
            .then(res => res.json())
            .then(res => {
                var groups = [];
                Object.keys(res.communities).forEach(function(cid) {
                    var community = new Community(res.communities[cid]);
                    cache.current['community'][cid] = community;
                    groups.push(community);
                });

                return groups;
            }, err => {
                console.log('error getting groups', err);
            });

        } catch(err) {
            console.error('error fetching groups', err);
            return id;
        }
    }
    Groups.id = 'groups';
    Groups.cacheable = false;

    async function GetCommunity(id,auth) {
        try {
            if (cache.current['community'][id]) {
                return cache.current['community'][id];
            }

            return fetch( `/api/community/${id}/`, auth ? { headers: { Authorization: "Bearer " + auth}} : options.current)
            .then(res => res.json())
            .then(res => {
                const community = new Community(res);
                cache.current['community'][id] = community;
                return community;
            }, err => {
                console.log('error getting community', err);
            });
            
        } catch(err) {
            delete cache.current['community'][id];
            return new Community({
                id: err,
                _failed: true
            });
        }
    }
    GetCommunity.id = 'community';
    GetCommunity.cacheable = true;

    // BEGIN BATCHABLE FUNCTIONS
    async function GetProvider(id) {
        try {
            if (cache.current['npi'][id]) {
                return cache.current['npi'][id];
            }
            var deferred = createDeferred();
            var item = { id: id, deferred: deferred };
            batchQueues.current['npi'].push(item);
            const npiResult = await deferred.then(mydeferred => {
                return mydeferred;
            }, errdeferred => {
                console.error('errdeferred', id, errdeferred);
                return errdeferred;
            });
            var npiResultProvider;
            
            if (npiResult && npiResult.entitytype == 1) {
                npiResultProvider = new Physician(npiResult);
            } else if (npiResult && npiResult.entitytype == 2) {
                npiResultProvider = new Organization(npiResult);
            } else {
                npiResultProvider = new Provider({npi: id, _failed: true});
            }

            cache.current['npi'][id] = npiResultProvider;
            return npiResultProvider;
        } catch(err) {
            delete cache.current['npi'][id];
            console.error('error getting npiResult', err);
        }
    }
    GetProvider.id = 'npi';
    GetProvider.url = '/api/npi/';
    GetProvider.cacheable = true;
    GetProvider.batchable = true;
    GetProvider.batchResolver = (data, queue) => {
        Object.keys(queue).forEach(function (id) {
            if (data.npis[id]) {
            queue[id].deferred.resolve(data.npis[id]);
            } else {
                console.error('npi id', id);
                queue[id].deferred.resolve({npi: id, _failed: true});
            }
            delete queue[id];
        });
    };

    async function CBSA(id) {
        try {
            if (cache.current['cbsa'][id]) {
                return cache.current['cbsa'][id];
            }

            var deferred = createDeferred();
            var item = { id: id, deferred: deferred };
            batchQueues.current['cbas'].push(item);

            const cbsaResult = await deferred.then(mydeferred => {
                return mydeferred;
            });

            cache.current['cbsa'][id] = cbsaResult;

            return cbsaResult;

        } catch (err) {
            delete cache.current['cbsa'][id];
            console.error('error getting cbsa', err);
        }
    }
    CBSA.id = 'cbsa';
    CBSA.url = '/api/cbsa/';
    CBSA.cacheable = true;
    CBSA.batchable = true;
    CBSA.batchResolver = (data, queue) => {
        data.map(function (cbsa) {
            if (queue[cbsa.cbsa]) {
                queue[cbsa.cbsa].deferred.resolve(cbsa);
                delete queue[cbsa.cbsa];
            }
        });
    };

    async function Clinical(id) {
        try {

            var deferred = createDeferred();
            var item = { id: id, deferred: deferred };
            batchQueues.current['clinical'].push(item);

            const clinicalResult = await deferred.then(mydeferred => {
                return mydeferred;
            });

            return clinicalResult;

        } catch(err) {
            console.error('error getting clinical data', err);
        }
    }
    Clinical.id = 'clinical';
    Clinical.url = npis =>  `/api/npi/${npis.join(',')}/clinical/`,
    Clinical.cacheable = false;
    Clinical.batchable = true;
    Clinical.batchResolver = (data, queue) => {
        data.data.map(function (code) {
            if (queue[code.npi]) {
                queue[code.npi].deferred.resolve(code);
                delete queue[code.npi];
            }
        });
    };

    async function Charges(id) {
        try {

            var deferred = createDeferred();
            var item = { id: id, deferred: deferred };
            batchQueues.current['charges'].push(item);

            const chargesResult = await deferred.then(chargesDeferred => {
                return chargesDeferred;
            });

            return chargesResult;

        } catch (err) {
            console.error('error getting clinical data', err);
        }
    }
    Charges.id = 'charges';
    Charges.url = npis => `/api/npi/${npis.join(',')}/charges/`;
    Charges.cacheable = false;
    Charges.batchable = true;
    Charges.batchResolver = (data, queue) => {
        Object.keys(data.charges).map(npi => {
            var providerCharges = data.charges[npi];
            
            queue[npi].deferred.resolve({npi, totalCharges: providerCharges});
            delete queue[npi];
        });
    };
    // END BATCHABLE FUNCTIONS

    function batchProcess() {
        [
            GetProvider,
            CBSA,
            Clinical,
            Charges,
        ].forEach(conf => {
            if (batchQueues.current[conf.id].length > 0) {
                var queue = batchQueues.current[conf.id];
                batchQueues.current[conf.id] = [];

                if (queue.length > 250) {
                    queue.splice(250, queue.length - 250).forEach(function (item) {
                        batchQueues.current[conf.id].unshift(item);
                    });
                }

                queue = queue.reduce(function (h, i) {
                    h[i.id] = i;
                    return h;
                }, {});

                setTimeout(function() {

                var batchRequest;
                if (typeof conf.url === 'function') {
                    let customURL = conf.url(Object.keys(queue));
                    batchRequest = fetch(customURL, options.current).then(req => req.json());
                } else {
                    batchRequest = fetch(conf.url + Object.keys(queue).join(',') + '/', options.current).then(req => req.json());
                }

                batchRequest.then(function (res) {
                    
                    conf.batchResolver(res, queue);
                    //after resolving what can be resolved resolve the rest as failed
                    Object.keys(queue).forEach(function (id) {
                        queue[id].deferred.resolve({ [conf.id]: id, _failed: true });
                    });
                }, function(err) {
                    Object.keys(queue).forEach(function (id) {
                            queue[id].deferred.resolve({ [conf.id]: id, _failed: true });
                        if (conf.cacheable) {
                            delete cache.current[conf.id][id];
                        }
                    });
                });
                }, 0);

            }
        })
    }

    useEffect(() => {
        let tokenUpdateInterval;
        let picUpdateInterval;
        let batchProcessInterval;
        async function init() {

            let firstConfigRes;
            let firstResData;

            try {

                firstConfigRes = await fetch('/api/config');
                firstResData = await firstConfigRes.json();
                
                //server config with cookie
                Amplify.configure({
                    Auth: firstResData.cognito
                });

                //Local dev without cookie
                // console.log('firstResData.cognito', firstResData.cognito);
                // var cognitoObj = {
                //     ...firstResData.cognito,
                // };
                // delete cognitoObj.cookieStorage;
                // console.log('cognitoObj in servicesContext init', cognitoObj);
                // Amplify.configure({
                //     Auth: cognitoObj
                // });
                
                const cognitoUser = await Auth.currentAuthenticatedUser();
                
                const user = Object.create(cognitoUser);
                
                const headers = await getFetchHeaders();

                optionsFn(headers);
                const secondConfigRes  = await fetch('/api/config', headers);
                const secondConfigResData = await secondConfigRes.json();


                await initStorage({
                    userId: cognitoUser.username,
                    token: headers.headers,
                    models: secondConfigResData.models,
                    accountApi: secondConfigResData.account.api
                });

                setStorageUserId(cognitoUser.username);
                user.dataModel = function() {
                    return secondConfigResData.models.filter(model => {
                        return model.name === getStorage('ttm');
                    })[0];
                };
                setCurrentUser(user);

                bindData(secondConfigResData, setConfigState);

                tokenUpdateInterval = setInterval(updateToken, 1000 * 60 * 4); // Update Authorization headers every 4 minutes
                updateToken();
                picUpdateInterval = setInterval(picUpdate, 1000 * 60 * 5);// update pic data every 5 min
                await picUpdate();
                batchProcessInterval = setInterval(batchProcess, 100);
                batchProcess();
                //finally done with setup and now ready

                // "priming" the provider cache with recent providers so menu and breadcrumb component is handled on a fresh (re)load

                const recentNpis = [...localStorage.getObj('recentlySelected.organization',[]), ...localStorage.getObj('recentlySelected.physician',[])].map(val => val.id);
                for await (var npi of recentNpis) {
                    await GetProvider(npi);
                }

                setReady(true);
            } catch(err) {
                console.error('error setting config init', err);
                const accountRedirect = firstResData.account.ui
                const redirectUrls = ['https://account.perceptionhealth.dev', 'https://account.perceptionhealth.com']
                const redirectIndex = redirectUrls.indexOf(accountRedirect)
                if (redirectIndex > -1) {
                    window.location.href = redirectUrls[redirectIndex];
                } else {
                    console.error('unable to navigate to login');
                }
            }
        }
        init();
        return () => {
            clearInterval(tokenUpdateInterval);
            clearInterval(picUpdateInterval);
            clearInterval(batchProcessInterval);
        };
    }, []);

    //begin values to set on providers
    const configValue = {
        ready: ready,
        brand: brand => {
            if (typeof brand === 'undefined') {
                return configState.brand || 'PHTEAM';
            } else {
                setConfigState({...configState, brand: brand});
            }
        },
        version: () => configState.version,
        manual: () => configState.manual,
        states: () => statesArr,
        pcpTaxonomyCodes: () => configState.pcpTaxonomyCodesArr ? configState.pcpTaxonomyCodesArr : [],
        radiologyTaxonomyCodes: () => configState.radiologyTaxonomyCodesArr ? configState.radiologyTaxonomyCodesArr : [],
        laboratoryTaxonomyCodes: () => configState.laboratoryTaxonomyCodesArr ? configState.laboratoryTaxonomyCodesArr : [],
        gacTaxonomyCodes: () => configState.gacTaxonomyCodesArr ? configState.gacTaxonomyCodesArr : [],
        postAcuteTaxonomyCodes: () => configState.postAcuteTaxonomies ? configState.postAcuteTaxonomies : [],
        marketMapTaxonomyCodes: () => configState.marketMapTaxonomies ? configState.marketMapTaxonomies : [],
        models: () => configState.models ? configState.models : [],
        cognito: () => configState.cognito,
        account: () => configState.account,
        accountUrl: configState.account || '',
        affiliationsEndpoint: () => configState.affiliations_api ? configState.affiliations_api : null,
        codesEndpoint: () => configState.codes_api ? configState.codes_api : null,
        forecastingEndpoint: () => configState.forecasting_api ? configState.forecasting_api : null
    };

    const authValue = {
        currentUser: currentUserFn,
        currentUserIsSet: currentUser,
        cognitoLogout
    };

    const storageValue = {
        init: updateStorage,
        getStorage,
        fetchStorage,
        setStorage,
        setStorageUserId: setStorageUserIdFn
    };

    const apiValue= {
        updateCache,
        options: optionsFn,
        getOptions,
        getReport,
        updateCommunity,
        deleteCommunity,
        getServiceArea,
        Relations,
        CommunityRelations,
        buildRelations,
        Groups,
        GetProvider,
        GetCommunity,
        CBSA,
        Clinical,
        Charges,
        Groups,
        Provider,
        Physician,
        Organization,
        Community,
        Taxonomy,
        Relation,
        Taxonomies
    };

    const userValue = {
        getUserById,
        getCurrentUserProfile
    };

    const templateValue = {
        title,
        header,
        nonReportingNpi,
        toggleNavSidebar: toggleNavSidebarFn,
        contentResize,
        openNavSidebar,
        sideBarClosed: toggleNavSidebar
    };

    const forecastingValue = {
        validateCodesUsage,
        getForecastingData
    };

    const recentlySelectedValue = {
        getRecent,
        store,
        remove
    };

    const affiliationsValue = {
        getAffiliations,
        getHospitalAffiliations,
        getGroupAffiliations
    };
    
    const communityListValue = {
        update,
        reset,
        get,
        listFinal,
        altListFinal,
        descriptionFinal,
        temporaryListFinal,
        messageFinal
    };

    const codesValue = {
        searchCodesGet,
        searchCodesGetBatched,
        searchCodesPost,
        searchCodesetServicelines
    };

    const notifyValue = {
        alert: notifyToast,
        error: opts => notifyToast({...opts, type: 'error'}),
        info: opts => notifyToast({...opts, type: 'info'}),
    };

    const tourManagerValue = {
        createTour,
        startTour,
        hasTour,
        clearTour,
        executeTour
    };

    const loggingValue = {
        routeLoad,
    };
    //end values to set on providers

    // to have single services provider we create a higher order component and wrap here
    return (
        <ConfigContext.Provider value={configValue}>
            <AuthContext.Provider value={authValue}>
                <StorageContext.Provider value={storageValue}>
                    <ApiContext.Provider value={apiValue}>
                        <UserContext.Provider value={userValue}>
                            <TemplateContext.Provider value={templateValue}>
                                <ForecastingContext.Provider value={forecastingValue}>
                                    <RecentlySelectedContext.Provider value={recentlySelectedValue}>
                                        <CommunityListContext.Provider value={communityListValue}>
                                            <CodesContext.Provider value={codesValue}>
                                                <PicContext.Provider value={pic}>
                                                    <AffiliationsContext.Provider value={affiliationsValue}>
                                                        <NotifyContext.Provider value={notifyValue}>
                                                            <TourManagerContext.Provider value={tourManagerValue}>
                                                                <LoggingContext.Provider value={loggingValue}>
                                                                    {props.children}
                                                                </LoggingContext.Provider>
                                                            </TourManagerContext.Provider>
                                                        </NotifyContext.Provider>
                                                    </AffiliationsContext.Provider>
                                                </PicContext.Provider>
                                            </CodesContext.Provider>
                                        </CommunityListContext.Provider>
                                    </RecentlySelectedContext.Provider>
                                </ForecastingContext.Provider>
                            </TemplateContext.Provider>
                        </UserContext.Provider>
                    </ApiContext.Provider>
                </StorageContext.Provider>
            </AuthContext.Provider>
        </ConfigContext.Provider>
    );

}

// all exports here
export {
    ServicesProvider,
    useConfig,
    useAuth,
    useStorage,
    useApi,
    useUser,
    useForecasting,
    usePic,
    useTemplate,
    useRecentlySelected,
    useAffiations,
    useCommunityList,
    useCodes,
    useTourManager,
    useNotify,
    useLogging
};