import '../../js/datumlist';

export default 'legendList';

angular.module('legendList', [])
.directive('legendList', function(){
    return {
        scope: {
            data: '=',
            opts: '=',
        },
        link: function(scope, elem, attrs) {
            elem.datumlist(scope.opts);
            
            scope.$watch('opts', function(a, b) {
                if (a) {
                    elem.datumlist('options', a);
                }
            });
            
            scope.$watchCollection('data', function(a, b) {
                if (a) {
                    elem.datumlist('data', a);
                }
            });
        }
    };
});