var script = {
    config: {},
    steps: [{
    	content: "This is the Performance Report. It shows the type of billing codes used and the number of patients who received services under these billing codes. These values should roughly equate to volume statistics for the reporting entity."
    },{
    	content: "The categories show the New vs Established Patients, Inpatients, Observation, and Emergency Room Visits."
    },{
    	content: "Since we are using HCPCS codes, we show this information in an ascending mode which typically equates to severity of the visit."
    },{
    	content: "At the bottom in bold, the Performance Report contains ratios for each of the services so you can more fully understand your conversion ratio of new patients to inpatients. This also allows you to compare your facility to your competitors."
    }]
};

export default script;