import React, { useState, useEffect } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';
import eventBus from "../../services/eventBus";
import { v4 as uuid_v4 } from "uuid";
import { useTourManager, useLogging, usePic } from '../../services/servicesContext';

export function ClinicalCommunityComponent({ api,  notify, codes, download, config }) {
    const [ data, setData] = useState(null);
    const [ loading, setLoading] = useState(null);
    const [ noPhysicians, setnoPhysicians] = useState(null);
    const [ diagData, setDiagData] = useState([]);
    const [ procData, setProcData] = useState([]);
    const [ currentTab, setCurrentTab] = useState("1");
    const pic = usePic();
    const tourManager = useTourManager();
    const logging = useLogging();
    
    const community = useRouteLoaderData('community.cid');

    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'Clinical Report - 1/17',
            text: "This is the clinical report.",
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    document.getElementById('1').click();//select the procedures tab
                    setTimeout(() => {
                        if (document.querySelector('.parent-row.top-row-0').classList.contains('collasped')) {// if the first row is expanded, collapse it
                            document.querySelector('.parent-row.top-row-0 i.fa.open.fa-chevron-down').click();
                        }
                        setTimeout(() => {
                            resolve();
                        });
                    });
                });
            }
        },
        {
            id: 2,
            title: 'Clinical Report - 2/17',
            text: "This is where you can view the claims data by procedure code grouped by service line for a community.",
        },
        {
            id: 3,
            attachTo: { element: ".tourscript-npi", on: 'bottom' },
            title: 'Clinical Report - 3/17',
            text: "Each Community member's claims data is represented in this report."
        },
        {
            id: 4,
            attachTo: { element: ".tourscript-total", on: 'bottom' },
            title: 'Clinical Report - 4/17',
            text: "You can view total procedures...",
        },
        {
            id: 5,
            attachTo: { element: ".tourscript-serviceLineColumn", on: 'bottom' },
            title: 'Clinical Report - 5/17',
            text: 'grouped by Service Line'

        },
        {
            id: 6,
            attachTo: { element: ".tourscript-code", on: 'bottom' },
            title: 'Clinical Report - 6/17',
            text: "and its HCPCS code(s).",
        },
        {
            id: 7,
            attachTo: { element: "#service.header-container.table-header", on: 'bottom' },
            title: 'Clinical Report - 7/17',
            text: "The 'Place of Service' details whether the procedure was performed at a physician office building (O) or facility (F) - like a hospital or SNF.  We use the place of service field listed on the claim to make this designation.",
        },
        {
            id: 8,
            attachTo: { element: '.parent-row.top-row-0 i.fa.fa-chevron-right', on: 'bottom'},
            title: 'Clinical Report - 8/17',
            text: "service line groupings aggregate all the procedure codes totals into a single row, clicking the right-facing arrow in the show column shows the code level data"
        },
        {
            id: 9,
            attachTo: {element: 'tr.nested.nested-row-0', on: 'top' },
            title: 'Clinical Report - 9/17',
            text: 'after clicking the arrow, you can view the totals per code',
            scrollTo: true,
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    document.querySelector('.parent-row.top-row-0 i.fa.fa-chevron-right').click();
                    setTimeout(() => {
                        resolve();
                    });
                });
            }
        },
        {
            id: 10,
            attachTo: { element: ".content-container thead", on: 'bottom' },
            scrollTo: true,
            title: 'Clinical Report - 10/17',
            text: "You can sort on any column by simply clicking on the header name.",
        },
        {
            id: 11,
            attachTo: { element: ".content-container .table-controls-input .filter-box", on: 'bottom' },
            scrollTo: true,
            title: 'Clinical Report - 11/17',
            text: "Also, you can filter results here by searching for any value in the row.",
        },
        {
            id: 12,
            attachTo: { element: "#tourscript-buttons", on: 'bottom' },
            scrollTo: true,
            title: 'Clinical Report - 12/17',
            text: "Use this button to download the data in the table.",
        },
        {
            id: 13,
            attachTo: { element: ".diagnoses-tab-link", on: 'bottom'},
            scrollTo: true,
            title: 'Clinical Report - 13/17',
            text: 'Clicking the "Diagnoses" tab reveals clinical data by diagnosis codes'
        },
        {
            id: 14,
            title: 'Clinical Report - 14/17',
            text: 'This is where you can view the claims data by diagnosis code grouped by service line for a community.',
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    document.querySelector('.diagnoses-tab-link').click();
                    setTimeout(() => {
                        resolve();
                    });
                });
            }
        },
        {
            id: 15,
            attachTo: { element: '.tourscript-totalclaims', to: 'bottom' },
            title: 'Clinical Report 15/17',
            text: 'by total claims'
        },
        {
            id: 16,
            attachTo: { element: '.tourscript-serviceLineColumn', to: 'bottom' },
            title: 'Clinical Report 16/17',
            text: 'grouped by Service Line'
        },
        {
            id: 17,
            attachTo: { element: '.tourscript-codeColumn', to: 'bottom' },
            title: 'Clinical Report 17/17',
            text: 'and its ICD10CM code(s)'
        }]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: `root.app.com.reports.clinical`
        });

        function init() {    
            community.type()
            .then(comType => {
                if (comType === "1" || comType === "3") {
                    //only continue if physician or hybrid community
                    return community.ResolveMembers(api);
                } else {
                    setnoPhysicians(true);
                    setLoading(false);
                }
            }, err => {
                console.log('error getting community type', err);
                return Promise.reject(err);
            })
            .then(members => {
                
                if (members != undefined) {
                    //get list of only physician provider npis
                    var physMembersNpis = members
                        .filter(member => member.entitytype == 1)
                        .map(member => member.npi);
                    
                    if (physMembersNpis.length > 0) {
                        setLoading(true);
                        //retrieve the clinical procedural data, format, and set on applicable table
                        getAndFormatProceduresData({npis: physMembersNpis}, () => {
                            setLoading(false);
                            setCurrentTab(1);
                        });
                        //retrieve the clinical diagnosis data, format, and set on applicable table
                        getAndFormatDiagnosesData({npis: physMembersNpis});
                        
                    } else {
                        setnoPhysicians(true);
                        setLoading(false);
                    }
                }
                else {
                    setnoPhysicians(true);
                    setLoading(false);
                }
            }, err => {
                console.log('error resoving community members', err);
            });

        }

        if (config.ready && community && !community._failed) {
            init();
        }    

        return () => {
            tourManager.clearTour();
        };

    }, [community, config.ready]);


    const gotoTab = (e) => {
        setCurrentTab(e.target.id)
    }

    function getAndFormatProceduresData(npis) {
        var codesets = 'HCPCS,CPT'
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': api.options().headers.Authorization, 'X-TEAM-TTM': api.options().headers["X-TEAM-TTM"]},
            body: JSON.stringify(npis)
        };
        fetch('/api/npi/clinical/',requestOptions)
        .then(res => res.json())
        .then(function(res) {
            var data = res.data;
            var npis = res.npis;
            var codeList = [...new Set(data.map(d => d.code))];
            codes.searchCodesPost({codes: codeList, codeset: codesets})
            .then(resCodes => {              
                
                var codeCodesetLookup = resCodes.data.reduce((acc, code) => {
                    if (!acc[`${code.clean_code}||${code.codeset}`]) {
                        acc[`${code.clean_code}||${code.codeset}`] = code;
                    }
                    return acc;
                }, {});
                
                var servicelineAgg = res.data.reduce(function(acc, datum) {
                    var ret = {
                        'npi': [datum.npi],
                        'name': npis[datum.npi].name.display,
                        'entitytype': npis[datum.npi].entitytype,
                        'city': npis[datum.npi].location.city,
                        'state': npis[datum.npi].location.state,
                        'zip': npis[datum.npi].location.postal_code,
                        'phone': npis[datum.npi].location.phone_number,
                        'communities': pic(datum.npi),
                        'service': datum.place_of_service,
                        'codesets': datum.codeset || '',
                        'code': datum.code,
                        'departments': codeCodesetLookup[`${datum.code}||HCPCS`]?.department || codeCodesetLookup[`${datum.code}||CPT`]?.department || codeCodesetLookup[`${datum.code}||ICD10CM`]?.department || '',
                        'serviceline': datum?.serviceline || '',
                        '_servicelines': datum?.serviceline || '',
                        'anatomies': codeCodesetLookup[`${datum.code}||HCPCS`]?.anatomy || codeCodesetLookup[`${datum.code}||CPT`]?.anatomy || codeCodesetLookup[`${datum.code}||ICD10CM`]?.anatomy || '',
                        'description': codeCodesetLookup[`${datum.code}||HCPCS`]?.description || codeCodesetLookup[`${datum.code}||CPT`]?.description || codeCodesetLookup[`${datum.code}||ICD10CM`]?.description || '',
                        'totalClaims': datum.values.line_srvc_cnt,
                        'uniquePatients': datum.values.unique_cnt,
                        'avgSubmittedCharge': datum.values.average_submitted_chrg_amt,
                        'rowType': 'item'
                    };
                    
                    if (!acc[ret['_servicelines']]) {
                        acc[ret['_servicelines']] = {
                            npi: [ret.npi],
                            name: [ret.name],
                            entitytype: [ret.entitytype],
                            city: [ret.city],
                            state: [ret.state],
                            zip: [ret.zip],
                            phone: [ret.phone],
                            communities: [ret.communities],
                            service: [ret.service],
                            codesets: [ret.codesets],
                            code: [ret.code],
                            departments: [ret.departments],
                            servicelines: ret['_servicelines'],
                            anatomies: [ret.anatomies],
                            description: '',
                            totalClaims: [ret.totalClaims],
                            uniquePatients: [ret.uniquePatients],
                            avgSubmittedCharge: [ret.avgSubmittedCharge],
                            list: [ret]
                        };
                    } else {
                        acc[ret['_servicelines']].npi.push(ret.npi);
                        acc[ret['_servicelines']].name.push(ret.name);
                        acc[ret['_servicelines']].entitytype.push(ret.entitytype);
                        acc[ret['_servicelines']].city.push(ret.city);
                        acc[ret['_servicelines']].state.push(ret.state);
                        acc[ret['_servicelines']].zip.push(ret.zip);
                        acc[ret['_servicelines']].phone.push(ret.phone);
                        acc[ret['_servicelines']].communities.push(ret.communities);
                        acc[ret['_servicelines']].service.push(ret.service);
                        acc[ret['_servicelines']].codesets.push(ret.codesets);
                        acc[ret['_servicelines']].code.push(ret.code);
                        acc[ret['_servicelines']].departments.push(ret.departments);
                        acc[ret['_servicelines']].anatomies.push(ret.anatomies);
                        acc[ret['_servicelines']].totalClaims.push(ret.totalClaims);
                        acc[ret['_servicelines']].uniquePatients.push(ret.uniquePatients);
                        acc[ret['_servicelines']].avgSubmittedCharge.push(ret.avgSubmittedCharge);
                        acc[ret['_servicelines']].list.push(ret);
                    }
                    
                    return acc;
                    
                }, {});
                
                const procDataObject = Object.values(servicelineAgg).map(function(datum) {
                    return {
                        'npi': [...new Set(datum.npi.flat())],
                        'name': [...new Set(datum.name)].join(', '),
                        'entitytype': [...new Set(datum.entitytype)].join(', '),
                        'city': [...new Set(datum.city)].join(', '),
                        'state': [...new Set(datum.state)].join(', '),
                        'zip': [...new Set(datum.zip)].join(', '),
                        'phone': [...new Set(datum.phone)].join(', '),
                        'communities': datum.communities.flat().reduce((acc, com) => {
                            if (!acc.some(searchCom => com.id === searchCom.id)) {
                                acc.push(com);
                            }
                            return acc;
                        },[]),
                        'service': [...new Set(datum.service)].join(', '),
                        'code': [...new Set(datum.code)].join(', '),
                        'description': '',
                        'totalClaims': datum.totalClaims.reduce((acc, val) => (acc + val), 0),
                        'uniquePatients': '',
                        'avgSubmittedCharge': (datum.avgSubmittedCharge.reduce((acc, val) => (acc + val), 0) / datum.avgSubmittedCharge.length),
                        'serviceline': datum.servicelines || 'n/a',
                        'departments': [...new Set(datum.departments)].join(', '),
                        'anatomies': [...new Set(datum.anatomies)].join(', '),
                        'codesets': [...new Set(datum.codesets)].join(', '),
                        'list': datum.list,
                        'rowType': 'grouping'
                    };
                });
                
                setLoading(false);
                setProcData(procDataObject);
                eventBus.dispatch("recentlySelectedChangeCommunities");
                 
            });
        }, function(err) {
            console.log('Error fetching Community Diagnoses Clinical Report data: ', err);
            setLoading(true);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Community Diagnoses Clinical Report data.',
                delay: 30000
            });
        });
    }
    function getAndFormatDiagnosesData(npis) {
        var codesets = 'ICD10CM'
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': api.options().headers.Authorization, 'X-TEAM-TTM': api.options().headers["X-TEAM-TTM"]},
            body: JSON.stringify(npis)
        };
        fetch('/api/npi/clinical_diag/', requestOptions)
        .then(res => res.json())
        .then(function(res) {
            var data = res.data;
            var npis = res.npis;
            var codeList = [...new Set(data.map(d => d.code))];
            codes.searchCodesPost({codes: codeList, codeset: codesets})
            .then(resCodes => {
                
                var codeCodesetLookup = resCodes.data.reduce((acc, code) => {
                    if (!acc[`${code.clean_code}||${code.codeset}`]) {
                        acc[`${code.clean_code}||${code.codeset}`] = code;
                    }
                    return acc;
                }, {});
                
                var servicelineAgg = res.data.reduce(function(acc, datum) {
                    var ret = {
                        'npi': [datum.npi],
                        'name': npis[datum.npi].name.display,
                        'entitytype': npis[datum.npi].entitytype,
                        'city': npis[datum.npi].location.city,
                        'state': npis[datum.npi].location.state,
                        'zip': npis[datum.npi].location.postal_code,
                        'phone': npis[datum.npi].location.phone_number,
                        'communities': pic(datum.npi),
                        'service': datum.place_of_service,
                        'codesets': datum.codeset || '',
                        'code': datum.code,
                        'departments': codeCodesetLookup[`${datum.code}||HCPCS`]?.department || codeCodesetLookup[`${datum.code}||CPT`]?.department || codeCodesetLookup[`${datum.code}||ICD10CM`]?.department || '',
                        'serviceline': datum?.serviceline || '',
                        '_servicelines': datum?.serviceline || '',
                        'anatomies': codeCodesetLookup[`${datum.code}||HCPCS`]?.anatomy || codeCodesetLookup[`${datum.code}||CPT`]?.anatomy || codeCodesetLookup[`${datum.code}||ICD10CM`]?.anatomy || '',
                        'description': codeCodesetLookup[`${datum.code}||HCPCS`]?.description || codeCodesetLookup[`${datum.code}||CPT`]?.description || codeCodesetLookup[`${datum.code}||ICD10CM`]?.description || '',
                        'totalClaims': datum.values.line_srvc_cnt,
                        'uniquePatients': datum.values.unique_cnt,
                        'avgSubmittedCharge': datum.values.average_submitted_chrg_amt,
                        'rowType': 'item'
                    };
                    
                    if (!acc[ret['_servicelines']]) {
                        acc[ret['_servicelines']] = {
                            npi: [ret.npi],
                            name: [ret.name],
                            entitytype: [ret.entitytype],
                            city: [ret.city],
                            state: [ret.state],
                            zip: [ret.zip],
                            phone: [ret.phone],
                            communities: [ret.communities],
                            service: [ret.service],
                            codesets: [ret.codesets],
                            code: [ret.code],
                            departments: [ret.departments],
                            servicelines: ret['_servicelines'],
                            anatomies: [ret.anatomies],
                            description: '',
                            totalClaims: [ret.totalClaims],
                            uniquePatients: [ret.uniquePatients],
                            avgSubmittedCharge: [ret.avgSubmittedCharge],
                            list: [ret]
                        };
                    } else {
                        acc[ret['_servicelines']].npi.push(ret.npi);
                        acc[ret['_servicelines']].name.push(ret.name);
                        acc[ret['_servicelines']].entitytype.push(ret.entitytype);
                        acc[ret['_servicelines']].city.push(ret.city);
                        acc[ret['_servicelines']].state.push(ret.state);
                        acc[ret['_servicelines']].zip.push(ret.zip);
                        acc[ret['_servicelines']].phone.push(ret.phone);
                        acc[ret['_servicelines']].communities.push(ret.communities);
                        acc[ret['_servicelines']].service.push(ret.service);
                        acc[ret['_servicelines']].codesets.push(ret.codesets);
                        acc[ret['_servicelines']].code.push(ret.code);
                        acc[ret['_servicelines']].departments.push(ret.departments);
                        acc[ret['_servicelines']].anatomies.push(ret.anatomies);
                        acc[ret['_servicelines']].totalClaims.push(ret.totalClaims);
                        acc[ret['_servicelines']].uniquePatients.push(ret.uniquePatients);
                        acc[ret['_servicelines']].avgSubmittedCharge.push(ret.avgSubmittedCharge);
                        acc[ret['_servicelines']].list.push(ret);
                    }
                    
                    return acc;
                    
                }, {});
                
                const diagDataObject = Object.values(servicelineAgg).map(value => {
                    return {
                        'npi': [...new Set(value.npi.flat())],
                        'name': [...new Set(value.name)].join(', '),
                        'entitytype': [...new Set(value.entitytype)].join(', '),
                        'city': [...new Set(value.city)].join(', '),
                        'state': [...new Set(value.state)].join(', '),
                        'zip': [...new Set(value.zip)].join(', '),
                        'phone': [...new Set(value.phone)].join(', '),
                        'communities': value.communities.flat().reduce((acc, com) => {
                            if (!acc.some(searchCom => com.id === searchCom.id)) {
                                acc.push(com);
                            }
                            return acc;
                        },[]),
                        'service': [...new Set(value.service)].join(', '),
                        'code': [...new Set(value.code)].join(', '),
                        'description': '',
                        'totalClaims': value.totalClaims.reduce((acc, val) => (acc + val), 0),
                        'uniquePatients': '',
                        'avgSubmittedCharge': (value.avgSubmittedCharge.reduce((acc, val) => (acc + val), 0) / value.avgSubmittedCharge.length),
                        'serviceline': value.servicelines || 'n/a',
                        'departments': [...new Set(value.departments)].join(', '),
                        'anatomies': [...new Set(value.anatomies)].join(', '),
                        'codesets': [...new Set(value.codesets)].join(', '),
                        'list': value.list,
                        'rowType': 'grouping'
                    };
                });
                
                setLoading(false);
                setDiagData(diagDataObject);
                eventBus.dispatch("recentlySelectedChangeCommunities");
            });
        }, function(err) {
            console.log('Error fetching Community Diagnoses Clinical Report data: ', err);
            setLoading(true);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Community Diagnoses Clinical Report data.',
                delay: 30000
            });
        });
        
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }

    const reportConfigProcedures = function() {
        return {  
          tableConfig :  [
                 {
                    "header": {
                        "id": "col20",
                        "accessor": "rowType",
                        "content": "Row Type",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 20
                        }
                 },
                  {
                    "header": {
                        "id": "col19",
                        "accessor": "anatomies",
                        "content": "Anatomy(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 19
                        }
                },
                {
                    "header": {
                        "id": "col18",
                        "accessor": "departments",
                        "content": "Department(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 18
                        }
                },
                {
                    "header": {
                        "id": "col17",
                        "accessor": "codesets",
                        "content": "Codeset(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 17
                        }
                },
                {
                  "header": {
                      "id": "col0",
                      "accessor": "show",
                      "content": "Show",
                      "defaultVisibilty": true,
                      "type" : "collapse-button",
                      "collasped" : true,
                      "classLists" : "fa fa-chevron-right",
                      "sorting": false,
                      "exclude": true,
                      "template": function() { return }   
                  },
                  "column": {
                      "class": "'text-center'",
                      "content": "link",
                      "style": "",
                      "id": 0                   
                  }
              },
              {
                "header": {
                    "id": "col16",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "tooltip" : true,
                    'tooltipType': 'details',
                    "export": true,
                    "sortable": true,
                    "template": function(content, rowDataObject) { 
                        if (!content) return
                        if (content.length > 1) {
                            const npis = content.map((rowObject) => {
                                let obj = rowDataObject.list.find(o => o.npi[0] === rowObject);
                                var formatPhoneNum = obj && obj.phone && obj.phone.toString() || '';
                                var phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                                var provider = { 
                                    location : {
                                    'postal_code' : obj.zip,
                                    'phone_number': phone,
                                    'city': obj.city,
                                    'state': obj.state
                                    },
                                    'name': {'display' : obj.name }
                                } 
                                return <React.Fragment key={uuid_v4()}><span className='tourscript-npi'><PopoverDetails provider={provider}> <Link to={`/physician/${obj.npi[0]}/home`}>{obj.npi[0]}</Link></PopoverDetails></span></React.Fragment>
                            })
                            return <>
                            {npis}
                            </>
                        }
                        else {
                            let obj = rowDataObject;
                            var formatPhoneNum = obj && obj.phone && obj.phone.toString() || '';
                            var phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            var provider = { 
                                location : {
                                'postal_code' : obj.zip,
                                'phone_number': phone,
                                'city': obj.city,
                                'state': obj.state
                                },
                                'name': {'display' : obj.name }
                            } 
                            return <React.Fragment key={uuid_v4()}><span className='tourscript-npi'><PopoverDetails provider={provider}> <Link to={`/physician/${obj.npi[0]}/home`}>{obj.npi[0]}</Link></PopoverDetails></span></React.Fragment>
                        }
                    }  
                },
                "column": {
                    "class": "'text-center'",
                    "content": "link",
                    "style": "",
                    "id": 16                 
                }
              },
              {
                    "header": {
                        "id": "col1",
                        "accessor": "serviceline",
                        "content": "Service Line",
                        "defaultVisibilty": true,
                        "forcedColumn": 1,
                        "tooltip" : true,
                        "type": "Array",
                        "export": true,
                        "template": content => <span className='tourscript-serviceLineColumn'>{content}</span>  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                    
                    }
                },
                {
                    "header": {
                        "id": "col15",
                        "accessor": "phone",
                        "content": "Provider Phone",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                },
                {
                    "header": {
                        "id": "col14",
                        "accessor": "zip",
                        "content": "Provider ZIP",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                    "header": {
                        "id": "col13",
                        "accessor": "state",
                        "content": "Provider State",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "city",
                        "content": "Provider City",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col11",
                        "accessor": "entitytype",
                        "content": "Provider Entity type",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col10",
                        "accessor": "name",
                        "content": "Provider Name",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "communities",
                        "content": "Communities",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => {
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent;
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                  "header": {
                      "id": "col3",
                      "accessor": "service",
                      "content": "Place of Service",
                     "defaultVisibilty": true,
                      "export": true,
                     "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 3
                    }
                },
             
                {
                    "header": {
                        "id": "col4",
                        "accessor": "code",
                        "content": "HCPCS Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-code'>{content}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 4
                   }
                },
                {
                  "header": {
                      "id": "col5",
                      "accessor": "description",
                      "content": "HCPCS Description",
                      "defaultVisibilty": true,
                      "export": true,
                      "template": function(content) { 
                        var val =  <PopoverDetails providerTooltip={ content || '' }>{(content.length > 1) ? `${content.substring(0, 20)} ...`  : ''}</PopoverDetails>
                        return val;
                    }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 5
                 }
              },
                {
                  "header": {
                      "id": "col6",
                      "accessor": "totalClaims",
                      "content": "Total Procedures",
                      "sort": "desc",
                      "defaultVisibilty": true,
                      "export": true,
                      "template": content => <span className='tourscript-total'>{content.toLocaleString()}</span>
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 6
                      }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "uniquePatients",
                        "content": "Unique Patients",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
              {
                "header": {
                    "id": "col9",
                    "accessor": "avgSubmittedCharge",
                    "content": "Average Submitted Charge",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return currencyFormat(content) }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 9
                    }
            }
              
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        nested: "list",
        nestedSearch: true,// when filtering the table, results should filter child rows and if parent row ends up with no children then filter the parent out too, exports should output children only 
        reportTitle: `Procedures Clinical Report for ${community ? community.name : ""}`,
        localStorageName: 'com.reports.clinical.procedure.v-2',
        csvdDownloadTemplate: function() { return null }
        }
    }


    const reportConfigDiagnosis = function() { 
        return {  
          tableConfig :  [
                {
                    "header": {
                        "id": "col20",
                        "accessor": "rowType",
                        "content": "Row Type",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 20
                        }
                 },
                  {
                    "header": {
                        "id": "col19",
                        "accessor": "anatomies",
                        "content": "Anatomy(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 19
                        }
                },
                {
                    "header": {
                        "id": "col18",
                        "accessor": "departments",
                        "content": "Department(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 18
                        }
                },
                {
                    "header": {
                        "id": "col17",
                        "accessor": "codesets",
                        "content": "Codeset(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 17
                        }
                },
                {
                  "header": {
                      "id": "col0",
                      "accessor": "show",
                      "content": "Show",
                      "defaultVisibilty": true,
                      "type" : "collapse-button",
                      "collasped" : true,
                      "classLists" : "fa fa-chevron-right",
                      "sorting": false,
                      "exclude": true,
                      "template": function() { return }   
                  },
                  "column": {
                      "class": "'text-center'",
                      "content": "link",
                      "style": "",
                      "id": 0                   
                  }
              },
              {
                "header": {
                    "id": "col1",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "tooltip" : true,
                    'tooltipType': 'details',
                    "export": true,
                    "sortable": true,
                    "template": function(content, rowDataObject) { 
                        if (!content) return
                        if (content.length > 1) {
                            const npis = content.map((rowObject) => {
                                let obj = rowDataObject.list.find(o => o.npi[0] === rowObject);
                                var formatPhoneNum = obj && obj.phone && obj.phone.toString() || '';
                                var phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                                var provider = { 
                                    location : {
                                    'postal_code' : obj.zip,
                                    'phone_number': phone,
                                    'city': obj.city,
                                    'state': obj.state
                                    },
                                    'name': {'display' : obj.name }
                                } 
                                return <React.Fragment key={uuid_v4()}><span className='tourscript-npi'><PopoverDetails provider={provider}> <Link to={`/physician/${obj.npi[0]}/home`}>{obj.npi[0]}</Link></PopoverDetails></span></React.Fragment>
                            })
                            return <>
                            {npis}
                            </>
                        }
                        else {
                            let obj = rowDataObject;
                            var formatPhoneNum = obj && obj.phone && obj.phone.toString() || '';
                            var phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            var provider = { 
                                location : {
                                'postal_code' : obj.zip,
                                'phone_number': phone,
                                'city': obj.city,
                                'state': obj.state
                                },
                                'name': {'display' : obj.name }
                            } 
                            return <React.Fragment key={uuid_v4()}><span className='tourscript-npi'><PopoverDetails provider={provider}> <Link to={`/physician/${obj.npi[0]}/home`}>{obj.npi[0]}</Link></PopoverDetails></span></React.Fragment>
                        }  
                    }  
                },
                "column": {
                        "class": "'text-center'",
                       "content": {},
                        "style": "",
                        "id": 1
                  }
              },
              {
                    "header": {
                        "id": "col16",
                        "accessor": "serviceline",
                        "content": "Service Line",
                        "defaultVisibilty": true,
                        "forcedColumn": 1,
                        "tooltip" : true,
                        "type": "Array",
                        "export": true,
                        "template": content => <span className='tourscript-serviceLineColumn'>{content}</span>
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 16                    
                    }
                },
                {
                    "header": {
                        "id": "col15",
                        "accessor": "phone",
                        "content": "Provider Phone",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                },
                {
                    "header": {
                        "id": "col14",
                        "accessor": "zip",
                        "content": "Provider ZIP",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                },
                {
                    "header": {
                        "id": "col13",
                        "accessor": "state",
                        "content": "Provider State",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "city",
                        "content": "Provider City",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col11",
                        "accessor": "entitytype",
                        "content": "Provider Entity type",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col10",
                        "accessor": "name",
                        "content": "Provider Name",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "communities",
                        "content": "Communities",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) {
                            if (content && content.length === 0) return                   
                            const arrContent = content.map((item) => {
                                const { name, insert_date, owner, npis } = item;
                                const providerCollection = {
                                    name: name || "",
                                    insert_date: insert_date && new Date(Number(insert_date)).toDateString() || "",
                                    insert_user: owner || "",
                                    npis: npis || ""
                                }
                                return (<PopoverDetails providerCollection={providerCollection}><Link to={`/community/${item.id}/home`}>{item.abbr} </Link></PopoverDetails>)  
                            }) 
                            return arrContent;
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                  "header": {
                      "id": "col3",
                      "accessor": "service",
                      "content": "Place of Service",
                     "defaultVisibilty": true,
                      "export": true,
                     "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 3
                    }
                },
             
                {
                    "header": {
                        "id": "col4",
                        "accessor": "code",
                        "content": "ICD10CM Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-codeColumn'>{content}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 4
                   }
                },
                {
                  "header": {
                      "id": "col5",
                      "accessor": "description",
                      "content": "ICD10CM Description",
                      "defaultVisibilty": true,
                      "export": true,
                      "template": function(content) { 
                        var val =  <PopoverDetails providerTooltip={ content || '' }>{(content.length > 1) ? `${content.substring(0, 20)} ...`  : ''}</PopoverDetails>
                        return val;
                      }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 5
                 }
              },
                {
                  "header": {
                      "id": "col6",
                      "accessor": "totalClaims",
                      "content": "Total Claims",
                      "defaultVisibilty": true,
                      "sort": "desc",
                      "export": true,
                      "template": content => <span className="tourscript-totalclaims">{content.toLocaleString()}</span>
                  },
                  "column": {
                          "class": "'text-center'",
                          "content": {},
                          "style": "",
                          "id": 6
                      }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "uniquePatients",
                        "content": "Unique Patients",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
              {
                "header": {
                    "id": "col9",
                    "accessor": "avgSubmittedCharge",
                    "content": "Average Submitted Charge",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return currencyFormat(content) }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 9
                    }
            }
        ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        nested: "list",
        nestedSearch: true,
        pagination: true,
        reportTitle: `Diagnoses Clinical Report for ${community ? community.name : ""}`,
        localStorageName: 'com.reports.clinical.diagnosis.v-2'
        }
    }

    const renderTabs = () => {
       if (currentTab === "1") return procData ? <PhtableComponent data={procData} reportconfig={reportConfigProcedures} download={download}/> : "No data found"
       if (currentTab === "2") return diagData ? <PhtableComponent data={diagData} reportconfig={reportConfigDiagnosis} download={download}/> : "No data found"   
    }

    const NoPhysicians = () => {
        return <div className='noPhysicians'> <p>The clinical report can be run for Communities that include Physicians. Please choose another Community with type "Physician" or "Hybrid" from the <Link to="/groups">Communities</Link> page.</p></div>
    }

  return (
   <>
   { noPhysicians ? <NoPhysicians/> : null }
   <ul className="nav nav-tabs">
      <li className={currentTab === "1" ? "active" : ""}><a id="1" className="procedures-tab-link" onClick={(e) => gotoTab(e)}>Procedures</a></li>
      <li className={currentTab === "2" ? "active" : ""}><a id="2" className="diagnoses-tab-link" onClick={(e) => gotoTab(e)}>Diagnoses</a></li>
   </ul>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
   { noPhysicians ? "" :
    <div>{renderTabs()}</div>
    }
    </>
  )
}
