import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import { moduleName as sankeyModule, componentName as sankeyComponent } from '../reports/sankey/sankey';
// import { moduleName as carepathwaysModule, componentName as carepathwaysComponent } from '../reports/carepathways.community/carepathways';
import { moduleName as carepathwaysModule, updatedComponentName as carepathwaysComponent } from '../reports/carepathways.community/carepathways';
import { moduleName as specialtyFlowModule, componentName as specialtyFlowComponent } from '../reports/specialtyflow.community/specialtyflow';
import { moduleName as flowModule, componentName as flowComponent } from '../reports/flow/flow';
import { moduleName as generalflowModule, updatedComponentName as generalflowComponent } from '../reports/generalflow.community/generalflow';
import { componentName as marketMapComponent } from '../reports/marketmap.organization/marketmap';
import { moduleName as trendingGraphModule, updatedComponentName as trendingGraphComponent } from '../reports/trendinggraph/trending';


export default angular.module('app.pages.community.graphs', [
    uirouter, carepathwaysModule, specialtyFlowModule, flowModule, sankeyModule, trendingGraphModule, generalflowModule
]).config(['$stateProvider',
function($stateProvider) {

    $stateProvider.state({
        name: 'root.app.com.graphs',
        abstract: true,
        template: '<ui-view class="app.com.graphs"/>',
        url: ''
    });
    
    $stateProvider.state({
        name: 'root.app.com.graphs.sankey',
        abstract: true,
        // component: sankeyComponent
    });
    
    $stateProvider.state({
        name: 'root.app.com.graphs.sankey.carepathways',
        url: '/carejourney',
        altUrls: ['^/community/butterfly/:cid','^/community/:cid/carepathways'],
        component: carepathwaysComponent,
        data: {
            name: 'Care Journey'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.graphs.sankey.specialtyflow',
        url: '/SpecialtyGraph',
        altUrls: ['^/community/specialtyflow/:cid', '^/community/:cid/specialtyflow' ],
        component: specialtyFlowComponent,
        data: {
            name: 'Specialty'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.graphs.sankey.flow',
        url: '/flow',
        altUrls: ['^/community/flow/:cid'],
        component: flowComponent,
        data: {
            name: 'Flow'
        }
    });
    
     $stateProvider.state({
        name: 'root.app.com.graphs.sankey.generalflow',
        url: '/generalflow',
        component: generalflowComponent,
        data: {
            name: 'General Flow',
            isActive: function(context) {
                return new Promise((resolve, reject) => {
                    return context.type().then(comType => {
                        return resolve(comType === '1');
                    });
                });
            }
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.graphs.marketmap',
        url: '/MarketMakers',
        component: marketMapComponent,
        data: {
            name: 'Market Makers',
            isActive: function(context) {
                return new Promise((resolve, reject) => {
                    return context.type().then(comType => {
                        return resolve(comType === '2');
                    });
                });
            }
        }
    });
    
    $stateProvider.state({
        name: 'root.app.com.graphs.trendinggraph',
        url: '/trendinggraph',
        component: trendingGraphComponent,
        data: {
            name: 'Trending'
        }
    });

}]).name;