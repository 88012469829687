import React, { useEffect, useRef } from 'react';

import { trendingLineGraph } from './trendingLineGraphModule.js';

export function TrendingLineGraph({
    graphdata,
    currentaxis,
    options,
    template
}) {
    
    const svgRef = useRef(null);
    const chart = useRef(null);

    useEffect(() => {
        if (svgRef) {
            chart.current = trendingLineGraph(svgRef);
        }
        return () => {
            chart.current.remove();
        };
    }, []);

    useEffect(() => {
        if (chart.current &&  currentaxis ) {
            chart.current.currentAxis(currentaxis);
        }
    }, [currentaxis]);

    useEffect(() => {
        if (chart.current && options) {
            chart.current.options(options);
        }
    }, [options]);

    useEffect(() => {
        if (chart.current && graphdata) {
            chart.current.graphData(graphdata);
        }
    }, [graphdata]);

    useEffect(() => {
        var resizeDisposal;
        var resizeTimer;
        if (template && chart.current) {
            resizeDisposal = template.contentResize(function(){
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function() {
                    // Run code here, resizing has "stopped"
                   chart.current.resize();
                }, 250);
            });
        }

        return function() {
            if (resizeDisposal) {
                resizeDisposal();
            }
        };
    }, [template]);

    return (
        <svg ref={svgRef}></svg>
    );
}