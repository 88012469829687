import jQuery from 'jquery';
import * as d3 from 'd3';

(function($) {

    var chart, xAxis, yAxis, xAxisContainer, yAxisContainer, zoom, resetted;
    
    function buildChart(options) {
        d3.select(this.get(0)).select('svg').remove();
        
        this.data('chart', chart = d3.select(this.get(0)).append('svg'));
        
        options.x.scale
            .range([0, options.dimensions.width - options.dimensions.marginLeft - options.dimensions.marginRight]);

        options.y.scale
            .range([options.dimensions.height - options.dimensions.marginTop - options.dimensions.marginBottom, 0]);

        options.size.scale
            .range([5,45]);
        
        chart
            .attr("width", options.dimensions.width)
            .attr("height", options.dimensions.height)
            .style("float", "left");
            
        var clipPathId = 'clip-path-' + Math.round(Math.random() * 1000000);
        
        chart
            .append('clipPath')
            .attr('id', clipPathId)
            .append("rect")
                .attr("width", options.dimensions.width - options.dimensions.marginLeft - options.dimensions.marginRight)
                .attr("height", options.dimensions.height - options.dimensions.marginTop - options.dimensions.marginBottom)
                .attr("transform", "translate(" + options.dimensions.marginLeft + "," + options.dimensions.marginTop + ")");
            
        var clip = chart.append('g')
            .attr("clip-path", 'url(#' + clipPathId + ')');
            
        clip
            .append("rect")
                .attr('id', 'light-gray-fill')
                .attr("width", options.dimensions.width - options.dimensions.marginLeft - options.dimensions.marginRight)
                .attr("height", options.dimensions.height - options.dimensions.marginTop - options.dimensions.marginBottom)
                .attr("transform", "translate(" + options.dimensions.marginLeft + "," + options.dimensions.marginTop + ")")
                .style("fill", '#fafafa');
            
        var g = clip.append("g")
            .attr("class", "viewport")
            .attr("width", options.dimensions.width - options.dimensions.marginLeft - options.dimensions.marginRight)
            .attr("height", options.dimensions.height - options.dimensions.marginTop - options.dimensions.marginBottom)
            .attr("transform", "translate(" + options.dimensions.marginLeft + "," + options.dimensions.marginTop + ")");
            
        $(this).data('nodes', g);
        
        chart
            .append('text')
            .attr('class', 'chart-title')
            .style("text-anchor", "middle")
            .attr('x', options.dimensions.width/2)
            .attr('y', options.dimensions.marginTop/2);

        buildTitle(options);
            
        chart
            .append('text')
            .attr('class', 'chart-title-back')
            .style("text-anchor", "start")
            .style('cursor', 'pointer')
            .attr('x', options.dimensions.marginLeft)
            .attr('y', options.dimensions.marginTop/2)
            .on('click', function(datum){
                if (options.events.back) {
                    options.events.back(d3.event, datum);
                }
            });

        evalBtn(options);
        
        resetted = function() {
            chart.transition()
                .duration(750)
                .call(zoom.transform, d3.zoomIdentity);
        };
            
        var reset = chart
            .append('text')
            .attr('class', 'chart-title-reset')
            .style("text-anchor", "end")
            .attr('x', (options.dimensions.width - options.dimensions.marginRight))
            .attr('y', options.dimensions.marginTop/2)
            .style('cursor', 'default')
            .on('click', resetted);
            
        var manualZoom = chart
            .append('g')
            .attr('class', 'manual-zoom')
            .attr('transform', 'translate('+ (options.dimensions.marginLeft) +','+ (options.dimensions.marginTop) +')');
            
        var manualZoomButtonIn = manualZoom
            .append('g')
                .attr('class', 'manualZoomIn')
                .style('cursor', 'pointer')
                .attr('transform', 'translate(15,15)');
            
            
        manualZoomButtonIn
            .append('rect')
                .attr('width', 30)
                .attr('height', 30);
            
        manualZoomButtonIn
            .append('line')
                .attr('x1', 5)
                .attr('y1', 15)
                .attr('x2', 25)
                .attr('y2', 15);
            
        manualZoomButtonIn
            .append('line')
                .attr('x1', 15)
                .attr('y1', 5)
                .attr('x2', 15)
                .attr('y2', 25);
            
        var manualZoomButtonOut = manualZoom
            .append('g')
                .attr('class', 'manualZoomOut')
                .style('cursor', 'pointer')
                .attr('transform', 'translate(15,45)');
            
        manualZoomButtonOut.append('rect')
            .attr('width', 30)
            .attr('height', 30);
        
        manualZoomButtonOut
            .append('line')
                .attr('x1', 5)
                .attr('y1', 15)
                .attr('x2', 25)
                .attr('y2', 15);
        
        xAxisContainer = chart
            .append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(" + options.dimensions.marginLeft + "," + (options.dimensions.height - options.dimensions.marginBottom) + ")");

        chart
             .append("text")
                .attr("class", "xlabel")
                .attr("x", options.dimensions.marginLeft)
                .attr("y", (options.dimensions.height - options.dimensions.marginBottom) + (options.dimensions.marginBottom * .25))
                .style("text-anchor", "start")
                .text(options.x.axisLabel);
            
        yAxisContainer = chart
            .append("g")
                .attr("class", "y axis")
                .attr("transform", "translate(" + options.dimensions.marginLeft + "," + options.dimensions.marginTop + ")");

        chart
            .append("text")
                .attr("class", "ylabel")
                .attr("transform", "rotate(-90)")
                .attr("x", -(options.dimensions.height - options.dimensions.marginTop - options.dimensions.marginBottom))// align to x-axis line
                .attr("y", options.dimensions.marginLeft/4)//places anchor 25% of the way from the left bondary of left margin to avoid ticks overlap
                .style("text-anchor", "start")
                .text(options.y.axisLabel);
                
        chart
            .append("defs")
                .append("linearGradient")
                    .attr("id", "gradient-scale-colors")
                    .attr("x1", "0%")
                    .attr("y1", "0%")
                    .attr("x2", "100%")
                    .attr("y2", "0%")
                    .selectAll("stop")
                    .data(options.color.scale.ticks().map(function(tick, i, ticks){
                        return {
                            color: options.color.scale(options.color.scale.domain()[1] - tick),// (1 - tick) will reverse color direction
                            offset: tick
                        };
                    }))
                        .enter().append("stop")
                            .attr("offset", function(d) { return d.offset; })
                            .attr("stop-color", function(d) { return d.color; });
                    
        var colorLegend = chart
            .append("g")
                .attr("class", "color-legend-wrapper")
                .attr("transform", "translate(" + (options.dimensions.width/2 ) + "," + (options.dimensions.height - (options.dimensions.marginBottom/2)) + ")" );//place at center of bottom margin
        
        colorLegend
            .append("rect")
                .attr("x", -options.dimensions.width/4)//since bar is half width of chart x-coordinate is quarter of the way from the left
                .attr("y", -10)
                .attr("class", "color-legend-rect")
                .attr("width", options.dimensions.width/2)//color bar is half width of chart
                .attr("height", 12)
                .attr("stroke", "#000")
                .attr("stroke-width", 1)
                .attr("shape-rendering", "auto")
                .style('opacity', options.nodes.opacity.value)//.9
                .style("fill", "url(#gradient-scale-colors)");
                
        colorLegend
            .append("text")
                .attr("class", "color-legend-title")
                .attr("x", ((-options.dimensions.width/4) - 5))// offset of text is based on half chart width on center
                .attr("y", 0)
                .style("text-anchor", "end")
                .text(options.color.axisLabel);
                
        var colorLegendScale =  d3.scaleLinear()
            .range([0, (options.dimensions.width/2)])
            .domain([0, 100]);
            
        var colorLegendAxis = d3.axisBottom()
            .ticks(6)
            .tickFormat(options.color.tickFormat)
            .scale(colorLegendScale);
            
        colorLegend
            .append("g")
                .attr("class", "color-legend-axis")
                .attr("transform", "translate(" + (-options.dimensions.width/4) + "," + (2) + ")")
                .call(colorLegendAxis);
                
        var sizeLegend = chart
            .append("g")
                .attr("class", "size-legend-wrapper")
                .attr("transform", "translate(" + ((options.dimensions.width - ((options.size.scale.range()[1] + options.size.scale.range()[0]) * 2))) + "," + (options.dimensions.height - (options.dimensions.marginBottom/2)) + ")" );
                //size legend is placed at right side of bottom margin, centered on height, and width based on dimension calculations that exist in chart
                
        sizeLegend
            .append("text")
                .attr("class", "size-legend-title")
                .attr("x", -(options.size.scale.range()[1] + options.size.scale.range()[0]))
                .style("text-anchor", "end")
                .text(options.size.axisLabel);
        
        xAxis = d3.axisBottom(options.x.scale)
            .tickFormat(options.x.tickFormat)
            .tickSize(-(options.dimensions.height - options.dimensions.marginTop - options.dimensions.marginBottom));

        yAxis = d3.axisLeft(options.y.scale)
            .tickFormat(options.y.tickFormat)
            .tickSize(-(options.dimensions.width - options.dimensions.marginLeft - options.dimensions.marginRight));
        
        xAxisContainer.call(xAxis);
        yAxisContainer.call(yAxis);
        
        
        
        function zoomed() {
            g.attr("transform", "translate(" + (options.dimensions.marginLeft + d3.event.transform.x) + "," + (options.dimensions.marginTop + d3.event.transform.y) + ") " + "scale(" + d3.event.transform.k + ")");
            xAxisContainer
                .call(xAxis.scale(d3.event.transform.rescaleX(options.x.scale)));
            yAxisContainer
                .call(yAxis.scale(d3.event.transform.rescaleY(options.y.scale)));
            if (d3.event.transform.k > 1) {
                reset
                    .style('cursor', 'pointer')
                    .text('Current Zoom:' +  d3.format(".2")(d3.event.transform.k) + 'X | Reset Pan and Zoom');
            } else if (d3.event.transform.x !== 0 || d3.event.transform.y !== 0){
                 reset
                    .style('cursor', 'pointer')
                    .text('Current Zoom:' +  d3.format(".2")(d3.event.transform.k) + 'X | Reset Pan');
            } else {
                reset
                    .text('Current Zoom:' +  d3.format(".2")(d3.event.transform.k) + 'X');
            }
            
        }
        
        zoom = d3.zoom()
            .scaleExtent([1, 10])
            .on("zoom", zoomed)
            .clickDistance(4);
            
        chart.call(zoom);
        
        manualZoomButtonIn
            .on('click', function(){
                zoom.scaleBy(chart.transition().duration(750), 2);
            });
            
        manualZoomButtonOut
            .on('click', function(){
                zoom.scaleBy(chart.transition().duration(750), 1 / 2);
            });
        
        bindData.call(g, options);
    }
    
    function buildTitle(options){
        chart.select('.chart-title')
            .text(options.title);
    }
    
    function evalBtn(options){
        if (options.back.visible){
            chart.select('.chart-title-back')
                .style('display', 'block')
                .text(options.back.value);
        } else {
            chart.select('.chart-title-back')
                .style('display', 'none');
        }
    }
    
    function bindData(options) {
        
        var g = this;
        
        resetted();// reset on drilldown/back
        
        [options.x, options.y, options.size].forEach(function(axis) {
            var extent = d3.extent(options.data, axis.value).map(function(val) { return val + 0.0; });
            if (axis.scale.base || extent[1] - extent[0] == 0) {// log scale or single node'
                extent[0] *= .9;
                extent[1] *= 1.1;
            } else {// not log scale
                extent[0] -= (extent[1] - extent[0]) * .05;
                extent[1] += (extent[1] - extent[0]) * .05;
            }
            axis.scale.domain(extent);
        });
            
        xAxisContainer.call(xAxis.scale(options.x.scale));
        yAxisContainer.call(yAxis.scale(options.y.scale));
        
        var sizeLegend = d3.select('.size-legend-wrapper');
        
        sizeLegend.selectAll("circle.legend-bubble").remove();
        sizeLegend.selectAll("line.legend-bubble-line").remove();
        sizeLegend.selectAll("text.legend-bubble-label").remove();
        
        function datumQtyCheck(options){
            if (options.data.length === 1) {// handle single value
                return [options.size.value(options.data[0])];
            } else {// handle multiple values
                var min = d3.min(options.data, options.size.value);
                var middle = (d3.max(options.data, options.size.value) - (d3.max(options.data, options.size.value) - d3.min(options.data, options.size.value))/2);
                var max = d3.max(options.data, options.size.value);
                return [max, middle, min];
            }
        }
        
        if (options.data.length > 0) {// only render full size legend if there are datums, e.g. when data returns from api
            var sizeLegendCircles = sizeLegend.selectAll("circle.legend-bubble")
                .data(datumQtyCheck(options));
            
            sizeLegendCircles
                .enter()
                    .append("circle")
                        .attr("class", "legend-bubble")
                        .style("fill", "#fff")
                        .style('opacity', options.nodes.opacity.value)//.9
                        .style("stroke", "rgb(51, 51, 51)")
                        .style("stroke-width", 1)
                        .attr("r", function(d){
                            return options.size.scale(d);
                        })
                        .attr("cy", function(d){
                            return options.size.scale.range()[1] - options.size.scale(d);
                        });
                        
            sizeLegendCircles
                .enter()
                    .append("line")
                        .attr("class", "legend-bubble-line")
                        .style("stroke", "#000")
                        .style("stroke-width", 1)
                        .style("stroke-dasharray", "2,2")
                        .attr("x1", function(d){
                            return options.size.scale(d);
                        })
                        .attr("y1", function(d){
                            return options.size.scale.range()[1] - options.size.scale(d);
                        })
                        .attr("x2", options.size.scale.range()[1] + options.size.scale.range()[0])
                        .attr("y2", function(d){
                            return  options.size.scale.range()[1] - options.size.scale(d);
                        });
                        
                        
            sizeLegendCircles
                .enter()
                    .append("text")
                        .attr("class", "legend-bubble-label")
                        .attr("x", function(d){
                            return options.size.scale.range()[1] + options.size.scale.range()[0];
                        })
                        .attr("y", function(d){
                            return options.size.scale.range()[1] + options.size.scale.range()[0] - options.size.scale(d);
                        })
                        .text(function(d){
                             return options.size.tickFormat(d);
                        });
        }
        
        var selection = this.selectAll(".bubble")
                            .data(options.data, options.id);
        
        selection.exit().remove();
        
        function transform(d){
            return [
                options.x.scale(options.x.value(d)),
                options.y.scale(options.y.value(d))
            ];
        }
        
        var bubbles = selection
            .enter()
                .append("g")
                    .attr("class", "bubble")
                    .style('opacity', options.nodes.opacity.value)//.9
                    .style("text-anchor", "middle")
                    .style("font", "10px sans-serif")
                    .attr("transform", function(d){
                        var coords = transform(d);
                        return 'translate(' + coords[0] + ',' + coords[1] + ')';
                    });
        bubbles
            .append('circle')
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", function(d){
                    return options.size.scale(options.size.value(d));
                })
                .attr("shape-rendering", "auto")
                .style("fill", function(d) {
                    return options.color.scale(options.color.value(d));
                })
                .style("stroke", "rgb(51, 51, 51)")
                .style("stroke-width", .25)
            .on('mouseenter', function(datum, index, bubbles) {

                options.events.hover(d3.event, datum, index);
                
                var focusContainer = d3.select(bubbles[index].parentNode);
                
                var r = options.size.scale(options.size.value(datum));
                
                focusContainer.append("line")// horizontal line
                    .attr("class", "x-hover-line hover-line")
                    .attr("x1", -r)
                    .attr("y1", 0)
                    .attr("x2", -options.dimensions.width)
                    .attr("y2", 0)
                    .style("stroke", function(datum) {
                        return options.color.scale(options.color.value(datum));
                    });
                
                focusContainer.append("line")// vertical line
                    .attr("class", "y-hover-line hover-line")
                    .attr("x1", 0)
                    .attr("y1", r)
                    .attr("x2", 0)
                    .attr("y2", options.dimensions.height)
                    .style("stroke", function(datum) {
                        return options.color.scale(options.color.value(datum));
                    });
                
                d3.selectAll('.bubble')
                    .style('opacity', function(d){
                        if (d.id !== datum.id) {
                            return options.nodes.opacity.hover.inactive;//.2
                        } else {
                            return options.nodes.opacity.hover.active;//1
                        }
                    });
            
            })
            .on('mouseleave', function(datum, index, bubbles) {
                
                d3.select(bubbles[index].parentNode).selectAll('line.hover-line').remove();
                d3.select('g.axis').selectAll('rect.tick-guide').remove();
                
                options.events.hover(d3.event, datum, index);
                
                d3.selectAll('.bubble')
                    .style('opacity', options.nodes.opacity.value);//.9
                
            })
            .on('click', function(d, i, nodes){
                
                if (options.events.click) {
                    options.events.click(d3.event, d, i);
                }
            });
        
        bubbles
            .each(function(d){
                
                var text = options.label(d);
                var words = text.split(' ');
                
                var measureWidth = function(){
                    return function(text){ 
                        return document.createElement("canvas").getContext("2d").measureText(text).width;
                    };
                }();
                
                var lineHeight = 12;
                
                var targetWidth = Math.sqrt(measureWidth(text.trim()) * lineHeight);
                
                var lines = function(){
                    var line;
                    var lineWidth0 = Infinity;
                    var lines = [];
                    for (var i = 0, n = words.length; i < n; ++i) {
                        var lineText1 = (line ? line.text + " " : "") + words[i];
                        var lineWidth1 = measureWidth(lineText1);
                        if ((lineWidth0 + lineWidth1) / 2 < targetWidth) {
                            line.width = lineWidth0 = lineWidth1;
                            line.text = lineText1;
                        } else {
                            lineWidth0 = measureWidth(words[i]);
                            line = {width: lineWidth0, text: words[i]};
                            lines.push(line);
                        }
                    }
                    return lines;
                }();
                
                var textRadius = function(){
                    var radius = 0;
                    for (var i = 0, n = lines.length; i < n; ++i) {
                        var dy = (Math.abs(i - n / 2 + 0.5) + 0.5) * lineHeight;
                        var dx = lines[i].width / 2;
                        radius = Math.max(radius, Math.sqrt(dx ** 2 + dy ** 2));
                    }
                    return radius;
                }();
                
                var circleContainer = d3.select(this);
                
                circleContainer
                    .append('text')
                        .attr("transform", 'scale(' + (options.size.scale(options.size.value(d)) / textRadius) + ')')
                        .attr("pointer-events", "none")
                    .selectAll("tspan")
                    .data(lines)
                    .enter()
                        .append("tspan")
                            .attr("x", 0)
                            .attr("y", function(d, i){
                                return ((i - lines.length / 2 + 0.8) * lineHeight);
                            })
                            .text(function(d){
                                return d.text;
                            });
                
            });
        // sort to ensure smaller circles are layered on top of larger circles
        bubbles.sort(function(a, b){
            return d3.descending(options.size.value(a), options.size.value(b));
        });
    }
    
    $.fn.d3Bubble = function(action, value) {
        
        var $d3BubbleContainerDiv = $(this);
        
        var options = $(this).data('options');
        if (!options) {
            $(this).data('options', options = { /* defaults */
                data: [],
                back: {},
                events: {},
                dimensions: {
                    width: $(this).width(),
                    height: $(this).height(),
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    marginBottom: 0
                },
                title: '',
                nodes: {
                    opacity: {
                        value: .9,
                        hover: {
                            active: 1,
                            inactive: .2  
                        }
                    }
                },
                x: {
                    scale: d3.scaleLinear(),
                    value: function(d){
                        return d.x;    
                    },
                    helper: true,
                    tickFormat: function(d) { return d; }
                },
                y: {
                    scale: d3.scaleLinear(),
                    value: function(d) {
                        return d.y;    
                    },
                    helper: true,
                    tickFormat: function(d) { return d; }
                },
                size: {
                    scale: d3.scaleLinear(),
                    value: function(d) {
                        return d.size;    
                    },
                    helper: true,
                    tickFormat: function(d) { return d; }
                },
                color: {
                    value: function(d) {
                        return d.color;    
                    },
                    helper: true,
                    tickFormat: function(d) { return d; }
                },
                id: function(d, i) {
                    return i;
                },
                label: function(d) {
                    return d.label;    
                }
            });    
        }
        
        if (action === 'data' && Array.isArray(value)) {
            options.data = value;
            
            bindData.call(
                  $(this).data('nodes'),
                  options
            );
        }
        
        if (action == 'getNode' && typeof value === 'object') {
            
            return $(this).data('nodes').selectAll('.bubble').filter(function (d) {
                return d == value;
            });
        }
        
        if (action === 'button' && typeof value === 'object') {
            $.extend(options.back, value);
            evalBtn(options);
        }
        
        if (action === 'title' && typeof value === 'string') {
            if (options.title) options.title = value;
            buildTitle(options);
        }
        
        if (action === 'resize') {
            options.dimensions.width = $d3BubbleContainerDiv.width();
            options.dimensions.height = $d3BubbleContainerDiv.height();
            
            buildChart.call($d3BubbleContainerDiv, options);
        }
        
        if (typeof action === 'object' && !value) {
            Object.keys(action).forEach(function(opt) {
                if (typeof action[opt] === 'object')
                    $.extend(options[opt], action[opt]);
                else
                    options[opt] = action[opt];
            });
            
            buildChart.call(this, options);// (re)build
            
        } else if (action === 'options' && typeof value == 'object') {
            Object.keys(value).forEach(function(opt) {
                if (typeof value[opt] === 'object')
                    $.extend(options[opt], value[opt]);
                else
                    options[opt] = value[opt];
            });
            
            buildChart.call(this, options);// (re)build
            
        } else if (typeof action == 'undefined') {
            
            buildChart.call(this, options);// (re)build
        }
        
        var chart = $(this).data('chart');
        if (!chart) {
            buildChart.call(this, options);
        }
    };
    return this;
})(jQuery);