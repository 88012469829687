import angular from 'angular';
import { react2angular } from 'react2angular';

import api from '../../services/api';
import uirouter from '@uirouter/angularjs';

import './specialtyflow.scss';
import template from './specialtyflow.html';
import controller from './specialtyflow.controller';
import config from '../../services/config';

import { ProviderSpecialtyFlowComponent } from './specialtyFlowComponent';

const componentName = 'providerSpecialtyflow';
const updatedComponentName = 'providerSpecialtyflowComponent';
const moduleName = 'app.reports.sankey.specialtyflow.provider';

angular.module(moduleName, [
    api, uirouter, config
])
.component(componentName, {
    require: {
        reportSankey: '?^sankey'
    },
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(
        ProviderSpecialtyFlowComponent,
        ['npis', 'community', 'opts'],
        ['api', '$state', 'pic', 'template', 'config']
    )
);

export {
    moduleName,
    componentName,
    updatedComponentName
};