import React, {useState, useEffect, useRef} from "react";
import ReactDom from 'react-dom';
import { useRouteLoaderData } from "react-router-dom";
import {
    useLogging,
} from "../../services/servicesContext";

import { GroupedBarGraphComponent } from '../../components/groupedBarGraph/groupedBarGraphComponent';

export function ServicelineBenchmarkComponent(props) {
    
    const [ loaded, setLoaded ] = useState(false);
    
    const [ opts, setOpts ] = useState(null);
    const [ data, setData ] = useState(null);

    const [ providerClinicalData, setProviderClinicalData ] = useState({});
    const [ servicelines, setServicelines ] = useState(null);
    const [ communities, setCommunities ] = useState(null);
    const [ availableCommunities, setAvailableCommunities ] = useState(null);
    const [ availableCommunitiesOrig, setAvailableCommunitiesOrig ] = useState(null);
    const [ openDropdown, setOpenDropdown ] = useState(null);

    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalData, setModalData ] = useState(null);
    const [ modalOpts, setModalOpts ] = useState(null);
    const [ modalTitle, setModalTitle] = useState(null);
    const community = useRouteLoaderData('community.cid');
    const logging = useLogging();

    var defaultGroupedBarOpts = {
        groupKey: 'serviceline'
    };

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.com.dashboards.servicelinebenchmark'
        });

        async function init() {
            try {
                let rawGroups;
                const communitiesTmp = [];
                const groups = await props.api.Groups();
                rawGroups = groups;

                let types = [];
                for (const group of groups) {
                    let a = await group.type();
                    types.push(a);
                }
                
                let availableCommunitiesTmp = rawGroups.filter((group, idx) => types[idx] === '1');
                rawGroups.forEach(group => {
                    if (group.id === community.id) {
                        communitiesTmp.push(group);
                    }
                });

                let tempArray = [];
                communitiesTmp.forEach(item => {
                    tempArray.push(item.id);
                })

                availableCommunitiesTmp = availableCommunitiesTmp.filter(function(comm) {
                    if (!tempArray.includes(comm.id)) {
                        return comm;
                    }              
                })

                setAvailableCommunitiesOrig(availableCommunitiesTmp);
                setAvailableCommunities(availableCommunitiesTmp);
                setCommunities(communitiesTmp);

                setLoaded(true);
            } catch(err) {
                console.error('error with groups data', err);
            }
        }
        if (props.config.ready) {
            init();
        }
    }, [props.config.ready, community]);

    useEffect(() => {
        if (communities) {
            let clinicalData;

            Promise.all(communities.map(community => community.npis).flat().map(npi => props.api.GetProvider(npi)))
            .then(providers => {
                return getProvidersClinicalData(
                    providers
                        .filter(provider => {
                            return (provider.entitytype == 1 && !providerClinicalData[`${provider.npi}`])
                        })
                        .map(p => p.npi),
                    providerClinicalData);
            }, err => {
                console.error('error resolving providers', err);
            })
            .then(res => {
                clinicalData = res;
                return Promise.resolve(true);
            }, err => {
                console.log('error', err);
            })
            .then(res => {
                const newServicelines = resetServicelines(clinicalData);
                resetOpts(communities, openServicelineModal);
                resetData(newServicelines);
            }, err => {
                console.error('error getting clinical data', err);
            });
        }
    }, [communities]);

    function handleDropdownClick(col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
    };

    function resetServicelines(providerClinicalData) {
        const servicelinesNew = {};//set fresh each time
        
        communities.forEach(community => {
            community.npis.forEach(npi => {
                if (providerClinicalData[`${npi}`]) {
                    providerClinicalData[`${npi}`].data.forEach(providerCode => {
                        
                        if (!servicelinesNew[`${providerCode.serviceline}`]) {// if not serviceline, create one
                            servicelinesNew[`${providerCode.serviceline}`] = {};
                            servicelinesNew[`${providerCode.serviceline}`][`${community.name}`] = providerCode.values.line_srvc_cnt;
                            // while here initalize codes lookup
                            servicelinesNew[`${providerCode.serviceline}`].codes = {};
                        } else {
                            if (!servicelinesNew[`${providerCode.serviceline}`][`${community.name}`]) {// if no total in serviceline for current community, add one
                                servicelinesNew[`${providerCode.serviceline}`][`${community.name}`] = providerCode.values.line_srvc_cnt;
                            } else {// if serviceline and current community exists on it, add current providerCode value to it
                                servicelinesNew[`${providerCode.serviceline}`][`${community.name}`] += providerCode.values.line_srvc_cnt;
                            }
                        }
                        
                        // while here take current community and add to relevant code totals
                        if (!servicelinesNew[`${providerCode.serviceline}`].codes[`${providerCode.code}`]) {//check for current code initialization within serviceline
                            servicelinesNew[`${providerCode.serviceline}`].codes[`${providerCode.code}`] = {};
                            servicelinesNew[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name] = providerCode.values.line_srvc_cnt;
                        } else {// if current code initialized
                            if (!servicelinesNew[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name]) {//check if current community is initialized on current code
                                servicelinesNew[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name] = providerCode.values.line_srvc_cnt;
                            } else {// and add to it if current community is initialized on current code
                                servicelinesNew[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name] += providerCode.values.line_srvc_cnt;
                            }
                        }
                        
                    });
                }
            });
        });

        setServicelines(servicelinesNew);
        return servicelinesNew;
    }

    function getProvidersClinicalData(npis, passedProviderClinicalData) {
        if (npis.length > 0) {//if npis provided just return the original clinical data
            
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': props.api.options().headers.Authorization, 'X-TEAM-TTM': props.api.options().headers["X-TEAM-TTM"]},
            body: JSON.stringify({ npis: npis })
        };

            return fetch('/api/npi/clinical/',requestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.data.length === 0) {
                    props.notify.alert({
                        title: 'NO CLINICAL DATA',
                        text: 'No clicical data found for the requested community.',
                        delay: 10000
                    });
                }

                const providerClinicalDataCopy = {...passedProviderClinicalData};
                res.data.forEach(providerCode => {//add providers clinical data to the providerClinicalData lookup
                    if (!providerClinicalDataCopy[`${providerCode.npi}`]) {
                        providerClinicalDataCopy[`${providerCode.npi}`] = res.npis[`${providerCode.npi}`];
                        providerClinicalDataCopy[`${providerCode.npi}`].data = [providerCode];
                    } else {
                        providerClinicalDataCopy[`${providerCode.npi}`].data.push(providerCode);
                    }
                });
                setProviderClinicalData(providerClinicalDataCopy);
                return providerClinicalDataCopy;
            }, err => {
                console.error('error retrieving clinical data', err);
                return err;
            });
        } else {//if no npis provided just return the original clinical data
            return ctrlProviderClinicalData;
        }
        
    }

    function resetOpts(communities, openServicelineModal) {
        setOpts({
                groupKey: defaultGroupedBarOpts.groupKey,
                keys: communities.map(group => group.name),
                groupClickHandler: openServicelineModal
        });
    }

    function resetData(servicelines) {
        const newData = Object.keys(servicelines).map(servicelineKey => {
            var ret = servicelines[servicelineKey];
            ret.serviceline = servicelineKey;
            return ret;
        });
        setData(newData);
    }

    function openServicelineModal(servicelineData, keys) {
        
        const optsToSet = {
            groupKey: 'code',
            keys: keys,
            groupClickHandler: () => {},
            yAxisKeyHoverHandler: d => props.codes.searchCodesGet([d])
        };
        const dataToSet = Object.keys(servicelineData.codes).map(codeKey => {
            var ret = servicelineData.codes[codeKey];
            ret.code = codeKey;
            return ret;
        });
        setModalOpts(optsToSet);
        setModalTitle(servicelineData.serviceline || '');
        setModalData(dataToSet);
        setModalOpen(true);
    }

    function resetAllFilters() {
        const resetCommunities = communities.filter(comm=> comm.id == community.id);
        setCommunities(resetCommunities);
        setProviderClinicalData({});
    }

    function downloadCSV() {
        let applicableNPIs = new Set();
        let communityLookup = {};

        communities.forEach(community => {
            community.npis.forEach(npi => {
                applicableNPIs.add(`${npi}`);
                if (!communityLookup[`${npi}`]) {
                    communityLookup[`${npi}`] = [community.name];
                } else {
                    communityLookup[`${npi}`].push(community.name);
                }
            });
        });

        let reportData = Object.keys(providerClinicalData).reduce((acc, clinicalNPI) => {
            if (applicableNPIs.has(clinicalNPI) && providerClinicalData[clinicalNPI].data) {//is this provider active in the chart
                acc = acc.concat(providerClinicalData[clinicalNPI].data.map(providerCode => {
                    return {
                        'Code': providerCode.code,
                        'Codeset': providerCode.codeset,
                        'Serviceline': providerCode.serviceline,
                        'Provider Name': providerClinicalData[clinicalNPI].name.display,
                        'Provider NPI': clinicalNPI,
                        'Communities': communityLookup[clinicalNPI].join(', '),
                        'Procedures': providerCode.values.line_srvc_cnt
                    };
                }));
            }
            return acc;
        }, []);

        let columnOrder = [
            ['Code', 0],
            ['Codeset', 1],
            ['Serviceline', 2],
            ['Provider Name', 3],
            ['Provider NPI', 4],
            ['Communities', 5],
            ['Procedures', 6]
        ];

        props.download.downloadCSV(reportData, `Community Serviceline Benchmark Report for ${community.name}`, true, columnOrder);
    }

    return <div className="community-market-serviceline-component">
        { loaded  ? <div id="community-serviceline-main" className="row">
            <div id="community-serviceline-controls" className="col-md-12">
                <div className="btn-group" style={{padding:0}}>
                    <button type="button" className="btn btn-default btn-md">
                        <span>Community One </span>
                        {communities ? <span>( {communities[0].name} )</span> : ""}
                    </button>
                </div>
                { communities && communities.length >= 1 && <div className={`btn-group ${openDropdown == 1 ? 'open' : null }`} auto-close="outsideClick" style={{padding:0}}>
                    <button type="button" className="btn btn-default btn-md dropdown-toggle" aria-haspopup="true" onClick={e => handleDropdownClick(1)}>
                        <span>Community Two </span>
                        <span>( {communities?.[1]?.name || ''} )</span>
                        <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight: '600px',overflow:'auto'}}>
                        {availableCommunities && Object.keys(availableCommunities).map(key => {
                            return <li className="radio" style={{display:'block',margin:'10px'}} key={availableCommunities[key].id}>
                                <label style={{whiteSpace:'nowrap',display:'block',padding:'0px 20px'}}>
                                    <input
                                        type="radio"
                                        checked={communities?.[1]?.id === availableCommunities[key].id}
                                        value={availableCommunities[key]}
                                        style={{top:'2px'}}
                                        onChange={e => {
                                            const communitiesCopy = [...communities];
                                            communitiesCopy[1] = availableCommunities.filter(comm => comm.id === e.target.value)[0];
                                            let availableCommTemp = availableCommunitiesOrig.filter(function(comm) {
                                                return comm.id !== e.target.value
                                            })
                                            setCommunities(communitiesCopy);
                                            setAvailableCommunities(availableCommTemp)
                                        }}
                                    />
                                    {availableCommunities[key].name}
                                </label>
                            </li>;
                        })}
                    </ul>
                </div>}
                { communities && communities.length >= 2 && <div className={`btn-group ${openDropdown == 2 ? 'open' : null }`} auto-close="outsideClick" style={{padding:0}}>
                    <button type="button" className="btn btn-default btn-md dropdown-toggle" aria-haspopup="true" onClick={e => handleDropdownClick(2)}>
                        <span>Community Three </span>
                        <span>( {communities?.[2]?.name || ''} )</span>
                        <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight: '600px',overflow:'auto'}}>
                        {availableCommunities && Object.keys(availableCommunities).map(key => {
                            return <li className="radio" style={{display:'block',margin:'10px'}} key={availableCommunities[key].id}>
                                <label style={{whiteSpace:'nowrap',display:'block',padding:'0px 20px'}}>
                                    <input
                                        type="radio"
                                        checked={communities?.[2]?.id === availableCommunities[key].id}
                                        value={availableCommunities[key]}
                                        style={{top:'2px'}}
                                        onChange={e => {
                                            const communitiesCopy = [...communities];
                                            communitiesCopy[2] = availableCommunities.filter(comm => comm.id === e.target.value)[0];
                                            let availableCommTemp = availableCommunitiesOrig.filter(function(comm) {
                                                return comm.id !== e.target.value
                                            })
                                            setCommunities(communitiesCopy);
                                            setAvailableCommunities(availableCommTemp)
                                        }}
                                    />
                                    {availableCommunities[key].name}
                                </label>
                            </li>;
                        })}
                    </ul>
                </div>}
                <button type="button" className="btn btn-default btn-md" onClick={() => resetAllFilters()}>
                    Reset Filters
                </button>
                <button type="button" className="btn btn-default btn-md" onClick={() => downloadCSV()}>
                    <i className="fa fa-download" aria-hidden="true"></i> CSV
                </button>
            </div>
            
            { (data && opts) && <>
                <ServicelineDashboardModalComponent
                    modalOpen={modalOpen}
                    opts={modalOpts}
                    data={modalData}
                    title={modalTitle}
                    template={props.template}
                    setModalOpen={setModalOpen}
                />
                <GroupedBarGraphComponent
                    data={data}
                    opts={opts}
                    template={props.template}
                />
            </>}
        </div>
        : <div className="loading-lg"></div>}
    </div>;
}

function ServicelineDashboardModalComponent(props) {
    if (!props?.modalOpen) return null;

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        minWidth: '960px',
        overflowY: 'auto',
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
    };

    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={() => props.setModalOpen(false)}>×</button>
                <h3 className="modal-title" id="modal-title">{props.title}</h3>
            </div>
            <div className="modal-body serviceline-modal-body" style={{margin: 'auto', overflowY: 'auto'}}>
                {(props.modalOpen && props.data && props.opts) && <GroupedBarGraphComponent
                    data={props.data}
                    opts={props.opts}
                    template={props.template}
                />}
            </div>
            <div className="modal-footer">
                <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={() => props.setModalOpen(false)}>Close</button>
            </div>
        </div>
    </>, document.body);
    
}