// import { Auth } from '@aws-amplify/auth';
import Auth from '@aws-amplify/auth';

export async function getToken() {
    try {
        const session = await Auth.currentSession();
        // console.log('session in getToken()', session);
        const idToken = session.getIdToken().getJwtToken();
        return idToken;
    } catch (err) {
        console.error('error getting token', err);
        return null;
    }
}

export async function getFetchHeaders() {
    try {
        const token = await getToken();
        // console.log('token in getFetchHeaders() from getToken()', token);
        return { headers : {
            Authorization: `Bearer ${token}`
        }};
    } catch (err) {
        console.error('error getting token', err);
        return {};
    }
}