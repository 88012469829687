import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { usePopper }  from 'react-popper';
import './popoverMenu.scss';

export function useOutsideAlerter(ref, closeFunc) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref && !ref.contains(event.target)) {
                closeFunc(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const reportList = [
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'other', displayName: 'Summary', url: 'home'},
    { providerTypes: ['physician', 'organization'], reportType: 'other', displayName: 'Brief', url: 'brief'},
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'graph', displayName: 'Care Journey', url: 'carejourney' },
    { providerTypes: ['physician', 'community'], reportType: 'graph', displayName: 'General Flow', url: 'generalflow', comType: '1' },
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'graph', displayName: 'Specialty', url: 'SpecialtyGraph' },
    { providerTypes: ['organization', 'community'], reportType: 'graph', displayName: 'Market Makers', url: 'MarketMakers', comType: '2' },
    { providerTypes: ['organization'], reportType: 'graph', displayName: 'Share/Volume', url: 'ShareByVolume'},
    { providerTypes: ['physician', 'organization'], reportType: 'graph', displayName: 'Referral Map', url: 'ReferralMap' },
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'graph', displayName: 'Flow', url: 'flow' },
    { providerTypes: ['physician'], reportType: 'graph', displayName: 'Clinical Dashboard', url: 'clinicaldashboard' },
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'graph', displayName: 'Trending', url: 'trendinggraph' },
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'report', displayName: 'Shared Visits', url: 'SharedVisitsReport'},
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'report', displayName: 'by Specialty', url: 'bySpecialty'},
    { providerTypes: ['physician', 'organization', 'community'], reportType: 'report', displayName: 'by Organization', url: 'byOrganization'},
    { providerTypes: ['physician', 'community'], reportType: 'report', displayName: 'Clinical Report', url: 'ClinicalReport'},
    { providerTypes: ['organization'], reportType: 'report', displayName: 'Pre-Acute Share', url: 'PreAcuteShare'},
    { providerTypes: ['organization'], reportType: 'report', displayName: 'Post-Acute Share', url: 'PostAcuteShare'},
    { providerTypes: ['organization'], reportType: 'report', displayName: 'Services', url: 'services'},
    { providerTypes: ['physician'], reportType: 'report', displayName: 'Services', url: 'Services'},
    { providerTypes: ['organization'], reportType: 'report', displayName: 'PCP/Specialist Volumes', url: 'PCPSpecialistVolumes'},
    { providerTypes: ['organization'], reportType: 'report', displayName: 'Network Integrity', url: 'NetworkIntegrity'},
    { providerTypes: ['physician'], reportType: 'report', displayName: 'Summary', url: 'summary'},
    { providerTypes: ['physician'], reportType: 'report', displayName: 'Prescriptions', url: 'Prescriptions'},
    { providerTypes: ['physician'], reportType: 'other', displayName: 'Dashboard - Year Over Year', url: 'yoy'},
    { providerTypes: ['physician', 'comunity'], reportType: 'other', displayName: 'Volume Benchmarks', url: 'dashboards'},
    { providerTypes: ['physician'], reportType: 'other', displayName: 'Benchmarks - CBSA (Core Based Statistical Area) Market Volumes', url: 'marketbenchmarks'},
    { providerTypes: ['community'], reportType: 'other', displayName: 'Dashboard - Serviceline Benchmarks', url: 'marketbenchmarks'},
    { providerTypes: ['physician'], reportType: 'other', displayName: 'Dashboard - Geographic Benchmarks', url: 'geobenchmark'},
    { providerTypes: ['physician', 'organizations'], reportType: 'report', displayName: 'Service Line', url: 'ServiceLine'},
    { providerTypes: ['organization'], reportType: 'report', displayName: 'Market Share by ZIP', url: 'marketsharebyzip' },
];

function entityTypeStr(numberStr) {
    if (numberStr == '1') {
        return 'physician';
    } else if (numberStr == '2') {
        return 'organization'; 
    } else {
        console.warn('unrecognized entitytype', numberStr);
        return '';
    }
}

function reportLink(props) {
    return <div><Link className="menu-item" to={`/${props.providerType}/${props.npi}/${props.reportUrl}`}>{props.reportName}</Link></div>;
}

export function PopoverMenu(props) {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [showPopperElem, setShowPopperElem] = useState(false);
    const [refPopperElem, seRefPopperElem] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }, {name: 'offset', options: {offset: [0,5]}}],
    });

    useOutsideAlerter(popperElement, (val) => {
        setShowPopperElem(val);
        if (props.isOpenCallback) {
            props.isOpenCallback(val);
        }
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        marginBottom: 0,
        width: '320px',
        zIndex: 1000
    };

    const toggleShowPopperEl = (e) => {
        if (refPopperElem) {
            refPopperElem.click()
        }
        if (props.isOpenCallback) {
            props.isOpenCallback(!showPopperElem);
        }

        seRefPopperElem(e.currentTarget);

        setShowPopperElem(!showPopperElem);
        
    }
    
    return (<>
        <span
            ref={setReferenceElement}
            onClick={(e) => toggleShowPopperEl(e)}
        >{ props.children }</span>
            { props.provider && (props.provider.entitytype == '2') && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default popperEl" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0, fontWeight: 600}}>
                      Menu for {props.provider.name}
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        style={{lineHeight: 0.7}}
                        onClick={(e) => {
                            if (props.isOpenCallback) {
                                props.isOpenCallback(false);
                            }
                            setShowPopperElem(false)
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </h5>
                </div>
                <div className="context-menu-popover" style={{ top: '263px', left: '1010px' }}>
                    <div className="context-menu">  
                        <div className="col-sm-12 menu-column">
                            <div><Link className="menu-item" to={`/${entityTypeStr(props.provider.entitytype)}/${props.provider.npi}/home`}>Summary</Link></div>
                            <div><Link className="menu-item" to={`/organization/${props.provider.npi}/brief`}>Organization Brief</Link></div>
                        </div>

                        <div className="col-sm-6 menu-column">
                            <div className="menu-header">GRAPHS</div>
                            {reportList.filter(report => (report.providerTypes.includes('organization') && report.reportType === 'graph')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/${entityTypeStr(props.provider.entitytype)}/${props.provider.npi}/${report.url}`}>{report.displayName}</Link></div>
                            })}
                        </div>
                        <div className="col-sm-6" >
                            <div className=" menu-header">REPORTS</div>
                            {reportList.filter(report => (report.providerTypes.includes('organization') && report.reportType === 'report')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/${entityTypeStr(props.provider.entitytype)}/${props.provider.npi}/${report.url}`}>{report.displayName}</Link></div>
                            })} 
                        </div>
                    </div> 
                </div>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}

            { props.provider && (props.provider.entitytype == '1') && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default popperEl" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                      {props.provider.name}
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        style={{lineHeight: 0.7}}
                        onClick={(e) => {
                            if (props.isOpenCallback) {
                                props.isOpenCallback(!showPopperElem);
                            }
                            setShowPopperElem(false)
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </h5>
                </div>
                <div className="context-menu-popover" style={{ top: '263px', left: '1010px' }}>
                    <div className="context-menu">  
                        <div className="col-sm-12 menu-column">
                            {reportList.filter(report => (report.providerTypes.includes('physician') && report.reportType === 'other')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/${entityTypeStr(props.provider.entitytype)}/${props.provider.npi}/${report.url}`}>{report.displayName}</Link></div>
                            })}
                        </div>

                        <div className="col-sm-6 menu-column">
                            <div className="menu-header">GRAPHS</div>
                            {reportList.filter(report => (report.providerTypes.includes('physician') && report.reportType === 'graph')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/${entityTypeStr(props.provider.entitytype)}/${props.provider.npi}/${report.url}`}>{report.displayName}</Link></div>
                            })}
                        </div>
                        <div className="col-sm-6" >
                            <div className=" menu-header">REPORTS</div>
                            {reportList.filter(report => (report.providerTypes.includes('physician') && report.reportType === 'report')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/${entityTypeStr(props.provider.entitytype)}/${props.provider.npi}/${report.url}`}>{report.displayName}</Link></div>
                            })}
                        </div>
                    </div> 
                </div>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}

            { props.providerCollection && !props.provider && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                      {props.providerCollection.name}
                    </h5>
                </div>
                <div className="context-menu-popover" style={{ top: '263px', left: '1010px' }}>
                    <div className="context-menu">  
                        <div className="col-sm-12 menu-column">
                            {reportList.filter(report => (report.providerTypes.includes('community') && report.reportType === 'other')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/community/${props.providerCollection.id}/${report.url}`}>{report.displayName}</Link></div>
                            })}
                        </div>
                        <div className="col-sm-6 menu-column">
                            <div className="menu-header" >GRAPHS</div>
                            {reportList.filter(report => (report.providerTypes.includes('community') && report.reportType === 'graph')).map(report => {
                                if (report.comType) {
                                    if (report.comType === "1" && props.providerCollection.type === 'Physicians') {
                                        return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/community/${props.providerCollection.id}/${report.url}`}>{report.displayName}</Link></div>
                                    }
                                    else if (report.comType === "2" && props.providerCollection.type === 'Organizations') {
                                        return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/community/${props.providerCollection.id}/${report.url}`}>{report.displayName}</Link></div>
                                    }
                                }
                                else {
                                    return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/community/${props.providerCollection.id}/${report.url}`}>{report.displayName}</Link></div>
                                }
                            })}
                        </div>
                        <div className="col-sm-6 menu-column" >
                            <div className="menu-header">REPORTS</div>
                            {reportList.filter(report => (report.providerTypes.includes('community') && report.reportType === 'report')).map(report => {
                                return <div key={`${report.displayName}${report.url}`}><Link className="menu-item" to={`/community/${props.providerCollection.id}/${report.url}`}>{report.displayName}</Link></div>
                            })}
                        </div>
                    </div> 
                </div>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}

        </>);
}
