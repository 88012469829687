var script = {
    config: {},
    steps: [{
        content: "This report shows summary of patients, code counts, and charges for diagnoses and procedures by service line, helping you understand the volume of work a given physician or organization does by service line.<br><br>Use left/right arrow keys to navigate."
    },{
        target: ".tourscript-serviceline",
        content: "For example, this service line..."
    },{
        target: '.tourscript-diagnosis-patients',
        content: "had this many patients..."
    },{
        target: '.tourscript-diagnosis-transactions',
        content: "with this many diagnoses codes..."
    },{
        target: '.tourscript-diagnosis-charges',
        content: "that resulted in these total charges."
    },{
        target: '.tourscript-procedure-patients',
        content: "As well as, this many patients..."
    },{
        target: '.tourscript-procedure-transactions',
        content: "with this many procedure codes..."
    },{
        target: '.tourscript-procedure-charges',
        content: "that resulted in these total charges."
    },{
        target: "provider-service-line-summary ph-table thead",
        content: "You can sort on any column by simply clicking on the header name."
    },{
        target: "#tourscript-filter",
        content: "Also, you can filter results here by searching for any value in the row."
    },{
        target: "#tourscript-buttons",
        content: "Use this button to download the data in the table."
    }]
};

export default script;