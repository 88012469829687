import React, { useEffect, useState } from 'react'
import { useRouteLoaderData } from "react-router-dom";

import { useApi, useCommunityList, usePic, useTemplate } from '../../services/servicesContext';
import PanelComponent from './panelComponent';
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import './summaryPanels.scss';

export default function SummarypanelsComponent({ /* CommunityList, */ notify, download }) {
    console.log('download', download);
    const communityMembersFirstList = [];
    const communityMembersFullList = [];
    const [loading, setLoading] = useState(true);
    const [optsObject, setOptsObject] = useState(null);
    const [data, setData] = useState([]);
    const npis = useRouteLoaderData('organization.npis');
    const api = useApi();
    const CommunityList = useCommunityList();

    useEffect(() => {
      onInit();
    }, [npis]);

    const onInit = async function() {
        setLoading(true);
        var provider = npis;
        if (!provider) return
        await fetch('/api/npi/' + provider.npi + '/marketmap/', api.options())
            .then(res => res.json())
            .then(function(res) {
                
                var data = res;
                setData(data);

                var opts = {
                    loyalistsPCP: {
                        color: 'primary',
                        icon: 'fa-hospital-o',
                        title: 'Top Primary Care Physicians | Loyalists'
                    },
                    splittersPCP: {
                        color: 'red',
                        icon: 'fa-bar-chart-o',
                        title: 'Top PCP Opportunities | Splitters'
                    },
                    allCardioPulmonary: {
                        color: 'green',
                        icon: 'fa-user-md',
                        title: 'Top Pulmonary & Cardio Opportunities'
                    },
                    loyalistsSpecialists: {
                        color: 'primary',
                        icon: 'fa-sitemap',
                        title: 'Top Specialist Physicians | Loyalists'
                    },
                    splittersSpecialists: {
                        color: 'red',
                        icon: 'fa-stethoscope',
                        title: 'Top Specialist Opportunities | Splitters'
                    },
                    allOrthopaedicSurgery: {
                        color: 'green',
                        icon: 'fa-table',
                        title: 'Top Ortho Opportunities'
                    }
                };
            
                var panels = {
                    loyalistsPCP: {
                        filter: loyalistsFilter,
                        classifications: [
                            "Family Medicine (non-specialists)", 
                            "Internal Medicine (non-specialists)", 
                            "General Practice (non-specialists)"
                        ]
                    },
                    splittersPCP: {
                        filter: splittersFilter,
                        classifications: [
                            "Family Medicine (non-specialists)", 
                            "Internal Medicine (non-specialists)", 
                            "General Practice (non-specialists)"
                        ]
                    },
                    allCardioPulmonary: {
                        specializations: [
                            "Pulmonary Disease", 
                            "Interventional Cardiology", 
                            "Cardiovascular Disease"
                        ]
                    },
                    loyalistsSpecialists: {
                        filter: loyalistsFilter,
                        specializations: [ ]
                    },
                    splittersSpecialists: {
                        filter: splittersFilter,
                        specializations: [ ]
                    },
                    allOrthopaedicSurgery: {
                        classifications: [
                            "Orthopaedic Surgery",
                            "Orthopaedic Surgery (non-specialists)"
                        ]
                    }
                };
        
                function loyalistsFilter(provider) {
                    return provider['Share'] > 0.75;
                }
            
                function splittersFilter(provider) {
                    return 0.25 <= provider['Share'] && provider['Share'] <= 0.75;
                }
                
                function getSum(total, num) {
                return total + num;
                }

                function addToCommunityFullList(data) {
                data.forEach(function(datum) {
                    if (communityMembersFullList.indexOf(datum.NPI) === -1) {
                        communityMembersFullList.push(datum.NPI);
                    }
                });
                }
        
                function populateTopTen(key, list) {
                
                    var allProviders = list.sort(function(a,b){
                        return b['Share'] - a['Share'] || b['Total Charges'] - a['Total Charges'];
                    });
                    
                    opts[key].allProviders = allProviders;
                    
                    var top10Providers = allProviders.slice(0,10);
                    
                    addToCommunityFullList(top10Providers);
                    
                    opts[key].top10Providers = top10Providers;

                    var sumtotalcharges = top10Providers.map(function(i) {
                        return i['Total Charges'];
                    });
                    
                    opts[key].top10Charges = sumtotalcharges.length ? sumtotalcharges.reduce(getSum) : 0;
                    
                }
        
                Object.keys(panels).forEach(function(key) {
                    var providers = data.nodes;
                    
                    if (typeof panels[key].filter === 'function') {
                        providers = providers.filter(panels[key].filter);
                    }
                    
                    providers = providers.filter(function(p) {
                        var ret = true;
                        
                        if (p.Taxonomy !== "Allopathic & Osteopathic Physicians") {
                            ret = false;
                        }
                        
                        if (Array.isArray(panels[key].classifications)) {
                            if (panels[key].classifications.length == 0)
                                ret = ret && !!p.Classification;
                            else
                                ret = ret && panels[key].classifications.indexOf(p.Classification) > -1;
                        }
                        
                        if (Array.isArray(panels[key].specializations)) {
                            if (panels[key].specializations.length == 0)
                                ret = ret && !!p.Specialization && p.Specialization !== 'None';
                            else
                                ret = ret && panels[key].specializations.indexOf(p.Specialization) > -1 && p.Specialization !== 'None';
                        }
                        
                        return ret;
                    });
                    
                    populateTopTen(key, providers);
                });

                setLoading(false);
                setOptsObject(opts);
    
            })
            .catch(err => {
                console.log('Error fetching Organization Brief Marketmap data: ', err);
                setLoading(true);
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Organization Brief Marketmap data.',
                    delay: 30000
                });
            });
    };
    
    if (loading) {
        return <div className="loading-sm" style={{height: '50px'}}></div>;
    } else {
        return <div className="organization-summary-panels" style={{display:'inline', height:'auto'}}>
            <div className="row">
                <div className="col-lg-4">
                {optsObject && optsObject.loyalistsPCP && <PanelComponent api={api} communityList={CommunityList} download={download} optsObject={optsObject.loyalistsPCP}></PanelComponent>}
                </div>
                <div className="col-lg-4">
                {optsObject && optsObject.splittersPCP && <PanelComponent api={api} communityList={CommunityList} download={download} optsObject={optsObject.splittersPCP}></PanelComponent>}
                </div>
                <div className="col-lg-4"> 
                {optsObject && optsObject.allCardioPulmonary && <PanelComponent api={api} communityList={CommunityList} download={download} optsObject={optsObject.allCardioPulmonary}></PanelComponent>}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    {optsObject && optsObject.loyalistsSpecialists && <PanelComponent api={api} communityList={CommunityList} download={download} optsObject={optsObject.loyalistsSpecialists}></PanelComponent>}
                </div>
                <div className="col-lg-4">
                    {optsObject && optsObject.splittersSpecialists && <PanelComponent api={api} communityList={CommunityList} download={download} optsObject={optsObject.splittersSpecialists}></PanelComponent>}
                </div>
                <div className="col-lg-4">
                    {optsObject && optsObject.allOrthopaedicSurgery && <PanelComponent api={api} communityList={CommunityList} download={download} optsObject={optsObject.allOrthopaedicSurgery}></PanelComponent>}
                </div>
            </div>
        </div>
    }
}