import React, {useState, useEffect, useRef} from 'react'
import { useRouteLoaderData } from "react-router-dom";
import * as crossfilter from "crossfilter2";
import merge from 'lodash/merge';
import {HistogramComponent} from '../../directives/histogram/histogramComponent';
import {HorizontalBarGraphComponent} from '../../directives/horizontalBarGraph/horizontalBarGraphComponent';
import { useLogging } from '../../services/servicesContext';
import dompurify from 'dompurify';
export function MarketbenchmarkComponent({ $http, api, pic, codes, notify, download }) {
    const crossFilter = useRef(null);
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [cbsa, setCbsa] = useState(null);
    const [cbsaName, setcbsaName] = useState(null);
    const [codeDescriptionLookup, setCodeDescriptionLookup] = useState([]);
    const [histogramOpts, setHistogramOpts] = useState(null);
    const [sidebarOpts, setSidebarOpts] = useState(null);
    const [provider, setProvider] = useState(null);
    const [activeFilterCodes, setActiveFilterCodes] = useState([]);
    const [activeFilterServicelines, setActiveFilterServicelines] = useState([]);
    const [activeFilterCodesets, setActiveFilterCodesets] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const marketBenchmark = {};
    const [servicelineFilterCheckboxes, setServicelineFilterCheckboxes] = useState([]);
    const [codesetFilterCheckboxes, setCodesetFilterCheckboxes] = useState([]);
    const [codeFilterCheckboxes, setCodeFilterCheckboxes] = useState([]);
    const [menus, setMenus] = useState([false,false,false]);
    const servicelineMenu = useRef(null);
    const codesetMenu = useRef(null);
    const codeMenu = useRef(null);
    const logging = useLogging();

    useEffect(() => {
        logging.routeLoad({
          pathname: location.pathname,
          npis: [npis.npi],
          statename: 'root.app.phy.dashboards.marketbenchmark'
        });
        onInit();
        return () => {
          // dispose
          document.removeEventListener("click", handleOutsideClick);
        };
    }, [npis]);

    const groupByProvider = (providerCodes) => {
        var providerLookup = providerCodes.reduce((acc, val) => {
          if (!acc[`${val.provider.npi}`]) {
            acc[`${val.provider.npi}`] = {
              npi: `${val.provider.npi}`,
              provider: val.provider,
              procedures: val.values.line_srvc_cnt
            };
          } else {
            acc[`${val.provider.npi}`].procedures += val.values.line_srvc_cnt;
          }
          return acc;
        }, {});
        return Object.values(providerLookup);
      }
      
      const navToProviderHome = (npi) => {
        location.href = `physician/${npi}`;
      }
      
      const filterZeroValue = (val, idx, arr) => {
        return val.value !== 0;
      };

      const resetAllFilters = function() {
        let _provider = provider;

        _provider.activeFilterServicelines = [];
        _provider.activeFilterCodesets = [];
        _provider.activeFilterCodes = [];
        
        _provider.servicelinesControlList.forEach(code => {code.active = false});
        _provider.codesetsControlList.forEach(code => {code.active = false});
        _provider.codesControlList.forEach(code => {code.active = false});
        
        _provider.linesByServicelineDim.filter(null);
        _provider.linesByCodesetDim.filter(null);
        _provider.linesByCodeDim.filter(null);

        const data = groupByProvider(crossFilter.current.allFiltered());
        setData(data);
        setProvider(_provider);
        setActiveFilterServicelines(_provider.activeFilterServicelines);
        setActiveFilterCodesets(_provider.activeFilterCodesets);
        setActiveFilterCodes(_provider.activeFilterCodes);
        
        const updatedServicelineCheckedState = servicelineFilterCheckboxes.map((item, index) =>
          false
        );
        setServicelineFilterCheckboxes(updatedServicelineCheckedState);
        
        const updatedCodesetCheckedState = codesetFilterCheckboxes.map((item, index) =>
          false
        );
        setCodesetFilterCheckboxes(updatedCodesetCheckedState);
        
        const updatedCodeCheckedState = codeFilterCheckboxes.map((item, index) =>
          false
        );
        setCodeFilterCheckboxes(updatedCodeCheckedState);

        const updatedMenuState = menus.map((item, index) =>
          false
        );

        setMenus(updatedMenuState);

      };


      const updateServicelineFilters = function(event, clickedServiceline, position) { 
        const updatedServicelineCheckedState = servicelineFilterCheckboxes.map((item, index) =>
          index === position ? !item : item
        );

        setServicelineFilterCheckboxes(updatedServicelineCheckedState);

        event.stopPropagation();
        let _provider = provider;
        if (_provider.activeFilterServicelines.indexOf(clickedServiceline) > -1) {
            _provider.activeFilterServicelines = _provider.activeFilterServicelines.filter(code => code != clickedServiceline);
        } else {
            _provider.activeFilterServicelines.push(clickedServiceline);
        }
        
        if (_provider.activeFilterServicelines.length > 0) {
            _provider.linesByServicelineDim.filter(d => _provider.activeFilterServicelines.indexOf(d) > -1);
        } else {
            _provider.linesByServicelineDim.filter(null);
        }

        const data = groupByProvider(crossFilter.current.allFiltered());
        setData(data);
        setProvider(_provider);
        setActiveFilterServicelines(_provider.activeFilterServicelines);
      };

      const updateCodesetFilters = function(event, clickedCodeset, position) {
        const updatedCodesetCheckedState = codesetFilterCheckboxes.map((item, index) =>
          index === position ? !item : item
        );

        setCodesetFilterCheckboxes(updatedCodesetCheckedState);
        event.stopPropagation();
        let _provider = provider;
        if (_provider.activeFilterCodesets.indexOf(clickedCodeset) > -1) {
            _provider.activeFilterCodesets = _provider.activeFilterCodesets.filter(codeset => codeset != clickedCodeset);
        } else {
            _provider.activeFilterCodesets.push(clickedCodeset);
        }
        
        if (_provider.activeFilterCodesets.length > 0) {
            _provider.linesByCodesetDim.filter(d => _provider.activeFilterCodesets.indexOf(d) > -1);
        } else {
            _provider.linesByCodesetDim.filter(null);
        }
        
        const data = groupByProvider(crossFilter.current.allFiltered());
        setData(data);
        setProvider(_provider);
        setActiveFilterCodesets(_provider.activeFilterCodesets);
      };


      const updateCodeFilters = function(event, clickedCode, position) {
        const updatedCodeCheckedState = codeFilterCheckboxes.map((item, index) =>
          index === position ? !item : item
        );

        setCodeFilterCheckboxes(updatedCodeCheckedState);
        event.stopPropagation();
        let _provider = provider;
        if (_provider.activeFilterCodes.indexOf(clickedCode) > -1) {
            _provider.activeFilterCodes = _provider.activeFilterCodes.filter(code => code != clickedCode);
          } else {
            _provider.activeFilterCodes.push(clickedCode);
          }
          
          if (_provider.activeFilterCodes.length > 0) {
            _provider.linesByCodeDim.filter(d => _provider.activeFilterCodes.indexOf(d) > -1);
          } else {
            _provider.linesByCodeDim.filter(null);
          }
        
        const data = groupByProvider(crossFilter.current.allFiltered());
        setData(data)
        setProvider(_provider);
        setActiveFilterCodes(_provider.activeFilterCodes);
      };

      const handleOutsideClick = (event)=>{
        if (servicelineMenu.current && !servicelineMenu.current.contains(event.target) && codesetMenu.current && !codesetMenu.current.contains(event.target) && codeMenu.current && !codeMenu.current.contains(event.target)){
          const updatedMenuState = menus.map((item, index) =>
            false
          );

          setMenus(updatedMenuState);
        }
      }
      
   
    const onInit = async function() {
    
        document.addEventListener('click',handleOutsideClick);

        const cbsa = npis.cbsa.point;
        setCbsa(cbsa);
        const sidebarOptsObject = {
          seedProvider: npis,
          navToProviderHome: navToProviderHome
        };

        const histogramOptsObject = {
          seedProvider: npis,
          navToProviderHome: navToProviderHome
        };
        
        var params = {
          entitytypecode: 1,
          taxonomy: npis.taxonomy.code,
          cbsapoint: npis.cbsa.point,
          limit: 1_000_000_000
        };
        
        
        fetch(`/api/cbsa/${npis.cbsa.point}/`, api.getOptions())
        .then(res => res.json())
        .then(res => {
          const cbsaName = res && res[0].name;
          setcbsaName(cbsaName);
        }).catch(err => console.log('error requesting cbse', err));
        
        fetch('/api/npi/search?' + new URLSearchParams(
          params
        ), api.getOptions())
        .then(res  => res.json())
        .then(res => {
          
            let npis = res.npis.map(provider => provider.npi);
            if (npis.length === 1) {
              notify.alert({
                text: "No other providers of the same taxonomy type found in this provider's cbsa",
                type: 'warning',
                delay: 30000
              });
              setLoaded(true);
            } else {
              sidebarOptsObject.communityMembers = histogramOptsObject.communityMembers = npis.filter(npi => pic(npi).length > 0);
              var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': api.options().headers.Authorization, 'X-TEAM-TTM': api.options().headers["X-TEAM-TTM"]},
                body: JSON.stringify({npis: npis})
              };
              return fetch('/api/npi/clinical/',requestOptions);
            }
            
        }, res => {
            if (res.status > -1) {
                notify.alert({ text: 'Search failed', type: 'warning'});
            }
        })
        .then(res  => res.json())
        .then(res => {
          
          res.data = res.data.map(obj => {
            return {
              code: dompurify.sanitize(obj.code),
              codeset: dompurify.sanitize(obj.codeset),
              npi: Number(dompurify.sanitize(obj.npi)),
              'place_of_service': dompurify.sanitize(obj['place_of_service']),
              serviceline: dompurify.sanitize(obj.serviceline),
              values: {
                'average_submitted_chrg_amt': Number(dompurify.sanitize(obj.values['average_submitted_chrg_amt'])),
                'bene_day_srvc_cnt': Number(dompurify.sanitize(obj.values['bene_day_srvc_cnt'])),
                'line_srvc_cnt': Number(dompurify.sanitize(obj.values['line_srvc_cnt'])),
                'srvc_cnt': Number(dompurify.sanitize(obj.values['srvc_cnt'])),
                'unique_cnt': Number(dompurify.sanitize(obj.values['unique_cnt']))
              }
            };
          });
          const sanitizedNpis = {};
          for (const [npi, val] of Object.entries(res.npis)) {
            sanitizedNpis[dompurify.sanitize(npi)] = {
              cbsa: {
                point: dompurify.sanitize(val?.cbsa?.point)
              },
              npi: Number(dompurify.sanitize(val.npi)),
              name: {
                display: dompurify.sanitize(val?.name?.display)
              }
            };
          }
          res.npis = sanitizedNpis;
          
          const providerClinicalData = res.data.reduce((acc, item) => {
            
            if (!acc.providers[`${item.npi}`]) {
              acc.providers[`${item.npi}`] = {npi: `${item.npi}`, provider: res.npis[`${item.npi}`], procedures: item.values.line_srvc_cnt};
            } else {
              acc.providers[`${item.npi}`].procedures += item.values.line_srvc_cnt;
            }
            
            if (!acc.codes[item.codeset]) {
              acc.codes[item.codeset] = [];
            } else {
              if (acc.codes[item.codeset].indexOf(item.code) === -1) {
                acc.codes[item.codeset].push(item.code);
              }
            }
            
            if (acc.servicelines.indexOf(item.serviceline) === -1) {
              acc.servicelines.push(item.serviceline);
            }
            
            var line = {
              code: item.code,
              codeset: item.codeset,
              values: item.values,
              provider: res.npis[`${item.npi}`],
              serviceline: item.serviceline
            };

            acc.lines.push(line);
            
            return acc;
          }, { providers: [], codes: {}, lines: [], servicelines: [] });
          
          marketBenchmark.activeFilterCodes = [];
          marketBenchmark.activeFilterServicelines = [];
          marketBenchmark.activeFilterCodesets = [];
          
          const _crossfilter = crossfilter(providerClinicalData.lines.slice());
          crossFilter.current = _crossfilter;
          
          marketBenchmark.linesByProviderDim = crossFilter.current.dimension(d => d.provider.npi);
          marketBenchmark.providersByVolumeGrp = marketBenchmark.linesByProviderDim.group().reduceSum(line => line.values.line_srvc_cnt);
          
          marketBenchmark.linesByCodesetDim = crossFilter.current.dimension(d => d.codeset);
          marketBenchmark.codesetsByCountGrp = marketBenchmark.linesByCodesetDim.group();
          
          marketBenchmark.linesByCodeDim = crossFilter.current.dimension(d => d.code);
          marketBenchmark.codesByCountGrp = marketBenchmark.linesByCodeDim.group();
          
          marketBenchmark.linesByServicelineDim = crossFilter.current.dimension(d => d.serviceline);
          marketBenchmark.servicesByCountGrp = marketBenchmark.linesByServicelineDim.group();
          
          marketBenchmark.codesControlList = marketBenchmark.codesByCountGrp.top(Infinity);
          marketBenchmark.servicelinesControlList = marketBenchmark.servicesByCountGrp.top(Infinity);
          marketBenchmark.codesetsControlList = marketBenchmark.codesetsByCountGrp.top(Infinity);
          
          codes.searchCodesPost({
            codeset: 'HCPCS,CPT',
            codes: marketBenchmark.codesControlList.map(code => code.key)
          })
          .then(res => {
            marketBenchmark.codeDescriptionLookup = res.data.reduce((acc,val)=>{
              if (!acc[val.code]) {
                acc[val.code] = {
                  code: val.code,
                  codesets: [val.codeset],
                  description: val.description,
                };
              } else {
                acc[val.code].codesets.push(val.codeset);
              }
              return acc;
            },{});
            setCodeDescriptionLookup(marketBenchmark.codeDescriptionLookup );
          }, err => {
            console.error('error getting codes for lookup', err);
            setLoaded(false);
          });

          setLoaded(true);
          let data = groupByProvider(crossFilter.current.allFiltered());
  
          setHistogramOpts(histogramOptsObject);
          setSidebarOpts(sidebarOptsObject);

          setProvider(marketBenchmark);
          setData(data);
          setServicelineFilterCheckboxes(new Array(marketBenchmark.servicelinesControlList.length).fill(false))
          setCodesetFilterCheckboxes(new Array(marketBenchmark.codesetsControlList.length).fill(false))
          setCodeFilterCheckboxes(new Array(marketBenchmark.codesControlList.length).fill(false))

          marketBenchmark.downloadCSVReport = () => {
            
            let reportData = crossFilter.current.allFiltered().map(row => {
              return {
                'Code': row.code,
                'Codeset': row.codeset,
                'Serviceline': row.serviceline,
                'Provider Name': row.provider.name.display,
                'Provider NPI': row.provider.npi,
                'Procedures': row.values.line_srvc_cnt
              };
            });
            
            var columnOrder = [
                ['Code', 0],
                ['Codeset', 1],
                ['Serviceline', 2],
                ['Provider Name', 3],
                ['Provider NPI', 4],
                ['Procedures', 5]
            ];
            
            download.downloadCSV(reportData, `CBSA Market Volume Report for ${npis.toString()}`, true, columnOrder);
          };

        }, err => {
          console.error('error getting clinical data', err);
          setLoaded(false);
        });
        
      };

      const toggleDropdownClass = (e, position) => {
        if(e.target.className != 'dropdown-menu'){
          const updatedMenuState = menus.map((item, index) =>
            index === position ? !item : false
          );

          setMenus(updatedMenuState);
        }
      }

  return (
      <div className="physician-market-dashboard">
    <div className="loading-lg col-md-12" style={{ display : (loaded) ? 'none' : 'inline'}}></div>
  
    <div id="histogram-controls" >
    <div ref={servicelineMenu} className={`${menus[0] == false ? 'btn-group dropdown': 'btn-group dropdown open'}`} style={{ padding: 0, margin: '5px'}}>
      <button type="button"  onClick={(e) => toggleDropdownClass(e,0)} className="btn btn-default btn-md dropdown-toggle" aria-haspopup="true" aria-expanded="true">
          <span>Service Line </span>
          <span>( {activeFilterServicelines && activeFilterServicelines.length} filter{activeFilterServicelines.length === 1 ? '' : 's'} active ) </span>
          <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{'maxHeight': '600px', overflow : 'auto'}}>
        <li className="checkbox" style={{ display:'block', margin:'10px'}}></li> 
        <li style={{ display : (activeFilterServicelines && activeFilterServicelines.length === 0) ? 'none' : 'block', 'margin' : '10px'}}>
          <span><strong>Selected: </strong></span>
           { 
             activeFilterServicelines && activeFilterServicelines.map((activeServiceline, index) => {
              return <span key={`key-${index}`}>{activeServiceline}
                        {(activeFilterServicelines.length > 1 && activeFilterServicelines.indexOf(activeServiceline) !== activeFilterServicelines.length - 1 ) ? ' , ' : ''}</span>
            })
           }
        </li> 
        {provider && provider.servicelinesControlList && provider.servicelinesControlList.map((serviceline, index) => {
        return <li key={`key-${index}`} className="checkbox" style={{ display:'block', margin:'10px' }}>           
                  <label style={{ display:'block', padding:'0px 20px',  width: '500px' }}>
                            <input type="checkbox" style={{ top: '2px'}} checked={servicelineFilterCheckboxes[index]}

                            onChange={(event) => updateServicelineFilters(event, serviceline.key, index)} />
                            {serviceline.key} - {serviceline.value} entries 
                  </label>   
              </li>
          })}
      </ul>
    </div>
    <div ref={codesetMenu} className={`${menus[1] == false ? 'btn-group dropdown': 'btn-group dropdown open'}`} onClick={(e) => toggleDropdownClass(e,1)} style={{ padding: 0, margin: '5px'}}>
      <button type="button" className="btn btn-default btn-md dropdown-toggle" aria-haspopup="true" aria-expanded="true">
          <span>Code Type </span>
          <span>({activeFilterCodesets && activeFilterCodesets.length} filter{activeFilterCodesets.length === 1 ? '' : 's'} active ) </span>
          <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{ maxHeight: '600px', overflow:'auto'}}>
        <li style={{ display : (activeFilterCodesets && activeFilterCodesets.length === 0) ? 'none' : 'block', 'margin' : '10px'}}>
          <span><strong>Selected: </strong></span>
          { 
             activeFilterCodesets && activeFilterCodesets.map((activeCodesets) => {
              return <span>{activeCodesets}
                        {(activeFilterCodesets.length > 1 && activeFilterCodesets.indexOf(activeCodesets) !== activeFilterCodesets.length - 1 ) ? ' , ' : ''}</span>
            })
           } 
        </li>
        {provider && provider.codesetsControlList && provider.codesetsControlList.map((codeset, index) => {
        return <li key={`${codeset.key}-${index}`} className="checkbox" style={{ display:'block', margin:'10px' }}>        
                  <label style={{ display:'block', padding:'0px 20px',  width: '500px' }}>
                            <input type="checkbox" style={{ top: '2px'}} checked={codesetFilterCheckboxes[index]} onChange={(event) => updateCodesetFilters(event, codeset.key, index)} />
                            {codeset.key} - {codeset.value} codes  
                 </label>   
               </li>
          })}

      </ul>
    </div>
    <div ref={codeMenu} className={`${menus[2] == false ? 'btn-group dropdown': 'btn-group dropdown open'}`} onClick={(e) => toggleDropdownClass(e,2)} style={{ padding: 0, margin: '5px'}}>
      <button type="button" className="btn btn-default btn-md dropdown-toggle" aria-haspopup="true" aria-expanded="true">
          <span>Code </span>
          <span>({activeFilterCodes.length} filter{activeFilterCodes.length === 1 ? '' : 's'} active ) </span>
          <span className="caret"></span>
      </button>
      <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{ maxHeight: '600px', overflow:'auto'}}>
        <li style={{ display : (activeFilterCodes && activeFilterCodes.length === 0) ? 'none' : 'block', 'margin' : '10px'}}>
          <span><strong>Selected: </strong></span>
          { 
             activeFilterCodes && activeFilterCodes.map((activeCodes) => {
              return <span>{activeCodes}
                       {(activeFilterCodes.length > 1 && activeFilterCodes.indexOf(activeCodes) !== activeFilterCodes.length - 1 ) ? ' , ' : ''}
                     </span>
            })
           } 
        </li>
        {provider && provider.codesControlList && provider.codesControlList
        .filter(codes =>  codes.value !== 0)
        .map((codes, index) => {
        return <li key={`${codes.key}-${index}`} className="checkbox" style={{ display:'block', margin:'10px' }}>        
                  <label style={{ display:'block', padding:'0px 20px',  width: '500px' }}>
                            <input type="checkbox" style={{ top: '2px'}} checked={codeFilterCheckboxes[index]} onChange={(event) => updateCodeFilters(event, codes.key, index)} />
                            {codes.key} - {codes.value} providers  
                 </label>   
               </li>
          })}
      </ul>
    </div>
    <button type="button" className="btn btn-default btn-md" style={{ margin: '5px'}} onClick={() => resetAllFilters()}>
      Reset Filters
    </button>
    <button type="button" className="btn btn-default btn-md" style={{ margin: '5px'}} onClick={() => provider.downloadCSVReport()}>
      <i className="fa fa-download" aria-hidden="true"></i> CSV
    </button>
    {  
      (activeFilterServicelines.length > 0 || activeFilterCodesets.length > 0 || activeFilterCodes.length > 0 || (cbsa && cbsaName)) ? 
        (<div className="col-md-12" style={{ paddingLeft: '0px'}}>
           <span><strong>CBSA:</strong> {cbsa} {cbsaName}</span>
           { 
            activeFilterServicelines.length > 0 ? <><span><strong> Servicelines: </strong></span>
              <span>
                  {
                  activeFilterServicelines.map((activeServiceline) => {
                     const lastItem = activeFilterServicelines.length > 1 && activeFilterServicelines.indexOf(activeServiceline) !== activeFilterServicelines.length - 1
                     return <>{activeServiceline} {lastItem ? ', ' : ''}</>
                  })}
              </span></> : null
           }
           { 
            activeFilterCodesets.length > 0 ? <><span><strong> Codesets: : </strong></span>
              <span>
                  {
                  activeFilterCodesets.map((activeCodeset) => {
                     const lastItem = activeFilterCodesets.length > 1 && activeFilterCodesets.indexOf(activeCodeset) !== activeFilterCodesets.length - 1
                     return <>{activeCodeset} {lastItem ? ', ' : ''}</>
                  })}
              </span></> : null
           }
           { 
            activeFilterCodes.length > 0 ? <><span><strong> Codesets: : </strong></span>
              <span>
                  {
                  activeFilterCodes.map((activeCode) => {
                     const lastItem = activeFilterCodes.length > 1 && activeFilterCodes.indexOf(activeCode) !== activeFilterCodes.length - 1
                     return <>{activeCode} {lastItem ? ', ' : ''}</>
                  })}
              </span></> : null
           }
        </div>) : null
    }
    </div>
    <div className="col-md-12" style={{height: '90%'}}>
        <div className='col-md-10 ph-histogram'>
            { (data && histogramOpts) ? <HistogramComponent data={data} histogramOpts={histogramOpts} /> : null }
        </div>
        <div className='col-md-2 ph-horizontal-bar-graph'>
        { (data && sidebarOpts) ? <HorizontalBarGraphComponent data={data} sidebarOpts={sidebarOpts} />  : null}
        </div>
    </div>
    </div>
  )
}
