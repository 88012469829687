import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { addCommas } from '../../filters/addCommas';
import { falsyToDashesModule } from '../../filters/falsy-to-dashes-module';
import { useTourManager, useLogging } from '../../services/servicesContext';

export function ServiceLineSummaryComponent({ api, opts, pic, download, notify }) {
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(null);
    const tourManager = useTourManager();
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const logging = useLogging();

    useEffect(() => {  

        tourManager.createTour([
            {
                id: 1,
                title: 'Service Line Report - 1/11',
                text: "This report shows summary of patients, code counts, and charges for diagnoses and procedures by service line, helping you understand the volume of work a given physician or organization does by service line.<br><br>Use left/right arrow keys to navigate.",
            },
            {
                id: 2,
                attachTo: { element: ".tourscript-serviceline", on: 'bottom' },
                title: 'Service Line Report - 2/11',
                text: "For example, this service line...",
            },
            {
                id: 3,
                attachTo: { element: '.tourscript-diagnosis-patients', on: 'bottom' },
                title: 'Service Line Report - 3/11',
                text: "had this many patients...",
            },
            {
                id: 4,
                attachTo: { element: '.tourscript-diagnosis-transactions', on: 'bottom' },
                title: 'Service Line Report - 4/11',
                text: "with this many diagnoses codes...",
            },
            {
                id: 5,
                attachTo: { element: '.tourscript-diagnosis-charges', on: 'bottom' },
                title: 'Service Line Report - 5/11',
                text: "that resulted in these total charges.",
            },
            {
                id: 6,
                attachTo: { element: '.tourscript-procedure-patients', on: 'bottom' },
                title: 'Service Line Report - 6/11',
                text: "As well as, this many patients...",
            },
            {
                id: 7,
                attachTo: { element: '.tourscript-procedure-transactions', on: 'bottom' },
                title: 'Service Line Report - 7/11',
                text: "with this many procedure codes...",
            },
            {
                id: 8,
                attachTo: { element: '.tourscript-procedure-charges', on: 'bottom' },
                title: 'Service Line Report - 8/11',
                text: "that resulted in these total charges.",
            },
            {
                id: 9,
                attachTo: { element: " thead .tableHeader", on: 'bottom' },
                title: 'Service Line Report - 9/11',
                text: "You can sort on any column by simply clicking on the header name.",
            },
            {
                id: 10,
                attachTo: { element: ".filter-box", on: 'bottom' },
                title: 'Service Line Report - 10/11',
                text: "Also, you can filter results here by searching for any value in the row.",
            },
            {
                id: 11,
                attachTo: { element: "#tourscript-buttons", on: 'bottom' },
                title: 'Service Line Report - 11/11',
                text: "Use this button to download the data in the table.",
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.servicelinesummary`
        });

        getServiceLines(npis);

        return () => {
            tourManager.clearTour();
        };
    }, [npis]);
    
    function formatEndpointData(data) {
        var serviceLines = Object.keys(data).map(function(key){
            data[key].serviceLine = key;
            return data[key];
        }).map(function(serviceLineObj){
            // check for lack of diagnosis or procedure property and add empty data if absent
            var emptyData = { charges: 0, patients: 0, transactions: 0};
            if (!serviceLineObj.diagnosis) { serviceLineObj.diagnosis = emptyData; }
            if (!serviceLineObj.procedure) { serviceLineObj.procedure = emptyData; }

            // hardcode for patients and transactions < 11
            if (serviceLineObj.diagnosis.patients && serviceLineObj.diagnosis.patients < 11) { serviceLineObj.diagnosis.patients = '< 11'; }
            if (serviceLineObj.procedure.patients && serviceLineObj.procedure.patients < 11) { serviceLineObj.procedure.patients = '< 11'; }
            if (serviceLineObj.diagnosis.transactions && serviceLineObj.diagnosis.transactions < 11) { serviceLineObj.diagnosis.transactions = '< 11'; }
            if (serviceLineObj.procedure.transactions && serviceLineObj.procedure.transactions < 11) { serviceLineObj.procedure.transactions = '< 11'; }

            return serviceLineObj;
        });
        
        return serviceLines;
    }

    function getServiceLines(npis) {       
        var endpoint = '/api/npi/'+ npis.npi +'/servicelines/';  
        var requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': api.options().headers.Authorization, 'X-TEAM-TTM': api.options().headers["X-TEAM-TTM"]}
        };
        setLoading(true);         
        fetch(endpoint, requestOptions)
        .then(res => res.json())
        .then(function(res){      
            const displayData = formatEndpointData(res);
            const data = displayData.map(dataObject => { 
                return {
                    'Charges by Diagnosis Code ($)': falsyToDashesModule(dataObject.diagnosis.charges),
                    'Charges by Procedure Code ($)': dataObject.procedure.charges,
                    'Patients by Diagnosis Code': falsyToDashesModule(dataObject.diagnosis.patients),
                    'Patients by Procedure Code': falsyToDashesModule(dataObject.procedure.patients),
                    'Service Line': dataObject.serviceLine,
                    'Service Line Count by Diagnosis Code': falsyToDashesModule(dataObject.diagnosis.transactions),
                    'Service Line Count by Procedure Code': falsyToDashesModule(dataObject.procedure.transactions),
                };
            });
            setData(data);
            setLoading(false);         
        }).catch(rejection => {
            console.log('Error fetching Service Line Summary data: ', rejection);
            setLoading(false);       
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Service Line Summary data.',
                delay: 30000
            });
        });
    }

  const reportConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col1",
                    "accessor": "Service Line",
                    "content": "Service Line",
                    "defaultVisibilty": true,
                    "export": true,
                    "sort": 'asc',
                    "template": content => <span className='tourscript-serviceline'>{content}</span>
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                },

                {
                    "header": {
                        "id": "col2",
                        "accessor": "Patients by Diagnosis Code",
                        "content": "Patients by Diagnosis Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "nested": "",
                        "template": function (content) {
                            const val = (typeof content === 'number') ? addCommas(Math.round(content)) : content;
                            return <span className='tourscript-diagnosis-patients'>{val}</span>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "Service Line Count by Diagnosis Code",
                        "content": "Service Line Count by Diagnosis Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function (content) {
                            const val = (typeof content === 'number') ? addCommas(Math.round(content)) : content;
                            return <span className='tourscript-diagnosis-transactions'>{val}</span>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "Charges by Diagnosis Code ($)",
                        "content": "Charges by Diagnosis Code ($)",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) {
                            const val = (typeof content === 'number') ? addCommas(Math.round(content) ) : content;
                            return <span className='tourscript-diagnosis-charges'>{val}</span>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "Patients by Procedure Code",
                        "content": "Patients by Procedure Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function (content) {
                            const val = (typeof content === 'number') ? addCommas(Math.round(content)) : content;
                            return <span className='tourscript-procedure-patients'>{val}</span>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "Service Line Count by Procedure Code",
                        "content": "Service Line Count by Procedure Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function (content) {
                            const val = (typeof content === 'number') ? addCommas(Math.round(content)) : content;
                            return <span className='tourscript-procedure-transactions'>{val}</span>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "Charges by Procedure Code ($)",
                        "content": "Charges by Procedure Code ($)",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) {
                            const val = (typeof content === 'number') ? addCommas(Math.round(content)) : content;
                            return <span className='tourscript-procedure-charges'>{val}</span>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                }
               ],
            configure : true,
            configureButton: true,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            localStorageName: 'app.phy.reports.serviceline',
            reportTitle: `Service Line Report for ${npis && npis.name.display} (${npis.npi})` 
        }
    }

  

  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig} download={download} />  : null}
    </>
  )
}
