import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { useTourManager } from '../../services/servicesContext';

export function PerformanceReportComponent({ api, notify, codes,  download, tour }) {
    const [ codesLookup, setCodesLookUp ] = useState([]);
    const [ loading, setLoading] = useState(null);
    const [ data, setData] = useState([]);
    const [ config, setConfg ] = useState(null);
    const tourManager = useTourManager();
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const npi = npis.npi;
    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'Services Report - 1/5',
            text: "This is the Performance Report. It shows the type of billing codes used and the number of patients who received services under these billing codes. These values should roughly equate to volume statistics for the reporting entity.",
        },{
            id: 2,
            title: 'Services Report - 2/5',
            text: "The categories show the New vs Established Patients, Inpatients, Observation, and Emergency Room Visits.",
        },{
            id: 3,
            title: 'Services Report - 3/5',
            text: "Since we are using HCPCS codes, we show this information in an ascending mode which typically equates to severity of the visit.",
        },{
            id: 4,
            title: 'Services Report - 4/5',
            text: "At the bottom in bold, the Performance Report contains ratios for each of the services so you can more fully understand your conversion ratio of new patients to inpatients. This also allows you to compare your facility to your competitors.",
        },{
            id: 5,
            attachTo: { element: '#service_report', on: 'bottom' },
            title: 'Services Report 5/5',
            text: "Use the tabs to move between reports."
        }]);

        setConfg(reportConfig);
        getsearchCodesPost();
        return () => {
            tourManager.clearTour();
        };
     }, [npis]);

     const reportConfig = function() { 
        return {  
          tableConfig :  [
                {
                "header": {
                    "id": "col1",
                    "accessor": "codeORheader",
                    "content": "HCPCS",
                    "defaultVisibilty": true,
                    "export": true,
                    "sortable": false,
                    "template": function(content, rowData) {
                        if (rowData.description) {
                            return <div className="text-right">{content}</div>;
                        } else {
                            return <div className="bold">{content}</div>;
                        }
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                },
    
                {
                    "header": {
                        "id": "col2",
                        "accessor": "description",
                        "content": "Description",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": false,
                        "template": function(content) {
                            if (content === 'Categories') {
                                return <div className="bold">{content}</div>;
                            } else {
                                return content;
                            }
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col2b",
                        "accessor": "ratios",
                        "content": "",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": false,
                        "template": function(content) {
                            if (content === "Ratios") {
                                return <div className="bold">{content}</div>;
                            } else if (typeof content ) {
                                return <div className="text-right">{content}</div>;
                            } else {
                                return content;
                            }
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "sumVisits",
                        "content": "Sum Visits",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "sectionSum",
                        "content": "Section Sum",
                        "defaultVisibilty": false,
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                }
               ],
               configure : true,
               configureButton: false,
               showSelected: false,
               filterInput: false,
               csvdownload: true,
               pagination: false,
               sorting: false,
               reportTitle: `Services Report for ${npis && npis.name && npis.name.display} (${npis.npi})`,
               localStorageName: 'app.phy.reports.services'
        }
    }

    function getsearchCodesPost(){
        const overrides = {
            CPT: {
                '99201':{ description: 'New patient office or other outpatient visit, typically 10 minutes', department: 'Physician Office Visits New'},
                '99202':{ description: 'New patient office or other outpatient visit, typically 20 minutes', department: 'Physician Office Visits New'},
                '99203':{ description: 'New patient office or other outpatient visit, typically 30 minutes', department: 'Physician Office Visits New'},
                '99204':{ description: 'New patient office or other outpatient visit, typically 45 minutes', department: 'Physician Office Visits New'},
                '99205':{ description: 'New patient office or other outpatient visit, typically 60 minutes', department: 'Physician Office Visits New'},
    
                '99211':{ description: 'Established patient office or other outpatient visit, typically 5 minutes', department: 'Physician Office Visits Est'},
                '99212':{ description: 'Established patient office or other outpatient visit, typically 10 minutes', department: 'Physician Office Visits Est'},
                '99213':{ description: 'Established patient office or other outpatient visit, typically 15 minutes', department: 'Physician Office Visits Est'},
                '99214':{ description: 'Established patient office or other outpatient, visit typically 25 minutes', department: 'Physician Office Visits Est'},
                '99215':{ description: 'Established patient office or other outpatient, visit typically 40 minutes', department: 'Physician Office Visits Est'},
    
                '99218':{ description: 'Hospital observation care typically 30 minutes per day', department: 'Initial Observation Care'},
                '99219':{ description: 'Hospital observation care typically 50 minutes per day', department: 'Initial Observation Care'},
                '99220':{ description: 'Hospital observation care typically 70 minutes per day', department: 'Initial Observation Care'},
                '99221':{ description: 'Initial hospital inpatient care, typically 30 minutes per day', department: 'Initial Hospital Care'},
                '99222':{ description: 'Initial hospital inpatient care, typically 50 minutes per day', department: 'Initial Hospital Care'},
                '99223':{ description: 'Initial hospital inpatient care, typically 70 minutes per day', department: 'Initial Hospital Care'},
    
                '99231':{ description: 'Subsequent hospital inpatient care, typically 15 minutes per day', department: 'Subsequent Hospital Care'},
                '99232':{ description: 'Subsequent hospital inpatient care, typically 25 minutes per day', department: 'Subsequent Hospital Care'},
                '99233':{ description: 'Subsequent hospital inpatient care, typically 35 minutes per day', department: 'Subsequent Hospital Care'},
                
                '99234':{ description: 'Hospital observation or inpatient care low severity, 40 minutes per day', department: 'Observation Visit'},
                '99235':{ description: 'Hospital observation or inpatient care moderate severity, 50 minutes per day', department: 'Observation Visit'},
                '99236':{ description: 'Hospital observation or inpatient care high severity, 55 minutes per day', department: 'Observation Visit'},
                
                '99241':{ description: 'Office consultation for a new or established patient, typically 15 minutes', department: 'Consultation Visit'},
                '99242':{ description: 'Office consultation for a new or established patient, typically 30 minutes', department: 'Consultation Visit'},
                '99243':{ description: 'Office consultation for a new or established patient, typically 40 minutes', department: 'Consultation Visit'},
                '99244':{ description: 'Office consultation for a new or established patient, typically 60 minutes', department: 'Consultation Visit'},
                '99245':{ description: 'Office consultation for a new or established patient, typically 80 minutes', department: 'Consultation Visit'},
                '99251':{ description: 'Inpatient consultation for a new or established patient, typically 20 minutes', department: 'Consultation Visit'},
                '99252':{ description: 'Inpatient consultation for a new or established patient, typically 40 minutes', department: 'Consultation Visit'},
                '99253':{ description: 'Inpatient consultation for a new or established patient, typically 55 minutes', department: 'Consultation Visit'},
                '99254':{ description: 'Inpatient consultation for a new or established patient, typically 80 minutes', department: 'Consultation Visit'},
                '99255':{ description: 'Inpatient consultation for a new or established patient, typically 110 minutes', department: 'Consultation Visit'},
    
                '99281':{ description: 'Emergency department visit, self limited or minor problem', department: 'ED Visit'},
                '99282':{ description: 'Emergency department visit, low to moderately severe problem', department: 'ED Visit'},
                '99283':{ description: 'Emergency department visit, moderately severe problem', department: 'ED Visit'},
                '99284':{ description: 'Emergency department visit, problem of high severity', department: 'ED Visit'},
                '99285':{ description: 'Emergency department visit, problem with significant threat to life or function', department: 'ED Visit'},
    
                '99354':{ description: 'Prolonged service in the office or other outpatient setting, first hour', department: 'Other'},
                '99355':{ description: 'Prolonged service in the office or other outpatient setting, each additional 30 minutes', department: 'Other'}
            },
            HCPCS: {
                'S9083':{ description: 'Urgent Care', department: 'Urgent Care'},
                'S9088':{ description: 'Urgent Care', department: 'Urgent Care'}
            }
        };
        var overrideCodes = Object.keys(overrides).map(codeset => Object.keys(overrides[codeset])).flat();
        codes.searchCodesPost({
        codeset: Object.keys(overrides).join(','),
        codes: overrideCodes
        }).then(resCodes => {
            const rescodes = resCodes.data.reduce((acc, val) => {
                if (!acc[val.codeset]) { acc[val.codeset] = {} }
                  acc[val.codeset][val.code] = val;
                     return acc;
            }, {});
            getAndFormatData(npi, rescodes);
        }).catch(err => console.log("codesLookupResult Failed (: " + err )); 
     
    async function getAndFormatData(npi, codesLookup){
        try {
        setLoading(true);
        var SORT_ORDER = [
            "Physician Office Visits New", 
            "Physician Office Visits Est", 
            "Urgent Care",
            "ED Visit",
            "Initial Observation Care",
            "Observation Visit",
            "Consultation Visit",
            "Initial Hospital Care",
            "Subsequent Hospital Care",
            "Other"
        ].reduce(function(o, g, i) { 
            o[g] = i+1;
            return o; 
        }, {});
        
        function formatData(data) {

                var codes = data.slice();
                var foundCodes = codes.reduce(function(o, c){
                    if (!o.items[c.code]) {
                        o.items[c.code] = {[c.codeset]: c};
                        o.list.push(c.code);
                    }
                    return o;
                }, { list:[], items: {}});
                
                Object.keys(codesLookup).forEach(function(codeset) {
                    Object.keys(codesLookup[codeset]).forEach(function(code) {
                        var index = foundCodes.list.indexOf(code);
                        if (index < 0) {
                            codes.push({
                                code: code,
                                codeset: codeset,
                                _code: codesLookup[codeset][code],
                                values: {
                                    "average_submitted_chrg_amt": 0, 
                                    "bene_day_srvc_cnt": 0,
                                    "line_srvc_cnt": 0, 
                                    "national_average_submitted_to_allowed": 0, 
                                    "srvc_cnt": 0, 
                                    "unique_cnt": 0
                                }
                            });
                        } else {
                            if (!foundCodes.items[code][codeset]) {
                                foundCodes.items[code][codeset] = {};
                                foundCodes.items[code][codeset]._code = codesLookup[codeset][code];
                            } else {
                                foundCodes.items[code][codeset]._code = codesLookup[codeset][code];
                            }
                        }
                    });	
                });

                codes = codes.reduce(function(o, d){
                    
                    if (o[d.code]){
                        o[d.code].total += d.values.line_srvc_cnt;
                        return o;
                    }

                    var code = codesLookup[d.codeset][d.code];
                    var department = code.department ? code.department : 'Other Categories';
                    var description = codesLookup[d.codeset][d.code].description;

                    var override = overrides[d.codeset][d.code];
                    if (override) {
                        department = override.department || department;
                        description = override.description || description;
                    } 

                    if (!codesLookup[d.codeset][d.code].hasOwnProperty('total')){
                        codesLookup[d.codeset][d.code].total = d.values.line_srvc_cnt;
                    } else {
                        codesLookup[d.codeset][d.code].total += d.values.line_srvc_cnt;
                    }

                    o[d.code] = {
                        hcpcs_code: d.code,
                        category: department,
                        hcpcs_description: description,
                        total: d.values.line_srvc_cnt
                    };

                    return o;
                }, {});

                codes = Object.keys(codes).reduce(function(l, code) {
                    l.push(codes[code]);
                    return l;
                }, []);

                var newEst = ['Physician Office Visits New', 'Physician Office Visits Est'];
                var ratios = [{
                    'HCPCS': '',
                    'Description': 'Total Visits',
                    'Sum Visits': '',
                    'Ratios': 0,
                    'Section Sum': ''
                }];

                var funnel = {};

                var __data = codes.reduce(function(output, row, i){
                    if (!output.hasOwnProperty(row.category)){
                        output[row.category] = {
                            'section_sum': row.total
                        };
                    } else {
                        output[row.category].section_sum += row.total;
                    }
                    if (!funnel.hasOwnProperty(row.category)){
                        funnel[row.category] = {
                            'section_sum': row.total
                        };
                    } else {
                        funnel[row.category].section_sum += row.total;
                    }
                    if (!output[row.category].hasOwnProperty(row.hcpcs_code)){
                        output[row.category][row.hcpcs_code] = {
                            'description': row.category,
                            'visits': row.total,
                            'hcpcs_code': row.hcpcs_code,
                            'hcpcs_description': row.hcpcs_description
                        };
                    }
                    if (newEst.indexOf(row.category) > -1)
                        ratios[0]['Ratios'] += row.total;
                    return output;
                }, {});

                var rows = [];
                var keys = Object.keys(__data);
                keys.forEach((k) => {
                    var code = __data[k];
                    var innerKeys = Object.keys(code);
                    rows.push([k, '', '', code.section_sum, k]);
                    innerKeys.forEach((ik) => {
                        if (ik != 'section_sum') {
                            rows.push([code[ik].hcpcs_code, code[ik].hcpcs_description, code[ik].visits, '', k]);
                        }
                        else {
                            var sum_visits = code[ik] == 0 ? 0 : (ratios[0]['Ratios'])/code[ik];
                            ratios.push({
                                'HCPCS': '',
                                'Description': 'Ratio Practice Visits to ' + k, 
                                'Ratios': (sum_visits ? sum_visits : 0).toFixed(2),
                                'Sum Visits': '',
                                'Section Sum': ''
                            });
                        }
                    });
                });

                rows = rows.reduce(function(output, row){
                    var row = {
                        'HCPCS': row[0],
                        'Description': row[1],
                        'Sum Visits': (codesLookup.HCPCS[row[0]] ? codesLookup.HCPCS[row[0]].total : row[2]),
                        'Section Sum': row[3],
                        'Ratios': '',
                        'Section': row[4]
                    };
                    output.push(row);
                    return output;
                }, []);

                rows.sort(function(a,b){
                    // Sort by Section, Header > Code
                    var aSec = SORT_ORDER[a['Section']];
                    var bSec = SORT_ORDER[b['Section']];

                    if (aSec && bSec) {
                        if (aSec != bSec) 
                            return aSec - bSec;
                    } else {
                        if (aSec)
                            return -1;
                        if (bSec)
                            return 1;
                        if (b['Section'] != b['Section'])
                            return a['Section'] < b['Section'] ? -1 : 1;
                    }

                    if (a['Section Sum'] !== '')
                        return -1;
                    if (b['Section Sum'] !== '') 
                        return 1;

                    return a.HCPCS < b.HCPCS ? -1 : 1;
                });

                rows.push({
                    'HCPCS': '',
                    'Description': '',
                    'Sum Visits': '',
                    'Ratios': '',
                    'Section Sum': ''
                });
                rows.push({
                    'HCPCS': '',
                    'Description': 'Categories',
                    'Sum Visits': '',
                    'Ratios': 'Ratios',
                    'Section Sum': ''
                });
                return rows.concat(ratios);
        }

       
        const response = await fetch('/api/npi/' + npi + '/clinical/?filter_code=992*,99354,99355,-99*7,-99*38,-99*39,-99288,-99225,-99224,-99*9*,-99226,S9083,S9088', api.options())
        .then(res => res.json())
        .then(res => res)
      
            var rawData = response.data;
            rawData.forEach(d => {
                if (d.codeset === 'HCPCS' && codesLookup.CPT[d.code]) {
                    d.codeset = 'CPT';
                }
            });
            const formattedData = formatData(rawData);
            const tabledata = formattedData.map(function(datum) {
                return {
                    'codeORheader': datum.HCPCS,
                    'description': datum.Description, 
                    'ratios': datum.Ratios.toLocaleString(),
                    'sumVisits': datum['Sum Visits'] ? datum['Sum Visits'].toLocaleString() : '',
                    'sectionSum': datum['Section Sum'] ? datum['Section Sum'].toLocaleString() : ''
                };
            });

        setData(tabledata);
        setLoading(false);
        
        } catch(err) {
            console.error(err);
            setLoading(false);
        }
     }
  }

  const renderComponent = () => {
      if (data.length > 0 && config) {
          return <PhtableComponent data={data} reportconfig={reportConfig} download={download}></PhtableComponent>
      }
  }

  return (
    <div style={{ padding : '20px' }}>
         <div className="loading-lg" style={{ 'display' : (loading) ? 'inline' : 'none' }}></div>
        { renderComponent() }
    </div>
  )
}