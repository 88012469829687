import angular from 'angular';
import React, {useState, useEffect} from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { useApi, usePic, useTemplate, useCommunityList, useLogging } from '../services/servicesContext';

import { react2angular } from 'react2angular';

import uirouter from '@uirouter/angularjs';

import briefTemplate from './templates/brief.organization.html';

import tour from '../services/tour';
import api from '../services/api';
import notify from '../services/notify';

import SynopsisComponent from '../reports/synopsis.provider/synopsisComponent.js';
import SummarySharedvisitsComponent from '../reports/summarySharedvisits.provider/summarySharedvisitsComponent.js';
import SummaryPanelsComponent from '../reports/summaryPanels.organization/summaryPanelsComponent.js';

export default angular.module('app.pages.orgs.brief', [
    uirouter, tour, api, notify
])
.component('organizationBriefComponent', react2angular(OrganizationBriefComponent, ['npis'], ['tour','api', 'notify']))
.config(['$stateProvider', 
function($stateProvider) {
    
    $stateProvider.state({
        name: 'root.app.org.brief',
        url: '/brief',
        altUrls: ['^/organization/brief/:npis'],
        data: {
            name: 'Physician Brief'    
        }
        
    });
    
}]).name;

export function OrganizationBriefComponent({tour, notify, download}) {
    const npis = useRouteLoaderData('organization.npis');
    const api = useApi();
    const CommunityList = useCommunityList();
    const logging = useLogging();

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: 'root.app.org.brief'
        });

    }, [npis]);

    return (<>
        <div className="bottom50">
            <div className="jumbotron">
                <SynopsisComponent
                    npis={npis}
                    api={api}
                    notify={notify}
                    CommunityList={CommunityList}
                />
            </div>
        </div>

        <div className="bottom50">
            <SummarySharedvisitsComponent
                npis={npis}
                api={api}
                notify={notify}
                CommunityList={CommunityList}
            />
        </div>

        <div className="bottom50">
            <SummaryPanelsComponent
                npis={npis}
                api={api}
                notify={notify}
                CommunityList={CommunityList}
                download={download}
            />
        </div>
    </>);


}