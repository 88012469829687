import angular from 'angular';
import * as d3Base from 'd3';
import { sankeyCircular as d3SankeyCircular } from 'd3-sankey-circular';
import {event as d3SelectionEvent} from 'd3-selection';
import { legendColor } from 'd3-svg-legend';

import * as parseD from 'd-path-parser';

const d3 = angular.extend({}, d3Base, { sankey: d3SankeyCircular, legendColor });

// workaround for webpack breaking d3.event
// https://github.com/d3/d3-zoom/issues/32#issuecomment-229889310
d3.getEvent = () => d3SelectionEvent;// call and assign to variable wherever d3.event is needed


export default [ '$window', '$timeout', 'api', 'template', function($window, $timeout, api, template){

    var sankey;

    var defaults = {
        color: {
            scale: d3.scaleOrdinal(d3.schemeCategory10),
            value: function(d) {
                return this.scale(d);
            }
        },
        node: {
            value: function(d) { return d.valueOf() + ''; },
            colorValue: function(d) { return d.valueOf() + ''; },
            label: function(d) { return d.toString(); },
            width: 25,
            padding: 15,
            opacity: {
                full: 1,
                partial: .2
            }
        },
        link: {
            value: function(d) { return d.valueOf(); },
            opacity: {
                full: 1,
                partial: .5
            }
        },
        controlled: false,
        dimensions: {
            margin: {
                top: 10,
                right: 10,
                bottom: 10,
                left: 10
            },
            container: {},
            sankey: {}
        },
        toolTip: {
            datum: false,
            units: 'Visits',
            value: function(d) {
                return d.valueOf();
            },
            isRelation: function(d) {
                return d instanceof api.Relation;
            },
            cursorOffset: 5,
            calcLeftOffset: function(eventObj) {
                return (eventObj.pageX > 450 ? eventObj.pageX - 220 - this.cursorOffset : eventObj.pageX + 5 + this.cursorOffset);
            },
            calcTopOffset: function(eventObj){
                return eventObj.pageY - 55;
            },
            qtyFormat: function (d) {
                return d3.format(",")(d);
            },
            sourceName: function(d){
                return d.source.toString();
            },
            targetName: function(d){
                return d.target.toString();
            }
        },
        colorLegend: false
    };
    
    return {
        require: '^?reportSankey',
        scope: true,
        controllerAs: '$ctrl',
        controller: ['$scope', 'pic', 'Provider', '$templateCache', '$compile', '$transitions', function($scope, pic, Provider, $templateCache, $compile, $transitions){
            
            var $ctrl = this;
            
            var toolTip;
            
            var options = $ctrl.options = angular.copy(defaults);
            
            $scope.$watchCollection('options', function(newOpts, oldOpts, theScope) {
                
                options = $ctrl.options = angular.merge({}, defaults, newOpts);
                angular.extend(hoverScope, options.toolTip);
                
                if (toolTip) {// if prior report had tooltip remove before settup of new tooltip
                    toolTip.remove();
                }
                
                if (options.toolTip.template) {//if report has tooltip template override
                
                    var overrideTooltipLinkFn = $compile(options.toolTip.template);
                    angular.element(document.querySelector('body'))
                    .append(
                        toolTip = overrideTooltipLinkFn(hoverScope)
                    );
                    
                } else {//use default tooltip template
                    
                    var tooltipTemplate = $templateCache.get('ng-template/sankeyToolTip.html');
                    var tooltipLinkFn = $compile(tooltipTemplate);
                    angular.element(document.querySelector('body'))
                    .append(
                        toolTip = tooltipLinkFn(hoverScope)
                    );
                    
                }
                
                sankey = d3.sankey()
                    .nodeId(options.node.value)
                    .nodeWidth(options.link.arrows ? options.node.width + (options.link.arrows.directedOffset * 2) : options.node.width)
                    .nodePadding(options.node.padding)
                    .nodePaddingRatio(0.1)
                    .extent([[1, 1], [options.dimensions.sankey.width, options.dimensions.sankey.height]]);
            });
            
            var hoverScope = $scope.$new(true);
            hoverScope.pic = pic;
            
            if (options.currentCommunityPic) {
                hoverScope.currentCommunityPic = options.currentCommunityPic;
            }
            
            hoverScope.styles = {
                left: 0,
                top: 0,
                display: 'none',
                'z-index': 10001
            };
            
            [
                'sankey.link.mousemove', 
                'sankey.link.mouseleave',
                'sankey.node.mousemove',
                'sankey.node.mouseleave'
            ].forEach(function(id) {
                $scope.$on(id, function(event, d3Event, datum, context) {
                    
                    switch (id) {
                        case 'sankey.link.mouseleave':
                        case 'sankey.node.mouseleave':
                            $timeout(function(){
                                hoverScope.styles.display = 'none';
                            });
                            break;
                        case 'sankey.link.mousemove':
                        case 'sankey.node.mousemove':
                            $timeout(function(){
                                hoverScope.datum = datum;
                                hoverScope.styles.display = 'block';
                                hoverScope.styles.left = hoverScope.calcLeftOffset(d3Event);
                                hoverScope.styles.top = hoverScope.calcTopOffset(d3Event);
                            });
                    }
                    
                });
            });
            
            $ctrl.$onInit = function(){
                
            };
            
            $ctrl.$onDestroy = function(){
                
                toolTip.remove();

            };
            
            $scope.communities = function(d){
                return d instanceof Provider ? pic(d) : false;
            };
            
        }],
        link: function(scope, elem, attrs, ctrl) {
            var options = angular.extend({}, defaults);
            
            scope.$watch('$ctrl.options', function(opts) {
                if (opts) options = opts;
            });
            
            var svg, legend, legendContainer, legendRect, resizeTimer;
            
            function buildGraph(data) {
                
                svg.selectAll('*').remove();
                
                var innerMarginG = svg.append('g')
                    .attr('transform', 'translate('+(options.dimensions.margin.left)+','+(options.dimensions.margin.top)+')');
                
                var links = innerMarginG.append("g")
                    .attr('class', 'links')
                    .selectAll("g.link-container");

                links = links.data(data.links)
                    .enter()
                    .append('g')
                    .attr('class', function(d) {
                        if (typeof options.link.class == 'string') {
                            return 'link-container ' +options.link.class;
                        } else if ( typeof options.link.class == 'function') {
                            return 'link-container ' +options.link.class(d);
                        } else {
                            return 'link-container';
                        }
                    })
                    .on('click', function(d, i, nodes){
                        const d3event = d3.getEvent();
                        scope.$emit('sankey.link.click', d3event, d, i);
                    });
                
                var linkPaths = links.append("path")
                    .attr('class', 'link')
                    .attr("d", function(d){
                        return d.path;
                    })
                    .attr("stroke-width", function(d) { return d.width; });
                    
                if (options.link.arrows) {
                    var linkArrowHeads = options.link.arrows.appendArrowHeads(links);
                    var linkArrowTails = options.link.arrows.appendArrowTails(links);
                }
                
                if (options.link.animatedDashes) {
                    links.append('g')
                        .attr('class', 'g-dashes')
                        .call(options.link.animatedDashes.appendDashes);
                    var animatedDashesPaths = d3.select('g.links').selectAll('g.g-dashes').selectAll('path');
                }
                
                var nodes = innerMarginG.append('g')
                    .attr('class', 'nodes')
                    .attr('fill', 'none')
                    .attr('stroke', '#000')
                    .attr("stroke-opacity", 0.2)
                    .selectAll('g');
                    
                nodes = nodes.data(data.nodes)
                    .enter()
                        .append("g")
                        .attr('class', 'node')
                        .on('click', function(d, i, nodes){
                            const d3event = d3.getEvent();
                            scope.$emit('sankey.node.click', d3event, d, i);
                        })
                        .on('mousemove', function(d, i, nodes){
                            const d3event = d3.getEvent();
                            scope.$emit('sankey.node.mousemove', d3event, d, i);
                        })
                        .on('mouseleave', function(d, i, nodes){
                            const d3event = d3.getEvent();
                            scope.$emit('sankey.node.mouseleave', d3event, d, i);
                        })
                        .call(
                            d3.drag()
                                .subject(function(d){return d})
                                .on('drag', dragmove)
                        );
                        
                var nodeRects = nodes.append("rect")
                    .attr("x", function (d) {
                        if (options.link.arrows) {
                            return d.x0 + (options.link.arrows.directedOffset);
                        } else {
                            return d.x0;
                        }
                    })
                    .attr("y", function (d) { return d.y0 })
                    .attr("data-side", function(d) {
                        if (d.side) {
                            return d.side;
                        }
                    })
                    .attr("height", function (d) { return d.y1 - d.y0 })
                    .attr("width", function(d) {
                        if (options.link.arrows) {
                            return d.x1 - d.x0 - (options.link.arrows.directedOffset * 2);
                        } else {
                            return d.x1 - d.x0;
                        }
                    })
                    .attr("fill", function(d) { 
                        return options.color.value(
                            options.node.colorValue(d)
                        );
                    });
                
                nodes.append("text")
                    .attr("x", function(d) {
                        return d.x0 < options.dimensions.sankey.width / 2 ? d.x1 + 6 : d.x0 - 6;
                    })
                    .attr("y", function(d) {
                        return (d.y1 + d.y0) / 2;
                    })
                    .attr("dy", "0.35em")
                    .attr("text-anchor", function(d) {
                        return d.x0 < options.dimensions.sankey.width / 2 ? "start" : "end";
                    })
                    .text(options.node.label);
                    
                nodes.append('text')
                    .attr('x', function(d) {
                        return d.x0 + 5;
                    })
                    .attr('y', function(d){
                        return (d.y1 + d.y0) / 2;
                    })
                    .attr('dy', '.35em')
                    .attr('font-family', 'FontAwesome')
                    .attr('font-size', '1em')
                    .html(function(d){
                        if (scope.communities(d).length){
                            return '&#xf0c0;';
                        } else if (options.currentCommunityPic) {
                            return options.currentCommunityPic(d) ? '&#xf0c0;' : '';
                        } else {
                            return '';
                        }
                    });
                    
                nodeRects
                    .on('mouseenter', function(datum){
                        nodes.classed('no-hover', function(d){
                            if (d.sourceLinks.concat(d.targetLinks).filter(function(relation){
                                if (relation.source == datum || relation.target == datum) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }).length === 0) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                        links.classed('no-hover', function(d){
                            if (d) {
                                if (d.source == datum || d.target == datum) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                            
                        });
                    })
                    .on('mouseleave', function(datum){
                        nodes.classed('no-hover', false);
                        links.classed('no-hover', false);
                    });
                    
                links
                    .on('mousemove', function(datum, i, theNodes){

                        nodes.classed('no-hover', function(d){
                            if (d.sourceLinks.concat(d.targetLinks).indexOf(datum) == -1) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                        
                        links.classed('no-hover', function(d){
                            
                            if (options.link && options.link.hoverClassCheck) {//community butterfly and specialty flow sankey
                                return options.link.hoverClassCheck(d, datum);
                            } else {// all other sankeys
                                if (d.source == datum.source && d.target == datum.target && d._reversed == datum._reversed) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                            
                        });
                        
                        const d3event = d3.getEvent();
                        scope.$emit('sankey.link.mousemove', d3event, datum, i );
                    })
                    .on('mouseleave', function(datum, i, theNodes){

                        nodes.classed('no-hover', false);
                        links.classed('no-hover', false);
                        
                        $timeout(function(datum, i, theNodes, d3Event){
                            scope.$emit('sankey.link.mouseleave', d3Event, datum, i);
                        }, 50);
                    });
                    
                if (options.colorLegend === true && data.nodes.length > 0) {
                    
                    legendContainer = svg
                        .append('g')
                        .attr('class', 'legend')
                        .style('cursor', 'move')
                        .attr('transform', 'translate('+(options.dimensions.margin.left)+','+(options.dimensions.margin.top)+')');
                    
                    legendRect = legendContainer.append('rect');
                    
                    var activeColors = data.nodes.reduce(function(acc, node){//lookup table for legend colors/labels
                        var colorVal = options.node.colorValue(node);
                        if(!acc[colorVal]){
                            acc[colorVal] = true;
                            return acc;
                        } else {
                            return acc;
                        }
                    }, {});
                
                    legend = d3.legendColor()
                        .shape('path', d3.symbol().type(d3.symbolCircle).size(150)())
                        .shapePadding(10)
                        .scale(options.color.scale)
                        .cellFilter(function(d){
                            if (activeColors[d.label]) {
                                return true;
                            } else {
                                return false;
                            }
                        });
                    
                    var legendCellsContainer = legendContainer.append('g')
                        .attr('transform', 'translate('+(options.dimensions.margin.left)+','+(options.dimensions.margin.top)+')');
                        
                    legendCellsContainer.call(legend);
                    
                    var legendDims = legendContainer.node().getBoundingClientRect();
                    
                    legendRect
                        .attr('width', (legendDims.width + options.dimensions.margin.left + 25))//added 25 width for [+]/[-]
                        .attr('height', (legendDims.height  + options.dimensions.margin.top));
                
                    var legendButton = legendContainer
                        .append('text')
                        .attr('text-anchor', 'end')
                        .style('cursor', 'pointer')
                        .attr('transform', 'translate('+(legendDims.width + options.dimensions.margin.left - 3 + 25)+','+(options.dimensions.margin.top + 3)+')')//added 25 width for [+]/[-]
                        .text('[-]')
                        .on('click', function(d){
                            var button = d3.select(this);
                            if (button.text() === '[-]') {
                                button.text('[+]');
                                legendCellsContainer.style('display', 'none');
                                legendRect.attr('height', (options.dimensions.margin.top + options.dimensions.margin.bottom));
                            } else {
                                button.text('[-]');
                                legendCellsContainer.style('display', 'inline');
                                legendRect.attr('height', (legendDims.height + options.dimensions.margin.top));
                            }
                        });
                    
                    legendContainer.call(
                        d3.drag()
                            .subject(function(d){
                                if (d) {
                                    return d;
                                } else {
                                    var t = d3.select(this);
                                    var tr = getTranslation(t.attr("transform"));
                                    return {
                                        x: t.attr("x") + tr[0],
                                        y: t.attr("y") + tr[1]
                                    };
                                }
                            })
                            .on('drag', dragLegend)
                            .clickDistance(5)
                    );
                    
                    function getTranslation(transform) {// https://stackoverflow.com/questions/38224875/replacing-d3-transform-in-d3-v4
                        var g = document.createElementNS("http://www.w3.org/2000/svg", "g");
                        g.setAttributeNS(null, "transform", transform);
                        var matrix = g.transform.baseVal.consolidate().matrix;
                        return [matrix.e, matrix.f];
                    }
                    
                    function dragLegend(d){
                        
                        const d3event = d3.getEvent();
                        
                        var theLegend = d3.select(this);
                        var extent = sankey.extent();
                       
                        if (d3event.x < extent[0][0]) {// prevent dragging left of svg
                            d3event.x = extent[0][0];
                        }
                        
                        if (d3event.x > (extent[1][0] - legendDims.width)) {// prevent dragging right of svg
                            d3event.x = (extent[1][0] - legendDims.width);
                        }
                        
                        if (d3event.y < extent[0][1]) {// prevent dragging top of svg
                            d3event.y = extent[0][1];
                        }
                        
                        if (d3event.y > (extent[1][1] - legendDims.height)) {// prevent dragging bottom of svg
                            d3event.y = (extent[1][1] - legendDims.height);
                        }
                        
                        theLegend
                            .attr("transform", function(){
                                return "translate("+ (d3event.x) + "," + (d3event.y) + ")";
                            });
                        
                    }
                    
                }
                
                function dragmove(d) {
                
                    var node = d3.select(this);
                    var rect = node.select("rect");
                
                    var rectX = rect.attr("x");
                    var rectY = rect.attr("y");
                    
                    var extent = sankey.extent();
                    const d3event = d3.getEvent();
                    
                    if (d.x0 + d3event.dx >= extent[0][0] && d.x1 + d3event.dx <= extent[1][0]) {// prevent dragging left or right of svg
                        d.x0 = d.x0 + d3event.dx;
                        d.x1 = d.x1 + d3event.dx;
                    }
                    
                    if (d.y0 + d3event.dy >= extent[0][1] && d.y1 + d3event.dy <= extent[1][1]) {// prevent dragging top or bottom of svg
                        d.y0 = d.y0 + d3event.dy;
                        d.y1 = d.y1 + d3event.dy;
                    }
                    
                    var xTranslate = options.link.arrows ? d.x0 + options.link.arrows.directedOffset - rectX : d.x0 - rectX;
                    var yTranslate = d.y0 - rectY;
                    
                    node.attr("transform", 
                        "translate("+ (xTranslate) + "," + (yTranslate) + ")");
                    
                    sankey.update(data);
                    linkPaths.attr("d", function(d){
                        return d.path;
                    });
                    
                    if (options.link.arrows) {
                        linkArrowHeads.attr('points', options.link.arrows.calcArrowHeadPoints);
                        linkArrowTails.attr('points', options.link.arrows.calcArrowTailPoints);
                    }
                    
                    if (options.link.animatedDashes) {
                        animatedDashesPaths.attr('d', function(d){
                            return d.path;
                        });
                    }
                    
                }
                
            }
            
            function processRelations(relations) {
                return {
                    nodes: relations.reduce(function(nodes, relation) {
                            if (nodes.indexOf(relation.source) == -1) nodes.push(relation.source);
                            if (nodes.indexOf(relation.target) == -1) nodes.push(relation.target);
                            return nodes;
                        }, []).map(function(node) {
                            var ret = {
                                id: options.node.value(node)
                            };
                            
                            ret.__proto__ = node;
                            
                            return ret;
                        }),
                    links: relations.map(function(relation) {
                            var ret = { 
                                source: options.node.value(relation.source),
                                target: options.node.value(relation.target),
                                value: options.link.value(relation)
                            };
                            
                            if (relation.origin) ret.origin = relation.origin;
                            if (relation.destination) ret.destination = relation.destination;
                            
                            ret.__proto__ = relation;
                            
                            return ret;
                        })
                };
            }
            
            scope.$watchCollection('data', function(newRelations, oldRelations, theScope){
                if (newRelations) {
                    
                    updateDimensions();
                    
                    var graphData = processRelations(newRelations);
                    
                    svg 
                        .attr("width", options.dimensions.container.width)
                        .attr("height", options.dimensions.container.height);
                    
                    sankey.extent([
                        [1, 1],
                        [options.dimensions.sankey.width, options.dimensions.sankey.height]
                    ]);
                    
                    var graph = sankey(graphData);
                    buildGraph(graph);
                    
                }
            });
            
            function updateDimensions(){
                options.dimensions.container = {
                    // parent used here because sankey directive on svg element
                    width: (elem.parent().width()),
                    height: (elem.parent().height())
                };
                
                options.dimensions.sankey = {
                    width: options.dimensions.container.width - (options.dimensions.margin.left + options.dimensions.margin.right),    
                    height:  options.dimensions.container.height - (options.dimensions.margin.top + options.dimensions.margin.bottom)
                };
            }
            
            updateDimensions();
            
            svg = scope.sankeyGraph = d3.select(elem[0])
                .attr("width", options.dimensions.container.width)
                .attr("height", options.dimensions.container.height);
            
            buildGraph({
                nodes: [],
                links: []
            });

            var resizeDisposal = template.contentResize(function(){

                if (scope.data) {
                    
                    $timeout.cancel(resizeTimer);
                    resizeTimer = $timeout(function() {
                    
                        // Run code here, resizing has "stopped"
                        updateDimensions();
                        
                        var graphData = processRelations(scope.data);
                        
                        svg
                            .attr("width", options.dimensions.container.width)
                            .attr("height", options.dimensions.container.height);
                            
                        sankey.extent([
                            [1, 1],
                            [options.dimensions.sankey.width, options.dimensions.sankey.height]
                        ]);
                        
                        var graph = sankey(graphData);
                        buildGraph(graph);
                    
                    }, 250);
                    
                }

            });
            
            scope.$on('$destroy', resizeDisposal);
        }
    };

}];