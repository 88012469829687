import React, {} from "react";
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    redirect,
    Navigate,
    useLocation
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';

import {
    ServicesProvider,
    useApi,
    useConfig,
    useRecentlySelected,
    usePic,
    useAffiations,
    useAuth,
    useCodes,
    useTemplate,
    useForecasting,
    useUser,
    useCommunityList,
    useNotify,
    useLogging
} from "./services/servicesContext";

import download from "./services/downloadModule";
import quicksight from "./services/quicksightModule";

import { PhysicianRoutingComponent } from "./pages/physician";
import { OrganizationRoutingComponent } from "./pages/organization";
import { CommunityRoutingComponent } from "./pages/community";

//misc components
import { MenuComponent } from "./components/menu/menuComponent";
import { FooterComponent } from "./components/footer/footerComponent";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumbComponent";

//org only components
import { OrganizationBriefComponent } from "./pages/organization.brief";
import { PreacuteLeakageComponent } from "./reports/preacuteleakage.organization/preacuteleakageComponent";
import { PostacuteLeakageComponent } from "./reports/postacuteleakage.organization/postacuteleakageComponent";
import { MarketSharebyZipComponent } from "./reports/marketsharebyzip.organization/marketsharebyzipComponent";
import { MarketMapComponent } from "./reports/marketmap.organization/marketmapComponent";
import { QuadrantAnalysisComponent } from "./reports/quadrantanalysis.organization/quadrantAnalysisComponent";

//provider (phys and org) components
import { HomeComponent } from './reports/home.provider/homeComponent';
import { SharedVisitsComponent } from "./reports/sharedvisits.provider/sharedvisitsComponent";
import { SpecialtyComponent } from "./reports/specialty/specialtyComponent";
import { OrganizationComponent } from "./reports/organization/organizationComponent";
import { ClinicalComponent } from "./reports/clinical.physician/clinicalComponent";
import { PerformanceProvider } from "./reports/performance.provider/performance.provider";
import { SummaryComponent } from "./reports/summary.provider/summaryComponent";
import { PartdutilizationComponent } from "./reports/partdutilization.physician/partdutilizationComponent";
import { ServiceLineSummaryComponent } from "./reports/servicelinesummary.provider/serviceLineSummaryComponent";
import { LeakageProvider } from "./reports/leakage.provider/leakage.provider";
import { CarepathwaysComponent } from "./reports/carepathways.provider/carepathwaysComponent";
import { ProviderSpecialtyFlowComponent } from "./reports/specialtyflow.provider/specialtyFlowComponent";
import { GeoflowComponent } from "./reports/geoflow.provider/geoflowComponent";

//dashboards
import { YearOverYearDashboardComponent } from "./reports/yearoveryeardashboard.physician/yearoveryeardashboardComponent";
import { DashboardsectionComponent } from "./reports/dashboardssection/dashboardsectionComponent";
import { DashboardComponent } from "./reports/dashboards/dashboardComponent";
import { DashboardsEmbedWrapper } from "./reports/dashboards/dashboard.embed";

//physician only components
import { PhysicianGeneralflowComponent } from "./reports/generalflow.physician/generalflowComponent";
import { PhysicianBriefComponent } from "./pages/physician.brief";
import { GeographicbenchmarkComponent } from "./reports/geographicbenchmark.physician/geographicbenchmarkComponent";
import { MarketbenchmarkComponent } from "./reports/marketbenchmark.physician/marketbenchmarkComponent";
import { PhysicianTrendingComponent } from "./reports/trending.physician/physicianTrendingComponent";

//all entity components
import { SharedVisitsCommunityComponent } from "./reports/sharedvisits.community/sharedvisitsComponent";
import { ClinicalCommunityComponent } from "./reports/clinical.community/clinicalComponent";
import { ServicelineBenchmarkComponent } from "./reports/servicelinebenchmark.community/servcielinebenchmarkComponent";
import { CarepathwaysComponent as CommunityCarepathwaysComponent } from "./reports/carepathways.community/carepathwaysComponent";
import { SpecialtyFlowComponent as CommunitySpecialtyFlowComponent } from "./reports/specialtyflow.community/specialtyflowComponent";
import { CommunityGeneralflowComponent } from "./reports/generalflow.community/generalflowComponent";
import { FlowComponent } from "./reports/flow/flowComponent";
import { TrendingGraphComponent } from "./reports/trendinggraph/trendingGraphReportComponent"

//comm home and com lists
import { GroupsComponent } from "./reports/groups/groupsComponent";
import { CommunityHomeComponent } from "./reports/home.community/homeComponent";

//one-off top levelcomponents
import { LandingComponent } from "./components/landing/landingComponent";
import { SearchComponent } from "./reports/search/searchComponent";
import { GeoForecastingComponent } from "./reports/geo.forecasting/geographicForecastingComponent";
import { UserProfileComponent } from "./components/userProfile/userProfileComponent";
import { FaqComponent } from "./components/faq/faqComponent";

import eventBus from "./services/eventBus";
// import user from "./services/user";

//may access services within routes
//all routes existe in the router wrapper component 
function RouterWrapper(props) {

    const api = useApi();
    const config = useConfig();
    const recentlySelected = useRecentlySelected();
    const pic = usePic();
    const affiliations = useAffiations();
    const auth = useAuth();
    const codes = useCodes();
    const forecasting = useForecasting();
    const template = useTemplate();
    const user = useUser();
    const communityList = useCommunityList();
    const notify = useNotify();
    const logging = useLogging();

    //config.ready set to true when main useeffect hook in services provider finishes all async calls
    if (config.ready) {
        const router = createBrowserRouter(
            createRoutesFromElements(
                <>
                    {/* root route handles sidebar, footer and main area. landing component is higher order component */}
                    <Route
                        path="/"
                        element={<>
                            <div className={`content-container content-menu ${template.sideBarClosed ? 'content-no-menu' : ''}`}>
                                <div className="content">
                                    <LandingComponent>
                                        <Outlet />
                                    </LandingComponent>
                                </div>
                            </div>
                            <MenuComponent
                                recentlySelected={recentlySelected}
                                config={config}
                                api={api}
                                template={template}
                                auth={auth}
                                notify={notify}
                            />
                            <FooterComponent />
                            <ToastContainer />
                        </>}
                    >
                        {/* handle all /organization routes and use loader to preload org provider */}
                        <Route
                            path="organization/:npis"
                            id="organization.npis"
                            loader={async ({params}) => {
                                let npisArray = params.npis.split(',');
                                let providerVal;
                                if (npisArray.length == 1) {
                                    await api.GetProvider(params.npis)
                                    .then(value => {
                                        providerVal = value;
                                        recentlySelected.store(value);
                                    })
                                    return params.npis && providerVal ? providerVal : null;
                                }
                                else if (npisArray.length > 1) {
                                    return npisArray;
                                }
                                else {
                                    return null;
                                }
                            }}
                            element={<>
                                <BreadcrumbComponent
                                    api={api}
                                    recentlySelected={recentlySelected}
                                />
                                <Outlet/>
                            </>}
                        >
                            <Route
                                path="home"
                                element={<HomeComponent
                                    api={api}
                                    pic={pic}
                                    CommunityList={communityList}
                                    codes={props.codes}
                                    notify={notify}
                                    download={download}
                                    affiliations={affiliations}
                                />}
                            />
                            <Route
                                path="brief"
                                element={<OrganizationBriefComponent
                                    api={api}
                                    pic={pic}
                                    notify={notify}
                                    download={download}
                                />}
                            />
                            {/* begin org reports */}
                            <Route
                                path="SharedVisitsReport"
                                element={<SharedVisitsComponent
                                    api={api}
                                    pic={pic}
                                    notify={notify}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="bySpecialty"
                                element={<SpecialtyComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    pic={pic}
                                />}
                            />
                            <Route
                                path="byOrganization"
                                element={<OrganizationComponent
                                    api={api}
                                    notify={notify}
                                    pic={pic}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="PreAcuteLeakage"
                                loader={async ({params}) => {
                                    return redirect(`/organization/${params.npis}/PreAcuteShare`);
                                }}
                            />
                            <Route
                                path="PreAcuteShare"
                                element={<PreacuteLeakageComponent
                                    api={api}
                                    notify={notify}
                                    pic={pic}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="PostAcuteLeakage"
                                loader={async ({params}) => {
                                    return redirect(`/organization/${params.npis}/PostAcuteShare`);
                                }}
                            />
                            <Route
                                path="PostAcuteShare"
                                element={<PostacuteLeakageComponent
                                    api={api}
                                    notify={notify}
                                    pic={pic}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="Services"
                                element={<PerformanceProvider
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    pic={pic}
                                    codes={codes}
                                />}
                            />
                            <Route
                                path="PCPSpecialistVolumes"
                                element={<SummaryComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    pic={pic}
                                />}
                            />
                            <Route
                                path="Leakage"
                                loader={async ({params}) => {
                                    return redirect(`/organization/${params.npis}/NetworkIntegrity`);
                                }}
                            />
                            <Route
                                path="NetworkIntegrity"
                                element={<LeakageProvider
                                    api={api}
                                    codes={codes}
                                    notify={notify}
                                    download={download}
                                    pic={pic}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="ServiceLine"
                                element={<ServiceLineSummaryComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="marketsharebyzip"
                                element={<MarketSharebyZipComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            {/* end org reports */}
                            {/* begin org graphs */}
                            <Route
                                path="CareJourney"
                                element={<CarepathwaysComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="SpecialtyGraph"
                                element={<ProviderSpecialtyFlowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    config={config}
                                    template={template}
                                />}
                            />
                            <Route
                                path="MarketMakers"
                                element={<MarketMapComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="ShareByVolume"
                                element={<QuadrantAnalysisComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="ReferralMap"
                                element={<GeoflowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="flow"
                                element={<FlowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="trendinggraph"
                                element={<TrendingGraphComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                />}
                            />
                            {/* end org graphs */}
                        </Route>
                        {/* end org section */}
                        {/* handle all /physician routes and use loader to preload phys provider */}
                        <Route
                            path="physician/:npis"
                            id="physician.npis"
                            loader={async ({params}) => {
                                let npisArray = params.npis.split(',');
                                let providerVal;
                                if (npisArray.length == 1) {
                                    await api.GetProvider(params.npis)
                                    .then(value => {
                                        providerVal = value;
                                        recentlySelected.store(value);
                                    })
                                    return params.npis && providerVal ? providerVal : null;
                                }
                                else if (npisArray.length > 1) {
                                    return npisArray;
                                }
                                else {
                                    return null;
                                }
                            }}
                            element={<>
                                <BreadcrumbComponent
                                    api={api}
                                    recentlySelected={recentlySelected}
                                />
                                <Outlet/>
                            </>}

                        >
                            <Route
                                path="home"
                                loader={async ({params}) => {
                                    let npisArray = params.npis.split(',');
                                    if (npisArray.length > 1) {
                                            return redirect('/' + params.npis + '/ambiguous');
                                    }
                                    else {
                                        return null;
                                    }
                                }}
                                element={<HomeComponent
                                    api={api}
                                    pic={pic}
                                    CommunityList={communityList}
                                    codes={props.codes}
                                    notify={notify}
                                    download={download}
                                    affiliations={affiliations}
                                />}
                            />
                            <Route
                                path="brief"
                                element={<PhysicianBriefComponent
                                    api={api}
                                    pic={pic}
                                    notify={notify}
                                />}
                            />
                            {/* begin phys reports */}
                            <Route
                                path="SharedVisitsReport"
                                element={<SharedVisitsComponent
                                    api={api}
                                    pic={pic}
                                    notify={notify}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="bySpecialty"
                                element={<SpecialtyComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    pic={pic}
                                />}
                            />
                            <Route
                                path="byOrganization"
                                element={<OrganizationComponent
                                    api={api}
                                    notify={notify}
                                    pic={pic}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="ClinicalReport"
                                element={<ClinicalComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    codes={codes}
                                    pic={pic}
                                />}
                            />
                            <Route
                                path="Services"
                                element={<PerformanceProvider
                                    api={api}
                                    pic={pic}
                                    codes={codes}
                                    notify={notify}
                                    download={download}
                                />}
                            />
                            <Route
                                path="summary"
                                element={<SummaryComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="Prescriptions"
                                element={<PartdutilizationComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                />}
                            />
                            <Route
                                path="ServiceLine"
                                element={<ServiceLineSummaryComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            {/* end phys reports */}
                            {/* begin phys graphs */}
                            <Route
                                path="CareJourney"
                                element={<CarepathwaysComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="GeneralFlow"
                                element={<PhysicianGeneralflowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="SpecialtyGraph"
                                element={<ProviderSpecialtyFlowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="ReferralMap"
                                element={<GeoflowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="Flow"
                                element={<FlowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="clinicaldashboard"
                                element={<PhysicianTrendingComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    config={config}
                                    auth={auth}
                                    codes={codes}
                                />}
                            />
                            <Route
                                path="TrendingGraph"
                                element={<TrendingGraphComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                />}
                            />
                            {/* end phys graphs */}
                            {/* begin phys dashboards */}
                            <Route
                                path="yoy"
                                element={<YearOverYearDashboardComponent
                                    
                                />}
                            />
                            <Route
                                path="dashboards"
                                element={<DashboardsectionComponent
                                    elementName='physician'
                                    name='Physician Volume Benchmarks'
                                    dashboards={[
                                        {
                                        name: 'Volume of Procedures by Geography',
                                        paramkey: 'NPI',
                                        description: "Physician benchmarks compare a provider to their peers on both a local, regional, and national scale. Navigate the above tabs to analyze the physician's volumes and relative performance in each of the four code sets listed above"
                                        },
                                        {
                                        name: 'CBSA (Core Based Statistical Area) Market Volumes',
                                        paramkey: 'npis',
                                        description: "Compare a provider to other providers of the same taxonomy in the same CBSA by clinical volume data"
                                        }
                                    ]}
                                />}
                            />
                            <Route
                                path="geobenchmark"
                                element={<GeographicbenchmarkComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                    codes={codes}
                                    
                                />}
                            />
                            <Route
                                path="marketbenchmarks"
                                element={<MarketbenchmarkComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                    codes={codes}
                                    
                                />}
                            />
                            {/* end phys dashboards */}
                        </Route>
                        <Route
                            path="groups"
                            id="groups"
                            loader={async () => {
                                return props;
                            }}
                            element={<GroupsComponent
                                download={download}
                                notify={notify}
                            />}
                        />
                        {/* community section that handles preloading community */}
                        <Route
                            path="community/:cid"
                            id="community.cid"
                            loader={async ({params}) => {
                                if (params.cid) {
                                    let community = Promise.resolve(api.GetCommunity(params.cid));
                                    community.then(value => {
                                        recentlySelected.store(value); 
                                    })
                                }
                                return params.cid ? api.GetCommunity(params.cid) : null;
                            }}
                            element={<>
                                <BreadcrumbComponent
                                    api={api}
                                    recentlySelected={recentlySelected}
                                />
                                <Outlet/>
                            </>}
                        >
                            <Route
                                path="home"
                                loader={async ({params}) => {
                                    const comm = await api.GetCommunity(params.cid);
                                    const members = await Promise.all(comm.npis.map(npi => api.GetProvider(npi)));
                                    return members;
                                }}
                                element={<CommunityHomeComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    CommunityList={communityList}
                                />}
                            />
                            {/* begin comm reports */}
                            <Route
                                path="SharedVisitsReport"
                                element={<SharedVisitsCommunityComponent
                                    api={api}
                                    pic={pic}
                                    notify={notify}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="bySpecialty"
                                element={<SpecialtyComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    pic={pic}
                                />}
                            />
                            <Route
                                path="byOrganization"
                                element={<OrganizationComponent
                                    api={api}
                                    notify={notify}
                                    pic={pic}
                                    download={download}
                                    CommunityList={communityList}
                                />}
                            />
                            <Route
                                path="ClinicalReport"
                                element={<ClinicalCommunityComponent
                                    api={api}
                                    notify={notify}
                                    download={download}
                                    codes={codes}
                                    pic={pic}
                                    CommunityList={communityList}
                                    config={config}
                                />}
                            />
                            {/* end comm reports */}
                            <Route
                                path="marketbenchmarks"
                                element={<ServicelineBenchmarkComponent
                                    api={api}
                                    pic={pic}
                                    config={config}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                />}
                            />
                            {/* begin comm graphs */}
                            <Route
                                path="CareJourney"
                                element={<CommunityCarepathwaysComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="SpecialtyGraph"
                                element={<CommunitySpecialtyFlowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="Flow"
                                element={<FlowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="GeneralFlow"
                                element={<CommunityGeneralflowComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                    template={template}
                                />}
                            />
                            <Route
                                path="MarketMakers"
                                element={<MarketMapComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            <Route
                                path="trendinggraph"
                                element={<TrendingGraphComponent
                                    api={api}
                                    pic={pic}
                                    download={download}
                                    notify={notify}
                                />}
                            />
                            {/* end comm graphs */}
                            {/* comm dashboards */}
                            <Route
                                path="dashboards"
                                element={<DashboardsectionComponent
                                    elementName='community'
                                    name='Community Volume Benchmarks'
                                    dashboards={[
                                        {
                                            name: 'Volume of Procedures by Physician Community',
                                            paramkey: 'cid',
                                            description: "Compare community procedures by servicelines and code"
                                        }
                                    ]}
                                />}
                            />
                        </Route>
                        {/* begin non provider dashboards */}
                        <Route
                            path="dashboards/:id"
                            id="dashboards.id"
                            loader={async ({params}) => {
                                return params.id ? params.id : null;
                            }}
                            element={<DashboardsEmbedWrapper/>}
                        ></Route>
                        <Route
                            path="dashboards"
                            element={<DashboardComponent
                                elementName=''
                                name='Dashboards'
                            />}
                        />
                        {/* end non provider dashboards */}
                        <Route
                            path="search"
                            element={<SearchComponent
                                config={config}
                                api={api}
                                download={download}
                                CommunityList={communityList}
                            />}
                        />
                        <Route
                            path="profile"
                            element={<UserProfileComponent
                                config={config}
                                api={api}
                                download={download}
                                auth={auth}
                                user={user}
                                CommunityList={communityList}
                            />}
                        />
                        <Route
                            path="faq"
                            element={<FaqComponent
                                config={config}
                                api={api}
                                download={download}
                                auth={auth}
                                user={user}
                                CommunityList={communityList}
                            />}
                        />
                        <Route
                            path="forecasting/geo"
                            element={<GeoForecastingComponent
                                codes={codes}
                                forecasting={forecasting}
                                config={config}
                                notify={notify}
                            />}
                        />
                    </Route>
                </>
            )
        );
        return <RouterProvider router={router} />;
    } else {
        return null;
    }
        
}

//to make access to all the services providers in the services provider they have to wrap all the routes and components
export function App(props) {
    return <ServicesProvider>
        <RouterWrapper />
    </ServicesProvider>;
}
