import React, {useState, useEffect} from 'react';
import { useRouteLoaderData, Link } from "react-router-dom";
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import parseQuicksightName from '../../js/parseQuicksightName';
import localStorageModule from '../../services/localStorageModule';
import quicksight from '../../services/quicksightModule';
import eventBus from "../../services/eventBus";
import { useLogging } from '../../services/servicesContext';

export function DashboardsEmbedWrapper({$state, $timeout, params, report}){
    const [dashboardName, setdashboard] = useState(null);
    const [loading, setLoading] = useState(false);
    const dashboardID = useRouteLoaderData('dashboards.id');
    const logging = useLogging();
 
    let dashboard;

    useEffect(() => {
        logging.routeLoad({
          pathname: location.pathname,
          npis: [],
          statename: 'root.app.dbs.embed'
        });
        onInit();
        eventBus.dispatch("hideTTM");
        return () => {
          //Cleanup hide TTM
          eventBus.dispatch("showTTM");
      };
    }, [dashboardID]);

    const handleParametersChange = (changedParams) => {
        localStorageModule.setObj(`team.dashboards.${dashboardName}`, changedParams);
    }
    
    const initEmbedDashboard = (urlStr, params) => {
      var options = {
        url: urlStr,// url generated via embedding API
        container: "#embeddingContainer",
        scrolling: "auto",
        footerPaddingEnabled: true,
    };
      
    if (params) {   //parameter passed to a named dashboard from app, don't use localstorage params
        options.parameters = params; 
      } else {  //loading from one of the list of dashboards, use localstorage
        options.parametersChangeCallback = handleParametersChange;
        
        let paramsObj = localStorageModule.getObj(`team.dashboards.${dashboardName}`);
        if (paramsObj) {
          let params = paramsObj.parameters;
          options.parameters = params.reduce((acc, param) => {
            acc[param.name] = param.value;
            return acc;
          },{});
        }
    }
      
      dashboard = embedDashboard(options);
    }
    
    const onInit = async function() {
      const quickSight = quicksight();
      setLoading(true);
      if (report && params) {
        quickSight.getDashboardList({ search: report })
          .then(lookup => {
              if (Object.keys(lookup).length === 1) {
                  return quickSight.getDashboardLocation(Object.keys(lookup)[0]);
              } else {
                  console.error('lookup should only have one key', lookup);
                  return
              }}).then(location => {
                    if (location === null || !location) {
                      console.error('location not found!')
                    } else {
                      dashboard = quickSight.initializeDashboardEmbed(location, { params: params });
                    }
                    setLoading(false);
              })
        } else if (dashboardID){
        quickSight.getDashboardList()
        .then(lookup => {
            const dashboardName = lookup[dashboardID].name;
            setdashboard(dashboardName)
            quickSight.getDashboardLocation(dashboardID)
                         .then(location => {

            dashboard = quickSight.initializeDashboardEmbed(location, { dashboardName: dashboardName });
            setLoading(false);
           })

        
        });
      } 
    };

    return(<>
      <div className="loading-lg col-md-12" style={{ display : (loading) ? 'inline' : 'none'}}></div>
      <div className="dashboard-container" style={{ height: '100%' }}>
          <div className="dashboard-report-container" >
             <div className="dashboard-graph-full" id="embeddingContainer" style={{ height: '750px' }} ></div>
          </div>
      </div> 
    </>)
}