import React, {useState, useEffect, useRef} from "react";
import { useRouteLoaderData } from "react-router-dom";
import * as d3 from 'd3';
import merge from 'lodash/merge';

import { useApi, useConfig, usePic, useTemplate, useTourManager, useLogging } from '../../services/servicesContext';

import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';

export function ProviderSpecialtyFlowComponent(props) {
    
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const npisRef = useRef(npis);

    const api = useApi();
    const config = useConfig();
    const pic = usePic();
    const template = useTemplate();
    const tourManager = useTourManager();
    const logging = useLogging();

    const [ loading, setLoading ] = useState(true);
    const [ axis, setAxis ] = useState(null);
    const [ pcpTaxonomyCodes, setPcpTaxonomyCodes ] = useState(null);
    const [ nodesBins, setNodesBins ] = useState(null);
    const [ relations, setRelations ] = useState(null);
    const [ filterControls, setFilterControls ] = useState(null);
    const [ openDropdown, setOpenDropdown ] = useState(null);

    const [ options, setOptions ] = useState(null);

    const allStringVal = '-- All Filters Below --';

    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        same: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };

    function build(index, nodes, prev){
            
        if (index > filterControls.length - 1) {
            console.log('invalid index', index);
            return;
        }
        
        if (!nodes) {
            nodes = getActiveFromColumn(index);
        }
        
        var list = getActiveFromColumn(index)
            .map(function(node){
                switch(index) {
                    case 0:
                        node.side = 'left';
                        break;
                    case 1:
                        node.side = 'middle';
                        break;
                    case 2:
                        node.side = 'right';
                        break;
                    default:
                        node.side = 'extended';
                        break;
                }
                return node;
            })
            .filter(function(node){
                return nodes.indexOf(node) > -1;
            })
            .sort(function(a,b){
                return b[options.node.sortAxis] - a[options.node.sortAxis];
            })
            .slice(0, options.limit);
            
        var relations = list.reduce(function(acc, node){
            acc.push.apply(acc, node.outBound);
            return acc;
        },[]);
        
        if (relations.length > 0) {
            var outBound = build(index + 1, relations.reduce(function(acc, relation){
                acc.push(relation.target);
                return acc;
            }, []), list);
            var inBound = outBound
                .reduce(function(acc, relation){
                    if (acc.indexOf(relation.source) === -1) {
                        acc.push(relation.source);
                    }
                    return acc;
                },[])
                .filter(function(node){
                    return list.indexOf(node) > -1;
                })
                .reduce(function(acc, node){
                    acc.push.apply(acc, node.inBound);
                    return acc;
                },[])
                .filter(function(relation){
                    return !prev || prev.indexOf(relation.source) > -1;
                });
                
            return inBound.concat(outBound);
            
        } else {
            return list.reduce(function(acc, node){
                acc.push.apply(acc, node.inBound);
                return acc;
            },[])
            .filter(function(relation){
                return !prev || prev.indexOf(relation.source) > -1;
            });
        }
        
    }

    function getActiveFromColumn(columnIndex){
            
        var nodes = [];
        
        if (filterControls[columnIndex][allStringVal].active === true) {// if 'All' active we take its nodes, sort them, and return the top limit (default 10 or less)
            
            return filterControls[columnIndex][allStringVal].nodes;
            
        } else {// if 'All' is not active find the nodes present in each filter
        
            for (var specClass in filterControls[columnIndex]) {// each filter in the bin object
                if (filterControls[columnIndex][specClass].name !== allStringVal && filterControls[columnIndex][specClass].active === true) {// if not the 'all' filter and active add its nodes to the current column
                    nodes = nodes.concat(filterControls[columnIndex][specClass].nodes);
                }
            }
            
            return nodes;
        }
        
    }

    function buildAndSendRelations(rawNodesBinsArgument) {
        
        var list;
        if (
            nodesBins?.[0]?.length === 0 ||
            nodesBins?.[1]?.length === 0 ||
            nodesBins?.[2]?.length === 0
        ) {
             props.notify.alert({
                 title: 'DATA',
                 text: 'Insuficient provider data to render chart.',
                 delay: 30000
             });
        } else {
            list = build(0);
        }
        
        setRelations(list);
        
    }
    
    const stateColumn = function(number){
        var ret;
        switch (number) {
            case 0:
                ret = 'Left';
                break;
            case 1:
                ret = 'Middle';
                break;
            case 2:
                ret = 'Right';
                break;
            default:
                ret = 'Additional Targets';
                break;
        }
        return ret;
    };
    
    const setFilterColumnClass = function(index, isButton){
        
        if (isButton) {
            if (index === undefined) {// the default filter reset button
                if (npis.entitytype == 2) {// an org
                    return false;
                } else if (pcpTaxonomyCodes.indexOf(npis.taxonomy.code) > -1) {// a PCP
                    return false;
                } else {// a Specialist
                    return false;
                }
                
            } else if (index === 0) {// left nodes
                if (npis.entitytype == 2) {// an Org
                    return false;
                } else if (pcpTaxonomyCodes.indexOf(npis.taxonomy.code) === -1) {// a Specialist
                    return false;
                } else {// a PCP
                    return true;
                }
            } else if (index === 1) {// middle nodes
                if (npis.entitytype == 2) {// an Org
                    return false;
                } else if (pcpTaxonomyCodes.indexOf(npis.taxonomy.code) > -1) {// a PCP 
                    return false;
                } else {// a Specialist
                    return true;
                }
            } else {// right nodes column and all other columns
                return true;
            }
        } else {
            if (index === 2) {// hide right (GAC) nodes column
                return 'hidden';
            } else {
                return 'col-md-6';
            }
        }
        
    };

    const handleDropdownClick = function(col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
        
    };
    
    const numberOfFilters = function(columnHash){//returns number of active filters
        return Object.keys(columnHash).filter(function(key){
            return columnHash[key].active;
        }).length;
    };
    
    function areNonAllControlsInactive(controlObj){// returns true if there aren't any active filters other than All
        return Object.keys(controlObj).filter(function(key) {
            return key !== allStringVal && controlObj[key].active === true;
        }).length === 0;
    }
    
    const toggleFilters = function(classSpec, controlIndex){

        let filterControlsClone = [...filterControls];

        if (filterControlsClone[controlIndex][classSpec].active) {
            filterControlsClone[controlIndex][classSpec].active = false;
        } else {
            filterControlsClone[controlIndex][classSpec].active = true;
        }

        if (classSpec === allStringVal && filterControlsClone[controlIndex][allStringVal].active === true) {
            for (var item in filterControlsClone[controlIndex]) {
                if (item !== allStringVal && filterControlsClone[controlIndex][item].active === true) {
                    filterControlsClone[controlIndex][item].active = false;
                }
            }
        } else if (filterControlsClone[controlIndex][allStringVal].active === true && filterControlsClone[controlIndex][classSpec].active === true) {
            filterControlsClone[controlIndex][allStringVal].active = false;
        } else if (filterControlsClone[controlIndex][allStringVal].active === false && areNonAllControlsInactive(filterControlsClone[controlIndex])) {
            filterControlsClone[controlIndex][allStringVal].active = true;
        }
        setFilterControls(filterControlsClone);
        buildAndSendRelations();
    };
    
    const toggleAxis = function(property) {
        
        let optionsClone = {...options};
        optionsClone.link = {
            value: function(d) {
                return d.values[property];
            }
        };
        
        merge(optionsClone.toolTip, {
            units: mapping[property].units,
            value: function(d) {
                return d.values[property];
            } 
        });

        optionsClone.node.sortAxis = mapping[property].sortAxis;
        
        setAxis(property);
        setOptions(optionsClone);
        buildAndSendRelations();
        setOpenDropdown(null);
    };
    
    const resetFilters = function() {
        let filterControlsClone = [...filterControls];

        filterControlsClone.forEach(function(column){
            for (var columnItemKey in column) {
                if (column[columnItemKey].active === undefined) {// handle hashkeys
                    return;
                } else {
                    if (columnItemKey === allStringVal) {
                        column[columnItemKey].active = true;
                    } else {
                        column[columnItemKey].active = false;
                    }
                }
            }
        });

        setFilterControls(filterControlsClone);                
        toggleAxis('shared');

    };
    
    const getBgColor = function(specClass){
        if (specClass !== allStringVal) {
            return options.color.value(specClass);
        } else {
            return 'none';
        }
        
    };
    
    const showFilterColumn = function(index){
        if (index === 0) {// left nodes
            if (npis.entitytype == 2) {// an Org
                return true;
            } else if (pcpTaxonomyCodes.indexOf(npis.taxonomy.code) === -1) {// a Specialist
                return true;
            } else {// a PCP
                return false;
            }
        } else if (index === 1) {// middle nodes
            if (npis.entitytype == 2) {// an Org
                return true;
            } else if (pcpTaxonomyCodes.indexOf(npis.taxonomy.code) > -1) {// a PCP 
                return true;
            } else {// a Specialist
                return false;
            }
        } else {// right nodes column and all other columns, TODO: change when we add additional columns
            return false;
        }
    };
    
    const hasName = function(){
        return function(value){
            return value.name !== undefined;
        };
    };

    useEffect(()=>{

        tourManager.createTour([
            {
                id: 1,
                title: 'Specialty Graph - 1/7',
                text: "The purpose of the Organization Specialty Flow graph is to the flow of patients from primary care providers (left nodes), to specialists (middle nodes), to a selected Organization type (right nodes)."
            },
            {
                id: 2,
                title: 'Specialty Graph - 2/7',
                attachTo: { element: 'g.nodes g.node rect[data-side="left"]', on: 'bottom' },
                text: "Primary Care Providers are displayed in the leftmost node (primary care).",
            },
            {
                id: 3,
                title: 'Specialty Graph - 3/7',
                attachTo: { element: 'g.nodes g.node rect[data-side="middle"]', on: 'left' },
                text: "Specialists are displayed in the middle node (specialists)."
            },
            {
                id: 4,
                title: 'Specialty Graph - 4/7',
                attachTo: { element: '.specialtyflow-column-controls-container button:not([disabled])', on: 'bottom' },
                text: "When you click on the button for the Left, Middle, or Right Nodes..."
            },
            {
                id: 5,
                title: 'Specialty Graph - 5/7',
                attachTo: { element: '.open .dropdown-menu', on: 'left' },
                text: "...you see a drop down that lets you select the types of physicians that populate the middle, or left nodes, respectively.",
                beforeShowPromise: function(){
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('.specialtyflow-column-controls-container button:not([disabled])');
                        elementToClick.click();
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
                
            },
            {
                id: 6,
                title: 'Specialty Graph - 6/7',
                attachTo: { element: 'g.nodes g.node rect[data-side="right"]', on: 'left' },
                text: "The rightmost nodes represent the facilities the specialists send their patients to. The size of the gray areas represents patient volume. The larger they are, the more volume there is.",
                beforeShowPromise: function(){
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('.specialtyflow-column-controls-container button:not([disabled])');
                        elementToClick.click();
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
            },
            {
                id: 7,
                title: 'Specialty Graph - 7/7',
                attachTo: { element: 'g.legend rect', on: 'bottom' },
                text: "There is also a collapsible legend that provides reference for how the nodes are color-coded by Provider type."
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.graphs.sankey.specialtyflow`
        });
        
        async function init() {
            try {
                setLoading(true);
                let allRelations;
                let initPcpTaxonomyCodes;
                initPcpTaxonomyCodes = config.pcpTaxonomyCodes();
                
                const seedProvider = npis;
                var specialtyFlowEndpoint = '/api/npi/'+ seedProvider.npi +'/specialty_flow/';
                const response = await seedProvider.Report('specialtyflow');                
                    
                allRelations = response.relations;// set up array of all relations before any filters and npi resolution
                var responseNpiPromises = Object.keys(response.npis)
                .map(function(key){
                    return api.GetProvider(key);
                });
                
                // let npis = await Promise.all(responseNpiPromises);// resolve npis with with the npi service
                let initNpis = await Promise.all(responseNpiPromises);// resolve npis with with the npi service
                                    
                // npis = npis.map(function(provider){// set empty inbound and outbound relations arrays on resolved npis array
                initNpis = initNpis.map(function(provider){// set empty inbound and outbound relations arrays on resolved npis array
                    var ret = {
                        inBound: [],
                        outBound: [],
                    };
                    ret.__proto__ = provider;
                    return ret;
                });

                // let npisHash = npis.reduce(function(hash, provider){// set up hash table for easy lookup
                let npisHash = initNpis.reduce(function(hash, provider){// set up hash table for easy lookup
                    hash[provider.npi] = provider;
                    return hash;
                },{});
                    
                allRelations = allRelations
                    .map(function(relationDatum){// change the allRelations array replacing with relation instance using the resolved npis
                        var relation = new api.Relation({
                            values: relationDatum.values,
                            source: npisHash[relationDatum.source],
                            target: npisHash[relationDatum.target]
                        });
                        
                        relation.source.outBound.push(relation);// add the current relation to the source npi as an outbound relation
                        relation.target.inBound.push(relation);// add the current relation to the target npi as an inbound relation
                        
                        return relation;
                        
                    })
                    .filter(function(relation){// current fix to remove specialist to specialist relations
                        if (
                            relation.source.entitytype == 1 && relation.target.entitytype == 1
                            && initPcpTaxonomyCodes.indexOf(relation.source.taxonomy.code) === -1
                            && initPcpTaxonomyCodes.indexOf(relation.target.taxonomy.code) === -1
                        ) { 
                            return false;
                        } else {
                            return true;
                        }
                    });
                    
                // npis = npis.filter(function(npi){// remove npis not found in relations (those lost after after specialty -> specialty relations filter)
                initNpis = initNpis.filter(function(npi){// remove npis not found in relations (those lost after after specialty -> specialty relations filter)
                    var timesInRelations = allRelations.filter(function(relation){
                        return (relation.source == npi || relation.target == npi);
                    });
                    if (timesInRelations.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                });
                    
                // const rawNodesBins = npis.reduce(function(acc, npi){// take all npis and put them into one of three bins for use in filter inputs 
                const rawNodesBins = initNpis.reduce(function(acc, npi){// take all npis and put them into one of three bins for use in filter inputs 
                    
                    if (npi.entitytype == 1) {
                        if (initPcpTaxonomyCodes.indexOf(npi.taxonomy.code) > -1) {// if a PCP put in bin [0]
                            acc[0].push(npi);
                            npi.column = 0;
                            return acc;
                        } else {// if a Specialist (e.g. type 1 and not a PCP) put in bin [1]
                            acc[1].push(npi);
                            npi.column = 1;
                            return acc;
                        }
                    } else {// otherwise must be type 2 (an org) and put in bin [2]
                        acc[2].push(npi);
                        npi.column = 2; 
                        return acc;
                    }
                },[[],[],[]]);
                    
                rawNodesBins.forEach(function(bin){// add totalShared property to each node descending and sort each bin on it
                    
                    bin.forEach(function(node){// add totalshared property
                        node.totalShared = 0;
                        node.totalSame = 0;
                        node.totalUnique = 0;
                        [node.inBound, node.outBound].forEach(function(relationsArr){
                            relationsArr.forEach(function(relation){
                                node.totalShared += relation.values.shared;
                                node.totalSame += relation.values.same;
                                node.totalUnique += relation.values.unique;
                            });
                        });
                    });
                    
                    bin.sort(function(a, b){// sort bin descending on totalShared
                        return b.totalShared - a.totalShared;
                    });
                    
                });
                    
                const filterControls = rawNodesBins.map(function(column, columnIndex){// map over the three bins and set hash table in each for use in filtering
                    
                    var columnValueAll = {// each hash table will have an 'All' value that with include all nodes of its type
                        nodes: [],
                        active: true,// all is active on route load
                        name: allStringVal
                    };
                    
                    var hash = column.reduce(function(acc, columnItem){
                        
                        var name = columnItem.taxonomy.toString();
                        
                        if (!acc[name]) {//set filter entry if it doesn't yet exist by name
                            acc[name] = {
                                nodes: [],
                                active: false,// each filter inactive by default since all is active
                                name: name
                            };
                        }
                        columnValueAll.nodes.push(columnItem);//add each node to the all filter
                        acc[name].nodes.push(columnItem);//and also its appropriate group based on name
                        return acc;
                        
                    },{});
                    var sorted_hash = Object.keys(hash)
                            .sort()
                            .reduce(function (acc, key) { 
                                acc[key] = hash[key];
                                return acc;
                            }, {});
                    
                    sorted_hash[allStringVal] = columnValueAll;// add 'All' to each hash
                    return sorted_hash;
                });

                setOptions({
                    controlled: true,
                    colorLegend: true,
                    limit: 10,
                    color: {
                        scale: d3.scaleOrdinal(d3.schemeCategory20),
                        value: function(d) {
                            return this.scale(d);
                        }
                    },
                    node: {
                        colorValue: function(d){
                            return d.taxonomy.toString();
                        },
                        label: function(d){
                            if (d.entitytype == 2) {
                                return d.name.display+' ('+d.npi+')';
                            } else {
                                return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                            }
                        },
                        sortAxis: 'totalShared'
                    },
                    link: {
                        value: function(d) {
                            return d.values.shared;
                        }
                    },
                    toolTip: {
                        units: 'Shared Visits',
                        value: function(d) {
                            return d.values.shared;
                        } 
                    }
                });
                setAxis('shared');
                setPcpTaxonomyCodes(initPcpTaxonomyCodes);
                setFilterControls(filterControls);
                setNodesBins(rawNodesBins);
                setLoading(false);
                const done = await Promise.resolve('done');
                return done;
                //all data ready to process based on active filters and send to sankey parent controller
                    
            } catch(error) {
                setLoading(false);
                console.log('Error fetching Speciality Flow Graph data: ', error);
                props.notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Speciality Flow Graph data.',
                    delay: 30000
                });
            };

        }

        if (config.ready) {
            init();
        }

        return () => {
            tourManager.clearTour();
        };

    },[npis, config.ready]);

    useEffect(()=>{
        if (filterControls && nodesBins) {
            buildAndSendRelations();
        }
    },[filterControls, nodesBins]);
    
    return (<>
        {(relations && options && pic && template && pcpTaxonomyCodes && !loading)
            ? <div className="sankey-report-container">
                <div style={{flex: 1}}>
                    <div className="form-inline col-sm-8 specialtyflow-column-controls-container" style={{padding: 0}}>
                        {filterControls.map((column, idx) => (
                            <div key={idx} className={`${setFilterColumnClass(idx)} ${idx === openDropdown ? ' open ' : ' '}`} style={{padding:0}}>
                                <button type="button" disabled={setFilterColumnClass(idx, true)} className="btn btn-default btn-md btn-block" onClick={() => {handleDropdownClick(idx)} }>
                                    <span>{stateColumn(idx)} Nodes </span>
                                    {!setFilterColumnClass(idx, true) ? <span>({numberOfFilters(filterControls[idx])} filter{numberOfFilters(filterControls[idx]) > 1 ? 's' : ''} active) </span> : null}
                                    {!setFilterColumnClass(idx, true) ? <span className="caret"></span> : null}
                                </button>
                                <ul className="dropdown-menu" role="menu" aria-labelledby="single-button" style={{maxHeight: '600px',overflow:'auto'}}>
                                    { Object.values(column)
                                        .reduce((acc, item) => {
                                            //ensure 'All' filters is first in list
                                            if (item.name !== allStringVal) {
                                                acc.push(item);
                                            } else {
                                                acc.unshift(item);
                                            }
                                            return acc;
                                        },[])
                                        .map( columnItem => (
                                            <li key={columnItem.name} className="checkbox" style={{display:'block'}}>
                                                <label style={{whiteSpace: 'nowrap',display:'block',padding:'0px 20px'}}>
                                                    <input type="checkbox" checked={columnItem.active} onChange={() => toggleFilters(columnItem.name, idx)} style={{top:'2px'}}></input>
                                                    <div style={{width:'10px', height:'10px', display:'inline-block', marginLeft: '5px', marginRight: '5px', backgroundColor: getBgColor(columnItem.name)}}></div>
                                                    {columnItem.name} 
                                                </label>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="col-sm-4 secondary-button-container" style={{padding:0}}>
                        <div className={`provider-axis-dropdown col-md-6 axis-toggle ${ openDropdown === 'axis' ? 'open' : ''}`} style={{padding:0}}>
                            <button id="values-axis" type="button" onClick={() => {handleDropdownClick('axis')} } className="btn btn-default btn-md btn-block">{ mapping[axis].units } <span className="caret"></span></button>
                            <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis" style={{ width: '100%' }}>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => {toggleAxis('shared')}}></input>
                                        { mapping.shared.units }
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'same'} value="same" onChange={() => {toggleAxis('same')}}></input>
                                        { mapping.same.units }
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => {toggleAxis('unique')}}></input>
                                        { mapping.unique.units }
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 reset" style={{padding:0}}>
                            <button type="button" onClick={()=> {resetFilters()}} className="btn btn-default btn-md btn-block">Reset</button>
                        </div>
                    </div>
                </div>
                <div style={{flex:19}}>
                    <PhSankeyComponent
                        data={relations}
                        opts={options}
                        pic={pic}
                        template={template}
                    />
                </div>
                
            </div>
            : <div className="loading-lg"></div>
        }
    </>);
}