
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import parseQuicksightName from '../js/parseQuicksightName';
import Auth from '@aws-amplify/auth';
import localStorage from './localStorageModule';

const quicksight = () => {    
        let cachedDashboardLookup;
        const getHeader = async () => {
          return await Auth.currentSession()
                .then(res => {
                    var idToken = res.getIdToken().getJwtToken();
                    const header = {
                        headers : {
                            Authorization: `Bearer ${idToken}`
                        }
                    }
                    return header;
                }).catch(err => {
                    console.log('Auth.currentSession() error', err);
                });
        }
        
        const requestDashboardLookup = () => {
          return getHeader()
            .then(header => {
                return fetch('/api/quicksight/dashboard', header);
            })
            .then(res => res.json())
            .then(res => {
                const result = res.dashboards.reduce((agg, dashboard) => {
                  const dashboardObj = parseQuicksightName(dashboard.name);
                    agg[dashboard.id]=dashboardObj;
                  
                  return agg;
                }, {});
                return result;
              }).catch(err => {
                console.error('error response requesting dashboards list from TEAM api', err);
              })
        } 
        
        const filterDashboards = (dashBoards, opts) => {
          return Object.keys(dashBoards).reduce((acc, key) => {
            let insert = true;
            if (opts.search && dashBoards[key].search !== opts.search) {
              insert = false;
            }
            if (opts.hideHidden === true && dashBoards[key].hidden === true) {
              insert = false;
            }
            if (insert) {
              acc[key] = dashBoards[key];
            }
            return acc;
          },{});
        }
        
        return {
            getDashboardList: function (opts) {   //slow if not cached, returned cached result if set, otherwise request then cache and return result
       
                return requestDashboardLookup()
                .then(lookup => {
                  if (opts) {
                    return filterDashboards(lookup, opts);
                  } else {
                    return lookup;
                  }
                });
            },
            getDashboardLocation: function(dashboardId) {
              return getHeader()
              .then(header => {
                return fetch('/api/quicksight/dashboard/' + dashboardId, header)
              })
              .then(res => res.json())
              .then((res) => {
                     return res && res.location || null;
                  })
                  .catch(err => {
                       console.error('errior retrieving dashboard location', err);
                 });
            },
            filterDashboardsList: filterDashboards,
            initializeDashboardEmbed: function(urlStr, config) {
                var options = {
                  url: urlStr, // url generated via embedding API
                  container: "#embeddingContainer",
                  scrolling: "auto",
                  footerPaddingEnabled: true,
                };
                
                if (config && config.params) { //parameter passed to a named dashboard from app, don't use localstorage params
                  options.parameters = config.params; 
                } else if (config && config.dashboardName) { //loading from one of the list of dashboards, use localstorage
                  options.parametersChangeCallback = function (changedParams) {
                    localStorage.setObj(`team.dashboards.${config.dashboardName}`, changedParams);
                  };
                  
                  let paramsObj = localStorage.getObj(`team.dashboards.${config.dashboardName}`);
                  if (paramsObj) {
                    let params = paramsObj.parameters;
                    options.parameters = params.reduce((acc, param) => {
                      acc[param.name] = param.value;
                      return acc;
                    },{});
                  }
                }
                
                return embedDashboard(options);
            }
        };
    };

    export default quicksight;