import { phGroupedBarGraph } from '../../js/groupedBarGraph';

export default 'phGroupedBarGraph';

angular.module('phGroupedBarGraph', [])
.directive('phGroupedBarGraph', ['$timeout', '$parse', 'template', function($timeout, $parse, template){
    return {
        scope: {
            data: '=',
            opts: '='
        },
        restrict:'EA',
        link: function(scope, elem, attrs){
            
            var resizeTimer;
            
            var elemDims = elem[0].getBoundingClientRect();
            
            var instance = phGroupedBarGraph();
            
            instance(elem[0], {
                data: scope.data?.slice() || [],
                opts: scope.opts,
                width: elemDims.width,
                height: elemDims.height,
            });
            
            function updateDimensions(){
                elemDims = elem[0].getBoundingClientRect();
            }
            
            scope.$watch('data', function(newData, oldData) {
                if (newData != oldData) {
                    instance.servicelines(scope.data, scope.opts);
                    
                }
            });
            
            var resizeDisposal = template.contentResize(function(){
                if (scope.data) {
                    $timeout.cancel(resizeTimer);
                    resizeTimer = $timeout(function(){
                        updateDimensions();
                        instance.dimensions({width: elemDims.width, height: elemDims.height});
                    },250);
                }
            });
            
            scope.$on('$destroy', function() {
                resizeDisposal();
                var groupedBarGraphDestroyResult = instance.destroy();
            });
        }
    };
}]);