import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./modalCommunity.scss";
import { useApi, useCommunityList, usePic } from "../../services/servicesContext";
import { PopoverDetails } from "../popoverDetails/popoverDetails";
import Select from "react-select";
import notify from "../../services/notify";
import { v4 as uuid_v4 } from "uuid"

export function CommunityAddBulkModal(props) {
    const CommunityList = useCommunityList();

    const {show, onClose, updateMembers, api, community, pic} = props
    const [npiList, setNpiList] = useState(null);

    useEffect(() => {

        if (show) {
            
        }
    }, [show]);

    function addBulk () {
        if (npiList.length > 0) {
            let editableCommunity = community;

            let newNpis = npiList.split(/[ ,]+/);

            editableCommunity.npis = editableCommunity.npis.concat(newNpis);

            editableCommunity.Update({editableCommunity}, {api: api, pic: pic})
            .then(function(updatedCommunity) {
                updateMembers();
                onClose();
            })

        }
    }

    function handleNPIListChange (e) {
        let val = e.target.value;

        setNpiList(val);

    }

    return (
        <div className="community-modal">
         <Modal show={show} onHide={onClose}  dialogClassName="modal-lg-community">
            <Modal.Header closeButton>
                <h3 className="modal-title" id="modal-title-search">Add Bulk Community Members</h3>
            </Modal.Header>
            <Modal.Body>
                <div>
                <label htmlFor="name">NPIs</label>
                <textarea rows="15" className="form-control" onChange={(e) => handleNPIListChange(e)} name="newNpis" placeholder="Enter NPIs seperated by a comma or a space." required></textarea>
                <span className="formMsg">This is a required field.</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="modal-footer">
                    <button class="btn btn-primary" type="submit" onClick={() => addBulk()} disabled={npiList ? undefined : true}>Add</button>
                    <button class="btn btn-warning" type="button"  onClick={onClose}>Cancel</button>
                </div>
            </Modal.Footer>
          </Modal>
        </div>
      )
}