import angular from 'angular';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists';

/*
    wrapping depenency in an es module compatible angularjs module for use in project

    uses this suggestion:
    https://github.com/marceljuenemann/angular-drag-and-drop-lists/issues/361#issuecomment-451916687

    this addresses this issue
    https://github.com/marceljuenemann/angular-drag-and-drop-lists/issues/361
*/

export default angular.module('dndModule', ['dndLists']).name;