import React, { useState, useEffect } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { useApi } from '../../services/servicesContext';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';

export function AmbiguousComponent(props) {
    const [ loading, setLoading] = useState(null);
    const [ ambiguousProvidersArr, setAmbiguousProvidersArr ] = useState([]);
    const [ path, setPath ] = useState(null);
    const [ data, setData ] = useState(null);

    const npis = useRouteLoaderData('physician.npis');
    const api = useApi();

    useEffect(() => { 
        async function init() {
            setLoading(true);
            setPath(props.path);

            const providers = await Promise.all(npis.map(function(d){
                let data = api.GetProvider(d)
                return data;
            }));

            setData(providers);
            setAmbiguousProvidersArr(providers);
            setLoading(false);

        }    
        init();
    }, []);

    const reportConfig = function() {
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col1",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "tooltip" : true,
                    'tooltipType': 'details',
                    "export": false,
                    "sortable": false,
                    "template": function(content, rowDataObject) { 
                        if (!rowDataObject) return
                        const formatPhoneNum = rowDataObject.location.phone_number && rowDataObject.location.phone_number.toString();
                        const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                        const provider = { 
                            location : {
                              'postal_code' : rowDataObject.location.postal_code,
                              'phone_number': phone,
                              'city': rowDataObject.location.city,
                              'state': rowDataObject.location.state
                             },
                            'name': {'display' : rowDataObject.name.display }
                          } 
                        return <PopoverDetails provider={provider}><Link className="word-wrap" to={`/${rowDataObject.entitytype == 1 ? 'physician' : 'organization'}/${rowDataObject.npi}/home`}>{rowDataObject.npi}</Link></PopoverDetails>  
                    } 
                },
                "column": {
                    "class": "'text-center'",
                    "content": "link",
                    "style": "",
                    "id": 1                    
                }
            },
            {
                "header": {
                    "id": "col2",
                    "accessor": "provider",
                    "content": "Name",
                   "defaultVisibilty": true,
                    "export": true,
                    "sortable": false,
                    "template": function(content, rowObject) {
                        const provider = {  name : rowObject.name.display, npi : rowObject.npi, entitytype: rowObject.entitytype };
                        return <>
                            <PopoverMenu provider={provider}>
                                <span className='tourscript-name'>{rowObject.name.display}</span>
                                <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </span>
                            </PopoverMenu>
                        </> 
                    },
                "column": {
                        "class": "'text-center'",
                       "content": {},
                        "style": "",
                        "id": 2
               }
            }
        },
        {
            "header": {
                "id": "col3",
                "accessor": "entitytype",
                "content": "Entity Type",
                "headerTooltip": "Row provider entity type",
                "defaultVisibilty": true,
                "export": false,
                "sortable": false,
                "template": content => content && ((content === '2') ? 'Organization' : 'Physician' )
            },
            "column": {
                    "class": "'text-center'",
                    "content": {},
                    "style": "",
                    "id": 3
                }
            },
            {
                "header": {
                    "id": "col4",
                    "accessor": "reporttype",
                    "content": "Report Request",
                    "headerTooltip": "Row report request",
                    "defaultVisibilty": true,
                    "export": false,
                    "sortable": false,
                    "template": function(content, rowObject) {
                        return <Link className="word-wrap" to={`/${rowObject.entitytype == 1 ? 'physician' : 'organization'}/${rowObject.npi}/home`}>SUMMARY</Link>
                    }
                    },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 4
                    }
                },
            ],
        configure : false,
        configureButton: false,
        showSelected: false,
        filterInput: false,
        csvdownload: false,
        pagination: true
        }
    }

    return (
        <>
        <div className="container">
            <div className="message col-sm-6 col-sm-offset-3">
                <h3 className="text-center">Where would you like to go?</h3>
                { ambiguousProvidersArr.length === 1 ?
                    <div>
                        <p>We don't have <span className="report-name uppercase">{path}</span> for this NPI.</p>
                        <p>Please try another report by clicking <i className="fa fa-bars" aria-hidden="true"></i>, or, if you believe there has been an error, <a href="#">let us know</a>.</p>
                    </div>
                : "" }
                { ambiguousProvidersArr.length > 1 ?
                    <div ng-if="ambiguousProvidersArr.length > 1">
                        <p>We don't have the report you have requested for multiple NPIs.</p>
                        <p>If you do not see <span className="report-name uppercase">{path}</span> for an individual NPI, please try another report by clicking <i className="fa fa-bars" aria-hidden="true"></i>, or, if you believe there has been an error, <a href="#">let us know</a>.</p>
                    </div>
                : "" }
            </div>
        </div>
        <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
        { data ? <PhtableComponent data={data} reportconfig={reportConfig} /> : "" }
        </>
  )
}