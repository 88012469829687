import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$http', 'tour', 'notify', 'api', function($scope, $http, tour, notify, api) {
    var $ctrl = this;
        
    $ctrl.$onInit = function() {
        
        tour.setScript(tourConfig);

        var provider = $ctrl.npis[0];
        
        $scope.reportOptions = { 
            export: { 
                name: 'PCP Specialist Volumes Report for ' + provider.name.display + '(' + provider.npi + ')'
            },
            pagination: {
                disable: true
            },
            filterInput: {
                disable: true
            }
        };
        
        getAndFormatData(provider.npi);
    };
        
    function getAndFormatData(npi) {
        
            $http.get('/api/npi/' + npi + '/summary_report/', api.options())
            .then(function(res) {
                $scope.loaded = true;
                $scope.data = res.data.map(function(datum) {
                    return {
                        'totals': datum.Totals,
                        'fagm': datum['Family and General Medicine'] || 0,
                        'im': datum['Internal Medicine (non specialty)'] || 0,
                        'tpc': datum['Total Primary Care'] || 0,
                        'emergency': datum.Emergency || 0,
                        'cardiology': datum.Cardiology || 0,
                        'orthopedics': datum.Orthopedics || 0,
                        'urology': datum.Urology || 0,
                        'pulmonary': datum.Pulmonary || 0,
                        'neurology': datum.Neurology || 0,
                        'ts': datum['Total Specialist'] || 0,
                        'tap': datum['Total All Physicians'] || 0
                    };
                });
            }, function(err){
                console.log('Error fetching Summary report data: ', err);
                $scope.loaded = true;
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Summary report data.',
                    delay: 30000
                });
            });
        }
}];