import angular from 'angular';

import template from './clinical.html';
import controller from './clinical.controller';

import { react2angular } from 'react2angular';
import api from '../../services/api';
import notify from '../../services/notify';

import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';
import codes from '../../services/codes';
import download from '../../services/download';

import { ClinicalCommunityComponent } from './clinicalComponent'

const componentName = 'communityClinical';
const moduleName = 'app.reports.clinical.community';
const updatedComponentName = 'clinicalCommunityComponent';


angular.module(moduleName, [
    phTable, tour, codes
])

/*.component(componentName, {
    bindings: {
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});*/

.component(
    updatedComponentName,
    react2angular(ClinicalCommunityComponent, ['npis', 'opts', 'community'], ['api', 'notify', 'codes', 'download'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};