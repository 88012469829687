import angular from 'angular';

import 'nz-tour/dist/nz-tour.min.js';
import 'nz-tour/dist/nz-tour.min.css';
import uirouter from '@uirouter/angularjs';
import template from './template';

export default angular.module('tour', [
    'nzTour', uirouter, template
])
.provider('tour', ['$transitionsProvider', function($transitionsProvider) {
    
    var config = this.config = {};
    
    var script = {
        config: {
            needMenu: false
        },
        steps: [{
            content: "There is no tour for this page yet."
        }]
    };
    
    $transitionsProvider.onExit({}, function() {
        script = {
            config: {
                needMenu: false
            },
            steps: [{
                content: "There is no tour for this page yet."
            }]
        };
    });
    
    this.$get = ['nzTour', '$transitions', '$interpolate', 'template', '$timeout', function(nzTour, $transitions, $interpolate, template, $timeout) {
        return {
            start: function() {
                
                var finalScript = angular.copy(script);
                finalScript.config = angular.extend({}, config, script.config);
                
                if (finalScript.hooksObj) {
                    
                    if (finalScript.hooksObj.beforeTour) {
                        finalScript.hooksObj.beforeTour();
                    } 
                    
                    if (finalScript.hooksObj.afterTour) {
                        finalScript.config.onClose = finalScript.hooksObj.afterTour;
                        finalScript.config.onComplete = finalScript.hooksObj.afterTour;
                    }
                }
                
                
                var contentArea = document.querySelector('div.content-container');

                function scrollAsNeeded(targetElem) {
                    try {
                        if (contentArea.contains(targetElem)) {
                            if (targetElem.getBoundingClientRect().bottom > contentArea.clientHeight) {//target bottom below viewport
                                targetElem.scrollIntoView();
                            }
                            if (targetElem.getBoundingClientRect().top < 0) {// target top above viewport
                                targetElem.scrollIntoView();
                            }
                        } else {
                            targetElem.scrollIntoView();
                        }
                    } catch (ex) { }
                }
                
                finalScript.steps = finalScript.steps.map((step) => {
                    
                    var hasBefore = step.before ? true : false;
                    var hasTarget = step.target ? true : false;
                    
                    if (hasBefore && hasTarget) {
                        
                        let first = step.before;
                        var target = document.querySelector(step.target);
                        
                        let preScroll = function() {
                            return new Promise((resolve, reject) => {
                                
                                scrollAsNeeded(target);
                                $timeout(() => {
                                    resolve();
                                });
                            });
                        };
                        step.before = function(){
                            return preScroll().then(first());
                        };
                    }
                    
                    if (hasTarget && !hasBefore) {
                        step.before = function() {
                            return new Promise((resolve, reject) => {
                                
                                var target = document.querySelector(step.target);
                                
                                scrollAsNeeded(target);
                                $timeout(() => {
                                    resolve();
                                }); 
                                
                            });
                        };
                    }
                    
                    return step;    
                });
                
                if (finalScript.config.needMenu) template.openNavSidebar();
                
                nzTour.start(finalScript)
                .then(function() {
                    console.log('Tour Finished!');
                })
                .catch(function() {
                    console.log('Tour Aborted!');
                });
            },
            setScript: function(newScript, scope, hooksObj) {
                
                if (hooksObj) {
                    newScript.hooksObj = hooksObj;
                }
                
                if (scope == undefined) {
                    script = newScript;
                } else {
                    var interpolatedScript = angular.copy(newScript);
                    interpolatedScript.steps.forEach(function(step) {
                        Object.keys(step).forEach(function(key) {
                            if (typeof step[key] == 'function') {
                                return;
                            } else {
                                step[key] = $interpolate(step[key])(scope);
                            }
                        });
                    });
                    
                    script = interpolatedScript;
                }

            },
            setShepherdScript: function(script) {
                shepherdScript = script;
            },
            startShepherd: function(){
                    const tour = new Shepherd.Tour({
                        useModalOverlay: true,
                        defaultStepOptions: {
                          cancelIcon: {
                            enabled: true
                          },
                          classes: 'tourStyles',
                          scrollTo: { behavior: 'smooth', block: 'center' },
                          useModalOverlay: true
                        }
                      });
                    
                    shepherdScript.steps.forEach((step) => {
                        if (step.target) {
                           tour.addStep({
                            text: step.content,
                            classPrefix: 'tourscript',
                            attachTo: {
                                element: step.target || '.content',
                                on: 'bottom'
                            },
                            highlightClass: "active-tourscript",
                            buttons: [
                              {
                                action() { return this.back() },
                                text: 'Back',
                                classes: 'btn btn-secondary'
                              },
                              {
                                action() { return this.next() },
                                text: 'Next',
                                classes: 'btn btn-success'
                              }
                            ],
                            id: 'shepherd-tour-intro'
                          });
                        } else {
                            tour.addStep({
                                text: step.content,
                                attachTo: {
                                    element: step.intro || 'body',
                                    on: 'left'
                                },
                               highlightClass: "floatModal",
                               arrow: false,
                               buttons: [
                                {
                                  action() { return this.next() },
                                  text: 'Next',
                                  classes: 'btn btn-success'
                                }
                              ],
                              });
                        }
                    });
                 
                  tour.start();
            },
            tourType: (type = 'default') => {
                tourType = type
                return tourType 
            }

        };
    }];
}]).name;