import angular from 'angular';
import { react2angular } from 'react2angular';

import template from './summaryleakageReport.html';
import controller from './summaryleakageReport.controller';

import phTable from '../../directives/phTable/phTable';
import pic from '../../services/pic';
import localStorage from '../../services/localStorage';
import tour from '../../services/tour';

import { SummaryLeakageReportComponent } from './summaryleakageReportComponent';

const componentName = 'organizationSummaryleakageReport';
const updatedComponentName = 'organizationSummaryleakageReportComponent';
const moduleName = 'app.reports.summaryleakageReport.organization';

angular.module(moduleName, [
    phTable, pic, localStorage, tour
])
.component(componentName, {
    bindings: {
        npis: '<',
        miles: '<',
        tour: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(SummaryLeakageReportComponent, ['npis', 'opts'], ['api', '$localStorage', 'pic', 'notify', 'download', 'tour'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};