import angular from 'angular';

import './servicelinesummary.scss';

import template from './servicelinesummary.html';
import controller from './servicelinesummary.controller';
import { react2angular } from 'react2angular';
import api from '../../services/api';
import tableData from '../../directives/tableData/tableData';
import falsyToDashes from '../../filters/falsy-to-dashes';
import tour from '../../services/tour';

import { ServiceLineSummaryComponent } from './serviceLineSummaryComponent';
const componentName = 'providerServiceLineSummary';
const updatedComponentName = 'serviceLineSummaryComponent';
const moduleName = 'app.reports.servicelinesummary.provider';

angular.module(moduleName, [
    api, tableData, falsyToDashes, tour
])
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(ServiceLineSummaryComponent, ['npis', 'opts', 'community'], ['api', 'notify', 'download'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};