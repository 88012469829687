
import affiliationsModalTemplate from './affiliationsModalTemplate.html';

var modalController = ['$scope', '$uibModalInstance', 'providers', 'config', 'CommunityList', function($scope, $uibModalInstance, providers, config, CommunityList) {
    var $modalCtrl = this;
    var communityModal;
    $modalCtrl.name = config.name;
    $modalCtrl.opts = {
        configure: {
            disable: true
        },
        export: {
            name: `${$modalCtrl.name || 'Provider'} affiliates`
        },
        communityList: {
            accessor: datum => datum.npi,
            description: 'Toggle between the list of providers from the page you were viewing and the list of providers from the list of affiliations.'
        }
    };
    
    $modalCtrl.providers = providers;
    
    $scope.communityList = CommunityList;

    $scope.$watch('communityList.list.length', function(a, b) {
        $scope.modalCommunityCount = a;
    });
    
    if (config.affiliationtype === 'hospital') {
        $modalCtrl.headerTitle = 'Hospital Affiliations';
    } else if (config.affiliationtype === 'group') {
        $modalCtrl.headerTitle = 'Group Affiliations';
    } else {
        $modalCtrl.headerTitle = 'Affiliations';
    }
    
    $modalCtrl.close = function() {
        $uibModalInstance.close();
    };
    
    $modalCtrl.createCommunity = function () {
        CommunityList.openCommunityModal();
    };
    
}];

export default [function(){
    return {
        restrict: 'A',
        scope: {
            modalconfig: '<?'
        },
        template: affiliationsModalTemplate,
        controller: ['$scope', '$q', 'api', 'affiliations', '$uibModal', function($scope, $q, api, affiliations, $uibModal) {
            var $ctrl = this;
            this.$onInit = function() {
                
            };
            $ctrl.openModal = function(modalconfig) {
                
                $uibModal.open({
                    templateUrl: 'ng-template/affiliationsModalContent.html',
                    controller: modalController,
                    controllerAs: '$modalCtrl',
                    size: 'lg',
                    resolve: {
                        providers: function() {
                            if (modalconfig.affiliationtype === 'hospital') {
                                let postObj = {};
                                if (modalconfig.npi) {
                                    postObj.hospitalnpi = `${modalconfig.npi}`;
                                } else {
                                    postObj.hospitalproviderid = modalconfig.id;
                                }
                                return affiliations.getHospitalAffiliations(postObj)
                                    .then(affiliates => {
                                        return $q.all(affiliates.map( afl => {
                                            return api.GetProvider(afl.npi).then(provider => {
                                                provider.source = afl.source;
                                                provider.facilityId = afl.hospitalproviderid;
                                                return provider;
                                            });
                                        }))
                                        .then(providers => providers);
                                    });
                            } else if (modalconfig.affiliationtype === 'group') {
                                let postObj = {};
                                if (modalconfig.npi) {
                                    postObj.groupnpi = `${modalconfig.npi}`;
                                } else {
                                    postObj.grouppracticeid = modalconfig.id;
                                }
                                return affiliations.getGroupAffiliations(postObj)
                                    .then(affiliates => {
                                        return $q.all(affiliates.map( afl => {
                                            return api.GetProvider(afl.npi).then(provider => {
                                                provider.source = afl.source;
                                                provider.grouppracticeid = afl.grouppracticeid;
                                                return provider;
                                            });
                                        }))
                                        .then(providers => providers);
                                    });
                            } else {
                                return [];
                            }
                             
                        },
                        config: function() {
                            return modalconfig;
                        }
                    }
                });
            };
        }],
        controllerAs: '$ctrl'
    };
}];