import angular from 'angular';

import generalFlowModalTemplate from './generalFlowModalTemplate.html';
import generalFlowModalTooltipTemplate from './generalFlowModalTooltipTemplate.html';

import pic from '../../services/pic';
import api from '../../services/api';
import uibModal from 'angular-ui-bootstrap/src/modal';

import { moduleName as generalFlowModule } from '../../reports/generalflow.physician/generalflow';

const moduleName = 'generalFlowModal';

export default moduleName;

angular.module(moduleName, [
    pic, uibModal, generalFlowModule, api
])
.directive(moduleName, [function(){
    return {
        restrict: 'A',
        scope: {
            npi: '=npi'
        },
        controller: ['$scope', '$uibModal', '$state', '$window', 'api', function($scope, $uibModal, $state, $window, api){
            
            var modalInstance;
            
            this.openModal = function(){
                modalInstance = $uibModal.open({
                    template: generalFlowModalTemplate,
                    size: 'lg',
                    controller: ['$scope', '$uibModalInstance', 'provider', function($scope, $uibModalInstance, provider) {
                        var $modalCtrl = this;
                        
                        $scope.options = {
                            toolTip: {
                                template: generalFlowModalTooltipTemplate
                            }
                        };
                        
                        $scope.provider = provider;
                        
                        $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
                            var newTabUrl = $state.href('root.app.provider', {npis: [$scope.provider.npi], path:'home'});
                            $window.open(newTabUrl, '_blank');
                        });
                        
                        $modalCtrl.close = $uibModalInstance.dismiss;
                    }],
                    resolve: {
                        provider: function() {
                            return api.GetProvider($scope.npi);
                        }
                    },
                    controllerAs: '$modalCtrl'
                });
            };
            $scope.$on('$destroy', function() {
                if (modalInstance) {
                    modalInstance.dismiss();
                }
            });
        }],
        link: function(scope, elem, attrs, ctrl){
            elem.on('click', function(e){
                ctrl.openModal();
            });
        }
    };
}]);