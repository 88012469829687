import angular from 'angular';

export default ['$scope', 'api', '$state', function($scope, api, $state) {
    var $ctrl = this;
    $ctrl.stateObject = $state;
    $ctrl.$onInit = function() {
        $ctrl.heading = $state.current.data.name;
        $ctrl.dashboards = $state.current.data.dashboards.map(dashboard => {
            var stateparam = {};
            if ($ctrl.npis) {
                stateparam[dashboard.paramkey] = $ctrl.npis[0].npi;
            } else {
                stateparam[dashboard.paramkey] = $ctrl.community.id;
            }
            
            return {
                name: dashboard.name,
                description: dashboard.description,
                navigate: () => {
                    $state.go(dashboard.stateId, stateparam);
                }
            };
        });
    };
}];