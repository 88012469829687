import angular from 'angular';

import * as D3 from 'd3';
import * as D3Funnel from 'd3-funnel/dist/d3-funnel';

export default 'd3Funnel';

angular.module('d3Funnel', [])
.directive('d3Funnel', ['$window', function($window) {
    return {
        restrict: 'E',
        scope: {
            data: '<data',
            options: '<options'
        },
        link: function(scope, elem, attrs, controller) {

            var boundingEl = elem[0].getBoundingClientRect();
            var options;
            var mergedOptions;

            var chart = new D3Funnel(elem[0]);
            
            function buildChart() {
                options = {
                    chart: {
                        height: boundingEl.height,
                        width: boundingEl.width
                    }
                };
                mergedOptions = angular.merge({}, scope.options, options);
                chart.draw(scope.data, mergedOptions);
            }
            
            scope.onResizeBuildChart = function() {
                if (elem[0].getBoundingClientRect().height === 0) {
                    //in case of resizing when not in view, go back to original size
                    options = {
                        chart: {
                            height: boundingEl.height,
                            width: boundingEl.width
                        }
                    };
                    mergedOptions = angular.merge({}, scope.options, options);
                    chart.draw(scope.data, mergedOptions);
                } else {
                    chart.draw(scope.data, scope.options);
                }
            };

            scope.$watch('data', function(data) {
                if (data) {
                    buildChart();
                    angular.element($window).bind('resize', function() {
                        scope.onResizeBuildChart();
                    });
                }
            });
        }
    };
}]);