import angular from 'angular';

export default 'api';

import notify from './notify';

angular.module('api', [notify])
.factory('Community', function() {
    function Community(data) {
        var community = this;
        
        if (data && data.insert_date) {
            try {
                data.insert_date = Date.parse(data.insert_date);
            } catch (ex) { }
        }
        
        angular.merge(community, data);
        return this;
    };
    
    Community.prototype.serialize = function() {
        return {
            id: this.id,
            name: this.name,
            abbr: this.abbr,
            npis: this.npis.slice(0),
            owner: this.owner,
            is_public: this.is_public,
            watched: this.watched
        };
    };
    
    return Community;    
    
}).factory('Provider', [ 'Taxonomy', function(Taxonomy) {
    function Provider(data) {
        angular.merge(this, data);

        this.taxonomy = new Taxonomy(this.taxonomy);

        return this;
    }
    
    
    return Provider;    

}]).factory('Physician', ['Provider', function(Provider) {
    function Physician(data) {
        Provider.call(this, data);
    }
    
    Physician.prototype = Object.create(Provider.prototype);
    
    
    return Physician;    
    
}]).factory('Organization', ['Provider', function(Provider) {
    function Organization(data) {
        Provider.call(this, data);
    }
    
    Organization.prototype = Object.create(Provider.prototype);
    
    
    return Organization;    
    
}]).factory('Relation', function() {

    function Relation(data) {
        angular.extend(this, data);    
        return this;
    }
    
    Relation.prototype.valueOf = function(){
        return this.values.shared;
    };
    
    return Relation;
    
})
.factory('Taxonomy', function() {
    function Taxonomy(data) {
        angular.extend(this, data);
        return this;
    }

    Taxonomy.prototype.toString = function(ref) {
        ref = ref || this;
        if (ref.specialization) {
            return `${ref.classification} (${ref.specialization})`;
        } else if (ref.classification) {
            return ref.classification;
        } else {
            return ref.type;
        }
    };

    return Taxonomy;
})
.service('api', ['$http', '$q', '$interpolate', '$log', 'Provider', 'Physician', 'Organization', 'Community', 'Relation', 'notify',
function($http, $q, $interpolate, $log, Provider, Physician, Organization, Community, Relation, notify) {
    var srvc = this;

    srvc.Provider = Provider;
    srvc.Physician = Physician;
    srvc.Organization = Organization;
    srvc.Community = Community;
    srvc.Relation = Relation;
    
    var cache = {};
    var batchQueues = {};
    
    srvc.updateCache = function(obj) {
        if (obj instanceof Provider) cache['npi'][obj.npi] = $q.resolve(obj);
        else if (obj instanceof Community) cache['community'][obj.id] = $q.resolve(obj);
    };
    
    var options = {};
    
    srvc.options = function(opts) {
        if (opts) {
            options = angular.merge(options, opts);
            cache['config'] = {};
        } else {
            return options;
        }
        
    };
    
    srvc.getOptions = function() {
        return JSON.parse(JSON.stringify(options));
    };
    
    srvc.getReport = function(entity, report, opts) {
        
        var urlBase = '/api/';
        
        if (entity instanceof Provider)
            urlBase += 'npi/';
        else
            urlBase += 'community/';
            
        urlBase += entity.valueOf();
        
        var httpOptions = angular.merge({
            method: 'GET',
            url: urlBase
        }, options, opts);
        
        switch (report) {
            case 'geoflow':
                httpOptions.url += '/geoflow/';
                break;
            case 'flow':
                httpOptions.url += '/flow/';
                httpOptions.method = 'POST';
                break;
            case 'specialtyflow':
                httpOptions.url += '/specialty_flow/';
                break;
        }
        
        return $http(httpOptions).then(function(response) {
            return response.data;    
        }, function(err) {
            console.log('Report Retrieval Error:', report, err, );
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Provider report data.',
                delay: 30000
            });
            return $q.reject(err);
        });
    };
    
    
    srvc.updateCommunity = function(community) {
        if (community.id) {
            return $q(function(resolve, reject) {
                $http(angular.merge({}, options, {
                    method: 'PUT',
                    url: community['@href'],
                    data: community.serialize()
                })).then(function(res) {
                    Community.call(community, res.data);
                    resolve(community);
                }, function(err) {
                    reject(err);
                });
            });
        } else {
            return $q(function(resolve, reject) {
                $http(angular.merge({}, options, {
                    method: 'POST',
                    url: '/api/community/',
                    data: community.serialize()
                })).then(function(res) {
                    Community.call(community, res.data);
                    srvc.updateCache(community);
                    resolve(community);
                }, function(err) {
                    reject(err);
                });
            });
        }
    };
    
    srvc.deleteCommunity = function(community) {
        return $http(angular.merge({}, options, {
            method: 'DELETE',
            url: community['@href'],
        })).then(function(res) {
            delete cache['community'][community.id];
            return res;
        }, function(err) {
            return $q.reject(err);
        });
    };
    
    srvc.getServiceArea = function(provider) {
        return $http(angular.merge({}, options, {
            method: 'GET',
            url: 'api/npi/' + provider.npi + '/service-area/'
        })).then(function(res) {
            return res.data;
        }, function(err) {
            return $q.reject(err);
        });
    };
    
    srvc.Relations = function(npi, direction, opts) {
        
        var url = "/api/npi/" + npi + "/relations/";
        
        if (direction) {
            url = url + direction + "/";
        }
        
        if (!opts) {
            opts = {};
        }
        
        return $http.get(url, angular.merge({}, options, { params: opts }))
        .then(function(res) {
            
            return buildRelations(res.data);

        }, function(err) {
            return $q.reject(err);
        });
    };
    
    srvc.CommunityRelations = function(cid, direction, options) {
        
        var url = "/api/cid/" + cid + "/relations/";
        
        if (direction) {
            url = url + direction + "/";
        }
        
        if (!options) {
            options = {};
        }
        
        return $http.get(url, angular.merge({}, srvc.options(), { params: options }))
        .then(function(res) {
            
            return buildRelations(res.data);
        }, function(err) {
            return $q.reject(err);
        });
    };
    
    function buildRelations(data) {
        Object.keys(data.npis).forEach(function(npi) {
            if (cache['npi'][npi]) {
                return;
            }
            var provider = Provider.create(data.npis[npi]);
            srvc.updateCache(provider);
        });
        
        var promises = data.relations.map(function(relation) {
            return $q(function(resolve, reject) {
                relation = new Relation(relation);
                
                $q.all([srvc.GetProvider(relation.source), srvc.GetProvider(relation.target)])
                .then(function(npis) {
                    relation.source = npis[0];
                    relation.target = npis[1];
                    resolve(relation);
                });
            });
            
        });
        
        return $q.all(promises);
    }

    var config = [
        { id: "config", func: "Config", url: "/api/config", cacheable: true, resolve: function(data, queue) {
            queue.resolve(data);
        } },
        { id: "taxonomies", func: "Taxonomies", url: "/api/taxonomies/", cacheable: true, resolve: function(data, queue) {
            queue.resolve(data);
        } },
        { id: "groups", func: "Groups", url: "/api/community/", cacheable: false, resolve: function(data, queue) {
            var groups =  [];
                Object.keys(data.communities).forEach(function(cid) {
                    var community = new Community(data.communities[cid]);
                    cache['community'][cid] = $q.when(community);
                    groups.push(community);
                });
            
            queue.resolve(groups);
        } },
        { id: "community", func: "GetCommunity", url: "/api/community/{{id}}/", resolve: function(data, queue) {
            if (typeof data !== 'object') {
                return queue.resolve(new Community({
                    id: data,
                    _failed: true
                }));    
            }
            queue.resolve(new Community(data));
        } },
        { id: "npi",  func: "GetProvider", url: "/api/npi/", batchable: true, resolve: function(data, queue) {
            Object.keys(data.npis).map(function (npi) {
                var provider = data.npis[npi];
                
                queue[npi].deferred.resolve(Provider.create(provider));
                delete queue[npi];
            });
        } },
        { id: "cbsa", func: "CBSA", url: "/api/cbsa/", batchable: true, resolve: function(data, queue) {
            data.map(function (cbsa) {
                if (queue[cbsa.cbsa]) {
                    queue[cbsa.cbsa].deferred.resolve(cbsa);
                    delete queue[cbsa.cbsa];
                }
            });
        } },
        { id: "clinical", func: "Clinical", url: npis => `/api/npi/${npis.join(',')}/clinical/`, cacheable: false, batchable: true,  resolve: function(data, queue) {
            data.data.map(function (code) {
                if (queue[code.npi]) {
                    queue[code.npi].deferred.resolve(code);
                    delete queue[code.npi];
                }
            });
        } },
        { id: "charges", func: "Charges", url: npis => `/api/npi/${npis.join(',')}/charges/`, cacheable: false, batchable: true, resolve: function(data, queue) {
            Object.keys(data.charges).map(npi => {
                var providerCharges = data.charges[npi];
                
                queue[npi].deferred.resolve({npi, totalCharges: providerCharges});
                delete queue[npi];
            });
        } }
    ];
    
    config.forEach(function(conf) {
        if (typeof conf.cacheable === 'undefined') {
            conf.cacheable = true;
        }
            
        if (conf.cacheable) {
            cache[conf.id] = {};
        }
        
        if (conf.batchable) {
            batchQueues[conf.id] = [];
        }

        var urlParse;    
        if (typeof conf.url !== 'function') {
            urlParse = $interpolate(conf.url);
        }
        
        srvc[conf.func] = function(id) {
            if (id) {
                if (Array.isArray(id)) {
                    id = id.join(',');
                } else if (!id.toUpperCase) {
                    id = id+'';
                }
                if (conf.id !== 'community') {
                    id = id.toUpperCase();
                }
            }
            if (conf.cacheable && cache[conf.id][id]) {
                return cache[conf.id][id];
            } else if (conf.batchable && !conf.cacheable) {
                var batchItem = batchQueues[conf.id].find(function(batchItem) {
                    return batchItem.id == id;
                });
                
                if (batchItem) {
                    return batchItem.deferred.promise;
                }
            }
            var deferred = $q.defer();
            

            if (conf.batchable) {
                var item = { id: id, deferred: deferred };
                batchQueues[conf.id].push(item);
            } else {
                setTimeout(function() {
                    $http.get(urlParse({
                        id: id    
                    }), options).then(function(res) {
                        
                        conf.resolve(res.data, deferred);
                        
                    }, function(err) {
                        
                        conf.resolve(id, deferred);
                
                        if (conf.cacheable) {
                            delete cache[conf.id][id];
                        }
                        
                    });
                }, 0);
            }
            if (conf.cacheable)
                cache[conf.id][id] = deferred.promise;

            return deferred.promise;
        };
    });

    setInterval(function() {
        config.filter(function(confg){return confg.batchable;}).forEach(function(conf){ 
            if (batchQueues[conf.id].length > 0) {
                var queue = batchQueues[conf.id];
                batchQueues[conf.id] = [];

                if (queue.length > 250) {
                    queue.splice(250, queue.length - 250).forEach(function (item) {
                        batchQueues[conf.id].unshift(item);
                    });
                }

                queue = queue.reduce(function (h, i) {
                    h[i.id] = i;
                    return h;
                }, {});

                var batchRequest;

                if (typeof conf.url === 'function') {
                    let customURL = conf.url(Object.keys(queue));
                    batchRequest = $http.get(customURL, options);
                } else {
                    batchRequest = $http.get(conf.url + Object.keys(queue).join(',') + '/', options);
                }

                batchRequest.then(function (res) {
                    // console.log('batchRequest res', res);
                    // console.log('batchRequest res queue', Object.entries(queue));
                    conf.resolve(res.data, queue);
                    
                    Object.keys(queue).forEach(function (id) {
                        queue[id].deferred.resolve({ [conf.id]: id, _failed: true });
                    });
                }, function(err) {
                    Object.keys(queue).forEach(function (id) {
                        queue[id].deferred.reject({ [conf.id]: id, _failed: true });
                        if (conf.cacheable) {
                            delete cache[conf.id][id];
                        }
                    });
                    // should we requeue?
                });
            }
        });
    }, 10);
}]); 