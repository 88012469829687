var script = {
    config: {
        needMenu: true
    },
    steps: [{
        content: "This is the Community's home page."
    },{
        target: ".tourscript-removemember",
        content: "Click here to remove a member from your Community",
    },{
        target: '.tourscript-addmember',
        content: "Use this to add more members to your Community.",
    },{
        target: '#tourscript-communityreports',
        content: "There are many reports here to give you insight into the loyalty patterns, referral trends and general behavior of your Community.",
    },{
        target: '#tourscript-communitygraphs',
        content: "There are also plenty of graphs to keep you busy with insighful information.",
    },{
        target: '#tourscript-communities',
        content: "Click here to view all of your Communities",
    }]
};

export default script;