import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import { moduleName as sankeyModule, componentName as sankeyComponent } from '../reports/sankey/sankey';
import { moduleName as carepathwaysModule, physicianComponentName as physiciancarepathwaysComponent } from '../reports/carepathways.provider/carepathways';
import { moduleName as generalFlowModule, componentName as generalFlowComponent, updatedComponentName as updatedGeneralFlowComponent } from '../reports/generalflow.physician/generalflow';
import { moduleName as specialtyFlowModule, componentName as specialtyFlowComponent, updatedComponentName as updatedSpecialtyFlowComponent } from '../reports/specialtyflow.provider/specialtyflow';
import { moduleName as geoflowModule, updatedComponentName as geoflowComponent } from '../reports/geoflow.provider/geoflow';
import { moduleName as flowModule, componentName as flowComponent, updatedComponentName } from '../reports/flow/flow';
import { moduleName as trendingModule, updatedComponentName as updatedTrendingComponent } from '../reports/trending.physician/trending';
import { moduleName as trendingGraphModule, updatedComponentName as trendingGraphComponent } from '../reports/trendinggraph/trending';

export default angular.module('app.pages.phys.graphs', [
    uirouter, carepathwaysModule, generalFlowModule, specialtyFlowModule, geoflowModule, flowModule, trendingModule, trendingGraphModule, sankeyModule
]).config(['$stateProvider', 'authProvider',
function($stateProvider, authProvider) {

    $stateProvider.state({
        name: 'root.app.phy.graphs',
        abstract: true,
        template: '<ui-view class="app.phy.graphs"/>',
        url: ''
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.sankey',
        abstract: true,
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.sankey.carepathways',
        url: '/carejourney',
        altUrls: ['^/physician/butterfly/:npis','^/physician/:npis/carepathways'],
        component: physiciancarepathwaysComponent,
        data: {
            name: 'Care Journey'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.sankey.generalflow',
        url: '/generalflow',
        altUrls: ['^/physician/general_flow/:npis', 'generalflow/:npis'],
        component: updatedGeneralFlowComponent,
        data: {
            name: 'General Flow'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.sankey.specialtyflow',
        url: '/SpecialtyGraph',
        altUrls: ['^/physician/specialty_flow/:npis', '^/physician/specialtyflow/:npis', '^/physician/:npis/specialtyflow' ],
        component: updatedSpecialtyFlowComponent,
        data: {
            name: 'Specialty'
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.geoflow',
        url: '/ReferralMap',
        altUrls: ['^/physician/geoflow/:npis', '^/physician/:npis/geoflow' ],
        component: geoflowComponent,
        data: {
            name: 'Referral Map'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.sankey.flow',
        url: '/flow',
        altUrls: ['^/physician/flow/:npis'],
        component: updatedComponentName,
        data: {
            name: 'Flow'    
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.trending',
        url: '/clinicaldashboard',
        altUrls: ['^/physician/trending/:npis','^/physician/:npis/trending'],
        component: updatedTrendingComponent,
        data: {
            name: 'Clinical Dashboard',
            isActive: function() {
                return authProvider.service.loggedIn().then(user => {
                    return user.dataModel().features.indexOf('PHYSICIAN TRENDING') > -1;
                });
            },
            hideTTM: true
        }
    });
    
    $stateProvider.state({
        name: 'root.app.phy.graphs.trendinggraph',
        url: '/trendinggraph',
        component: trendingGraphComponent,
        data: {
            name: 'Trending'
        }
    });

}]).name;