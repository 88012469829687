import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { useTourManager, useLogging } from '../../services/servicesContext';
import tourScript from './tourConfig.sp';

export function PartdutilizationComponent({ api, /* npis, */ notify, download, tour }) {
    const [ data, setData ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const tourManager = useTourManager();
    const logging = useLogging();
    const npis = useRouteLoaderData('physician.npis');

    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'Prescriptions - 1/10',
                text: "The Part D Prescriber report provides information on prescription drugs prescribed by individual physicians and other health care providers and paid for under the Medicare Part D Prescription Drug Program.",
            },
            {
                id: 2,
                attachTo: { element: ".tourscript-drugname", on: 'bottom'},
                title: 'Prescriptions - 2/10',
                text: "The report lists the specific prescriptions that were dispensed, listed by brand name (if applicable)...",
            },
            {
                id: 3,
                attachTo: { element: ".tourscript-genericname", on: 'bottom' },
                title: 'Prescriptions - 3/10',
                text: "and generic name...",
            },
            {
                id: 4,
                attachTo: {element: ".tourscript-beneficiary", on: 'bottom'},
                title: 'Prescriptions - 4/10',
                text: "The total number of unique Medicare Part D beneficiaries with at least one claim for the drug.",
            },
            {
                id: 5,
                attachTo: { element: ".tourscript-claimcount", on: 'bottom'},
                title: 'Prescriptions - 5/10',
                text: "The number of Medicare Part D claims. This includes original prescriptions and refills. Aggregated records based on total_claim_count fewer than 11 are not included.",
            },
            {
                id: 6,
                attachTo: { element: ".tourscript-daysupply", on: 'bottom'},
                title: 'Prescriptions - 6/10',
                text: "The aggregate number of day's supply for which this drug was dispensed.",
            },
            {
                id: 7,
                attachTo: { element: ".tourscript-drugcost", on: 'bottom' },
                title: 'Prescriptions - 7/10',
                text: "The aggregate total drug cost paid for all associated claims. This amount includes ingredient cost, dispensing fee, sales tax, and any applicable vaccine administration fees.",
            },
            {
                id: 8,
                attachTo: { element: ".tableHeader", on: 'bottom' },
                title: 'Prescriptions 8/10',
                text: "You can sort on any column by simply clicking on the header name.",
            },
            {
                id: 9,
                attachTo: { element: ".filter-box", on: 'bottom' },
                title: 'Prescriptions - 9/10',
                text: "Also, you can filter results here by searching for any value in the row.",
            },{
                id: 10,
                attachTo: { element: "#tourscript-buttons", on: 'bottom' },
                title: 'Prescriptions - 10/10',
                text: "Use this button to download the data in the table.",
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.phy.reports.partdutilization`
        });

        init();

        return () => {
            tourManager.clearTour();
        };
    }, [npis]);

    async function init() {
        try {
            var formatMedicareValue = function(value) {
                if (value === null) {
                    return '< 11';
                } else if (value > 10) {
                    return value;
                } else if (value === 0) {
                    return '0';
                } else {
                    return '-';
                }
            };
            setLoading(true)    
        await fetch('/api/npi/' + npis.npi + '/partd_utilization/', api.options())
            .then(res => res.json())
            .then(function(res) {
                
                if (res.length === 0) {
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Pard D Utilization report data found for given provider.',
                        delay: 30000
                    });
                    setLoading(false);
                }
                const data = res.map(function(datum) {
                    return {
                        'drugName': datum.drug_name || '-',
                        'genericName': datum.generic_name || '-',
                        'beneficiary': formatMedicareValue(datum.bene_count),
                        'claimCount': formatMedicareValue(datum.total_claim_count),
                        'daySupply': datum.total_day_supply || '-',
                        'drugCost': datum.total_drug_cost || '-'
                    };
                });
                setData(data);
                setLoading(false);
                }).catch(err => {
                    setLoading(false)
                    console.error('error requesting relations', err);
                })
           } catch(err) {
               console.error(err); 
           }
    }

    const reportConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col1",
                    "accessor": "drugName",
                    "content": "Drug Name",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return <span className='tourscript-drugname'>{content}</span> }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                },

                {
                    "header": {
                        "id": "col2",
                        "accessor": "genericName",
                        "content": "Generic Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span className='tourscript-genericname'>{content}</span> }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "beneficiary",
                        "content": "Beneficiary Count",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span style={{ 'float' : 'right', marginRight : '10px'}} className='tourscript-beneficiary'>{content}</span> }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "claimCount",
                        "content": "Total Claim Count",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span style={{ 'float' : 'right', marginRight : '10px'}} className='tourscript-claimcount'>{content}</span>  }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "daySupply",
                        "content": "Total Day Supply",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span style={{ 'float' : 'right', marginRight : '10px'}} className='tourscript-daysupply'>{content.toLocaleString('en-US')}</span>  }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "drugCost",
                        "content": "Total Drug Cost",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return <span style={{ 'float' : 'right', marginRight : '10px'}} className='tourscript-drugcost'>${content.toLocaleString('en-US')}</span>   }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                }
  
               ],
            configure : true,
            configureButton: true,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            reportTitle: `Prescriptions Report for ${npis && npis.name && npis.name.display} (${npis.npi})`,
            localStorageName: 'app.phy.reports.partdutilization'
        }
    }

  

  return (
    <>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    { (data) ? <PhtableComponent data={data} reportconfig={reportConfig}  download={download} />  : null}
    </>
  )
}
