import angular from 'angular';

export default angular.module('codes', [])
.provider('codes', function() {
    
    this.$get = ['$http', '$q', 'api', 'auth','config', function($http, $q, api, auth, config) {
        
        let codesEndpointURL;
        codesEndpointURL = config.codesEndpoint();
        return {
            searchCodesGet: function(codes) {
                return $http.get(
                    `${codesEndpointURL}/codes/search/${codes.join(',')}/`,
                    {
                        headers: {
                            Authorization: api.options().headers.Authorization
                        }
                    }
                )
                .then(res => {
                    const rawData = res?.data?.data;
                    return rawData;
                })
                .catch(err => {
                    console.log('error fetching codes', err);
                });
            },
            searchCodesPost: function(searchObj) {
                return $http.post(
                    `${codesEndpointURL}/codes/search/`,
                    searchObj,
                    {
                        headers: {
                            Authorization: api.options().headers.Authorization
                        }
                    }
                )
                .then(res => {
                    const rawData = res?.data;
                    return rawData;
                })
                .catch(err => {
                    console.log('error fetching codes', err);
                });
            },
            searchCodesetServicelines: function(codesetStr) {
                return $http.get(
                    `${codesEndpointURL}/servicelines/${codesetStr}`,
                    {
                        headers: {
                            Authorization: api.options().headers.Authorization
                        }
                    }
                )
                .then(res => {
                    return res.data;
                }).catch(err => {
                    console.error('error fetching servicelines for codeset', err);
                });
            }
        };
        
    }];
    
}).name;