import React, {useState, useEffect} from "react";
import { useRouteLoaderData } from "react-router-dom";
import * as parseD from 'd-path-parser';
import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';
import { useLogging, useTourManager } from '../../services/servicesContext';
import merge from 'lodash/merge';

export function CommunityGeneralflowComponent({
    opts,
    api,
    pic,
    template
}) {

    const graphOptions = {
        node: {
            label: function(d){
                if (d.entitytype == 2) {
                    return d.name.display+' ('+d.npi+')';
                } else if (d.entitytype == 1) {
                    return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                } else { // Community AKA 'Others'
                    return d.name; 
                }
            }
        },
        toolTip: {
            units: 'Shared Visits',
            value: function (d) {
                return d.values.shared;
            }
        }
    };

    const [ loading, setLoading ] = useState(true);
    const [ relations, setRelations ] = useState(null);
    const [ options, setOptions ] = useState(null);
    const tourManager = useTourManager();
    const logging = useLogging();
    const community = useRouteLoaderData('community.cid');
    const [axis, setAxis] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        sameday: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };
    const handleDropdownClick = function (col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
    };
    const toggleAxis = function (property) {
        let optionsClone = { ...options };
        optionsClone.link = {
            value: function (d) {
                return d.values[property];
            }
        };
        merge(optionsClone.toolTip, {
            units: mapping[property].units,
            value: function (d) {
                return d.values[property];
            }
        });
        optionsClone.node.sortAxis = mapping[property].sortAxis;
        setAxis(property);
        setOptions(optionsClone);
        setOpenDropdown(null);
    };

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: `root.app.com.graphs.sankey.generalflow`
        });

        tourManager.createTour([
            {
                id: 1,
                title: 'General Flow - 1/4',
                text: "The purpose of the Physician General Flow graphic is to help you visualize a Physician's affinity for the Hospitals with whom they share patients.",
            },
            {
                id: 2,
                attachTo: { element: 'g.nodes g.node rect:not([data-side])', on: "right" },
                title: 'General Flow - 2/4',
                text: `The selected Physician, ${community.name} is seen here on the left side of the graph.`,
            },{
                id: 3,
                attachTo: { element: 'g.nodes g.node rect[data-side="right"]', on: "left" },
                title: 'General Flow - 3/4',
                text: `The hospitals that see patients after ${community.name} are shown as the right-side "nodes."`,
            },{
                id: 4,
                attachTo: { element: 'div.sankeyTooltip', on: 'bottom' },
                title: 'General Flow - 4/4',
                text: "Patient volumes are represented by the gray lines and can be hovered over to provide more detail.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var pathD= parseD(elem.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                        
                    });
                },
                /* after: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var moveEvent = new Event('mouseleave');
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        resolve();
                    });
                } */
            }
        ]);

        async function init() {
            try {
                setLoading(true);
                let data = await api.CommunityRelations(community.id, 'outbound', { classification: 'General Acute Care Hospital' });

                if (data.length === 0) {
                    console.log('No Provider General Flow data found: ', data);
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Provider General Flow data found.',
                        delay: 30000
                    });
                }

                let sortedData = data.sort(function(a, b) {
                    return b.values.shared - a.values.shared;
                });

                let finalSortedDataLookup = sortedData.reduce((acc, relation) => {
                    if (!acc[relation.target.npi]) {
                        acc[relation.target.npi] = {
                            target: relation.target,
                            values: relation.values
                        };
                    } else {
                        acc[relation.target.npi].values.sameday += relation.values.sameday;
                        acc[relation.target.npi].values.shared += relation.values.shared;
                        acc[relation.target.npi].values.unique += relation.values.unique;
                    }
                    return acc;
                },{});

                let finalData = Object.keys(finalSortedDataLookup).map(key => {
                    return new api.Relation({
                        source: community,
                        target: finalSortedDataLookup[key].target,
                        values: finalSortedDataLookup[key].values
                    });
                });

                setAxis('shared');
                setOptions(graphOptions);
                setRelations(finalData);
                setLoading(false);
                
            } catch(err) {
                console.error('error requesting relations', err);
            }
        }

        init();

        return () => {
            tourManager.clearTour();
        };

    }, [opts, api, community]);

    return (<>
        {(relations && options && pic && template && !loading)
            ? <div className="sankey-report-container">
                <div style={{ display: 'contents' }}>
                    <div className="col-sm-4 secondary-button-container" style={{ marginRight: 0, marginLeft: 'auto' }}>
                        <div className={`provider-axis-dropdown col-md-6 axis-toggle ${openDropdown === 'axis' ? 'open' : ''}`} style={{ padding: 0 }}>
                            <button id="values-axis" type="button" onClick={() => { handleDropdownClick('axis') }} className="btn btn-default btn-md btn-block">{mapping[axis].units} <span className="caret"></span></button>
                            <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis" style={{ width: '100%' }}>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => { toggleAxis('shared') }}></input>
                                        {mapping.shared.units}
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'sameday'} value="sameday" onChange={() => { toggleAxis('sameday') }}></input>
                                        {mapping.sameday.units}
                                    </label>
                                </li>
                                <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                    <label>
                                        <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => { toggleAxis('unique') }}></input>
                                        {mapping.unique.units}
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 reset" style={{ padding: 0 }}>
                            <button type="button" onClick={() => { toggleAxis('shared') }} className="btn btn-default btn-md btn-block">Reset</button>
                        </div>
                    </div>
                </div>
                <div style={{ flex: 19 }}>
                    <PhSankeyComponent
                        data={relations}
                        opts={options}
                        pic={pic}
                        template={template}
                    />
                </div>
            </div>
            : <div className="loading-lg"></div>
        }
    </>);

}