import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$http', 'api', 'tour', 'notify', function($scope, $http, api, tour, notify) {
    var $ctrl = this;

    function formatEndpointData(data) {
        var serviceLines = Object.keys(data).map(function(key){
            data[key].serviceLine = key;
            return data[key];
        }).map(function(serviceLineObj){
            // check for lack of diagnosis or procedure property and add empty data if absent
            var emptyData = { charges: 0, patients: 0, transactions: 0};
            if (!serviceLineObj.diagnosis) { serviceLineObj.diagnosis = emptyData; }
            if (!serviceLineObj.procedure) { serviceLineObj.procedure = emptyData; }
            return serviceLineObj;
        });
        
        return serviceLines;
    }

    $ctrl.$onInit = function() {
        
        $scope.provider = $ctrl.npis[0];
        
        tour.setScript(tourConfig, $scope);
        
        $scope.reportOptions = { 
            export: { 
                name: 'Service Line Report for ' + $scope.provider.name.display 
            }
        };
        
        var endpoint = '/api/npi/'+ $ctrl.npis[0].npi +'/servicelines/';
        
        $http.get(endpoint, api.options())
        .then(function(res){
            
            $scope.enpointData = res.data;
            $scope.displayData = formatEndpointData(res.data);
            $scope.loaded = true;
             
        }, function(rejection){
            console.log('Error fetching Service Line Summary data: ', rejection);
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Service Line Summary data.',
                delay: 30000
            });
        });
       
    };
}];