import React, { useEffect } from "react";
import {
    Outlet, useLocation
} from "react-router-dom";

import { useLogging } from "../../services/servicesContext";

import './landing.scss';
import logo from '../../public/ph-large-1284x440.png';

// landing component is higher order component that renders itself or children based on route
export function LandingComponent(props) {
    let locationUrl = useLocation();
    const logging = useLogging();

    if (locationUrl.pathname === "/") {
        
        logging.routeLoad({
            pathname: '/',
            npis: [],
            statename: 'root.app.landing'
        });

        return (<div className="container-home">
            <div className="jumbotron">
                <img src={logo} className="img-responsive" />
            </div>
            <div className="row">
                <div className="col-md-4">
                    <h2><i className="fa fa-bar-chart" aria-hidden="true"></i> Visualization</h2>
                    <p>TEAM helps you see referral patterns across your entire network, from primary care to specialists to facilities to post-acute care. We put the data into meaningful graphs and provide the reporting you need to tell your story.</p>
                </div>
                <div className="col-md-4">
                    <h2><i className="fa fa-pencil" aria-hidden="true"></i> Strategy</h2>
                    <p>Built upon the most robust database of commercial and Medicaid medical claims in the industry, TEAM helps you spend less time managing referral data and more time on strategy. You can then grow your market by looking at one doctor, a group of physicians, or your entire network at one time.</p>
                </div>
                <div className="col-md-4">
                    <h2><i className="fa fa-cogs" aria-hidden="true"></i> Execute</h2>
                    <p>Once your strategy is set, TEAM helps you identify and address referral share, prioritize relationships, and measure accountability, as well as pre-qualifying potential partnerships and selecting new sites of service.</p>
                </div>
            </div>
        </div>);
    } else {
        return <> {props.children} </>;
    }
}