import angular from 'angular';

import modalTemplate from './servicelinebenchmarkModal.html';

export default ['$scope', 'api', 'notify', '$http', '$q', 'pic', '$uibModal', 'config', 'codes', 'download', function($scope, api, notify, $http, $q, pic, $uibModal, config, codes, download) {
    
    var $ctrl = this;
    var modal;
    var defaultGroupedBarOpts = {
        groupKey: 'serviceline'
    };
    
    $ctrl.providerClinicalData = {};//add provider clinical data to this object as needed
    
    $ctrl.servicelines = {};
    
    $ctrl.communities = [];
    
    function getProvidersClinicalData(npis, ctrlProviderClinicalData) {
        if (npis.length > 0) {
            return $http.post('/api/npi/clinical/', { npis: npis }, api.options())
            .then(res => {
                if (res.data.data.length === 0) {
                    notify.alert({
                        title: 'NO CLINICAL DATA',
                        text: 'No clicical data found for the requested community.',
                        delay: 10000
                    });
                }
                res.data.data.forEach(providerCode => {//add providers clinical data to the providerClinicalData lookup
                    if (!ctrlProviderClinicalData[`${providerCode.npi}`]) {
                        ctrlProviderClinicalData[`${providerCode.npi}`] = res.data.npis[`${providerCode.npi}`];
                        ctrlProviderClinicalData[`${providerCode.npi}`].data = [providerCode];
                    } else {
                        ctrlProviderClinicalData[`${providerCode.npi}`].data.push(providerCode);
                    }
                });
                return true;
            }, err => {
                console.error('error retrieving clinical data', err);
                return err;
            });
        } else {
            return true;
        }
        
    }
    
    function setServicelines() {
        $ctrl.servicelines = {};//set fresh each time
        
        $ctrl.communities.forEach(community => {
            community.npis.forEach(npi => {
                if ($ctrl.providerClinicalData[`${npi}`]) {
                    $ctrl.providerClinicalData[`${npi}`].data.forEach(providerCode => {
                        
                        if (!$ctrl.servicelines[`${providerCode.serviceline}`]) {// if not serviceline, create one
                            $ctrl.servicelines[`${providerCode.serviceline}`] = {};
                            $ctrl.servicelines[`${providerCode.serviceline}`][`${community.name}`] = providerCode.values.line_srvc_cnt;
                            // while here initalize codes lookup
                            $ctrl.servicelines[`${providerCode.serviceline}`].codes = {};
                        } else {
                            if (!$ctrl.servicelines[`${providerCode.serviceline}`][`${community.name}`]) {// if no total in serviceline for current community, add one
                                $ctrl.servicelines[`${providerCode.serviceline}`][`${community.name}`] = providerCode.values.line_srvc_cnt;
                            } else {// if serviceline and current community exists on it, add current providerCode value to it
                                $ctrl.servicelines[`${providerCode.serviceline}`][`${community.name}`] += providerCode.values.line_srvc_cnt;
                            }
                        }
                        
                        // while here take current community and add to relevant code totals
                        if (!$ctrl.servicelines[`${providerCode.serviceline}`].codes[`${providerCode.code}`]) {//check for current code initialization within serviceline
                            $ctrl.servicelines[`${providerCode.serviceline}`].codes[`${providerCode.code}`] = {};
                            $ctrl.servicelines[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name] = providerCode.values.line_srvc_cnt;
                        } else {// if current code initialized
                            if (!$ctrl.servicelines[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name]) {//check if current community is initialized on current code
                                $ctrl.servicelines[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name] = providerCode.values.line_srvc_cnt;
                            } else {// and add to it if current community is initialized on current code
                                $ctrl.servicelines[`${providerCode.serviceline}`].codes[`${providerCode.code}`][community.name] += providerCode.values.line_srvc_cnt;
                            }
                        }
                        
                    });
                }
            });
        });
    }
    
    function setOpts() {
        $ctrl.opts = {
            groupKey: defaultGroupedBarOpts.groupKey,
            keys: $ctrl.communities.map(group => group.name),
            groupClickHandler: openServicelineModal
        };
    }
    
    function setData() {
        $ctrl.data = Object.keys($ctrl.servicelines).map(servicelineKey => {
            var ret = $ctrl.servicelines[servicelineKey];
            ret.serviceline = servicelineKey;
            return ret;
        });
    }
    
    function openServicelineModal(servicelineData, keys) {
        if (modal) modal.dismiss();
        
        modal = $uibModal.open({
            size: 'lg',
            template: modalTemplate,
            controllerAs: '$ctrl',
            controller: ['$scope', '$uibModalInstance', '$timeout', function($scope, $uibModalInstance, $timeout) {
                var $ctrl = this;
                $ctrl.$onInit = function() {
                    $timeout(() => {
                        $ctrl.opts = {
                            groupKey: 'code',
                            keys: keys,
                            groupClickHandler: () => {},
                            yAxisKeyHoverHandler: (d) => {
                                return codes.searchCodesGet([d]);
                            }
                        };
                        $ctrl.title = servicelineData.serviceline;
                        $ctrl.data = Object.keys(servicelineData.codes).map(codeKey => {
                            var ret = servicelineData.codes[codeKey];
                            ret.code = codeKey;
                            return ret;
                        });
                    }, 0);
                    
                };
                $ctrl.cancel = function () {
                    $uibModalInstance.close();
                };
                $ctrl.$onDestroy = function() {
                    console.log('destroying modal');
                };
            }]
        });
    }
    
    $scope.$watch("$ctrl.communities", function(newValue, oldValue){
        if(newValue != oldValue){
            $ctrl.loaded = false;
            $q.all($ctrl.communities.map(community => community.npis).flat().map(npi => api.GetProvider(npi)))
            .then(providers => {
                return getProvidersClinicalData(providers.filter(provider => (provider.entitytype == 1 && !$ctrl.providerClinicalData[`${provider.npi}`])).map(p => p.npi), $ctrl.providerClinicalData);
            }, err => {
                console.error('error resolving providers', err);
                $ctrl.loaded = true;
            })
            .then(res => {
                setServicelines();
                setOpts();
                setData();
                $ctrl.loaded = true;
            }, err => {
                console.error('error getting clinical data', err);
                $ctrl.loaded = true;
            });
            
        }
    }, true);
    
    $ctrl.$onInit = function() {
        let rawGroups;
        api.Groups()
        .then(groups => {
            rawGroups = groups;
            return $q.all(groups.map(group => group.type()));
        }, err => {
            console.error('error retrieving groups data', err);
        })
        .then(types => {
            $ctrl.availableCommunities = rawGroups.filter((group, idx) => types[idx] === '1');
            rawGroups.forEach(group => {
                if (group.id === $ctrl.community.id) {
                    $ctrl.communities.push(group);
                }
            });
            $ctrl.loaded = true;
        }, err => {
            console.error('error resolving groups types', err);
        });
        
    };
    
    $ctrl.filterCommunities = function(val, idx, arr) {
        if ($ctrl.communities.map(comm => comm.id).indexOf(val.id) === -1) {
            return true;
        } else {
            return false;
        }
        
    };
    
    $ctrl.resetAllFilters = function() {
        $ctrl.communities = $ctrl.communities.filter(community => community.id === $ctrl.community.id);
    };
    
    $ctrl.downloadCSV = function() {
        
        let applicableNPIs = new Set();
        let communityLookup = {};
        
        $ctrl.communities.forEach(community => {
            community.npis.forEach(npi => {
                applicableNPIs.add(`${npi}`);
                if (!communityLookup[`${npi}`]) {
                    communityLookup[`${npi}`] = [community.name];
                } else {
                    communityLookup[`${npi}`].push(community.name);
                }
            });
        });
        
        let reportData = Object.keys($ctrl.providerClinicalData).reduce((acc, clinicalNPI) => {
            if (applicableNPIs.has(clinicalNPI) && $ctrl.providerClinicalData[clinicalNPI].data) {//is this provider active in the chart
                acc = acc.concat($ctrl.providerClinicalData[clinicalNPI].data.map(providerCode => {
                    return {
                        'Code': providerCode.code,
                        'Codeset': providerCode.codeset,
                        'Serviceline': providerCode.serviceline,
                        'Provider Name': $ctrl.providerClinicalData[clinicalNPI].name.display,
                        'Provider NPI': clinicalNPI,
                        'Communities': communityLookup[clinicalNPI].join(', '),
                        'Procedures': providerCode.values.line_srvc_cnt
                    };
                }));
            }
            return acc;
        }, []);
        
        var columnOrder = [
            ['Code', 0],
            ['Codeset', 1],
            ['Serviceline', 2],
            ['Provider Name', 3],
            ['Provider NPI', 4],
            ['Communities', 5],
            ['Procedures', 6]
        ];
        
        download.downloadCSV(reportData, `Community Serviceline Benchmark Report for ${$ctrl.community.name}`, true, columnOrder);
        
    };
    
    $ctrl.$onDestroy = function() {
        if (modal) {
            modal.close();
        }
    };
}];