import angular from 'angular';

export default angular.module('models.provider', [])
.run(['Provider', 'Physician', 'Organization', 'api', 
function(Provider, Physician, Organization, api) {
    
    Provider.create = function createProviderClass(provider) {
        if (provider.entitytype === '1') {
            return new Physician(provider);
        } else {
            return new Organization(provider);
        }
    };
    
    Provider.prototype.toString = function() {
        return this.name.display;    
    };
    
    Provider.prototype.valueOf = function() {
        return this.npi;
    };
    
    Provider.prototype.GetServiceArea = function() {
        var provider = this;
        return api.getServiceArea(provider);
    };
    
    Provider.prototype.Report = function(report) {
        return api.getReport(this, report);    
    };
    
}]).name;