import angular from 'angular';
import crossfilter from 'crossfilter2';

export default ['$scope', 'api', 'notify', '$http', '$q', 'pic', 'codes', '$state', 'download', function($scope, api, notify, $http, $q, pic, codes, $state, download) {
  var $ctrl = this;
  
  function groupByProvider(providerCodes) {
    var providerLookup = providerCodes.reduce((acc, val) => {
      if (!acc[`${val.provider.npi}`]) {
        acc[`${val.provider.npi}`] = {
          npi: `${val.provider.npi}`,
          provider: val.provider,
          procedures: val.values.line_srvc_cnt
        };
      } else {
        acc[`${val.provider.npi}`].procedures += val.values.line_srvc_cnt;
      }
      return acc;
    }, {});
    return Object.values(providerLookup);
  }
  
  function navToProviderHome(npi) {
    $state.go('root.app.phy.home', {npis: [npi]});
  }
  
  $ctrl.filterZeroValue = function(val, idx, arr) {
    return val.value !== 0;
  };
  
  $ctrl.resetAllFilters = function() {
    $ctrl.activeFilterServicelines = [];
    $ctrl.activeFilterCodesets = [];
    $ctrl.activeFilterCodes = [];
    
    $ctrl.servicelinesControlList.forEach(code => {code.active = false});
    $ctrl.codesetsControlList.forEach(code => {code.active = false});
    $ctrl.codesControlList.forEach(code => {code.active = false});
    
    $ctrl.linesByServicelineDim.filter(null);
    $ctrl.linesByCodesetDim.filter(null);
    $ctrl.linesByCodeDim.filter(null);
    $ctrl.data = groupByProvider($ctrl.crossfilter.allFiltered());
  };
  
  $ctrl.updateServicelineFilters = function(clickedServiceline) {
    
    if ($ctrl.activeFilterServicelines.indexOf(clickedServiceline) > -1) {
      $ctrl.activeFilterServicelines = $ctrl.activeFilterServicelines.filter(code => code != clickedServiceline);
    } else {
      $ctrl.activeFilterServicelines.push(clickedServiceline);
    }
    
    if ($ctrl.activeFilterServicelines.length > 0) {
      $ctrl.linesByServicelineDim.filter(d => $ctrl.activeFilterServicelines.indexOf(d) > -1);
    } else {
      $ctrl.linesByServicelineDim.filter(null);
    }
    
    $ctrl.data = groupByProvider($ctrl.crossfilter.allFiltered());
  };
  
  $ctrl.updateCodesetFilters = function(clickedCodeset) {
    
    if ($ctrl.activeFilterCodesets.indexOf(clickedCodeset) > -1) {
      $ctrl.activeFilterCodesets = $ctrl.activeFilterCodesets.filter(codeset => codeset != clickedCodeset);
    } else {
      $ctrl.activeFilterCodesets.push(clickedCodeset);
    }
    
    if ($ctrl.activeFilterCodesets.length > 0) {
      $ctrl.linesByCodesetDim.filter(d => $ctrl.activeFilterCodesets.indexOf(d) > -1);
    } else {
      $ctrl.linesByCodesetDim.filter(null);
    }
    
    $ctrl.data = groupByProvider($ctrl.crossfilter.allFiltered());
  };
  
  $ctrl.updateCodeFilters = function(clickedCode) {
    
    if ($ctrl.activeFilterCodes.indexOf(clickedCode) > -1) {
      $ctrl.activeFilterCodes = $ctrl.activeFilterCodes.filter(code => code != clickedCode);
    } else {
      $ctrl.activeFilterCodes.push(clickedCode);
    }
    
    if ($ctrl.activeFilterCodes.length > 0) {
      $ctrl.linesByCodeDim.filter(d => $ctrl.activeFilterCodes.indexOf(d) > -1);
    } else {
      $ctrl.linesByCodeDim.filter(null);
    }
    
    $ctrl.data = groupByProvider($ctrl.crossfilter.allFiltered());
  };
  
  $ctrl.$onInit = function() {
    
    $ctrl.cbsa = $ctrl.npis[0].cbsa.point;
    
    $ctrl.sidebarOpts = {
      seedProvider: $ctrl.npis[0],
      navToProviderHome: navToProviderHome
    };
    $ctrl.histogramOpts = {
      seedProvider: $ctrl.npis[0],
      navToProviderHome: navToProviderHome
    };
    
    var params = {
      entitytypecode: 1,
      taxonomy: $ctrl.npis[0].taxonomy.code,
      cbsapoint: $ctrl.npis[0].cbsa.point,
      limit: 1_000_000_000
    };
    
    
    $http.get(`/api/cbsa/${$ctrl.npis[0].cbsa.point}/`, api.getOptions())
    .then(res => {
      $ctrl.cbsaName = res.data[0].name;
    }, err => console.log('error requesting cbse', err));
    
    $http.get('/api/npi/search', angular.merge({
      params: params
    }, api.getOptions()))
    .then(res => {
      
        let npis = res.data.npis.map(provider => provider.npi);
        if (npis.length === 1) {
          notify.alert({
            text: "No other providers of the same taxonomy type found in this provider's cbsa",
            type: 'warning',
            delay: 30000
          });
          $ctrl.loaded = true;
        } else {
          $ctrl.sidebarOpts.communityMembers = $ctrl.histogramOpts.communityMembers = npis.filter(npi => pic(npi).length > 0);
          return $http.post('/api/npi/clinical/', {npis: npis}, api.options());
        }
        
    }, res => {
        if (res.status > -1) {
            notify.alert({ text: 'Search failed', type: 'warning'});
        }
    })
    .then(res => {
      
      $ctrl.providerClinicalData = res.data.data.reduce((acc, item) => {
        
        if (!acc.providers[`${item.npi}`]) {
          acc.providers[`${item.npi}`] = {npi: `${item.npi}`, provider: res.data.npis[`${item.npi}`], procedures: item.values.line_srvc_cnt};
        } else {
          acc.providers[`${item.npi}`].procedures += item.values.line_srvc_cnt;
        }
        
        if (!acc.codes[item.codeset]) {
          acc.codes[item.codeset] = [];
        } else {
          if (acc.codes[item.codeset].indexOf(item.code) === -1) {
            acc.codes[item.codeset].push(item.code);
          }
        }
        
        if (acc.servicelines.indexOf(item.serviceline) === -1) {
          acc.servicelines.push(item.serviceline);
        }
        
        var line = {
          code: item.code,
          codeset: item.codeset,
          values: item.values,
          provider: res.data.npis[`${item.npi}`],
          serviceline: item.serviceline
        };
        acc.lines.push(line);
        
        return acc;
      }, {providers: [], codes: {}, lines: [], servicelines: []});
      
      $ctrl.activeFilterCodes = [];
      $ctrl.activeFilterServicelines = [];
      $ctrl.activeFilterCodesets = [];
      
      $ctrl.crossfilter = crossfilter($ctrl.providerClinicalData.lines.slice());
      
      $ctrl.linesByProviderDim = $ctrl.crossfilter.dimension(d => d.provider.npi);
      $ctrl.providersByVolumeGrp = $ctrl.linesByProviderDim.group().reduceSum(line => line.values.line_srvc_cnt);
      
      $ctrl.linesByCodesetDim = $ctrl.crossfilter.dimension(d => d.codeset);
      $ctrl.codesetsByCountGrp = $ctrl.linesByCodesetDim.group();
      
      $ctrl.linesByCodeDim = $ctrl.crossfilter.dimension(d => d.code);
      $ctrl.codesByCountGrp = $ctrl.linesByCodeDim.group();
      
      $ctrl.linesByServicelineDim = $ctrl.crossfilter.dimension(d => d.serviceline);
      $ctrl.servicesByCountGrp = $ctrl.linesByServicelineDim.group();
      
      $ctrl.codesControlList = $ctrl.codesByCountGrp.top(Infinity);
      $ctrl.servicelinesControlList = $ctrl.servicesByCountGrp.top(Infinity);
      $ctrl.codesetsControlList = $ctrl.codesetsByCountGrp.top(Infinity);
      
      codes.searchCodesPost({
        codeset: 'HCPCS,CPT',
        codes: $ctrl.codesControlList.map(code => code.key)
      })
      .then(res => {
        $ctrl.codeDescriptionLookup = res.data.reduce((acc,val)=>{
          if (!acc[val.code]) {
            acc[val.code] = {
              code: val.code,
              codesets: [val.codeset],
              description: val.description,
            };
          } else {
            acc[val.code].codesets.push(val.codeset);
          }
          return acc;
        },{});
      }, err => {
        console.error('error getting codes for lookup', err);
      });
      
      $ctrl.loaded = true;
      $ctrl.data = groupByProvider($ctrl.crossfilter.allFiltered());
      
      $ctrl.downloadCSV = function() {
        
        let reportData = $ctrl.crossfilter.allFiltered().map(row => {
          return {
            'Code': row.code,
            'Codeset': row.codeset,
            'Serviceline': row.serviceline,
            'Provider Name': row.provider.name.display,
            'Provider NPI': row.provider.npi,
            'Procedures': row.values.line_srvc_cnt
          };
        });
        
        var columnOrder = [
            ['Code', 0],
            ['Codeset', 1],
            ['Serviceline', 2],
            ['Provider Name', 3],
            ['Provider NPI', 4],
            ['Procedures', 5]
        ];
        
        download.downloadCSV(reportData, `CBSA Market Volume Report for ${$ctrl.npis[0].toString()}`, true, columnOrder);
        
      };
      
    }, err => {
      console.error('error getting clinical data', err);
    });
    
  };
  
  $ctrl.$onDestroy = function() {
    console.log('buh bye!');
  };
  
}];