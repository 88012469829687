import angular from 'angular';
import * as d3 from 'd3';

import modalTemplate from './communitySpecialtyFlowModal.html';

export default ['$scope', '$http', '$q', '$state', '$window', 'config', 'api', 'notify', '$uibModal',
function($scope, $http, $q, $state, $window, config, api, notify, $uibModal) {
    var $ctrl = this;
    $ctrl.relations = [];
    
    $scope.mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        same: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };
    
    var modal;
    
    $scope.$on('$destroy', function() {
       if (modal) {
           modal.dismiss();
       }
    });
    
    bindSankeyMouseClick($scope);
    
    $ctrl.toggleAxis = function(property) {
        
        $ctrl.options.toolTip.units = $scope.mapping[property].units;
        $ctrl.options.toolTip.value = function(d) {
            return d.values[property];
        };
        $ctrl.options.link.value = function(d) {
            return d.values[property];
        };
        $ctrl.reportSankey.options(angular.merge({}, $ctrl.options));
        
        $ctrl.relations.sort((a,b) => {
            return b.values[property] - a.values[property];
        });
        
        $ctrl.reportSankey.data($ctrl.relations);
    };
    
    $ctrl.$onInit = function() {
        
        $scope.axis = 'shared';
        
        $ctrl.options = {
            colorLegend: true,
            toolTip: {
                sourceName: function(d){
                    return d.origin.toString();
                },
                targetName: function(d){
                    return d.destination.toString();
                },
                units: 'Shared Visits',
                value: function(d) {
                    return d.values.shared;
                }
            },
            color: {
                scale: d3.scaleOrdinal(d3.schemeCategory20),
                value: function(d) {
                    return this.scale(d);
                }
            },
            node: {
                colorValue: function(d){
                    if (d.taxonomy) {
                        return d.taxonomy.toString();
                    } else {
                        return d.id;
                    }
                },
                label: function(d){
                    if (d.entitytype == 2) {
                        return d.name.display+' ('+d.npi+')';
                    } else if (d.entitytype == 1) {
                        return d.name.primary[0]+'. '+d.name.secondary+' ('+d.npi+')'; 
                    } else {
                        return d.id;
                    }
                }
            },
            link: {
                hoverClassCheck: function(currentDatum, hoverDatum){
                    if (currentDatum.origin == hoverDatum.origin && currentDatum.destination == hoverDatum.destination) {
                        return false;
                    } else {
                        return true;
                    }
                },
                value: function(d) {
                    return d.values.shared;
                }
            }
        };
        
        $ctrl.reportSankey.options($ctrl.options);
        
        $scope.containsPcp = false;
        $scope.containsSpecialist = false;
        $scope.containsOrg = false;
        
        if ($ctrl.community) {
            
            $ctrl.endpoints = {
                pcp: '/api/community/'+$ctrl.community.id+'/specialtyflow/left/',
                specialist: '/api/community/'+$ctrl.community.id+'/specialtyflow/middle/',
                organization: '/api/community/'+$ctrl.community.id+'/organizationspecialtyflow/'
            };
            
            $ctrl.payloads = {
                pcp: {"initial":true},
                specialist: {"classification":null,"specialization":null,"initial":true},
                organization: {"initial":false}
            };
            
            $ctrl.pcpTaxonomyCodes = config.pcpTaxonomyCodes();
            
            $ctrl.communityNode = new api.Community({
                name: $ctrl.community.name
            });
            
            $ctrl.community.members = $ctrl.community.npis.map(function(npi){
                return api.GetProvider(npi).then(function(provider){
                    if (!provider._failed) {
                        if (provider.entitytype == 2) {
                            $scope.containsOrg = true;
                        } else if ($ctrl.pcpTaxonomyCodes.indexOf(provider.taxonomy.code) > -1) {
                            $scope.containsPcp = true;
                        } else {
                            $scope.containsSpecialist = true;
                        }
                    }
                    return provider;
                });
            });
            
            $q.all($ctrl.community.members).then(function(members){
                if ($scope.containsSpecialist) {// set specialist as default filter if one exists in community
                    $scope.providerFilter = {type:'specialist'};
                } else if ($scope.containsPcp) {// or set PCP as default filter if one exists in community
                    $scope.providerFilter = {type:'pcp'};
                } else if ($scope.containsOrg) {// or set Org as default filter if community doesn't have any PCPs or Specialists
                    $scope.providerFilter = {type:'organization'};
                } else {
                    if (modal) modal.dismiss();
                    modal = $uibModal.open({
                        template: modalTemplate,
                        size: 'sm',
                        controller: ['$scope', '$state', '$uibModalInstance', function ($scope, $state, $uibModalInstance) {
                            var $ctrl = this;
                            
                            $scope.title = 'Empty Community';
                            $scope.body = 'This community does not have any members.';
                            
                            $ctrl.cancel = function () {
                                 $uibModalInstance.close();
                            };
                            
                        }],
                        controllerAs: '$ctrl'
                    });
                }
            });
            
            $scope.community = $ctrl.community;
            
            $scope.$watch('providerFilter.type', function(newVal, oldVal){
                if (newVal) {
                    handleFilterChange(newVal);
                }
            });
            
            $scope.$watch('axis', function(newVal, oldVal) {
                if (newVal && $scope.providerFilter.type !== 'organization') {
                    handleFilterChange($scope.providerFilter.type);
                }
            });
            
        }
        
    };
    
    function fetchEndPointData(endpoint, payload, type){
        
        $http
            .post(endpoint, payload, api.options())
            .then(function(response){
                
                var providerPromises = [];
                $ctrl.relations = [];
                $ctrl.providerHash = {};
                
                if (response.data.links && type === 'specialist') {
                    let sudoCommunity = $ctrl.communityNode;
                    let relPromises = response.data.links.map(function(ref) {
                        var rel = new api.Relation({ values: { [$scope.axis]: ref.value } });
                        rel.origin = ref.sourceName;
                        rel.destination = ref.targetName;
                        
                        rel.$rel = ref;
                        
                        if (ref.sourceType == "community") {
                            rel.source = sudoCommunity;
                            return api.GetProvider(ref.targetNpi)
                            .then(function(p) {
                                rel.target = p;
                                return rel;
                            });
                        } else {
                            rel.target = sudoCommunity;
                            return api.GetProvider(ref.sourceNpi)
                            .then(function(p) {
                                rel.source = p;
                                return rel;
                            });
                        }                
                    });
                    
                    $q.all(relPromises).then(function(relations) {
                        $ctrl.relations = relations;
                        $ctrl.reportSankey.data(relations);
                    });
                    
                } else if (response.data.links && type === 'pcp') {
                    let sudoCommunity = $ctrl.communityNode;
                    let relPromises = response.data.links.map(function(ref) {
                        var rel = new api.Relation({ values: { [$scope.axis]: ref.value } });
                        rel.origin = ref.sourceName;
                        rel.destination = ref.targetName;
                        
                        rel.$rel = ref;
                        
                        if (ref.sourceType == "community") {
                            rel.source = sudoCommunity;
                            return [
                                api.GetProvider(ref.sourceNpi)
                                .then(function(p) {
                                    rel.origin = p.toString();
                                    return rel;
                                }),
                                api.GetProvider(ref.targetNpi)
                                .then(function(p) {
                                    rel.target = p;
                                    rel.destination = p.toString();
                                })
                            ]
                        } else {
                            return [
                                api.GetProvider(ref.sourceNpi)
                                .then(function(p) {
                                    rel.source = p;
                                    rel.origin = p.toString();
                                    return rel;
                                }),
                                api.GetProvider(ref.targetNpi)
                                .then(function(p) {
                                    rel.target = p;
                                    rel.destination = p.toString();
                                })
                            ]
                        }
                    });
                    
                    $q.all(relPromises.reduce(function(l, a) { 
                        l.push.apply(l, a);
                        return l;
                    }, [])).then(function(relations) {
                        relations = relations.filter(function(rel) { return rel; });
                        $ctrl.relations = relations;
                        $ctrl.reportSankey.data(relations);
                    });
                    
                } else if (response.data.relations) {
                    let sudoCommunity = $ctrl.communityNode;
                    let relPromises = response.data.relations.map(function(ref) { // Right side only
                        var rel = new api.Relation({ values: ref.values });
                        
                        rel.$rel = ref;
                        
                        if ($scope.community.npis.indexOf(ref.target) > -1) {
                            rel.target = sudoCommunity;
                            return [
                                api.GetProvider(ref.source)
                                .then(function(p) {
                                    rel.source = p;
                                    rel.origin = p.toString();
                                    return rel;
                                }),
                                api.GetProvider(ref.target)
                                .then(function(p) {
                                    rel.destination = p.toString();
                                })
                            ];
                        } else {
                            return [
                                api.GetProvider(ref.target)
                                .then(function(p) {
                                    rel.target = p;
                                    rel.destination = p.toString();
                                    return rel;
                                }),
                                api.GetProvider(ref.source)
                                .then(function(p) {
                                    rel.source = p;
                                    rel.origin = p.toString();
                                })
                            ];
                        }               
                    });
                    
                    $q.all(relPromises.reduce(function(l, a) { 
                        l.push.apply(l, a);
                        return l;
                    }, [])).then(function(relations) {
                        relations = relations.filter(function(rel) { return rel; });
                        $ctrl.relations = relations;
                        $ctrl.reportSankey.data(relations);
                    });
                        
                } else {
                    
                    $ctrl.reportSankey.data([]);//clear any previous sankey and stop loading icon
                    
                    if (modal) modal.dismiss();
                    modal = $uibModal.open({
                        template: modalTemplate,
                        size: 'sm',
                        controller: ['$scope', '$state', '$uibModalInstance', function ($scope, $state, $uibModalInstance) {
                            var $ctrl = this;
                            
                            $scope.title = 'Notice';
                            $scope.body = 'This community subset does not share visits with other providers for chart to render.';
                            
                            $ctrl.cancel = function () {
                                 $uibModalInstance.close();
                            };
                            
                        }],
                        controllerAs: '$ctrl'
                    });
                    
                }
                
            },function(error){
                console.log(error);
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching community specialty flow data.'
                });
            });
            
    }
    
    function handleFilterChange(filterVal) {
        if (filterVal === 'organization') {
            fetchEndPointData($ctrl.endpoints[filterVal], $ctrl.payloads[filterVal], filterVal);
        } else {
            if ($scope.axis === 'same') {//hotfix to substitute, TODO: upstream more comprehensive renaming
                fetchEndPointData(`${$ctrl.endpoints[filterVal]}sameday/`, $ctrl.payloads[filterVal], filterVal);
            } else {
                fetchEndPointData(`${$ctrl.endpoints[filterVal]}${$scope.axis}/`, $ctrl.payloads[filterVal], filterVal);
            }
            
        }
    }
    
    function bindSankeyMouseClick($scope){
        
        $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
            if (datum instanceof api.Provider) {
                var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                $window.open(newTabUrl, '_blank');
            } 
        });
        
    }
    
}];