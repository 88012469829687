import React, { useEffect, useRef, useState } from 'react'
import { phHistogram } from '../../js/histogram';

export function HistogramComponent({ data, histogramOpts }) {
    const histogramContainer = useRef(null);
    const [dimension, setDimension] = useState(null);

    useEffect(() => {
        renderChart();
    }, [data]);

    function renderChart(){
        removeAllChildNodes();
        createHistogram();
    }

    const createHistogram = () => {
        const elem = histogramContainer.current;
        var elemDims = elem.getBoundingClientRect();
        var instance = phHistogram();
        
        instance(elem, {
            data: data.slice(),
            width: elemDims.width,
            height: elemDims.height,
            seedProvider: histogramOpts.seedProvider.npi,
            communityMembers: histogramOpts.communityMembers,
            navToProviderHome: histogramOpts.navToProviderHome
        });
    } 

    window.onresize = debounce(() => renderChart());

    function removeAllChildNodes() {
        const parent = histogramContainer.current;
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }

    function debounce(func, timeout = 500){
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    return (
      <>
      <div id="histogramContainer" ref={histogramContainer} style={{ height : '550px', minWidth : '550px'}}></div>
      </>
    )
  }

