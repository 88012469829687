import React, { useRef, useEffect, useState } from "react";
import ReactDom from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const modal_styles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    zIndex: 1000,
    maxHeight: 'calc(110vh - 210px)',
    overflowY: 'auto'
};

const overlay_style = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .7',
    zIndex: 1000
};

const ConfigModal = ({ reportfields, updateReportsConfig, open, onClose, reset }) => {
    if (!open) return null;
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [forcedColumns, setForcedColumns] = useState([]);
    const [checkedList, setChecked] = useState([]);

    useEffect(() => {
        if (visibleColumns && reportfields.tableConfig.length > 0) {
            const visibleFields = reportfields.tableConfig.filter(fields => fields.header.defaultVisibilty === true);
            const hiddenFields = reportfields.tableConfig.filter(fields => fields.header.defaultVisibilty === false);
            const forcedColumns = reportfields.tableConfig.filter(column => {
                if (column.header.forcedColumn) return column;
            });
            const exportValues = reportfields.tableConfig.map((fields) => {
                const id = fields.header.id;
                const exportobj = fields.header.export;
                return { "exportobj": exportobj, "id": id };
            });
            setChecked(exportValues);
            setVisibleColumns(visibleFields);
            setHiddenColumns(hiddenFields);
            setForcedColumns(forcedColumns);
        }
    }, [reportfields]);

    const moveColumnsHiddenToVisible = (colId, index) => {
        hiddenColumns[index].header.defaultVisibilty = true;
        const movedColumn = hiddenColumns.splice(index, 1);
        setVisibleColumns([...movedColumn, ...visibleColumns]);
        setHiddenColumns([...hiddenColumns]);
    };

    const moveColumnsVisibleToHidden = (colId, index) => {
        if (colId.forcedColumn) return;
        visibleColumns[index].header.defaultVisibilty = false;
        const movedColumn = visibleColumns.splice(index, 1);
        setHiddenColumns([...hiddenColumns, ...movedColumn]);
        setVisibleColumns([...visibleColumns]);
    };

    const moveColumnsUp = (colId, index, visibility) => {
        if (visibility === true && index !== 0) {
            const prev = visibleColumns[index - 1];
            const curr = visibleColumns[index];
            visibleColumns[index] = prev;
            visibleColumns[index - 1] = curr;
            setVisibleColumns([...visibleColumns]);
        }

        if (visibility === false && index !== 0) {
            const prev = hiddenColumns[index - 1];
            const curr = hiddenColumns[index];
            hiddenColumns[index] = prev;
            hiddenColumns[index - 1] = curr;
            setHiddenColumns([...hiddenColumns]);
        }
    };

    const moveColumnsDown = (colId, index, visibility) => {
        if (visibility === true && index < visibleColumns.length) {
            const prev = visibleColumns[index + 1];
            const curr = visibleColumns[index];
            visibleColumns[index] = prev;
            visibleColumns[index + 1] = curr;
            setVisibleColumns([...visibleColumns]);
        }

        if (visibility === false && index < hiddenColumns.length) {
            const prev = hiddenColumns[index + 1];
            const curr = hiddenColumns[index];
            hiddenColumns[index] = prev;
            hiddenColumns[index + 1] = curr;
            setHiddenColumns([...hiddenColumns]);
        }
    };

    const altExportState = (index, visibility) => {
        if (visibility === true) {
            visibleColumns[index].header.export = !visibleColumns[index].header.export;
            setVisibleColumns([...visibleColumns]);
        }

        if (visibility === false) {
            hiddenColumns[index].header.export = !hiddenColumns[index].header.export;
            setHiddenColumns([...hiddenColumns]);
        }
    };

    const saveConfig = () => {
        forcedColumns.forEach((column) => {
            visibleColumns.forEach((vcolumn, index) => {
                if (vcolumn.header.accessor === column.header.accessor) {
                    visibleColumns.splice(index, 1);
                    visibleColumns.splice(column.header.forcedColumn, 0, column);
                }
            });
        });
        return updateReportsConfig([...visibleColumns, ...hiddenColumns]);
    };

    const dragEnd = result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        var draggedField = undefined;
        const newVisibleOrder = Array.from(visibleColumns);
        const newHiddenOrder = Array.from(hiddenColumns);

        switch (source.droppableId) {
            case 'visible-column':
                draggedField = visibleColumns.find(field => field.column.id == draggableId);

                if (destination.droppableId === source.droppableId) {
                    newVisibleOrder.splice(source.index, 1);
                    newVisibleOrder.splice(destination.index, 0, draggedField);
                } else {
                    if (draggedField.header.forcedColumn) return;
                    newVisibleOrder[source.index].header.defaultVisibilty = false;
                    newVisibleOrder.splice(source.index, 1);
                    newHiddenOrder.splice(destination.index, 0, draggedField);
                    setHiddenColumns([...newHiddenOrder]);
                }

                setVisibleColumns([...newVisibleOrder]);
                break;

            case 'hidden-column':
                draggedField = hiddenColumns.find(field => field.column.id == draggableId);

                if (destination.droppableId === source.droppableId) {
                    newHiddenOrder.splice(source.index, 1);
                    newHiddenOrder.splice(destination.index, 0, draggedField);
                } else {
                    newHiddenOrder[source.index].header.defaultVisibilty = true;
                    newHiddenOrder.splice(source.index, 1);
                    newVisibleOrder.splice(destination.index, 0, draggedField);
                    setVisibleColumns([...newVisibleOrder]);
                }

                setHiddenColumns([...newHiddenOrder]);
                break;

            default:
                return;
        }
    };

    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <DragDropContext onDragEnd={dragEnd}>
            <div className="modal-content" style={modal_styles}>
                <div className="modal-header">
                    <h2 className="pull-left" style={{ 'margin': '25px' }}>Manage Report Fields</h2>
                    <div className="pull-right" style={{ 'marginTop': '20px' }}>
                        <button style={{ 'margin': '5px' }} onClick={() => saveConfig()} className="btn btn-success">Save</button>
                        <button style={{ 'margin': '5px' }} className="btn btn-warning" onClick={reset}>Reset</button>
                        <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={onClose}>Cancel</button>
                    </div>
                </div>
                <div className="modal-body container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <Droppable droppableId="hidden-column" renderClone={(provided, snapshot, rubric) => (
                                <div className="list-group-item"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >{hiddenColumns[rubric.source.index].header.content}</div>
                            )}
                            >
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <div className="panel panel-default">
                                            <div className="panel-heading">Available Fields</div>
                                            <ul className="available-fields list-group">
                                                {(hiddenColumns && hiddenColumns.map((field, index) => {
                                                    if (field !== undefined) {
                                                        return <Draggable key={String(field.column.id)} draggableId={String(field.column.id)} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <li className="list-group-item">{field.header.content}<br />
                                                                        <button className="btn btn-default btn-xs" onClick={() => moveColumnsUp(field.header, index, field.header.defaultVisibilty)} style={{ display: index === 0 ? 'none' : 'inline' }} ><i className="fa fa-arrow-up"></i></button>
                                                                        <button className="btn btn-default btn-xs" onClick={() => moveColumnsDown(field.header, index, field.header.defaultVisibilty)} style={{ display: (index === hiddenColumns.length - 1) ? 'none' : 'inline' }} ><i className="fa fa-arrow-down"></i></button>
                                                                        <button className="btn btn-default btn-xs" onClick={() => moveColumnsHiddenToVisible(field.header.id, index)}><i className="fa fa-arrow-right"></i></button>
                                                                        <label className="pull-right">
                                                                            Export {(field.header.accessor !== 'select_all') ? <input type="checkbox" onChange={() => altExportState(index, field.header.defaultVisibilty)} value={field.header.export} checked={field.header.export} /> : null}
                                                                        </label>
                                                                    </li>
                                                                </div>
                                                            )}
                                                        </Draggable>;
                                                    }
                                                })
                                                )}
                                            </ul>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div className="col-md-6">
                            <Droppable droppableId="visible-column" renderClone={(provided, snapshot, rubric) => (
                                <div className="list-group-item"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >{visibleColumns[rubric.source.index].header.content}</div>
                            )}
                            >
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Current Fields
                                            </div>
                                            <ul className="available-fields list-group">
                                                {
                                                    visibleColumns && visibleColumns.map((field, index) => {
                                                        if (field !== undefined && !field.header.exclude) {
                                                            return <Draggable key={String(field.column.id)} draggableId={String(field.column.id)} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        <li className="list-group-item">{field.header.content}<br />
                                                                            <button className="btn btn-default btn-xs" onClick={() => moveColumnsVisibleToHidden(field.header, index)}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                                                                            <button className="btn btn-default btn-xs" onClick={() => moveColumnsUp(field.header, index, field.header.defaultVisibilty)} style={{ display: index === 0 ? 'none' : 'inline' }} ><i className="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                                            <button className="btn btn-default btn-xs" onClick={() => moveColumnsDown(field.header.id, index, field.header.defaultVisibilty)} style={{ display: (index === visibleColumns.length - 1) ? 'none' : 'inline' }} ><i className="fa fa-arrow-down" aria-hidden="true"></i></button>
                                                                            <label className="pull-right">
                                                                                Export {(field.header.accessor !== 'select_all') ? <input type="checkbox" onChange={() => altExportState(index, field.header.defaultVisibilty)} value={field.header.export} checked={field.header.export} /> : null}
                                                                            </label>
                                                                        </li>
                                                                    </div>
                                                                )}
                                                            </Draggable>;
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
            </div>
        </DragDropContext></>, document.getElementById('ph-table-root'));
};

export default ConfigModal;
