import * as d3 from 'd3';

import modalTemplate from './sankeyButterflyModal.html';

export default ['$scope', '$state', 'api', '$q', '$window', 'notify',
    
    function($scope, $state, api, $q, $window, notify) {
        var $ctrl = this;
        
        var modal;
        
        $scope.$on('$destroy', function() {
           if (modal) {
               modal.dismiss();
           }
        });
        
        function bindSankeyMouseClick($scope){
            
            $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
                
                if (datum instanceof api.Community && datum.sourceLinks.length > 0 && datum.targetLinks.length > 0) {
                    var newTabUrl = $state.href('root.app.com.home', {cid: Object.getPrototypeOf(datum).id});
                    $window.open(newTabUrl, '_blank');
                }
                
            });
            
        }
        
        bindSankeyMouseClick($scope);
        
        var options = {
            color: {
                scale: d3.scaleOrdinal(d3.schemeCategory20),
                value: function(d) {
                    return this.scale(d.replace(/TO |FROM /, ''));
                }
            },
            link: {
                hoverClassCheck: function(currentDatum, hoverDatum){
                    if (currentDatum.origin == hoverDatum.origin && currentDatum.destination == hoverDatum.destination) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            toolTip: {
                sourceName: function(d){
                    return d.origin.toString();
                },
                targetName: function(d){
                    return d.destination.toString();
                }
            }
        };
        
        $ctrl.$onInit = function(){
            
            var seedProvider = {};
            if ($ctrl.community){
                seedProvider = $ctrl.community;
            }
            api.CommunityRelations(seedProvider.id).then(function(relations) {
                var promises = relations.map(function(relation) {
                    
                    var relationships = [];
                    
                    if (relation.source instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.source).then(function(provider){
                            return relation.source = provider;
                        }));
                    }
                    
                    if (relation.target instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.target).then(function(provider){
                            return relation.target = provider;
                        }));
                    } 
                    
                    return $q.all(relationships);
                    
                });
                $q.all(promises).then(function() {
                    var seedCommunity = $ctrl.community;
                    
                    var hash = relations.filter(function(relation){// Filter for inter community
                        return !(
                            seedProvider.npis.indexOf(relation.source.npi) > -1 && 
                            seedProvider.npis.indexOf(relation.target.npi) > -1
                        );
                    }).reduce(function(hash, relation) {
                        var category, obj;
                        
                        if (seedProvider.npis.indexOf(relation.source.npi) > -1) { // TO
                            category = 'TO ' + relation.target.taxonomy;
                            
                            if (!hash[category]) {
                                hash[category] = obj = new api.Relation({
                                    source: seedCommunity ? seedCommunity : relation.source,
                                    target: new api.Community({
                                        name: category
                                    }),
                                    values: { shared: 0, unique: 0, sameday: 0}
                                });
                                obj.target.relations = [ relation ];
                            } else {
                                obj = hash[category];
                                obj.target.relations.push(relation);
                            }
                        } else {
                            category = 'FROM ' + relation.source.taxonomy;
                            
                            if (!hash[category]) {
                                hash[category] = obj = new api.Relation({
                                    source: new api.Community({
                                        name: category
                                    }),
                                    target: seedCommunity ? seedCommunity: relation.target,
                                    values: { shared: 0, unique: 0, sameday: 0}
                                });
                                obj.source.relations = [ relation ];
                            } else {
                                obj = hash[category];
                                obj.source.relations.push(relation);
                            }
                        }
                        
                        obj.values.shared += relation.values.shared;
                        obj.values.unique += relation.values.unique;
                        obj.values.sameday += relation.values.sameday;
                        
                        return hash;
                        
                    }, {});//get data and categorize based on specializaton or classification
                    
                    var top10targetsAndSources = Object.keys(hash).map(function(key) {//
                        return hash[key];
                    }).reduce(function(acc, relation){
                        
                        var dir = relation.source.id ? 1 : 0 ;
                        acc[dir].push(relation);
                         
                        return acc;
                        
                    },[[], []])
                    .reduce(function(acc, list) {
                        var top10 = list.sort(function(a, b) { return a.values.shared - b.values.shared }).slice(-10);
                        return acc.concat(top10);
                    }, []);
                    
                    var separatedRelations = top10targetsAndSources.reduce(function(acc, relation){// for Each relation in each community create a new relation
                        if (relation.source.relations) {// left side
                            // if Left side, source = adhoc, target = seed,  origin = adhoc, destination = seed.meber provider
                            var leftRelationsHash = relation.source.relations.reduce(function(innerAcc, member){
                                
                                if (innerAcc[member.source.toString()]) {
                                    innerAcc[member.source.toString()].values.shared += member.values.shared;
                                    innerAcc[member.source.toString()].values.unique += member.values.unique;
                                    innerAcc[member.source.toString()].values.sameday += member.values.sameday;
                                    return innerAcc;
                                } else if (Object.keys(innerAcc).length === 49) {
                                    innerAcc.others = {
                                        __proto__: relation,
                                        origin: '(others)',
                                        destination: relation.target.toString(),
                                        values: {
                                            __proto__: member.values
                                        }
                                    };
                                    return innerAcc;
                                } else if (Object.keys(innerAcc).length === 50) {
                                    innerAcc.others.values.shared += member.values.shared;
                                    innerAcc.others.values.unique += member.values.unique;
                                    innerAcc.others.values.sameday += member.values.sameday;
                                    return innerAcc;
                                } else {
                                    innerAcc[member.source.toString()] = {
                                        __proto__: relation,
                                        origin: member.source.toString(),
                                        destination: relation.target.toString(),
                                        values: {
                                            __proto__: member.values
                                        }
                                    };
                                    return innerAcc;
                                }
                                
                            }, {});
                            var leftRelationsToAdd = Object.keys(leftRelationsHash).map(function(key){
                                return leftRelationsHash[key];
                            });
                            var newLeftAcc = acc.concat(leftRelationsToAdd);
                            return newLeftAcc;
                        } else {// right side
                            // if Right side, source = seed, target = adhoc, origin = seed.member provider, destination = adhoc
                            var rightRelationsHash = relation.target.relations.reduce(function(innerAcc, member){
                                
                                if (innerAcc[member.target.toString()]) {
                                    innerAcc[member.target.toString()].values.shared += member.values.shared;
                                    innerAcc[member.target.toString()].values.unique += member.values.unique;
                                    innerAcc[member.target.toString()].values.sameday += member.values.sameday;
                                    return innerAcc;
                                } else if (Object.keys(innerAcc).length === 49) {
                                    innerAcc.others = {
                                        __proto__: relation,
                                        origin: relation.source.toString(),
                                        destination: '(others)',
                                        values: {
                                            __proto__: member.values
                                        }
                                    };
                                    return innerAcc;
                                } else if (Object.keys(innerAcc).length === 50) {
                                    innerAcc.others.values.shared += member.values.shared;
                                    innerAcc.others.values.unique += member.values.unique;
                                    innerAcc.others.values.sameday += member.values.sameday;
                                    return innerAcc;
                                } else {
                                    innerAcc[member.target.toString()] = {
                                        __proto__: relation,
                                        origin: relation.source.toString(),
                                        destination: member.target.toString(),
                                        values: {
                                            __proto__: member.values
                                        }
                                    };
                                    return innerAcc;
                                }
                                
                            }, {});
                            var rightRelationsToAdd = Object.keys(rightRelationsHash).map(function(key){
                                return rightRelationsHash[key];
                            });
                            var newRightAcc = acc.concat(rightRelationsToAdd);
                            return newRightAcc;
                        }
                    }, []);
                        
                    if ($ctrl.reportSankey) {
                        $ctrl.reportSankey.options(options);
                        $ctrl.reportSankey.data(separatedRelations);
                    } else {
                        // debugger;//brief page, TODO:(ticket#) clean up later 
                        $scope.options = options;
                        // $scope.data = sortedTop25;
                        $scope.data = separatedRelations;
                    }
                    
                });
            },function(error){
                console.log('Error fetching Community Care Journey data: ', error);
                
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Community Care Journey data.',
                    delay: 30000
                });
                
                if ($ctrl.reportSankey) {
                    $ctrl.reportSankey.options(options);
                    $ctrl.reportSankey.data([]);//need to send an empty array, so the sankey loading indicator stops
                }
                
            });
        };
    }    
];