import React, { useState, useEffect, useRef, useContext } from "react";
import { createPortal } from 'react-dom';
import {
    useAuth,
    useRecentlySelected,
} from '../../services/servicesContext';
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    Link,
    NavLink,
    useLocation,
    useNavigate
} from "react-router-dom";
import { usePopper }  from 'react-popper';
import '../popoverMenu/popoverMenu.scss';

import Shepherd from 'shepherd.js';

import { PopoverDetails } from "../popoverDetails/popoverDetails";
import { useOutsideAlerter } from "../popoverMenu/popoverMenu"; 
import { set } from "lodash";
import TrainingManual from "../../assets/TEAM-Platform-Training-Manual.pdf";
import { useCommunityList, useTourManager } from '../../services/servicesContext';
import { CommunityModal } from "../community/modalCommunityComponent";
import eventBus from "../../services/eventBus";
import localStorage from '../../services/localStorageModule';

import './menu.scss';
import config from "../../services/config";

import letterbox from '../../public/ph-letterbox-206x80.png';
import square from '../../public/logo.png';

export function MenuComponent(props) {

    let currentLocation = useLocation();
    let tourManager = useTourManager();
    let previousLocationPathname = useRef(null)
    
    const toggleNavSidebar = props.template.sideBarClosed;
    
    const [recentOrgListExpanded, setRecentOrgListExpanded] = useState(false);
    const [recentPhyListExpanded, setRecentPhyListExpanded] = useState(false);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [currentPhy, setCurrentPhy] = useState(null);
    const [currentCom, setCurrentCom] = useState(null);
    const [recentOrgList, setRecentOrgList] = useState(null);
    const [recentPhyList, setRecentPhyList] = useState(null);
    const [currentComType, setCurrentComType] = useState(null);

    const [orgGraphsExpanded, setOrgGraphsExpanded] = useState(false);
    const [orgReportsExpanded, setOrgReportsExpanded] = useState(false);
    const [phyGraphsExpanded, setPhyGraphsExpanded] = useState(false);
    const [phyReportsExpanded, setPhyReportsExpanded] = useState(false);
    const [phyDashboardsExpanded, setPhyDashboardsExpanded] = useState(false)
    const [comGraphsExpanded, setComGraphsExpanded] = useState(false);
    const [comReportsExpanded, setComReportsExpanded] = useState(false);
    const [comDashboardsExpanded, setComDashboardsExpanded] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState("null");
    const CommunityList = useCommunityList();
    const recentlySelected = useRecentlySelected();
    let navigate = useNavigate(); 
    const routeChange = (redirectTo) =>{ 
        navigate(redirectTo);
    }

    const [openNoTourAvailable, setOpenNoTourAvailable] = useState(false);

    const [show, setShow] = useState(false);

    function handleClose () {
        if (show) {
            setShow(false);
        }
    }

    function handleShow () {
        if (!show) {
            setShow(true);
        }
    }

    const [communityList, setCommunityList] = useState(null);
    const comm = useRef();

    useEffect(() => {
        
        async function handleLocationChange() {
            if (previousLocationPathname.current !== currentLocation.pathname) {
                previousLocationPathname.current = currentLocation.pathname;

                getOrgs();
                getPhys();
                getCommunity();
                if (comm.current && comm.current.source != currentLocation.pathname) {
                    restCommunityListVar();
                }
            }
        }

        handleLocationChange();

        //Fired from start over on Search Component only
        eventBus.on("resetCommunityList", () => {
            restCommunityListVar();
        });

        //Fired from phTable component on load with new shown list
        eventBus.on("updatedCommunityList", () => {
            setCommunityListVar();
        });

        return () => {
            //Cleanup eventBus listeners
            eventBus.remove("updatedCommunityList");
            eventBus.remove("resetCommunityList");
        };
        
    }, [currentLocation]);

    function restCommunityListVar() {
        CommunityList.reset();
        setCommunityList(null);
    }

    function setCommunityListVar () {
        
        let Comm = CommunityList.get();
            if (communityList != Comm) {
                setCommunityList(Comm);
                comm.current = Comm;
            }
    };

    async function getOrgs() {
        await recentlySelected.getRecent({entitytype:'organization', n:11})
        .then(value => {
            let orglist = value;
            setCurrentOrg(orglist.shift());
            setRecentOrgList(orglist);
        })
        .catch(err => {console.log('error recentlySelected.getRecent in getOrgs', err)});
    }

    async function getPhys() {
        await recentlySelected.getRecent({entitytype:'physician', n:11})
        .then(value => {
            let phylist = value;
            setCurrentPhy(phylist.shift());
            setRecentPhyList(phylist);
        })
        .catch(err => {console.log('error recentlySelected.getRecent in getPhys', err)});
    }

    async function getProviders() {
        let orglist = await recentlySelected.getRecent({entitytype:'organization', n:11});
        let phylist = await recentlySelected.getRecent({entitytype:'physician', n:11});

        setCurrentOrg(orglist.shift());
        setRecentOrgList(orglist);

        setCurrentPhy(phylist.shift());
        setRecentPhyList(phylist);
       /* let [orglist, phylist] = await Promise.all([getOrgs(), getPhys()]);

        console.log("organization: " + orglist);
        console.log("physician: " + phylist);*/

        return true;
    }

    async function getCommunity() {
        let auth = await authToken();
        let comList = await recentlySelected.getRecent({entitytype:'community', auth:auth});
        
        setCurrentCom(comList[0]);
        if(comList.length > 0) {
            let comType = await comList[0].type();
            setCurrentComType(comType);
        }
        return true;   
    }

    async function authToken() {
        return await props.api.options().headers.Authorization.slice(7);
    }

    function startTour() {
        props.tour.start();
    };

    function manageCommunity() {
        CommunityList.openCommunityModal();
    };

    function getActiveMenuItem() {
        let path = window.location.pathname.split( '/' );

        if (path.length === 2) {
            setActiveMenuItem(path[1]);
        }
        else if (path.length >= 3) {
            if (path[1] == "forecasting") {
                setActiveMenuItem(path[1] + path[2]);
            }
            else {
                setActiveMenuItem(path[1] + path[3]);
            }
        }
        else {
            setActiveMenuItem(null);
        }
    };

    return <div id="main-menu">
        <div className={`sidebar-header ${toggleNavSidebar ? 'sidebar-header-collapsed':'sidebar-header-open'}`}>
            { toggleNavSidebar 
                ? <span className="navbar-brand navbar-brand-closed"><NavLink to="/"><img src={square} height="22" /></NavLink></span>
                : <span className="navbar-brand navbar-brand-open"><NavLink to="/"><img src={letterbox} height="40" /></NavLink></span>
            }
        </div>
        <div className="sidebar-main text-center">

            <div onClick={() => {
                // setTimeout(()=>{
                    // window.dispatchEvent(new Event('resize'));
                    props.template.toggleNavSidebar();
                // },0);
            }} className="">
                { !toggleNavSidebar && <PopoverDetails providerTooltip="Close menu" placement="right">
                    <i className="fa fa-chevron-circle-right fa-2x main-menu-button" aria-hidden="true"></i>
                </PopoverDetails>}
                { toggleNavSidebar && <PopoverDetails providerTooltip="Open menu" placement="right">
                    <i className="fa fa-chevron-circle-down fa-2x main-menu-button" aria-hidden="true"></i>
                </PopoverDetails>}
            </div>
            <div className="">
                <PopoverDetails providerTooltip="Search for providers" placement="right">
                    <Link to="/search">
                        <i className="fa fa-search fa-2x main-menu-button" aria-hidden="true" style={{color:'#333333'}}></i>
                    </Link>
                </PopoverDetails>
            </div>
            <div id="tourscript-community" className="main-menu-button" onClick={() => {
                if (communityList && communityList.list && communityList.list.length > 0) {handleShow();} else {routeChange("/search")}
            }}>
                <CommunityModal show={show} onClose={handleClose} api={props.api}/>
                { communityList && communityList.list && communityList.list.length > 0 ? <span className="badge community-count">+{communityList.list.length}</span> : ""}
                <PopoverDetails providerTooltip="Create a Community" placement="right">
                    <i className="fa fa-users fa-2x main-menu-button" aria-hidden="true"></i>
                </PopoverDetails>
            </div>

            <div className={`sidebar-nav text-left ${ toggleNavSidebar ? 'hide-menu' : '' }`}>
                <ul id="tourscript-menu">
                    <div className="entity-section">
                        <li>
                            <NavLink to="/search" className={({isActive}) => (isActive ? "active-state" : "")}>Select Provider</NavLink>
                        </li>
                    </div>

                    {/* organization */}
                    <div id="tourscript-organizationdropdown" className="entity-section">
                        <li id="tourscript-organizationrecent">
                            <a onClick={()=>{setRecentOrgListExpanded(!recentOrgListExpanded)}}>
                                Recently Selected Organizations
                                { !recentOrgListExpanded  && <span className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                { recentOrgListExpanded  && <span className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                            </a>
                            <ul className={`nested-list ${recentOrgListExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`} onClick={() => {}/* recentOrg=!recentOrg */}>
                                {recentOrgList && recentOrgList.map(recent => {
                                    return <li key={recent.npi}>
                                        <Link className="word-wrap" to={`/organization/${recent.npi}/home`}>{ recent.name.display }</Link>
                                    </li>;
                                })}
                            </ul>
                        </li>
                        { currentOrg && <div>
                            <li>
                                <Link className="word-wrap" to={`/organization/${currentOrg.npi}/home`}>
                                    <span className="entity-title">{ currentOrg.name.display }</span>
                                </Link>
                            </li>
                            
                            <li className="side-bar-indent">
                                <NavLink to={`/organization/${currentOrg.npi}/brief`} className={({isActive}) => (isActive ? "active-state" : "")}>
                                    Organization Brief
                                </NavLink>
                            </li>
                            
                            <li id="tourscript-organizationgraphs" className="side-bar-indent">
                                <a onClick={()=>{
                                    setOrgGraphsExpanded(!orgGraphsExpanded);
                                    setOrgReportsExpanded(false);
                                    setPhyGraphsExpanded(false);
                                    setPhyReportsExpanded(false);
                                    setComGraphsExpanded(false);
                                    setComReportsExpanded(false);
                                }}>
                                    Graphs - Organization
                                    { !orgGraphsExpanded && <span className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                    { orgGraphsExpanded && <span className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                </a>
                                <ul className={`nested-list ${orgGraphsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/carejourney`} className={({isActive}) => (isActive ? "active-state" : "")}>Care Journey</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/SpecialtyGraph`} className={({isActive}) => (isActive ? "active-state" : "")}>Specialty</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/MarketMakers`} className={({isActive}) => (isActive ? "active-state" : "")}>Market Makers</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/ShareByVolume`} className={({isActive}) => (isActive ? "active-state" : "")}>Share/Volume</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/ReferralMap`} className={({isActive}) => (isActive ? "active-state" : "")}>Referral Map</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/flow`} className={({isActive}) => (isActive ? "active-state" : "")}>Flow</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/trendinggraph`} className={({isActive}) => (isActive ? "active-state" : "")}>Trending</NavLink></li>
                                </ul>
                            </li>

                            <li id="tourscript-organizationreports" className="side-bar-indent">
                                <a onClick={() => {
                                    setOrgGraphsExpanded(false);
                                    setOrgReportsExpanded(!orgReportsExpanded);
                                    setPhyGraphsExpanded(false);
                                    setPhyReportsExpanded(false);
                                    setComGraphsExpanded(false);
                                    setComReportsExpanded(false);
                                }}>
                                    Reports - Organization
                                    { !orgReportsExpanded && <span className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                    { orgReportsExpanded && <span className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                </a>
                                <ul className={`nested-list ${ orgReportsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/SharedVisitsReport`} className={({isActive}) => (isActive ? "active-state" : "")}>Shared Visits</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/bySpecialty`} className={({isActive}) => (isActive ? "active-state" : "")}>by Specialty</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/byOrganization`} className={({isActive}) => (isActive ? "active-state" : "")}>by Organization</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/preacuteshare`} className={({isActive}) => (isActive ? "active-state" : "")}>Pre-Acute Share</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/postacuteshare`} className={({isActive}) => (isActive ? "active-state" : "")}>Post-Acute Share</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/services`} className={({isActive}) => (isActive ? "active-state" : "")}>Services</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/PCPSpecialistVolumes`} className={({isActive}) => (isActive ? "active-state" : "")}>PCP/Specialist Volumes</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/NetworkIntegrity`} className={({isActive}) => (isActive ? "active-state" : "")}>Network Integrity</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/ServiceLine`} className={({isActive}) => (isActive ? "active-state" : "")}>Service Line</NavLink></li>
                                    <li><NavLink to={`/organization/${currentOrg.npi}/marketsharebyzip`} className={({isActive}) => (isActive ? "active-state" : "")}>Market Share by ZIP</NavLink></li>
                                </ul>
                            </li>

                        </div>}
                    </div>
                    
                    {/* physician */}
                    <div id="tourscript-physiciandropdown"className="entity-section">
                        <li id="tourscript-physicianrecent">
                            <a onClick={()=>{setRecentPhyListExpanded(!recentPhyListExpanded)}}>
                                Recently Selected Physicians
                                { !recentPhyListExpanded && <span className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                { recentPhyListExpanded && <span className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                            </a>
                            <ul className={`nested-list ${recentPhyListExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`} onClick={()=>{}}>
                                {recentPhyList && recentPhyList.map(recent => {
                                    return <li key={recent.npi}>
                                        <Link className="word-wrap" to={`/physician/${recent.npi}/home`}>{ recent.name.display }</Link>
                                    </li>;
                                })}
                            </ul>
                        </li>
                        { currentPhy && <div>
                            <li>
                                <Link className="word-wrap" to={`/physician/${currentPhy.npi}/home`}>
                                    <span className="entity-title">{ currentPhy.name.display }</span>
                                </Link>
                            </li>
                            
                            <li className="side-bar-indent">
                                <NavLink to={`/physician/${currentPhy.npi}/brief`} className={({isActive}) => (isActive ? "active-state" : "")}>
                                    Physician Brief
                                </NavLink>
                            </li>
                            
                            <li id="tourscript-physiciangraphs" className="side-bar-indent">
                                <a onClick={() => {
                                    setOrgGraphsExpanded(false);
                                    setOrgReportsExpanded(false);
                                    setPhyGraphsExpanded(!phyGraphsExpanded);
                                    setPhyReportsExpanded(false);
                                    setComGraphsExpanded(false);
                                    setComReportsExpanded(false);
                                }}>
                                    Graphs - Physician
                                    { !phyGraphsExpanded && <span ng-show="!graphsPhy" className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                    { phyGraphsExpanded && <span ng-show="graphsPhy" className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                </a>
                                <ul className={`nested-list ${ phyGraphsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/carejourney`} className={({isActive}) => (isActive ? "active-state" : "")}>Care Journey</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/generalflow`} className={({isActive}) => (isActive ? "active-state" : "")}>General Flow</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/SpecialtyGraph`} className={({isActive}) => (isActive ? "active-state" : "")}>Specialty</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/ReferralMap`} className={({isActive}) => (isActive ? "active-state" : "")}>Referral Map</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/flow`} className={({isActive}) => (isActive ? "active-state" : "")}>Flow</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/clinicaldashboard`} className={({isActive}) => (isActive ? "active-state" : "")}>Clinical Dashboard</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/trendinggraph`} className={({isActive}) => (isActive ? "active-state" : "")}>Trending</NavLink></li>
                                </ul>
                            </li>
                                
                            <li id="tourscript-physicianreports" className="side-bar-indent">
                                <a onClick={() => {
                                    setOrgGraphsExpanded(false);
                                    setOrgReportsExpanded(false);
                                    setPhyGraphsExpanded(false);
                                    setPhyReportsExpanded(!phyReportsExpanded);
                                    setComGraphsExpanded(false);
                                    setComReportsExpanded(false);
                                }}>
                                    Reports - Physician
                                    { !phyReportsExpanded && <span className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                    { phyReportsExpanded && <span className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                </a>
                                <ul className={`nested-list ${ phyReportsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/SharedVisitsReport`} className={({isActive}) => (isActive ? "active-state" : "")}>Shared Visits</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/bySpecialty`} className={({isActive}) => (isActive ? "active-state" : "")}>by Specialty</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/byOrganization`} className={({isActive}) => (isActive ? "active-state" : "")}>by Organization</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/ClinicalReport`} className={({isActive}) => (isActive ? "active-state" : "")}>Clinical Report</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/Services`} className={({isActive}) => (isActive ? "active-state" : "")}>Services</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/summary`} className={({isActive}) => (isActive ? "active-state" : "")}>Summary</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/Prescriptions`} className={({isActive}) => (isActive ? "active-state" : "")}>Prescriptions</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/ServiceLine`} className={({isActive}) => (isActive ? "active-state" : "")}>Service Line Summary</NavLink></li>
                                </ul>
                            </li>
                            
                            <li id="tourscript-physiciangraphs" className="side-bar-indent">
                                <div style={{padding:'10px 10px 10px 0'}}>
                                    <Link
                                        to={`/physician/${currentPhy.npi}/dashboards`}
                                        className={({isActive}) => (isActive ? "active-state" : "")}
                                        style={{display:'inline'}}
                                    >
                                        Benchmarks - Physician
                                    </Link>
                                    <span onClick={()=>{
                                        setOrgGraphsExpanded(false);
                                        setOrgReportsExpanded(false);
                                        setPhyGraphsExpanded(false);
                                        setPhyReportsExpanded(false);
                                        setPhyDashboardsExpanded(!phyDashboardsExpanded);
                                        setComGraphsExpanded(false);
                                        setComReportsExpanded(false);
                                    }}>
                                        { !phyDashboardsExpanded && <span className="pull-right" style={{color:'#3c763d'}}><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                        { phyDashboardsExpanded && <span className="pull-right" style={{color:'#3c763d'}} ><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                    </span>
                                </div>
                                <ul className={`nested-list ${ phyDashboardsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/geobenchmark`} className={({isActive}) => (isActive ? "active-state" : "")}>Volume of Procedures by Geography</NavLink></li>
                                    <li><NavLink to={`/physician/${currentPhy.npi}/marketbenchmarks`} className={({isActive}) => (isActive ? "active-state" : "")}>CBSA Market Volumes</NavLink></li>
                                </ul>
                            </li>
                            
                        </div>}
                    </div>

                    {/* community */}
                    <div className="entity-section">
                        <li id="tourscript-communities">
                            <NavLink to="/groups" className={({isActive}) => (isActive ? "active-state" : "")}>Communities</NavLink>
                        </li>
                        { currentCom && <div>
                            <li>
                                <NavLink className={({isActive}) => (isActive ? "active-state" : "")} to={`/community/${currentCom.id}/home`}>
                                    <span className="entity-title">{ currentCom.name }</span>
                                </NavLink>
                            </li>

                            <li className="side-bar-indent" id="tourscript-communitygraphs">
                                <a onClick={()=>{
                                    setOrgGraphsExpanded(false);
                                    setOrgReportsExpanded(false);
                                    setPhyGraphsExpanded(false);
                                    setPhyReportsExpanded(false);
                                    setPhyDashboardsExpanded(false);
                                    setComGraphsExpanded(!comGraphsExpanded);
                                    setComReportsExpanded(false);
                                    setComDashboardsExpanded(false);
                                }}>
                                    Graphs - Community
                                    { !comGraphsExpanded && <span className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                    { comGraphsExpanded && <span className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                </a>
                                <ul className={`nested-list ${ comGraphsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/community/${currentCom.id}/carejourney`} className={({isActive}) => (isActive ? "active-state" : "")}>Care Journey</NavLink></li>
                                    <li><NavLink to={`/community/${currentCom.id}/SpecialtyGraph`} className={({isActive}) => (isActive ? "active-state" : "")}>Specialty</NavLink></li>
                                    <li><NavLink to={`/community/${currentCom.id}/flow`} className={({isActive}) => (isActive ? "active-state" : "")}>Flow</NavLink></li>
                                    { currentComType == 1 && <li><NavLink to={`/community/${currentCom.id}/generalflow`} className={({isActive}) => (isActive ? "active-state" : "")}>General Flow</NavLink></li>}
                                    { currentComType == 2 && <li><NavLink to={`/community/${currentCom.id}/MarketMakers`} className={({isActive}) => (isActive ? "active-state" : "")}>Market Makers</NavLink></li>}
                                    <li><NavLink to={`/community/${currentCom.id}/trendinggraph`} className={({isActive}) => (isActive ? "active-state" : "")}>Trending</NavLink></li>
                                </ul>
                            </li>

                            <li className="side-bar-indent" id="tourscript-communityreports">
                                <a onClick={()=>{
                                    setOrgGraphsExpanded(false);
                                    setOrgReportsExpanded(false);
                                    setPhyGraphsExpanded(false);
                                    setPhyReportsExpanded(false);
                                    setPhyDashboardsExpanded(false);
                                    setComGraphsExpanded(false);
                                    setComReportsExpanded(!comReportsExpanded);
                                    setComDashboardsExpanded(false);
                                }}>
                                    Reports - Community
                                    { !comReportsExpanded && <span ng-show="!reportsCom" className="pull-right"><i className="fa fa-angle-left" aria-hidden="true"></i></span>}
                                    { comReportsExpanded && <span ng-show="reportsCom" className="pull-right"><i className="fa fa-angle-down" aria-hidden="true"></i></span>}
                                </a>
                                <ul className={`nested-list ${ comReportsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    <li><NavLink to={`/community/${currentCom.id}/SharedVisitsReport`} className={({isActive}) => (isActive ? "active-state" : "")}>Shared Visits</NavLink></li>
                                    <li><NavLink to={`/community/${currentCom.id}/bySpecialty`} className={({isActive}) => (isActive ? "active-state" : "")}>by Specialty</NavLink></li>
                                    <li><NavLink to={`/community/${currentCom.id}/byOrganization`} className={({isActive}) => (isActive ? "active-state" : "")}>by Organization</NavLink></li>
                                    <li><NavLink to={`/community/${currentCom.id}/ClinicalReport`} className={({isActive}) => (isActive ? "active-state" : "")}>Clinical Report</NavLink></li>
                                </ul>
                            </li>

                            {currentComType == 1 && <li className="side-bar-indent" id="tourscript-communitybenchmarks">
                                <div style={{padding:'10px 10px 10px 0'}}>
                                    <NavLink
                                        to={`/community/${currentCom.id}/dashboards`}
                                        className={({isActive}) => (isActive ? "active-state" : "")}
                                        style={{display:'inline'}}
                                    >
                                        Benchmarks - Community
                                    </NavLink>
                                    <span className="pull-right" style={{color:'#3c763d'}} onClick={()=>{
                                        setOrgGraphsExpanded(false);
                                        setOrgReportsExpanded(false);
                                        setPhyGraphsExpanded(false);
                                        setPhyReportsExpanded(false);
                                        setPhyDashboardsExpanded(false);
                                        setComGraphsExpanded(false);
                                        setComReportsExpanded(false);
                                        setComDashboardsExpanded(!comDashboardsExpanded);
                                    }}>
                                        <i className={`fa ${comDashboardsExpanded ? 'fa-angle-down' : 'fa-angle-left'}`} aria-hidden="true"></i>
                                    </span>
                                </div>
                                
                                <ul className={`nested-list ${ comDashboardsExpanded ? 'accordian-expanded' : 'accordian-collapsed' }`}>
                                    { currentComType == 1 && <li><NavLink to={`/community/${currentCom.id}/marketbenchmarks`} className={({isActive}) => (isActive ? "active-state" : "")}>Serviceline Benchmark</NavLink></li>}
                                </ul>
                            </li>}

                        </div>}

                    </div>

                    {/* dashboards */}
                    <div className="entity-section">
                        <li id="tourscript-dashboards"><NavLink to="/dashboards" className={({isActive}) => (isActive ? "active-state" : "")}>Dashboards</NavLink></li>
                    </div>
                    
                    {/* forecasting */}
                    <div className="entity-section">
                        <li id="tourscript-forecasting"><NavLink to="/forecasting/geo" className={({isActive}) => (isActive ? "active-state" : "")}>Forecasting</NavLink></li>
                    </div>

                </ul>
            </div>
            <div className="main-menu-buttons-container">
                <div id="tourscript-tour" className="main-menu-button" onClick={()=>{
                    if (currentLocation.pathname === '/') {
                        tourManager.executeTour([
                            {
                                id: 'landing1',
                                title: 'Welcome - 1/3',
                                text: 'Welcome to the TEAM platform!',
                            },
                            {
                                id: 'landing2',
                                attachTo: { element: '.sidebar-nav.text-left', on: 'right'},
                                title: 'Welcome - 2/3',
                                text: `This is the main menu. Here, you'll select providers and analyze reports and graphs.`,
                            },
                            {
                                id: 'landing3',
                                attachTo: { element: '#tourscript-tour', on: 'right'},
                                title: 'Welcome - 3/3',
                                text: `If you need help throughout the site, click the <span class='glyphicon glyphicon-question-sign' aria-hidden='true'></span> button`,
                            }
                        ]);
                    } else if (tourManager.hasTour()) {
                        tourManager.startTour();
                    } else {
                        setOpenNoTourAvailable(true)
                    }
                }} uib-tooltip="Tour the page" tooltip-placement="right" tooltip-append-to-body="true">
                    <PopoverDetails providerTooltip="Tour the page" placement="right">    
                    <i className="fa fa-question-circle fa-2x" aria-hidden="true"></i>
                    </PopoverDetails>
                </div>
                { openNoTourAvailable && <NoTourModalComponent close={()=>{setOpenNoTourAvailable(false)}} /> }
                <div className="main-menu-button">
                    <ProfileMenuPopover
                        config={props.config}
                        authToken={authToken}
                        auth={props.auth}
                    >
                        <i className="fa fa-user-circle fa-2x" aria-hidden="true"></i>
                    </ProfileMenuPopover>
                </div>
            </div>
        </div>
    </div>;
}

// const tourButtons = {
//     next: {text:'Next', classes: 'shepherd-button-primary', action:tourManager.tour.next},
//     back: {text:'Prev', classes: 'shepherd-button-secondary', action: tourManager.tour.back}
// };
// tourManager.tour.addSteps([
//     {
//         id: 'landing1',
//         title: 'Welcome - 1/3',
//         text: 'Welcome to the TEAM platform!',
//         buttons: [ tourButtons.next ]
//     },
//     {
//         id: 'landing2',
//         attachTo: { element: '.sidebar-nav.text-left', on: 'right'},
//         title: 'Welcome - 2/3',
//         text: `This is the main menu. Here, you'll select providers and analyze reports and graphs.`,
//         buttons: [ tourButtons.back, tourButtons.next ]
//     },
//     {
//         id: 'landing3',
//         attachTo: { element: '#tourscript-tour', on: 'right'},
//         title: 'Welcome - 3/3',
//         text: `If you need help throughout the site, click the <span class='glyphicon glyphicon-question-sign' aria-hidden='true'></span> button`,
//         buttons: [ tourButtons.back, ]
//     }
// ]);
// tourManager.tour.start();
// ['landing1','landing2','landing3'].forEach(id => tourManager.tour.removeStep(id));

function ProfileMenuPopover(props) {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [showPopperElem, setShowPopperElem] = useState(false);
    const [refPopperElem, seRefPopperElem] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right-end',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }, {name: 'offset', options: {offset: [0,5]}}],
    });

    useOutsideAlerter(popperElement, (val) => {
        setShowPopperElem(val);
        if (props.isOpenCallback) {
            props.isOpenCallback(val);
        }
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        marginBottom: 0,
        zIndex: 1000
    };

    const toggleShowPopperEl = (e) => {
        if (refPopperElem) {
            refPopperElem.click()
        }
        if (props.isOpenCallback) {
            props.isOpenCallback(!showPopperElem);
        }

        seRefPopperElem(e.currentTarget);

        setShowPopperElem(!showPopperElem);
        
    }

    return (<>
        <span
            ref={setReferenceElement}
            onClick={(e) => toggleShowPopperEl(e)}
        >{ props.children }</span>
        { showPopperElem
            ? <div ref={setPopperElement} id="tooltip" className="profile-menu-popover panel panel-default popperEl" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <ul
                    onClick={()=>{
                    }}
                    style={{listStyleType: 'none', padding: '10px 0', margin: 0, whiteSpace: 'nowrap', textAlign:'left'}}
                >
                    <li style={{padding: '3px 10px'}}><Link to="/profile"><i className="fa fa-fw fa-user"></i> User Profile</Link></li>
                    <li style={{padding: '3px 10px'}}><a href={TrainingManual} target="_blank" rel="noreferrer"><i className="fa fa-book" aria-hidden="true"></i> Manual</a></li>
                    <li style={{padding: '3px 10px'}}><Link to="/faq"><i className="fa fa-fw fa-question"></i> FAQ</Link></li>
                    {/* <li style={{padding: '3px 10px'}}><a id="issue-collector" className="pointer"><i className="fa fa-fw fa-comments-o"></i> Feedback</a></li> */}
                    <li style={{padding: '3px 10px'}} ><a className="pointer" onClick={() => {props.auth.cognitoLogout();}}><i className="fa fa-sign-out fa-fw"></i> Logout</a></li>
                </ul>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div>
            : null
        }
    </>);
}

function NoTourModalComponent(props) {
    
    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        maxWidth: '400px',
        overflowY: 'auto',
        width: '100%'
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .5)',
         zIndex: 1000
    };
    
    return createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header" style={{background: '#e6e6e6', padding: '7px'}}>
                <h4 className="modal-title" id="modal-title" style={{marginLeft: '8px'}}>No Tour</h4>
            </div>
            <div className="modal-body">
                <p style={{margin: 0}}>There is no tour for this page yet.</p>
            </div>
            <div className="modal-footer" style={{padding: '7px', margin: 0}}>
                <button style={{ 'margin': '5px' }} className="btn btn-sm primary-product-button" onClick={props.close}>Close</button>
            </div>
        </div>
    </>, document.body);
}
