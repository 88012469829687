import React, {useState, useEffect, useRef} from "react";
import ReactDom from 'react-dom';
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import * as d3 from 'd3';
import * as parseD from 'd-path-parser';
import merge from 'lodash/merge';

import { useApi, usePic, useTemplate } from '../../services/servicesContext';

import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';
import { useCommunityList, useTourManager, useLogging } from '../../services/servicesContext';
import { CommunityModal } from "../../components/community/modalCommunityComponent";

export function CarepathwaysComponent() {
    
    const [ loading, setLoading ] = useState(true);
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const entitytype = (npis.entitytype == 1 ? "physician" : (npis.entitytype == 2 ? "organization" : "community"));
    const api = useApi();
    const pic = usePic();
    const template = useTemplate();
    const tourManager = useTourManager();
    const logging = useLogging();
    const [ relations, setRelations ] = useState(null);
    const [ options, setOptions ] = useState(null);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalData, setModalData ] = useState(null);
    const [ modalTitle, setModalTitle ] = useState(null);
    const [ axis, setAxis ] = useState(null);
    const [ openDropdown, setOpenDropdown ] = useState(null);

    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        sameday: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };

    const handleDropdownClick = function(col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
    };
    

    const toggleAxis = function (property) {
        let optionsClone = { ...options };
        optionsClone.link = {
            value: function(d) {
                return d.values[property];
            }
        };

        merge(optionsClone.toolTip, {
            units: mapping[property].units,
            value: function(d) {
                return d.values[property];
            }
        });

        optionsClone.node.sortAxis = mapping[property].sortAxis;

        setAxis(property);
        setOptions(optionsClone);
        setOpenDropdown(null);
    };

    let navigate = useNavigate(); 
    const routeChange = (redirectTo) =>{ 
        navigate(redirectTo);
    }

    const CommunityList = useCommunityList();

    const graphOptions = {
        color: {
            scale: d3.scaleOrdinal(d3.schemeCategory20),
            value: function(d) {
                return this.scale(d.replace(/TO |FROM /, ''));
            }
        },
        node: {
            colorValue: function(d) {
                return d.id;
            },
            label: function(d) { return d.toString(); },
            handleClick: function(event, datum) {

                var links;
                var drillDownTitle = '';
                var overflow = {};

                links = datum.relations;
                
                overflow.source = datum.sourceLinks.length ? new api.Community({ name: '(others)' }) : Object.getPrototypeOf(datum.targetLinks[0].source);
                overflow.target = datum.targetLinks.length ? new api.Community({ name: '(others)' }) : Object.getPrototypeOf(datum.sourceLinks[0].target);

                if (datum.sourceLinks.length > 0 && datum.targetLinks.length > 0) {// is center node
                    window.open(`${entitytype}/${datum.npi}/home`, '_blank');
                    return null;
                } else if (datum.sourceLinks.length > 0) {// is left node
                    drillDownTitle = datum.sourceLinks[0].source.toString()  + ' → ' + npis.toString();
                } else if (datum.targetLinks.length > 0) {// is right node
                    drillDownTitle = npis.toString() + ' → ' + datum.targetLinks[0].target.toString();
                } else {
                    console.log('incorrect data found from node click', datum);
                    return null;
                }
                
                var linksCopy = links.slice(0).sort(function(a,b){
                    return b.valueOf() - a.valueOf();
                });
                
                var topLinks = linksCopy.slice(0, 25);
                
                buildCommunityList(links, topLinks, datum);

                if (linksCopy.length > 25) {//create '(others)' community rollup if more than 25
            
                    topLinks.push(linksCopy.slice(25).reduce(function(relation, link){
                        
                        relation.values.shared += link.values.shared;
                        relation.values.unique += link.values.unique;
                        relation.values.sameday += link.values.sameday;
                        return relation;
                        
                    },new api.Relation({
                        source: overflow.source,
                        target: overflow.target,
                        values: {
                            sameday: 0,
                            shared: 0,
                            unique: 0
                        }
                        
                    })));
                }

                setModalData(topLinks);
                setModalTitle(drillDownTitle);
                setModalOpen(true);
            }
        },
        toolTip: {
            units: 'Shared Visits',
            value: function (d) {
                return d.values.shared;
            }
        }
    };

    function buildCommunityList(links, topLinks, datum) {
        var direction;
        if (datum.sourceLinks.length > 0) {
            direction = 'source';
        } else {
            direction = 'target';
        }
        
        var list = topLinks.map(function(link) { return link[direction].npi; });
        
        var altList;
        if (links > topLinks) {
            altList = links.map(function(link) { return link[direction].npi; });
        }
        
        CommunityList.temporaryList = true;
        CommunityList.update({listVal: list, altListVal: altList});
    }

    const closeModal = (currentState) => {
        setModalOpen(currentState);
    };

    useEffect(() => {

        tourManager.createTour([
            {
                id: 1,
                title: 'Care Journey - 1/7',
                text: ( entitytype === 'physician' )
                    ? "The purpose of the Physician Care Journey graph is to understand where patients are treated before seeing the Provider and after seeing the provider."
                    : "The purpose of the Organization Care Journey graph is to understand where patients are treated before seeing a specific provider, then who they see after seeing the provider."
                ,
            },
            {
                id: 2,
                attachTo: { element: 'g.nodes g.node rect:not([data-side])', on: 'right' },
                title: 'Care Journey - 2/7',
                text: ( entitytype === 'physician' )
                    ? `We start with our Provider, ${npis.name.display} displayed as the middle node.`
                    : `Starting with the organization, ${npis.name.display} displayed as the middle node.`
                ,
            },
            {
                id: 3,
                attachTo: { element: 'g.nodes g.node rect[data-side="left"]', on: 'right' },
                title: 'Care Journey - 3/7',
                text: ( entitytype === 'physician')
                    ? `Sorted by Provider type and ordered by patient volume, the nodes on the left show what type of Provider saw patients before ${npis.name.display}. The thicker the gray line, the more shared visits ${npis.name.display} has with that type of provider.`
                    : `Sorted by Provider type and ordered by patient volume, the nodes on the left show what type of Provider saw patients before ${npis.name.display}. The thicker the gray line, the more shared visits the Provider has with that type of provider.`
                ,
            },
            {
                id: 4,
                attachTo: { element: 'g.nodes g.node rect[data-side="right"]', on: 'left' },
                title: 'Care Journey - 4/7',
                text: `Sorted by Provider type and ordered by patient volume, the nodes on the right show what type of Provider saw patients after ${npis.name.display}.`,
            },
            {
                id: 5,
                attachTo: { element: 'div.sankeyTooltip', on: 'bottom'},
                title: 'Care Journey - 5/7',
                text: "Hovering over the gray lines reveals the quantity of shared visits.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var pathD= parseD(elem.attributes.d.value);
                        var avgX = (pathD[0].end.x + pathD[1].end.x)/2;
                        var avgY = (pathD[0].end.y + pathD[1].end.y)/2;
                        var moveEvent = new Event('mousemove');
                        moveEvent.pageX = avgX;
                        moveEvent.pageY = avgY;
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                        
                    });
                },
            },
            {
                id: 6,
                attachTo: { element: 'g.nodes g.node rect[data-side]', on: 'bottom' },
                title: 'Care Journey 6/7',
                text: `Clicking on a "node" (color bar to the left or right)...`,
                beforeShowPromise: function () {
                    return new Promise((resolve, reject) => {
                        
                        var elem = document.querySelector('path.link:nth-child(1)');
                        var moveEvent = new Event('mouseleave');
                        elem.parentElement.dispatchEvent(moveEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
            },
            {
                id: 7,
                attachTo: { element: 'div.modal-dialog', on: 'bottom' },
                title: 'Care Journey - 7/7',
                text: "... will expand to reveal which providers make up that grouping. Also clicking the icon in the bottom left will quickly add the providers displayed to a Community.",
                beforeShowPromise: function() {
                    return new Promise((resolve, reject) => {
                        
                        var nodeToClick = document.querySelector('g.nodes g.node rect[data-side]').parentElement;
                        var clickEvent = new Event('click');
                        nodeToClick.dispatchEvent(clickEvent);
                        
                        setTimeout(()=>{
                            resolve();
                        });
                    });
                },
            }
        ]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.graphs.sankey.carepathways`
        });

        async function init() {
            try {
                setLoading(true);
                var seedProvider = npis;

                const relations = await api.Relations(seedProvider.npi);

                if (relations.length === 0) {
                    console.log('No Provider Care Pathways data found: ', relations);
                }

                var promises = relations.map(function(relation) {
                    
                    var relationships = [];
                    
                    if (relation.source instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.source).then(function(provider){
                            return relation.source = provider;
                        }));
                    }
                    
                    if (relation.target instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.target).then(function(provider){
                            return relation.target = provider;
                        }));
                    } 
                    
                    return Promise.all(relationships);
                    
                });

                await Promise.all(promises);

                var hash = relations.reduce(function(hash, relation) {
                    var category, source, target, obj;
                    
                    if (relation.source.npi == seedProvider.npi) { // TO
                        category = 'TO ' + relation.target.taxonomy;
                        
                        if (!hash[category]) {
                            hash[category] = obj = new api.Relation({
                                source: relation.source,
                                target: new api.Community({
                                    name: category,
                                    side: 'right',
                                }),
                                values: { shared: 0, unique: 0, sameday: 0}
                            });
                            obj.target.relations = [ relation ];
                        } else {
                            obj = hash[category];
                            obj.target.relations.push(relation);
                        }
                    } else {
                        category = 'FROM ' + relation.source.taxonomy;
                        
                        if (!hash[category]) {
                            hash[category] = obj = new api.Relation({
                                source: new api.Community({
                                    name: category,
                                    side: 'left'
                                }),
                                target: relation.target,
                                values: { shared: 0, unique: 0, sameday: 0}
                            });
                            obj.source.relations = [ relation ];
                        } else {
                            obj = hash[category];
                            obj.source.relations.push(relation);
                        }
                    }
                    
                    obj.values.shared += relation.values.shared;
                    obj.values.unique += relation.values.unique;
                    obj.values.sameday += relation.values.sameday;
                    
                    return hash;
                    
                }, {});//get data and categorize based on specializaton or classification

                var sortedTop25 = Object.keys(hash).map(function(key) {// sort and get top 25
                    return hash[key];
                }).sort(function(a, b) {
                    return b.values.shared - a.values.shared;
                }).slice(0, 25);

                setAxis('shared');
                setOptions(graphOptions);
                setRelations(sortedTop25);
                setLoading(false);

            } catch(err) {
                setLoading(false);
                console.error('error requesting relations', err);
            }
        }

        init();

        return () => {
            tourManager.clearTour();
        };
    }, [npis]);
    
    return (<>
        { (relations && options && pic && template && !loading)
            ? <div className="sankey-report-container">
                <div className="col-sm-4 secondary-button-container" style={{ padding: 0, marginRight: 0, marginLeft: 'auto'}}>
                    <div className={`provider-axis-dropdown col-md-6 axis-toggle ${openDropdown === 'axis' ? 'open' : ''}`} style={{ padding: 0 }}>
                        <button id="values-axis" type="button" onClick={() => { handleDropdownClick('axis') }} className="btn btn-default btn-md btn-block">{mapping[axis].units} <span className="caret"></span></button>
                        <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis" style={{ width: '100%'}}>
                            <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                <label>
                                    <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => { toggleAxis('shared') }}></input>
                                    {mapping.shared.units}
                                </label>
                            </li>
                            <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                <label>
                                    <input type="radio" checked={axis === 'sameday'} value="sameday" onChange={() => { toggleAxis('sameday') }}></input>
                                    {mapping.sameday.units}
                                </label>
                            </li>
                            <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                <label>
                                    <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => { toggleAxis('unique') }}></input>
                                    {mapping.unique.units}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 reset" style={{ padding: 0 }}>
                        <button type="button" onClick={() => { toggleAxis('shared') }} className="btn btn-default btn-md btn-block">Reset</button>
                    </div>
                </div>
                <CarepathwaysModalComponent
                    open={modalOpen}
                    onClose={() => closeModal(!modalOpen)}
                    modalData={modalData}
                    CommunityList={CommunityList}
                    opts={options}
                    pic={pic}
                    template={template}
                    title={modalTitle}
                    api={api}
                />
                <PhSankeyComponent
                    data={relations}
                    opts={options}
                    pic={pic}
                    template={template}
                    entitytype="physician"
                />
            </div>
            : <div className="loading-lg"></div>
        }
    </>);
}

function CarepathwaysModalComponent(props) {

    const modalOptions = {
        color: props.opts.color,
        link: props.opts.link,
        node: {
            handleClick: (e, d) => {
                if (d?.entitytype == 1) {//physician
                    window.open((`physician/${d.id}/home`), '_blank');
                }
                else if (d?.entitytype == 2) {//org
                    window.open((`organization/${d.id}/home`), '_blank');
                }
                else if (d?.id && d?.id !== '(others)') {//community
                    window.open((`community/${d.id}/home`), '_blank');
                } else {
                    return;// 'others' rollup
                }
            }
        },
        toolTip: props.opts.toolTip
    }
    const [show, setShow] = useState(false);

    function handleClose () {
        if (show) {
            setShow(false);
        }
    }

    function handleShow () {
        if (!show) {
            setShow(true);
        }
    }

    var Comm = props.CommunityList.get();

    if (!props?.open) return null

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        minWidth: '960px',
        overflowY: 'auto',
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
    };

    const createCommunity = function () {
        props.CommunityList.openCommunityModal();
    };
    
    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={props.onClose}>×</button>
                <h3 className="modal-title" id="modal-title">{props.title}</h3>
            </div>
            <div className="modal-body sankey-modal-body" style={{margin: 'auto'}}>
                { (props.open && props.modalData) ? <PhSankeyComponent
                    data={props.modalData}
                    opts={modalOptions}
                    pic={props.pic}
                    template={props.template}
                /> : null}
            </div>
            <div className="modal-footer">
                <CommunityModal show={show} onClose={handleClose} api={props.api}/>
                <div className="modal-create-community pointer" onClick={() => { if (Comm.list.length > 0) {handleShow()} else {routeChange("/search")}} }>
                    <span className="badge modal-community-count">{"+"+Comm.list.length}</span>
                    <a><i className="fa fa-users fa-2x" aria-hidden="true"></i></a>
                </div>
                <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={props.onClose}>Close</button>
            </div>
        </div>
    </>, document.body);
}