import React from 'react';
export function CellComponent({ rowDataObject, currentVisibleColumn, cellOptions, updateCommunityList, api }) {
  var cellData = {}

  try {
      if (cellOptions.nested && rowDataObject[cellOptions.nested] !== null && cellOptions.type === "Array") {
          const cellDataArray = rowDataObject[cellOptions.nested].map((nestedObject) => nestedObject[currentVisibleColumn]);
          cellData = cellDataArray.filter( cellData => (cellData !== ""));
      } else { 
          cellData = (rowDataObject[currentVisibleColumn] === undefined) ? null : rowDataObject[currentVisibleColumn]
      }
  } catch (error) {
    console.error(error)
  }

 const renderComponent = () => {
   try {
     if (Object.keys(cellOptions).length === 0 || !cellOptions.template) return

        if (cellOptions.type === "checkbox") {
            return <div style={{ 'textAlign' : 'center' }} ><input type='checkbox' value={rowDataObject['_checked']} checked={rowDataObject['_checked']} onChange={(event) => updateCommunityList(event, rowDataObject)} /></div>
        } 

      return cellOptions.template(cellData, rowDataObject || {}, api || null) 


    } catch (error) {
      console.error(error)
    }
  }
  
  return (
    <div>
      {renderComponent()}
    </div>
  )
}
