import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$uibModal', 'recentlySelected', '$rootScope', 'template', 'api', 'notify', 'leafletData', '$localStorage', 'tour', '$timeout',
function($scope, $uibModal, recentlySelected, $rootScope, template, api, notify, leafletData, $localstorage, tour, $timeout) {
    var $ctrl = this;
    var modal;
    $scope.originalProviderMembers = {};
    
    $scope.$on('$destroy', function() {
        if (modal) {
            modal.dismiss();
        }
    });
    
    $ctrl.$onInit = function() {
        
        tour.setScript(tourConfig);
        
        $scope.community = $ctrl.community;
        
        $scope.reportOptions = { 
            report: { 
                name: $scope.community.name
            },
            sort: {
                column: 'name',
                reverse: false
            },
            communityList: {
                accessor: function(datum) { return datum.npi; }
            }
        };
        
        $ctrl.updateMembers($ctrl.community);
    };
    
    $ctrl.updateMembers = function(community) {
        community.ResolveMembers()
        .then(function(members) {
            $scope.members = members.map(function(member) {
                $scope.originalProviderMembers[member.npi] = member;
                if (!member._failed) {
                    return {
                        'npi': member.npi,
                        'name': member.name.display,
                        'specialty': member.taxonomy.specialization ? member.taxonomy.classification + ' (' + member.taxonomy.specialization + ')' : member.taxonomy.classification,
                        'address': member.location.address.join(', '),
                        'city': member.location.city,
                        'state': member.location.state,
                        'zip': member.location.postal_code
                    };
                } else {
                    return {
                        'npi': member.npi,
                        'name': 'provider not found',
                        'specialty': '',
                        'address': '',
                        'city': '',
                        'state': '',
                        'zip': ''
                    };
                }
                
            });
            
            $scope.markers = createAndBoundMarkers(members);
        });
    };
    
    function createAndBoundMarkers(members) {
        var markersArray = [];
        $scope.markers = {};
        // new date for use in unique group name
        var date = new Date();
        members.forEach(function(member) {
            if (!member._failed) {
                $scope.markers[member.npi] = {
                    group: 'providers' + date,
                    lat: member.location.coordinates.lat,
                    lng: member.location.coordinates.lng,
                    message: member.name.display,
                    focus: true,
                    draggable: false
                };
                markersArray.push([member.location.coordinates.lat, member.location.coordinates.lng]);
            }
        });
        
        leafletData.getMap().then(function(map) {
            map.fitBounds(markersArray);
        });
        
        return $scope.markers;
    }
    
    // ui-leaflet
    angular.extend($scope, {
        markers: {},
        layers: {
            baselayers: {
                'Grayscale': {
                    name: 'Grayscale',
                    url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png',
                    type: 'xyz',
                    options: {
                        attribution: 'Map tiles by <a href="//stamen.com" target="_blank">Stamen Design</a>, under <a href="//creativecommons.org/licenses/by/3.0" target="_blank">CC BY 3.0</a>. Data by <a href="//openstreetmap.org" target="_blank">OpenStreetMap</a>, under <a href="//creativecommons.org/licenses/by-sa/3.0" target="_blank">CC BY SA</a>',
                        subdomains: ['a','b','c','d']
                    }
                },
                'OpenStreetMap': {
                    name: 'OpenStreetMap',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    type: 'xyz'
                }
            }
        }
    });
    
    var preferredBaseLayer = $localstorage.get('map.baseLayerPreference');
    
    leafletData.getMap().then(function(map) {
        leafletData.getLayers().then(function (layers) {
            map.addLayer(layers.baselayers[preferredBaseLayer] || layers.baselayers['Grayscale']);
        });
    });
    
    $scope.$on('leafletDirectiveMap.baselayerchange', function(event, obj) {
        $localstorage.set('map.baseLayerPreference', obj.leafletEvent.name);
    });
    
    
    // community actions

    $ctrl.edit = function() {
        $ctrl.community.EditName()
        .then(function(updatedCommunity) {
            $rootScope.$broadcast('recentlySelectedChange', 'community');
            template.header(updatedCommunity.name);
        });
    };
    
    $ctrl.addBulk = function() {
        modal = $uibModal.open({
            templateUrl: 'modalAddBulkCommunity.html',
            controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                var $modalCtrl = this;

                $scope.editableCommunity = angular.copy($ctrl.community);
                
                $modalCtrl.addBulk = function() {
                    
                    $scope.editableCommunity.newNpis = $scope.editableCommunity.newNpis.split(/[ ,]+/);
                    
                    $scope.editableCommunity.npis = $scope.editableCommunity.npis.concat($scope.editableCommunity.newNpis);
                    
                    $scope.editableCommunity.Update()
                    .then(function(updatedCommunity) {
                        $ctrl.updateMembers(updatedCommunity);
                        $uibModalInstance.close();
                    }, function(err) {
                        console.log('error', err);
                    });
                };
            
                $modalCtrl.close = $uibModalInstance.dismiss;
            }],
            controllerAs: '$modalCtrl'
        });
        
    };
    
    $ctrl.deleteMember = function(provider) {
        $scope.community.RemoveMember(provider)
        .then(function(updatedCommunity) {
            $ctrl.updateMembers(updatedCommunity);
        });
    };
    
    var resizeDisposal = template.contentResize(function(){
        $timeout(function() {
            leafletData.getMap().then(function(map) {
                map.invalidateSize();
            });
        }, 300);
    });
    $scope.$on('$destroy', resizeDisposal);
}];