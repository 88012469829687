import { NonRetryableError } from "@aws-amplify/core";
import React, { useState } from "react";
import { usePopper }  from 'react-popper';

import './popoverDetails.scss';
import { useApi } from '../../services/servicesContext';

export function PopoverDetails(props) {
    
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [showPopperElem, setShowPopperElem] = useState(false);
    const [providerDetails, setProviderDetails] = useState(null);
    
    const apiService = useApi();

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: props.placement ? props.placement : 'top',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }, {name: 'offset', options: {offset: [0,5]}}],
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        marginBottom: 0,
        fontSize: '.8em'
    };

    const poppertooltipElemStyles = {
        backgroundColor: 'black',
        borderRadius: '5px',
        // marginBottom: '2px',
        fontSize: '0.8em',
        color: '#ffffff',
        // width: '200px',
        maxWidth: '200px',
        padding: '5px 10px',
        textAlign: 'center',
        border: 'none',
        zIndex: 1000
    };

    if (props.width) {
        poppertooltipElemStyles.width = props.width;
    }
    
    async function provider() {
        setShowPopperElem(true);
        if (props.provider) {
            setProviderDetails(props.provider);
        }
        else if (props.npi === '') {
            const provider = { 
                location: {
                    'postal_code': 'N/A',
                    'phone_number': 'N/A',
                    'city': 'N/A',
                    'state': 'N/A'
                },
                'name': {'display' : props.name }
            };
            setProviderDetails(provider);
        }
        else {
            try {
                const providerData = await apiService.GetProvider(props.npi);
                const formatPhoneNum = providerData && providerData.location && providerData.location.phone_number && providerData.location.phone_number.toString() || '';
                const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`;
                const provider = { 
                    location: {
                        'postal_code' : providerData.location.postal_code,
                        'phone_number': phone,
                        'city': providerData.location.city,
                        'state': providerData.location.state
                    },
                    'name': {'display' : providerData.name.display }
                };
                setProviderDetails(provider);
            } catch(err) {
                console.log('error getting provider', err);
            }
        }
    }
    
    return (<>
        <span
            ref={setReferenceElement}
            onMouseEnter={provider}
            onMouseLeave={() => setShowPopperElem(false)}
        >{ props.children }</span>
            { providerDetails && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                        {providerDetails && providerDetails.name && providerDetails.name.display ? providerDetails.name.display : <i className="fa fa-spinner fa-spin fa-fw"></i> }
                    </h5>
                </div>
                <table className="panel-body table table-striped">
                    <tbody>
                        <tr>
                            <th>City, State</th>
                            <td>{providerDetails && providerDetails.location && providerDetails.location.city ? providerDetails.location.city : <i className="fa fa-spinner fa-spin fa-fw"></i> }, {providerDetails && providerDetails.location && providerDetails.location.state ? providerDetails.location.state : <i className="fa fa-spinner fa-spin fa-fw"></i> }</td>
                        </tr>
                        <tr>
                            <th>Postal Code</th>
                            <td>{providerDetails && providerDetails.location && providerDetails.location.postal_code ? providerDetails.location.postal_code : <i className="fa fa-spinner fa-spin fa-fw"></i> }</td>
                        </tr>
                        <tr>
                            <th>Phone Number</th>
                            <td>{providerDetails && providerDetails.location && providerDetails.location.phone_number ? providerDetails.location.phone_number : <i className="fa fa-spinner fa-spin fa-fw"></i> }</td>
                        </tr>
                    </tbody>
                </table>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null }
            { props.providerCollection && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                        {props.providerCollection.name}
                    </h5>
                </div>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th>Date Created</th>
                            <td>{props.providerCollection.insert_date.substring(0,16)}</td>
                        </tr>
                        <tr>
                            <th>Owner</th>
                            <td>{props.providerCollection.insert_user}</td>
                        </tr>
                        <tr>
                            <th>Members</th>
                            <td>{props.providerCollection.npis.length}</td>
                        </tr>
                    </tbody>
                </table>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}
            { props.providerTooltip && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default" style={{...styles.popper, ...poppertooltipElemStyles}} {...attributes.popper}>
                <div>{props.providerTooltip}</div>
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
            </div> : null}
    </>);
}
