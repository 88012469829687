import angular from 'angular';
import { react2angular } from 'react2angular';

import './summaryleakageflow.scss';
import template from './summaryleakageflow.html';
import controller from './summaryleakageflow.controller';

import tableData from '../../directives/tableData/tableData';
import localStorage from '../../services/localStorage';
import tour from '../../services/tour';

import { moduleName as geoflowModule } from '../geoflow.provider/geoflow';

import { SummaryLeakageFlowComponent } from './summaryleakageFlowComponent';


const componentName = 'organizationSummaryleakageflow';
const updatedComponentName = 'organizationSummaryleakageflowComponent';
const moduleName = 'app.reports.summaryleakageflow.organization';

angular.module(moduleName, [
    tableData, localStorage, tour, geoflowModule
])
.component(componentName, {
    bindings: {
        npis: '<',
        miles: '<', 
        tour: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(SummaryLeakageFlowComponent, ['npis', 'miles'], ['api', '$localStorage', 'pic', 'notify', 'download', 'tour'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};