import '@babel/polyfill';

import React from "react";
import ReactDOM from "react-dom/client";

import 'bootstrap/dist/css/bootstrap.css';

import 'font-awesome/css/font-awesome.css';

import 'shepherd.js/dist/css/shepherd.css';

import './styles/styles.scss';

//app level component that wraps router and servcies provider
import { App } from './app.js';

const domContainer = document.querySelector('#root');
const root = ReactDOM.createRoot(domContainer);
root.render(<App />);
