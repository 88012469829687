import React, { useEffect, useRef } from 'react'
import * as D3 from 'd3';
import * as D3Funnel from 'd3-funnel/dist/d3-funnel';
export function D3FunnelComponent({ data, $window }) {
    const d3FunnelContainer = useRef(null);
    useEffect(() => {
        createD3Funnel(d3FunnelContainer.current);
    }, []);
    const STYLES = {
        height: '90%',
        width: '60%',
        minWidth: '700px',
        display: 'block',
        margin: 'auto',
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'absolute',
    }

  function createD3Funnel(elem) {
    var boundingEl = elem.getBoundingClientRect();

    var options = 
    {
        chart: {
            curve: { 
                enabled: true,
                height: 20 
            },
            bottomPinch: 1,
            height: boundingEl.height,
            width: boundingEl.width
        },
        block: {
            dynamicHeight: true,
            highlight: true,
            fill: {
                type: 'gradient',
            }
        },
        label: {
            format: '{l}: {f}',
        }
    };

    var chart = new D3Funnel(elem);

    function buildChart() {
       chart.draw(data, options);
    }
    
    const onResizeBuildChart = function() {
        chart.draw(data, options); 
    };

    if (data) {
        buildChart();
        window.addEventListener('resize', onResizeBuildChart());
    }
}
  return (
    <>
    <div ref={d3FunnelContainer} style={STYLES}>
    </div>
    </>
  )
}
