import angular from 'angular';

export default angular.module('affiliations', [])
.provider('affiliations', function() {
    
    this.$get = ['$http', '$q', 'api', 'auth','config', function($http, $q, api, auth, config) {
        
        let ttm;
        let affiliationsEndpointURL; 
        
        auth.currentUser()
        .then(function(currentUser) {
            let currentUserTTM = currentUser.dataModel().name.split('_').pop();
            ttm = currentUserTTM;
        });
        
        affiliationsEndpointURL = config.affiliationsEndpoint();
        
        return {
            getAffiliations: function(npi) {
                return $q.all([$http.post(
                    `${affiliationsEndpointURL}/hospital-affiliation/search/`,
                    {
                        npis: [npi]
                    },
                    {
                        headers: {
                            Authorization: api.options().headers.Authorization
                        }
                    }
                )
                .then(res => {
                    const rawData = res?.data?.data.filter(hospital => (hospital.ttm === ttm));
                    return rawData.map(hospital => {
                        if (hospital.hospitalnpi) {
                            return api.GetProvider(hospital.hospitalnpi)
                            .then(provider => {
                                return Object.assign({
                                    hospitalName: hospital.hospitalname,
                                    facilityId: hospital.hospitalproviderid,
                                    source: hospital.source
                                }, provider);
                            }, err => {
                                console.log('error resolving hospital', err);
                                return {};
                            });
                        } else {
                            return {
                                facilityId: hospital.hospitalproviderid,
                                name: {
                                    display: hospital.hospitalname
                                },
                                source: hospital.source
                            };
                        }
                    });
                }),
                $http.post(
                    `${affiliationsEndpointURL}/group-affiliation/search/`,
                    {
                        npis: [npi]
                    },
                    {
                        headers: {
                            Authorization: api.options().headers.Authorization
                        }
                    }
                )
                .then(res => {
                    const rawData = res?.data?.data.filter(group => (group.ttm === ttm));
                    return rawData.map(group => {
                        if (group.groupnpi) {
                            return api.GetProvider(group.groupnpi)
                            .then(provider => {
                                return Object.assign({
                                    groupName: group.groupname,
                                    grouppracticeid: group.grouppracticeid,
                                    source: group.source
                                }, provider);
                            }, err => {
                                console.log('error resolving hospital', err);
                                return {};
                            });
                        } else {
                            return {
                                id: group.grouppracticeid,
                                name: {
                                    display: group.groupname
                                },
                                source: group.source
                            };
                        }
                        
                    });
                })
                ]).then(results => {
                    return {
                        hospitalAffiliations: $q.all(results[0]).then(hospitals => hospitals),
                        groupAffiliations: $q.all(results[1]).then(groups => groups)
                    };
                });
                
            },
            getHospitalAffiliations: function(params) {
                
                return $http
                    .post(
                        `${affiliationsEndpointURL}/hospital-affiliation/search/`,
                        params,
                        {
                            headers: {
                                Authorization: api.options().headers.Authorization
                            }
                        }
                    )
                    .then(res => {
                        const rawData = res?.data?.data;
                        const filteredDeduppedRawData = Object.values(rawData.reduce((acc, provider) => {
                            if (provider.ttm !== ttm) {//not in ttm
                                return acc;
                            } else if (!acc[provider.npi]) {// new in lookup
                                acc[provider.npi] = provider;
                            } else {//already in lookup
                                if (acc[provider.npi].source === 'CMS') {
                                    acc[provider.npi].source = `${acc[provider.npi].source},${provider.source}`; 
                                } else {
                                    acc[provider.npi].source = `${provider.source},${acc[provider.npi].source}`;
                                }
                            }
                            return acc;
                        },{}));
                        return filteredDeduppedRawData;
                    }, err => {
                        console.error('error calling NPI api', err);
                    });
            },
            getGroupAffiliations: function(params) {
                return $http
                    .post(
                        `${affiliationsEndpointURL}/group-affiliation/search/`,
                        params,
                        {
                            headers: {
                                Authorization: api.options().headers.Authorization
                            },
                        }
                    )
                    .then(res => {
                        const rawData = res?.data?.data;
                        const filteredRawData = rawData.filter(result => result.ttm === ttm);
                        return filteredRawData;
                    }, err => {
                        console.error('error calling NPI api', err);
                    });
            }
        };
        
    }];
    
}).name;