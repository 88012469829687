import React, { useRef, useEffect, useState } from "react";

import ConfigModal from './configModal';
import { CellComponent } from './cellComponent';
import localStorage from "../../services/localStorageModule";
import ReactPaginate from "react-paginate";
import { PopoverDetails } from '../popoverDetails/popoverDetails.js';
import eventBus from "../../services/eventBus";
import { useCommunityList } from '../../services/servicesContext';
import { v4 as uuid_v4 } from "uuid"

export function PhtableComponent({ data, opts, reportconfig, api, download, $timeout, sharePhTableData, needCommUpdate, showCount, isSearch, ignoreCommUpdate }) {
    
    const defaultReportConfig = reportconfig || {};
    const [reportfields, setReportfields] = useState(null);
    const [tableData, setTableData] = useState(null);
 
    const [order, setOrder] = useState("desc");
    const [sortField, setSortField] = useState("");
    const [filterString, setFilterString] = useState(null);

    const [postsPerPage, setPostsPerPage] = useState(10);
    const [lists, setLists] = useState([])
    const [showSelected, setShowSelected] = useState({ code: 1, count: null, label: 'Selected'});
    const [resetData, setResetData] = useState([]);
    const [_unCheckedRows, setUncheckedRows] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const report_options = reportconfig();

    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [endOffset, setEndOffset] = useState(0);
    const [currentList, setCurrentList] = useState([]);
    const CommunityList = useCommunityList();
    const [currentPage, setCurrentPage] = useState(0);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        let reportFieldsToSet;
        if (!showCount) {
            setShowSelected({ code: 1, count: null, label: 'Selected'});
            setLists([]);
        }
        const storedConfig = localStorage.getObj(report_options.localStorageName);
        if (
            storedConfig &&
            (!storedConfig.hiddenFields || !storedConfig.visibleFields) &&
            Object.keys(storedConfig.length > 0)
        ) {
            reportFieldsToSet = localStorage.getObj(report_options.localStorageName);
            setReportfields(reportFieldsToSet);handlePageClick
        } else {
            reportFieldsToSet = defaultReportConfig;
            setReportfields(reportFieldsToSet);
        }
        
        if (typeof reportFieldsToSet === 'function') {
            reportFieldsToSet = reportFieldsToSet();
        }
        if ( reportFieldsToSet.tableConfig && reportFieldsToSet.tableConfig.filter(col => col?.header?.sort).length > 0) {
            const sortCol = reportFieldsToSet.tableConfig.filter(col => col?.header?.sort)[0];
            if (sortCol.header.sort === 'asc') {
                let dataCopy = data.slice();
                let sortedData = dataCopy.sort((a, b) => (a[sortCol.header.accessor] > b[sortCol.header.accessor]) ? 1 : -1);
                setOrder('asc');
                setSortField(sortCol.header.accessor);
                setTableData(dataCopy);
            } else if (sortCol.header.sort === 'desc') {
                let dataCopy = data.slice();
                dataCopy.sort((a, b) => (a[sortCol.header.accessor] < b[sortCol.header.accessor]) ? 1 : -1);
                setOrder('desc');
                setSortField(sortCol.header.accessor);
                setTableData(dataCopy);
            }
        } else {
            setTableData(data);
        }

        setItemOffset(0);
        setCurrentPage(0);
        const endOffset = itemOffset + postsPerPage;
        setEndOffset(endOffset);

        if (data) { setPageCount(Math.ceil(data.length / postsPerPage)) }

        if (data && !isSearch) {
            updateCommList(data, 0, endOffset);
        }

     }, [data]); 

     useEffect(() => {
        if (!showCount) {
            setShowSelected({ code: 1, count: null, label: 'Selected'});
            setLists([]);
        }
        const endOffset = itemOffset + postsPerPage;
        setEndOffset(endOffset);
        tableData ? setPageCount(Math.ceil(tableData.length / postsPerPage)) : null;

        if (tableData && !isSearch) {
            updateCommList(tableData, itemOffset, endOffset);
        }

         if (tableData) {
             selectAllrows();
         }
     }, [itemOffset, postsPerPage, tableData]); 

    const selectAllrows = (event) => {
        const description = "Toggle between selected Providers and Providers from the full search results.";
        if (selectAll) {
            setSelectAll(false);
            tableData.map((row) => {
                row['_checked'] = false;
            });
            setLists([]);
            updateCommList([]);
            //CommunityList.reset();
        }
        else {
            setSelectAll(true);
            const checkedAllList = tableData.map((row) => {
                row['_checked'] = true;
                return row
            });
            setLists(checkedAllList);
            updateCommList(checkedAllList);
            //CommunityList.update({listval: checkedAllList, altListVal: data, descriptionVal: description});
        }
    }

    const updateCommunityList = (event, row) => {
      const description = "Toggle between selected Providers and Providers from the full search results.";
      row['_checked'] = !row['_checked'];
        if (event.target.checked) {
            let updatedList = [...lists, row];
            setLists(updatedList);
            updateCommList(updatedList);
            //CommunityList.update({listval: updatedList, altListVal: data, descriptionVal: description});
            if (tableData.length == updatedList.length) {
                selectAllrows();
            }
        } else {
            const index = lists.map((list, index) => {
                if (list.npi === row.npi) return index
            });
            lists.splice(index, 1);
            setLists([...lists]);
            updateCommList([...lists])
            setSelectAll(false);
            //CommunityList.update({listval: lists, altListVal: data, descriptionVal: description});
        }
    }

    const persistTableConfig = (obj) => {
        localStorage.setObj(report_options.localStorageName, obj);
    }

    const currentItems = (!tableData) ? null : tableData.slice(itemOffset, endOffset);

    if (sharePhTableData) { 
        sharePhTableData(currentItems);
    }

    const updateReportsConfig = (config) => {
        reportfields.tableConfig = config;
        setReportfields(reportfields);
        setIsOpen(!isOpen);
        persistTableConfig(reportfields); 
    }

    const createRows = (data, reportConfig) => {
        const reportsOptions = reportconfig();
        if (!reportConfig || reportConfig.length === 0 || !data) return 
        const visibleColumns = Object.values(reportConfig.tableConfig).filter(column => column.header.defaultVisibilty).map((column) => column.header.accessor);     
        const nested = reportsOptions.nested;

        const mapAccessorKeysWithRow = (rowDataObject, nestedRows, currentIconIndex) => {
                return visibleColumns.map((currentVisibleColumn, index) => { 

                let cellOptions = {}
                reportsOptions.tableConfig.forEach((opt) => {
                    if (opt['header']['accessor'] === currentVisibleColumn) { cellOptions = opt['header'] }
                })

                if (cellOptions.type === "collapse-button" && !nestedRows) {
                    return <td className="fa-icon" key={`icon-${uuid_v4()}`}>
                              <i key={uuid_v4()} onClick={(e) => toggleNestedRows(e, currentIconIndex)} className="fa fa-chevron-right" style={{ 'cursor': 'pointer'}} ></i>
                           </td>
                } 
              
                return <React.Fragment key={uuid_v4()}>
                        <td key={`cell-${uuid_v4()}`}>
                            <CellComponent 
                                rowDataObject={rowDataObject} 
                                currentVisibleColumn={currentVisibleColumn} 
                                cellOptions={cellOptions} 
                                updateCommunityList={updateCommunityList}
                                api={api}
                                >
                            </CellComponent>
                        </td></React.Fragment>
            });
        }

        const toggleNestedRows = (e, index) => {
 
            const childElementClass = `nested-row-${index}`;
            const parentElementClass = `top-row-${index}`;
            const elements = document.getElementsByClassName(childElementClass);
            const parentElements = document.getElementsByClassName(parentElementClass);
            [...elements].forEach((elem) => (elem.classList.contains("hidden")) ? elem.classList.remove('hidden') : elem.classList.add('hidden'));
            [...parentElements].forEach((elem) => elem.classList.toggle('collasped'));

            e.target.classList.toggle('open')
            if (e.target.classList.contains('open')) {
                e.target.classList.add('fa-chevron-down')
                e.target.classList.remove('fa-chevron-right')
            } else {
                e.target.classList.remove('fa-chevron-down')
                e.target.classList.add('fa-chevron-right')
            }
        }
        
        const renderRows = data.map((rowObject, index) => { 
            let sortedNested = nested ? rowObject[nested] : "";

            if (order && sortField && nested && sortedNested) {
                if (order === "asc") {
                    sortedNested.sort((a, b) => (a[sortField] > b[sortField]) ? 1 : -1);
                }
                if (order === "desc") {
                    sortedNested.sort((a, b) => (a[sortField] < b[sortField]) ? 1 : -1);
                }
            }

            return <React.Fragment key={uuid_v4()}>
                <tr key={`row-${uuid_v4()}`} className={nested ? `parent-row top-row-${index}` : ""}>
                   {mapAccessorKeysWithRow(rowObject, false, index)}
                </tr>
                { nested && sortedNested
                    ? 
                    sortedNested.map((nestedItem) => {
                        return <tr key={`nested-row-${uuid_v4()}`} className={`nested hidden nested-row-${index}`}>
                            { mapAccessorKeysWithRow(nestedItem, true, null)}
                        </tr> })
                    : null 
               }
            </React.Fragment>
        }); 
        return renderRows;
    };


     const setPageIndex = (index = 10) => {
        if (!data) return
        setItemOffset(0);
        setPostsPerPage(index);
        setCurrentPage(0)
     }

     const searchObj = (obj, string) => {
        return JSON.stringify(obj).toLowerCase().includes(string.toLowerCase());
     }

    const filterNestedList = (list, nestKey, str) => {
        // when we filter on a table where nestedSearch is truthy
        // we filter the children and if a parent contains no children, filter out the parent too
        return list.reduce((acc, parentItem) => {
            const filteredChildList = parentItem[nestKey].filter(childItem => searchObj(childItem, str));
            if (filteredChildList.length === 0) {
                return acc;
            }
            const modifiedParentItem = {...parentItem, [nestKey]: filteredChildList}
            acc.push(modifiedParentItem);
            return acc;
        }, [])
    };

     function filterRows(searchString) {
        if (!data) return

        if (reportfields && reportfields.filterInput === true) {
            setFilterString(searchString);
        }

        let filteredResults;
        if (reportfields.nested && reportfields.nestedSearch) {
            // if the table is nested and nestedSearch is truthy
            // e.g. the clinical reports, but not community shared visits report
            // we need to filter rows differently
            filteredResults = filterNestedList(data, reportfields.nested, searchString);
        } else {
            // else we use default search filtering
            filteredResults = data.filter((obj) => searchObj(obj, searchString));
        }
        
        if (order && sortField) {
            if (order === "asc") {
                filteredResults.sort((a, b) => (a[sortField] > b[sortField]) ? 1 : -1);
            }
            if (order === "desc") {
                filteredResults.sort((a, b) => (a[sortField] < b[sortField]) ? 1 : -1);
            }
            
        }

        setTableData(filteredResults);
        setPageCount(Math.ceil(filteredResults.length / postsPerPage));

        if (sharePhTableData) { 
            sharePhTableData(currentItems);
        }
     }

    const sortByColumn = (key, sortorder, e) => {
        if (!data) return
        
        if (sortorder === 'toggle') {
            if (key !== sortField) {
                sortorder = 'desc';
            } else {
                order === 'desc' ? sortorder = 'asc' : sortorder = 'desc';
            }
        }

        setOrder(sortorder);
        setSortField(key);

        const filteredTableData = tableData.filter(row => {
            if (!filterString) {
                return true;
            } else {
                return searchObj(row, filterString);
            } 
        });

        if (sortorder === "asc") {
            filteredTableData.sort((a, b) => ((a[key] == "--" ? 0 : a[key]) > (b[key] == "--" ? 0 : b[key])) ? 1 : -1);
            return setTableData(filteredTableData);
        }

        if (sortorder === "desc") {
            filteredTableData.sort((a , b) => ((a[key] == "--" ? 0 : a[key]) < (b[key] == "--" ? 0 : b[key])) ? 1 : -1);
            return setTableData(filteredTableData);
        }
    } 

    const cancelConfigModal = () => {
          setIsOpen(!isOpen);
          setReportfields(reportconfig());
            if (localStorage.getObj(report_options.localStorageName) && Object.keys(localStorage.get(report_options.localStorageName).length > 0)) {
                setReportfields(localStorage.getObj(report_options.localStorageName));
            } else {
                setReportfields(defaultReportConfig);
            }
    }

    const resetConfigModal = () => {
        setIsOpen(!isOpen);
        setReportfields(reportconfig());
        persistTableConfig(reportconfig());
    }


    const csvDownload = () => {
        const title = reportfields && (report_options.reportTitle ? report_options.reportTitle : reportfields.reportTitle) || "CSV Download";
        const columnOrder = reportfields.tableConfig.filter(row => row.header.export).map((row, index) => [row.header.content, row.header.accessor]);
        const columnHeader = columnOrder.map((column, index) => [column[0], index]);      
        let CSVdata
        if (reportfields.nested && reportfields.nestedSearch) {
            // if nestedSearch is truthy on nested tables, export only the filtered child rows
            const expandedRows = tableData.reduce((acc, parentRow) => {
                parentRow[reportfields.nested].forEach(childRow => {
                    acc.push(childRow);
                });
                return acc;
            }, []);
            CSVdata = expandedRows.map((row) => {
                const obj = {};
                columnOrder.forEach((column, index) => {
                    const [content, accessor] = column;       
                    obj[content] = row[accessor] || '';
                });
                return obj;
            });
        } else {
            // else the default export of regular rows and parent rows
            CSVdata = tableData.map((row) => {
                const obj = {};
                columnOrder.forEach((column, index) => {
                    const [content, accessor] = column;    
                    obj[content] = row[accessor] || '';
                });
                return obj;
            });
        }

        download.downloadCSV(CSVdata, title, true, columnHeader); 
    }



    const showCheckedRows = () => {
        let selectedRows = { code: 0, count: lists.length, label: "" }

        if (showSelected.code === 1) {
                setResetData(tableData); // Hold a copy of result data;
                
                const checkedRows = tableData.filter(row => row._checked === true); 
                const unCheckedRows = tableData.filter(row => row._checked === false);
                setTableData(checkedRows);
                selectedRows.code = 2
                selectedRows.count = unCheckedRows.length;
                selectedRows.label = "Unselected";
                setUncheckedRows(unCheckedRows);
                setShowSelected(selectedRows);
                setItemOffset(0);
                setCurrentPage(0);
        } 
        
        if (showSelected.code === 2) {
                const allRows = resetData.map((row) => {
                    lists.forEach((list) => {
                        if (list.npi === row.npi) {
                            row._checked = list._checked
                            return row
                        }
                      })
                  return row              
                }).filter(row => row._checked === false); 
                setTableData(allRows);
                selectedRows.code = 3
                selectedRows.count = allRows.length
                selectedRows.label = "All";
                setShowSelected(selectedRows);
                setItemOffset(0);
                setCurrentPage(0);
        }

        if (showSelected.code === 3) {
                const allRows = resetData.map((row) => {
                      lists.forEach((list) => {
                          if (list.npi === row.npi) {
                              row._checked = list._checked
                              return row
                          }
                      }) 
                    return row              
                }); 
                setTableData(allRows);
                selectedRows.code = 1
                selectedRows.count = lists.length
                selectedRows.label = "Selected";
                setShowSelected(selectedRows);
                setItemOffset(0);
                setCurrentPage(0);
        }
    }

const handlePageClick = (event) => {
    const newOffset = event.selected * postsPerPage % tableData.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
};

function updateCommList(data, itemOffset, endOffset) {
    let checkKeyPresenceInArray = key => data.some(obj => Object.keys(obj).includes(key));
    let currentData;
    if (checkKeyPresenceInArray('npi')) {
        if (!isSearch) {
            currentData = data.slice(itemOffset, endOffset);
        }
        else {
            currentData = data;
        }
        let arrayNpis = [];
        currentData.forEach(row => {
            if (Array.isArray(row.npi)) {
                row.npi.forEach(npi => {
                    arrayNpis.push({npi:npi});
                })
            }
            else {
                arrayNpis.push({npi:row.npi});
            }
        })

        let distinctList = [...new Set(arrayNpis.map((row) => {
                return row.npi;
        }))];

            CommunityList.update({listVal: distinctList});
            eventBus.dispatch("updatedCommunityList");
    }
    else {
        if (isSearch) {
            CommunityList.reset();
            eventBus.dispatch("updatedCommunityList");
        }
    }
};

    return (<>
         <div className="row" id="ph-table-root" style={{marginLeft: 0, marginRight: 0}}>
            <div className="col-lg-3 table-controls-number"> 
                { reportfields && reportfields.pagination !== false && 
                <div>
                    View <select name="mySelect" id="mySelect" onChange={(e) => setPageIndex(Number(e.target.value))}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                    </select> records at a time.
                </div>
               }
            </div>
           

{(reportfields && reportfields.configure === true) ? <div className="col-lg-9 table-controls-input">

{(reportfields && reportfields.filterInput === true) ? <input type="text" className="form-control filter-box"  placeholder="&#xf0b0; Filter Results"  onChange={(e) => filterRows(e.target.value)} />: null}
{(reportfields && reportfields.csvdownload === true) ? <button id="tourscript-buttons" className="btn btn-default" disabled={((tableData && tableData.length > 0) ? undefined : true)} onClick={() => csvDownload()}><i className="fa fa-download" aria-hidden="true"></i> CSV</button> : null}
{(reportfields && reportfields.showSelected === true) ? <button className="btn btn-default maintain-width tourscript-button-filter" onClick={showCheckedRows} disabled={((lists.length === 0 && showSelected.code === 1) ? true : undefined)}> Show {showSelected.code == 1 ? lists.length + " " + showSelected.label : (showSelected.code == 2 ? _unCheckedRows.length + " " + showSelected.label : (showSelected.code == 3 ? showSelected.label : showSelected.label))} </button>: null}    
{(reportfields && reportfields.configureButton === true) ?<><ConfigModal reportfields={reportfields} updateReportsConfig={updateReportsConfig} open={isOpen} onClose={() =>  cancelConfigModal()} reset={() => resetConfigModal()}  />
            <button className="btn btn-default" onClick={() => setIsOpen(true)}>
                Configure Columns
            </button></> : null }

</div>: null}   

        <table className="table table-striped">
            <thead>
                <tr className="tableHeader">
                { reportfields && reportfields.tableConfig && reportfields.tableConfig
                .map((column) => {

                    if (column.header.accessor === 'select_all' && column.header.defaultVisibilty === true) {
                        return <th className={column.header.class} key={column.header.accessor + '-' + uuid_v4()}>
                                    <div className="pointer table-header">
                                       <input type='checkbox' checked={selectAll} onChange={(event) => selectAllrows(event)} />          
                                    </div>
                                </th>
                    }

                    if (column.header.accessor === 'show' && column.header.defaultVisibilty === true) {
                        return <th key={column.header.accessor + '-' + uuid_v4()}>
                                    <div className="pointer table-header">
                                       {column.header.content}         
                                    </div>
                                </th>
                    }

                    if (column.header.defaultVisibilty) {
                        return  <th className="" key={column.header.id + '-' + uuid_v4()}>
                            <PopoverDetails
                                providerTooltip={ column.header.headerTooltip || '' }
                            >                 
                                <div id={column.header.accessor} className="header-container pointer table-header" onClick={(e) => { column.header.sortable !== false ? sortByColumn(column.header.accessor, "toggle", e ) : ""}}>{column.header.content}
                                    { reportfields.sorting !== false ? <div className="sort-container" style={{ 'display' : (column.header.sortable === false) ? 'none' : ''}} >
                                        <span id={column.header.accessor} className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(sortField === column.header.accessor && order === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByColumn(column.header.accessor, "asc", e )}></span>
                                        <span id={column.header.accessor} className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(sortField === column.header.accessor  && order === "desc") ? "sort-color-active" : null}`}  onClick={(e) => sortByColumn(column.header.accessor, "desc", e )}></span>
                                    </div> : null}
                                </div>
                            </PopoverDetails>
                        </th>
                     }
                  })}
                </tr>
            </thead>
            <tbody>
               {
                reportfields && reportfields.pagination === false ? createRows(tableData, reportfields) : createRows(currentItems, reportfields) 
               }
            </tbody>
        </table>

        <div className="pagination-container">
            <div className="row">
                <div className="col-md-6 total-records">
                { 
                    reportfields 
                    && reportfields.pagination !== false 
                    && <span>Total records: {(tableData && tableData.length) ? tableData.length : 'n/a' }.</span>
                }
                </div>
                <div className="col-md-6">
                { 
                    tableData && reportfields && reportfields.pagination !== false &&  
                    <ReactPaginate
                        forcePage = {currentPage}
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                        onPageActive={handlePageClick}
                    />
                }
                </div>
            </div>
        </div>
        </div>
    </>)
}