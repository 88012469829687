import angular from 'angular';
import Amplify from '@aws-amplify/core';

export default angular.module('config', [])
.provider('config', function() {
    
    var srvc = this;
    
    this.templates = {};
                        
    var statesArr = [
                    { value: "AL", name: "Alabama"},
                    { value: "AK", name: "Alaska"},
                    { value: "AZ", name: "Arizona"},
                    { value: "AR", name: "Arkansas"},
                    { value: "CA", name: "California"},
                    { value: "CO", name: "Colorado"},
                    { value: "CT", name: "Connecticut"},
                    { value: "DE", name: "Delaware"},
                    { value: "DC", name: "District Of Columbia"},
                    { value: "FL", name: "Florida"},
                    { value: "GA", name: "Georgia"},
                    { value: "HI", name: "Hawaii"},
                    { value: "ID", name: "Idaho"},
                    { value: "IL", name: "Illinois"},
                    { value: "IN", name: "Indiana"},
                    { value: "IA", name: "Iowa"},
                    { value: "KS", name: "Kansas"},
                    { value: "KY", name: "Kentucky"},
                    { value: "LA", name: "Louisiana"},
                    { value: "ME", name: "Maine"},
                    { value: "MD", name: "Maryland"},
                    { value: "MA", name: "Massachusetts"},
                    { value: "MI", name: "Michigan"},
                    { value: "MN", name: "Minnesota"},
                    { value: "MS", name: "Mississippi"},
                    { value: "MO", name: "Missouri"},
                    { value: "MT", name: "Montana"},
                    { value: "NE", name: "Nebraska"},
                    { value: "NV", name: "Nevada"},
                    { value: "NH", name: "New Hampshire"},
                    { value: "NJ", name: "New Jersey"},
                    { value: "NM", name: "New Mexico"},
                    { value: "NY", name: "New York"},
                    { value: "NC", name: "North Carolina"},
                    { value: "ND", name: "North Dakota"},
                    { value: "OH", name: "Ohio"},
                    { value: "OK", name: "Oklahoma"},
                    { value: "OR", name: "Oregon"},
                    { value: "PA", name: "Pennsylvania"},
                    { value: "RI", name: "Rhode Island"},
                    { value: "SC", name: "South Carolina"},
                    { value: "SD", name: "South Dakota"},
                    { value: "TN", name: "Tennessee"},
                    { value: "TX", name: "Texas"},
                    { value: "UT", name: "Utah"},
                    { value: "VT", name: "Vermont"},
                    { value: "VA", name: "Virginia"},
                    { value: "WA", name: "Washington"},
                    { value: "WV", name: "West Virginia"},
                    { value: "WI", name: "Wisconsin"},
                    { value: "WY", name: "Wyoming"},
                    { value: "PR", name: "Puerto Rico"}
                ];
    
    var allRoles = ['Global Admin', 'Brand Admin', 'Admin'];
    
    this.$get = ['$http', '$state', 'api', '$q', function($http, $state, api, $q) {
        
        var bindData = function(data) {
            srvc.brand = data.brand;
            srvc.accounts = data.accounts;
            srvc.models = data.models;
            srvc.version = data.version;
            srvc.manual = data.manual;
            if (data.google_analytics_id) srvc.googleAnalyticsId = data.google_analytics_id;
            if (data.jira_collector) srvc.jiraCollector = data.jira_collector;
            srvc.pcpTaxonomyCodesArr = data.taxonomy.pcp;
            srvc.radiologyTaxonomyCodesArr = data.taxonomy.radiology;
            srvc.laboratoryTaxonomyCodesArr = data.taxonomy.laboratory;
            srvc.gacTaxonomyCodesArr = data.taxonomy.gac;
            srvc.postAcuteTaxonomies = data.taxonomy.report.postacute;
            srvc.marketMapTaxonomies = data.taxonomy.report.marketmap;
            srvc.cognito = data.cognito;
            srvc.affiliations_api = data.affiliations_api;
            srvc.codes_api = data.codes_api;
            srvc.account = data.account;
            srvc.forecasting_api = data.forecasting_api;
            
            //server config with cookie
            Amplify.configure({
               Auth: data.cognito
            });

            //Local dev without cookie
            //  var cognitoObj = {
            //      ...data.cognito,
            //  };
            //  delete cognitoObj.cookieStorage;
            //  Amplify.configure({
            //      Auth: cognitoObj
            //  });
            
            return data;
        };
        
        var initChain;
        
        return {
            init: function(token) {
                if (initChain && !token)
                    return initChain;
                
                return initChain = api.Config()
                .then(function(res) {
                    initChain = undefined;
                    return res;
                }, function() {
                    initChain = undefined;
                })
                .then(bindData);
            },
            template: function(path) {
                try {
                    return srvc.templates[srvc.brand][path] || path;
                } catch (err) { 
                    return path;
                }
            },
            templates: function(brand, templates) {
                srvc.templates[brand] = templates;    
            },
            brand: function(brand) {
                if (typeof brand === 'undefined')
                    return srvc.brand || 'PHTEAM';
                else
                    srvc.brand = brand;
            },
            allBrands: function() {
                return srvc.brands;
            },
            version: function() {
                return srvc.version;
            },
            manual: function() {
                return srvc.manual;
            },
            productName: function() {
                return srvc.productName;
            },
            states: function() {
                return statesArr;
            },
            roles: function() {
                return srvc.roles;
            },
            allRoles: function() {
                return allRoles;
            },
            admins: function() {
                return srvc.admins;
            },
            accounts: function() {
                return srvc.accounts;
            },
            pcpTaxonomyCodes: function() {
                if (srvc.pcpTaxonomyCodesArr) {
                    return srvc.pcpTaxonomyCodesArr;
                } else {
                    return [];
                }
            },
            radiologyTaxonomyCodes: function() {
                if (srvc.radiologyTaxonomyCodesArr) {
                    return srvc.radiologyTaxonomyCodesArr;
                } else {
                    return [];
                }
            },
            laboratoryTaxonomyCodes: function() {
                if (srvc.laboratoryTaxonomyCodesArr) {
                    return srvc.laboratoryTaxonomyCodesArr;
                } else {
                    return [];
                }
            },
            gacTaxonomyCodes: function() {
                if (srvc.gacTaxonomyCodesArr) {
                    return srvc.gacTaxonomyCodesArr;
                } else {
                    return [];
                }
            },
            postAcuteTaxonomyCodes: function() {
                if (srvc.postAcuteTaxonomies) {
                    return srvc.postAcuteTaxonomies;
                } else {
                    return [];
                }
            },
            marketMapTaxonomyCodes: function() {
                if (srvc.marketMapTaxonomies) {
                    return srvc.marketMapTaxonomies;
                } else {
                    return [];
                }
            },
            getMenuOptions: function(obj) {
                var states = $state.get().filter(function(state) {
                    return !state.redirectTo && state.data && state.data.name;
                });
                
                var type;
                if (obj instanceof api.Physician) {
                    type = 'phy';
                } else if (obj instanceof api.Organization) {
                    type = 'org';
                } else {
                    type = 'com';
                }

                var menuOptions = [];
                var graphs = {displayName: 'Graphs', children: []};
                var reports = {displayName: 'Reports', children: []};
                
                angular.forEach(states, function(state) {
                    if (state.name.startsWith('root.app.' + type)) {
                        if (state.name.indexOf('graph') > -1) {
                            graphs.children.push({displayName: state.data.name, state: state});
                        } else if (state.name.indexOf('report') > -1) {
                            reports.children.push({displayName: state.data.name, state: state});
                        } else {
                            menuOptions.push({displayName: state.data.name, state: state});
                        }
                    }
                });
                
                
                var promises = [];
                [graphs, reports].forEach( group => {
                    promises = promises.concat(group.children.map( child => {
                        if (!child.state.data.isActive) {
                            return $q.resolve();
                        }
                        return child.state.data.isActive(obj).then( active => {
                            if (!active) {
                                group.children.splice( group.children.indexOf( child), 1);
                            }
                        }, error => {
                            group.children.splice( group.children.indexOf( child), 1);
                        });
                    }));
                });
                
                menuOptions.push(graphs, reports);
                return $q.all(promises).then( () => { return menuOptions; } );
                
            },
            models: function() {
                if (srvc.models) {
                    return srvc.models;
                } else {
                    return [];
                }
            },
            passwordPolicies: function() {
                return srvc.passwordPolicies;
            },
            googleAnalyticsId: function(){
                return srvc.googleAnalyticsId;
            },
            jiraCollector: function(){
                return srvc.jiraCollector;
            },
            cognito: function() {
                return srvc.cognito;
            },
            account: function() {
                return srvc.account;
            },
            affiliationsEndpoint: function() {
                if (srvc.affiliations_api) {
                    return srvc.affiliations_api;
                } else {
                    return null;
                }
            },
            codesEndpoint: function() {
                if (srvc.codes_api) {
                    return srvc.codes_api;
                } else {
                    return null;
                }
            },
            forecastingEndpoint: function() {
                if (srvc.codes_api) {
                    return srvc.forecasting_api;
                } else {
                    return null;
                }
            }
        };
    }]; 
}).name;
