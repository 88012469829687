import angular from 'angular';

import tableData from '../../directives/phTable/phTable';
import tour from '../../services/tour';

import api from '../../services/api';
import notify from '../../services/notify';

import { react2angular } from 'react2angular';
import { PartdutilizationComponent } from './partdutilizationComponent';

import download from '../../services/download';

const componentName = 'physicianPartdutilization';
const updatedComponentName = 'partdutilizationComponent';
const moduleName = 'app.reports.partdutilization.physician';

angular.module(moduleName, [
    tableData, tour, api, notify, tour
])
.component(
    updatedComponentName,
    react2angular(PartdutilizationComponent, ['npis', 'opts'], ['api', 'notify', 'download', 'tour'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};