import React, { useEffect, useState, useRef } from 'react';
import ReactDom from 'react-dom';
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import * as d3Base from 'd3';
import { interpolateRdBu  as d3interpolateRdBu } from 'd3-scale-chromatic';
import { ScatterPlotComponent } from './scatterPlotComponent';

import { useApi, useLogging } from '../../services/servicesContext';

const d3 = Object.assign( {}, d3Base, { interpolateRdBu: d3interpolateRdBu });

const colorScale = d3.scaleSequential(d3.interpolateRdBu);

const format1s = d3.format(',.1s');
const format4s = d3.format(',.4s');
const formatSize = format4s;

export function QuadrantAnalysisComponent(props) {

    const api = useApi();
    const logging = useLogging();

    const npis = useRouteLoaderData('organization.npis');

    const navigate = useNavigate();

    const [ loaded, setLoaded ] = useState(false);
    const [ datums, setDatums ] = useState(null);
    const [ graphopts, setGraphopts ] = useState(null);
    const [ listopts, setListopts ] = useState(null);

    const [ hoverScopeDatum, setHoverScopeDatum ] = useState({
        label: 'Label',
        charges: 0,
        count: 0,
        leakage: 0,
        shared: 0
    });

    const [ hoverScopeStyles, setHoverScopeStyles ] = useState({
        zIndex: 2000,
        position: 'absolute',
        width: 212,
        opacity: 1,
        top: 0,
        left: 0
    });

    const [ hoverScopeArrowStyles, setHoverScopeArrowStyles ] = useState({
        top: 0,
        bottom: 0
    });

    const [ activeTooltip, setActiveTooltip ] = useState(false);

    const colorScale = d3.scaleSequential(d3.interpolateRdBu);

    function buildQuadrantAnalysis(npiProvider){
        setLoaded(false);    
        var npiDisplayName = npiProvider.name.display;
        var endpoint = '/api/npi/' + npiProvider.npi + '/quadrant-analysis/';
        var dt, back, title;
                
        const bubbleOpts = {
            dimensions: {
                marginTop: 30,
                marginRight: 1,
                marginBottom: 150,
                marginLeft: 50
            },
            back: {
                value: '< Back',
                visible: false
            },
            title: npiDisplayName || '',
            nodes: {
                opacity: {
                    value: .9,
                    hover: {
                        active: 1,
                        inactive: .2  
                    }
                }
            },
            events: {
                hover: function(event, datum, index) {
                    
                    var test = document.getElementById("circleTooltipId");
                    
                    if (event.type === "mouseenter") {
                        

                        setHoverScopeDatum(datum);
                        var eventTargetBoundingClientRect = event.target.getBoundingClientRect();

                        //hang below
                        // if (yValue(datum) > 50)
                        setHoverScopeArrowStyles({top: -5, bottom: 'auto'});
                        setHoverScopeStyles({...hoverScopeStyles,
                            left: (eventTargetBoundingClientRect.left - (hoverScopeStyles.width/2 - eventTargetBoundingClientRect.width/2)),
                            top: (eventTargetBoundingClientRect.top + eventTargetBoundingClientRect.height)
                        });

                        // hang above
                        // setHoverScopeArrowStyles({top: 'auto', bottom: -5});
                        // setHoverScopeStyles({...hoverScopeStyles,
                        //     left: (eventTargetBoundingClientRect.left - (hoverScope.styles.width/2 - eventTargetBoundingClientRect.width/2)),
                        //     top: (eventTargetBoundingClientRect.top - toolTipBoundingClientRect.height)
                        // });

                        setActiveTooltip(true);  
                    }
                    
                    if (event.type === "mouseleave") {
                        setActiveTooltip(false);
                    }
                    
                },
                click: function(event, datum, index) {
                    setTimeout(function() {
                        setActiveTooltip(false);
                        if (datum.children) {
                            
                            if (datum.parent) {
                                back = datum.parent;
                            } else {
                                back = dt;
                            }
                            
                            bubbleOpts.back.visible = true;
                            bubbleOpts.title = datum.label;
                                
                            const newDatums = getArrayFromTreeProp(datum);
                            setDatums(newDatums);

                        } else {
                            window.open(`/physician/${datum.id}/home`, '_blank');
                        }
                    }, 0);
                },
                back: function(event, backData, index) {
                    setTimeout(function(){
                        bubbleOpts.title = back.label || npiDisplayName;
                        const newdatums = getArrayFromTreeProp(back);
                        setDatums(newdatums);
                        if (back == dt ) {
                            bubbleOpts.back.visible = false;
                        } else if (!back.parent) {
                            back = dt;
                        } else {
                            back = back.parent;    
                        }
                    }, 0);
                }
            },
            x: {
                scale: d3.scaleLog(),
                value: xValue,
                extent: function (options) {
                    return d3.extent(options.data, options.x.value);
                },
                axisLabel: 'X-Axis: Volume',
                tickFormat: function(value) { return format1s(value)}
            },
            y: {
                value: yValue,
                extent: function (options) {
                    return d3.extent(options.data, options.y.value);
                },
                extentRange: function(options) {
                    return (options.y.extent(options)[1] - options.y.extent(options)[0]);
                },
                axisLabel: 'Y-Axis: Market Share',
                tickFormat: function(value) { return value + "%"; }
            },
            size: {
                value: sizeValue,
                axisLabel: 'Size: Charges',
                tickFormat: function(value) { return  format4s(value); }
            },
            color: {
                value: colorValue,
                scale: colorScale,
                axisLabel: 'Color: Share to Competitors',
                tickFormat: function(value) { return value + "%"; }
            },
            id: function(d) {
                return d.id;    
            }
        };
        
        const legendOpts = {
            dimensions: {
                paddingTop: 30
            },
            sort: {
                value: yValue,
                order: d3.descending
            },
            color: {
                value: colorValue,
                scale: colorScale
            },
            events: {
                mouseenter: function(event, datum, index) {
                    const elemToMouseEnter = document.getElementById(`circ-${datum.id}`);
                    const eventToDispatch = new Event("mouseenter");
                    elemToMouseEnter.dispatchEvent(eventToDispatch);
                },
                mouseleave: function(event, datum, index) {
                    const elemToMouseEnter = document.getElementById(`circ-${datum.id}`);
                    const eventToDispatch = new Event("mouseleave");
                    elemToMouseEnter.dispatchEvent(eventToDispatch);
                },
                click: function(event, datum, index) {

                    if (datum.children) {
                            
                        if (datum.parent) {
                            back = datum.parent;
                        } else {
                            back = dt;
                        }
                        
                        bubbleOpts.back.visible = true;
                        bubbleOpts.title = datum.label;

                        const newDatums = getArrayFromTreeProp(datum);
                        setDatums(newDatums);
                        setActiveTooltip(false);
                    } else {
                        window.open(`/physician/${datum.id}/home`, '_blank');
                    }

                }
            },
            id: function(d) {
                return d.id;    
            }
        };
        
        var requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': api.options().headers.Authorization, 'X-TEAM-TTM': api.options().headers["X-TEAM-TTM"]}
        };
        fetch(endpoint, requestOptions)
        .then(res => res.json())
        .then(function(response){
            if (response.scatter_data.length === 0) {
                console.log('No Quadrant Analysis data available for provider.');
            }
            setLoaded(true);
            dt = buildDataTree(response.scatter_data);
            const datumsToSet = getArrayFromTreeProp(dt);
            setDatums(datumsToSet);
            setGraphopts(bubbleOpts);
            setListopts(legendOpts);
            setLoaded(true);
            console.log("init done");
        })
        .catch(function(err){
            console.log('Error fetching Quadrant Analysis data: ', err);
            setLoaded(true);
        });
        
    }

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.org.graphs.quadrantanalysis`
        });
        
        async function init() {
            try {
                buildQuadrantAnalysis(npis);
            } catch (err) {
                console.error('error initializing quadrant analysis', err);
            }
        }

        init();

        return () => {
            
        };

    },[npis]);

    return <>
    { loaded ? <>
        { datums && graphopts && listopts && <ScatterPlotComponent
            data={datums}
            graphopts={graphopts}
            listopts={listopts}
        />}
        { activeTooltip && <ScatterPlotTooltip
            styles={hoverScopeStyles}
            datum={hoverScopeDatum}
            arrowStyles={hoverScopeArrowStyles}
            ho
        />}
        </>
        : <div className="loading-lg"></div>
    }
    </>;
}

function ScatterPlotTooltip(props) {

    let children;
    if (props?.datum?.children) {
        children = childrenFn(props.datum).slice().sort((a,b) => {
            return b.charges - a.charges;
        });
    }

    return ReactDom.createPortal(<>
        <div id="circleTooltipId" className="circleTooltip" style={props.styles}>
            <h4><strong>{props.datum.label}</strong></h4>
            <p>Share to Competitors %<span style={{float:'right'}}>{formatLeakage(colorValue(props.datum))}</span></p>
            <hr />
            <p>Size <span style={{float:'right'}}>{formatSize(sizeValue(props.datum))}</span></p>
            <hr />
            { children && <div>
                <p>
                    <strong>Including</strong>
                    <strong style={{float:'right'}}>Charges ($)</strong>
                </p>
                <div>
                    { children.map(child => {
                        return <div key={child.label}> 
                            <hr />
                            <p>
                                <div className="legend-color" style={{backgroundColor: colorScale(colorValue(child))}}></div>
                                <span style={{display:'inline-block', width:'100px'}}>{child.label}</span>
                                <span style={{float:'right'}}>{formatSize(child.charges)}</span>
                            
                            </p>
                        </div>
                    }).slice(0, 10) }
                </div>
                { children.length > 10 && <div>
                    <hr />
                    <p><p>{children.length - 10} more</p></p>
                    <p style={{textAlign:'center',fontStyle:'italic'}}>Click to Expand</p>
                </div>}
            </div>}
            <div className="circleTooltipArrow" style={props.arrowStyles}></div>
        </div>
    </>, document.body);
}

function formatLeakage(num){
    return (((1 - Number.parseFloat(num)) * 100).toFixed(2));
}

function childrenFn(datum) {
    return Object.keys(datum.children).map(function(key) { 
        return datum.children[key]; 
    });
}

function colorValue(d) {
    return (d.shared / (d.leakage + d.shared));
}

function sizeValue(d) {
    return d.charges;
}

function xValue(d) {
    return d.shared / d.count;
}

function yValue(d){
    return Math.round(d.shared / (d.leakage + d.shared) * 1000) / 10;
}

function getArrayFromTreeProp(treeObj){
    return Object.keys(treeObj.children).map(function(key){
        return treeObj.children[key];
    });
}

function buildDataTree(data) {
    var dt = {
        children: {}    
    };
    data.forEach(function(d) {
        if (!dt.children[d.classification]) {
            dt.children[d.classification] = { 
                id: 'cl-'+d.classification,
                label: d.classification,
                count: 0,
                shared: 0,
                leakage: 0,
                charges: 0,
                children: {}    
            };
        }
            
        if (!dt.children[d.classification].children[d.specialization]) {
            dt.children[d.classification].children[d.specialization] = { 
                id: 'sp-'+d.specialization,
                label: d.specialization,
                parent: dt.children[d.classification],
                count: 0,
                shared: 0,
                leakage: 0,
                charges: 0,
                children: {}
            };
        }
        
        dt.children[d.classification].shared += d.sharedTransactionCount;
        dt.children[d.classification].children[d.specialization].shared += d.sharedTransactionCount;
        
        dt.children[d.classification].leakage += d.leakage;
        dt.children[d.classification].children[d.specialization].leakage += d.leakage;
        
        dt.children[d.classification].charges += d["Submitted Charges"];
        dt.children[d.classification].children[d.specialization].charges += d["Submitted Charges"];
        
        dt.children[d.classification].count += 1;
        dt.children[d.classification].children[d.specialization].count += 1;
        
        dt.children[d.classification].children[d.specialization].children[d.npi] = {
            id: d.npi,
            label: d.name,
            parent: dt.children[d.classification].children[d.specialization],
            count: 1,
            shared: d.sharedTransactionCount,
            leakage: d.leakage,
            charges: d["Submitted Charges"],
        };
    });
    return dt;
}