import angular from 'angular';

import './performanceReport.scss';

import controller from './performanceReport.controller';

import template from './performanceReport.html';

import tableData from '../../directives/tableData/tableData';
import tour from '../../services/tour';

const componentName = 'providerPerformanceReport';
const moduleName = 'app.reports.performanceReport.provider';

angular.module(moduleName, [
    tableData, tour
])
.component(componentName, {
    bindings: {
        npis: '<',
        tour: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName
};