import angular from 'angular';
import { react2angular } from 'react2angular';
import template from './preacuteleakage.html';
import controller from './preacuteleakage.controller';

import phTable from '../../directives/phTable/phTable';
import pic from '../../services/pic';
import api from '../../services/api';
import uibModal from 'angular-ui-bootstrap/src/modal';
import uirouter from '@uirouter/angularjs';
import tour from '../../services/tour';

import generalFlowModal from '../../directives/generalFlowModal/generalFlowModal';
import { PreacuteLeakageComponent } from './preacuteleakageComponent';
import { moduleName as generalFlowModule } from '../generalflow.physician/generalflow';

//const componentName = 'organizationPreacuteleakage';
const moduleName = 'app.reports.preacuteleakage.organization';
const componentName = 'preAcuteLeakageComponent';

angular.module(moduleName, [
    phTable, pic, uibModal, uirouter, tour, generalFlowModule, api, generalFlowModal
])
.component(
    componentName,
    react2angular(PreacuteLeakageComponent, ['npis', 'opts'], ['api', 'pic', 'notify', 'download', 'tour', 'CommunityList'])
);
// .component(componentName, {
//     bindings: {
//         npis: '<'
//     },
//     controller,
//     controllerAs: '$ctrl',
//     template,
// });

export {
    moduleName,
    componentName
};