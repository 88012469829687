import React, {useEffect} from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { useLogging } from '../../services/servicesContext';

export function DashboardsectionComponent(props) {

  const npis = useRouteLoaderData('physician.npis');
  const community = useRouteLoaderData('community.cid');
  const logging = useLogging();

  useEffect(() => {

    if (npis) {
      logging.routeLoad({
        pathname: location.pathname,
        npis: [npis.npi],
        statename: 'root.app.phy.dashboards.home'
      });
    }

    if (community) {
      logging.routeLoad({
        pathname: location.pathname,
        npis: [],
        statename: 'root.app.com.dashboards.home'
      });
    }

  }, [npis, community]);

  const dashboardObject = props?.dashboards ? props.dashboards.map((dashboard) => {
    let paramkey = '';
    const paramId = (dashboard.name === 'Volume of Procedures by Geography') ? 'geobenchmark' : 'marketbenchmarks';
    if (npis) {
        paramkey = npis.npi;
    } else {
        paramkey = community.id;
    }
    return (
      <div className="panel panel-success">
        <div className="panel-heading">
            <h3 className="panel-title">
            <Link style={{cursor : 'pointer'}} to={`/${props.elementName}/${paramkey}/${paramId}`}>
            {dashboard.name} <i className="fa fa-link" aria-hidden="true"></i>
            </Link>
            </h3>
        </div>
        <div className="panel-body">{dashboard.description}</div>
      </div>
    )
  }) : null;

  return (
    <div>
    <h2>{props?.name}</h2>
    <ul style={{ paddingLeft: 0}}>
      { props?.dashboards
        ? props.dashboards.map(dashboard => {
          let paramkey = '';
          const paramId = (dashboard.name === 'Volume of Procedures by Geography') ? 'geobenchmark' : 'marketbenchmarks';
          if (npis) {
              paramkey = npis.npi;
          } else {
              paramkey = community.id;
          }
          return (
            <div className="panel panel-success">
              <div className="panel-heading">
                  <h3 className="panel-title">
                    <Link style={{cursor : 'pointer'}} to={`/${props.elementName}/${paramkey}/${paramId}`}>
                    {dashboard.name} <i className="fa fa-link" aria-hidden="true"></i>
                    </Link>
                  </h3>
              </div>
              <div className="panel-body">{dashboard.description}</div>
            </div>
          );
        })
        : null
      }
    </ul>
    </div>
  )
}
