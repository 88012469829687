import React, { useEffect, useState, useRef } from 'react';
import { usePic } from '../../services/servicesContext';
import { geoflowModule } from '../../js/geoflowModule.js';

export function SummaryLeakageFlowComponent(props) {
    const [ loading, setLoading ] = useState(true);
    const pic = usePic();
    const elem = useRef(null);
    const leafletInstance = useRef(null);
    const npis = props.npis;

    useEffect(() => {
        setLoading(false);
        createGeoFlow();
    }, []);

    async function createGeoFlow() {
        setLoading(true);
        const geoflowReportData = props.data;
        const geoflowInstance = geoflowModule(elem.current);
        geoflowInstance.geoflow({
            providername: npis.name.display,
            NPI: npis.npi,
            center: npis.location.coordinates,
            entitytypecode: npis.entitytype,
            filterOpen: true,
            legend: true,
            recentLegend: true,
            zoom: 4,
            pic: pic,
            geoFlowComponentElementName: '#geo-flow',
            generateHomeUrl: function({npi, entitytype}){
                if (entitytype == 1) {
                    return `/physician/${npi}/home`;
                } else if (entitytype == 2) {
                    return `/organization/${npi}/home`
                } else {
                    console.log('entitytype not provided');
                    return '';
                }
            },
            mapSizeCallback: function(map) {
                leafletInstance.current = map;
            },
            data: geoflowReportData
        });
    }

    return <>
        { props.data ? <div id="geo-flow" ref={elem} style={{height:'95%', width:'100%'}}></div> : <div>Network Integrity Component lacks data</div> }
    </>
}