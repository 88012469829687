import angular from 'angular';
import { react2angular } from 'react2angular';

import api from '../../services/api';
import pic from '../../services/pic';
import template from '../../services/template';

import './flow.scss';
import ngtemplate from './flow.html';
import controller from './flow.controller';

import { FlowComponent } from './flowComponent';

const componentName = 'flow';
const updatedComponentName = 'flowComponent';
const moduleName = 'app.reports.flow';

angular.module(moduleName, [
    api, pic, template
])
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<',
        sankeyData: '<'
    },
    require: {
        reportSankey: '^?sankey'
    },
    controller,
    controllerAs: '$ctrl',
    template: ngtemplate,
})
.component(
    updatedComponentName,
    react2angular(
        FlowComponent,
        ['npis', 'community'],
        ['api', 'pic', 'template']
    )
);

export {
    moduleName,
    componentName,
    updatedComponentName
};