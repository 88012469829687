import angular from 'angular';
import * as d3Base from 'd3';

import { legendColor } from 'd3-svg-legend';

const d3 = angular.extend({}, d3Base, { legendColor });

export default ['$scope', '$http', '$q', 'api', '$timeout', 'notify', function($scope, $http, $q, api, $timeout, notify) {

    var flattenedData;
    var aggregateData;
    var monthsRange;
    var options = {
        scales: {
            x: {
                fn: d3.scaleTime,
                accessor: 'date'
            },
            y: {
                fn: d3.scaleLinear,
                accessor: 'patients'
            },
            color: {
                fn: d3.scaleOrdinal,
                accessor: 'dataSource'
            }
        },
        axis: {
            x: {
                fn: d3.axisBottom,
                ticks: d3.timeMonth,
                tickFormat: {
                    formatFn: d3.timeFormat,
                    format: "%b %Y"
                }
            },
            y: {
                fn: d3.axisLeft,
                tickFormat: currentYAxisKey => currentYAxisKey === "charges" ? d3.format("$,.0f") : d3.format(",.0f")
            }
        }
    };

    var $ctrl = this;

    $ctrl.toggleAxis = axis => {
        Object.keys($scope.activeAxis).forEach(key => {
            if (key === axis) {
                $scope.activeAxis[key] = true;
            } else {
                $scope.activeAxis[key] = false;
            }
        });
    };

    $ctrl.toggleFilters = classSpecName => {

        var augmentedGraphData = aggregateData.reduce((acc, cur) => {
            
            acc.push(cur);//include current month standard dataSource
            
            var applicableCurMonthTaxonomies = cur.providers.reduce((taxonomyList, provider) => {
                var providerClassSpec = provider.taxonomy.toString();
                if ($scope.taxonomies[providerClassSpec] && taxonomyList.indexOf(providerClassSpec) === -1) {
                    taxonomyList.push(providerClassSpec);
                }
                return taxonomyList;
            }, []);
            
            if (applicableCurMonthTaxonomies.length > 0) {
                
                let applicableSeeds = applicableCurMonthTaxonomies.map(taxonomy => {
                    return {
                        patients: 0,
                        claims: 0,
                        charges: 0,
                        date: cur.date,
                        dataSource: `${cur.dataSource} - ${taxonomy}`,
                        providers: [],
                        npis: []
                    };
                });
                
                var taxonomyMonthDataSources = cur.sources.reduce((monthTaxonomiesAcc, curMonthSrc) => {
                    
                    var providerClassSpec = curMonthSrc.provider.taxonomy.toString();
                    
                    if ($scope.taxonomies[curMonthSrc.provider.taxonomy.toString()]) {
                        let seedIdx = monthTaxonomiesAcc.findIndex(taxonomySeed => taxonomySeed.dataSource === `${cur.dataSource} - ${providerClassSpec}`);
                        monthTaxonomiesAcc[seedIdx].patients += curMonthSrc.patients;
                        monthTaxonomiesAcc[seedIdx].claims += curMonthSrc.claims;
                        monthTaxonomiesAcc[seedIdx].charges += curMonthSrc.charges;
                        monthTaxonomiesAcc[seedIdx].providers.push(curMonthSrc.provider);
                        monthTaxonomiesAcc[seedIdx].npis.push(curMonthSrc.provider.npi);
                    }
                    
                    return monthTaxonomiesAcc;
                    
                }, applicableSeeds);
                
                return acc.concat(taxonomyMonthDataSources);
            } else {
                return acc;
            }

        }, []);

        var monthsByDataSourceAndTaxonomy = groupByProperty(augmentedGraphData, 'dataSource');

        monthsByDataSourceAndTaxonomy = fillMonthGaps(monthsByDataSourceAndTaxonomy);

        $scope.finalData = monthsByDataSourceAndTaxonomy;
    };

    $ctrl.$onInit = function() {
        $scope.taxonomies = {};
        var providers = {};
        var providersUrlSection;
        var endpointRequest;
        
        if ($ctrl.npis) {//physician or organization

            $ctrl.npis.forEach(provider => providers[provider.npi] = provider);// use already resolved provider(s)

            providersUrlSection = $ctrl.npis.map(provider => provider.npi.toString()).join(',');

            endpointRequest = $http.get(`/api/npi/${providersUrlSection}/trending/`, api.options());

        } else if ($ctrl.community) {// community

            endpointRequest = $q.all($ctrl.community.npis.map(npi => api.GetProvider(npi)))// communities only have a list of npis, we need to resolve them to providers
            .then(res => {
                res.forEach(provider => {
                    providers[provider.npi] = provider;//add to provider lookup
                    if (provider.taxonomy && !$scope.taxonomies[provider.taxonomy.toString()]) {
                        $scope.taxonomies[provider.taxonomy.toString()] = false;
                    }
                });
                return $http.get(`/api/cid/${$ctrl.community.id}/trending/`, api.options());
            });

        } else {
            console.log('not a provider or community');
            notify.error({
                text: 'Invalid Provider',
                hide: false
            });
            $scope.loaded = true;
            return;
        }

        if ($ctrl.community || $ctrl.npis.length > 1) {//don't sum unique patients (bad math), so don't provide the patients axis with multiple providers
            $scope.activeAxis = {
                claims: true,
                charges: false
            };
        } else {
            $scope.activeAxis = {
                patients: true,
                claims: false,
                charges: false
            };
        }

        endpointRequest
            .then(res => {

                var trendingData = res.data;

                flattenedData = Object.keys(trendingData).map(npi => {
                    return Object.keys(trendingData[npi]).map(dataSource => {
                        return Object.keys(trendingData[npi][dataSource]).map(yearMonth => {
                            var ret = trendingData[npi][dataSource][yearMonth];
                            ret.npi = npi;
                            ret.provider = providers[npi];
                            ret.date = new Date(
                                parseInt(yearMonth.slice(0,4)),
                                (parseInt(yearMonth.slice(5,7)) - 1)
                            );
                            ret.dataSource = dataSource.charAt(0).toUpperCase()+dataSource.slice(1);
                            return ret;
                        });
                    });
                })
                .flat(Infinity);

                aggregateData = Object.values(flattenedData.reduce((acc, cur) => {
                    var index = cur.date.getTime() + cur.dataSource;
                    var bin = acc[index];
                
                    if (!bin) {
                        acc[index] = bin = {
                            patients: 0,
                            claims: 0,
                            charges: 0,
                            providers: [],
                            npis: [],
                            dataSource: cur.dataSource,
                            date: cur.date,
                            sources: []
                         };
                    }

                    bin.patients += cur.patients;
                    bin.claims += cur.claims;
                    bin.charges += cur.charges;
                    bin.providers.push(cur.provider);
                    bin.npis.push(cur.npi);
                    bin.sources.push(cur);
                
                  return acc;
                }, {}));

                var finalData = groupByProperty(aggregateData, 'dataSource');

                monthsRange = createMonthsRange(finalData);
                finalData = fillMonthGaps(finalData);

                var dataSources = finalData.map(dataSource => dataSource.key);
                var taxonomies = Object.keys($scope.taxonomies);

                options.scales.color.domain = createColorDomain(dataSources, taxonomies);
                $scope.options = options;

                $timeout(()=>{//options must load first
                    $scope.finalData = finalData;
                    $scope.loaded = true;
                });
                
            })
            .catch(err => console.log('err', err));
    };

    function createColorDomain(dataSources, taxonomies) {
        var colorDomainSet = new Set();
        dataSources.forEach(ds => colorDomainSet.add(ds));
        taxonomies.forEach(tax => {
            dataSources.forEach(ds => {
                colorDomainSet.add(`${ds} - ${tax}`);
            });
        });
        return [...colorDomainSet];
    }

    function groupByProperty(list, propertyKey) {// nest arrays in object { key: string, values: array }
        return list.reduce((acc, cur) => {
            if (acc.indexOf(cur[propertyKey]) === -1 ) {
                acc.push(cur[propertyKey]);
            }
            return acc;
        },[]).map(propertyValue => {
            return {
                key: propertyValue,
                values: list.filter(obj => obj[propertyKey] === propertyValue)
            };
        });
    }
    
    function createMonthsRange(data) {
        
        var flattenedData = data.flatMap(lineObj => lineObj.values);
        var dateExtent = d3.extent(flattenedData, d => d.date);
        
        //https://github.com/d3/d3-time/tree/v1.0.8#interval_range
        //note: .range() Returns every an array of dates representing every interval boundary after or equal to start (inclusive) and before stop (exclusive)
        var monthsRange = d3.timeMonth
            .range(dateExtent[0], dateExtent[1])
            .concat(dateExtent[1]);//since stop month is excluded, add it to the end
            
        return monthsRange;
    }
    
    function fillMonthGaps(data) {
        
        return data.map((lineObj, dataSourceIdx, originalArr) => {
            return {
                key: lineObj.key,
                values: monthsRange.map(monthDate => {
                    var originalValueIdx = originalArr[dataSourceIdx].values.findIndex(month => {
                        //is original month found in the overall months
                        return `${month.date.getFullYear()}-${month.date.getMonth()+1}` === `${monthDate.getFullYear()}-${monthDate.getMonth()+1}`;
                    });
                    if (originalValueIdx === -1) {//empty month
                        return {
                            patients: null,
                            claims: null,
                            charges: null,
                            dataSource: lineObj.key,
                            date: monthDate
                        };
                    } else {//month exists
                        return originalArr[dataSourceIdx].values[originalValueIdx];
                    }
                })
            };
        });
    }

}];