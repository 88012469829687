import React, { useState, useEffect } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails } from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu } from '../../components/popoverMenu/popoverMenu';

import { RemoveMemberModalComponent } from '../../models/communityClass';

export default function MyCommunities({ provider, api, affiliations, pic, CommunityList, notify }) {
    const [ myCommunities, setCommunities ] = useState('');
    const [ community, setCommunity] = useState([]);
    const [ sharedCommunities, setsharedCommunities ] = useState([]);
    const [ index, setIndex ] = useState(0);
    const [ sharing, updateSharing] = useState(null);
    const [ watching, updateWatching] = useState(null);
    const [ originalCommunities, setoriginalCommunities ] = useState({});
    const [ hospitalAffiliates, setHospitalAffiliates ] = useState('');
    const [ groupAffiliates, setGroupAffiliates ] = useState('');
    const [ entitytype, setEntityType ] = useState('');
    const [ loading, setLoading ] = useState(true);

    const [ deleteMemberModalOpen, setDeleteMemberModalOpen ] = useState(false);
    const [ modalCommunity, setModalCommunity ] = useState(null);
    const [ modalProvider, setModalProvider ] = useState(null);

    function getformattedCommunities() {
        const Provider = provider;
        var communities = pic(Provider.npi, true).map(function(community) {
            originalCommunities[community.id] = community;
            return {
                'name': community.name,
                'abbr': community.abbr,
                'id': community.id,
                'owner': community.insert_user || community.owner,
                'is_public': community.is_public,
                'watched': community.watched,
                'owned': community.owned,
                'insert_date': community.insert_date ? new Date(community.insert_date).toDateString() : null,
                'npis': community.npis || null
            };
        });
        setCommunities(communities);
        return communities;
    }
    
    function getMyCommunities() {
        const myCommunities = getformattedCommunities().filter(community  => community.owned);
        setCommunities(myCommunities);
        return myCommunities;  
    }
    
    function getSharedCommunities() {
        const shared = getMyCommunities().filter(community => community.is_public );
        setsharedCommunities(shared);
    }
    
    function updateList() {    
        getMyCommunities();
        getSharedCommunities();
    }

    useEffect(() => {
        if (pic.ready) {
            async function onInit() {
                // const Provider = provider;    
                
        
                updateList();
            
            }
            onInit();
            updateCommunityList([provider]);
        }

        
    }, [pic.ready, provider]);

    const reportConfigMyCommunities = function() { 
        return {  
          tableConfig :  [
                {
                "header": {
                    "id": "col1a",
                    "accessor": "name",
                    "content": "Name (Abbreviation)",
                    "defaultVisibilty": true,
                    "export": false,
                    "template": function(content, rowObject) {
                        if (!rowObject) return
                        const name_popover = {
                            providerCollection: 
                                { name :  rowObject.name || '' , insert_date : rowObject.insert_date || "", insert_user: rowObject.insert_user || rowObject.owner || "", npis : rowObject.npis || '' }
                        }
                        return <>
                        <PopoverDetails {...name_popover}> <Link to={`/community/${rowObject.id}/home`}>{content} ({rowObject.abbr})</Link></PopoverDetails> 
                        <PopoverMenu providerCollection={rowObject}>
                                <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                </span></PopoverMenu>
                        </>
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                }
            },
                {
                    "header": {
                        "id": "col2a",
                        "accessor": "remove_member",
                        "content": "Remove Member",
                        "defaultVisibilty": true,
                        "export": false,
                        "template": function(content, community) { return (<>
                            <button className="btn btn-default btn-sm delete" onClick={() => deleteMember(originalCommunities[community.id], {api, provider, notify})}>
                                <i className="fa fa-minus-circle fa-lg" aria-hidden="true"></i>
                            </button>
                        </>)}
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3a",
                        "accessor": "share",
                        "content": "Share",
                        "defaultVisibilty": true,
                        "export": false,
                        "headerTooltip": "Allow other members in your account to view this community.",
                        "template": function(content, community) { return (
                            <button className={`btn btn-default btn-sm ${(community.is_public) ? 'select-true' : '' }`} onClick={() => share(originalCommunities[community.id])}>
                                <span style={{ 'display' : (sharing == community.id) ? 'none' : 'inline' }}><i className="fa fa-share-alt fa-lg" aria-hidden="true"></i></span>
                                <span style={{ 'display' : (sharing == community.id) ? 'inline' : 'none' }}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
                            </button>
                        )}
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4a",
                        "accessor": "watch",
                        "content": "Watch",
                        "defaultVisibilty": true,
                        "export": false,
                        "headerTooltip": "Identify members of this community in vizualizatons and reports.",
                        "template": function(content, community) { return(
                            <button className={`btn btn-default btn-sm ${(community.watched) ? 'select-true' : '' }`} onClick={() => watch(originalCommunities[community.id])}>
                                <span style={{ 'display' : (watching == community.id) ? 'none' : 'inline' }}><i className="fa fa-eye-slash fa-lg" aria-hidden="true"></i></span>
                                <span style={{ 'display' : (watching == community.id) ? 'inline' : 'none' }}><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
                                <span>{community.watched}</span>
                            </button>
                       )}
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                }
             ],
        configure : false,
        showSelected: false,
        filterInput: true,
        csvdownload: false
        }
    }

    var communities;
    

    function share(community) {
        updateSharing(community.id);
        community.Update({is_public: !community.is_public}, {api, notify, pic})
        .then(function() {
            updateSharing(null);
            updateList();
        });
    };

    const watch = function(community) {
        updateWatching(community.id);
        community.Update({watched: !community.watched}, {api, notify, pic})
        .then(function() {
            updateWatching(null);
            updateList();
        });
    };

    const deleteMember = function(community) {
        community.RemoveMember({provider : provider},{api: api, pic: pic},updateList)
    };

    const updateCommunityList = function(affiliationsToUse) {
        // CommunityList.update([...new Set(affiliationsToUse.map(afl => afl.npi).filter(afl => afl))]);
    };

  return (
    (myCommunities && myCommunities.length > 0)
        ? <>
            
            <PhtableComponent data={myCommunities} reportconfig={reportConfigMyCommunities}></PhtableComponent>
        </>
        : <div style={{ 'padding' : '20px'}}>No Results.</div>
  )
}

