import angular from 'angular';

import './home.scss';
import template from './home.html';
import controller from './home.controller';

import uibModal from 'angular-ui-bootstrap/src/modal';
import recentlySelected from '../../services/recentlySelected';
import serviceTemplate from '../../services/template';
import api from '../../services/api';
import localStorage from '../../services/localStorage';
import tour from '../../services/tour';

import 'leaflet/dist/leaflet.js';
import 'angular-simple-logger';
import 'ui-leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import '../../js/leafletIconOverride.js';

const componentName = 'communityHome';
const moduleName = 'app.reports.home.community';

angular.module(moduleName, [
    uibModal, recentlySelected, serviceTemplate, api, localStorage, tour, 'nemLogging', 'ui-leaflet'
])
.component(componentName, {
    bindings: {
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName
};