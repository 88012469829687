import React, { useState, useEffect } from "react";

import { useConfig, useStorage, useAuth } from '../../services/servicesContext';
import './footer.scss';

import footerLogo from '../../public/PerceptionHealth-powered-footer@2x.png';
import eventBus from "../../services/eventBus";
import { PopoverTTM } from "../popoverTTM/popoverTTM";

function processDataModels(models) {
    const retModels = models.map(function(model) {
        var codeArr = model.code.split('|');
        model.details = {
            relations: [],
            clinical: [],
            comment: ''
        };
        
        codeArr[0].split('/').forEach(function(code) {
            var match = code.match(/([0-9]{2})(M|C)([+]*)/);
            var year = 2000 + parseInt(match[1]);
            var notes = match[3] ? ' projected from ' + (year - match[3].length) : '';
            switch (match[2]) {
                case 'C':
                    model.details.relations.push(year + ' Commercial' + notes);
                    break;
                case 'M':
                    model.details.relations.push(year + ' Medicare' + notes);
                    break;
                default:
                    model.details.relations.push(year + ' ' + match[2] + notes);
                    break;
            }
        });
        
        codeArr[1].split('/').forEach(function (code) {

            var match = code.match(/([0-9]{2})(M|C)([+]*)/);
            var year = 2000 + parseInt(match[1]);
            var notes = match[3] ? ' projected from ' + (year - match[3].length) : '';

            switch (match[2]) {
                case 'C':
                    model.details.clinical.push(year + ' Commercial' + notes);
                    break;
                case 'M':
                    model.details.clinical.push(year + ' Medicare' + notes);
                    break;
                default:
                    model.details.clinical.push(year + ' ' + match[2] + notes);
                    break;
            }
        });
        
        switch (codeArr[2]) {
            case 'C':
                model.details.comment = 'Additional customizations have been applied';
                break;
            case 'GS':
                model.details.comment = 'National Market Adjustment Scalars';
                break;
            default:
                model.details.comment = codeArr[2];
        }

        return model;
    });

    //case insensitive ascending string sort
    retModels.sort((a,b) =>  a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1);

    return retModels;
}

export function FooterComponent(props) {

    const config = useConfig();
    const auth = useAuth();
    const storage = useStorage();

    const [ loading, setLoading ] = useState(true);
    const [ currentUser, setCurrentUser] = useState(null);
    const [ models, setModels ] = useState([]);
    const [ ttmMenuOpen, setTtmMenuOpen ] = useState(false);
    const [ hideTTM, setHideTTM ] = useState(false);

    const currentYear = new Date().getFullYear();

    function changeDataModel(model) {
        setLoading(true);
        storage.setStorage('ttm', model.name)
        .then(res => {
            setLoading(false);
            window.location.reload();//to clear memory cache
        }, err => {
            console.log('setStorage failure', err);
            setLoading(false);
        });
    }

    useEffect(() => {
        async function init() {
            try {
                const currentUserRes = await auth.currentUser();
                setModels(processDataModels(config.models()));
                setCurrentUser(currentUserRes);
                setLoading(false);
            } catch (err) {
                console.error('error in FooterComponent init', err);
            }
        }
        if (config.ready) {
            init();
        }
        init();
        //Fired dashboard components only
        eventBus.on("hideTTM", () => {
            setHideTTM(true);
        });
        eventBus.on("showTTM", () => {
            setHideTTM(false);
        });

        return () => {
            //Cleanup eventBus listeners
            eventBus.remove("hideTTM");
            eventBus.remove("showTTM");
        };
    }, [config]);

    return <div className="footer-wrapper">
        <div className="footer">
        <img className="powered-by-logo" src={footerLogo} width="220" />
    
        {
        hideTTM ? ""
        :
        <div className="data-model-list text-center dropup pointer dropdown open">
            <h4 className="v-center" onClick={e => setTtmMenuOpen(!ttmMenuOpen)}>
                { !loading && currentUser
                    ? <a>{ currentUser.dataModel()?.display_name } <i className="fa fa-fw fa-caret-square-o-up" aria-hidden="false"></i></a>
                    : <a> <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i></a>
                }
            </h4>
            { ttmMenuOpen && <ul className="dropdown-menu">
                { models.map((model, index) => {
                    return <PopoverTTM {...model}><li key={model.name + "_" + index} className="ttm-data-model-list-item">
                            <a onClick={e => changeDataModel(model)} className="ttm-data-model-list-a">    
                            {model.display_name}
                                { currentUser && model.display_name === currentUser.dataModel()?.display_name ? <span><i className="fa fa-check pull-right"></i></span> : null}
                            </a>
                            
                    </li></PopoverTTM>;
                })}
            </ul>}
        </div>
        }
        <p className="text-muted credit">
            <span>{'\u00A9'} {currentYear}</span>
            {' '}
            <a href="http://www.perceptionhealth.com" target="_blank">Perception Health</a>
            <br />
            <span>All rights reserved. {config.version()}</span>
        </p>
        </div>
    </div>;
}