import angular from 'angular';

import uibModal from 'angular-ui-bootstrap/src/modal';

import configModalTemplate from './configModal.html';
import phTableTemplate from './phTable.html';

import CommunityList from '../../services/communityList';
import download from '../../services/download';
import localStorage from '../../services/localStorage';
import dndModule from '../dndWrapper/dndLists';

export default 'phTable';

angular.module('phTable', [
    CommunityList, download, uibModal, localStorage, dndModule
])
.directive('phTable',['$templateCache','$interpolate', '$compile', 'download', '$uibModal', 'CommunityList', function($templateCache, $interpolate, $compile, download, $uibModal, CommunityList) {
    
    var defaults = {
        export: {
            name: 'report'
        },
        search: false,
        nested: false,
        communityList: {
            accessor: false,
            description: 'Toggle between the list of providers on the page you were viewing and the list of providers from the full report.'
        }
    };
    
    function computeColumns(columns, reportConfig) {
        
        columns = Object.values(columns);

        if (reportConfig) {
            
            var visibleFields = reportConfig.visibleFields.map(column => column.headerLabel);
            columns = columns.filter(column => {
                if (visibleFields.indexOf(column.header.heading) > -1) {
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            columns = columns.filter(column => {
                if (column.header.defaultVisibilty && column.header.defaultVisibilty === 'hidden') {
                    return false;
                } else {
                    return true;
                }
            });
        }
        
        return columns;
    }
    
    return {
        scope: {
            data: '<?',
            opts: '<?',
            disableSort: '<?'
        },
        priority: -2,
        restrict: 'E',
        transclude: {
            buttons: '?buttons'
        },
        template: phTableTemplate,
        controllerAs: '$ctrl',
        controller: ['$scope', '$templateCache', '$interpolate', '$parse', '$sce', '$compile', '$filter', '$localStorage', '$state', function($scope, $templateCache, $interpolate, $parse, $sce, $compile, $filter, $localStorage, $state){
            var $ctrl = this;
            
            var registeredColumns = {};//keep arround reference to columns with compiled values for re-used in add/remove/re-order on ph-table instance
            
            $scope.filter = '';
            
            $scope.mergedOpts = this.opts = angular.merge({}, defaults, $scope.opts);
            
            $ctrl.storageKey = $scope.mergedOpts.localStorageKey ? `${$state.current.name}.${$scope.mergedOpts.localStorageKey}` : $state.current.name;
            
            $scope.$watch('opts.forceColumns', function(newVal, oldVal){
                if (newVal) {
                    $scope.mergedOpts.forceColumns = $ctrl.opts.forceColumns = newVal;
                    
                    if (oldVal) {//remove previous forced columns
                        let configFields = $localStorage.getObj($ctrl.storageKey);
                        let visibleFields = configFields ? configFields.visibleFields.map(field => field.id) : [];
                        oldVal.forEach(removeVal => {
                            $scope.columns = $scope.columns.filter(col => {
                                if (col.id !== removeVal.colId || (visibleFields.length > 0 && visibleFields.indexOf(col.id) > -1)) {
                                    //if current column isn't set to be removed or is a visible field, keep it
                                    return true;
                                }
                                
                            });
                        });
                    }
                    
                    let activeColumnsIdSet = new Set($scope.columns.map(col => col.id));
                    let newValColIdSet = new Set(newVal.map(val => val.colId));
                    let intersection = new Set(
                        [...activeColumnsIdSet].filter(x => newValColIdSet.has(x))
                    );
                    
                    if (intersection.size === 0) {// forced column not currently in table
                        newVal.forEach(addedCol => {// add new forced columns
                            $scope.columns.splice(addedCol.idx, 0, registeredColumns[addedCol.colId]);
                        });
                    }
                }
            });
            
            $scope.$watch('data', function(newVal, oldVal){
                 if (newVal) {
                    
                    var sortColumn = $scope.columns.filter(column => column.header.sort)[0];
                    
                    if (sortColumn) {//if one of the columns has a sort attr
                        if (sortColumn.header.sort === 'desc') {
                            newVal.sort((a, b) =>  {
                                return (sortColumn.header.accessor(a) > sortColumn.header.accessor(b)) ? -1 : 1;
                            });
                        } else {
                            newVal.sort((a, b) => {
                                return (sortColumn.header.accessor(b) > sortColumn.header.accessor(a)) ? -1 : 1;
                            });
                        }
                    }
                    
                    $scope.allRows = newVal;
                    
                    if ($scope.mergedOpts.pagination?.disable) {
                        $scope.rows = newVal.slice();
                    } else {
                        $scope.rows = newVal.slice((($scope.page.current-1)*$scope.page.items), $scope.page.items);
                    }
                    
                    updateCommunityList($scope.rows, $scope.allRows);
                 }
            });
            
            $scope.$watch('filter', function(newVal, oldVal){
                if (newVal) {
                    var filteredRows = $filter('filter')($scope.data, newVal);
                    var sortColumn = $scope.columns.filter(column => column.header.sort)[0];
                    var sortedData;
                    
                    if (sortColumn) {
                        if (sortColumn.header.sort === 'desc') {
                            sortedData = filteredRows.slice().sort((a, b) =>  {
                                return (sortColumn.header.accessor(a) > sortColumn.header.accessor(b)) ? -1 : 1;
                            });
                        } else {
                            sortedData = filteredRows.slice().sort((a, b) => {
                                return (sortColumn.header.accessor(b) > sortColumn.header.accessor(a)) ? -1 : 1;
                            });
                        }
                    } else {
                        sortedData = filteredRows.slice();
                    }
                    
                    $scope.allRows = sortedData;
                    
                    if ($scope.mergedOpts.pagination?.disable) {
                        $scope.rows = sortedData.slice();
                    } else {
                        $scope.rows = sortedData.slice((($scope.page.current-1)*$scope.page.items), $scope.page.items);
                    }
                    
                    updateCommunityList($scope.rows, $scope.allRows);
                }
            });
            
            $scope.$watch('page.current', function(newVal, oldVal){
                if (newVal && $scope.data) {
                    
                    var sortColumn = $scope.columns.filter(column => column.header.sort)[0];
                    
                    var sortedData;
                    if (sortColumn) {
                        if (sortColumn.header.sort === 'desc') {
                            sortedData = $scope.allRows.slice().sort((a, b) =>  {
                                return (sortColumn.header.accessor(a) > sortColumn.header.accessor(b)) ? -1 : 1;
                            });
                        } else {
                            sortedData = $scope.allRows.slice().sort((a, b) => {
                                return (sortColumn.header.accessor(b) > sortColumn.header.accessor(a)) ? -1 : 1;
                            });
                        }
                    } else {//no sort on active columns
                        sortedData = $scope.allRows.slice();
                    }
                    
                    $scope.allRows = sortedData;
                    $scope.rows = sortedData.slice(((newVal-1)*$scope.page.items), (newVal*$scope.page.items));
                    updateCommunityList($scope.rows, $scope.allRows);
                }
            });
            
            $scope.$watch('page.items', function(newVal, oldVal){
                if (newVal && $scope.data) {
                    
                    var sortColumn = $scope.columns.filter(column => column.header.sort)[0];
                    
                    var sortedData;
                    
                    if (sortedData) {
                        if (sortColumn.header.sort === 'desc') {
                            sortedData = $scope.allRows.slice().sort((a, b) =>  {
                                return (sortColumn.header.accessor(a) > sortColumn.header.accessor(b)) ? -1 : 1;
                            });
                        } else {
                            sortedData = $scope.allRows.slice().sort((a, b) => {
                                return (sortColumn.header.accessor(b) > sortColumn.header.accessor(a)) ? -1 : 1;
                            });
                        }
                    } else {
                        sortedData = $scope.allRows.slice();
                    }
                    
                    $scope.page.current = 1;
                    
                    $scope.allRows = sortedData;
                    $scope.rows = sortedData.slice((($scope.page.current-1)*$scope.page.items), ($scope.page.current*$scope.page.items));
                    updateCommunityList($scope.rows, $scope.allRows);
                }
            });
            
            $scope.$watch('columns', function(newVal, oldVal) {
                if (newVal && $scope.data) {
                    var filteredRows = $filter('filter')($scope.data, $scope.filter);
                    var sortColumn = $scope.columns.filter(column => column.header.sort)[0];
                    var sortedData;
                    
                    if (sortColumn) {//if one of the columns has a sort
                        if (sortColumn.header.sort === 'desc') {
                            sortedData = filteredRows.slice().sort((a, b) =>  {
                                return (sortColumn.header.accessor(a) > sortColumn.header.accessor(b)) ? -1 : 1;
                            });
                        } else {
                            sortedData = filteredRows.slice().sort((a, b) => {
                                return (sortColumn.header.accessor(b) > sortColumn.header.accessor(a)) ? -1 : 1;
                            });
                        }
                    } else {
                        sortedData = filteredRows.slice();
                    }
                    
                    $scope.allRows = sortedData;
                    
                    if ($scope.mergedOpts.pagination?.disable) {
                        $scope.rows = sortedData.slice();
                    } else {
                        $scope.rows = sortedData.slice((($scope.page.current-1)*$scope.page.items), $scope.page.items);
                    }
                    
                    updateCommunityList($scope.rows, $scope.allRows);
                }
            });
            
            $scope.page = {
                current: 1,
                items: "10" // string due to value attr of option in markup
            };

            $scope.rows = $scope.data || $scope.phTable;

            $scope.columns = [];

            $scope.currentSortAccessor = row => {
                var col = $scope.columns.filter(column => column.header.sort);
                if (col.length > 0) {
                    return col[0].header.accessor(row);
                }
            };
            
            $scope.currentSortDesc = () => {
                var col = $scope.columns.filter(column => column.header.sort);
                if (col.length > 0) {
                    return col[0].header.sort === 'desc' ? true : false;
                }
            };

            $scope.sorted = (index) => {
                
                if ($scope.disableSort) {
                    return;
                }
                
                var columnClicked = $scope.columns.filter((col, idx) => idx === index)[0];
                
                if (columnClicked.header.disableSort) {
                    return;
                }
                
                var sortColumn;
                
                $scope.columns.forEach((column, i) => {
                    if (column.header.disableSort) {
                        return;
                    } else if (i === index) {
                        if (column.header.sort) {
                            column.header.sort = (column.header.sort === 'desc') ? 'asc' : 'desc';
                        } else {
                            column.header.sort = 'desc';
                        }
                        sortColumn = column;
                    } else {
                        column.header.sort = null;
                    }
                });
                
                if (sortColumn) {
                    if (sortColumn.header.sort === 'desc') {
                        $scope.allRows.sort((a, b) =>  {
                            return (sortColumn.header.accessor(a) > sortColumn.header.accessor(b)) ? -1 : 1;
                        });
                    } else {
                        $scope.allRows.sort((a, b) => {
                            return (sortColumn.header.accessor(b) > sortColumn.header.accessor(a)) ? -1 : 1;
                        });
                    }
                }
                
                $scope.page.current = 1;
                
                if ($scope.mergedOpts.pagination?.disable) {
                    $scope.rows = $scope.allRows.slice();
                } else {
                    $scope.rows = $scope.allRows.slice((($scope.page.current-1)*$scope.page.items), $scope.page.items);
                }
                
                updateCommunityList($scope.rows, $scope.allRows);
            };
            
            function registerColumn(headerConfig, rowConfig) {
                
                var id = headerConfig.id || ($scope.$id + '-' + Math.floor(Math.random() * 10000));
                
                registeredColumns[id] = {
                    id: id,
                    content: rowConfig.content,
                    header: headerConfig,
                    row: rowConfig
                };
                
            }
            
            function updateCommunityList(visibleRows, allRows) {
                
                if ($scope.mergedOpts.communityList.accessor && !$scope.mergedOpts.search) {
                    
                    var accessorFn;
                    
                    if (typeof $scope.mergedOpts.communityList.accessor === 'function') {
                        accessorFn = $scope.mergedOpts.communityList.accessor;
                    } else if (typeof $scope.mergedOpts.communityList.accessor === 'string') {
                        accessorFn = $parse($scope.mergedOpts.communityList.accessor);
                    } else {
                        console.log('Community list accessor improperly defined');
                        return;
                    }
                    
                    var visibleDistinctNpis = [...new Set(
                        visibleRows.map(row => accessorFn(row)).flat()
                    )];

                    var allDistinctNpis = [...new Set(
                        allRows.map(row => accessorFn(row)).flat()
                    )];
                    
                    $ctrl.communityList.update(visibleDistinctNpis, allDistinctNpis, $scope.mergedOpts.communityList.description);
                }
            }
            
            $ctrl.registerColumn = registerColumn;
            
            $ctrl.communityList = this.opts.communityList.service || CommunityList;//this is where search's special overrides happen
            
            $ctrl.computeColumns = function() {
                var reportConfig = $localStorage.getObj($ctrl.storageKey);
                $scope.columns = computeColumns(registeredColumns, reportConfig);
            };
            
            $ctrl.export = function() {

                var CSVdata;
                var columnOrder;
                var title = $scope.mergedOpts.export.name;
                var tableConfig = $localStorage.getObj($ctrl.storageKey);
                var allRowsPrepped;
                
                // returns a copy of all rows in a table, and un-nests if needed
                function getApplicableRows() {
                    //if config indicates nested data should be expanded for export
                    if ($scope.mergedOpts.export.nested) {
                        let nestedAccessor = $scope.mergedOpts?.nested;
                        return $scope.allRows.reduce((acc, row) => {
                            acc.push(row);
                            nestedAccessor(row).forEach(nestedRow => {
                                acc.push(nestedRow);
                            });
                            return acc;
                        }, []);
                    } else {
                        return [...$scope.allRows];
                    }
                }
                
                if (tableConfig) {// tableconfig found in localstorage
                    
                    let allExportFields = tableConfig.visibleFields.concat(tableConfig.hiddenFields).filter(field => field.export);
                    
                    // handle locked columns when a tableconfig exists
                    if ($scope.mergedOpts?.forceColumns?.length > 0) {
                        let exportFields = allExportFields.map(field => field.id);
                        $scope.mergedOpts.forceColumns.forEach(col => {
                            if (exportFields.indexOf(col.colId) === -1) {//forced column not in export fields
                                let headerLabel = [ ...tableConfig.hiddenFields, ...tableConfig.visibleFields].filter(fld => fld.id === col.colId)[0].headerLabel;
                                allExportFields.splice(col.idx, 0, {headerLabel, id: col.colId});//splice forced columns into export columns list
                            }
                        });
                    }
                    
                    columnOrder = allExportFields.map((field, i) => [field.headerLabel, i]);
                    
                    // get the needed rows for export
                    allRowsPrepped = getApplicableRows();
                    
                    // CSVdata = $scope.allRows.map(row => {
                    CSVdata = allRowsPrepped.map(row => {
                        var ret = {};
                        allExportFields.forEach(field => {
                            ret[field.headerLabel] = registeredColumns[field.id].header.accessor(row);
                        });
                        return ret;
                    });
                    
                } else {// no tableconfig found in localstorage
                    
                    //filter out columns without heading or accessor, e.g. search page checkbox columns
                    let columns = $scope.columns.filter(column => (column.header.accessor && column.header.heading));
                    
                    columnOrder = columns.map((column, i) => {
                        return [column.header.heading, i];
                    });
                    
                    // get the needed rows for export
                    allRowsPrepped = getApplicableRows();
                    
                    // CSVdata = $scope.allRows.map(row => {
                    CSVdata = allRowsPrepped.map(row => {
                        var ret = {};
                        columns.forEach(column => {
                            ret[column.header.heading] = column.header.accessor(row);
                        });
                        return ret;
                    });
                }
                
                download.downloadCSV(CSVdata, title, true, columnOrder);
            };
            
            function configureAndRegisterColumns(columns, registerColumnFunc, scope) {
                columns.forEach((column) => {
                    
                    var headerConfig = { // Header Config
                        id: column.header.id,
                        class: $parse(column.header.class||'')(scope),
                        style: column.header.style,
                        content: $compile('<span>'+column.header.content+'</span>')(scope),
                        heading: column.header.content,
                        sort: column.header.sort,
                        defaultVisibilty: column.header.default,
                        tooltip: column.header.tooltip,
                        template: column.header.template ? $compile('<span>'+column.header.template+'<span>')(scope) : undefined
                    };
                    
                    var rowConfig = {
                        class: $parse(column.row.class||'')(scope),
                        style: $compile('<span>'+column.row.style+'</span>'),
                        content: $compile(column.row.content)
                    };
                    
                    if (column.header.class) {
                        scope.$watch(column.header.class, function() { 
                             headerConfig.class = $parse(column.header.class)(scope);
                        });
                    }
                    
                    if (column.row.class) {
                        scope.$watch(column.row.class, function() { 
                             rowConfig.class = $parse(column.row.class)(scope);
                        });
                    }
                    
                    if (column.header.disableSort) {
                        headerConfig.disableSort = !!column.header.disableSort; 
                    }
                    
                    if (column.header.accessor) {
                        headerConfig.accessor = $parse(column.header.accessor);
                    }
                    
                    registerColumnFunc(headerConfig, rowConfig);
                });
            }
            
            $ctrl.configureAndRegisterColumns = configureAndRegisterColumns;
            
            $ctrl.configReport = function() {
                var modalInstance = $uibModal.open({
                    template: configModalTemplate,
                    controllerAs: '$ctrl',
                    resolve: {
                        phTableScope: function() {
                            return $scope;
                        },
                        reportScope: function() {
                            return $scope.$parent;
                        }
                    },
                    bindings: {
                        resolve: '<'
                    },
                    controller: ['$scope', '$uibModalInstance', '$localStorage', 'reportScope', 'phTableScope', '$state', function($scope, $uibModalInstance, $localStorage, reportScope, phTableScope, $state) {
                        var $ctrl = this;
                        
                        //retrieve previous fieldstate from localstorage and hide forced columns
                        function getFieldState() {
                            var fieldsState = $localStorage.getObj(phTableScope.$ctrl.storageKey);
                            if (fieldsState && phTableScope.opts.forceColumns) {
                                // if there are columns being forced to a specific position via options,
                                // hide them in the modal since they will just confuse users and possibly create corrupt configs in localstorage
                                var forcedColumns = phTableScope.opts.forceColumns.map(col => col.colId);
                                fieldsState.hiddenFields = fieldsState.hiddenFields.map(field => {
                                    if (forcedColumns.indexOf(field.id) > -1) {
                                        field.hide = true;
                                    }
                                    return field;
                                });
                                fieldsState.visibleFields = fieldsState.visibleFields.map(field => {
                                    if (forcedColumns.indexOf(field.id) > -1) {
                                        field.hide = true;
                                    }
                                    return field;
                                });
                            }
                            return fieldsState;
                        }
                        
                        function setModalDefault() {
                            $localStorage.setObj(
                                phTableScope.$ctrl.storageKey,
                                phTableScope.$ctrl.rawColumns.reduce((acc, column) => {
                                    if (column.header.default === "hidden") {
                                        acc.hiddenFields.push({
                                            headerLabel: column.header.content,
                                            export: column.header.accessor ? false : null,
                                            id: column.header.id
                                        });
                                    } else {
                                        acc.visibleFields.push({
                                            headerLabel: column.header.content,
                                            export: column.header.accessor ? true : null,
                                            id: column.header.id
                                        });
                                    }
                                    return acc;
                                }, {hiddenFields: [], visibleFields: []})
                            );
                            
                            $scope.fieldsState = getFieldState();
                        }
                        
                        $ctrl.$onInit = function () {
                            $scope.fieldsState = getFieldState();
                            if (!$scope.fieldsState) {
                                //nothing in localstorage
                                setModalDefault();
                            }
                        };
                        
                        $ctrl.swapColumn = function(idx, source, target) { 
                            var fieldToMove = $scope.fieldsState[source].splice(idx, 1)[0];
                            $scope.fieldsState[target].splice(idx, 0, fieldToMove);
                        };
                        
                        $ctrl.move = function(idx, direction, list) {
                            $scope.fieldsState[list].splice((idx + direction), 0, $scope.fieldsState[list].splice(idx, 1)[0]);
                        };
                        
                        $ctrl.save = function() {
                            
                            $localStorage.setObj(phTableScope.$ctrl.storageKey, $scope.fieldsState);
                            
                            var tableConfig = $scope.fieldsState.visibleFields.map(field => field.headerLabel);
                            
                            var newColumns = tableConfig.map((colName, idx, src) => {
                                return Object.values(registeredColumns).filter(column => {
                                    return column.header.heading == colName;
                                })[0];
                            });
                            
                            if (phTableScope.opts.forceColumns && phTableScope.opts.forceColumns.length > 0) {
                                phTableScope.opts.forceColumns.forEach(forcedColumn => {
                                    newColumns.splice(forcedColumn.idx, 0, registeredColumns[forcedColumn.colId]);
                                });
                            }
                            
                            phTableScope.columns = newColumns;
                            
                            $uibModalInstance.close();
                        };
                        
                        $ctrl.reset = function() {
                            
                            $localStorage.remove(phTableScope.$ctrl.storageKey);
                            setModalDefault();
                            
                            var tableConfig = $scope.fieldsState.visibleFields.map(field => field.headerLabel);
                            
                            var newColumns = tableConfig.map((colName, idx, src) => {
                                return Object.values(registeredColumns).filter(column => {
                                    return column.header.heading == colName;
                                })[0];
                            });
                            
                            if (phTableScope.opts.forceColumns && phTableScope.opts.forceColumns.length > 0) {
                                phTableScope.opts.forceColumns.forEach(forcedColumn => {
                                    newColumns.splice(forcedColumn.idx, 0, registeredColumns[forcedColumn.colId]);
                                });
                            }
                            
                            phTableScope.columns = newColumns;
                        };
                        
                        $ctrl.cancel = function () {
                            $uibModalInstance.close();
                        };
                    }]
                });
            };
            
        }]
    };
}])
.directive('phTable', ['$compile', '$interpolate', '$parse', '$localStorage', '$state', function($compile, $interpolate, $parse, $localStorage, $state) {
    return {
        require: 'phTable',
        restrict: 'E',
        priority: -1,
        compile: function(tElement) {
            
            var columns = tElement
                .children('ph-table-column')
                .toArray()
                .map(function(node, i) {
                    
                    var column = angular.element(node);
                    
                    return {
                        header: {
                            id: column.attr('id'),
                            class: column.attr('header-class'),
                            style: column.attr('header-style'),
                            sort: column.attr('sort'),
                            accessor: column.attr('accessor'),
                            content: column.attr('header-content'),
                            default: column.attr('default'),
                            tooltip: column.attr('header-tooltip'),
                            disableSort: column.attr('disable-sort'),
                            template: column.attr('header-template')
                        },
                        row: {
                            class: column.attr('row-class'),
                            style: column.attr('style'),
                            content: column.contents()
                        }
                    };
                    
                });
            
            return function(scope, element, attribute, controller) {
                
                controller.rawColumns = columns;
                
                var tableConfig = $localStorage.getObj(controller.storageKey);
                
                if (!tableConfig) {
                    
                    tableConfig = columns.reduce((acc, column) => {
                        if (column.header.default === "hidden") {
                            acc.hiddenFields.push({
                                headerLabel: column.header.content
                            });
                        } else {
                            acc.visibleFields.push({
                                headerLabel: column.header.content
                            });
                        }
                        return acc;
                    }, {hiddenFields: [], visibleFields: []});
                    
                }
                
                let tableFieldLookup = columns.reduce((acc, column) => {
                    acc[column.header.content] = column;
                    return acc;
                },{});
                
                controller.configureAndRegisterColumns(
                    tableConfig.visibleFields
                        .map(field => field.headerLabel).map(key => tableFieldLookup[key])
                        .concat(tableConfig.hiddenFields.map(field => field.headerLabel)
                        .map(key => tableFieldLookup[key])),
                    controller.registerColumn,
                    scope
                );
                
                controller.computeColumns();
            };
        },
        
    };
}])
.directive('phBindDom', function() { // Helper to bind a dom object
    return {
        scope: {
            elem: '<phBindDom',
            scope: '<?scope'
        },
        link: function(scope, elem, attrs) {
            if (scope.scope) {
                var newScope = scope.$new(true);
                angular.extend(newScope, scope.scope);
                try { 
                    scope.elem(newScope, function(clone) {
                        elem.prepend(clone);
                    });
                } catch (ex) {
                    console.log('The elem:', scope.elem);
                    console.log('The ex:', ex);
                    elem.prepend(scope.elem);
                }
            } else {
                elem.prepend(scope.elem);
            }
        }
    };
});