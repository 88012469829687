import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { useLogging } from '../../services/servicesContext';
import { PerformanceReportComponent } from '../performanceReport.provider/performanceReportComponent';
import { PerformancefunnelComponent } from '../performancefunnel.provider/performancefunnelComponent';
export function PerformanceProvider({ api, codes, notify, download, tour }){

    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const [ currentTab, setCurrentTab] = useState("1");
    const [ loading, setLoading] = useState(false);
    const logging = useLogging();

    const renderTabs = () => {
        if (currentTab === "1") return <PerformanceReportComponent npis={npis} api={api} codes={codes} notify={notify} download={download} tour={tour}></PerformanceReportComponent>
        if (currentTab === "2") return <PerformancefunnelComponent npis={npis} api={api} codes={codes} notify={notify} download={download} tour={tour}></PerformancefunnelComponent>  
    }

    const changeTabs = (e) => {
        setCurrentTab(e.target.id);
    }

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.performance`
        });

        if (currentTab === "2") {// on load of new provider, reset table to the report instead of the funnel
            setCurrentTab("1");
        }
        
    }, [npis]);

    return <>
        <ul className="nav nav-tabs">
            <li id="service_report" className={currentTab === "1" ? "active" : ""}><a id="1" onClick={(e) => changeTabs(e)}>Services Report</a></li>
            <li id="service_funnel" className={currentTab === "2" ? "active" : ""}><a id="2" onClick={(e) => changeTabs(e)}>Services Funnel</a></li>
        </ul>
        <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
        {renderTabs()}
    </>;
}