import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$http', 'tour', 'notify', 'Taxonomy', 'api', 'community', function($scope, $http, tour, notify, Taxonomy, api, community) {
    var $ctrl = this;
        
    $ctrl.$onInit = function() {
            
            var entity = $ctrl.community || $ctrl.npis[0];
            
            $scope.tourName = $ctrl.community ? $ctrl.community.name : $ctrl.npis[0].name.display;
            
            
            tour.setScript(tourConfig, $scope);
            
            var reportType = $ctrl.community ? 'community' : 'npi';
            buildTable(entity, reportType);
        };
        
    function buildTable(entity, reportType) {
            
            var entityName = $scope.entityName = entity.name.display || entity.name;
            var entityId = entity.npi || entity.id;
            
            $scope.reportOptions = { 
                export: {
                    name: "by Speciality for " + entityName
                },
                sort: {
                    column: 'sharedTo'
                }
            };
            
            $http.get('/api/' + reportType + '/' + entityId + '/taxonomy/', api.options())
            .then(function(res) {
                $scope.loaded = true;
                if (res.data.length === 0) {
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Specialty Report Data found for entity.',
                        delay: 30000
                    });
                }
                $scope.data = res.data.map(function(datum) {
                    return {
                        'taxonomy': new Taxonomy(datum),
                        'sharedFrom': datum.fromsharedtransactioncount,
                        'uniqueFrom': datum.frompatienttotal,
                        'sameday': datum.samedaytotal,
                        'uniqueTo': datum.topatienttotal,
                        'sharedTo': datum.tosharedtransactioncount,
                        'taxonomyType': datum.taxonomytype
                    };
                });
            }, function(err){
                console.log('Error fetching Speciality report data: ', err);
                $scope.loaded = true;
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Speciality report data.',
                    delay: 30000
                });
            });
        }
}];