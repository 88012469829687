import angular from 'angular';

import template from './organization.html';
import controller from './organization.controller';
import { react2angular } from 'react2angular';
import phTable from '../../directives/phTable/phTable';
import pic from '../../services/pic';
import tour from '../../services/tour';
import { OrganizationComponent } from './organizationComponent.js';
import api from '../../services/api';
import download from '../../services/download';

const componentName = 'organization';
const moduleName = 'app.reports.organization';
const updatedComponentName = 'organizationComponent';

angular.module(moduleName, [
    phTable, tour, api
])
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(OrganizationComponent, ['npis', 'opts', 'community'], ['api', 'pic', 'download'])
);
export {
    moduleName,
    componentName,
    updatedComponentName
};