import React, { useState, useEffect } from 'react'
import { useRouteLoaderData } from "react-router-dom";

import { useApi, usePic, useTemplate } from '../../services/servicesContext';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import './summaryPanels.scss';
export default function SummaryPanelsComponent({ notify}) {
    const [Provider, setProvider] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SharedVisits, setSharedVisits] = useState({});
    const [leakageObject, setLeakageObj] = useState({});
    const [chargesObject, setChargesObj] = useState({});
    const [cptObject, setCptObj] = useState({});
    const npis = useRouteLoaderData('physician.npis')
    const api = useApi();

    useEffect(() => {
        onInit();
    }, []);

    const onInit = async function() {
        const provider = npis;

        function getAbbrName(name) {
            if (name.length > 16) {
                return name.slice(0,15) + '...';
            } else {
                return name;
            }
        }

        const toTitleCase = (str) => {
            return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        }

        var displayLocation = ' providers in ' + toTitleCase(provider.location.city) + ', ' + provider.location.state;
        var displaySpecialty = provider.taxonomy.specialization ? provider.taxonomy.specialization : provider.taxonomy.classification;
        
        setLoading(true);
        await fetch('/api/npi/' + provider.npi + '/total_charges/', api.options())
            .then(res => res.json())
            .then(function(res) {
                var data = res;
         
            // leakage panel
            const leakageObj = {}
            if (!data.leakagedata) {
                    leakageObj['dominantHospitalAbbr'] = 'No Data';
                    leakageObj['leakageBlurb'] = 'No Data';
                    leakageObj['leakage'] = 'No Data';
                    leakageObj['leakagePanelColor'] = 'default';
            } else {
                leakageObj['dominantHospitalAbbr'] = getAbbrName(data.leakagedata.data.secondnpiname);
                leakageObj['leakageBlurb'] = "Dominant Hospital: " + toTitleCase(data.leakagedata.data.secondnpiname);
                leakageObj['leakage'] = (1 - data.leakagedata.data.marketshare);
            
                if (leakageObj['leakage'] < 0.25){
                    leakageObj['leakagePanelColor'] = 'primary';
                } else if (leakageObj['leakage'] > 0.25 && leakageObj.leakage < 0.50){
                    leakageObj['leakagePanelColor'] = 'yellow';
                } else {
                    leakageObj['leakagePanelColor'] = 'red';
                }
            }
            
            setLeakageObj(leakageObj);

            // charges panel
            const chargesObj = {}
            if (!data && !data.npidata && !data.npidata.npicharges) {
                    chargesObj['npiCharges'] = 0;
                    chargesObj['chargesPanelColor'] = 'default';
                    chargesObj['chargesBlurb'] = 'No Data';
            } else {
                chargesObj['npiCharges'] = data.npidata.npicharges;
            
                var chargesThreshold;
                if (data.npidata.npicharges > (data.npidata.avgcharges + data.npidata.stddevcharges*1)) {
                    chargesObj['chargesPanelColor'] = 'green';
                    chargesThreshold = "'s total charges are significantly more than ";
                } else if (data.npidata.npicharges < (data.npidata.avgcharges - data.npidata.stddevcharges*1)) {
                    chargesObj['chargesPanelColor'] = 'red';
                    chargesThreshold = "'s total charges are significantly less than ";
                } else if (data.npidata.npicharges < (data.npidata.avgcharges + data.npidata.stddevcharges*1) && (data.npidata.npicharges > (data.npidata.avgcharges - data.npidata.stddevcharges*1))) {
                    chargesObj['chargesPanelColor'] = 'yellow';
                    chargesThreshold = "'s total charges are on par with other ";
                }

                chargesObj['chargesBlurb'] = data.npidata.npicharges ? toTitleCase(provider.name.display) + chargesThreshold + displaySpecialty + displayLocation : 'No Data';
            }
            
            setChargesObj(chargesObj);

            // code panel
            const cptObj = {}
            if (!data.npidata.hcpcs_code) {
                cptObj['cptCode'] = 'No Data';
                cptObj['cptDescription'] = 'No Data';
                cptObj['cptPanelColor'] = 'default';
            } else {
                cptObj['cptCode'] = data.npidata.hcpcs_code;
                cptObj['cptDescription'] = data.npidata.hcpcs_description;
                cptObj['cptPanelColor'] = 'primary';
            }
            
            setCptObj(cptObj);
            setLoading(false);
            
        }).catch(err => {
            console.log('Error fetching Physician Brief Charges data: ', err);
            setLoading(true);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Physician Brief Charges data.',
                delay: 30000
            });
        });
    };
    

    
  return (
    <div className='physician-summary-panels'>
       <div className="row summary-panels">
    <div className="loading-sm" style={{ 'display' : (loading) ? 'inline' : 'none' }}></div>
    { !loading && <div className="col-lg-4">
        <PopoverDetails providerTooltip={leakageObject.leakageBlurb}>  
        <div className={`panel panel-${leakageObject.leakagePanelColor}`}>
            <div className="panel-heading">
                <div className="row">
                    <div className="col-xs-3">
                        <i className="fa fa-hospital-o fa-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                        <div className="huge">{leakageObject && leakageObject.leakage && (leakageObject.leakage * 100).toFixed(2) }%</div>
                        <div className="nowrap">Share to Competitors from {leakageObject && leakageObject.dominantHospitalAbbr}</div> 
                    </div>
                </div>
            </div>
        </div>
        </PopoverDetails>
    </div> }

    { !loading && <div className="col-lg-4">
        <PopoverDetails providerTooltip={chargesObject.chargesBlurb}>  
        <div className={`panel panel-${chargesObject.chargesPanelColor}`}>
            <div className="panel-heading">
                <div className="row">
                    <div className="col-xs-3">
                        <i className="fa fa-bar-chart-o fa-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                        <div className="huge">${chargesObject && chargesObject.npiCharges && chargesObject.npiCharges.toLocaleString()}</div>
                        <div className="nowrap">Total Charges</div>
                    </div>
                </div>
            </div>
        </div>
       </PopoverDetails>   
    </div> }

    { !loading && <div className="col-lg-4">
       <PopoverDetails providerTooltip={cptObject['cptDescription']}>  
        <div className={`panel panel-${cptObject.cptPanelColor}`}>
            <div className="panel-heading">
                <div className="row">
                    <div className="col-xs-3">
                        <i className="fa fa-stethoscope fa-5x"></i>
                    </div>
                    <div className="col-xs-9 text-right">
                        <div className="huge">{cptObject.cptCode}</div>
                        <div className="nowrap">Top Procedure</div>
                    </div>
                </div>
            </div>
        </div>
        </PopoverDetails>  
    </div> }
    </div>
    </div>
  )
}
