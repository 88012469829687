import React, { useEffect, useRef, useState } from 'react'
import pic from '../../services/pic';
import * as d3 from 'd3v3.1';
import "./marketmap.scss";
import { useApi, useConfig, usePic, useTemplate } from '../../services/servicesContext';
import { GeneralFlowModal } from '../../components/generalFlow/modalGeneralFlowComponent';

export function D3TreeMapComponent(props) {

    const [show, setShow] = useState(false);

    function handleClose () {
        if (show) {
            setShow(false);
        }
    }

    function handleShow () {
        if (!show) {
            setShow(true);
        }
    }

    const pic = usePic();
    const api = useApi();

    const [dimensions, setDimensions] = useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    });

    const [isDisplay, setIsDisplay] = useState(false);
    const [provider, setProvider] = useState(null);
    const [npis, setNpis] = useState(null);
    const [community, setCommunity] = useState(null);

    var leakageColorScale = ["#337ab7", "#63a0d4", "#9fc5e5", "#dbe9f5", "#f9e2e2", "#f4cecd", "#e7908e", "#d9534f"];
    
    var legendMapping = {
        "#337ab7" : "100% - 87.5%",
        "#63a0d4" : "87.5% - 75%",
        "#9fc5e5" : "75% - 62.5%",
        "#dbe9f5" : "62.5% - 50%",
        "#f9e2e2" : "50% - 37.5%",
        "#f4cecd" : "37.5% - 25%",
        "#e7908e" : "25% - 12.5%",
        "#d9534f" : "12.5% - 0"
    }
    
    var treemap, x, y, height, width, svg, grandparent, transitioning;

    var colors = d3.scale.quantize()
            .domain([100, 0])
            .range(leakageColorScale);

    function removeAllChildNodes(parent) {
        while (parent.firstChild) {
                parent.removeChild(parent.firstChild);
        }
    }

    function text(text) {
        text.selectAll("tspan")
        .attr("x", function(d) { return x(d.x) + 6; });
        text.attr("x", function(d) { return x(d.x) + 3; })
        .attr("y", function(d) { return y(d.y) + 3; });
    }

    function initialize(root) {
        if (root.parent) {
            initialize(root.parent);
        } else {
            root.x = 0;
            root.y = 0;
            root.dx = width;
            root.dy = height;
            root.depth = 0;
        }
    }

    // Aggregate the values for internal nodes
    function accumulate(d, init) {
        if (d.parent && !init) {
            accumulate(d.parent);
        } else if (d._children = d.children) {
            d.value = d.children.reduce(function(p, v) { return p + accumulate(v, true).value; }, 0);
            d.total = d.children.reduce(function(p, v) { return p + accumulate(v, true).total; }, 0);
        }
        
        return { value: d.value, total: d.total };
    }

    // Compute the treemap layout recursively such that each group of siblings uses the same size (1Ã—1) rather than the dimensions of the parent cell
    function layout(d, init) {
        if (d.parent && !init) {
            layout(d.parent);
        } else if (d._children) {
            treemap.nodes({__proto__: d});
            d._children.forEach(function(c) {
                c.x = d.x + c.x * d.dx;
                c.y = d.y + c.y * d.dy;
                c.dx *= d.dx;
                c.dy *= d.dy;
                c.parent = d;
                layout(c, true);
            });
        }
    }

    function display(d, elem) {
        grandparent
        .datum(d.parent)
        .on("click", transition)
        .select("text")
        .text(name(d));

        var g1 = svg.insert("g", ".grandparent")
        .datum(d)
        .attr("class", "depth");

        var g = g1.selectAll("g")
        .data(d._children)
        .enter().append("g");

        g.filter(function(d) { return d._children; })
        .classed("children", true)
        .on("click", transition);

        g.selectAll(".child")
        .data(function(d) { return d._children || [d]; })
        .enter().append("rect")
        .attr("class", "child")
        .call(rect);

        //tooltip
        var tool = d3.select("#treemap")
        .append("div")
        .attr("class", "market-map-tooltip");  

        /* write parent rectangle */
        g.append("rect")
        .attr("class", "parent")
        .on('mouseout', function(d, i) {
            tool.style("display", "none");
        })
        .on('mousemove', function(d, i) {
            tool.style("top", d3.event.pageY + 20 + "px");
            tool.style("left", d3.event.pageX + 20 + "px");
            tool.style("display", "inline-block");
            tool.html(formulateToolTip(d));
        })
        .on('click', function(d, i) {
            if (!d.children && d.npi) {
                setProvider(d);
                setNpis(props.npis);
                setCommunity(props.community);

                handleShow();
            }
        })
        .call(rect);
        
        var t = g.append("text")
        .attr("class", "ptext")
        .each(fontSize)
        .each(wordWrap);
        
        t.call(text);
        
        function buildText(d) {
            var marketShare;
            var communities = '';
            
            if (d.npi) {
                communities = pic(d.npi).length ? '\uf0c0' : '';
            }
            
            if (d.children) {
                marketShare = '(' + ((1 - d.leakage/d.value)*100).toFixed(2) + '%)';
            } else {
                if (d.name.includes('to other organizations')) {
                    marketShare = '(' + ((d.leakage/d.total)*100).toFixed(2) + '%)';
                } else {
                    marketShare = '(' + ((1 - d.parent.leakage/d.parent.value)*100).toFixed(2) + '%)';
                }
            }
            
            return d.name + ' ' + marketShare + ' ' + communities;
        }
        
        function fontSize(d,i) {
            
            var originalText = buildText(d);
            var words = originalText.split(' ');
            var word = words[0];
            
            var currentText = d3.select(this).attr("font-size", "12px");
            currentText.text(word);
            
            var parentRectWidth = x(d.x + d.dx) - x(d.x);
            var currentTextWidth = this.getBBox().width;
            
            var currentTextFontSize = 12;
            
            while ((currentTextWidth/parentRectWidth) > 0.8) {
                currentText.attr('font-size', `${currentTextFontSize - 1}px`);
                currentTextFontSize = parseFloat(currentText.attr('font-size').slice(0,-2));
                currentTextWidth = this.getBBox().width;
            }
            
        }

        function wordWrap(d, i) {
            var originalText = buildText(d);
            var words = originalText.split(' ');
            var line = [];
            var length = 0;
            var text = "";
            var width = d.dx;
            var height = d.dy;
            var word;
            do {
                word = words.shift();
                line.push(word);
                
                if (words.length) this.firstChild.data = line.join(' ') + " " + words[0]; 
                else this.firstChild.data = line.join(' ');
                
                length = this.getBBox().width;
                
                if (length < width && words.length) {
                    ;
                }
                else {
                    text = line.join(' ');
                    this.firstChild.data = text;
                    
                    if (text != '') {
                        d3.select(this).append("svg:tspan")
                        .attr("x", 0)
                        .attr("dx", "0.15em")
                        .attr("dy", "0.9em")
                        .text(text);
                    }
        
                    line = [];
                }
            } while (words.length);
            this.firstChild.data = '';
        }
        
        function transition(d) {
            props.handleDrillUpdate(d);
            if (transitioning || !d) return;
            transitioning = true;
            
            var g2 = display(d),
            t1 = g1.transition().duration(750),//old view to be removed
            t2 = g2.transition().duration(750);//new view
            
            // Update the domain only after entering new elements.
            x.domain([d.x, d.x + d.dx]);
            y.domain([d.y, d.y + d.dy]);
            
            // Enable anti-aliasing during the transition.
            svg.style("shape-rendering", null);
            
            // Draw child nodes on top of parent nodes.
            svg.selectAll(".depth").sort(function(a, b) { return a.depth - b.depth; });
            
            // Fade-in entering text.
            g2.selectAll("text").style("fill-opacity", 0);
            
            // Transition to the new view.
            t1.selectAll("text").call(text).style("fill-opacity", 0);
            t2.selectAll("text").call(text).style("fill-opacity", 1);
            t1.selectAll("rect").call(rect);
            t2.selectAll("rect").call(rect);
            
            //resize new text
            t2.each('end',function(){
                g2.selectAll('.ptext')
                    .each(function(d){
                        
                        var parentRectWidth = x(d.x + d.dx) - x(d.x);
                        var parentRectHeight = y(d.y + d.dy) - y(d.y);
                        var currentTextWidth = this.getBBox().width;
                        var currentTextHeight = this.getBBox().height;
                        
                        var currentText = d3.select(this);
                        var currentTextFontSize = parseFloat(currentText.attr('font-size').slice(0,-2));
                        
                        // increase text size until max or within width boundary of parent rect
                        while (currentTextFontSize < 12 && ((currentTextWidth/parentRectWidth) < 0.8)) {
                            currentText.attr('font-size', `${currentTextFontSize + 1}px`);
                            currentTextFontSize = parseFloat(currentText.attr('font-size').slice(0,-2));
                            currentTextWidth = this.getBBox().width;
                        }
                        
                        // descrease text size until height is within height boundary
                        while (currentTextFontSize < 12 && ((currentTextHeight/parentRectHeight) > 0.8)) {
                            currentText.attr('font-size', `${currentTextFontSize - 1}px`);
                            currentTextFontSize = parseFloat(currentText.attr('font-size').slice(0,-2));
                            currentTextHeight = this.getBBox().height;
                        }
                    
                    });
            });
            
            // Remove the old node when the transition is finished.
            t1.remove().each("end", function() {
                svg.style("shape-rendering", "crispEdges");
                transitioning = false;
            });
            
        }
        
        function name(d) {
            return d.parent ? name(d.parent) + " > " + d.name : d.name;
        }

        const findByProperty = (obj, predicate) => {
            if (predicate(obj)) {
                 return obj
            }
            else {
                if (obj.children) {
                    let test = Object.values(obj.children).filter(Boolean).filter(v => typeof v === 'object');
                    for(var n of test) {
                        let found = findByProperty(n, predicate)
                        if (found) return found
                    }
                }
            }
        }

        

        if (props.drillLevel.current) {
            let newNode = findByProperty(d, val => val.name === props.drillLevel.current.name);
            transition(newNode);
        }
            
        return g;
    }

    function rect(rect) {
        rect.attr("x", function(d) {return x(d.x); })
        .attr('foo', 'bar')
        .attr("y", function(d) { return y(d.y); })
        .attr("width", function(d) { return x(d.x + d.dx) - x(d.x); })
        .attr("height", function(d) { return y(d.y + d.dy) - y(d.y); })
        .style("fill", function(d){ 
            if (d.children) {
                 return colors((1 - d.leakage/d.value)*100);
            } else {
                if ( d.name.indexOf('to other organizations') > -1 ) {
                    return '#d9534f';
                } else {
                    return '#337ab7';
                }
            }
        });
    }

    function buildTreemap(elem) {
        let data = props.data;

        initialize(data);
        accumulate(data);
        layout(data);
        
        // must be redefined after layout is called to account for resize
        x.domain([data.x, data.x + data.dx]);
        y.domain([data.y, data.y + data.dy]);
        
        display(data, elem);
    }

    function setupTreemap() {
        var elem = document.getElementById("treemap");
        var graphContainer = document.getElementById("graphContainer")

        let minHeight = "948";
        let minWidth = "531";

        height = graphContainer.getBoundingClientRect().height;
        width = graphContainer.getBoundingClientRect().width;
        
        removeAllChildNodes(elem);
    
        var margin = {top:25, right: 0, bottom: 0, left: 0};
        
        treemap = d3.layout.treemap()
        .children(function(d, depth) {
            return depth ? null : d._children; 
        })
        .sort(function(a, b) { return a.value - b.value; })
        .ratio(height / width * 0.5 * (1 + Math.sqrt(5)))
        .round(false);
        
        x = d3.scale.linear()
        .domain([0, width])
        .range([0, width]);
        
        y = d3.scale.linear()
        .domain([0, height])
        .range([0, height]);

        svg = d3.select(elem).append("svg")
        .attr("width", width)
        .attr("height", height + margin.top)
        .style("margin-left", -margin.left + "px")
        .style("margin-right", -margin.right + "px")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .style("shape-rendering", "crispEdges")
        .style("opacity", 0);
    
        grandparent = svg.append("g")
        .attr("class", "grandparent");
    
        grandparent.append("rect")
        .attr("y", -margin.top)
        .attr("width", width)
        .attr("height", margin.top);
    
        grandparent.append("text")
        .attr("x", 6)
        .attr("y", 6 - margin.top)
        .attr("dy", ".75em")
        .attr("id", "grandparent-text")
    
        svg
        .transition()
        .delay(400)
        .duration(450)
        .style("opacity", 1);

        var legendContainerTest = document.createElement("div");
        legendContainerTest.setAttribute("id", "legend");
        elem.appendChild(legendContainerTest);
        var legendContainer = document.getElementById("legend");
        removeAllChildNodes(legendContainer);

        d3.select(legendContainer)
        .append('label')
            .text("Percent of visits captured:")

        var legend = d3.select(legendContainer)
        .append('ul')
            .attr('class', 'list-inline');

        var keys = legend.selectAll('li.key')
            .data(colors.range());

        keys.enter().append('li')
        .attr('class', 'key col-xs-1')
        .style('border-top-color', String)
        .text(function(d) {
            var r = legendMapping[d]
            return r;
        });
                
        buildTreemap(elem);
    }

    function formulateToolTip(d) {
        let leakageOptsobj = d.leakageOpts.find(o => o.active === true);

        if (d.children) {
            return (`<p><strong>${d.name}</strong></p>
            <p>${leakageOptsobj.name}: <strong>${ d.value.toLocaleString()}</strong></p>
            <p>Market Share: <strong>${ parseFloat((1 - d.leakage/d.value)*100).toFixed(2) }%</strong></p>`)
        }
        else {
           return (`<p><strong>${d.parent.name}</strong></p>
            <p>${leakageOptsobj.name}: <strong>${ (d.parent.value - d.leakage).toLocaleString()}</strong></p>
            <p>${leakageOptsobj.name} to other organizations: <strong>${ d.leakage.toLocaleString() }</strong></p>
            <p>Market Share: <strong>${ parseFloat((1 - d.parent.leakage/d.parent.value)*100).toFixed(2) }%</strong></p>
            <p>Share to Competitors: <strong>${ parseFloat((d.leakage/d.total)*100).toFixed(2) }%</strong></p>`)
        }
      
    }  

    function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
    }

    useEffect(() => {

        if (props.data.children.length > 0) {
            setIsDisplay(true);
            setupTreemap();
        }
        

        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }

    }, [dimensions]);

    return (
    <>
    <div>
        {isDisplay ? "" : <span>No Data Found</span>}
        <GeneralFlowModal show={show} onClose={handleClose} api={api} provider={provider} npis={props.npis} community={props.community}/>
        <div id="treemap"></div>
       
    </div>
    </>
    )
}