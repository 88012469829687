import React, { useEffect, useState, useReducer, useRef} from 'react';
import { useRouteLoaderData } from "react-router-dom";
import MyCommunities  from './myCommunities'; 
import SharedCommunities from './sharedCommunities';
import HospitalAffiliations from './hospitalAffiliation';
import GroupAffiliations  from './groupAffiliation';
import L from 'leaflet';
import eventBus from '../../services/eventBus';

import './home.scss';

import { useConfig, useApi, usePic, useTourManager, useLogging } from '../../services/servicesContext';

export function HomeComponent({ api, CommunityList, codes, notify, download, affiliations }){
    
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');

    const pic = usePic();
    const tourManager = useTourManager();
    const logging = useLogging();

    const [ currentTab, setCurrentTab] = useState("myCommunities");
    const [ loading, setLoading] = useState(false);
    const [ physicianData, setPhysicianData] = useState([]);
    const [ Provider, setProvider ] = useState(npis);
    const [ entityType, setEntityType ] = useState('');
    const leafletInstance = useRef(null);


    const [ affiliationsData, affiliationsDispatch ] = useReducer(
        (state, action) => {
            switch(action.type) {
                case 'HOSPITAL_AFFILIATIONS':
                    return {...state,
                        hospitalAffiliations: action.payload
                    };
                case 'HOSPITAL_AFFILIATES':
                    return {...state,
                        hospitalAffiliates: action.payload
                    };
                case 'GROUP_AFFILIATIONS':
                    return {...state,
                        groupAffiliations: action.payload
                    };
                case 'GROUP_AFFILIATES':
                    return {...state, 
                        groupAffiliates: action.payload
                    };
                case 'ALL_LOADED':
                    return {...state,
                        allLoaded: action.payload
                    };
                default:
                    console.log('action type not found', action,'returning last affiliation state', state); 
                    return {...state};
            }
        },
        {
            allLoaded: false,
            hospitalAffiliations: null,
            hospitalAffiliates: null,
            groupAffiliations: null,
            groupAffiliates: null
        }
    );

    useEffect(() => {

        async function init() {
            const provider = npis;
            const formatPhoneNum = (content) => { 
                if (!content) return
                const PhoneNum = content.toString();
                return <span className="text-nowrap">({PhoneNum.substring(0, 3)}) {PhoneNum.substring(3, 6)}-{PhoneNum.substring(6, 10)}</span> 
            }

            const specialty = (taxonomy) => {
                if (!taxonomy) return 
                return `${taxonomy.type ? taxonomy.type : ''}
                        ${taxonomy.classification ? taxonomy.classification : ''} 
                        ${taxonomy.specialization ? taxonomy.specialization : ''}`    
            }

            const tableData = { 
                'npi' : provider.npi,
                'name' : provider.name.full,
                'address' : provider.location.address,
                'city' : provider.location.city,
                'state': provider.location.state,
                'county' : provider.location.county,
                'phone' : formatPhoneNum(provider.location.phone_number),
                'dba_name': provider.name.secondary,            
                'taxonomy' : provider.taxonomy            
            }
            
            if (provider.entitytype === '1') {
                setEntityType('physician');
                affiliations.getAffiliations(provider.npi)
                .then(data => {
                    return Promise.allSettled([data.hospitalAffiliations.then(hospitals => hospitals), data.groupAffiliations.then(groups => groups)]);
                })
                .then(data => {
                    
                    if (data[0].status === 'fulfilled' && data[0].value.length > 0) {
                        const hospitalAffiliations = data[0].value.map(provider => {
                            return Object.assign(provider, {
                                communities: provider.npi ? pic(provider.npi) : [],
                            });
                        });
                        affiliationsDispatch({type: 'HOSPITAL_AFFILIATIONS', payload: hospitalAffiliations});
                    } else {
                        console.log('issue setting hospital affiliations', data[0]);
                        affiliationsDispatch({type: 'HOSPITAL_AFFILIATIONS', payload: null});
                    }
                    if (data[1].status === 'fulfilled' && data[1].value.length > 0) {
                        const groupAffiliations = data[1].value.map(provider => {
                            return Object.assign(provider, {
                                communities: provider.npi ? pic(provider.npi) : [],
                                grouppracticeid: provider.grouppracticeid || provider.id,
                                groupName: provider.groupName || provider.name.display
                            });
                        });
                        affiliationsDispatch({type: 'GROUP_AFFILIATIONS', payload: groupAffiliations});
                    } else {
                        console.log('issue setting group affiliations', data[1]);
                        affiliationsDispatch({type: 'GROUP_AFFILIATIONS', payload: null});
                    }
                    affiliationsDispatch({type: 'ALL_LOADED', payload: true});
                                        

                });
                
                createLeafletMaps(provider);
                
            } 

            if (provider.entitytype === '2') {
                setEntityType('organization');
                affiliations.getHospitalAffiliations({
                    hospitalnpi: `${provider.npi}`
                })
                .then(data => {
                    return Promise.allSettled(data.map(affiliate => {
                        return api.GetProvider(affiliate.npi).then(provider => {
                            provider.source = affiliate.source;
                            return provider;
                        });
                    }));
                })
                .then(data => {
                    if(data.length > 0) {
                        if (data[0].status === 'fulfilled') {
                            let dataVal = data.map(val => {
                                return val.value;
                            })
                            const hospitalAffiliates = dataVal.map(provider => {
                                return Object.assign(provider, {
                                    communities: provider.npi ? pic(provider.npi) : [],
                                    displayName: provider?.name?.display || 'Provider Name Not Found'
                                });
                            });
                            affiliationsDispatch({type: 'HOSPITAL_AFFILIATES', payload: hospitalAffiliates});
                        } else {
                            console.log('issue setting hospital affiliates', data[0]);
                            affiliationsDispatch({type: 'HOSPITAL_AFFILIATES', payload: null});
                        }
                    }
                    affiliationsDispatch({type: 'ALL_LOADED', payload: true});
                });

                affiliations.getGroupAffiliations({
                    groupnpi: `${provider.npi}`
                })
                .then(data => {
                    return Promise.allSettled(data.map(affiliate => {
                        return api.GetProvider(affiliate.npi).then(provider => {
                            provider.source = affiliate.source;
                            return provider;
                        });
                    }));
                })
                .then(data => {
                    if(data.length > 0) {
                        if (data[0].status === 'fulfilled') {
                            let dataVal = data.map(val => {
                                return val.value;
                            })
                            const groupAffiliates = dataVal.map(provider => {
                                return Object.assign(provider, {
                                    communities: provider.npi ? pic(provider.npi) : [],
                                    grouppracticeid: provider.grouppracticeid || provider.id,
                                    groupName: provider.groupName || provider.name.display,
                                    displayName: provider?.name?.display || 'Provider Name Not Found'
                                });
                            });
                            affiliationsDispatch({type: 'GROUP_AFFILIATES', payload: groupAffiliates});
                        } else {
                            console.log('issue setting group affiliates', data[0]);
                            affiliationsDispatch({type: 'GROUP_AFFILIATES', payload: null});
                        }
                    }
                    affiliationsDispatch({type: 'ALL_LOADED', payload: true});
                });
                
                createLeafletMaps(provider);
            } 

            setProvider(provider);
            setPhysicianData(tableData);

            const tourEntityType = (provider.entitytype == '1') ? 'physician' : 'organization';

            tourManager.createTour([{
                id: 1,
                title: 'Home - 1/6',
                text: `This is the ${tourEntityType}'s home page.`,
            }, {
                id: 2,
                attachTo: { element: `#tourscript-${tourEntityType}dropdown`, on: 'right' },
                title: 'Home - 2/6',
                text: `Notice that the dropdown options change once you have a selected ${tourEntityType}.`,
            }, {
                id: 3,
                attachTo: { element: `#tourscript-${tourEntityType}recent`, on: 'right' },
                title: 'Home - 3/6',
                text: `Here you can view recently selected ${tourEntityType}s.`,
            }, {
                id: 4,
                attachTo: { element: `#tourscript-${tourEntityType}graphs`, on: 'right' },
                title: 'Home - 4/6',
                text: `There are plenty of graphs here to give you insight into the loyalty patterns, referral trends and general behavior of the ${tourEntityType}.`,
            }, {
                id: 5,
                attachTo: { element: `#tourscript-${tourEntityType}reports`, on: 'right' },
                title: 'Home - 5/6',
                text: "There are also many reports to keep you busy with insighful information.",
            },
            {
                id: 6,
                attachTo: { element: "#tourscript-tour", on: 'right' },
                title: 'Home 6/6',
                text: "If you need help throughout the site, click the <span class='glyphicon glyphicon-question-sign' aria-hidden='true'></span> button"
            }]);

        
            let providerList = [];
            providerList.push(provider.npi);
            let source = provider.entitytype === '2' ? "/organization/" + provider.npi + "/home" : "/physician/" + provider.npi + "/home"
            CommunityList.update({listVal: providerList, sourceVal: source});
            eventBus.dispatch("updatedCommunityList");
        }

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.home`
        });

        init();

        return () => {
            tourManager.clearTour();
            if (leafletInstance.current) {
                leafletInstance.current.remove();
            }
            //Cleanup eventBus listeners
            eventBus.dispatch("resetCommunityList");
        };

    }, [npis]);

    const createLeafletMaps = async (newProvider) => {

        const provider = newProvider;
        const center = {
            lat: provider.location.coordinates.lat || 0,
            lng: provider.location.coordinates.lng || 0
        }
        var openStreetMapAttribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    
        var tileOptions = {
            maxZoom: 19,
            subdomains: ['a','b','c']
        };

        var streets = new L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: openStreetMapAttribution}, tileOptions);
    
        var baseMaps = {
            "OpenStreetMap": streets
        };
    
        var preferredBase = 'OpenStreetMap';   

        const popupText = (provider && provider.name && provider.name.full || "");
        leafletInstance.current = L.map('map', {
            center: [center.lat, center.lng],
            zoom: 12
        });
        (baseMaps['OpenStreetMap']).addTo(leafletInstance.current);
        L.marker([provider.location.coordinates.lat, provider.location.coordinates.lng]).addTo(leafletInstance.current)
           .bindPopup(provider.name.display)
           .openPopup();
        
        await provider.GetServiceArea()
            .then(function(serviceAreaData) {
                if ((JSON.stringify(serviceAreaData.service_area) !== JSON.stringify({}))) {
                    
                    var serviceAreaKeys = Object.keys(serviceAreaData.service_area);
                    var actual, modeled;

                    var overlayMaps = {
                        'Service Area': {
                            'Actual': actual = new L.layerGroup(), 
                            'Modeled': modeled = new L.layerGroup()
                        }
                    };

                    var options = {
                        exclusiveGroups: ['Service Area'],
                        groupCheckboxes: true
                    };

                    var fitBoundsGroup = L.featureGroup();

                    serviceAreaKeys.forEach(function(key) {
                        var color;
                        
                        switch(key) {
                            case 'primary':
                                color = '#e6550d';
                                break;
                            case 'primary_modeled':
                                color = '#756bb1';
                                break;
                            case 'secondary':
                                color = '#fdae6b';
                                break;
                            case 'secondary_modeled':
                                color = '#bcbddc';
                                break;
                            default:
                                color = '#333';
                        }
                        
                        var polyLayer = L.geoJson(serviceAreaData.service_area[key], {
                            color: color,
                            fillColor: color,
                            weight: 1,
                            opacity: 1,
                            fillOpacity: 0.6
                        });
                    
                        if (key.includes('modeled')) {
                            modeled.addLayer(polyLayer.bindPopup((key.includes('primary')? 'Primary' : 'Secondary') + ' Service Area - Modeled'));
                        } else {
                            actual.addLayer(polyLayer.bindPopup((key.includes('primary')? 'Primary' : 'Secondary') + ' Service Area - Actual'));
                        }

                        fitBoundsGroup.addLayer(polyLayer);
                    });
                    
                    var legend = L.control({position: 'bottomleft'});
                    
                    legend.onAdd = function() {
                        var div = L.DomUtil.create('div', 'info legend');
                        
                        div.innerHTML += 
                        '<i style="background:#e6550d"></i> Actual Primary Service Area<br />' +
                        '<i style="background:#fdae6b"></i> Actual Secondary Service Area <br />' +
                        '<i style="background:#756bb1"></i> Modeled Primary Service Area<br />' +
                        '<i style="background:#bcbddc"></i> Modeled Secondary Service Area<br />'
                        ;
                        
                        return div;
                    };

                    const popUpText = (Provider && Provider.name && Provider.name.display || "");
                    L.marker([center.lat, center.lng]).addTo(leafletInstance.current).bindPopup(popUpText).openPopup();

                    overlayMaps['Service Area']['Actual'].addTo(leafletInstance.current);
                    leafletInstance.current.fitBounds(fitBoundsGroup.getBounds());
        
                    legend.addTo(leafletInstance.current);

                    L.control.layers(baseMaps, overlayMaps['Service Area']).addTo(leafletInstance.current);

                } else {

                    (baseMaps['OpenStreetMap']).addTo(leafletInstance.current);
                    L.control.layers(baseMaps).addTo(leafletInstance.current);

                    L.marker([center.lat, center.lng]).addTo(leafletInstance.current).bindPopup(popupText).openPopup();


                }
            }).catch(err => {
                console.log('error', err);
            });
        
    };
    

    const renderTabs = () => {
        if (currentTab === "myCommunities") { 
            return <MyCommunities provider={Provider} api={api} CommunityList={CommunityList} affiliations={affiliations} pic={pic} notify={notify} /> 
        } else if (currentTab === "sharedCommunities") {
            return <SharedCommunities provider={Provider} api={api} CommunityList={CommunityList} affiliations={affiliations} pic={pic} notify={notify} />
        } else if (currentTab === "hospital") {
            return <HospitalAffiliations
                download={download}
                provider={Provider}
                api={api}
                CommunityList={CommunityList}
                affiliations={affiliations}
                pic={pic}
                affiliationsList={(entityType === 'physician' ? affiliationsData.hospitalAffiliations : affiliationsData.hospitalAffiliates)}
            />  
        } else if (currentTab === "group") {
            return <GroupAffiliations
                download={download}
                provider={Provider}
                api={api}
                CommunityList={CommunityList}
                affiliations={affiliations}
                pic={pic}
                affiliationsList={(entityType === 'physician' ? affiliationsData.groupAffiliations : affiliationsData.groupAffiliates)}
            />
        } else {
            return null
        }
    }

    const changeTabs = (tab) => {
        setCurrentTab(tab);
    }
    
return (<>
    <div className="row pro-home-row">
        <div className="col-md-6">
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <th>NPI</th>
                        <td>{physicianData.npi}</td>
                    </tr>
                    <tr>
                        <th>{ entityType === 'physician' ? "Name" : "Business Name" }</th>
                        <td>{ entityType === 'organization' ?  Provider && Provider.name && Provider.name.primary || "" : physicianData.name }</td>
                    </tr>
                    { entityType === 'organization' ?
                    <tr>
                        <th>DBA Name</th>
                        <td>{physicianData.dba_name}</td>
                    </tr>  : ''}
                    <tr>
                        <th>Address</th>
                        <td>{physicianData.address ? physicianData.address.map(line => <div key={line}>{line}</div>) : null}</td>
                    </tr>
                    <tr>
                        <th>City, State</th>
                        <td>{physicianData.city}, {physicianData.state}</td>
                    </tr>
                    <tr>
                        <th>County</th>
                        <td>{physicianData.county}</td>
                    </tr>
                    <tr>
                        <th>Phone</th>
                        <td>{physicianData.phone}</td>
                    </tr>
                    <tr>
                        <th>Specialty</th>
                        <td>
                            { physicianData?.taxonomy?.type ? <div>{physicianData.taxonomy.type}</div> : null}
                            { physicianData?.taxonomy?.classification ? <div>{physicianData.taxonomy.classification}</div> : null}
                            { physicianData?.taxonomy?.specialization ? <div>{physicianData.taxonomy.specialization}</div> : null}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="col-md-6 pro-home-map-container">
            <div id="map" className="phys-home-map" style={{ height: 300, width: '100%' }}>
            </div>
        </div>
        <div>
        </div>
    </div>
    <ul className="nav nav-tabs">
        <li className={currentTab === "myCommunities" ? "active" : ""}><a id="1" onClick={() => changeTabs('myCommunities')}>My Communities</a></li>
        <li className={currentTab === "sharedCommunities" ? "active" : ""}><a id="2" onClick={() => changeTabs('sharedCommunities')}>Shared Communities</a></li>
        { ((entityType === 'physician' && affiliationsData?.hospitalAffiliations) || (entityType === 'organization' && affiliationsData?.hospitalAffiliates)) ?
             (entityType === 'physician' ? affiliationsData?.hospitalAffiliations : affiliationsData?.hospitalAffiliates)
                ? <li className={currentTab === "hospital" ? "active" : ""}>
                    <a id="3" onClick={() => changeTabs('hospital')}>{(entityType === 'organization') ? 'Hospital Affiliates' : 'Hospital Affiliations' }</a>
                </li>
                : affiliationsData?.allLoaded
                    ? <li><a id="4" className="disabled" style={{color:'gray'}}>{(entityType === 'organization') ? 'Hospital Affiliates' : 'Hospital Affiliations' } not found</a></li>
                    : <li><a id="3" className="disabled">Loading {(entityType === 'organization') ? 'Hospital Affiliates' : 'Hospital Affiliations' } <i className="fa fa-spinner fa-spin fa-fw"/></a></li>
            : ""
        }
        { ((entityType === 'physician' && affiliationsData?.groupAffiliations) || (entityType === 'organization' && affiliationsData?.groupAffiliates)) ?
         (entityType === 'physician' ? affiliationsData?.groupAffiliations : affiliationsData?.groupAffiliates)
            ? <li className={currentTab === "group" ? "active" : ""}><a id="4" onClick={() => changeTabs('group')}>{(entityType === 'physician') ? 'Group Affiliations' : 'Group Affiliates' }</a></li>
            : affiliationsData?.allLoaded
                ? <li><a id="4" className="disabled" style={{color:'gray'}}>{(entityType === 'physician') ? 'Group Affiliations' : 'Group Affiliates' } not found</a></li>
                : <li><a id="4" className="disabled">Loading {(entityType === 'physician') ? 'Group Affiliations' : 'Group Affiliates' } <i className="fa fa-spinner fa-spin fa-fw"/></a></li>
            : ""
        }
    </ul>
        <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
        { renderTabs() }
</>);
}
