import * as d3 from 'd3v6';

function wrap(text, width) {
  let xAttr = -9;
  
  text.each(function() {
    var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.0, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", xAttr);
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan").attr("x", xAttr).attr("dy", lineHeight + "em").text(word);
        ++lineNumber;
        text.attr("y", lineNumber > 0 ? `${-(lineNumber * lineHeight) * .5}em` : 0);//as lines increase bump higher by half of overall expanded hight
      }
    }
  });
}

export function phGroupedBarGraph() {
  
  let servicelines = [];
  let width = 300;
  let height = 300;
  let y0Scale;
  let y1Scale;
  let xScale;
  let colorScale;
  let xAxis;
  let yAxis;
  let legend;
  let groupKey;
  let keys;
  let parent;
  let selection;
  let barsContainer;
  let xAxisContainer;
  let yAxisContainer;
  let legendContainer;
  let tooltip;
  let yAxisKeyHoverHandler;
  let handleServicelineClick;
  let extraHeight = 0;
  let margin = {
    top: 20,
    right: 150,
    bottom: 20,
    left: 150
  };
  
  function render(data, selector) {
    if (data) {
        width = data.width || width;
        height = data.height || height;
        
        if (data.opts) {
          keys = data.opts.keys || keys || [];
          groupKey = data.opts.groupKey || groupKey || 'serviceline';
          yAxisKeyHoverHandler = data.opts.yAxisKeyHoverHandler || yAxisKeyHoverHandler || undefined;
          handleServicelineClick = data.opts.groupClickHandler || handleServicelineClick || undefined;
        }
        
        if (data.data) {
          servicelines = data.data.map(serviceline => {
            keys.forEach(key => {
              if (!serviceline[key]) {
                serviceline[key] = 0;
              }
            });
            return serviceline;
          }) || servicelines || [];
          servicelines.sort((a, b) => {
            let aTotal = keys.reduce((acc, key) => {
              acc += a[key];
              return acc;
            },0);
            let bTotal = keys.reduce((acc, key) => {
              acc += b[key];
              return acc;
            },0);
            return bTotal - aTotal;
          });
        }
      }
      
      if (!tooltip && yAxisKeyHoverHandler) {
        tooltip = d3.select("body").append("div")	
          .attr("class", "yaxis-key-tooltip")
          .style('position', 'absolute')
          .style('test-align', 'center')
          .style('padding', '5px')
          .style('font', '12px sans-serif')
          .style('background', 'white')
          .style('border-radius', '5px')
          .style('pointer-events', 'none')
          .style('opacity', 0)
          .style('box-shadow', '0 6px 12px rgb(0 0 0 / 18%)')
          .style('max-height', '250px')
          .style('max-width', '250px')
          .style('overflow-y', 'scroll')
          .style('z-index', 10000);
        tooltip.append('p')
          .style('margin', 0)
          .style('padding', 0);
      }
      
      extraHeight = 0;
      
      if (servicelines.length > 20) {
        extraHeight = (servicelines.length - 20) * 30;
      }
      
      if (!y0Scale) {
        y0Scale = d3.scaleBand()
          .paddingInner(0.1);
      }
      y0Scale
        .domain(servicelines.map(d => d[groupKey]))
        .rangeRound([margin.top, (height + extraHeight) - margin.bottom]);
        
      if (!y1Scale) {
        y1Scale = d3.scaleBand()
          .padding(0.05);
      }
      y1Scale
        .domain(keys)
        .rangeRound([y0Scale.bandwidth(), 0]);
        
      if (!xScale) {
        xScale = d3.scaleLinear();
      }
      xScale
        .domain([0, d3.max(servicelines, d => d3.max(keys, key => d[key]))]).nice()
        .rangeRound([margin.left, width - margin.right]);
      
      if (!colorScale) {
        colorScale = d3.scaleOrdinal()
          .range(d3.schemeCategory10);
      }
      colorScale
        .domain(keys);
      
      xAxis = g => g
        .attr("transform", `translate(0,${margin.top})`)
        .call(d3.axisTop(xScale).tickSizeOuter(0))
        .call(g => g.select(".domain").remove())
        .call(d3.axisTop(xScale).ticks(3, "s"))
        .call(g => g.select(".tick:last-of-type text").clone()
          .attr("x", 15)
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
          .text(servicelines.y));
      
      yAxis = g => g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y0Scale).ticks(null, "s"))
        .call(g => g.select(".domain").remove())
        .selectAll(".tick text")
        .on('mouseover', yAxisKeyHoverHandler ? (e,d) => {
          
          tooltip.select('p').text(`loading...`);
          
          yAxisKeyHoverHandler(d)
          .then(res => {
            tooltip.select('p').text(`${res[0].code} - ${res[0].description}`);
          });
          
          tooltip.transition()
            .duration(200)
            .style("opacity", .9);
          tooltip
            .style("left", (event.pageX + 20) + "px")
            .style("top", (event.pageY) + "px")
            .style('pointer-events', 'all');
            
        } : null)
        .on('mouseleave', yAxisKeyHoverHandler ? (e,d) => {
          tooltip.transition()
            .duration(100)
            .style("opacity", 0);
        } : null)
        .call(wrap, (margin.left - 9));
      
      legend = svg => {
        const g = svg
            .attr("transform", `translate(${width},0)`)
            .attr("text-anchor", "end")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
          .selectAll("g")
          .data(colorScale.domain().slice().reverse())
          .join("g")
            .attr("transform", (d, i) => `translate(0,${i * 20})`);
      
        g.append("rect")
            .attr("x", -19)
            .attr("width", 19)
            .attr("height", 19)
            .attr("fill", colorScale);
      
        g.append("text")
            .attr("x", -24)
            .attr("y", 9.5)
            .attr("dy", "0.35em")
            .text(d => d);
      };
      
      if (!parent) {
        parent = d3.select(selector);
      }
      
      if (!selection) {
        selection = parent
          .append('svg');
      }
      selection.attr("viewBox", [0, 0, width,  (height + extraHeight)]);
      
      if (!barsContainer) {
        barsContainer = selection.append("g")
          .attr("class", "main-g");
      }
      
      barsContainer.selectAll("g.serviceline-g")
        .data(servicelines)
        .join("g")
          .attr("class", "serviceline-g")
          .attr("transform", d => `translate(0,${y0Scale(d[groupKey])})`)
          .on("click", (e, d) => {
            handleServicelineClick(d, keys);
          })
          .each(function(p,j){
            var subrect = d3.select(this).select("rect.subrect");
            if (subrect.empty()) {//does prior background rect not exist?
              d3.select(this)
                .insert('rect')
                  .attr('class', 'mainrect')
                  .attr('x', margin.left)
                  .attr('width', width - margin.left - margin.right)
                  .attr('height', y0Scale.bandwidth())
                  .attr('fill', () => {
                    return j % 2 === 0 ? '#eee':'#f8f8f8';
                  });
            } else {
              d3.select(this)
                .select('rect.mainrect')
                  .attr('width', width - margin.left - margin.right)
                  .attr('height', y0Scale.bandwidth());
            }
          })
          .selectAll("rect.subrect")
          .data(d => keys.map(key => {
            let retObj = {
              key: key,
              [groupKey]: d[groupKey],
              value: d[key]
            };
            retObj[groupKey] = d[groupKey];
            return retObj;
          }))
          .join("rect")
            .attr("class", "subrect")
            .attr("x", d => xScale(0))
            .attr("y", d => y1Scale(d.key))
            .attr("width", d => xScale(d.value) - xScale(0))
            .attr("height", y1Scale.bandwidth())
            .attr("fill", d => colorScale(d.key))
            .each(function(p,j){
              var prevTitle = d3.select(this).select("title");
              if (prevTitle.empty()) {
                d3.select(this)
                  .append("title")
                    .text(d => {
                      return `${d.key} - ${d[groupKey]} - ${d.value} procedures`;
                    });
              } else {
                prevTitle
                  .text(d => {
                    return `${d.key} - ${d[groupKey]} - ${d.value} procedures`;
                  });
              }
            });

      if (!xAxisContainer) {
        xAxisContainer = selection.append("g");
      } else {
        xAxisContainer.remove();
        xAxisContainer = selection.append("g");
      }
      xAxisContainer.call(xAxis);
      
      if (!yAxisContainer) {
        yAxisContainer = selection.append("g");
      } else {
        yAxisContainer.remove();
        yAxisContainer = selection.append("g");
      }
      yAxisContainer.call(yAxis);
      
      if (!legendContainer) {
        legendContainer = selection.append("g");
      } else {
        legendContainer.remove();
        legendContainer = selection.append("g");
      }
      legendContainer.call(legend);
      
  }
  
  function chart(selector, data) {
    render(data, selector);
  }
  
  chart.dimensions = function(values) {
    if (!arguments.length) return {width: width, height: height};
    width = values.width ? values.width : width;
    height = values.height ? values.height : height;
    render({
      width: width,
      height: height
    });
    return chart;
  };
  
  chart.servicelines = function(newData, newOpts) {
    if (!arguments.length) return servicelines;
    render({data: newData, opts: newOpts});
  };
  
  chart.destroy = function() {
    
    if (tooltip) {
      tooltip.remove();
      tooltip = null;
    }
    
    legendContainer.remove();
    legendContainer = null;
    
    xAxisContainer.remove();
    xAxisContainer = null;
    
    yAxisContainer.remove();
    yAxisContainer = null;
    
    selection.remove();
    selection = null;
    
    parent.remove();
    parent = null;
    
    return true;
  };
  
  return chart;
  
}