import * as d3 from 'd3';

import modalTemplate from './sankeyButterflyModal.html';
import tourConfigOrg from './tourConfigOrg';
import tourConfigPhys from './tourConfigPhys';

export default ['$scope', '$state', 'api', '$q', '$uibModal', '$window', 'CommunityList', 'notify', 'tour', '$timeout',
    
    function($scope, $state, api, $q, $uibModal, $window, CommunityList, notify, tour, $timeout) {
        var $ctrl = this;
        
        var modal;
        
        $scope.$on('$destroy', function() {
           if (modal) {
               modal.close();
           }
        });
        
        function bindSankeyMouseClick($scope){
            
            $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
                if (datum instanceof api.Provider) {
                    var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                    $window.open(newTabUrl, '_blank');
                } else if (datum instanceof api.Community) {
                    modalHandler(event, d3Event, datum, context);
                }
            });
            
            $scope.$on('sankey.link.click', function(event, d3Event, datum, context) {
                if (datum.source instanceof api.Community || datum.target instanceof api.Community ) {
                    modalHandler(event, d3Event, datum, context);
                }
            });
            
        }
        
        function buildCommunityList(links, topLinks, datum) {
            var direction;
            if (datum.sourceLinks.length > 0) {
                direction = 'source';
            } else {
                direction = 'target';
            }
            
            var list = topLinks.map(function(link) { return link[direction].npi; });
            
            var altList;
            if (links > topLinks) {
                altList = links.map(function(link) { return link[direction].npi; });
            }
            
            CommunityList.temporaryList = true;
            CommunityList.update(list, altList);
        }
        
        bindSankeyMouseClick($scope);
        
        function modalHandler(event, d3Event, datum, context){
            
            var links;
            var drillDownTitle = '';
            
            var overflow = {};
            
            if (datum instanceof api.Relation) {//clicked on a link
                links = datum.source instanceof api.Community ? datum.source.relations : datum.target.relations;
                
                overflow.source = datum.source instanceof api.Community ? new api.Community({ name: '(others)' }) : Object.getPrototypeOf(datum.source);
                overflow.target = datum.target instanceof api.Community ? new api.Community({ name: '(others)' }) : Object.getPrototypeOf(datum.target);
                
                
                drillDownTitle = datum.source.valueOf() + ' &rarr; ' + datum.target.valueOf();
            } else {//if not relation then it's a node that is a Community
                links = datum.relations;
                
                overflow.source = datum.sourceLinks.length ? new api.Community({ name: '(others)' }) : Object.getPrototypeOf(datum.targetLinks[0].source);
                overflow.target = datum.targetLinks.length ? new api.Community({ name: '(others)' }) : Object.getPrototypeOf(datum.sourceLinks[0].target);
                
                if (datum.sourceLinks.length > 0) {// is left node
                    drillDownTitle = datum.toString() + ' &rarr; ' + event.currentScope.$ctrl.npis[0].toString();
                } else {// is right node
                    drillDownTitle = event.currentScope.$ctrl.npis[0].toString() + ' &rarr; ' + datum.toString();
                }
            }
            
            var linksCopy = links.slice(0).sort(function(a,b){
                return b.valueOf() - a.valueOf();
            });
            
            var topLinks = linksCopy.slice(0, 25);
            
            buildCommunityList(links, topLinks, datum);
            
            if (linksCopy.length > 25) {//create '(others)' community rollup if more than 25
            
                topLinks.push(linksCopy.slice(25).reduce(function(relation, link){
                    
                    relation.values.shared += link.values.shared;
                    relation.values.unique += link.values.unique;
                    relation.values.sameday += link.values.sameday;
                    return relation;
                    
                },new api.Relation({
                    source: overflow.source,
                    target: overflow.target,
                    values: {
                        sameday: 0,
                        shared: 0,
                        unique: 0
                    }
                    
                })));
            }
            
            if (modal) modal.dismiss();
            
            modal = $uibModal.open({
                size: 'lg',
                template: modalTemplate,
                controller: ['$scope', '$state', '$uibModalInstance', '$sce', function ($scope, $state, $uibModalInstance, $sce) {
                    var $ctrl = this;
                    
                    $scope.data = topLinks;
                    $scope.title = $sce.trustAsHtml(drillDownTitle);
                    
                    bindSankeyMouseClick($scope);
                    
                    $ctrl.createCommunity = function() {
                        CommunityList.openCommunityModal();
                    };

                    $scope.modalCommunityCount = linksCopy.slice(0, 25).length;
                    
                    $ctrl.cancel = function () {
                         $uibModalInstance.close();
                    };
                    
                }],
                controllerAs: '$ctrl'
            });
            
            modal.result.then(function () {
                CommunityList.temporaryList = false;
                CommunityList.reset();
            }, function() {
                CommunityList.temporaryList = false;
                CommunityList.reset();
            });
        }
        
        var options = {
            color: {
                scale: d3.scaleOrdinal(d3.schemeCategory20),
                value: function(d) {
                    return this.scale(d.replace(/TO |FROM /, ''));
                }
            },
            node: {
                colorValue: function(d) {
                    return d.id;
                },
                label: function(d) { return d.toString(); }
            }
        };
        
        $ctrl.$onInit = function(){

            var seedProvider = $scope.provider = $ctrl.npis[0];
            
            if (seedProvider instanceof api.Organization) {//set org tour config
                tour.setScript(tourConfigOrg($timeout), $scope);
            }
            
            if (seedProvider instanceof api.Physician){//set physician tour config
                tour.setScript(tourConfigPhys($timeout), $scope);
            }
            
            api.Relations(seedProvider.npi).then(function(relations) {
                if (relations.length === 0) {
                    console.log('No Provider Care Pathways data found: ', relations);
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Provider Care Pathways data found.',
                        delay: 30000
                    });
                }
                var promises = relations.map(function(relation) {
                    
                    var relationships = [];
                    
                    if (relation.source instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.source).then(function(provider){
                            return relation.source = provider;
                        }));
                    }
                    
                    if (relation.target instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.target).then(function(provider){
                            return relation.target = provider;
                        }));
                    } 
                    
                    return $q.all(relationships);
                    
                });
                $q.all(promises).then(function() {
                    var hash = relations.reduce(function(hash, relation) {
                        var category, source, target, obj;
                        
                        if (relation.source.npi == seedProvider.npi) { // TO
                            category = 'TO ' + relation.target.taxonomy;
                            
                            if (!hash[category]) {
                                hash[category] = obj = new api.Relation({
                                    source: relation.source,
                                    target: new api.Community({
                                        name: category,
                                        side: 'right',
                                    }),
                                    values: { shared: 0, unique: 0, sameday: 0}
                                });
                                obj.target.relations = [ relation ];
                            } else {
                                obj = hash[category];
                                obj.target.relations.push(relation);
                            }
                        } else {
                            category = 'FROM ' + relation.source.taxonomy;
                            
                            if (!hash[category]) {
                                hash[category] = obj = new api.Relation({
                                    source: new api.Community({
                                        name: category,
                                        side: 'left'
                                    }),
                                    target: relation.target,
                                    values: { shared: 0, unique: 0, sameday: 0}
                                });
                                obj.source.relations = [ relation ];
                            } else {
                                obj = hash[category];
                                obj.source.relations.push(relation);
                            }
                        }
                        
                        obj.values.shared += relation.values.shared;
                        obj.values.unique += relation.values.unique;
                        obj.values.sameday += relation.values.sameday;
                        
                        return hash;
                        
                    }, {});//get data and categorize based on specializaton or classification
                    
                    var sortedTop25 = Object.keys(hash).map(function(key) {// sort and get top 25
                            return hash[key];
                        }).sort(function(a, b) {
                            return b.values.shared - a.values.shared;
                        }).slice(0, 25);
                    
                    if ($ctrl.reportSankey) {
                        $ctrl.reportSankey.options(options);
                        $ctrl.reportSankey.data(sortedTop25);
                    } else {
                        // debugger;//brief page, TODO:(ticket#) clean up later 
                        $scope.options = options;
                        $scope.data = sortedTop25;
                    }
                    
                });
            }, function(error){
                console.log('Error fetching Provider Care Pathways data: ', error);
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Provider Care Pathways data.',
                    delay: 30000
                });
                if ($ctrl.reportSankey) {
                    $ctrl.reportSankey.options(options);
                    $ctrl.reportSankey.data([]);//need to send an empty array, so the sankey loading indicator stops
                }
                
            });
        };
    }    
];