import angular from 'angular';

import template from './dashboardssection.html';
import controller from './dashboardssection.controller';

import api from '../../services/api';
import DashboardsectionComponent from './dashboardsectionComponent';

import { react2angular } from 'react2angular';

const componentName = 'dashboardsSection';
const updatedComponentName = 'dashboardsectionComponent';
const moduleName = 'app.reports.dashboardsSection';

angular.module(moduleName, [
    api
])
.component(
    updatedComponentName,
    react2angular(DashboardsectionComponent, ['opts', 'npis', 'community', 'dashboards'], ['api', 'download', 'tour', '$state'])
)
.component(componentName, {
    bindings: {
        npis: '<',
        community: '<',
        dashboards: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName,
    updatedComponentName
};