import angular from 'angular';
import { react2angular } from 'react2angular';
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    Outlet
} from "react-router-dom";
import uirouter from '@uirouter/angularjs';

import api from '../services/api';
import recentlySelected from '../services/recentlySelected';
import template from '../services/template';
import models from '../models';

import home from './community.home';
import graphs from './community.graphs';
import reports from './community.reports';
import benchmarks from './community.dashboards';
import CommunityList from '../services/communityList';
import { ServicesProvider, useConfig, useApi } from '../services/servicesContext';

import { moduleName as breadcrumbModule } from '../components/breadcrumb/breadcrumb';

import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumbComponent';
import { SharedVisitsCommunityComponent } from '../reports/sharedvisits.community/sharedvisitsComponent';
import { SpecialtyComponent } from '../reports/specialty/specialtyComponent';
import { OrganizationComponent } from '../reports/organization/organizationComponent';
import { ClinicalCommunityComponent } from '../reports/clinical.community/clinicalComponent';
import { ServicelineBenchmarkComponent } from '../reports/servicelinebenchmark.community/servcielinebenchmarkComponent';

import { CarepathwaysComponent } from '../reports/carepathways.community/carepathwaysComponent';
import { SpecialtyFlowComponent } from '../reports/specialtyflow.community/specialtyflowComponent';
import { FlowComponent } from '../reports/flow/flowComponent';
import { CommunityGeneralflowComponent } from '../reports/generalflow.community/generalflowComponent';
import { TrendingGraphComponent } from '../reports/trendinggraph/trendingGraphReportComponent';
import { MarketMapComponent } from '../reports/marketmap.organization/marketmapComponent';

import { CommunityHomeComponent } from '../reports/home.community/homeComponent';

import { DashboardsectionComponent } from '../reports/dashboardssection/dashboardsectionComponent';
import eventBus from "../services/eventBus";


export function CommunityRoutingComponent(props) {
    console.log('rendering CommunityRoutingComponent', props);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    path="/:cid"
                    id="community.cid"
                    loader={async ({params}) => {
                        let community = Promise.resolve(props.api.GetCommunity(params.cid));
                        community.then(value => {
                            props.recentlySelected.store(value); 
                            eventBus.dispatch("recentlySelectedChangeCommunities");
                            eventBus.dispatch("resetCommunityList");
                            eventBus.dispatch("pageChange");
                        })
                        return props.api.GetCommunity(params.cid);
                    }}
                    element={<>
                        <BreadcrumbComponent
                            api={props.api}
                            recentlySelected={props.recentlySelected}
                        />
                        <Outlet/>
                    </>}

                >
                    <Route
                        path="SharedVisitsReport"
                        element={<SharedVisitsCommunityComponent
                            api={props.api}
                            pic={props.pic}
                            notify={props.notify}
                            download={props.download}
                            CommunityList={props.CommunityList}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="bySpecialty"
                        element={<SpecialtyComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            tour={props.tour}
                            pic={props.pic}
                        />}
                    />
                    <Route
                        path="byOrganization"
                        element={<OrganizationComponent
                            api={props.api}
                            notify={props.notify}
                            pic={props.pic}
                            download={props.download}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="ClinicalReport"
                        element={<ClinicalCommunityComponent
                            api={props.api}
                            notify={props.notify}
                            download={props.download}
                            codes={props.codes}
                            tour={props.tour}
                            pic={props.pic}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="marketbenchmarks"
                        element={<ServicelineBenchmarkComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                        />}
                    />
                    <Route
                        path="CareJourney"
                        element={<CarepathwaysComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="SpecialtyGraph"
                        element={<SpecialtyFlowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    {<Route
                        path="MarketMakers"
                        element={<MarketMapComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />}
                    <Route
                        path="Flow"
                        element={<FlowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="GeneralFlow"
                        element={<CommunityGeneralflowComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            template={props.template}
                        />}
                    />
                    <Route
                        path="trendinggraph"
                        element={<TrendingGraphComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                        />}
                    />
                    <Route
                        path="home"
                        loader={async ({params}) => {
                            let provider = Promise.resolve(props.api.GetCommunity(params.cid));
                            provider.then(value => {
                                props.recentlySelected.store(value); 
                              })
                              return props.api.GetCommunity(params.cid);
                        }}
                        element={<CommunityHomeComponent
                            api={props.api}
                            pic={props.pic}
                            download={props.download}
                            notify={props.notify}
                            tour={props.tour}
                            CommunityList={props.CommunityList}
                        />}
                    />
                    <Route
                        path="dashboards"
                        element={<DashboardsectionComponent
                            elementName='community'
                            name='Community Volume Benchmarks'
                            dashboards={[
                                {
                                    name: 'Volume of Procedures by Physician Community',
                                    paramkey: 'cid',
                                    description: "Compare community procedures by servicelines and code"
                                }
                              ]}
                        />}
                    />
                </Route>
            </>
        ),
        { basename: '/community'}
    );

    return (
        <ServicesProvider>
            <RouterProvider router={router} />
        </ServicesProvider>
    );
    
}

export default angular.module('app.pages.community', [
    uirouter, api, models, recentlySelected, template, CommunityList,
    /*home, graphs, reports, benchmarks,*/ breadcrumbModule
])
.component(
    'communityRoutesComponent',
    react2angular(
        CommunityRoutingComponent,
        ['community'],
        ['api', 'pic','notify','download','tour','CommunityList','recentlySelected','codes','template']
    )
)
.run(['$stateRegistry', '$rootScope', '$transitions', 'template', 'api', '$state', 'notify', 'recentlySelected',
function($stateRegistry, $rootScope, $transitions, template, api, $state, notify, recentlySelected) {
    
    $stateRegistry.register({
        name: 'root.app.com',
        url: '/community/:cid/:reportname',
        component: 'communityRoutesComponent',
    });
    
}]).name;