import angular from 'angular';

import uirouter from '@uirouter/angularjs';

import {
    moduleName as providerHomeModule,
    orgComponentName as orgHomeComponent,
    physComponentName as physHomeComponent,
} from '../reports/home.provider/home';

export default angular.module('app.pages.orgs.home', [
    uirouter, providerHomeModule
]).config(['$stateProvider', 
function($stateProvider) {
    
    $stateProvider.state({
        name: 'root.app.org.home',
        url: '/home',
        component: orgHomeComponent,
        data: {
            name: 'Summary'    
        },
    });
    
   /* $stateProvider.state({
        name: 'root.app.phy.home',
        url: '/home',
        component: physHomeComponent,
        data: {
            name: 'Summary'    
        },
    });*/
    
}]).name;