import angular from 'angular';
import { contains, sortBy } from 'underscore';
import { react2angular } from 'react2angular';
import template from './postacuteleakage.html';
import modalTemplate from './postAcuteLeakageModal.html';
import modalTooltipTemplate from './postAcuteLeakageModalToolip.html';
import controller from './postacuteleakage.controller';

import phTable from '../../directives/phTable/phTable';
import pic from '../../services/pic';
import api from '../../services/api';
import uibModal from 'angular-ui-bootstrap/src/modal';
import uirouter from '@uirouter/angularjs';
import tour from '../../services/tour';
import {PostacuteLeakageComponent}  from './postacuteleakageComponent';

// const componentName = 'organizationPostacuteleakage';
const componentName = 'postacuteLeakageComponent';
const moduleName = 'app.reports.postacuteleakage.organization';

angular.module(moduleName, [
    phTable, pic, uibModal, uirouter, tour, api
])
.component(
    componentName,
    react2angular(PostacuteLeakageComponent, ['npis', 'opts'], ['api', 'pic', 'notify', 'download', 'tour', 'CommunityList'])
)
// .component(componentName, {
//     bindings: {
//         npis: '<'
//     },
//     controller,
//     controllerAs: '$ctrl',
//     template,
// })
// .directive('postAcuteModal', [function(){
//     return {
//         restrict: 'A',
//         require: {
//             organizationPostacuteleakage: '^',
//             postAcuteModal: ''
//         },
//         controller: ['$scope', '$uibModal', '$state', '$window', 'api', 'notify', function($scope, $uibModal, $state, $window, api, notify){
//             var modalInstance;
//             this.$onInit = function() {
//                 this.openModal = function(npisList){
//                     modalInstance = $uibModal.open({
//                         template: modalTemplate,
//                         size: 'lg',
//                         controller: ['$scope', '$uibModalInstance', 'provider', function($scope, $uibModalInstance, provider) {
//                             var $modalCtrl = this;
                            
//                             $scope.provider = provider;
                            
//                             $scope.options = {
//                                 toolTip: {
//                                     template: modalTooltipTemplate
//                                 }
//                             };
                            
//                             api.Relations(provider.npi, 'outbound')
//                             .then(function(relations) {
//                                 if (relations.length === 0) {
//                                     notify.alert({
//                                         title: 'NO DATA',
//                                         text: 'No Post Acute Leakage data found for provider.',
//                                         delay: 30000
//                                     });
//                                 }
//                                 var filteredRelations = relations.filter(function(relation) {
//                                     return contains(npisList, relation.target.npi);
//                                 });
                                
//                                 $scope.loaded = true;
//                                 $scope.data = filteredRelations = sortBy(filteredRelations, function(relation) {
//                                     return relation.target.value;
//                                 }).slice(0, 25);
//                             }, function(err){
//                                 console.log('Error fetching Post Acute Leakage data for provider: ', err);
//                                 $scope.loaded = true;
//                                 notify.error({
//                                     title: 'ERROR',
//                                     text: 'Error fetching Post Acute Leakage data for provider.',
//                                     delay: 30000
//                                 });
//                             });
                            
//                             $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
//                                 var newTabUrl = $state.href('root.app.provider', {npis: [$scope.provider.npi], path:'home'});
//                                 $window.open(newTabUrl, '_blank');
//                             });
                            
//                             $modalCtrl.close = $uibModalInstance.dismiss;
//                         }],
//                         resolve: {
//                             provider: function() {
//                                 return api.GetProvider($scope.fromNpi);
//                             }
//                         },
//                         controllerAs: '$modalCtrl'
//                     });
//                 };
//             };
//         }],
//         link: function(scope, elem, attrs, ctrl){
//             elem.on('click', function(e){
//                 ctrl.postAcuteModal.openModal(ctrl.organizationPostacuteleakage.npisList);
//             });
//         }
//     };
//}]);

export {
    moduleName,
    componentName
};