import angular from 'angular';
import * as d3Base from 'd3';
import {event as d3SelectionEvent} from 'd3-selection';
import { legendColor } from 'd3-svg-legend';

const d3 = angular.extend({}, d3Base, { legendColor });

// workaround for webpack breaking d3.event
// https://github.com/d3/d3-zoom/issues/32#issuecomment-229889310
d3.getEvent = () => d3SelectionEvent;// call and assign to variable wherever d3.event is needed

export default ['template', '$timeout',
    function(template, $timeout) {

        var options;
        var hoverScope;
        var toolTip;
        var visibleLines;
        
        var monthsRange;

        var defaults = {
            dimensions: {
                margin: {
                    top: 10,
                    right: 10,
                    bottom: 80,
                    left: 80
                },
                dotRadius: 5,
                lineWidth: 1.5,
                container: {},
                lineGraph: {}
            },
            colors: d3.schemeCategory20,
            scales: {
                x: {
                    fn: d3.scaleLinear,
                    accessor: 'x'
                },
                y: {
                    fn: d3.scaleLinear,
                    accessor: 'y'
                },
                color: {
                    fn: d3.scaleOrdinal
                }
            },
            legend: {
                fn: d3.legendColor,
                shape: d3.symbol().type(d3.symbolSquare).size(150),
                shapePadding: 10,
                cellFilter: d => visibleLines.indexOf(d.label) > -1
            },
            axis: {
                x: {
                    fn: d3.axisBottom,
                    ticks: d3.timeMonth,
                    tickFormat: {
                        formatFn: d3.timeFormat,
                        format: "%b %Y"
                    }
                },
                y: {
                    fn: d3.axisLeft,
                    tickFormat: currentYAxisKey => d3.format(",.0f")
                }
            },
            line: {
                curve: d3.curveMonotoneX
            },
            toolTip: {
                styles: {
                    left: 0,
                    top: 0,
                    display: 'none',
                    'z-index': 10001
                },
                arrowStyles: {
                    top: 'auto',
                    bottom: -5
                },
                currencyFormat: d3.format("$,.0f"),
                monthFormat: d3.timeFormat("%B"),
                templateIdentifier: 'ng-template/lineGraphToolTip.html'
            }
        };

        return {
            scope: {
                graphdata: '<',
                currentaxis: '<',
                options: '<'
            },
            controller: [
                '$scope', '$templateCache', '$compile',
                function($scope, $templateCache, $compile) {

                    var $ctrl = this;

                    hoverScope = $scope.$new(true);

                    $ctrl.createTooltipTemplate = templateIdentifier => $templateCache.get(templateIdentifier);
                    $ctrl.createTooltipLinkFn = tooltipTemplate => $compile(tooltipTemplate);

                    $ctrl.appendTooltip = (tooltipLinkFn, tooltipScope) => {
                        angular.element(document.querySelector('body'))
                            .append(
                                toolTip = tooltipLinkFn(tooltipScope)
                            );
                    };

                    [
                        'linegraph.circle.mouseenter', 
                        'linegraph.circle.mouseleave'
                    ].forEach(function(id) {
                        $scope.$on(id, function(event, d3Event, datum, idx, nodes) {

                            switch (id) {
                                case 'linegraph.circle.mouseleave':
                                    $timeout(function(){
                                        hoverScope.styles.display = 'none';
                                    });
                                    break;
                                case 'linegraph.circle.mouseenter':
                                    $timeout(function(){
                                        hoverScope.datum = datum;
                                        hoverScope.currentaxis = $scope.currentaxis;
                                        hoverScope.styles.display = 'block';
                                    });
                                    $timeout(function(){
                                        var toolTipBoundingClientRect = toolTip[0].getBoundingClientRect();
                                        var circleBoundingClientRect = nodes[idx].getBoundingClientRect();
                                        hoverScope.styles.left = (circleBoundingClientRect.left + (circleBoundingClientRect.width/2)) - (toolTipBoundingClientRect.width/2);
                                        hoverScope.styles.top = circleBoundingClientRect.top - circleBoundingClientRect.height - toolTipBoundingClientRect.height;
                                    });
                            }
                        });
                    });
                    
                    $ctrl.$onInit = function() {
                        
                    };
                    
                    $ctrl.$onDestroy = function(){
                        toolTip.remove();
                    };
                    
                }],
            link: function(scope, elem, attrs, ctrl) {

                var svg;
                var resizeTimer;
                var lines;
                var dotGroups;
                var xScale;
                var yScale;
                var colorScale;
                var makeLegend;
                var xAxis;
                var yAxis;
                var xAxisBottomContainer;
                var yAxisLeftContainer;
                var layer;
                var currentYAxisKey;
                var legend;

                // watch for data and then render
                scope.$watchCollection('graphdata', (newData, oldData, theScope) => {
                    if (!oldData && newData) {
                        if (!currentYAxisKey) {
                            currentYAxisKey = Object.keys(scope.currentaxis).filter(key => scope.currentaxis[key])[0];
                        }
                        setScaleDomains(newData);
                        updateScaleSizeRanges();
                        initialize();
                        renderGraph(newData);
                    } else if (oldData && newData)  {
                        renderGraph(newData);
                    }
                });

                // watch for y axis changes
                scope.$watchCollection('currentaxis', (newData, oldData, theScope) => {
                    if (Object.keys(newData).filter(key => newData[key])[0] !== Object.keys(oldData).filter(key => oldData[key])[0]) {
                        
                        currentYAxisKey = Object.keys(newData).filter(key => newData[key])[0];
                        yScale.domain([0, d3.max(theScope.graphdata.flatMap(line => line.values), d => d[currentYAxisKey])]);
                        
                        var tickFormatter = options.axis.y.tickFormat(currentYAxisKey);
                        yAxis.tickFormat(tickFormatter);
                        
                        reRenderGraph();
                    }
                });

                scope.$watch('options', (newData, oldData, theScope) => {
                    if (newData) {

                        options = angular.extend({}, defaults, newData);
                        updateDimensions();
                        setScales();

                        hoverScope.styles = options.toolTip.styles;
                        hoverScope.arrowStyles = options.toolTip.arrowStyles;
                        hoverScope.currencyFormat = options.toolTip.currencyFormat;
                        hoverScope.monthFormat = options.toolTip.monthFormat;

                        var tooltipTemplate = ctrl.createTooltipTemplate(options.toolTip.templateIdentifier);
                        var tooltipLinkFn = ctrl.createTooltipLinkFn(tooltipTemplate);
                        ctrl.appendTooltip(tooltipLinkFn, hoverScope);
                    }
                });

                // called after options passed in but before dimensions set and data is ready
                function setScales() {

                    xScale = options.scales.x.fn();

                    yScale = options.scales.y.fn();

                    colorScale = options.scales.color.fn(options.colors);

                    makeLegend = options.legend.fn()
                        .shape("path", options.legend.shape())
                        .shapePadding(options.legend.shapePadding)
                        .cellFilter(options.legend.cellFilter)
                        .scale(colorScale);

                    xAxis = options.axis.x.fn(xScale)
                        .ticks(options.axis.x.ticks)
                        .tickFormat(options.axis.x.tickFormat.formatFn(options.axis.x.tickFormat.format));
                }

                // called only once after scales initialized and data is ready
                function setScaleDomains(data) {

                    // flatten lines data for each of calculating domains
                    var flattenedData = data.flatMap(lineObj => lineObj.values);
                    var dateExtent = d3.extent(flattenedData, d => d[options.scales.x.accessor]);

                    //calculate x domain and update x scale
                    xScale
                        .domain(dateExtent);

                    //determine names and map to color pallette
                    colorScale
                        .domain(options.scales.color.domain);

                    //calculate y domain and update y scale
                    yScale.domain([0, d3.max(flattenedData, d => d[currentYAxisKey])]);
                }

                // used whenever container dimensions need to be recalculated and saved in options
                function updateDimensions() {
                    options.dimensions.container = {
                        // parent used here because line graph directive on svg element
                        width: (elem.parent().width()),
                        height: (elem.parent().height())
                    };
                }

                // called for initial scale size range setup and after page resize
                function updateScaleSizeRanges() {

                    xScale
                        .range([
                            (options.dimensions.dotRadius * 2),
                            (options.dimensions.container.width - options.dimensions.margin.left - options.dimensions.margin.right - (options.dimensions.dotRadius * 2))
                        ]);

                    yScale
                        .range([
                            (options.dimensions.container.height - options.dimensions.margin.top - options.dimensions.margin.bottom),
                            0
                        ]);

                }

                // called when page resizes or y axis changes
                function reRenderGraph() {

                    xAxisBottomContainer
                        .attr("transform", `translate(${options.dimensions.margin.left},${options.dimensions.container.height - options.dimensions.margin.bottom})`);

                    xAxisBottomContainer.call(xAxis.scale(xScale))
                        .selectAll("text")
                            .attr("y", 0)
                            .attr("x", 9)
                            .attr("dy", ".35em")
                            .attr("transform", "rotate(90)")
                            .style("text-anchor", "start");

                    yAxisLeftContainer
                        .call(yAxis);

                    layer.selectAll('.line')
                        .attr("d", d => {
                            return d3.line()
                                .defined(d => !!d[currentYAxisKey])
                                .x( d => xScale(d.date) )
                                .y( d => yScale(d[currentYAxisKey]) )
                                .curve(options.line.curve)
                                (d.values);
                        });

                    layer.selectAll('.dot')
                        .attr('cx', d => xScale(d.date))
                        .attr('cy', d => yScale(d[currentYAxisKey]));
                }

                // called only once, after scales finished, but before lines and dots rendered
                function initialize() {

                    svg = scope.lineGraph = d3.select(elem[0])
                        .attr("width", options.dimensions.container.width)
                        .attr("height", options.dimensions.container.height);

                    xAxisBottomContainer = svg
                        .append('g')
                            .attr("transform", `translate(${options.dimensions.margin.left},${options.dimensions.container.height - options.dimensions.margin.bottom})`)
                            .classed('xAxisBottom', true);

                    yAxisLeftContainer = svg
                        .append('g')
                            .attr('transform', `translate(${options.dimensions.margin.left},${options.dimensions.margin.top})`)
                            .classed(`yAxisLeft`, true);

                    xAxisBottomContainer.call(xAxis)
                        .selectAll("text")
                            .attr("y", 0)
                            .attr("x", 9)
                            .attr("dy", ".35em")
                            .attr("transform", "rotate(90)")
                            .style("text-anchor", "start");

                    // all lines and dots will be appended to "layer"
                    layer = svg
                        .append('g')
                        .classed(`layer`, true)
                        .attr('transform', `translate(${options.dimensions.margin.left},${options.dimensions.margin.top})`);

                    var tickFormatter = options.axis.y.tickFormat(currentYAxisKey);
                    yAxis = options.axis.y.fn(yScale)
                        .tickFormat(tickFormatter);
                        
                    yAxisLeftContainer
                        .call(yAxis);
                        
                    legend = svg.append("g")
                        .attr("class", "legendOrdinal")
                        .attr("transform", `translate(${options.dimensions.margin.left + 20},${options.dimensions.margin.top + 20})`);
                }

                // called at end of initial render and when new data passed in
                function renderGraph(data) {

                    visibleLines = data.map(group => group.key);

                    lines = layer.selectAll(".line")
                        .data(data, d => d.key);

                    lines
                        .enter()
                        .append("path")
                            .classed("line", true)
                            .attr("fill", "none")
                            .attr("stroke", d => colorScale(d.key) )
                            .attr("stroke-width", options.dimensions.lineWidth)
                            .attr("d", d => {
                                return d3.line()
                                    .defined(d => !!d[currentYAxisKey])
                                    .x(d => xScale(d.date) )
                                    .y(d => yScale(d[currentYAxisKey]) )
                                    .curve(options.line.curve)
                                    (d.values);
                            });

                    dotGroups = layer.selectAll('.dots')
                        .data(data, d => d.key);

                    dotGroups
                        .enter()
                        .append('g')
                            .classed('dots', true)
                            .attr('fill', d => colorScale(d.key))
                            .selectAll('circle')
                            .data(d => d.values)
                                .enter()
                                .append('circle')
                                    .style('display', d => d[currentYAxisKey] ? 'initial' : 'none')
                                    .classed('dot', true)
                                    .attr('r', options.dimensions.dotRadius)
                                    .attr('cx', d => xScale(d.date))
                                    .attr('cy', d => yScale(d[currentYAxisKey]))
                                    .on('mouseenter', (datum, i, nodes) => {
                                        const d3event = d3.getEvent();
                                        scope.$emit('linegraph.circle.mouseenter', d3event, datum, i , nodes);
                                    })
                                    .on('mouseleave', (datum, i, nodes) => {
                                        scope.$emit('linegraph.circle.mouseleave');
                                    });
                                    

                    lines
                        .exit()
                        .remove();

                    dotGroups
                        .exit()
                        .remove();

                    legend.call(makeLegend);
                }

                var resizeDisposal = template.contentResize(function(){
                    if (scope.graphdata) {

                        $timeout.cancel(resizeTimer);
                        resizeTimer = $timeout(function() {

                            // Run code here, resizing has "stopped"
                            updateDimensions();
                            updateScaleSizeRanges();

                            svg
                                .attr("width", options.dimensions.container.width)
                                .attr("height", options.dimensions.container.height);

                            reRenderGraph();

                        }, 250);
                    }
                });

                scope.$on('$destroy', resizeDisposal);
            }
        };
    }
];