import React, { useEffect, useRef, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { geoflowModule } from '../../js/geoflowModule.js';
import { usePic, useLogging } from '../../services/servicesContext';

export function GeoflowComponent() {
    
    const [ loading, setLoading ] = useState(true);
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const pic = usePic();
    const logging = useLogging();
    const elem = useRef(null);
    const leafletInstance = useRef(null);
    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.graphs.geoflow`
        });

        async function init() {
            try {
                setLoading(true);
                const geoflowReportData = await npis.Report('geoflow');
                setLoading(false);
                const geoflowInstance = geoflowModule(elem.current);
                geoflowInstance.geoflow({
                    providername: npis.name.display,
                    NPI: npis.npi,
                    center: npis.location.coordinates,
                    entitytypecode: npis.entitytype,
                    filterOpen: true,
                    legend: true,
                    recentLegend: true,
                    zoom: 4,
                    pic: pic,
                    geoFlowComponentElementName: '#geo-flow',
                    generateHomeUrl: function({npi, entitytype}){
                        if (entitytype == 1) {
                            return `/physician/${npi}/home`;
                        } else if (entitytype == 2) {
                            return `/organization/${npi}/home`
                        } else {
                            console.log('entitytype not provided');
                            return '';
                        }
                    },
                    mapSizeCallback: function(map) {
                        leafletInstance.current = map;
                    },
                    data: geoflowReportData
                });
                
            } catch(err) {
                setLoading(false);
                console.error('error in GeoflowComponent init', err);
            }
        }

        init();

        return () => {
            if (leafletInstance.current) {
                leafletInstance.current.remove()
            }
        };

    }, [npis]);

    return (<>
        { loading && <div className="loading-lg"></div>}
        <div id="geo-flow" ref={elem} style={{height:'95%', width:'100%'}}></div>
    </>);
}