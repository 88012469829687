import React, { useEffect, useState, useRef } from 'react';

import { phGroupedBarGraph } from '../../js/groupedBarGraph';

export function GroupedBarGraphComponent(props) {
    
    const divRef = useRef(null);
    const instance = useRef(null);
    const elemDims = useRef(null);
    const resizeTimer = useRef(null);
    const resizeDisposal = useRef(null);

    function updateDimensions() {
        elemDims.current = divRef.current.getBoundingClientRect();
    }    

    useEffect(() => {

        elemDims.current = divRef.current.getBoundingClientRect();

        instance.current = phGroupedBarGraph();
        instance.current(divRef.current, {
            data: props.data,
            opts: props.opts,
            width: elemDims.current.width,
            height: elemDims.current.height
        });

        resizeDisposal.currrent = props.template.contentResize(function(){
            if (props.data) {
                clearTimeout(resizeTimer.current);
                resizeTimer.current = setTimeout(function(){
                    updateDimensions();
                    instance.current.dimensions({width: elemDims.current.width, height: elemDims.current.height});
                },250);
            }
        });
        return () => {
            resizeDisposal.currrent();
            var groupedBarGraphDestroyResult = instance.current.destroy();
        };
    }, []);

    useEffect(()=> {
        if (props.data && props.opts) {
            instance.current.servicelines(props.data, props.opts);
        }
    }, [props.data, props.opts]);

    return <div ref={divRef} className="col-md-12 ph-grouped-bar-graph"></div>
}