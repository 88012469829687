import angular from 'angular';

import * as d3Base from 'd3';
import { interpolateRdBu  as d3interpolateRdBu } from 'd3-scale-chromatic';

const d3 = angular.extend({}, d3Base, { interpolateRdBu: d3interpolateRdBu });

export default ['$scope', '$http', '$window', '$timeout', '$state', '$templateCache', '$compile', 'notify', 'api',
    function($scope, $http, $window, $timeout, $state, $templateCache, $compile, notify, api) {
        
        var $ctrl = this;
        
        var toolTip;
        var colorScale;
        
        var tooltipTemplate = $templateCache.get('ng-template/quadrantAnalysysToolTip.html');
        var tooltipLinkFn = $compile(tooltipTemplate);
        
        var format1s = d3.format(',.1s'),
            format4s = d3.format(',.4s');
        
        var hoverScope =  $scope.$new(true);
        
        hoverScope.datum = {
            label: 'Label',
            charges: 0,
            count: 0,
            leakage: 0,
            shared: 0
        };
        
        hoverScope.styles = {
            'z-index': 2000,
            position: 'absolute',
            width: 212,
            opacity: 0,
            top: 0,
            left: 0
        };
        
        hoverScope.arrowStyles = {
            top: 0,
            bottom: 0
        };
        
        hoverScope.$ctrl = $ctrl;
        
        hoverScope.formatLeakage = function(num){
            return (((1 - Number.parseFloat(num)) * 100).toFixed(2));
        };
            
        hoverScope.formatSize = format4s;
        
        hoverScope.children = function(datum) {
            return Object.keys(datum.children).map(function(key) { 
                return datum.children[key]; 
            });
        };
        
        $ctrl.$onInit = function() {
            buildQuadrantAnalysis($ctrl.npis[0]);
            buildCircleToolTip();
            angular.element(document.querySelector('#loading')).css('display', 'none');// TODO: this will eventually be template.loading(false); from the template service.
        };
        
        $ctrl.$onDestroy = function(){
            toolTip.remove();
        };
        
        $scope.$on('listMouseEnter', function(event, data){
            $scope.$broadcast('nodeMouseEnter', data);
        });
        
        $scope.$on('listMouseLeave', function(event, data){
            $scope.$broadcast('nodeMouseLeave', data);
        });
        
        $scope.$on('listMouseClick', function(event, data){
            $scope.$broadcast('nodeMouseClick', data);
        });
        
        function buildCircleToolTip() {
            toolTip = angular.element(document.querySelector('.circleTooltip'));
            
            if(!toolTip.length) {
                angular.element(document.querySelector('body'))
                .append(
                    toolTip = tooltipLinkFn(hoverScope)
                );
            }
        }
        
        function getArrayFromTreeProp(treeObj){
            return Object.keys(treeObj.children).map(function(key){
                return treeObj.children[key];
            });
        }
        
        function buildDataTree(data) {
            var dt = {
                children: {}    
            };
            data.forEach(function(d) {
                if (!dt.children[d.classification]) {
                    dt.children[d.classification] = { 
                        id: 'cl-'+d.classification,
                        label: d.classification,
                        count: 0,
                        shared: 0,
                        leakage: 0,
                        charges: 0,
                        children: {}    
                    };
                }
                    
                if (!dt.children[d.classification].children[d.specialization]) {
                    dt.children[d.classification].children[d.specialization] = { 
                        id: 'sp-'+d.specialization,
                        label: d.specialization,
                        parent: dt.children[d.classification],
                        count: 0,
                        shared: 0,
                        leakage: 0,
                        charges: 0,
                        children: {}
                    };
                }
                
                dt.children[d.classification].shared += d.sharedTransactionCount;
                dt.children[d.classification].children[d.specialization].shared += d.sharedTransactionCount;
                
                dt.children[d.classification].leakage += d.leakage;
                dt.children[d.classification].children[d.specialization].leakage += d.leakage;
                
                dt.children[d.classification].charges += d["Submitted Charges"];
                dt.children[d.classification].children[d.specialization].charges += d["Submitted Charges"];
                
                dt.children[d.classification].count += 1;
                dt.children[d.classification].children[d.specialization].count += 1;
                
                dt.children[d.classification].children[d.specialization].children[d.npi] = {
                    id: d.npi,
                    label: d.name,
                    parent: dt.children[d.classification].children[d.specialization],
                    count: 1,
                    shared: d.sharedTransactionCount,
                    leakage: d.leakage,
                    charges: d["Submitted Charges"],
                };
            });
            return dt;
        }
        
        function xValue(d) {
            return d.shared / d.count;
        }
        
        $ctrl.xValue = xValue;
        
        function yValue(d){
            return Math.round(d.shared / (d.leakage + d.shared) * 1000) / 10;
        }
        
        $ctrl.yValue = yValue;
        
        function sizeValue(d) {
            return d.charges;
        }
        
        $ctrl.sizeValue = sizeValue;
        
        function colorValue(d) {
            return (d.shared / (d.leakage + d.shared));
        }
        
        $ctrl.colorValue = colorValue;
        
        function buildQuadrantAnalysis(npiProvider){
            
            var npiDisplayName = npiProvider.name.display;
            var endpoint = '/api/npi/' + npiProvider.npi + '/quadrant-analysis/';
            var dt, back, title;
            
            $ctrl.colorScale = colorScale = d3.scaleSequential(d3.interpolateRdBu);
            
            $ctrl.bubbleOpts = {
                dimensions: {
                    marginTop: 30,
                    marginRight: 1,
                    marginBottom: 150,
                    marginLeft: 50
                },
                back: {
                    value: '< Back',
                    visible: false
                },
                title: npiDisplayName || '',
                nodes: {
                    opacity: {
                        value: .9,
                        hover: {
                            active: 1,
                            inactive: .2  
                        }
                    }
                },
                events: {
                    hover: function(event, datum, index) {
                        
                        if (event.type === "mouseenter") {
                            
                            hoverScope.$apply(function() {
                                hoverScope.datum = datum;
                            });
                            
                            hoverScope.$apply(function(){
                                
                                var eventTargetBoundingClientRect = event.target.getBoundingClientRect();
                                var toolTipBoundingClientRect = toolTip[0].getBoundingClientRect();
                                
                                hoverScope.styles.left = (eventTargetBoundingClientRect.left - (hoverScope.width/2 - eventTargetBoundingClientRect.width/2));
                                
                                if (yValue(datum) > 50) { 
                                    hoverScope.arrowStyles.top = -5;
                                    hoverScope.arrowStyles.bottom = 'auto';
                                    hoverScope.styles.left = (eventTargetBoundingClientRect.left - (hoverScope.styles.width/2 - eventTargetBoundingClientRect.width/2));
                                    hoverScope.styles.top = (eventTargetBoundingClientRect.top + eventTargetBoundingClientRect.height);
                                } else {
                                    hoverScope.arrowStyles.top = 'auto';
                                    hoverScope.arrowStyles.bottom = -5;
                                    hoverScope.styles.left = (eventTargetBoundingClientRect.left - (hoverScope.styles.width/2 - eventTargetBoundingClientRect.width/2));
                                    hoverScope.styles.top = (eventTargetBoundingClientRect.top - toolTipBoundingClientRect.height);
                                }
                                
                                hoverScope.styles.opacity = 1;
                                
                            });
                        }
                        
                        if (event.type === "mouseleave") {
                            
                            hoverScope.$apply(function(){
                                hoverScope.styles.opacity = 0;
                            });
                                
                            angular.element(document.querySelector('.focus'))
                                .css('display', 'none');
                            
                        }
                        
                    },
                    click: function(event, datum, index) {
                        $timeout(function() {
                            if (datum.children) {
                                
                                if (datum.parent) {
                                    back = datum.parent;
                                } else {
                                    back = dt;
                                }
                                
                                $ctrl.bubbleOpts.back.visible = true;
                                $ctrl.bubbleOpts.title = datum.label;
                                    
                                toolTip.css('opacity', 0);
                                $ctrl.datums = getArrayFromTreeProp(datum);
    
                            } else {
                                var newTabUrl = $state.href('root.app.provider', {npis: [datum.id], path:'home'});
                                $window.open(newTabUrl, '_blank');
                            }
                        }, 0);
                    },
                    back: function(event, backData, index) {
                        $timeout(function(){
                            $ctrl.bubbleOpts.title = back.label || npiDisplayName;
                            $ctrl.datums = getArrayFromTreeProp(back);
                            
                            if (back == dt ) {
                                $ctrl.bubbleOpts.back.visible = false;
                            } else if (!back.parent) {
                                back = dt;
                            } else {
                                back = back.parent;    
                            }
                        }, 0);
                    }
                },
                x: {
                    scale: d3.scaleLog(),
                    value: xValue,
                    extent: function (options) {
                        return d3.extent(options.data, options.x.value);
                    },
                    axisLabel: 'X-Axis: Volume',
                    tickFormat: function(value) { return format1s(value)}
                },
                y: {
                    value: yValue,
                    extent: function (options) {
                        return d3.extent(options.data, options.y.value);
                    },
                    extentRange: function(options) {
                        return (options.y.extent(options)[1] - options.y.extent(options)[0]);
                    },
                    axisLabel: 'Y-Axis: Market Share',
                    tickFormat: function(value) { return value + "%"; }
                },
                size: {
                    value: sizeValue,
                    axisLabel: 'Size: Charges',
                    tickFormat: function(value) { return  format4s(value); }
                },
                color: {
                    value: colorValue,
                    scale: colorScale,
                    axisLabel: 'Color: Leakage',
                    tickFormat: function(value) { return value + "%"; }
                },
                id: function(d) {
                    return d.id;    
                }
            };
            
            $ctrl.legendOpts = {
                dimensions: {
                    paddingTop: 30
                },
                sort: {
                    value: yValue,
                    order: d3.descending
                },
                color: {
                    value: colorValue,
                    scale: colorScale
                },
                events: {
                    mouseenter: function(event, datum, index) {
                        $scope.$emit('listMouseEnter', datum);
                    },
                    mouseleave: function(event, datum, index) {
                        $scope.$emit('listMouseLeave', datum);
                    },
                    click: function(event, datum, index) {
                        $scope.$emit('listMouseClick', datum);
                    }
                },
                id: function(d) {
                    return d.id;    
                }
            };
            
            $http.get(endpoint, api.options())
            .then(function(response){
                if (response.data.scatter_data.length === 0) {
                    notify.alert({
                        title: 'NO DATA',
                        text: 'No Quadrant Analysis data available for provider.',
                        delay: 30000
                    });
                }
                $scope.loaded = true;
                dt = buildDataTree(response.data.scatter_data);
                $ctrl.datums = getArrayFromTreeProp(dt);
                console.log("$ctrl", $ctrl);
            }, function(err){
                console.log('Error fetching Quadrant Analysis data: ', err);
                $scope.loaded = true;
                notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Quadrant Analysis data.',
                    delay: 30000
                });
            });
            
        }
        
    }
];