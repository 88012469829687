import React, { useEffect, useState } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails } from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu } from '../../components/popoverMenu/popoverMenu';
import download from '../../services/download'; 
import Modal from './modal';


export default function HospitalAffiliations({ provider, api, affiliations, pic, CommunityList, download, affiliationsList }) {
    
    const [ hospitalAffiliates, setHospitalAffiliates ] = useState(null);
    const [ groupAffiliates, setGroupAffiliates ] = useState(null);
    const [ entitytype, setEntityType ] = useState('');
    const [ ttm, setTtm] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ isOpen, setIsOpen] = useState(false);
    const [ modalconfig, setModalconfig ] = useState({});
    const [ data, setData ] = useState([]);
    const [ reportconfig, setReportconfig ] = useState([]);


    const reportConfigHospitalsPhy = function() { 
    
        return {  
          tableConfig :  [
                {
                "header": {
                    "id": "col1",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content, rowObject) { 
                        var npi_popover = ""
                        if (!content) return
                        if (!rowObject || !rowObject.location) {
                            npi_popover = {
                                provider: {
                                    name: {
                                        display: "Provider Name Not Found"
                                    },
                                    location: {
                                        city: "Not Found",
                                        state: "Not Found",
                                        postal_code: "Not Found",
                                        phone_number: "Not Found"
                                    }
                                }
                            }
                            return <PopoverDetails {...npi_popover}>{content}</PopoverDetails> 
                        } else {
                            npi_popover = {
                                provider: {
                                   name: { 
                                      display: rowObject.name && rowObject.name.display || ""
                                   },
                                    location: {
                                        city: rowObject.location.city || "",
                                        state: rowObject.location.state || "",
                                        postal_code: rowObject.location.postal_code || "",
                                        phone_number: rowObject.location.phone_number || ""
                                    }
                                }
                            }
                        }
                        return <PopoverDetails {...npi_popover}><Link to={`/organization/${content}/home`}>{content}</Link></PopoverDetails> 
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "facilityId",
                        "content": "Facility ID",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "hospitalName",
                        "content": "Hospital Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowObject) {
                            const provider = { name : rowObject.name.display, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <span className="context-menu-icon pointer-cursor">
                                    <i className="fa fa-list-alt" role="button" aria-hidden="true" onClick={() => setModalConfiguration(rowObject)}></i>
                                    <span> { rowObject && rowObject.name && rowObject.name.display }</span>
                                    <span className="pull-right">
                                        <PopoverMenu provider={provider}>
                                        <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </span>
                                        </PopoverMenu>
                                    </span>
                                </span>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "communities",
                        "content": "Communities",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { 
                            if (!content && !Array.isArray(content)) return
                            const communities = content.map((rowObject) => {
                               const name_popover = {
                                providerCollection: 
                                    { 
                                      name:  rowObject.name || '',
                                      insert_date: "",
                                      insert_user:  "",
                                      npis: rowObject.npis || []
                                    }
                                }
                                return <PopoverDetails {...name_popover}>  <Link to={`/community/${rowObject.id}/home`}>{rowObject.abbr}</Link></PopoverDetails> 
                            })
                            return <>
                              {communities}
                             </>
                            }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "source",
                        "content": "Source",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                }
             ],
             configure : true,
             configureButton: false,
             pagination: false,
             showSelected: false,
             filterInput: true,
             csvdownload: true,
             reportTitle: `${provider?.name?.display || 'Provider'} hospital affiliations`
        }
    } 

    const reportConfigHospitalsOrg = function() { 
        return {  
          tableConfig :  [
                {
                "header": {
                    "id": "col1",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "export": true,
                    "tooltip" : true,
                    "tooltipType" : "relations",
                    "template": function(content, rowObject) { 
                        var npi_popover = ""
                        if (!content) return
                        if (!rowObject || !rowObject.location) {
                            npi_popover = {
                                provider: {
                                    name: {
                                        display: "Provider Name Not Found"
                                    },
                                    location: {
                                        city: "Not Found",
                                        state: "Not Found",
                                        postal_code: "Not Found",
                                        phone_number: "Not Found"
                                    }
                                }
                            }
                            return <PopoverDetails {...npi_popover}>{content}</PopoverDetails> 
                        } else {
                            npi_popover = {
                                provider: {
                                   name: { 
                                      display: rowObject.name && rowObject.name.display || ""
                                   },
                                    location: {
                                        city: rowObject.location.city || "",
                                        state: rowObject.location.state || "",
                                        postal_code: rowObject.location.postal_code || "",
                                        phone_number: rowObject.location.phone_number || ""
                                    }
                                }
                            }
                        }
                        return <PopoverDetails {...npi_popover}><Link to={`/physician/${content}/home`}>{content}</Link></PopoverDetails> 
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "displayName",
                        "content": "Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowObject) {
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "taxonomy",
                        "content": "Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content && content.classification }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "source",
                        "content": "Source",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                }
             ],
        configure : true,
        configureButton: false,
        pagination: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
            reportTitle: `${provider?.name?.display || 'Provider'} Hospital Affiliates`
        }
    }

    useEffect(() => {
        onInit();
        if (provider.entitytype === '1') {
            setReportconfig(reportConfigHospitalsPhy);
        } else {
            setReportconfig(reportConfigHospitalsOrg);
        }
    }, []);

    const setModalConfiguration = (rowObject) => {
        setIsOpen(true);
        setLoading(true);
        const modalconfig = {
            'npi' : rowObject.npi,
            'id': rowObject.facilityId,
            affiliationtype : 'hospital'
        };
        getProviders(modalconfig)
        const opts = {
            'npi': rowObject.npi,
            'id': rowObject.facilityId,
            'affiliationtype': 'hospital',
            'name':  rowObject.name.display
        };
        setModalconfig({ data : hospitalAffiliates, opts : opts, affiliations: affiliations, api: api });
    }

    const onInit = async function() {
        const Provider = provider;
        setLoading(true);

        if (Provider instanceof api.Physician) {
            setEntityType('physician');
            setLoading(false);
        } else if (Provider instanceof api.Organization) {
            setEntityType('organization');
            setLoading(false);
        } else {
            console.error('Provider not physician or organization');
        }
      
    }

    const getProviders = async (modalconfig) => {
        setLoading(true);
        if (modalconfig.affiliationtype === 'hospital') {
            let postObj = {};
            if (modalconfig.npi) {
                postObj.hospitalnpi = `${modalconfig.npi}`;
            } else {
                postObj.hospitalproviderid = modalconfig.id;
            }
            await affiliations.getHospitalAffiliations(postObj)
                .then(affiliates => {
                    return Promise.all(affiliates.map( afl => {
                        return api.GetProvider(afl.npi).then(provider => {
                            provider.source = afl.source;
                            provider.facilityId = afl.hospitalproviderid;
                            return provider;
                        });
                    }))
                    .then((providers) => { 
                        setData(providers);
                        setLoading(false)
                        return providers;
                    });
                });
        } else if (modalconfig.affiliationtype === 'group') {
            let postObj = {};
            if (modalconfig.npi) {
                postObj.groupnpi = `${modalconfig.npi}`;
            } else {
                postObj.grouppracticeid = modalconfig.id;
            }
            await affiliations.getGroupAffiliations(postObj)
                .then(affiliates => {
                    return Promise.all(affiliates.map( afl => {
                        return api.GetProvider(afl.npi).then(provider => {
                            provider.source = afl.source;
                            provider.grouppracticeid = afl.grouppracticeid;
                            return provider;
                        });
                    }))
                    .then((providers) => {
                        setLoading(false)
                        return providers
                    });
                });
        } else {
            return [];
        }
         
    }

    const updateCommunityList = function(affiliationsToUse) {
        if (!Array.isArray(affiliationsToUse)) return
        CommunityList.update({listVal: [...new Set(affiliationsToUse.map(afl => afl.npi).filter(afl => afl))]});
    };

    const closeModal = (currentState) => {
        setIsOpen(currentState);
        setLoading(false);
    }


  const renderComponents = () => {
    if (affiliationsList && affiliationsList.length > 0) {
      if (provider.entitytype === '1') {
          return <PhtableComponent download={download} data={affiliationsList} reportconfig={reportConfigHospitalsPhy}></PhtableComponent>
      } else if (provider.entitytype === '2') {
          return <PhtableComponent download={download} data={affiliationsList} reportconfig={reportConfigHospitalsOrg}></PhtableComponent>
      } else {
          return null
      }
    } 
  }
    
  return (
     <>
    <div className="loading-lg" style={{ 'height' : "50%", display : (loading ? 'inline' : 'none' )}}></div>
       <Modal download={download} open={isOpen} config={modalconfig} onClose={() => closeModal(!isOpen)} modaldata={data} CommunityList={CommunityList} loading={loading} />
       {(!loading && affiliationsList && affiliationsList.length === 0) ? <div style={{ 'margin' : '20px'}}>No Results.</div> : null}
       {renderComponents()}
    </>
  )
}

