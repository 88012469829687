import angular from 'angular';
import { react2angular } from 'react2angular';

import './home.scss';
import controller from './home.controller';
import template from './home.html';
import affiliationsModal from './affiliationsModal';

import pic from '../../services/pic'; 
import CommunityList from '../../services/communityList'; 
import localStorage from '../../services/localStorage';
import tour from '../../services/tour';
import api from '../../services/api';
import affiliations from '../../services/affiliations';
import download from '../../services/download';
import 'leaflet/dist/leaflet.js';
import 'ui-leaflet';
import 'leaflet-groupedlayercontrol';
import uibModal from 'angular-ui-bootstrap/src/modal';

import '../../js/leafletIconOverride.js';

import { HomeComponent } from './homeComponent'
const orgComponentName = 'orgHome';
const physComponentName = 'physHome';
const moduleName = 'app.reports.home';

angular.module(moduleName, [
    pic, CommunityList, localStorage, tour, api, affiliations, download, uibModal, 'nemLogging', 'ui-leaflet'
])
.component(
    physComponentName,
    react2angular(HomeComponent, ['npis', 'opts'], ['api', 'pic', 'notify', 'pic', 'CommunityList', 'affiliations', '$q', 'download'])
)
.component(orgComponentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.directive('affiliationsModal', affiliationsModal);

export {
    moduleName,
    orgComponentName,
    physComponentName
};