import angular from 'angular';

import './performancefunnel.scss';
import template from './performancefunnel.html';
import controller from './performancefunnel.controller';
import tour from '../../services/tour';
import d3Funnel from './d3Funnel.js';

const componentName = 'providerPerformancefunnel';
const moduleName = 'app.reports.performancefunnel.provider';

angular.module(moduleName, [
    tour, d3Funnel
])
.component(componentName, {
    bindings: {
        npis: '<',
        tour: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});

export {
    moduleName,
    componentName
};