import angular from 'angular';

import uirouter from '@uirouter/angularjs';

import { moduleName as communityHomeModule, componentName as communityHomeComponent } from '../reports/home.community/home';

export default angular.module('app.pages.community.home', [
    uirouter, communityHomeModule
]).config(['$stateProvider', 
function($stateProvider) {
    
    $stateProvider.state({
        name: 'root.app.com.home',
        url: '/home',
        component: communityHomeComponent, 
        data: {
            name: 'Home'
        }
    });
    
}]).name;