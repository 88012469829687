import React, { useEffect, useState } from "react";
import ReactDom from 'react-dom';
import { useRouteLoaderData, Link, useNavigate } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails } from '../../components/popoverDetails/popoverDetails';
import { CommunityModal } from "../../components/community/modalCommunityComponent";

export function Modal (props) {
//open, onClose, config, loading, CommunityList, download, api
const {open, onCloseModal, config, loading, CommunityList, download, api} = props
const [show, setShow] = useState(false);
const [comm, setComm] = useState(null);
let navigate = useNavigate(); 
const routeChange = (redirectTo) =>{ 
    navigate(redirectTo);
}


    useEffect(() => {
        updateCommunityList(config.data.allProviders);
    }, []);

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: 'calc(110vh - 210px)',
        minWidth: '960px',
        overflowY: 'auto',
        padding: '25px 20px'
      } 
    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
      }

    function handleClose () {
        if (show) {
            setShow(false);
        }
    }

    function handleShow () {
        if (!show) {
            setShow(true);
        }
    }

    const reportConfig = function() { 
        return {  
        tableConfig :  [
            {
                "header": {
                    "id": "col7",
                    "accessor": "NPI",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "export": true,
                    "sortable": true,
                    "template": function(content, rowDataObject) { 
                        const formatPhoneNum = rowDataObject && rowDataObject.Telephone.toString();
                        const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                        const provider = { 
                            location : {
                            'postal_code' : rowDataObject["Postal Code"],
                            'phone_number': phone,
                            'city': rowDataObject.City,
                            'state': rowDataObject.State
                            },
                            'name': {'display' : rowDataObject["Provider Name"] }
                        } 
                        return <PopoverDetails provider={provider}><Link to={`/physician/${content}/home`}>{content}</Link></PopoverDetails>  
                    } 
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 7
                    }
                },

                {
                    "header": {
                        "id": "col6",
                        "accessor": "Provider Name",
                        "content": "Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "Classification",
                        "content": "Specialization",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { return `${content} (${rowObject.Specialization})` }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "Total Charges",
                        "content": "Total Charges",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { return `$${rowObject['Total Charges'].toLocaleString()}` }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "Visits to other organizations",
                        "content": "Visits to other organizations",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "Share",
                        "content": "Market Share",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { return `${(rowObject.Share*100).toFixed(2)}%`  }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col1",
                        "accessor": "leakage",
                        "content": "Share to Competitors",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowObject) { return `${((1 - rowObject.Share)*100).toFixed(2)}%`  }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 1
                        }
                }
            ],
        configure : true,
        configureButton: false,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true
    }
    }

    if (!open) return null;

    const createCommunity = function () {
        CommunityList.openCommunityModal();
    };

    const updateCommunityList = function(data) {
        CommunityList.update({listVal: [...new Set(data.map(dt => dt.NPI).filter(dt => dt))] })
        setComm(CommunityList.get());
    };

    //updateCommunityList(config.data.allProviders);

    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-body container-fluid">
            { <PhtableComponent download={download} data={config.data.allProviders} reportconfig={reportConfig}></PhtableComponent> }
            </div>
            <div className="modal-footer">
            <div className="modal-create-community pointer" onClick={() => { if (comm && comm.list && comm.list.length > 0) {handleShow()} else {routeChange("/search")}}}>
                    <span className="badge modal-community-count">{comm && comm.list ? "+"+comm.list.length : 0}</span>
                <a><i className="fa fa-users fa-2x" aria-hidden="true"></i></a>
            </div>
            <CommunityModal show={show} onClose={handleClose} api={api}/>
            <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={onCloseModal}>Close</button>
        </div>
        </div>
</>, document.getElementById('ph-table-root'));
}

