import angular from 'angular';

import { react2angular } from 'react2angular';

import template from './sharedvisits.html';
import controller from './sharedvisits.controller';

import api from '../../services/api';
import pic from '../../services/pic';
import phTable from '../../directives/phTable/phTable';
import tour from '../../services/tour';
import notify from '../../services/notify';
import download from '../../services/download';
import communityList from '../../services/communityList';
import { SharedVisitsComponent } from './sharedvisitsComponent';
const componentName = 'providerSharedvisits';
const updatedComponentName = 'sharedVisitsComponent';
const moduleName = 'app.reports.sharedvisits.provider';

angular.module(moduleName, [
    api, pic, tour, phTable, notify, download, communityList
])
.component(componentName, {
    bindings: {
        npis: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
})
.component(
    updatedComponentName,
    react2angular(SharedVisitsComponent, ['npis', 'opts'], ['api', 'pic', 'notify', 'download', 'tour', 'CommunityList'])
);
export {
    moduleName,
    componentName,
    updatedComponentName
};