import merge from 'lodash/merge';

export default class Taxonomy {
    constructor(data) {
        merge(this, data)
    }

    toString(ref) {
        ref = ref || this;
        if (ref.specialization) {
            return `${ref.classification} (${ref.specialization})`;
        } else if (ref.classification) {
            return ref.classification;
        } else {
            return ref.type;
        }
    }
}