import angular from 'angular';

import template from './sharedvisits.html';

import { react2angular } from 'react2angular';
import api from '../../services/api';
import notify from '../../services/notify';

import phTable from '../../directives/phTable/phTable';

import download from '../../services/download';

import tableData from '../../directives/tableData/tableData';
import pic from '../../services/pic';
import tour from '../../services/tour';

import { SharedVisitsCommunityComponent } from './sharedvisitsComponent'

const componentName = 'communitySharedvisitsGrouped';
const moduleName = 'app.reports.sharedvisitsGrouped.community';
const updatedComponentName = 'sharedVisitsCommunityComponent';

angular.module(moduleName, [
    phTable, pic, tour
])
/*.component(componentName, {
    bindings: {
        community: '<',
        tour: '<',
        communityList: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template,
});*/

.component(
    updatedComponentName,
    react2angular(SharedVisitsCommunityComponent, ['opts', 'community'], ['api', 'notify', 'download'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};