import { phHorizontalBarGraph } from '../../js/horizontalBarGraph';

export default 'phHorizontalBarGraph';

angular.module('phHorizontalBarGraph', [])
.directive('phHorizontalBarGraph', ['$timeout', '$parse', 'template', function($timeout, $parse, template){
    return {
        scope: {
            data: '=',
            opts: '='
        },
        restrict:'EA',
        link: function(scope, elem, attrs){
            
            var resizeTimer;
            var elemDims = elem[0].getBoundingClientRect();
            
            var instance = phHorizontalBarGraph();
            instance(elem[0], {
                data: scope.data.slice(),
                width: elemDims.width,
                height: elemDims.height,
                seedProvider: scope.opts.seedProvider.npi,
                communityMembers: scope.opts.communityMembers,
                navToProviderHome: scope.opts.navToProviderHome
            });
            
            function updateDimensions(){
                elemDims = elem[0].getBoundingClientRect();
            }
            
            scope.$watch('data', function(newData, oldData) {
                if (newData) {
                    instance.providers(newData);
                }
            });
            
            var resizeDisposal = template.contentResize(function(){
                if (scope.data) {
                    $timeout.cancel(resizeTimer);
                    resizeTimer = $timeout(function(){
                        updateDimensions();
                        instance.dimensions({width: elemDims.width, height: elemDims.height});
                    },250);
                }
            });
            
            scope.$on('$destroy', function() {
                resizeDisposal();
                var sidebarDestroyResult = instance.destroy();
            });
        }
    };
}]);