import angular from 'angular';
import uirouter from '@uirouter/angularjs';

import template from '../services/template';

import { moduleName as CommunityDashboardsModule, componentName as CommunityDashboardsComponent } from '../reports/dashboardssection/dashboardssection';
import { moduleName as ServicelineBenchmarkModule, /* componentName */updatedComponentName as ServicelineBenchmarkComponent } from '../reports/servicelinebenchmark.community/servicelinebenchmark';

export default angular.module('app.pages.community.dashboards', [
    uirouter, template, CommunityDashboardsModule, ServicelineBenchmarkModule
]).config(['$stateProvider', 'authProvider',
function($stateProvider, authProvider) {
  
  $stateProvider.state({
    name: 'root.app.com.dashboards',
    abstract: true,
    template: '<ui-view class="app.com.dashboards"/>',
    url: ''
  });
  
  $stateProvider.state({
    name: 'root.app.com.dashboards.home',
    url: '/dashboards',
    data: {
      name: 'Community Volume Benchmarks',
      dashboards: [
        {
          name: 'Volume of Procedures by Physician Community',
          stateId: 'root.app.com.dashboards.servicelinebenchmark',
          paramkey: 'cid',
          description: "Compare community procedures by servicelines and code"
        }
      ]
    },
    component: CommunityDashboardsComponent
  });
  
  $stateProvider.state({
    name: 'root.app.com.dashboards.servicelinebenchmark',
    url: '/marketbenchmarks',
    data: {
      name: 'Dashboard - Serviceline Benchmarks',
      isActive: function(context) {
        return new Promise((resolve, reject) => {
          return context.type().then(comType => {
            return resolve(comType === '1');
          });
        });
      },
      hideTTM: true
    },
    component: ServicelineBenchmarkComponent
  });
    
}]).name;