import React, { useState, useEffect, useRef } from 'react'
import { useRouteLoaderData, Link } from "react-router-dom";
import Select from "react-select";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverRelations }  from '../../components/popoverRelations/popoverRelations';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import localStorage from '../../services/localStorageModule';
import eventBus from "../../services/eventBus";
import { toast } from 'react-toastify';
import { useLogging, useTourManager } from '../../services/servicesContext';
import merge from 'lodash.merge';
import './search.scss';

export function SearchComponent(props) {

    const tourManager = useTourManager();
    const logging = useLogging();
    const [loading, setLoading] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const [entityType, setEntityType] = useState(3);
    const [affiliationType, setAffiliationType] = useState("undefined");
    const [affiliationTypeTranslation, setAffiliationTypeTranslation] = useState(null);
    const [taxonomies, setTaxonomies] = useState([]);
    const [groupedSpecialties, setGroupedSpecialties] = useState([]);
    const [selectedSpecialtyOption, setSelectedSpecialtyOption] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [data, setData] = useState({
        npi: [],
        search: [],
        results: []
    });
    const [npiSearch, setNpiSearch] = useState(null);
    const [searchVal, setSearchVal] = useState(null);
    const [selectedStateOption, setSelectedStateOption] = useState({value: '', label: '- None -'});
    const [limit, setLimit] = useState(500);
    const [providerName, setProviderName] = useState(null);
    const [city, setCity] = useState(null);
    const [county, setCounty] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [teamingOnly, setTeamingOnly] = useState(1);
    const [affiliationVal, setAffiliationVal] = useState(null);
    const states = props.config.states();
    const [showCount, setShowCount] = useState(true);
    const [searchMode, setSearchMode] = useState('click');
    const [clickSearchReady, setClickSearchReady] = useState(false);

    const previousController = useRef();
    const providerSearchDelay = useRef();
    const npiSearchDelay = useRef();


    const translateAffiliationType = {
        hospitalnpi: {
            label: 'Hospital NPI',
            colId: 'col10'
        },
        hospitalproviderid: {
            label: 'Hospital Provider Id',
            colId: 'col11'
        },
        hospitalname: {
            label: 'Hospital Name',
            colId: 'col12'
        },
        groupnpi: {
            label: 'Group NPI',
            colId: 'col13'
        },
        grouppracticeid: {
            label: 'Group Practice Id',
            colId: 'col14'
        },
        groupname: {
            label: 'Group Name',
            colId: 'col15'
        },
        "undefined": {
            label: 'Type'
        }
    };

    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'Search - 1/15',
            text: "This is where you can search for Providers and/or create a Community."
        },{
            id: 2,
            attachTo: { element: ".tourscript-input-type", on: 'bottom' },
            title: 'Search - 2/15',
            text: "Choose whether you would like to limit your search to 'Organizations' or 'Physicians'. If you do not want to limit your search, choose 'Both'."
        },{
            id: 3,
            attachTo: { element: ".tourscript-input-npi", on: 'bottom' },
            title: 'Search - 3/15',
            text: "If you know the NPI of the provider you are looking for, enter it here. If you have a list of NPIs, you can enter that list here, as well. If you do not know the NPI, leave this box blank."
        },{
            id: 4,
            attachTo: { element: ".tourscript-input", on: 'bottom' },
            title: 'Search - 4/15',
            text: "Use any combination and number of the remaining boxes to narrow down you search results."
        },{
            id: 5,
            attachTo: {element: ".search-container #ph-table-root", on: 'top' },
            title: 'Search - 5/15',
            text: "Your search results will display here. They will update as you change your inputs in the boxes above."
        },{
            id: 6,
            title: 'Search - 6/15',
            text: "Once you have gotten results, you can sort the results by clicking on any of the column headers."
        },{
            id: 7,
            attachTo: { element: ".filter-box", on: 'bottom' },
            title: 'Search - 7/15',
            text: "Filter your results further here. Rows will remain if any column in the row contains the word or phrase that you type here."
        },{
            id: 8,
            attachTo: { element: "#tourscript-buttons", on: 'bottom' },
            title: 'Search - 8/15',
            text: "Download your results here."
        },{
            id: 9,
            attachTo: { element: ".tourscript-button-startover", on: 'bottom' },
            title: 'Search - 9/15',
            text: "If at anypoint in your search, you would like to clear your inputs and result, click 'Start Over'."
        },{
            id: 10,
            title: 'Search - 10/15',
            text: "When you have results, hover over an NPI to view relations counts for the NPI. This is a good indicator for if the provider you are looking for reports under this NPI."
        },{
            id: 11,
            title: 'Search - 11/15',
            text: "Once you have found the provider you are looking for, clicking the NPI will take you to the provider's home page."
        },{
            id: 12,
            title: 'Search - 12/15',
            text: "To create a community, use the checkboxes you will see after getting results to select the NPIs you would like to add/append to a new or existing community."
        },{
            id: 13,
            title: 'Search - 13/15',
            attachTo: { element: ".tourscript-button-filter", on: 'bottom' },
            text: "You can filter your community list based on the boxes you have checked here. One click will show selected, two will show unselected, and three will take you back to seeing both."
        },{
            id: 14,
            title: 'Search - 14/15',
            attachTo: { element: "#tourscript-community", on: 'bottom' },
            text: "You will see the community button update as you select providers you would like to add."
        },{
            id: 15,
            title: 'Search - 15/15',
            attachTo: { element: "#tourscript-community", on: 'bottom' },
            text: "Once you are satisfied with your list, you can click this button and add/append the selected providers to a new or existing community. "
        }]);
        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.search'
        });
        function init() {    
        setLoading(true);

        if (!states.includes("- None -") ) {
            states.unshift({value: '', name: '- None -'})
        }
            
        props.api.Taxonomies()
        .then(function(res) {
            let taxonomies;

            if (res) {
                taxonomies = res.reduce(function(acc, datum) {
                    
                    if (datum.specialization == null) {
                        datum.specialization = '';
                    }
                    
                    var matchIndex = acc.findIndex(function(item) {
                        return item.classification === datum.classification && item.specialization === datum.specialization;
                    });
                    
                    if (matchIndex > -1) {
                        acc[matchIndex].code.push(datum.code);
                    } else {
                        datum.code = [datum.code];
                        acc.push(datum);
                    }
                    return acc;
                }, []);
            }
            else {
                taxonomies
            }
            setTaxonomies(taxonomies);
            setValuesFromStorage(taxonomies);
            setGroupedSpecialties(groupSort(groupBy(taxonomies, 'type')));
        });

        setLoading(false);
        }    
        init();

        return () => {
            tourManager.clearTour();
        };

    }, []);

    function groupBy(arr, field){
        let groupedArr = [];
        arr.forEach(function(e){
          //look for an existent group
          let group = groupedArr.find(g => g['label'] === e[field]);
          if (group == undefined){
            //add new group if it doesn't exist
            group = {label: e[field], options: []};
            groupedArr.push(group);
          }
          
          //add the element to the group
          let element = {label: (e.classification ? e.classification: "") + (e.specialization ? ` (${e.specialization})` : ""), value: e.code[0]}
          group.options.push(element);
        });
        
        return groupedArr;
    }

    function chunkArray(myArray, chunkSize) {
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];
        
        for (index = 0; index < arrayLength; index += chunkSize) {
            var chunk = myArray.slice(index, index + chunkSize);
            tempArray.push(chunk);
        }
        
        return tempArray;
    }

    function resetPageVariables() {
        setDataState("data.npi", []);
        setDataState("data.search", []);
        setNpiSearch('');
        setEntityType(3);
        setSelectedSpecialtyOption(null);
        setSelectedStateOption(null);
        setProviderName(null);
        setCity(null);
        setCounty(null);
        setPostalCode(null);
        setAffiliationType("undefined");
        setAffiliationVal(null);
        setLimit(500);
        setTeamingOnly(1);
        setShowCount(false);
    };

    function startOver() {
        resetPageVariables();
        localStorage.remove('search.npiSearch');
        localStorage.remove('search.providerSearch');
        setValuesFromStorage();
        setClickSearchReady(false)
        eventBus.dispatch("resetCommunityList");
    };

    function setDataState(obj, val) {
        let prevData = data;
        setData({
            npi: obj === "data.npi" ? val : prevData.npi,
            search: obj === "data.search" ? val : prevData.search,
            results: obj === "data.results" ? val : prevData.results
        })

    };

    function createDatum(provider) {
        return {
            __proto__: provider,
            'npi': provider.npi,
            'hrefURL': "/" + ((provider.entitytype == 1) ? 'physician' : (provider.entitytype == 2) ? 'organization' : 'community') + "/" + provider.npi + "/home",
            'name': provider.name.display,
            'entitytype': provider.entitytype,
            'phone': provider.location.phone_number,
            'address': provider.location.address,
            'city': provider.location.city,
            'county': provider.location.county,
            'state': provider.location.state,
            'postal': provider.location.postal_code,
            'specialty': provider.taxonomy.specialization ? provider.taxonomy.classification + ' (' + provider.taxonomy.specialization + ')' : provider.taxonomy.classification,
            'hospital_affiliations_names': provider.hospital_affiliations ? provider.hospital_affiliations.map(hospAfl => {
                return {
                    ...hospAfl,
                    toString: () => `${hospAfl.hospitalname} - ( NPI:${hospAfl.hospitalnpi ? hospAfl.hospitalnpi : 'n/a'} / ProviderId:${hospAfl.hospitalproviderid ? hospAfl.hospitalproviderid : 'n/a'} )`
                };
            }) : provider.hospital_affiliations,
            'group_affiliations_names': provider.group_affiliations ? provider.group_affiliations.map(grpAfl => {
                return {
                    ...grpAfl,
                    toString: () => `${grpAfl.groupname} - ( NPI:${grpAfl.groupnpi ? grpAfl.groupnpi : 'n/a'} / ProviderId:${grpAfl.grouppracticeid ? grpAfl.grouppracticeid : 'n/a'} )`
                };
                
            }) : provider.group_affiliations,
            'hospital_affiliations_npis': provider.hospital_affiliations ? provider.hospital_affiliations.map(hospAfl => {
                return {
                    ...hospAfl,
                    toString: () => `${hospAfl.hospitalname} - ( NPI:${hospAfl.hospitalnpi ? hospAfl.hospitalnpi : 'n/a'} / ProviderId:${hospAfl.hospitalproviderid ? hospAfl.hospitalproviderid : 'n/a'} )`
                };
            }) : provider.hospital_affiliations,
            'group_affiliations_npis': provider.group_affiliations ? provider.group_affiliations.map(grpAfl => {
                return {
                    ...grpAfl,
                    toString: () => `${grpAfl.groupname} - ( NPI:${grpAfl.groupnpi ? grpAfl.groupnpi : 'n/a'} / ProviderId:${grpAfl.grouppracticeid ? grpAfl.grouppracticeid : 'n/a'} )`
                };
                
            }) : provider.group_affiliations,
            'hospital_affiliations_providerids': provider.hospital_affiliations ? provider.hospital_affiliations.map(hospAfl => {
                return {
                    ...hospAfl,
                    toString: () => `${hospAfl.hospitalname} - ( NPI:${hospAfl.hospitalnpi ? hospAfl.hospitalnpi : 'n/a'} / ProviderId:${hospAfl.hospitalproviderid ? hospAfl.hospitalproviderid : 'n/a'} )`
                };
            }) : provider.hospital_affiliations,
            'group_affiliations_practiceids': provider.group_affiliations ? provider.group_affiliations.map(grpAfl => {
                return {
                    ...grpAfl,
                    toString: () => `${grpAfl.groupname} - ( NPI:${grpAfl.groupnpi ? grpAfl.groupnpi : 'n/a'} / ProviderId:${grpAfl.grouppracticeid ? grpAfl.grouppracticeid : 'n/a'} )`
                };
                
            }) : provider.group_affiliations
        };
    }

    function updateProviderSearchStorage(obj, val) {
        localStorage.setObj('search.providerSearch', {
            limit: obj === "limit" ? val : limit,
            entitytypecode: obj === "entityType" ? val : entityType,
            name: obj === "providerName" ? val : providerName,
            city: obj === "city" ? val : city,
            county: obj === "county" ? val : county,
            state: obj === "selectedStateOption" ? val : (selectedStateOption ? selectedStateOption.value : ""),
            postalcode: obj === "postalCode" ? val : postalCode,
            taxonomy: obj === "selectedSpecialtyOption" ? val : (selectedSpecialtyOption ? selectedSpecialtyOption.value : ""),
            teamingonly: obj === "teamingOnly" ? val : teamingOnly,
            affiliation: obj === "affiliationVal" ? val : affiliationVal,
            affiliationtype: obj === "affiliationtype" ? val : affiliationType
        });
    };

    function npiSearchFunc(val) {

        var npis = val.split(/[^0-9]/).filter(function(npi) {
            return npi.length == 10;
        });

        if (npis.length > 0) {

            setLoading(true);
            
            var chunkedArrays = chunkArray(npis, 200);

            clearTimeout(npiSearchDelay.current);
            npiSearchDelay.current = setTimeout(() => {

                if (previousController.current) {
                    previousController.current.abort();
                }

                var chunkedArrays = chunkArray(npis, 200);
    
                const controller = new AbortController();
                const signal = controller.signal;
                previousController.current = controller;

                var requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': props.api.options().headers.Authorization, 'X-TEAM-TTM': props.api.options().headers["X-TEAM-TTM"]},
                    signal
                };
                    
                setLoading(true);

                Promise
                    .allSettled(chunkedArrays.map(chunk => {
                        return fetch('/api/npi/' + chunk + '/', requestOptions).then(res => res.json())
                            .catch((err) => {
                                if (!err.toString().includes('abort')) {
                                    toast(`There was an error fetching all of the given NPIs`, { type: toast.TYPE.ERROR, autoClose: 4000 });
                                    setLoading(false);
                                }
                            })
                    }))                    
                    .then(res => {
                        var resultSet = [];
                        merge(resultSet, ...res.map(resChunk => resChunk = resChunk.value));
                        
                        let dataNpi = Object.keys(resultSet.npis).map(function(npi) {
                            return resultSet.npis[npi];
                        });
                        
                        if (dataNpi.length < npis.length) {
                            toast(`${dataNpi.length} of ${npis.length} requested NPIs found.`, { type: toast.TYPE.ERROR, autoClose: 4000 });
                        }
                        setDataState("data.npi", dataNpi);
            
                        localStorage.set('search.npiSearch', val);
        
                        if (dataNpi.length > 0) {
                            var npiList = dataNpi.map(function(provider) {
                                var datum = createDatum(provider);
                                datum._checked = true;
                                return datum;
                            });
                            
                            setDataState("data.results", npiList);
                            setSearchData(npiList);
                            
                        }
                        setClickSearchReady(false);
                        setLoading(false);
                    })
                    .catch((err) => {
                        if (!err.toString().includes('abort')) {
                            toast(`There was an error fetching all of the given NPIs`, { type: toast.TYPE.ERROR, autoClose: 4000 });
                        }
                        setClickSearchReady(false);
                        setLoading(false);
                    });
                
            }, 2000);

        } else if (npis.length === 0) {
            setDataState("data.npi", []);
            localStorage.remove('search.npiSearch');
        }
    }

    function providerSearch(providerSearchVal) {
        setLoading(true);

        var searchObj = {};

        if (((providerSearchVal["entitytypecode"] == 1 || entityType == 1) && (providerSearchVal["affiliation"]) || (providerSearchVal["affiliationtype"]) && providerSearchVal["affiliationtype"] != "undefined") || (affiliationVal && entityType === 1 && affiliationType)) {
            if (providerSearchVal["affiliationtype"] || providerSearchVal["affiliation"]) {
                searchObj[providerSearchVal["affiliationtype"] ? providerSearchVal["affiliationtype"] : affiliationType] = providerSearchVal["affiliation"] ? providerSearchVal["affiliation"] : affiliationVal;
            }
            else {
                if (providerSearchVal["affiliation"] != "") {
                    searchObj[affiliationType] = affiliationVal;
                }
            }
        }
        if (providerSearchVal["entitytypecode"] || entityType) {
            searchObj["entitytypecode"] = (providerSearchVal["entitytypecode"] ? providerSearchVal["entitytypecode"] : entityType);
        }
        if (providerSearchVal["name"] !== '' && providerName || providerSearchVal["name"]) {
            searchObj["name"] = (providerSearchVal["name"] ? providerSearchVal["name"] : providerName);
        }
        if (providerSearchVal["city"] !== '' && city || providerSearchVal["city"] ) {
            searchObj["city"] = (providerSearchVal["city"]  ? providerSearchVal["city"] : city);
        }
        if (providerSearchVal["county"] !== '' && county || providerSearchVal["county"]) {
            let searchCounty = (providerSearchVal["county"] ? providerSearchVal["county"] : county);

            // Remove all leading and trailing commas and whitespace
            searchCounty = searchCounty.replace(/(^(\s|,)+)|((\s|,)+$)/gm, '');

            // Replace all commas and whitespace without any content between them with a single comma
            searchCounty = searchCounty.replace(/,(\s|,)*/gm, ',');

            // Remove any extra spaces
            searchCounty = searchCounty.replace(/\s+/gm, ' ');
            searchObj["county"] = searchCounty
        }
        if (selectedStateOption || providerSearchVal["state"]) {
            searchObj["state"] = (providerSearchVal["state"] ? (typeof providerSearchVal["state"] === "object" ? providerSearchVal["state"].value : providerSearchVal["state"]) : selectedStateOption.value);
        }
        if (providerSearchVal["postalcode"] !== '' && postalCode || providerSearchVal["postalcode"]) {
            searchObj["postalcode"] = (providerSearchVal["postalcode"] ? providerSearchVal["postalcode"] : postalCode);
        }
        if (selectedSpecialtyOption || providerSearchVal["taxonomy"]) {
            searchObj["taxonomy"] = (providerSearchVal["taxonomy"] ? (typeof providerSearchVal["taxonomy"][0] === "object" ? providerSearchVal["taxonomy"].map(function (taxonomy) {
                return taxonomy.value;
            }) : providerSearchVal["taxonomy"] )
            : selectedSpecialtyOption.map(function (taxonomy) {
                return taxonomy.value;
            }) );
        }
        if (teamingOnly || providerSearchVal["teamingonly"]) {
            searchObj["teamingonly"] = (providerSearchVal["teamingonly"] ? providerSearchVal["teamingonly"] : teamingOnly);
        }
        if (teamingOnly || providerSearchVal["limit"]) {
            searchObj["limit"] = (providerSearchVal["limit"] ? providerSearchVal["limit"] : limit);
        }

        if (searchObj['taxonomy'] && searchObj['taxonomy'].length > 0) {
            searchObj['taxonomy'] = searchObj['taxonomy'].join(',');
        } else {
            delete searchObj['taxonomy']
        }

        if (searchObj['entitytypecode'] === 3) {
            delete searchObj['entitytypecode'];
        }

        if (searchObj['teamingonly'] === 2) {
            delete searchObj['teamingonly'];
        }

        if (searchObj['state'] === "") {
            delete searchObj['state'];
        }

        for (const key in translateAffiliationType) {
            if (searchObj[key] === null || searchObj[key] === "undefined") {
                delete searchObj[key];
            }
        }

        if (searchObj['state'] && typeof searchObj['state'] === 'object' && "label" in searchObj['state'] && searchObj['state'].value == "") {
            delete searchObj['state'];
        }

        var searchableFields = Object.keys(searchObj).filter(function(key) { 
            return ['entitytypecode', 'limit', 'teamingonly', 'affiliationtype'].indexOf(key) == -1; 
        });

        if (searchableFields.length && searchableFields.filter(field => searchObj[field]).length > 0) {
            if (!npiSearch || providerSearchVal["npiSearch"] && providerSearchVal["npiSearch"] === "undefined") {

                clearTimeout(providerSearchDelay.current);
                providerSearchDelay.current = setTimeout(() => {


                    if (previousController.current) {
                        previousController.current.abort();
                    }

                    const controller = new AbortController();
                    const signal = controller.signal;
                    previousController.current = controller;
                    
                    var requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', 'Authorization': props.api.options().headers.Authorization, 'X-TEAM-TTM': props.api.options().headers["X-TEAM-TTM"]},
                        signal
                    };
                    fetch('/api/npi/search?'  + new URLSearchParams(
                        searchObj
                    ), requestOptions)
                    .then(res  => res.json())
                    .then(res => {

                        if (res.npis.length === 0) {
                            toast(`No providers found given current search parameters.`, { type: toast.TYPE.ERROR, autoClose: 4000 });
                        }

                        let dataSearch = Object.keys(res.npis).map(function(npi) {
                            return res.npis[npi];
                        });

                        var npiList = dataSearch.map(function(provider) {
                            var datum = createDatum(provider);
                            datum._checked = false;
                            return datum;
                        });
                        
                        var searchList = dataSearch.filter(function(provider) {
                            return dataSearch.indexOf(provider) == -1;
                        }).map(function(provider) {
                            var datum = createDatum(provider);
                            datum._checked = false;
                            return datum;
                        });
                        
                        setDataState("data.results", npiList.concat(searchList));

                        setDataState("data.search", searchList);

                        setSearchData(npiList.concat(searchList));

                        setClickSearchReady(false);
                        setLoading(false);
                    })
                    .catch((err) => {
                        if (!err.toString().includes('abort')) {
                            toast(`There was a problem fetching the given search parameters`, { type: toast.TYPE.ERROR, autoClose: 4000 });
                        }
                        setClickSearchReady(false);
                        setLoading(false);
                    });
                }, 2000);

            } else {
                setClickSearchReady(false);
                setLoading(false);
            }
        } else {
            setLoading(false);
            setDataState("data.search",[]);
        }

    };

    function setEntityTypeVal(value) {
        setEntityType(value);
        updateProviderSearchStorage("entityType", value);
        if (searchMode === 'reactive') {
            providerSearch({entitytypecode: value});
        } else {
            setClickSearchReady(true);
        }
        
    };

    function setAffiliationTypeVal(value, e) {
        setAffiliationType(value);
        
        setAffiliationTypeTranslation(translateAffiliationType[value].label);
        
        toggleDropdownClass(e);
        updateProviderSearchStorage("affiliationtype", value);
        if (affiliationVal && searchMode === 'reactive') {
            providerSearch({affiliationtype: value});
        } else {
            setClickSearchReady(true);
        }
    };

    function setIsCollapsedVal(value) {
        setIsCollapsed(value);
    };

    const handleSpecialtyChange = (selectedOption) => {
        setSelectedSpecialtyOption(selectedOption);
        updateProviderSearchStorage("selectedSpecialtyOption", selectedOption);
        let taxonomyArray = selectedOption.map(function (taxonomy) {
            return taxonomy.value;
        })
        if (searchMode === 'reactive') {
            providerSearch({taxonomy: taxonomyArray});
        } else {
            setClickSearchReady(true);
        }
    };

    const handleStateChange = (selectedOption) => {
        setSelectedStateOption(selectedOption);
        updateProviderSearchStorage("selectedStateOption", selectedOption);
        if (searchMode === 'reactive') {
            providerSearch({state: selectedOption.value});
        } else {
            setClickSearchReady(true);
        }
    };

    const handleProviderNameChange = (value) => {
        let val = value.target.value;
        setProviderName(val);
        updateProviderSearchStorage("providerName", val);
        if (searchMode === 'reactive') {
            providerSearch({name: val});
        } else {
            setClickSearchReady(true);
        }
    }

    const handleCityChange = (value) => {
        let val = value.target.value;
        setCity(val);
        updateProviderSearchStorage("city", val);
        if (searchMode === 'reactive') {
            providerSearch({city: val});
        } else {
            setClickSearchReady(true);
        }
    }

    const handleCountyChange = (value) => {
        let val = value.target.value;
        setCounty(val);
        updateProviderSearchStorage("county", val);
        if (searchMode === 'reactive') {
            providerSearch({county: val});
        } else {
            setClickSearchReady(true);
        }
    }

    const handlePostalCodeChange = (value) => {
        let val = value.target.value;
        setPostalCode(val);
        updateProviderSearchStorage("postalCode", val);
        if (searchMode === 'reactive') {
            providerSearch({postalcode: val});
        } else {
            setClickSearchReady(true);
        }
    }

    const handleAffiliationValChange = (value) => {
        let val = value.target.value;
        setAffiliationVal(val);
        updateProviderSearchStorage("affiliationVal", val);
        if (searchMode === 'reactive') {
            providerSearch({affiliation: val});
        } else {
            setClickSearchReady(true);
        }
    }

    const handleNpiSearchChange = (value) => {
        let val = value.target.value;
        setNpiSearch(val);
        if (searchMode === 'reactive') {
            if (val != "") {
                npiSearchFunc(val);
            } else {
                localStorage.remove('search.npiSearch');
                providerSearch({npiSearch: "undefined"});
            }
        } else {
            setClickSearchReady(true);
        }
    };

    function groupSort(groups) {
        const groupsCopy = [...groups];

        // sort by top level groups
        groupsCopy.sort(function(a, b) {
            return a.label > b.label ? 1 : -1;
        });

        // sort by sub level groups
        groupsCopy.forEach(group => {
            group.options.sort((a, b) => {
                return a.label > b.label ? 1 : -1;
            });
        });
        return groupsCopy;
    };

    const toggleDropdownClass = (e) => {
        e.target.closest('.dropdown').classList.toggle("open");
    }

    const setTeamingVal = (e, val) => {
        setLoading(true);
        setTeamingOnly(val);
        toggleDropdownClass(e);
        updateProviderSearchStorage("teamingOnly", val);
        providerSearch({teamingonly: val});
        setLoading(false);
    }

    function setValuesFromStorage(specialties) {
        
        if (localStorage.get('search.npiSearch')) {
            setNpiSearch(localStorage.get('search.npiSearch'));
            npiSearchFunc(localStorage.get('search.npiSearch'));
        };

        if (localStorage.getObj('search.providerSearch')) {
                let providerSearchVals = localStorage.getObj('search.providerSearch');

                for (var key in providerSearchVals) {
                    let val = providerSearchVals[key];
                    if (key === "limit") {
                        setLimit(val)
                    }
                    if (key === "entitytypecode") {
                        setEntityType(val);
                    }
                    if (key === "name") {
                        setProviderName(val);
                    }
                    if (key === "city") {
                        setCity(val);
                    }
                    if (key === "county") {
                        setCounty(val);
                    }
                    if (key === "state") {
                        if (typeof val === 'object' && val !== null) {
                            setSelectedStateOption(val);
                        }
                        else {
                            let stateObj = states.find(state => state.value === val);
                            stateObj = {label : stateObj.name, value : stateObj.value};
                            setSelectedStateOption(stateObj);
                        }
                    }
                    if (key === "postalcode") {
                        setPostalCode(val);
                    }
                    if (key === "taxonomy") {
                        let specialtyArr = [];
                            for (let i = 0; i < val.length; i++) {
                                if (!("label" in val[i])) {
                                    if (specialties.length > 0) {
                                        let specialty = specialties.find(tax => tax.code[0] === val[i][0]);
                                        specialtyArr.push({label: (specialty.classification ? specialty.classification: "") + (specialty.specialization ? ` (${specialty.specialization})` : ""), value: specialty.code[0]});
                                    }
                                }
                            }
                        setSelectedSpecialtyOption(specialtyArr.length > 0 ? specialtyArr : val);
                    }
                    if (key === "teamingonly") {
                        setTeamingOnly(val);
                    }
                    if (key === "affiliation") {
                        setAffiliationVal(val);
                    }
                    if (key === "affiliationtype") {
                        setAffiliationType(val);
                        if (val != "undefined") {
                            setAffiliationTypeTranslation(translateAffiliationType[val].label);
                        }
                    }
                }
            if (!localStorage.get('search.npiSearch')) {
                providerSearch(providerSearchVals);
            };
        } else {
            setEntityType(3);
            setAffiliationType("undefined");
            setLimit(500);
            setTeamingOnly(1);
            setSearchData(null);
        }
    }

    function handleProviderSearchButtonClick() {
        if (npiSearch) {
            npiSearchFunc(npiSearch);
        } else {
            providerSearch({});
        }
    }

    const reportOrgConfig = function() { 
        return {  
          tableConfig :  [
                {
                    "header": {
                        "id": "col0",
                        "accessor": "select_all",
                        "content": "select_all",
                        "defaultVisibilty": true,
                        "type": "checkbox",
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 0
                        }
                },
                {
                    "header": {
                        "id": "col1",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowObject) { 
                            if (!rowObject || !rowObject.location) return
                            const npi_popover = {
                                npi : content,
                                name : rowObject.name,
                                api : props.api
                        }
                            return <PopoverRelations {...npi_popover}><Link to={`/${rowObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverRelations> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 1
                        }
                 },
                 {
                    "header": {
                        "id": "col2",
                        "accessor": "name",
                        "content": "Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.npi, entitytype : rowObject.entitytype }
                            return <><span>{content}</span> 
                                     <PopoverMenu provider={provider}>
{                                     <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                      </span>}
                                    </PopoverMenu>
                                   </>
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                 },
                 {
                    "header": {
                        "id": "col3",
                        "accessor": "phone",
                        "content": "Phone",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { 
                            const formatPhoneNum = content && content.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            return phone;
                         }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                 },
                 {
                    "header": {
                        "id": "col4",
                        "accessor": "address",
                        "content": "Address",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 4
                        }
                 },
                 {
                    "header": {
                        "id": "col5",
                        "accessor": "city",
                        "content": "City",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                 },
                 {
                    "header": {
                        "id": "col6",
                        "accessor": "county",
                        "content": "County",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                 },
                 {
                    "header": {
                        "id": "col7",
                        "accessor": "state",
                        "content": "State",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                 },
                 {
                    "header": {
                        "id": "col8",
                        "accessor": "postal",
                        "content": "Zip",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                 },
                 {
                    "header": {
                        "id": "col9",
                        "accessor": "specialty",
                        "content": "Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                 },
                 {
                    "header": {
                        "id": "col10",
                        "accessor": "hospital_affiliations_npis",
                        "content": "Hospital Affiliation NPIs",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            let val = '';
    
                            if (content && content.length > 0) {
                                let affiliationArray = [];
                                content.forEach(function (affiliation) {
                                    if (affiliation.hospitalnpi != "") {
                                        let npi_popover = {
                                            npi : affiliation.hospitalnpi,
                                            name : affiliation.hospitalname,
                                            api : props.api
                                        }
    
                                        let popover = <PopoverRelations {...npi_popover}><Link to={`/organization/${affiliation.hospitalnpi}/home`}>{(affiliationArray.length > 0 ? ",\r\n" : "") + affiliation.hospitalnpi}</Link></PopoverRelations>
                                    
                                        
                                        affiliationArray.push(popover);
                                    }
                                });
    
                                val = affiliationArray
                            }
                            
                            return val
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 10
                        }
                 },
                 {
                    "header": {
                        "id": "col11",
                        "accessor": "hospital_affiliations_providerids",
                        "content": "Hospital Affiliation Provider IDs",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content, rowDataObject) { 
                            let val = '';
        
                            if (content && content.length > 0) {
                                let affiliationArray = [];
                                content.forEach(function (affiliation) {
                                    affiliationArray.push(affiliation.hospitalproviderid);
                                });
                                    
                            val = affiliationArray.join(",\r\n");
                            }
                                
                            return val
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                 },
                 {
                    "header": {
                        "id": "col12",
                        "accessor": "hospital_affiliations_names",
                        "content": "Hospital Affiliation Names",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            let val = '';
    
                            if (content && content.length > 0) {
                                let affiliationArray = [];
                                content.forEach(function (affiliation) {
                                    affiliationArray.push(affiliation.hospitalname);
                                });
                                
                                val = affiliationArray.join();
                            }
                            
                            return val
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                 },
                 {
                    "header": {
                        "id": "col13",
                        "accessor": "group_affiliations_npis",
                        "content": "Group Affiliation NPIs",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            let val = '';
    
                            if (content && content.length > 0) {
                                let affiliationArray = [];
                                content.forEach(function (affiliation) {
                                    if (affiliation.groupnpi != "") {
                                        let npi_popover = {
                                            npi : affiliation.groupnpi,
                                            name : affiliation.groupname,
                                            api : props.api
                                        }
        
                                        let popover = <PopoverRelations {...npi_popover}><Link to={`/organization/${affiliation.groupnpi}/home`}>{(affiliationArray.length > 0 ? ",\r\n" : "") + affiliation.groupnpi}</Link></PopoverRelations>
                                    
                                        
                                        affiliationArray.push(popover);
                                    }
                                });
    
                                val = affiliationArray
                            }
                            
                            return val
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                 },
                 {
                    "header": {
                        "id": "col14",
                        "accessor": "group_affiliations_practiceids",
                        "content": "Group Affiliation Practice IDs",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            let val = '';
    
                            if (content && content.length > 0) {
                                let affiliationArray = [];
                                content.forEach(function (affiliation) {
                                    if (affiliation.grouppracticeid != "") {
                                        affiliationArray.push(affiliation.grouppracticeid);
                                    }
                                });
                                
                            val = affiliationArray.join(",\r\n");
                            }
                            
                            return val
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 14
                        }
                 },
                 {
                    "header": {
                        "id": "col15",
                        "accessor": "group_affiliations_names",
                        "content": "Group Affiliation Names",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { 
                            let val = '';
    
                            if (content && content.length > 0) {
                                let affiliationArray = [];
                                content.forEach(function (affiliation) {
                                    affiliationArray.push(affiliation.groupname);
                                });
                                
                                val = affiliationArray.join();
                            }
                            
                            return val
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 15
                        }
                 }
        ],
        configure : true,
        configureButton: true,
        showSelected: true,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        localStorageName: 'reports.search',
        reportTitle: 'Search_Results'
        }
    }

    const reportPhyConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col0",
                    "accessor": "select_all",
                    "content": "select_all",
                    "defaultVisibilty": true,
                    "type": "checkbox",
                    "export": false,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 0
                    }
            },
            {
                "header": {
                    "id": "col1",
                    "accessor": "hospital_affiliations_names",
                    "content": "Hospital Affiliation Names",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                affiliationArray.push(affiliation.hospitalname);
                            });
                            
                            val = affiliationArray.join();
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
             },
            {
                "header": {
                    "id": "col2",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content, rowObject) { 
                        if (!rowObject || !rowObject.location) return
                        const npi_popover = {
                            npi : content,
                            name : rowObject.name,
                            api : props.api
                    }
                        return <PopoverRelations {...npi_popover}><Link to={`/${rowObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverRelations> 
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 2
                    }
             },
             {
                "header": {
                    "id": "col3",
                    "accessor": "name",
                    "content": "Name",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content, rowObject) { 
                        const provider = {  name : content, npi : rowObject.npi, entitytype : rowObject.entitytype }
                        return <><span>{content}</span> 
                                 <PopoverMenu provider={provider}>
{                                     <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                  </span>}
                                </PopoverMenu>
                               </>
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 3
                    }
             },
             {
                "header": {
                    "id": "col4",
                    "accessor": "phone",
                    "content": "Phone",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { 
                        const formatPhoneNum = content && content.toString() || '';
                        const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                        return phone;
                     }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 4
                    }
             },
             {
                "header": {
                    "id": "col5",
                    "accessor": "address",
                    "content": "Address",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 5
                    }
             },
             {
                "header": {
                    "id": "col6",
                    "accessor": "city",
                    "content": "City",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 6
                    }
             },
             {
                "header": {
                    "id": "col7",
                    "accessor": "county",
                    "content": "County",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 7
                    }
             },
             {
                "header": {
                    "id": "col8",
                    "accessor": "state",
                    "content": "State",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 8
                    }
             },
             {
                "header": {
                    "id": "col9",
                    "accessor": "postal",
                    "content": "Zip",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { 
                        return content 
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 9
                    }
             },
             {
                "header": {
                    "id": "col10",
                    "accessor": "specialty",
                    "content": "Specialty",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 10
                    }
             },
             {
                "header": {
                    "id": "col11",
                    "accessor": "hospital_affiliations_npis",
                    "content": "Hospital Affiliation NPIs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                if (affiliation.hospitalnpi != "") {
                                    let npi_popover = {
                                        npi : affiliation.hospitalnpi,
                                        name : affiliation.hospitalname,
                                        api : props.api
                                    }

                                    let popover = <PopoverRelations {...npi_popover}><Link to={`/organization/${affiliation.hospitalnpi}/home`}>{(affiliationArray.length > 0 ? ",\r\n" : "") + affiliation.hospitalnpi}</Link></PopoverRelations>
                                
                                    
                                    affiliationArray.push(popover);
                                }
                            });

                            val = affiliationArray
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 11
                    }
             },
             {
                "header": {
                    "id": "col12",
                    "accessor": "hospital_affiliations_providerids",
                    "content": "Hospital Affiliation Provider IDs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content, rowDataObject) { 
                        let val = '';
    
                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                affiliationArray.push(affiliation.hospitalproviderid);
                            });
                                
                            val = affiliationArray.join(",\r\n");
                        }
                            
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 12
                    }
             },
             {
                "header": {
                    "id": "col13",
                    "accessor": "group_affiliations_npis",
                    "content": "Group Affiliation NPIs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                if (affiliation.groupnpi != "") {
                                    let npi_popover = {
                                        npi : affiliation.groupnpi,
                                        name : affiliation.groupname,
                                        api : props.api
                                    }
    
                                    let popover = <PopoverRelations {...npi_popover}><Link to={`/organization/${affiliation.groupnpi}/home`}>{(affiliationArray.length > 0 ? ",\r\n" : "") + affiliation.groupnpi}</Link></PopoverRelations>
                                
                                    
                                    affiliationArray.push(popover);
                                }
                            });

                            val = affiliationArray
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 13
                    }
             },
             {
                "header": {
                    "id": "col14",
                    "accessor": "group_affiliations_practiceids",
                    "content": "Group Affiliation Practice IDs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                if (affiliation.grouppracticeid != "") {
                                    affiliationArray.push(affiliation.grouppracticeid);
                                }
                            });
                            
                            val = affiliationArray.join(",\r\n");
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 14
                    }
             },
             {
                "header": {
                    "id": "col15",
                    "accessor": "group_affiliations_names",
                    "content": "Group Affiliation Names",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                affiliationArray.push(affiliation.groupname);
                            });
                            
                            val = affiliationArray.join();
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 15
                    }
             }
        ],
        configure : true,
        configureButton: true,
        showSelected: true,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        localStorageName: 'reports.search',
        reportTitle: 'Search_Results'
        }
    }

    const reportBothConfig = function() { 
        return {  
          tableConfig :  [
            {
                "header": {
                    "id": "col0",
                    "accessor": "select_all",
                    "content": "select_all",
                    "defaultVisibilty": true,
                    "type": "checkbox",
                    "export": false,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 0
                    }
            },
            {
                "header": {
                    "id": "col1",
                    "accessor": "npi",
                    "content": "NPI",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content, rowObject) { 
                        if (!rowObject || !rowObject.location) return
                        const npi_popover = {
                            npi : content,
                            name : rowObject.name,
                            api : props.api
                    }
                        return <PopoverRelations {...npi_popover}><Link to={`/${rowObject.entitytype == 1 ? 'physician' : 'organization'}/${content}/home`}>{content}</Link></PopoverRelations> 
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 1
                    }
             },
             {
                "header": {
                    "id": "col2",
                    "accessor": "name",
                    "content": "Name",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content, rowObject) { 
                        const provider = {  name : content, npi : rowObject.npi, entitytype : rowObject.entitytype }
                        return <><span>{content}</span> 
                                 <PopoverMenu provider={provider}>
{                                     <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                  </span>}
                                </PopoverMenu>
                               </>
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 2
                    }
             },
             {
                "header": {
                    "id": "col3",
                    "accessor": "phone",
                    "content": "Phone",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { 
                        const formatPhoneNum = content && content.toString() || '';
                        const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                        return phone;
                     }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 3
                    }
             },
             {
                "header": {
                    "id": "col4",
                    "accessor": "address",
                    "content": "Address",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 4
                    }
             },
             {
                "header": {
                    "id": "col5",
                    "accessor": "city",
                    "content": "City",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 5
                    }
             },
             {
                "header": {
                    "id": "col6",
                    "accessor": "county",
                    "content": "County",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 6
                    }
             },
             {
                "header": {
                    "id": "col7",
                    "accessor": "state",
                    "content": "State",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 7
                    }
             },
             {
                "header": {
                    "id": "col8",
                    "accessor": "postal",
                    "content": "Zip",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 8
                    }
             },
             {
                "header": {
                    "id": "col9",
                    "accessor": "specialty",
                    "content": "Specialty",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return content }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 9
                    }
             },
             {
                "header": {
                    "id": "col10",
                    "accessor": "hospital_affiliations_npis",
                    "content": "Hospital Affiliation NPIs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                if (affiliation.hospitalnpi != "") {
                                    let npi_popover = {
                                        npi : affiliation.hospitalnpi,
                                        name : affiliation.hospitalname,
                                        api : props.api
                                    }

                                    let popover = <PopoverRelations {...npi_popover}><Link to={`/organization/${affiliation.hospitalnpi}/home`}>{(affiliationArray.length > 0 ? ",\r\n" : "") + affiliation.hospitalnpi}</Link></PopoverRelations>
                                
                                    
                                    affiliationArray.push(popover);
                                }
                            });

                            val = affiliationArray
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 10
                    }
             },
             {
                "header": {
                    "id": "col11",
                    "accessor": "hospital_affiliations_providerids",
                    "content": "Hospital Affiliation Provider IDs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content, rowDataObject) { 
                        let val = '';
    
                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                affiliationArray.push(affiliation.hospitalproviderid);
                            });
                                
                            val = affiliationArray.join(",\r\n");
                        }
                            
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 11
                    }
             },
             {
                "header": {
                    "id": "col12",
                    "accessor": "hospital_affiliations_names",
                    "content": "Hospital Affiliation Names",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                affiliationArray.push(affiliation.hospitalname);
                            });
                            
                            val = affiliationArray.join();
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 12
                    }
             },
             {
                "header": {
                    "id": "col13",
                    "accessor": "group_affiliations_npis",
                    "content": "Group Affiliation NPIs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                if (affiliation.groupnpi != "") {
                                    let npi_popover = {
                                        npi : affiliation.groupnpi,
                                        name : affiliation.groupname,
                                        api : props.api
                                    }
    
                                    let popover = <PopoverRelations {...npi_popover}><Link to={`/organization/${affiliation.groupnpi}/home`}>{(affiliationArray.length > 0 ? ",\r\n" : "") + affiliation.groupnpi}</Link></PopoverRelations>
                                
                                    
                                    affiliationArray.push(popover);
                                }
                            });

                            val = affiliationArray
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 13
                    }
             },
             {
                "header": {
                    "id": "col14",
                    "accessor": "group_affiliations_practiceids",
                    "content": "Group Affiliation Practice IDs",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                if (affiliation.grouppracticeid != "") {
                                    affiliationArray.push(affiliation.grouppracticeid);
                                }
                            });
                            
                            val = affiliationArray.join(",\r\n");
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 14
                    }
             },
             {
                "header": {
                    "id": "col15",
                    "accessor": "group_affiliations_names",
                    "content": "Group Affiliation Names",
                    "defaultVisibilty": false,
                    "export": false,
                    "template": function(content) { 
                        let val = '';

                        if (content && content.length > 0) {
                            let affiliationArray = [];
                            content.forEach(function (affiliation) {
                                affiliationArray.push(affiliation.groupname);
                            });
                            
                            val = affiliationArray.join();
                        }
                        
                        return val
                    }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 15
                    }
             }
        ],
        configure : true,
        configureButton: true,
        showSelected: true,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        localStorageName: 'reports.search',
        reportTitle: 'Search_Results'
        }
    }

    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    return (
        <>
        <div className="search-container">
            <div className="row tourscript-input">
                <div id="search" className={isCollapsed ? "search collapse" : "search in collapse"}>
                    <form name="searchForm" className="form search-form">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="type" className="capitalize">Search Type</label>
                                    <div className="toggle_radio tourscript-input-type">
                                        <input type="radio" className="toggle_option" id="first_toggle" name="first_toggle" value="2" checked={entityType === 2} onChange={(e) => setEntityTypeVal(2)}/>
                                        <input type="radio" className="toggle_option" id="second_toggle" name="second_toggle" value="3" checked={entityType === 3} onChange={(e) => setEntityTypeVal(3)}/>
                                        <input type="radio" className="toggle_option" id="third_toggle" name="third_toggle" value="1" checked={entityType === 1} onChange={(e) => setEntityTypeVal(1)}/>
                                        <label htmlFor="first_toggle"><p>Organization</p></label>
                                        <label htmlFor="second_toggle"><p>Both</p></label>
                                        <label htmlFor="third_toggle"><p>Physician</p></label>
                                        <div className="toggle_option_slider"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="providerName" className="capitalize">Provider Name</label>
                                    <input type="text" className="form-control" placeholder={entityType === 2 ? 'e.g. Regional Hospital' : 'e.g. John Doe'} name="providerName" onChange={(e) => handleProviderNameChange(e)} value={providerName ? providerName : ""}></input>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input type="text" className="form-control" placeholder="e.g. Nashville" name="city" onChange={(e) => handleCityChange(e)} value={city ? city : ""}></input>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="state">State</label>
                                    <Select styles={selectStyles}
                                        value={selectedStateOption}
                                        onChange={handleStateChange}
                                        options={states.map(state => {
                                            return ({label: state.name, value: state.value});
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="npi">NPI List</label>
                                    <textarea className="form-control tourscript-input-npi" rows="1" placeholder="e.g. 0123456789, 9876543210" name="npi" onChange={(e) => handleNpiSearchChange(e)} value={npiSearch ? npiSearch : ""}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="taxonomy">Specialties List</label>
                                    <Select styles={selectStyles}
                                        value={selectedSpecialtyOption}
                                        onChange={handleSpecialtyChange}
                                        options={groupedSpecialties}
                                        placeholder="Select or search for a specialty in the list..."
                                        isMulti
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="county">County</label>
                                    <textarea type="text" className="form-control" placeholder="e.g. Davidson County" name="county" onChange={(e) => handleCountyChange(e)} value={county ? county : ""}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="postalcode">ZIP Code List</label>
                                    <textarea className="form-control" rows="1" placeholder="e.g. 12345, 54321" name="postalcode" onChange={(e) => handlePostalCodeChange(e)} value={postalCode ? postalCode : ""}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                {entityType === 1 ? 
                                    <div className="form-group">
                                        <label htmlFor="">Affiliation</label>
                                        <div className="input-group">
                                            <div className="input-group-btn btn-group dropdown">
                                                <button id="single-button" type="button" onClick={(e) => toggleDropdownClass(e)} className="btn btn-default">
                                                    {affiliationTypeTranslation ? affiliationTypeTranslation : "Type"} <span className="caret"></span>
                                                </button>
                                                <ul className="dropdown-menu" role="menu" aria-labelledby="single-button">
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio" value="undefined"  checked={affiliationType === "undefined"} onChange={(e) => setAffiliationTypeVal("undefined", e)}/>
                                                            None (Select one to use)
                                                        </label>
                                                    </li>
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio" value="hospitalnpi"  checked={affiliationType === "hospitalnpi"} onChange={(e) => setAffiliationTypeVal("hospitalnpi", e)}/>
                                                            Hospital NPI
                                                        </label>
                                                    </li>
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio" value="hospitalproviderid"  checked={affiliationType === "hospitalproviderid"} onChange={(e) => setAffiliationTypeVal("hospitalproviderid", e)}/>
                                                            Hospital Provider Id
                                                        </label>
                                                    </li>
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio" value="hospitalname"  checked={affiliationType === "hospitalname"} onChange={(e) => setAffiliationTypeVal("hospitalname", e)}/>
                                                            Hospital Name
                                                        </label>
                                                    </li>
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio" value="groupnpi"  checked={affiliationType === "groupnpi"} onChange={(e) => setAffiliationTypeVal("groupnpi", e)}/>
                                                            Group NPI
                                                        </label>
                                                    </li>
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio" value="grouppracticeid"  checked={affiliationType === "grouppracticeid"} onChange={(e) => setAffiliationTypeVal("grouppracticeid", e)}/>
                                                            Group Practice Id
                                                        </label>
                                                    </li>
                                                    <li role="menuitem" className="radio" style={{"marginLeft" : "10px", "marginRight" : "10px"}}>
                                                        <label>
                                                            <input type="radio"value="groupname"  checked={affiliationType === "groupname"} onChange={(e) => setAffiliationTypeVal("groupname")}/>
                                                            Group Name
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Hospital/Group NPI, Provider/Practice Id or Name" onChange={(e) => handleAffiliationValChange(e)} value={affiliationVal ? affiliationVal : ""}/>
                                        </div>
                                    </div> 
                                : ""}
                            </div>
                        </div>
                    </form>
                </div>                
                {!isCollapsed ?
                        <div className="collapseEl text-center">
                            <i className="fa fa-caret-up fa-lg" aria-hidden="true"  onClick={(e) => setIsCollapsedVal(true)}></i><br/>
                            <a  onClick={(e) => setIsCollapsedVal(true)}>Hide</a>
                        </div>
                    : ""}
                {isCollapsed ?
                    <div className="collapseEl text-center">
                        <a  onClick={(e) => setIsCollapsedVal(false)}>Show</a><br/>
                        <i className="fa fa-caret-down fa-lg" aria-hidden="true"  onClick={(e) => setIsCollapsedVal(false)}></i>
                    </div>
                : ""}
                
            </div>
            <div>
                <PopoverDetails providerTooltip={"Reset all search form controls to default"}>
                    <button className="btn btn-default tourscript-button-startover" onClick={() => startOver()} disabled={false/* searchData && searchData.length > 0 ? undefined : true */}>
                        Start Over
                    </button>
                </PopoverDetails>
                <div className="btn-group dropdown">
                <PopoverDetails providerTooltip={"When set to 'Reporting NPIs', only providers with teaming data will be included in search results. When set to 'All NPIs', NPIs with no teaming data will also be included."}>
                    <button id="single-button" type="button"  onClick={(e) => toggleDropdownClass(e)} className="btn btn-default">
                        {teamingOnly === 1 ? 'Reporting' : 'All'} NPIs {teamingOnly === 2 ? <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> : ""} <span className="caret"></span>
                    </button>
                </PopoverDetails>
                  <ul className="dropdown-menu" role="menu" aria-labelledby="single-button">
                    <PopoverDetails providerTooltip={"Only providers with teaming data will be included in search results"}>
                        <li className="teaming-dropdown" role="menuitem" onClick={(e) => setTeamingVal(e,1)} style={{"padding":"5px 10px", "cursor":"pointer"}}>Reporting NPIs</li>
                    </PopoverDetails>
                    <PopoverDetails providerTooltip={"Search results will include all providers, regardless of teaming data"}>
                        <li className="teaming-dropdown" role="menuitem" onClick={(e) => setTeamingVal(e,2)} style={{"padding":"5px 10px", "cursor":"pointer"}}>All NPIs <i className="fa fa-exclamation-triangle" aria-hidden="true"></i></li>
                    </PopoverDetails>
                  </ul>
                </div>
                <div className="pull-right">
                    <button className="btn btn-default" style={clickSearchReady ? {backgroundColor:'#3c763d', color:'#fff'} : null} onClick={() => {handleProviderSearchButtonClick()}}>
                        { loading ? <i className="fa fa-fw fa-spinner fa-spin" aria-hidden="false"></i> : null }Search for Providers
                    </button>
                    <div className="btn-group dropdown">
                        <PopoverDetails width="200px" providerTooltip={"Set searches to either run on input field changes or on click of the 'Search for Providers' button"}>
                            <button id="searchtype-button" type="button" className="btn btn-default" onClick={(e) => toggleDropdownClass(e)}>
                                {searchMode === 'click'
                                    ? <i className="fa fa-hand-o-up" aria-hidden="true"></i>
                                    : <i className="fa fa-refresh" aria-hidden="true"></i>
                                }{' '}<span className="caret"></span>
                            </button>
                        </PopoverDetails>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="searchtype-button">
                            <li className="search-dropdown" role="menuitem" onClick={(e) => { if (searchMode === 'reactive') {setSearchMode('click');} toggleDropdownClass(e); }} style={{"padding":"5px 10px", "cursor":"pointer"}}><i className="fa fa-hand-o-up" aria-hidden="true"></i>{' '}Search on Click</li>
                            <li className="search-dropdown" role="menuitem" onClick={(e) => { if (searchMode === 'click') {setSearchMode('reactive');} toggleDropdownClass(e); }} style={{"padding":"5px 10px", "cursor":"pointer"}}><i className="fa fa-refresh" aria-hidden="true"></i>{' '}Search on input change</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                className="loading-lg"
                style={{
                    "display" : (loading ? 'inline' : 'none' ),
                    "backgroundColor": 'hsla(0,0%,95%,.7)',
                    "borderRadius": '.5rem'
                }}
            ></div>
            <PhtableComponent data={searchData} reportconfig={entityType === 1 ? reportPhyConfig : (entityType === 2 ? reportOrgConfig : (entityType === 3 ? reportBothConfig : reportBothConfig))} download={props.download} showCount={showCount} isSearch={true}/> 
        </div>
        </>
    )
}