import * as d3Base from 'd3';
import {event as d3SelectionEvent} from 'd3-selection';
import { legendColor } from 'd3-svg-legend';

const d3 = Object.assign({}, d3Base, { legendColor });

// workaround for webpack breaking d3.event
// https://github.com/d3/d3-zoom/issues/32#issuecomment-229889310
d3.getEvent = () => d3SelectionEvent;// call and assign to variable wherever d3.event is needed

export function trendingLineGraph(selection) {

    let options;
    let toolTip;
    let toolTipMonthYear;
    let toolTipCurrentAxis;
    let toolTipArrow;
    let visibleLines;
    
    let monthsRange;

    let defaults = {
        dimensions: {
            margin: {
                top: 10,
                right: 10,
                bottom: 80,
                left: 80
            },
            dotRadius: 5,
            lineWidth: 1.5,
            container: {},
            lineGraph: {}
        },
        colors: d3.schemeCategory20,
        scales: {
            x: {
                fn: d3.scaleLinear,
                accessor: 'x'
            },
            y: {
                fn: d3.scaleLinear,
                accessor: 'y'
            },
            color: {
                fn: d3.scaleOrdinal
            }
        },
        legend: {
            fn: d3.legendColor,
            shape: d3.symbol().type(d3.symbolSquare).size(150),
            shapePadding: 10,
            cellFilter: d => visibleLines.indexOf(d.label) > -1
        },
        axis: {
            x: {
                fn: d3.axisBottom,
                ticks: d3.timeMonth,
                tickFormat: {
                    formatFn: d3.timeFormat,
                    format: "%b %Y"
                }
            },
            y: {
                fn: d3.axisLeft,
                tickFormat: currentYAxisKey => d3.format(",.0f")
            }
        },
        line: {
            curve: d3.curveMonotoneX
        },
        toolTip: {
            styles: {
                left: 0,
                top: 0,
                display: 'none',
                'z-index': 10001
            },
            arrowStyles: {
                top: 'auto',
                bottom: -5
            },
            currencyFormat: d3.format("$,.0f"),
            monthFormat: d3.timeFormat("%B"),
        }
    };

    var svg;
    var resizeTimer;
    var lines;
    var dotGroups;
    var xScale;
    var yScale;
    var colorScale;
    var makeLegend;
    var xAxis;
    var yAxis;
    var xAxisBottomContainer;
    var yAxisLeftContainer;
    var layer;
    var currentYAxisKey;
    var legend;

    var currentAxis;
    var graphData;
    let elem;
    if (selection) {
        elem = selection;
    }
    
    if (!toolTip) {
        // create tooltip
        toolTip = d3.select('body')
            .append('div')
            .attr('class', 'lineGraphToolTip')
            .style('left', `${defaults.toolTip.styles.left}px`)
            .style('top', `${defaults.toolTip.styles.top}px`)
            .style('display', defaults.toolTip.styles.display)
            .style('z-index', defaults.toolTip.styles['z-index']);
        
        toolTipMonthYear = toolTip
            .append('p')
            .append('strong');

        toolTipCurrentAxis = toolTip
            .append('p');
        
        toolTipArrow = toolTip
            .append('div')
            .attr('class', 'lineGraphTooltipArrow')
            .style('top', defaults.toolTip.arrowStyles.top)
            .style('bottom', `${defaults.toolTip.arrowStyles.bottom}px`);

    }

    function chart (selection) {
        return chart;
    }

    chart.graphData = function(newData) {
        if (!graphData && newData) {
            if (!currentYAxisKey) {
                currentYAxisKey = Object.keys(currentAxis).filter(key => currentAxis[key])[0];
            }
            setScaleDomains(newData);
            updateScaleSizeRanges();
            initialize();
            renderGraph(newData);
        } else if (graphData && newData)  {
            renderGraph(newData);
        }
        return chart;
    };

    chart.options = function(newData) {

        if (newData) {

            options = Object.assign({}, defaults, newData);
                        
            updateDimensions();
            setScales();

        }

        return chart;
    };

    chart.currentAxis = function(newData) {
        if (Object.keys(newData).filter(key => newData[key])[0] !== Object.keys(currentAxis || {}).filter(key => currentAxis[key])[0]) {
                        
            currentYAxisKey = Object.keys(newData).filter(key => newData[key])[0];            
            
            if (graphData) {
                yScale.domain([0, d3.max(graphData.flatMap(line => line.values), d => d[currentYAxisKey])]);
            
                var tickFormatter = options.axis.y.tickFormat(currentYAxisKey);
                yAxis.tickFormat(tickFormatter);
                
                reRenderGraph();
            }
            
        }
        return chart;
    };

    chart.resize = function() {
        // Run code here, resizing has "stopped"
        updateDimensions();
        updateScaleSizeRanges();

        svg
            .attr("width", options.dimensions.container.width)
            .attr("height", options.dimensions.container.height);

        reRenderGraph();
        return chart;
    };

    chart.remove = function() {
        toolTip.remove();
    };

    // called after options passed in but before dimensions set and data is ready
    function setScales() {

        xScale = options.scales.x.fn();

        yScale = options.scales.y.fn();

        colorScale = options.scales.color.fn(options.colors);

        makeLegend = options.legend.fn()
            .shape("path", options.legend.shape())
            .shapePadding(options.legend.shapePadding)
            .cellFilter(options.legend.cellFilter)
            .scale(colorScale);

        xAxis = options.axis.x.fn(xScale)
            .ticks(options.axis.x.ticks)
            .tickFormat(options.axis.x.tickFormat.formatFn(options.axis.x.tickFormat.format));
    }

    // called only once after scales initialized and data is ready
    function setScaleDomains(data) {

        // flatten lines data for each of calculating domains
        var flattenedData = data.flatMap(lineObj => lineObj.values);
        var dateExtent = d3.extent(flattenedData, d => d[options.scales.x.accessor]);

        //calculate x domain and update x scale
        xScale
            .domain(dateExtent);

        //determine names and map to color pallette
        colorScale
            .domain(options.scales.color.domain);

        //calculate y domain and update y scale
        yScale.domain([0, d3.max(flattenedData, d => d[currentYAxisKey])]);
    }

    // used whenever container dimensions need to be recalculated and saved in options
    function updateDimensions() {
        options.dimensions.container = {
            // parent used here because line graph directive on svg element
            width: (elem.current.parentElement.closest('div').clientWidth),
            height: (elem.current.parentElement.closest('div').clientHeight)
        };
    }

    // called for initial scale size range setup and after page resize
    function updateScaleSizeRanges() {

        xScale
            .range([
                (options.dimensions.dotRadius * 2),
                (options.dimensions.container.width - options.dimensions.margin.left - options.dimensions.margin.right - (options.dimensions.dotRadius * 2))
            ]);

        yScale
            .range([
                (options.dimensions.container.height - options.dimensions.margin.top - options.dimensions.margin.bottom),
                0
            ]);

    }

    // called when page resizes or y axis changes
    function reRenderGraph() {

        xAxisBottomContainer
            .attr("transform", `translate(${options.dimensions.margin.left},${options.dimensions.container.height - options.dimensions.margin.bottom})`);

        xAxisBottomContainer.call(xAxis.scale(xScale))
            .selectAll("text")
                .attr("y", 0)
                .attr("x", 9)
                .attr("dy", ".35em")
                .attr("transform", "rotate(90)")
                .style("text-anchor", "start");

        yAxisLeftContainer
            .call(yAxis);

        layer.selectAll('.line')
            .attr("d", d => {
                return d3.line()
                    .defined(d => !!d[currentYAxisKey])
                    .x( d => xScale(d.date) )
                    .y( d => yScale(d[currentYAxisKey]) )
                    .curve(options.line.curve)
                    (d.values);
            });

        layer.selectAll('.dot')
            .attr('cx', d => xScale(d.date))
            .attr('cy', d => yScale(d[currentYAxisKey]));
    }

    // called only once, after scales finished, but before lines and dots rendered
    function initialize() {

        // svg = scope.lineGraph = d3.select(elem[0])
        svg = d3.select(elem.current)
            .attr("width", options.dimensions.container.width)
            .attr("height", options.dimensions.container.height);

        xAxisBottomContainer = svg
            .append('g')
                .attr("transform", `translate(${options.dimensions.margin.left},${options.dimensions.container.height - options.dimensions.margin.bottom})`)
                .classed('xAxisBottom', true);

        yAxisLeftContainer = svg
            .append('g')
                .attr('transform', `translate(${options.dimensions.margin.left},${options.dimensions.margin.top})`)
                .classed(`yAxisLeft`, true);

        xAxisBottomContainer.call(xAxis)
            .selectAll("text")
                .attr("y", 0)
                .attr("x", 9)
                .attr("dy", ".35em")
                .attr("transform", "rotate(90)")
                .style("text-anchor", "start");

        // all lines and dots will be appended to "layer"
        layer = svg
            .append('g')
            .classed(`layer`, true)
            .attr('transform', `translate(${options.dimensions.margin.left},${options.dimensions.margin.top})`);

        var tickFormatter = options.axis.y.tickFormat(currentYAxisKey);
        yAxis = options.axis.y.fn(yScale)
            .tickFormat(tickFormatter);
            
        yAxisLeftContainer
            .call(yAxis);
            
        legend = svg.append("g")
            .attr("class", "legendOrdinal")
            .attr("transform", `translate(${options.dimensions.margin.left + 20},${options.dimensions.margin.top + 20})`);
    }

    // called at end of initial render and when new data passed in
    function renderGraph(data) {

        graphData = data;

        visibleLines = data.map(group => group.key);

        lines = layer.selectAll(".line")
            .data(data, d => d.key);

        lines
            .enter()
            .append("path")
                .classed("line", true)
                .attr("fill", "none")
                .attr("stroke", d => colorScale(d.key) )
                .attr("stroke-width", options.dimensions.lineWidth)
                .attr("d", d => {
                    return d3.line()
                        .defined(d => !!d[currentYAxisKey])
                        .x(d => xScale(d.date) )
                        .y(d => yScale(d[currentYAxisKey]) )
                        .curve(options.line.curve)
                        (d.values);
                });

        dotGroups = layer.selectAll('.dots')
            .data(data, d => d.key);

        dotGroups
            .enter()
            .append('g')
                .classed('dots', true)
                .attr('fill', d => colorScale(d.key))
                .selectAll('circle')
                .data(d => d.values)
                    .enter()
                    .append('circle')
                        .style('display', d => d[currentYAxisKey] ? 'initial' : 'none')
                        .classed('dot', true)
                        .attr('r', options.dimensions.dotRadius)
                        .attr('cx', d => xScale(d.date))
                        .attr('cy', d => yScale(d[currentYAxisKey]))
                        .on('mouseenter', (datum, i, nodes) => {
                            const d3event = d3.getEvent();

                            toolTipMonthYear.text(`${options.toolTip.monthFormat(datum.date)} ${datum.date.getFullYear()}`);
                            toolTipCurrentAxis.text(`${currentYAxisKey.charAt(0).toUpperCase() + currentYAxisKey.slice(1)}: ${currentYAxisKey === 'charges' ? options.toolTip.currencyFormat(datum[currentYAxisKey]) : datum[currentYAxisKey]}`);
                            toolTip.style('display', 'block');
                            var toolTipBoundingClientRect = toolTip.node().getBoundingClientRect();
                            var circleBoundingClientRect = nodes[i].getBoundingClientRect();
                            toolTip.style('left', (circleBoundingClientRect.left + (circleBoundingClientRect.width/2)) - (toolTipBoundingClientRect.width/2)+'px') ;
                            toolTip.style('top', circleBoundingClientRect.top - circleBoundingClientRect.height - toolTipBoundingClientRect.height + 'px');
                        })
                        .on('mouseleave', (datum, i, nodes) => {
                            toolTip.style('display', 'none');
                        });
                        

        lines
            .exit()
            .remove();

        dotGroups
            .exit()
            .remove();

        legend.call(makeLegend);
    }

    return chart;
}