import angular from 'angular';
import * as crossfilter from 'crossfilter2';

export default angular.module('crossfilter', [])
.directive('crossfilter', ['$q','$timeout', function ($q, $timeout) {
    return {
        restrict: 'EA',
        scope: false,
        link: function (scope, element, attrs, controller) {
            scope.filter = crossfilter();
            scope.dimensions = {};
            scope.$on('update', function () {
                scope.$broadcast('redraw');
            });
            controller.deferred.resolve(scope.filter);
        },
        controller: ['$scope', function ($scope) {
            this.deferred = $q.defer();
            this.name = 'crossfilter';
            this.crossfilter = function () {
                return this.deferred.promise;
            };
            this.broadcast = function (event, data) {
                $scope.$parent.$broadcast(event, data);
            };
        }]
    };
}]).name;