import angular from 'angular';
import '../../js/geoflow';

export default 'geoFlow';

angular.module('geoFlow', [])
.directive('geoFlow', function(){
    return {
        scope: {
            opts: '<',
        },
        link: function(scope, elem, attrs){
            scope.$watchCollection('opts', function(newObj, oldObj){
                if (newObj.data) {
                    elem.geoflow(newObj);
                }
            });
            
        }
    };
});