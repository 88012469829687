import angular from 'angular';
import tourConfig from './tourConfig';

export default ['$scope', '$q', 'api', 'pic', 'tour', 'notify', function($scope, $q, api, pic, tour, notify) {
    var $ctrl = this;
    $scope.pic = pic;
    
    $ctrl.$onInit = function() {
        
        $scope.provider = $ctrl.npis[0];
        
        tour.setScript(tourConfig, $scope);
        
        $scope.reportOptions = { 
            export: { 
                name: 'Referrals Report for ' + $scope.provider.name.display
            },
            communityList: {
                accessor: datum => datum.provider.npi,
                description: 'Toggle between the list of providers from the page you were viewing and the list of providers from the full Shared Visits Report.'
            }
        };
        
        api.Relations($scope.provider.npi)
        .then(function(data) {
            
            if (data.length === 0) {
                console.log('No relations found for provider: ', data);
                $scope.loaded = true;
                notify.alert({
                    title: 'NO DATA',
                    text: 'No data found for provider.',
                    delay: 30000
                });
            }
            
            var hash = data.reduce(function(hash, rel) {
                var dir = rel.source.npi == $scope.provider.npi ? 'target' : 'source';
                var npi = rel[dir].npi;
                if ( !hash[npi] ) {
                    hash[npi] = {
                        [dir]: angular.extend({}, rel.values)
                    };
                } else {
                    hash[npi][dir] = angular.extend({}, rel.values);
                }
                hash[npi].sameday = hash[npi].sameday || rel.values.sameday;
                return hash;
            }, {});
            
            var datums = Object.keys(hash).map(function(npi) {
                return hash[npi];
            });
            
            var nullSubstitute = {
                sameday: 0,
                shared: 0,
                unique: 0
            };
            
            datums = datums.map(function(datum) {
                if (datum.target && !datum.source) {
                    datum.source = angular.extend({}, nullSubstitute);
                } else if (datum.source && !datum.target) {
                    datum.target = angular.extend({}, nullSubstitute);
                }
                return datum;
            });
            
            var promises = Object.keys(hash).map(function(npi) { return api.GetProvider(npi); });
            
            $q.all(promises).then(function(providers) {
              providers.forEach(function(provider) {
                  hash[provider.npi].provider = provider; 
              });
                datums = datums.map(function(datum) {
                    datum.communities = pic(datum.provider.npi);
                    return datum;
                });
                
                $scope.loaded = true;
                $scope.data = datums;
                
            });
        }, function(err){
            console.log('Error fetching Shared Visits report data: ', err);
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Shared Visits report data.',
                delay: 30000
            });
        });

    };
}];