import angular from 'angular';

import controller from './carepathways.controller';

import api from '../../services/api';
import pic from '../../services/pic';
import communityList from '../../services/communityList';
import template from '../../services/template';

import uirouter from '@uirouter/angularjs';
import { react2angular } from 'react2angular';

import { CarepathwaysComponent } from './carepathwaysComponent.js';

const componentName = 'communityCarepathways';
const updatedComponentName = 'communityCarepathwaysComponent';
const moduleName = 'app.reports.sankey.carepathways.community';

angular.module(moduleName, [
    api, uirouter, communityList, pic, template
])
.component(componentName, {
    require: {
        reportSankey: '^sankey'
    },
    bindings: {
        npis: '<',
        community: '<'
    },
    controller,
    controllerAs: '$ctrl'
})
.component(
    updatedComponentName,
    react2angular(CarepathwaysComponent, ['npis', 'community'], ['api', '$state', 'pic', 'template', 'CommunityList'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};