import angular from 'angular';
import { react2angular } from 'react2angular';
import './marketsharebyzip.scss';
import template from './marketsharebyzip.html';
import controller from './marketsharebyzip.controller';

import phTable from '../../directives/phTable/phTable';

import generalFlowModal from '../../directives/generalFlowModal/generalFlowModal';

import { moduleName as generalFlowModule } from '../generalflow.physician/generalflow';

import pic from '../../services/pic';
import api from '../../services/api';

import download from '../../services/download';

import uibModal from 'angular-ui-bootstrap/src/modal';
import uirouter from '@uirouter/angularjs';
import tour from '../../services/tour';

import { MarketSharebyZipComponent } from './marketsharebyzipComponent';

const componentName = 'organizationMarketShareByZip';
const moduleName = 'app.reports.marketsharebyzip.organization';
const updatedComponentName = 'marketSharebyZipComponent';

angular.module(moduleName, [
    phTable, pic, uibModal, uirouter, tour, api, generalFlowModal, generalFlowModule, download
])
.component(
    updatedComponentName,
    react2angular(MarketSharebyZipComponent, ['npis', 'opts', 'community'], ['api', 'pic', 'download', 'tour'])
);
// .component(componentName, {
//     bindings: {
//         npis: '<'
//     },
//     controller,
//     controllerAs: '$ctrl',
//     template,
// });

export {
    moduleName,
    componentName,
    updatedComponentName
};