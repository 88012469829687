import angular from 'angular';
import $ from 'jquery';
import * as d3 from 'd3v3';
import * as dc from 'dc';
import '../../js/dc.patches.js';
import template from '../../services/template';

export default angular.module('chart',[template])
.directive('chart', ['$parse','$interpolate', 'template', function ($parse, $interpolate, template) {
    
    function processOpts(scope, element, attrs) {
        if (attrs.opts) {
            var opts = $parse(attrs.opts)(scope);
            Object.keys(opts).map(function (key) {
                try {
                    var value = opts[key];
                    if (angular.isArray(value)) {
                        scope.graph[key].apply(scope.graph, value);
                    } else {
                        scope.graph[key](value);
                    }
                } catch (e) {
                    var path = $(element.target).parents().addBack().map(function () {
                        var siblings = $(this).siblings(this.tagName);
                        return this.tagName + (siblings.length ? '[' + $(this).prevAll(this.tagName).length + ']' : '');
                    }).get().join('/');
                    console.log(path, key, e);
                }
            });
        }
    }

    function buildGraph(scope, element, attrs, crossfilter, composite, grouping) {
        var graph;

        if (composite) {
            composite.compose(graph = scope.graph = dc[attrs.type](composite.graph(), composite.id));
        } else {
            graph = scope.graph = dc[attrs.type](element[0]);
        }

        if (grouping && !composite) {
            grouping.register(graph);
        }

        if (attrs.sort) {
            switch (attrs.sort.toLowerCase()) {
                case 'asc': graph.ordering(function (d) { return d.value; });
                case 'desc': graph.ordering(function (d) { return -d.value; });
            }
        }

        crossfilter.crossfilter().then(function(filter) {
            var dimension;

            scope.height = element.closest('.chart-stage').height();
            scope.width = element.closest('.chart-stage').width();

            scope.filter = filter;
            scope.Math = Math;
            scope.dc = dc;

            graph.width(scope.width);

            var sw = new Date();

            graph.on("filtered", function (chart, filter) {
                crossfilter.broadcast("filtered", { chart: chart, filter: filter });
            });

            if (attrs.dimension) {
                var dim = scope.$parent.dimensions[attrs.dimension];
                if (dim) {
                    dimension = scope.dimension = dim(filter);
                } else {
                    var parse = $parse(attrs.dimension);
                    dimension = scope.dimension = filter.dimension(function (d) {
                        return parse({ row: d }) || 'undefined';
                    }, attrs.dimensionIsArray);
                }
            } else if (!composite) {
                dimension = scope.dimension = filter.dimension(function (d) {
                    return d;
                }, attrs.dimensionIsArray);
            }

            if (!composite) graph.dimension(dimension);

            var group;

            if (attrs.group) {
                var groupParse = $parse(attrs.group);
                group = scope.group = {
                    all: function () { return groupParse(scope, { group: group.__proto__ }); },
                    top: function () { return groupParse(scope, { group: group.__proto__ }); },
                    __proto__: scope.group = dimension.group()
                };
            } else if (!composite) {
                group = scope.group = dimension.group();
            } else {
                group = scope.group = { all: function() { return composite.graph().group().all(); } };

                if (attrs.reduceSum) {
                    composite.reduce('sum', attrs.name, attrs.reduceSum);
                    graph.valueAccessor(function(group) { return group.value[attrs.name]; });
                } else if (attrs.reduceCount) {
                    composite.reduce('count', attrs.name, attrs.reduceCount);
                    graph.valueAccessor(function(group) { return group.value[attrs.name]; });
                } else if (attrs.reduceUnique) {
                    composite.reduce('unique', attrs.name, attrs.reduceUnique);
                    graph.valueAccessor(function(group) { 
                        var o = group.value[attrs.name]; 
                        return Object.keys(o).reduce(function (l, key) {
                            if (o[key] > 0) {
                                l.push(key);
                            }
                            return l;
                        }, []).length;
                    });
                }
            }

            group.unique = function(key) {
                var keyParse = $parse(key);
                return dimension.top(Infinity).map(function(d) {
                    return keyParse({ row: d });
                }).reduce(function(l, v) { 
                    if (l.indexOf(v) <0 ) 
                        l.push(v); 
                    return l; 
                }, []);
            };

            if (attrs.reduceSum && !attrs.stack && !composite) {
                var reduceSumParse = $parse(attrs.reduceSum);
                group.reduceSum(function (d) {
                    return reduceSumParse({ row: d, Math: Math });
                });
            }

            if (attrs.stack) {
                var stackParse = $parse(attrs.stack);

                var stacks = dimension.top(Infinity).map(function(d) {
                    return stackParse({ row: d }) + '';
                })
                .filter(function(value, index, self) { 
                    return self.indexOf(value) === index; 
                }).sort(function(a,b) {
                    return b.length - a.length || a > b;
                });

                var reduceFunc = function(d) {
                    return 1;
                };

                if (attrs.reduceSum) {
                    var reduceSumParse = $parse(attrs.reduceSum);
                    reduceFunc = function(d) {
                        return reduceSumParse({ row: d });
                    };
                } 

                group.reduce(function add(r, d) {
                    var index = stackParse({ row: d});
                    r[index] += reduceFunc(d); 

                    return r;
                }, function sub(r, d) {
                    var index = stackParse({ row: d});
                    r[index] -= reduceFunc(d);                     
                    
                    return r;
                }, function init() {
                    return stacks.reduce(function(o, s) { 
                        o[s] = 0;
                        return o;
                    }, {});
                });

                group.order(function(p) {
                    return Object.keys(p).reduce(function(s, k) {
                        return p[k] + s;
                    }, 0);
                });

                function stacker(stack) {
                    return function(g) {
                        return g.value[stack];
                    };
                }

                var defaultKey, stackParts = {};
                
                var titleFormat = d3.format(',f');
                if (attrs.titleFormat)
                    titleFormat = d3.format(attrs.titleFormat)

                stacks.forEach(function (stack) {
                    if (stackParts[stack]) return;
                    if (!defaultKey) {
                        defaultKey = stack;
                        graph.group(group, stack, stacker(stack));
                        
                    } else {
                        graph.stack(
                            group,
                            stack,
                            stackParts[stack] = stacker(stack)
                        );
                    }

                    graph.title(stack, function(d) {
                        return d.key + ': ' + titleFormat(d.value[stack]);
                    });
                });

                if (!graph.group()) {
                    graph.group(group, attrs.name);    
                }
            } else if (!composite) {
                graph.group(group, attrs.name);
            } else {
                graph.group({ all: function () { return composite.graph().group().all(); } }, attrs.name);
            }

            if (attrs.chartTitle) {
                var titleParse = $parse(attrs.chartTitle);
                graph.title(function (d) {
                    return titleParse({ d: d, Math: Math });
                });
            }

            if (attrs.valueAccessor) {
                var accessor = $parse(attrs.valueAccessor);
                graph.valueAccessor(function (row) { return accessor({ row: row }); });
            }
            

            processOpts(scope, element, attrs);

            if (attrs.xaxis) {
                $parse(attrs.xaxis)({ axis: graph.xAxis(), d3: d3 });
            }

            if (attrs.yaxis) {
                $parse(attrs.yaxis)({ axis: graph.yAxis(), d3: d3 });
            }
            
            if (attrs.filter) {
                    scope.$watch('filter', function(newVal, oldVal) {
                        if (newVal) {
                            graph.filter(null);
                            graph.filter(newVal);
                            crossfilter.broadcast("redraw");
                        }
                    });
                }
            
            if (!composite)
                graph.render();
        });

        return graph;
    }

    return {
        require: ["^^crossfilter", "?^^composite", "?^^grouping"],
        scope: {
            filter: '<'
        },
        link: function (scope, element, attrs, controllers) {
            if (!attrs.type) { return; }

            var crossfilter = controllers.filter(function(c) { return c && c.name == 'crossfilter'; })[0];
            var composite = controllers.filter(function (c) { return c && c.name == 'composite'; })[0];
            var grouping = controllers.filter(function (c) { return c && c.name == 'grouping'; })[0];

            scope.d3 = d3;

            var graph = buildGraph(scope, element, attrs, crossfilter, composite, grouping);

            function rebuild() {
                dc.chartRegistry.deregister(graph);
                graph.group().dispose();
                graph.dimension().dispose();
                graph = buildGraph(scope, element, attrs, crossfilter, composite, grouping);
            }

            crossfilter.crossfilter().then(function(filter) {
                filter.onChange(function (type) {
                    if (attrs.stack) {
                        if (type == 'dataAdded' || type == 'dataRemoved' ) {
                            rebuild();
                        }
                    } else if (type == 'dataAdded' && !composite) {
                        graph.render();
                    }
                });
            });

            scope.$on('reset', function () {
                graph.filterAll();
                crossfilter.broadcast("redraw");
            });
            
            scope.$on('filter', function(e, filter) {
                graph.filter(filter);
                crossfilter.broadcast("redraw");
            });

            if (!composite) {
                scope.$on('redraw', function () { graph.redraw(); });
            }

            var resize;
            var resizeDisposal = template.contentResize(function(newVal, oldVal){
                
                if (resize) clearTimeout(resize);

                if (typeof newVal !== "undefined") { //don't want to run resize on load
                    resize = setTimeout(function () {
                        scope.height = element.closest('.chart-stage').height();
                        scope.width = element.closest('.chart-stage').width();
    
                        if (!composite) {
                            rebuild();
                        } else {
                            processOpts(scope, element, attrs);
                            graph.redraw();
                        }
                        resize = null;
                    }, 300);
                }
            });
            
            scope.$on('$destroy', resizeDisposal);
        }
    };
}]).name;