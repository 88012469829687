import angular from 'angular';
import uirouter from '@uirouter/angularjs';
import uibModal from 'angular-ui-bootstrap/src/modal';
import './templates/modalCommunity.scss';
import modalTemplate from './templates/modalCommunity.html';
import api from '../services/api';

import uiSelect from 'ui-select';
import ngSanitize from 'angular-sanitize';
import 'ui-select/dist/select.css';
import propsFilter from '../filters/propsFilter';

export default angular.module('communityList', [
    uirouter, uibModal, api, , uiSelect, ngSanitize, propsFilter
])
.service('CommunityList', ['$transitions', '$uibModal', '$state',
function($transitions, $uibModal, $state) {
    
    var service = this;
    var modal;
    
    $transitions.onExit({ from: 'root.app.**' }, function() {
        service.reset();
        if (modal) {
            modal.dismiss();
        }
    });
    
    service.openCommunityModal = function() {
        if (service.list.length > 0) {
            modal = $uibModal.open({
                template: modalTemplate,
                controller: 'modalCommunityController',
                controllerAs: '$modalCtrl',
                size: 'lg'
            });
        } else {
            $state.go('root.app.search');
        }
    };
    
    var defaultDescription = 'Toggle between the list of providers visible in the content area and the full list of provders in the graph or report.'
    
    service.list = [];
    service.altList = [];
    service.message = defaultDescription;
    
    service.update = function(list, altList, description) {
        service.list = list || [];
        service.altList = altList || [];
        
        service.description = description || defaultDescription;
    };
    
    service.reset = function() {
        service.list = [];
        service.altList = [];
        service.description = defaultDescription;
    };
    
    service.temporaryList = false;
    
}]).controller('modalCommunityController', ['$scope', '$uibModalInstance', '$state', 'CommunityList', 'api', '$rootScope', '$q', function($scope, $uibModalInstance, $state, CommunityList, api, $rootScope, $q) {

    var $ctrl = this;
    
    var groupsHash = {};
    
    $scope.community = new api.Community();
    $scope.community.isPublic = false;
    $scope.community.watching = true;
    $scope.newCommunityMembers = [];
    $scope.action = 'Create';
    $scope.list = { show: false };
    $scope.altList = CommunityList.altList;
    $scope.suggestedAbbr = 'e.g. ABC';
    $scope.description = CommunityList.description;
    
    $ctrl.close = function() {
        $uibModalInstance.close();
    };
    
    function createProviderList(list) {
        var providerPromises = [];
        list.forEach(function(item) {
            if (typeof item === 'object') {
                providerPromises.push(api.GetProvider(item.npi));
            } else {
                providerPromises.push(api.GetProvider(item));
            }
        });
        $q.all(providerPromises).then(function(providers) {
            $scope.newCommunityMembers = providers;
            $ctrl.updateCommunityCount();
        });
    }
    
    function addAndRemoveFlags(id) {
        $scope.newCommunityMembers.forEach(function(member) {
            if (id && groupsHash[id].npis.indexOf(member.npi) > -1) {
                member._repeat = true;
            } else {
                member._repeat = false;
            }
        });
    }
    
    function wrapUp(updatedCommunity, action) {
        $uibModalInstance.close();
        $rootScope.$broadcast('communitiesUpdated', updatedCommunity);
        if ($scope.community.goHome) {
            $state.go('root.app.com.home', {cid: updatedCommunity.id});
        }
    }
    
    function determineNpis(existingNpis) {
        var npisToAdd = $scope.newCommunityMembers.map(function(provider) {
            return provider.npi;
        });
        
        if (existingNpis) {
            var nonRepeatingNpisToAdd = npisToAdd.filter(function(npi) {
                return !existingNpis.includes(npi);
            });
            npisToAdd = existingNpis.concat(nonRepeatingNpisToAdd);
        }
        
        return npisToAdd.slice(0,500);
    }
    
    $ctrl.toggleList = function() {
        $scope.toggleList = !$scope.toggleList;
        
        if ($scope.toggleList) {
            createProviderList(CommunityList.altList);
        } else {
            createProviderList(CommunityList.list);
        }
    };
    
    $ctrl.updateCommunityCount = function() {
        
        if ($scope.community.id) {
            addAndRemoveFlags($scope.community.id);
            $scope.providersToAdd = $scope.newCommunityMembers.filter(function(member) {
                return !groupsHash[$scope.community.id].npis.includes(member.npi);
            }).length;
            $scope.totalLength = groupsHash[$scope.community.id].count + $scope.providersToAdd;
        } else {
            addAndRemoveFlags();
            $scope.providersToAdd = $scope.newCommunityMembers.length;
            $scope.totalLength = $scope.newCommunityMembers.length;
        }
    };
    
    $ctrl.updateCommunity = function(action) {

        if (action === 'Append') {
            api.GetCommunity($scope.community.id)
            .then(function(res) {
                res.npis = determineNpis(res.npis);
                return res.Update();
            }).then(function(updatedCommunity) {
                wrapUp(updatedCommunity, action);
            }, function(err) {
                console.log('error', err);
            });
        } else {
            $scope.community.npis = determineNpis();
            $scope.community.abbr =  $scope.community.abbr || $scope.suggestedAbbr;
            $scope.community.Update()
            .then(function(updatedCommunity) {
                wrapUp(updatedCommunity, action);
            }, function(err) {
                console.log('error', err);
            });
        }
    };
    
    $ctrl.remove = function(member) {
        $scope.newCommunityMembers.splice($scope.newCommunityMembers.indexOf(member), 1);
        $ctrl.updateCommunityCount();
    };

     api.Groups()
    .then(function(groups) {
        var filteredResults = groups.filter(function(group) {
            return group.owned;
        });
        $scope.ownedGroups = filteredResults;
        
        $scope.ownedGroups.forEach(function(group) {
            groupsHash[group.id] = { 
                count: group.count,
                npis: group.npis
            };
        });
    });
    
    $scope.$watch('action', function (val) {
        if (val === 'Create') {
            $scope.community.id = undefined;
        }
        $ctrl.updateCommunityCount();
    });
    
    $scope.$watch('community.id', function(val) {
        if ($scope.action === 'Append') {
            $ctrl.updateCommunityCount();
        }
    });
    
    $scope.$watch('community.name', function (newVal, oldVal) {

        if (newVal) {
            newVal = newVal.split(' ');
            
            var suggestedAbbr;
            if (newVal.length > 1) {
                suggestedAbbr = newVal.reduce(function(acc, curr) { return acc + curr.charAt(0)}, '');
            } else {
                suggestedAbbr = newVal[0];
            }
            
            $scope.suggestedAbbr = suggestedAbbr.slice(0, 3).toUpperCase();
        } else if (oldVal && !newVal) {
            $scope.suggestedAbbr = 'e.g. ABC';
        }
    });
    
    createProviderList(CommunityList.list);

}]).name;