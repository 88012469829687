import modalTemplate from './sankeyModal.html';
import tourConfigOrg from './tourConfigOrg';
import tourConfigCom from './tourConfigCom';

export default ['$scope', '$http', '$document', '$uibModal', 'api', '$q', '$state', '$window', 'download', 'notify', 'tour', '$timeout', 'config', function($scope, $http, $document, $uibModal, api, $q, $state, $window, download, notify, tour, $timeout, config) {
    var $ctrl = this;
    var treemapTitle = "Market Makers";
    var modal;
    
    $scope.$on('$destroy', function() {
        if (modal) {
            modal.dismiss();
        }
    });
    
    $scope.treeMapStates = [
        ["Market Makers"],
        ["Market Makers"],
        ["Market Makers"]
    ];
    
    $ctrl.$onInit = function() {
        
        var preventArrowActions = false;
        
        var tourScriptHooks = {
            beforeTour: function() {
                preventArrowActions = true;
            },
            afterTour: function() {
                preventArrowActions = false;
            }
        };
        
        if ($ctrl.npis) {
            tour.setScript(tourConfigOrg($timeout), $scope, tourScriptHooks);
        }
        
        if ($ctrl.community) {
            tour.setScript(tourConfigCom($timeout), $scope, tourScriptHooks);
        }
        
        $ctrl.leakageOpts = [
            { name: 'Shared Visits', property: 'shared', active: true },
            { name: 'Unique Patients', property: 'unique', active: false },
            { name: 'Same Day Visits', property: 'sameday', active: false }
        ];
        
        $ctrl.toggleLeakageSelector = function (index) {
            $ctrl.leakageOpts.forEach((option, i) => {
                i === index ? option.active = true : option.active = false;
            });
            
            render($scope.originalRelations, $scope.treeMapStates.slice());
        };
        
        $scope.activeTab = 1;
        
        $document.bind('keydown', function (e) {
            if (!preventArrowActions) {
                $scope.$apply(function () {
                    if ( e.keyCode === 39 ){//right arrow key
                        $scope.activeTab = $scope.activeTab % 3 + 1;
                    } else if ( e.keyCode === 37 ){//left arrow key
                        $scope.activeTab = ( $scope.activeTab + 1 ) % 3 + 1;
                    }
                });
            }
        });
        
        function accumulate(d) {
            if (d.children) {
                return d.leakage = d.children.reduce(function(p, v) { return p + accumulate(v); }, 0);
            }

            if ( d.name.indexOf('to other organizations') == -1 ) {
                d.leakage = d.total - d.value;
                return 0;
            } else { 
                return d.leakage = d.value;
            }
        }
        
        function setParent(datum) {
            if (datum.children) {
                datum.children.forEach(function(child) {
                    child.parent = datum;
                    setParent(child);
                });
            }
        }
        
        function buildNestedLookup(relations){
            var lookupObj = relations.reduce((acc, relation) => {
                
                if (!acc[relation.source.taxonomy.type]) {//type not created yet
                    acc[relation.source.taxonomy.type] = {};
                }
                
                if (!acc[relation.source.taxonomy.type][relation.source.taxonomy.classification] && relation.source.taxonomy.specialization) {//classification not created yet
                    acc[relation.source.taxonomy.type][relation.source.taxonomy.classification] = {};
                }
                
                if (!relation.source.taxonomy.specialization) {
                    if (!acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`]) {// non-specialization classifiction not created yet
                        acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`] = {};
                    }
                } else {
                    if (!acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization]) {// specialization not created yet
                        acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization] = {};
                    }
                }
                
                if (!relation.source.taxonomy.specialization) {//add non-specialist
                    if (!acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`][relation.source.npi]) {
                        acc[relation.source.taxonomy.type][`${relation.source.taxonomy.classification} (non-specialists)`][relation.source.npi] = relation;
                    } else {
                        console.log('duplicate provider at tree', relation.source);
                    }
                } else {
                    if (!acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization][relation.source.npi]) {
                        acc[relation.source.taxonomy.type][relation.source.taxonomy.classification][relation.source.taxonomy.specialization][relation.source.npi] = relation;
                    } else {
                        console.log('duplicate provider at tree', relation.source);
                    }
                    
                }
                return acc;
            },{});
            return lookupObj;
        }
        
        function getValue(relation, property) {
            return relation.values[property];
        }
        
        function getLeakage(relation, property){
            return relation.values.leakage[property];
        }
        
        function processTree(obj, label) {
            var valProp = $ctrl.leakageOpts.filter(option => option.active)[0].property;

            if (obj instanceof api.Relation) {
                return {
                    name: obj.source.toString(),
                    npi: obj.source.npi,
                    leakageOpts: $ctrl.leakageOpts,
                    children: [
                        {
                            name: `${obj.source.toString()} to ${obj.target.toString()}`,
                            value: getValue(obj, valProp),
                            url: `/${obj.source.npi}/home`,
                            total: (getValue(obj, valProp) + getLeakage(obj, valProp)),
                            npi: obj.source.npi,
                            leakageOpts: $ctrl.leakageOpts
                        },
                        {
                            name: `${obj.source.toString()} to other organizations`,
                            value: getLeakage(obj, valProp),
                            url: `/${obj.source.npi}/home`,
                            total: (getValue(obj, valProp) + getLeakage(obj, valProp)),
                            npi: obj.source.npi,
                            leakageOpts: $ctrl.leakageOpts
                        }
                    ]
                };
            } else {
                return {
                    name: label,
                    leakageOpts: $ctrl.leakageOpts,
                    children: Object.keys(obj).map(key => {
                        return processTree(obj[key], key);
                    })
                };
            }
        }
        
        function filterRelationsByLeakageRange(relations, min, max) {
            var valProp = $ctrl.leakageOpts.filter(option => option.active)[0].property;

            return relations.filter(relation => {
                var relationLeakage = getLeakage(relation, valProp) / (getLeakage(relation, valProp) + getValue(relation, valProp));
                return relationLeakage > (min || 0) && relationLeakage < (max || 1);
            });
        }
        
        function render(relations, treeMapStates) {
            
            var relationsCopy = relations.slice();
            
            var splittersRelations = filterRelationsByLeakageRange(relationsCopy, .25, .75);
            var loyalistRelations = filterRelationsByLeakageRange(relationsCopy, 0, .25);
            
            var allDocsTree = buildNestedLookup(relationsCopy);
            var splittersTree = buildNestedLookup(splittersRelations);
            var loyalistsTree = buildNestedLookup(loyalistRelations);
            
            var completeAllDocs = processTree(allDocsTree, 'Market Makers');
            var completeSplitters = processTree(splittersTree, 'Market Makers');
            var completeLoyalists = processTree(loyalistsTree, 'Market Makers');
            
            accumulate(completeAllDocs);
            accumulate(completeSplitters);
            accumulate(completeLoyalists);
            
            setParent(completeAllDocs);
            setParent(completeSplitters);
            setParent(completeLoyalists);
            
            if (treeMapStates) {
                
                var splittersDepth = adjustRenderDepth(splittersTree, treeMapStates[0].slice());
                $scope.splittersData = drillToChild(completeSplitters, treeMapStates[0].slice(0, splittersDepth));
                
                var allDocsDepth = adjustRenderDepth(allDocsTree, treeMapStates[1].slice());
                $scope.allDocsData = drillToChild(completeAllDocs, treeMapStates[1].slice(0, allDocsDepth));
                
                var loyalistsDepth = adjustRenderDepth(allDocsTree, treeMapStates[2].slice());
                $scope.loyalistsData = drillToChild(completeLoyalists, treeMapStates[2].slice(0, loyalistsDepth));
                
            } else {
                
                $scope.splittersData = completeSplitters;
                $scope.allDocsData = completeAllDocs;
                $scope.loyalistsData = completeLoyalists;
                
            }
            
        }
        
        // function exists to handle shifts in tree that can occur when toggling axis selector removes nodes/branches
        // if an axis toggle removes nodes/branches at current level, back up to branch that still exists
        // function returns the index at which to slice the treeMapState to prevent drilling down too deep
        function adjustRenderDepth(tree, treeMapState, index) {
            
            if (index === undefined) {//undefined on first call
                index = 0;
            }
            
            treeMapState.shift();// remove first level on state array 
            index++;//increment to calculate eventual slice point
            
            if (
                treeMapState.length > 0 &&
                tree[treeMapState[0]]
            ) {//recurse if additional nesting and next state exists
                return adjustRenderDepth(tree[treeMapState[0]], treeMapState, index);
            } else if (
                treeMapState.length > 0 &&
                Object.values(tree).every(item => item instanceof api.Relation) &&
                Object.values(tree).filter(relation => relation.source.toString() === treeMapState[0]).length > 0
            ) {//recurse if additional nesting and the next state indicates a provider relation
                return adjustRenderDepth(Object.values(tree).filter(relation => relation.source.toString() === treeMapState[0])[0], treeMapState, index);
            } else {// else return the calulated index for slice point in drilldown
                return index;
            }
            
        }
        
        function drillToChild(marketTree, treeMapState){
            
            if (!treeMapState || treeMapState.length === 1) {
                return marketTree;
            } else {
                var nameToRemove = treeMapState.shift();
                return drillToChild(marketTree.children.filter(child => {
                    return child.name === treeMapState[0];
                })[0], treeMapState);
            }
        }
        
        var relationsRequest;
        if ($ctrl.community) {
            relationsRequest = api.CommunityRelations($ctrl.community.id, 'inbound', {leakage: 'all', entitytype: '2'});
            
        } else {
            relationsRequest = api.Relations($ctrl.npis[0].npi, 'inbound', { leakage: 'all' });
        }
        
        relationsRequest
        .then(res => {
            
            var taxonomyCodeExclusions = config.marketMapTaxonomyCodes();
            res = res.filter(relation => taxonomyCodeExclusions.indexOf(relation.source.taxonomy.code) === -1);//filter out all excluded taxonomy codes
            res = res.filter(relation => {
                return (
                    taxonomyCodeExclusions.indexOf(relation.source.taxonomy.code) === -1 //filter out all excluded taxonomy codes
                    &&
                    relation.source.entitytype == '1'//filter out orgs (entitytype 2)
                );
            });
            
            if ($ctrl.community) {
                var providersHash = res.reduce((hash, relation) => {
                    if (!hash[relation.source.npi]) {
                        hash[relation.source.npi] = new api.Relation({
                            source: relation.source,
                            target: $ctrl.community,
                            values: {
                                leakage: {
                                    sameday: relation.values.leakage.sameday,
                                    shared: relation.values.leakage.shared,
                                    unique: relation.values.leakage.unique
                                },
                                sameday: relation.values.sameday,
                                shared: relation.values.shared,
                                unique: relation.values.unique
                            },
                            relations: [relation]
                        });
                    } else {
                        hash[relation.source.npi].values.sameday += relation.values.sameday;
                        hash[relation.source.npi].values.shared += relation.values.shared;
                        hash[relation.source.npi].values.unique += relation.values.unique;
                        hash[relation.source.npi].relations.push(relation);
                    }
                    return hash;
                }, {});
                res = Object.values(providersHash);
            }
            
            $scope.originalRelations = res;
            
            $scope.loaded = true;
            
            render(res);
            
            return $q.all($scope.originalRelations.map(relation => api.Charges(`${relation.source.npi}`)));
            
        }, err => {
            console.log('Error fetching Marketmap data: ', err);
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Marketmap data.',
                delay: 30000
            });
        })
        .then(res => {
            
            var totalChargesLookup = res.reduce((lookUpObj, nodeCode) => {
                
                if (nodeCode._failed) {
                    return lookUpObj;
                }
                
                if (!lookUpObj[nodeCode.npi]) {
                    lookUpObj[nodeCode.npi] = 0;
                }
                
                lookUpObj[nodeCode.npi] += nodeCode.totalCharges;
                
                return lookUpObj;
            }, {});
            
            $scope.relationsWithCharges = $scope.originalRelations.reduce((acc, relation) => {
                if (relation.source.entitytype == '1' && totalChargesLookup[relation.source.npi]) {// only add relation if physician entitytype and total charges found
                    var updatedRelation = Object.create(relation);
                    updatedRelation['Total Charges'] = totalChargesLookup[relation.source.npi];
                    acc.push(updatedRelation);
                }
                return acc;
            }, []);
            
        }, err => {
            console.log('Error fetching Generate Report data: ', err);
            $scope.loaded = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Generate Report data.',
                delay: 30000
            });
        });
    };
    
    $scope.$on('treemap.node.nameChange', function(event, name, data) {
        treemapTitle = name;
        $scope.treeMapStates[$scope.activeTab - 1] = name.split(' > ');
    });
    
    $scope.$on('treemap.node.click', function(event, d3Event, d) {
        if (!d.children && d.npi) {
            
            var modalRelationsRequest;
            
            if ($ctrl.npis) {
                modalRelationsRequest = api.Relations($ctrl.npis[0].npi, 'outbound', { classification: 'General Acute Care Hospital' });
            } else if ($ctrl.community) {
                modalRelationsRequest = api.CommunityRelations($ctrl.community.id, 'outbound', { classification: 'General Acute Care Hospital' });
            }
            
            if (modalRelationsRequest) {
                modalRelationsRequest
                .then(function(data) {
                    if (data.length === 0) {
                        notify.alert({
                            title: 'NO DATA',
                            text: 'No Provider data found.',
                            delay: 30000
                        });
                    } else {
                        openModal(data, d);
                    }
                },function(err){
                    console.log('Error fetching Provider data: ', err);
                    notify.error({
                        title: 'ERROR',
                        text: 'Error fetching Provider data.',
                        delay: 30000
                    });
                });
            }
        }
    });
    
    function openModal(relations, d) {
        modal = $uibModal.open({
            size: 'lg',
            template: modalTemplate,
            controller: ['$scope', '$uibModalInstance', 'relations', 'provider', 'leakageOpts', function($scope, $uibModalInstance, relations, provider, leakageOpts) {
                var $modalCtrl = this;
                
                $scope.datum = d;
                $scope.provider = provider;
                
                $scope.communityOpts = $ctrl.community
                    ? {
                        currentCommunityPic: function (provider) {
                            return $ctrl.community.npis.map(npi => `${npi}`).indexOf(provider.id) > -1 ? $ctrl.community : false ;
                        },
                        toolTip: {
                            currentCommunityPic: function (provider) {
                                return $ctrl.community.npis.map(npi => `${npi}`).indexOf(provider.id) > -1 ? $ctrl.community : false ;
                            },
                            units: leakageOpts.name,
                            value: function(d) {
                                return d.values[leakageOpts.property];
                            }
                        },
                        link: {
                            value: function(d) {
                                return d.values[leakageOpts.property];
                            }
                        }
                    }
                    : {
                        toolTip: {
                            units: leakageOpts.name,
                            value: function(d) {
                                return d.values[leakageOpts.property];
                            }
                        },
                        link: {
                            value: function(d) {
                                return d.values[leakageOpts.property];
                            }
                        }
                    };
                
                $scope.title = 'General Flow for ' + provider.toString();
                
                $modalCtrl.cancel = function () {
                     $uibModalInstance.close();
                };
                
                $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
                    if (datum instanceof api.Provider) {
                        var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                            $window.open(newTabUrl, '_blank');
                    }
                });  

            }],
            resolve: {
                relations: function() {
                    return relations;
                },
                provider: function() {
                    return api.GetProvider(d.npi);
                },
                leakageOpts: function() {
                    return $ctrl.leakageOpts.filter(opt => opt.active)[0];
                }
            },
            controllerAs: '$ctrl'
        });
    }
    
    $ctrl.generateReport = function() {
        
        var activeAxis = $ctrl.leakageOpts.filter(option => option.active)[0];
        var titleValueType = `${activeAxis.name.split(" ").join("_")}_`;
        var marketShareHeader = `${activeAxis.name} to ${$ctrl.community ? 'community' : 'organization'}`;
        var leakageHeader = `${activeAxis.name} to other organizations`;
        
        var reportData = $scope.relationsWithCharges.map(relation => {
            return {
                NPI: `${relation.source.npi}`,
                'Provider Name': relation.source.toString(),
                Taxonomy: relation.source.taxonomy.type,
                Classification: `${relation.source.taxonomy.classification}${relation.source.taxonomy.specialization ? '' : ' (non-specialists)'}`,
                Specialization: relation.source.taxonomy.specialization || 'None',
                City: relation.source.location.city,
                State: relation.source.location.state,
                'Postal Code': `${relation.source.location.postal_code}`.slice(0,5),
                Telephone: relation.source.location.phone_number,
                [marketShareHeader]: relation.values[activeAxis.property],
                [leakageHeader]: relation.values.leakage[activeAxis.property],
                Share: relation.values[activeAxis.property]/(relation.values[activeAxis.property]+relation.values.leakage[activeAxis.property]),
                'Total Charges': relation['Total Charges']
            };
        }).filter(row => {
            switch ($scope.activeTab) {
                case 1:
                    return (row.Share >= .25 && row.Share < .75);
                case 3:
                    return row.Share >= .75;
                case 2:
                default:
                    return true;
            }
        }).sort((a,b) => b.Share - a.Share);
        
        var filter, chartType;
        
        if ($scope.activeTab === 3) {
            filter = treemapTitle.split(" > ");
            chartType = "Loyalists_";
        } else if ($scope.activeTab === 2) {
            filter = treemapTitle.split(" > ");
            chartType = "AllPhysicians_";
        } else if ($scope.activeTab === 1) {
            filter = treemapTitle.split(" > ");
            chartType = "Splitters_";
        }
        
        var drillDown = filter.length-1;
        var title = filter[drillDown];
        var nonSpecialists;
        var level;
        
        for (var i = 0; i < filter.length; i++) {
            if (filter[i].indexOf("non-specialists") > -1){
                nonSpecialists = true;
            }
        }
        
        if (drillDown == 0) {
            level = "Market Makers";
        } else if (drillDown == 1) {
            level = "Taxonomy";
        } else if (drillDown == 2) {
            level = "Classification";
        } else if (drillDown == 4 || nonSpecialists) {
            level = "Provider Name";
        } else if (drillDown == 3) {
            level = "Specialization";
        }
        
        var generateReport = [];
        if (title == "Market Makers") {
            generateReport = reportData;
        } else {
            for (var i = 0; i < reportData.length; i++) {
                if (reportData[i][level] == title){
                    generateReport.push(reportData[i]);
                }
            }
        }
        
        title = chartType + titleValueType + filter[drillDown];
        
        var columnOrder = [
            ['NPI', 0],
            ['Provider Name', 1],
            ['Taxonomy', 2],
            ['Classification', 3],
            ['Specialization', 4],
            ['City', 5],
            ['State', 6],
            ['Postal Code', 7],
            ['Telephone', 8],
            [marketShareHeader, 9],
            [leakageHeader, 10],
            ['Share', 11],
            ['Total Charges', 12]
        ];
        
        download.downloadCSV(generateReport, title, true, columnOrder);
    };
}];
