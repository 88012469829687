import angular from 'angular';

import { react2angular } from 'react2angular';

import './servicelinebenchmark.scss';
import template from './servicelinebenchmark.html';
import controller from './servicelinebenchmark.controller';

import api from '../../services/api';
// import codes from '../../services/codes';

import phGroupedBarGraph from '../../directives/groupedBarGraph/groupedBarGraphDirective';

import { ServicelineBenchmarkComponent } from './servcielinebenchmarkComponent';

const componentName = 'communityMarketServicelineDashboard';
const updatedComponentName = 'communityMarketServicelineDashboardComponent';
const moduleName = 'app.reports.dashboards.marketservicelinebenchmark.community';

angular.module(moduleName, [
    api, phGroupedBarGraph
])
.component(componentName, {
    bindings: {
        community: '<'
    },
    controller,
    controllerAs: '$ctrl',
    template
})
.component(
    updatedComponentName,
    react2angular(ServicelineBenchmarkComponent, ['community'], ['api','template','download','codes'])
);

export {
    moduleName,
    componentName,
    updatedComponentName
};