import React, { useEffect, useState } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import { D3FunnelComponent } from './d3FunnelComponent';

export function PerformancefunnelComponent({ api, notify, codes,  download }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const npi = npis.npi;

    useEffect(() => {
        getsearchCodesPost();
    }, [npis]);

    const overrides = {
        CPT: {
            '99201':{ description: 'New patient office or other outpatient visit, typically 10 minutes', department: 'Physician Office Visits New'},
            '99202':{ description: 'New patient office or other outpatient visit, typically 20 minutes', department: 'Physician Office Visits New'},
            '99203':{ description: 'New patient office or other outpatient visit, typically 30 minutes', department: 'Physician Office Visits New'},
            '99204':{ description: 'New patient office or other outpatient visit, typically 45 minutes', department: 'Physician Office Visits New'},
            '99205':{ description: 'New patient office or other outpatient visit, typically 60 minutes', department: 'Physician Office Visits New'},

            '99211':{ description: 'Established patient office or other outpatient visit, typically 5 minutes', department: 'Physician Office Visits Est'},
            '99212':{ description: 'Established patient office or other outpatient visit, typically 10 minutes', department: 'Physician Office Visits Est'},
            '99213':{ description: 'Established patient office or other outpatient visit, typically 15 minutes', department: 'Physician Office Visits Est'},
            '99214':{ description: 'Established patient office or other outpatient, visit typically 25 minutes', department: 'Physician Office Visits Est'},
            '99215':{ description: 'Established patient office or other outpatient, visit typically 40 minutes', department: 'Physician Office Visits Est'},

            '99218':{ description: 'Hospital observation care typically 30 minutes per day', department: 'Initial Observation Care'},
            '99219':{ description: 'Hospital observation care typically 50 minutes per day', department: 'Initial Observation Care'},
            '99220':{ description: 'Hospital observation care typically 70 minutes per day', department: 'Initial Observation Care'},
            '99221':{ description: 'Initial hospital inpatient care, typically 30 minutes per day', department: 'Initial Hospital Care'},
            '99222':{ description: 'Initial hospital inpatient care, typically 50 minutes per day', department: 'Initial Hospital Care'},
            '99223':{ description: 'Initial hospital inpatient care, typically 70 minutes per day', department: 'Initial Hospital Care'},

            '99231':{ description: 'Subsequent hospital inpatient care, typically 15 minutes per day', department: 'Subsequent Hospital Care'},
            '99232':{ description: 'Subsequent hospital inpatient care, typically 25 minutes per day', department: 'Subsequent Hospital Care'},
            '99233':{ description: 'Subsequent hospital inpatient care, typically 35 minutes per day', department: 'Subsequent Hospital Care'},
            
            '99234':{ description: 'Hospital observation or inpatient care low severity, 40 minutes per day', department: 'Observation Visit'},
            '99235':{ description: 'Hospital observation or inpatient care moderate severity, 50 minutes per day', department: 'Observation Visit'},
            '99236':{ description: 'Hospital observation or inpatient care high severity, 55 minutes per day', department: 'Observation Visit'},
            
            '99241':{ description: 'Office consultation for a new or established patient, typically 15 minutes', department: 'Consultation Visit'},
            '99242':{ description: 'Office consultation for a new or established patient, typically 30 minutes', department: 'Consultation Visit'},
            '99243':{ description: 'Office consultation for a new or established patient, typically 40 minutes', department: 'Consultation Visit'},
            '99244':{ description: 'Office consultation for a new or established patient, typically 60 minutes', department: 'Consultation Visit'},
            '99245':{ description: 'Office consultation for a new or established patient, typically 80 minutes', department: 'Consultation Visit'},
            '99251':{ description: 'Inpatient consultation for a new or established patient, typically 20 minutes', department: 'Consultation Visit'},
            '99252':{ description: 'Inpatient consultation for a new or established patient, typically 40 minutes', department: 'Consultation Visit'},
            '99253':{ description: 'Inpatient consultation for a new or established patient, typically 55 minutes', department: 'Consultation Visit'},
            '99254':{ description: 'Inpatient consultation for a new or established patient, typically 80 minutes', department: 'Consultation Visit'},
            '99255':{ description: 'Inpatient consultation for a new or established patient, typically 110 minutes', department: 'Consultation Visit'},

            '99281':{ description: 'Emergency department visit, self limited or minor problem', department: 'ED Visit'},
            '99282':{ description: 'Emergency department visit, low to moderately severe problem', department: 'ED Visit'},
            '99283':{ description: 'Emergency department visit, moderately severe problem', department: 'ED Visit'},
            '99284':{ description: 'Emergency department visit, problem of high severity', department: 'ED Visit'},
            '99285':{ description: 'Emergency department visit, problem with significant threat to life or function', department: 'ED Visit'},

            '99354':{ description: 'Prolonged service in the office or other outpatient setting, first hour', department: 'Other'},
            '99355':{ description: 'Prolonged service in the office or other outpatient setting, each additional 30 minutes', department: 'Other'}
        },
        HCPCS: {
            'S9083':{ description: 'Urgent Care', department: 'Urgent Care'},
            'S9088':{ description: 'Urgent Care', department: 'Urgent Care'}
        }
    };

    function getsearchCodesPost() {
        const overrideCodes = Object.keys(overrides).map(codeset => Object.keys(overrides[codeset])).flat();
        codes.searchCodesPost({
            codeset: Object.keys(overrides).join(','),
            codes: overrideCodes
        })
        .then(resCodes => {
            const rescodes = resCodes.data.reduce((acc, val) => {
                if (!acc[val.codeset]) { acc[val.codeset] = {} }
                acc[val.codeset][val.code] = val;
                    return acc;
            }, {});
            getAndFormatData(npi, rescodes);
        })
        .catch(err => console.log("codesLookupResult Failed (: " + err )); 
    }

    async function getAndFormatData(npi, codesLookup){
        setLoading(true)
        var SORT_ORDER = [
          "Physician Office Visits New", 
          "Physician Office Visits Est", 
          "Urgent Care",
          "ED Visit",
          "Initial Observation Care",
          "Observation Visit",
          "Consultation Visit",
          "Initial Hospital Care",
          "Subsequent Hospital Care",
          "Other"
      ].reduce(function(o, g, i) { 
          o[g] = i+1;
          return o; 
      }, {});

    function formatData(data) {
        var codes = data.slice();
        var foundCodes = codes.reduce(function(o, c){
              if (!o.items[c.code]) {
                  o.items[c.code] = {[c.codeset]: c};
                  o.list.push(c.code);
              }
              return o;
        }, { list:[], items: {}});
        Object.keys(codesLookup).forEach(function(codeset) {
            Object.keys(codesLookup[codeset]).forEach(function(code) {
                var index = foundCodes.list.indexOf(code);
                if (index < 0) {
                    codes.push({
                        code: code,
                        codeset: codeset,
                        _code: codesLookup[codeset][code],
                        values: {
                            "average_submitted_chrg_amt": 0, 
                            "bene_day_srvc_cnt": 0,
                            "line_srvc_cnt": 0, 
                            "national_average_submitted_to_allowed": 0, 
                            "srvc_cnt": 0, 
                            "unique_cnt": 0
                        }
                    });
                } else {
                    if (!foundCodes.items[code][codeset]) {
                        foundCodes.items[code][codeset] = {};
                        foundCodes.items[code][codeset]._code = codesLookup[codeset][code];
                    } else {
                        foundCodes.items[code][codeset]._code = codesLookup[codeset][code];
                    }
                }
            });	
        });

        codes = codes.reduce(function(o, d){
        
            if (o[d.code]){
                o[d.code].total += d.values.line_srvc_cnt;
                return o;
            }

            var code = codesLookup[d.codeset][d.code];

            var department = code.department ? code.department : 'Other Categories';
            var description;
            if (codesLookup[d.codeset][d.code].description) {
                description = codesLookup[d.codeset][d.code].description;
            } else {
                description = '';
            }
        
            var override = overrides[d.codeset][d.code];
            if (override) {
                department = override.department || department;
                description = override.description || description;
            } 

            if (!codesLookup[d.codeset][d.code].hasOwnProperty('total')){
                codesLookup[d.codeset][d.code].total = d.values.line_srvc_cnt;
            } else {
                codesLookup[d.codeset][d.code].total += d.values.line_srvc_cnt;
            }

            o[d.code] = {
                hcpcs_code: d.code,
                category: department,
                hcpcs_description: description,
                total: d.values.line_srvc_cnt
            };

            return o;
        }, {});

        codes = Object.keys(codes).reduce(function(l, code) {
            l.push(codes[code]);
            return l;
        }, []);

        var newEst = ['Physician Office Visits New', 'Physician Office Visits Est'];
        var ratios = [{
            'HCPCS': '',
            'Description': 'Total Visits',
            'Sum Visits': '',
            'Ratios': 0,
            'Section Sum': ''
        }];

        var funnel = {};

        var __data = codes.reduce(function(output, row, i){
            if (!output.hasOwnProperty(row.category)){
                output[row.category] = {
                    'section_sum': row.total
                };
            } else {
                output[row.category].section_sum += row.total;
            }
            if (!funnel.hasOwnProperty(row.category)){
                funnel[row.category] = {
                    'section_sum': row.total
                };
            } else {
                funnel[row.category].section_sum += row.total;
            }
            if (!output[row.category].hasOwnProperty(row.hcpcs_code)){
                output[row.category][row.hcpcs_code] = {
                    'description': row.category,
                    'visits': row.total,
                    'hcpcs_code': row.hcpcs_code,
                    'hcpcs_description': row.hcpcs_description
                };
            }
            if (newEst.indexOf(row.category) > -1)
                ratios[0]['Ratios'] += row.total;
            return output;
        }, {});

        var funnelArr = Object.keys(funnel).reduce(function(output, row){
            var arr = { 'label': row, 'value': funnel[row].section_sum };
            output.push(arr);
            return output;
        }, []);
        
        return funnelArr;
    }


    await fetch('/api/npi/' + npi + '/clinical/?filter_code=992*,99354,99355,-99*7,-99*38,-99*39,-99288,-99225,-99224,-99*9*,-99226,S9083,S9088', 
      api.options())
        .then(res => res.json())
        .then(res => {
            const rawData = res.data;       
            rawData.forEach(d => {
                if (d.codeset === 'HCPCS' && codesLookup.CPT[d.code]) {
                    d.codeset = 'CPT';
                }
            });
            
            var formattedData = formatData(rawData);
            formattedData = formattedData.sort(function(a,b) {
                var aText = SORT_ORDER[a.label];
                var bText = SORT_ORDER[b.label];
                return (aText < bText) ? -1 : (aText > bText) ? 1 : 0;
            });
          
          var maxFunnelValue = (Math.max.apply(Math, formattedData.map(function(i){return i.value})));
          
          if (maxFunnelValue > 0) {
              var data = [];
              formattedData.push({'label': 'Others', 'value': 0});
              
              formattedData.forEach(function(d, i) {
                  if ((d.value < (0.065*maxFunnelValue))) {
                      formattedData[formattedData.length-1].value += d.value;
                  }
              });
              
              formattedData.forEach(function(d, i) {
                  if (!(d.value < (0.065*maxFunnelValue)) || d.label == 'Others') {
                      data.push(d);
                  }
              });
              setLoading(false);
              setData(data);
          } else {
              setLoading(false);
              console.log('There is no data for the Performance Report Funnel.');
          }
      }).catch((err) => {
              console.log('Error fetching Performance Funnel data: ', err);
      })
    }
    
    const renderFunnelComponent = () => {
        if (data.length > 1)  return  <D3FunnelComponent data={data} ></D3FunnelComponent>
    }

    return (
        <div id="d3section">
        <div className="loading-lg" style={{ 'display' : (loading) ? 'inline' : 'none' }}></div>
        { renderFunnelComponent() }
        </div>
    )
}