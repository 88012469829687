import React, {useState, useEffect} from "react";
import ReactDom from 'react-dom';
import * as d3 from 'd3';
import { useRouteLoaderData } from "react-router-dom";
import merge from 'lodash/merge';

import { useApi, usePic, useTemplate, useLogging, useCommunityList } from '../../services/servicesContext';
import { PhSankeyComponent } from '../../components/sankey/sankeyComponent.js';

import { CommunityModal } from "../../components/community/modalCommunityComponent";

export function CarepathwaysComponent() {
    
    const [ relations, setRelations ] = useState(null);
    const [ options, setOptions ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalData, setModalData ] = useState(null);
    const [ modalTitle, setModalTitle] = useState(null);
    const community = useRouteLoaderData('community.cid');
    const api = useApi();
    const pic = usePic();
    const template = useTemplate();
    const logging = useLogging();
    const CommunityList = useCommunityList();
    const routeChange = (redirectTo) =>{ 
        navigate(redirectTo);
    };
    const [axis, setAxis] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(null);
    const mapping = {
        shared: {
            units: 'Shared Visits',
            sortAxis: 'totalShared'
        },
        sameday: {
            units: 'Same Day Visits',
            sortAxis: 'totalSame'
        },
        unique: {
            units: 'Unique Patients',
            sortAxis: 'totalUnique'
        }
    };
    const handleDropdownClick = function (col) {
        if (openDropdown === col) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(col);
        }
    };

    const toggleAxis = function (property) {
        let optionsClone = { ...options };
        optionsClone.link = {
            value: function (d) {
                return d.values[property];
            }
        };
        merge(optionsClone.toolTip, {
            units: mapping[property].units,
            value: function (d) {
                return d.values[property];
            }
        });
        optionsClone.node.sortAxis = mapping[property].sortAxis;
        setAxis(property);
        setOptions(optionsClone);
        setOpenDropdown(null);
    };
    
    const graphOptions = {
        color: {
            scale: d3.scaleOrdinal(d3.schemeCategory20),
            value: function(d) {
                return this.scale(d.replace(/TO |FROM /, ''));
            }
        },
        link: {
            hoverClassCheck: function(currentDatum, hoverDatum){
                if (currentDatum.origin == hoverDatum.origin && currentDatum.destination == hoverDatum.destination) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        node: {
            handleClick: function(event, datum) {
                
                let links;
                let drillDownTitle = '';
                let overflow = {};
                
                
                overflow.source = datum.sourceLinks.length ? new api.Community({ name: '(others)' }) : community;
                overflow.target = datum.targetLinks.length ? new api.Community({ name: '(others)' }) : community;

                if (community && datum.sourceLinks.length > 0 && datum.targetLinks.length > 0) {// is center node
                    window.open(`/community/${community.id}/home`, '_blank');
                    return null;
                } else if (datum.sourceLinks.length > 0) {// is left node
                    drillDownTitle = datum.sourceLinks[0].source.toString()  + ' → ' + community.toString();
                    const sources = datum.relations.reduce((acc, relation) => {
                        if (!acc[relation.source.npi]) {
                            acc[relation.source.npi] = {};
                            acc[relation.source.npi].source = relation.source;
                            acc[relation.source.npi].values = {...relation.values};
                        } else {
                            acc[relation.source.npi].values.shared += relation.values.shared;
                            acc[relation.source.npi].values.sameday += relation.values.sameday;
                            acc[relation.source.npi].values.unique += relation.values.unique;
                        }
                        return acc;
                    }, {});
                    links = Object.values(sources).map(sourceVal => {
                        return new api.Relation({
                            source: sourceVal.source,
                            target: community,
                            values: {...sourceVal.values}
                        });
                    });
                } else if (datum.targetLinks.length > 0) {// is right node
                    drillDownTitle = community.toString() + ' → ' + datum.targetLinks[0].target.toString();
                    const targets = datum.relations.reduce((acc, relation) => {
                        if (!acc[relation.target.npi]) {
                            acc[relation.target.npi] = {};
                            acc[relation.target.npi].target = relation.target;
                            acc[relation.target.npi].values = {...relation.values};
                        } else {
                            acc[relation.target.npi].values.shared += relation.values.shared;
                            acc[relation.target.npi].values.sameday += relation.values.sameday;
                            acc[relation.target.npi].values.unique += relation.values.unique;
                        }
                        return acc;
                    }, {});
                    links = Object.values(targets).map(targetVal => {
                        return new api.Relation({
                            source: community,
                            target: targetVal.target,
                            values: {...targetVal.values}
                        });
                    });
                } else {
                    console.log('incorrect data found from node click', datum);
                    return null;
                }

                let linksCopy = links.slice(0).sort(function(a,b){
                    return b.valueOf() - a.valueOf();
                });
                
                let topLinks = linksCopy
                    .slice(0, 25)
                    .filter(link => (link.source && link.target));//prevent empty sources or targets to cause bugs

                buildCommunityList(links, topLinks, datum);

                if (linksCopy.length > 25) {//create '(others)' community rollup if more than 25
            
                    topLinks.push(linksCopy.slice(25).reduce(function(relation, link){
                        
                        relation.values.shared += link.values.shared;
                        relation.values.unique += link.values.unique;
                        relation.values.sameday += link.values.sameday;
                        return relation;
                        
                    }, new api.Relation({
                        source: overflow.source,
                        target: overflow.target,
                        values: {
                            sameday: 0,
                            shared: 0,
                            unique: 0
                        }
                        
                    })));
                }
                
                setModalData(topLinks);
                setModalTitle(drillDownTitle);
                setModalOpen(true);
            }
        },
        toolTip: {
            units: 'Shared Visits',
            value: function (d) {
                return d.values.shared;
            }
        }
    };

    function buildCommunityList(links, topLinks, datum) {
        var direction;
        if (datum.sourceLinks.length > 0) {
            direction = 'source';
        } else {
            direction = 'target';
        }
        
        var list = topLinks.filter(link => {//remove "(others)"
            return link.source === '(others)' || link.destination === '(others)' ? false : true;
        }).map(function(link) {
            return link[direction].npi;
        });

        var altList;
        if (links > topLinks) {
            altList = links.map(function(link) { return link[direction]?.npi; });
        }
        
        CommunityList.temporaryList = true;
        CommunityList.update({listVal: list, altListVal: altList});
    }

    useEffect(() => {

        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.com.graphs.sankey.carepathways'
        });
        
        async function init() {
            try {
                setLoading(true);
                const seedProvider = community;
                const relations = await api.CommunityRelations(seedProvider.id);
                
                const promises = relations.map(relation => {
                    let relationships = [];
                    
                    if (relation.source instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.source).then(function(provider){
                            return relation.source = provider;
                        }));
                    }
                    
                    if (relation.target instanceof api.Provider === false) {
                        relationships.push(api.GetProvider(relation.target).then(function(provider){
                            return relation.target = provider;
                        }));
                    } 
                    
                    return Promise.allSettled(relationships);
                });

                await Promise.allSettled(promises);
                
                var seedCommunity = community;

                var hash = relations.filter(function(relation){// Filter for inter community
                    return !(
                        seedProvider.npis.indexOf(relation.source.npi) > -1 && 
                        seedProvider.npis.indexOf(relation.target.npi) > -1
                    );
                }).reduce(function(hash, relation) {
                    var category, obj;
                    
                    if (seedProvider.npis.indexOf(relation.source.npi) > -1) { // TO
                        category = 'TO ' + relation.target.taxonomy;
                        
                        if (!hash[category]) {
                            hash[category] = obj = new api.Relation({
                                source: seedCommunity ? seedCommunity : relation.source,
                                target: new api.Community({
                                    name: category
                                }),
                                values: { shared: 0, unique: 0, sameday: 0}
                            });
                            obj.target.relations = [ relation ];
                        } else {
                            obj = hash[category];
                            obj.target.relations.push(relation);
                        }
                    } else {
                        category = 'FROM ' + relation.source.taxonomy;
                        
                        if (!hash[category]) {
                            hash[category] = obj = new api.Relation({
                                source: new api.Community({
                                    name: category
                                }),
                                target: seedCommunity ? seedCommunity: relation.target,
                                values: { shared: 0, unique: 0, sameday: 0}
                            });
                            obj.source.relations = [ relation ];
                        } else {
                            obj = hash[category];
                            obj.source.relations.push(relation);
                        }
                    }
                    
                    obj.values.shared += relation.values.shared;
                    obj.values.unique += relation.values.unique;
                    obj.values.sameday += relation.values.sameday;
                    
                    return hash;
                    
                }, {});//get data and categorize based on specializaton or classification

                var top10targetsAndSources = Object.keys(hash).map(function(key) {//
                    return hash[key];
                }).reduce(function(acc, relation){
                    
                    var dir = relation.source.id ? 1 : 0 ;
                    acc[dir].push(relation);
                     
                    return acc;
                    
                },[[], []])
                .reduce(function(acc, list) {
                    var top10 = list.sort(function(a, b) { return a.values.shared - b.values.shared }).slice(-10);
                    return acc.concat(top10);
                }, []);

                var separatedRelations = top10targetsAndSources.reduce(function(acc, relation){// for Each relation in each community create a new relation
                    if (relation.source.relations) {// left side
                        // if Left side, source = adhoc, target = seed,  origin = adhoc, destination = seed.meber provider
                        var leftRelationsHash = relation.source.relations.reduce(function(innerAcc, member){
                            
                            if (innerAcc[member.source.toString()]) {
                                innerAcc[member.source.toString()].values.shared += member.values.shared;
                                innerAcc[member.source.toString()].values.unique += member.values.unique;
                                innerAcc[member.source.toString()].values.sameday += member.values.sameday;
                                return innerAcc;
                            } else if (Object.keys(innerAcc).length === 49) {
                                innerAcc.others = {
                                    __proto__: relation,
                                    origin: '(others)',
                                    destination: relation.target.toString(),
                                    values: {
                                        __proto__: member.values
                                    }
                                };
                                return innerAcc;
                            } else if (Object.keys(innerAcc).length === 50) {
                                innerAcc.others.values.shared += member.values.shared;
                                innerAcc.others.values.unique += member.values.unique;
                                innerAcc.others.values.sameday += member.values.sameday;
                                return innerAcc;
                            } else {
                                innerAcc[member.source.toString()] = {
                                    __proto__: relation,
                                    origin: member.source.toString(),
                                    destination: relation.target.toString(),
                                    values: {
                                        __proto__: member.values
                                    }
                                };
                                return innerAcc;
                            }
                            
                        }, {});
                        var leftRelationsToAdd = Object.keys(leftRelationsHash).map(function(key){
                            return leftRelationsHash[key];
                        });
                        var newLeftAcc = acc.concat(leftRelationsToAdd);
                        return newLeftAcc;
                    } else {// right side
                        // if Right side, source = seed, target = adhoc, origin = seed.member provider, destination = adhoc
                        var rightRelationsHash = relation.target.relations.reduce(function(innerAcc, member){
                            
                            if (innerAcc[member.target.toString()]) {
                                innerAcc[member.target.toString()].values.shared += member.values.shared;
                                innerAcc[member.target.toString()].values.unique += member.values.unique;
                                innerAcc[member.target.toString()].values.sameday += member.values.sameday;
                                return innerAcc;
                            } else if (Object.keys(innerAcc).length === 49) {
                                innerAcc.others = {
                                    __proto__: relation,
                                    origin: relation.source.toString(),
                                    destination: '(others)',
                                    values: {
                                        __proto__: member.values
                                    }
                                };
                                return innerAcc;
                            } else if (Object.keys(innerAcc).length === 50) {
                                innerAcc.others.values.shared += member.values.shared;
                                innerAcc.others.values.unique += member.values.unique;
                                innerAcc.others.values.sameday += member.values.sameday;
                                return innerAcc;
                            } else {
                                innerAcc[member.target.toString()] = {
                                    __proto__: relation,
                                    origin: relation.source.toString(),
                                    destination: member.target.toString(),
                                    values: {
                                        __proto__: member.values
                                    }
                                };
                                return innerAcc;
                            }
                            
                        }, {});
                        var rightRelationsToAdd = Object.keys(rightRelationsHash).map(function(key){
                            return rightRelationsHash[key];
                        });
                        var newRightAcc = acc.concat(rightRelationsToAdd);
                        return newRightAcc;
                    }
                }, []);

                setAxis('shared');
                setOptions(graphOptions);
                setRelations(separatedRelations);
                setLoading(false);

            } catch(err) {
                console.error('error initializing CarepathwaysComponent', err);
                setLoading(false);
            }
        }

        init();

    },[community, api]);

    const closeModal = (currentState) => {
        setModalOpen(currentState);
    };

    return (<>
        {(relations && options && pic && template && !loading)
            ? <div className="sankey-report-container">
                <div className="col-sm-4 secondary-button-container" style={{ padding: 0, marginRight: 0, marginLeft: 'auto' }}>
                    <div className={`provider-axis-dropdown col-md-6 axis-toggle ${openDropdown === 'axis' ? 'open' : ''}`} style={{ padding: 0 }}>
                        <button id="values-axis" type="button" onClick={() => { handleDropdownClick('axis') }} className="btn btn-default btn-md btn-block">{mapping[axis].units} <span className="caret"></span></button>
                        <ul className="dropdown-menu" role="menu" aria-labelledby="values-axis" style={{ width: '100%' }}>
                            <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                <label>
                                    <input type="radio" checked={axis === 'shared'} value="shared" onChange={() => { toggleAxis('shared') }}></input>
                                    {mapping.shared.units}
                                </label>
                            </li>
                            <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                <label>
                                    <input type="radio" checked={axis === 'sameday'} value="sameday" onChange={() => { toggleAxis('sameday') }}></input>
                                    {mapping.sameday.units}
                                </label>
                            </li>
                            <li className="radio" style={{ width: '100%', marginLeft: '15px' }}>
                                <label>
                                    <input type="radio" checked={axis === 'unique'} value="unique" onChange={() => { toggleAxis('unique') }}></input>
                                    {mapping.unique.units}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 reset" style={{ padding: 0 }}>
                        <button type="button" onClick={() => { toggleAxis('shared') }} className="btn btn-default btn-md btn-block">Reset</button>
                    </div>
                </div>
                <CarepathwaysModalComponent
                    open={modalOpen}
                    onClose={() => closeModal(!modalOpen)}
                    modalData={modalData}
                    CommunityList={CommunityList}
                    opts={options}
                    pic={pic}
                    template={template}
                    title={modalTitle}
                    api={api}
                />
                <div style={{ flex: 19 }}>
                    <PhSankeyComponent
                        data={relations}
                        opts={options}
                        pic={pic}
                        template={template}
                    />
                </div>
            </div>
            : <div className="loading-lg"></div>
        }
    </>);
}

function CarepathwaysModalComponent(props) {
    const modalOptions = {
        color: props.opts.color,
        link: props.opts.link,
        node: {
            handleClick: (e, d) => {
                if (d?.entitytype == 1) {//physician
                    window.open((`physician/${d.id}/home`), '_blank');
                }
                else if (d?.entitytype == 2) {//org
                    window.open((`organization/${d.id}/home`), '_blank');
                }
                else if (d?.id && d?.id !== '(others)') {//community
                    window.open((`community/${d.id}/home`), '_blank');
                } else {
                    return;// 'others' rollup
                }
            }
        },
        toolTip: props.opts.toolTip
    }

    const [show, setShow] = useState(false);

    function handleClose () {
        if (show) {
            setShow(false);
        }
    }

    function handleShow () {
        if (!show) {
            setShow(true);
        }
    }

    if (!props?.open) return null;

    var Comm = props.CommunityList.get();

    const modal_styles = {
        position : 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        zIndex: 1000,
        maxHeight: '100vh',
        minWidth: '960px',
        overflowY: 'auto',
    };

    const overlay_style = {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(0, 0, 0, .7',
         zIndex: 1000
    };

    const createCommunity = function () {
        props.CommunityList.openCommunityModal();
    };

    return ReactDom.createPortal(<>
        <div style={overlay_style}></div>
        <div className="modal-content" style={modal_styles}>
            <div className="modal-header">
                <button type="button" className="close" onClick={props.onClose}>×</button>
                <h3 className="modal-title" id="modal-title">{props.title}</h3>
            </div>
            <div className="modal-body sankey-modal-body" style={{margin: 'auto'}}>
                { (props.open && props.modalData) ? <PhSankeyComponent
                    data={props.modalData}
                    opts={modalOptions}
                    pic={props.pic}
                    template={props.template}
                /> : null}
            </div>
            <div className="modal-footer">
                <CommunityModal show={show} onClose={handleClose} api={props.api}/>
                <div className="modal-create-community pointer" onClick={() => { if (Comm.list.length > 0) {handleShow()} else {routeChange("/search")}} }>
                    <span className="badge modal-community-count">{"+"+Comm.list.length}</span>
                    <a><i className="fa fa-users fa-2x" aria-hidden="true"></i></a>
                </div>
                <button style={{ 'margin': '5px' }} className="btn btn-default" onClick={props.onClose}>Close</button>
            </div>
        </div>
    </>, document.body);

}