import React, { useEffect, useState, useReducer } from 'react';
import { useRouteLoaderData, Link } from "react-router-dom";
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
import { PopoverMenu }  from '../../components/popoverMenu/popoverMenu';
import eventBus from "../../services/eventBus";
import './home.scss';
import { CommunityAddBulkModal } from '../../components/community/modalAddBulkCommunityComponent';
import { useTourManager, useLogging } from '../../services/servicesContext'; 

function formatPhoneNumber(input) {
    const inputStr = `${input}`;//coerce to string
    if (inputStr.length === 10 ) {
        return `(${inputStr.substring(0, 3)}) ${inputStr.substring(3, 6)}-${inputStr.substring(6, 10)}`;
    } else {
        return inputStr;//if not standard length then just return string
    }
}

function entitytypeToName(input) {
    const inputStr = `${input}`;//coerce to string
    if (inputStr === '1') {
        return 'Physician';
    } else if (inputStr === '2') {
        return 'Organization';
    } else {
        return 'unknown';
    }
}

function zipCodeFormat(input) {
    const inputStr = `${input}`;//coerce to string
    if (inputStr.length === 9) {
        return `${inputStr.substring(0, 5)}-${inputStr.substring(5, 9)}`;//format for zip9
    } else {
        return inputStr;//no format for what is likely zip5
    }
}

export function CommunityHomeComponent(props) {

    const tourManager = useTourManager();
    const logging = useLogging();
    const [ loading, setLoading] = useState(null);
    const [ members, setMembers ] = useState([]);
    const [ markers, setMarkers ] = useState([]);
    const community = useRouteLoaderData('community.cid');
    const [isWatched, setIsWatched] = useState(false);
    const [isPublic, setIsPublic] = useState(false);

    const [show, setShow] = useState(false);

    function handleClose () {
        if (show) {
            setShow(false);
        }
    }

    function handleShow () {
        if (!show) {
            setShow(true);
        }
    }

    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'Home - 1/6',
            text: "This is the Community's home page."
        },{
            id: 2,
            attachTo: { element: ".tourscript-removemember", on: 'bottom' },
            title: 'Home - 2/6',
            text: "Click here to remove a member from your Community",
        },{
            id: 3,
            attachTo: { element: '.tourscript-addmember', on: 'bottom' },
            title: 'Home - 3/6',
            text: "Use this to add more members to your Community.",
        },{
            id: 4,
            attachTo: { element: '#tourscript-communityreports', on: 'right' },
            title: 'Home - 4/6',
            text: "There are many reports here to give you insight into the loyalty patterns, referral trends and general behavior of your Community.",
        },{
            id: 5,
            attachTo: { element: '#tourscript-communitygraphs', on: 'right' },
            title: 'Home - 5/6',
            text: "There are also plenty of graphs to keep you busy with insighful information.",
        },{
            id: 6,
            attachTo: { element: '#tourscript-communities', on: 'right' },
            title: 'Home - 6/6',
            text: "Click here to view all of your Communities",
        }]);
        logging.routeLoad({
            pathname: location.pathname,
            npis: [],
            statename: 'root.app.com.home'
        });
        function init() {
            setLoading(true);
            setIsWatched(community.watched);
            setIsPublic(community.is_public);
            updateMembers();
                
            
        }    
        init();

        return () => {
            tourManager.clearTour();
        };

    }, [community]);

    function updateMembers() {
        var memberResolve;
            community.ResolveMembers(props.api)
            .then(members => {
                var memberList;
                memberResolve = members;
                memberList = members.map(function(member) {
                    if (!member._failed) {
                        return {
                            'npi': member.npi,
                            'name': member.name.display,
                            'specialty': member.taxonomy.specialization ? member.taxonomy.classification + ' (' + member.taxonomy.specialization + ')' : member.taxonomy.classification,
                            'address': member.location.address.join(', '),
                            'city': member.location.city,
                            'state': member.location.state,
                            'county': member.location.county,
                            'zip': member.location.postal_code,
                            'phone': member.location.phone_number,
                            'entitytype': member.entitytype,
                            'entitytypename': entitytypeToName(member.entitytype)
                        };
                    } else {
                        return {
                            'npi': member.npi,
                            'name': 'provider not found',
                            'specialty': '',
                            'address': '',
                            'city': '',
                            'state': '',
                            'county': '',
                            'zip': '',
                            'phone': '',
                            'entitytype': '',
                            'entitytypename': ''
                        };
                    }
                });
                setMembers(memberList);
                setLoading(false);
                creatMap(memberResolve);
                eventBus.dispatch("recentlySelectedChangeCommunities");
            },err => {
                console.error('error resolving community members', err);
            });
    };

    function creatMap(memberResolve) {
        if (map != undefined) { map.remove(); }

        var markers = createAndBoundMarkers(memberResolve);

        const center = {
            lat:  39.8283,
            lng:  98.5795
        }

        var stamenAttribution = 'Map tiles by <a href="//stamen.com" target="_blank">Stamen Design</a>, under <a href="//creativecommons.org/licenses/by/3.0" target="_blank">CC BY 3.0</a>. Data by <a href="//openstreetmap.org" target="_blank">OpenStreetMap</a>, under <a href="//creativecommons.org/licenses/by-sa/3.0" target="_blank">CC BY SA</a>';
        var openStreetMapAttribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
        
        var tileOptions = {
            maxZoom: 19,
            subdomains: ['a','b','c','d']
        };
    
        var streets = new L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: openStreetMapAttribution}, tileOptions);
        
        var baseMaps = {
            "OpenStreetMap": streets
        };
        
    
        const map = L.map('map', {
            center: [center.lat, center.lng],
            zoom: 12
        });                 

        var markersVal = new window.L.MarkerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true
        });
    
        var layerControl = L.control.layers(baseMaps).addTo(map);

        baseMaps['OpenStreetMap'].addTo(map);

        let markersArray = [];

        markers.forEach(function (markerItem, index) {
            let marker = L.marker([markerItem.lat, markerItem.lng])
            .bindPopup(markerItem.message)
            markersVal.addLayer(marker)
            markersArray.push(marker);
          });  
        
        var group = new L.featureGroup(markersArray);

        map.fitBounds(group.getBounds());
        map.addLayer(markersVal);
    }

    
    function createAndBoundMarkers(members) {
        var markersArray = [];
        var markersList = {};
        // new date for use in unique group name
        var date = new Date();
        members.forEach(function(member) {
            if (!member._failed) {
                markersList = {
                    group: 'providers' + date,
                    lat: member.location.coordinates.lat,
                    lng: member.location.coordinates.lng,
                    message: member.name.display,
                    focus: true,
                    draggable: false
                };
                markersArray.push(markersList);
            }
        });
        return markersArray;
    }


    const reportOwnedConfig = function() { 
        return {  
            tableConfig :  [
                {
                    "header": {
                        "id": "col0",
                        "accessor": "remove",
                        "content": "Remove",
                        "defaultVisibilty": true,
                        "export": false,
                        "sortable": false,
                        "template": function(content, rowDataObject) {
                            const provider = { 
                                'npi': rowDataObject.npi,
                                'name': {'display' : rowDataObject.name }
                            } 
                            return <button className="btn btn-default btn-sm delete tourscript-removemember" onClick={() => deleteMember(provider)}>
                            <i className="fa fa-minus-circle fa-lg" aria-hidden="true"></i>
                        </button>
                        }
                    },
                    "column": {
                            "class": "btn btn-default btn-sm delete tourscript-removemember",
                            "content": "button",
                            "style": "",
                            "id": 0 
                        }
                },
                {
                    "header": {
                        "id": "col1expanded",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.phone && rowDataObject.phone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                'postal_code' : rowDataObject.zip,
                                'phone_number': phone,
                                'city': rowDataObject.city,
                                'state': rowDataObject.state
                                },
                                'name': {'display' : rowDataObject.name }
                            } 
                            return <PopoverDetails provider={provider}><Link to={`/physician/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                  
                    }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "name",
                        "content": "Name",
                        "headerTooltip": "Row Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": "asc",
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "specialty",
                        "content": "Specialty",
                        "headerTooltip": "Row Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "entitytypename",
                        "content": "Type",
                        "headerTooltip": "Provider type of the community member",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 4
                    }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "address",
                        "content": "Address",
                        "headerTooltip": "Address of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "city",
                        "content": "City",
                        "headerTooltip": "City of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "state",
                        "content": "State",
                        "headerTooltip": "State of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "county",
                        "content": "County",
                        "headerTooltip": "County of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "zip",
                        "content": "ZIP",
                        "headerTooltip": "ZIP code of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => zipCodeFormat(content)
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                }
            ],
            configure : true,
            configureButton: true,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            localStorageName: 'reports.home.community.owned.v-2',
            reportTitle: `${community.name}`,
            csvdDownloadTemplate: function() { return null }
        }
        
    }

    const reportUnOwnedConfig = function() { 
        return {  
            tableConfig :  [
                {
                    "header": {
                        "id": "col1expanded",
                        "accessor": "npi",
                        "content": "NPI",
                        "defaultVisibilty": true,
                        "tooltip" : true,
                        'tooltipType': 'details',
                        "export": true,
                        "sortable": true,
                        "template": function(content, rowDataObject) { 
                            const formatPhoneNum = rowDataObject && rowDataObject.phone && rowDataObject.phone.toString() || '';
                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                            const provider = { 
                                location : {
                                'postal_code' : rowDataObject.zip,
                                'phone_number': phone,
                                'city': rowDataObject.city,
                                'state': rowDataObject.state
                                },
                                'name': {'display' : rowDataObject.name }
                            } 
                            return <PopoverDetails provider={provider}><Link to={`/physician/${content}/home`}>{content}</Link></PopoverDetails>  
                        }  
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 1                  
                    }
                },
                {
                    "header": {
                        "id": "col2",
                        "accessor": "name",
                        "content": "Name",
                        "headerTooltip": "Row Name",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "sort": "asc",
                        "template": function(content, rowObject) { 
                            const provider = {  name : content, npi : rowObject.npi, entitytype: rowObject.entitytype };
                            return <>
                                <PopoverMenu provider={provider}>
                                    <span className='tourscript-name'>{content}</span>
                                    <span style={{ 'cursor': 'pointer' }} className="pull-right">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </span>
                                </PopoverMenu>
                            </> 
                        }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 2
                        }
                },
                {
                    "header": {
                        "id": "col3",
                        "accessor": "specialty",
                        "content": "Specialty",
                        "headerTooltip": "Row Specialty",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 3
                        }
                },
                {
                    "header": {
                        "id": "col4",
                        "accessor": "entitytypename",
                        "content": "Type",
                        "headerTooltip": "Provider type of the community member",
                        "defaultVisibilty": true,
                        "export": true,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 4
                    }
                },
                {
                    "header": {
                        "id": "col5",
                        "accessor": "address",
                        "content": "Address",
                        "headerTooltip": "Address of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 5
                        }
                },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "city",
                        "content": "City",
                        "headerTooltip": "City of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "state",
                        "content": "State",
                        "headerTooltip": "State of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "county",
                        "content": "County",
                        "headerTooltip": "County of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => content
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
                {
                    "header": {
                        "id": "col9",
                        "accessor": "zip",
                        "content": "ZIP",
                        "headerTooltip": "ZIP code of the community member",
                        "defaultVisibilty": false,
                        "export": false,
                        "sortable": true,
                        "template": content => zipCodeFormat(content)
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 9
                        }
                }
            ],
            configure : true,
            configureButton: true,
            showSelected: false,
            filterInput: true,
            csvdownload: true,
            pagination: true,
            localStorageName: 'reports.home.community.unowned.v-2',
            reportTitle: `${community.name}`,
            csvdDownloadTemplate: function() { return null }
        }
    }

    // community actions

    const edit = () => {
        community.EditName({pic: props.pic, api: props.api, updateList: props.CommunityList.update()})
    };

    const watch = async () => {
        await community.Update({watched: isWatched ? false : true},{api: props.api, pic: props.pic})
        setIsWatched(isWatched ? false : true);
    };

    const share = async () => {
        await community.Update({is_public: isPublic ? false : true},{api: props.api, pic: props.pic})
        setIsPublic(isPublic ? false : true);
    };

    function deleteMember(provider) {
        community.RemoveMember({provider : provider},{api: props.api, pic: props.pic, callback: updateMembers})
    };

    return (
        <>
        <div className="row community-home-container">
            <div className="col-lg-8">
                {/*<!--add bulk-->*/}
                {(community.owned ?
                <button className="btn btn-default btn-sm bulk tourscript-addmember" onClick={() => handleShow()}>
                    <span><i className="fa fa-plus fa-lg" aria-hidden="true"></i></span>
                </button> : "")}
                {/*<!--watch-->*/}
                <PopoverDetails providerTooltip={ "Identify members of this community in vizualizatons and reports." } placement="bottom">{
                    <button className={`btn btn-default btn-sm ${isWatched ? 'select-true' : ''}`} onClick={() => watch()} >
                        {(isWatched ? <span><i className="fa fa-eye fa-lg" aria-hidden="true"></i></span> : <span ><i className="fa fa-eye-slash fa-lg" aria-hidden="true"></i></span>)}
                    </button>
                }</PopoverDetails>
                
                {/*<!--share-->*/}
                {(community.owned ?
                    <PopoverDetails providerTooltip={ "Allow other members in your account to view this community." } placement="bottom">{
                    <button className={`btn btn-default btn-sm  ${isPublic ? 'select-true' : ''}`} onClick={() => share()}>
                        <span><i className="fa fa-share-alt fa-lg" aria-hidden="true"></i></span>
                    </button> 
                    }</PopoverDetails>
                : "")}
                {/*<!--edit name and abbr-->*/}
                {(community.owned ?
                <button className="btn btn-default btn-sm edit" onClick={() => edit()}>
                    <span><i className="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i></span>
                </button> : "")}
                {/*<!--copy-->*/}
                {(!community.owned ?
                        <button className="btn btn-default btn-sm" onClick={() => community.Copy({ api: props.api, pic: props.pic, callback: updateMembers })}>
                    <i className="fa fa-files-o fa-lg" aria-hidden="true"></i>
                </button> : "")}
                <PhtableComponent
                    data={members}
                    reportconfig={community.owned ? reportOwnedConfig : reportUnOwnedConfig}
                    download={props.download}
                />
            </div>
            <div className="col-lg-4 com-home-map-container">
                <div id="map" className="comm-home-map" style={{ height: '95%', width: '100%' }}>
                
                </div>
            </div>
        </div>
        
        <CommunityAddBulkModal show={show} onClose={handleClose} updateMembers={updateMembers} community={community} api={props.api} pic={props.pic}/>
        <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
    
        </>
    )
}