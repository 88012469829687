import { set } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useRouteLoaderData } from "react-router-dom";
import { usePopper }  from 'react-popper';
import { useAuth, useRecentlySelected } from '../../services/servicesContext';
import localStorage from '../../services/localStorageModule';

import { PopoverMenu, reportList, useOutsideAlerter }  from '../popoverMenu/popoverMenu';

export function BreadcrumbComponent(props) {
    let currentLocation = useLocation();
    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const comm = useRouteLoaderData('community.cid');
    const [ recentlySelectedVal, setrecentlySelectedVal ] = useState([]);
    const [ entityType, setEntityType ] = useState(null);
    const [ providerTypePath, setProviderTypePath] = useState(null);
    const [ providerIdPath, setProviderIdPath] = useState(null);
    const [ reportPath, setReportPath ] = useState(null);
    const [ provider, setProvider ] = useState(null);
    const [ community, setCommunity ] = useState(null);
    const [ popoverMenuOpen, setPopoverMenuOpen ] = useState(false);
    const [ breadcrumbReportType, setBreadCrumbReportType ] = useState(null);
    const [ isAmbiguous, setIsAmbiguous ] = useState(false);
    const recentlySelected = useRecentlySelected();

    const reportType = {
        carejourney: "Graphs",
        generalflow: "Graphs",
        SpecialtyGraph: "Graphs",
        ReferralMap: "Graphs",
        flow: "Graphs",
        clinicaldashboard: "Graphs",
        trendinggraph: "Graphs",
        MarketMakers: "Graphs",
        ShareByVolume: "Graphs",
        SharedVisitsReport: "Reports",
        bySpecialty: "Reports",
        byOrganization: "Reports",
        ClinicalReport: "Reports",
        Services: "Reports",
        summary: "Reports",
        Prescriptions: "Reports",
        ServiceLine: "Reports",
        preacuteshare: "Reports",
        postacuteshare: "Reports",
        PCPSpecialistVolumes: "Reports",
        NetworkIntegrity: "Reports",
        marketsharebyzip: "Reports"
    };

    const entityObj = useMemo(() => {
        const retObj = {};
        const splitLocationPathname = location.pathname.split('/');

        retObj.typePath = splitLocationPathname[1];
        retObj.idPath = splitLocationPathname[2];
        retObj.reportPath = splitLocationPathname[3];
        retObj.breadCrumbReportType = reportType[splitLocationPathname[3]];
        if (npis) {
            retObj.entityType = npis.entitytype == 1 ? 'Physician' : 'Organization'; 
        } else {
            retObj.entityType = 'Communities';
        }
        return retObj;
    }, [npis, comm, currentLocation]);

    useEffect(() => {
        async function getRecentSelections() {
            try {
                if (npis && npis.entitytype == 1) {
                    const localrecentlySelected = await props.recentlySelected.getRecent({entitytype:'physician', n:11});
                    setrecentlySelectedVal(localrecentlySelected);
                } else if (npis && npis.entitytype == 2) {
                    const localrecentlySelected = await props.recentlySelected.getRecent({entitytype:'organization', n:11});
                    setrecentlySelectedVal(localrecentlySelected);
                } else {
                    const localrecentlySelected = await props.recentlySelected.getRecent({entitytype:'community', n:11});
                    setrecentlySelectedVal(localrecentlySelected);
                }
            } catch(err) {
                console.error('error getting breadcrummb recentlyselected', err);
            }            
        }
        getRecentSelections();
    }, [npis, comm, currentLocation]);

    return (isAmbiguous ? "" : <div id="breadcrumb">
        <div className="app-breadcrumb">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    { recentlySelectedVal.length > 0
                        ? <li className="breadcrumb-item pointer">
                            <RecentProvidersPopover
                                recentlySelected={recentlySelectedVal}
                                reportPath={entityObj.reportPath}
                                entityType={entityObj.entityType}
                            >
                                {entityObj.entityType}
                                {' '}
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                            </RecentProvidersPopover>
                          </li>
                        : <li className="breadcrumb-item pointer">{entityObj.entityType}</li>
                    }
                    { npis && <li className="breadcrumb-item"><Link to={`/${npis.entitytype == 1 ? 'physician' : 'organization'}/${npis.npi}/home`}>{npis.name.display} ({npis.npi})</Link></li>}
                    { comm && <li className="breadcrumb-item"><Link to={`/community/${comm.id}/home`}>{comm.name} ({comm.abbr})</Link></li>}
                    <li className="breadcrumb-item pointer">
                        {npis && <PopoverMenu
                            provider={{name: npis.name.display, npi: npis.npi, entitytype: npis.entitytype}}
                            isOpenCallback={setPopoverMenuOpen}
                        >
                            {entityObj.breadCrumbReportType}
                            {' '}
                            <i className={`fa fa-angle-${popoverMenuOpen ? 'down' : 'left' }`} aria-hidden="true"></i>
                        </PopoverMenu>}
                        {comm && <PopoverMenu
                            providerCollection={comm}
                            isOpenCallback={setPopoverMenuOpen}
                        >
                            {entityObj.breadCrumbReportType}
                            {' '}
                            <i className={`fa fa-angle-${popoverMenuOpen ? 'down' : 'left' }`} aria-hidden="true"></i>
                        </PopoverMenu>}
                    </li>
                    { entityObj.reportPath && <li className="breadcrumb-item active" aria-current="page">{ (entityObj.reportPath == "dashboards" ?  (npis ? "Physician " : (comm ? "Community " : "")) + reportList.find(item => item.url === entityObj.reportPath)?.displayName : reportList.find(item => entityObj.reportPath === "marketbenchmarks" ? comm ? item.url === entityObj.reportPath && item.providerTypes.includes("community") : item.url === entityObj.reportPath && item.providerTypes.includes("physician") : item.url === entityObj.reportPath)?.displayName) }</li>}
                </ol>
            </nav>
        </div>
    </div>);
}

function RecentProvidersPopover(props) {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    const [showPopperElem, setShowPopperElem] = useState(false);
    const [refPopperElem, seRefPopperElem] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }, {name: 'offset', options: {offset: [0,5]}}],
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        marginBottom: 0,
        fontSize: '.8em',
        zIndex: 1000
    };

    const poppertooltipElemStyles = {
        backgroundColor: 'black',
        borderRadius: '5px',
        marginBottom: '2px',
        fontSize: '0.8em',
        color: '#ffffff',
        width: '200px',
        padding: '5px 10px',
        textAlign: 'center',
        border: 'none'
    }

    useOutsideAlerter(popperElement, setShowPopperElem)

    const toggleShowPopperEl = (e) => {
        if (refPopperElem) {
            refPopperElem.click()
        }
        seRefPopperElem(e.currentTarget);

        setShowPopperElem(!showPopperElem);
        
    }

    return <>
        <span
            ref={setReferenceElement}
            onClick={(e) => toggleShowPopperEl(e)}
        >{ props.entityType
            ? <>{props.entityType}{' '}<i className={`fa fa-angle-${showPopperElem ? 'down' : 'left'}`} aria-hidden="true"></i></>
            : props.children 
        }</span>
        { props.recentlySelected && showPopperElem ? <div ref={setPopperElement} id="tooltip" className="panel panel-default recently-selected-popover" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
                <div className="panel-heading">
                    <h5 style={{margin:0}}>
                        Recently Selected {props.entityType}
                    </h5>
                </div>
                {props.recentlySelected && props.recentlySelected.map(recent => {
                    let path;
                    let name;
                    let id;
                    if (recent.entitytype == '1') {
                        path = `/physician/${recent.npi}/${props.reportPath}`;
                        name = recent.name.display;
                        id = recent.npi;
                    } else if (recent.entitytype == '2') {
                        path = `/organization/${recent.npi}/${props.reportPath}`;
                        name = recent.name.display;
                        id = recent.npi;
                    } else {
                        path = `/community/${recent.id}/${props.reportPath}`;
                        name = recent.name;
                        id = recent.abbr;
                    }
                    return <div>
                        <Link to={path}>{name} ({id})</Link>
                    </div>;
                })}
                <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow, backgroundColor: '#f5f5f5'}} {...attributes.arrow}/>
            </div> : null }
    </>;
}