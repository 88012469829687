import * as d3 from 'd3';
import * as parseD from 'd-path-parser';
import tourConfigPhys from './tourConfigPhys';
import tourConfigOrg from './tourConfigOrg';
import tourConfigCom from './tourConfigCom';

export default ['$scope', 'api', 'notify', '$state', '$window', 'Taxonomy', '$sce', '$templateCache', '$compile', 'tour', '$timeout', function($scope, api, notify, $state, $window, Taxonomy, $sce, $templateCache, $compile, tour, $timeout) {
    var $ctrl = this;
    
    var allStringVal = '-- All Filters Below --';
    
    var relationsPromise;
    var seed;
    
    var flowTooltipTemplate = $templateCache.get('ng-template/sankeyFlowToolTip.html');

    var options = $ctrl.options = {
        colorLegend: true,
        color: {
            scale: d3.scaleOrdinal(d3.schemeCategory20),
            value: function(d) {
                return this.scale(d);
            }
        },
        node: {
            colorValue: function(d){
                if (d.taxonomy) {// a provider
                    return d.taxonomy.toString();
                } else if (d.name) {// a community
                    return d.name;
                } else {// fallback
                    return '';
                }
            },
            label: function(d){
                if (d.entitytype == 2) {
                    return d.name.display+' ('+d.npi+')';
                } else if (d.entitytype == 1) {
                    return d.name.primary.slice(0,1)+'. '+d.name.secondary+' ('+d.npi+')'; 
                } else {// community
                    return d.name;
                }
            }
        },
        link: {
            value: function(d) {
                return d.values.shared;
            },
            valueSet: function(d, val) {
                d.values.shared = val;
            },
            reversedClass: 'reversed',
            arrows: {
                directedOffset: 20,
                calcArrowHeadPoints: function(d){
                    var startPoint;
                    if (d._reversed) {
                        startPoint = parseD(d.path)[0].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x - options.link.arrows.directedOffset},${startPoint.y} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    } else {
                        startPoint = parseD(d.path)[1].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x + options.link.arrows.directedOffset},${startPoint.y} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    }
                },
                calcArrowTailPoints: function(d){
                    var startPoint;
                    if (d._reversed) {
                        startPoint = parseD(d.path)[1].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x + options.link.arrows.directedOffset},${startPoint.y - (d.width/2)} ${startPoint.x + options.link.arrows.directedOffset},${startPoint.y + (d.width/2)} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    } else {
                        startPoint = parseD(d.path)[0].end;
                        return `${startPoint.x},${startPoint.y - (d.width/2)} ${startPoint.x - options.link.arrows.directedOffset},${startPoint.y - (d.width/2)} ${startPoint.x - options.link.arrows.directedOffset},${startPoint.y + (d.width/2)} ${startPoint.x},${startPoint.y + (d.width/2)}`;
                    }
                },
                appendArrowHeads: function(selection){
                    return selection.append('polygon')
                        .attr('class', 'link-arrow-head')
                        .attr('points', options.link.arrows.calcArrowHeadPoints);
                },
                appendArrowTails: function(selection){
                    return selection.append('polygon')
                        .attr('class', 'link-arrow-tail')
                        .attr('points', options.link.arrows.calcArrowTailPoints);
                }
            },
            animatedDashes: {
                duration: 5,
                maxOffset: 10,
                percentageOffset: 1,
                sankeyPath: function(link) {
                  let path = '';
                  if (link.circular) {
                    path = link.circularPathData.path;
                  } else {
                    var normalPath = d3.linkHorizontal()
                      .source(function (d) {
                        let x = d.source.x0 + (d.source.x1 - d.source.x0);
                        let y = d.y0;
                        return [x, y];
                      })
                      .target(function (d) {
                        let x = d.target.x0;
                        let y = d.y1;
                        return [x, y];
                      });
                    path = normalPath(link);
                  }
                  return path;
                },
                appendDashes: function (selection) {
                    
                    let dashes = selection.append("path")
                        .attr("d", options.link.animatedDashes.sankeyPath)
                        .style("stroke-width", d => {
                            if (d.width/10 > 10) {
                                return 10;
                            } else if (d.width/10 < 1) {
                                return  1;
                            } else {
                                return d.width/10;
                            }
                            
                        });
                    
                }
            },
            class: function(d){
                if (d._reversed) {
                    return 'reversed';
                } else {
                    return '';
                }
            }
        },
        toolTip: {
            template: flowTooltipTemplate,
            sourceName: function(d){
                if (d._reversed) {
                    return d.source_name || d.target.toString();
                } else {
                    return d.source_name || d.source.toString();
                }
            },
            targetName: function(d){
                if (d._reversed) {
                    return d.target_name || d.source.toString();
                } else {
                    return d.target_name || d.target.toString();
                }
            },
            arrow: function(d){
                if (d._reversed) {
                    return $sce.trustAsHtml("&larr;");
                } else {
                    return $sce.trustAsHtml("&rarr;");
                }
            },
            hasReversedLinks: function(d) {
                if (d) {
                    return (d.sourceLinks.filter(link => link._reversed).length > 0) || (d.targetLinks.filter(link => link._reversed).length > 0);
                } else {
                    return false;
                }
            },
            calcSharedTotal: function(d, sharedVisitType){
                if (sharedVisitType == 'recieves') {
                    return [d.targetLinks, d.sourceLinks].reduce((total, relations) => {
                        total += relations.reduce((relationsTotal, relation) => {
                            if (relation._reversed && (d == relation.source)) {
                                relationsTotal += relation.valueOf();
                            }
                            if (!relation._reversed && (d == relation.target)) {
                                relationsTotal += relation.valueOf();
                            }
                            return relationsTotal;
                        }, 0);
                        return total;
                    }, 0);
                } else if (sharedVisitType == 'refers') {
                    return [d.targetLinks, d.sourceLinks].reduce((total, relations) => {
                        total += relations.reduce((relationsTotal, relation) => {
                            if (relation._reversed && (d == relation.target)) {
                                relationsTotal += relation.valueOf();
                            }
                            if (!relation._reversed && (d == relation.source)) {
                                relationsTotal += relation.valueOf();
                            }
                            return relationsTotal;
                        }, 0);
                        return total;
                    }, 0);
                } else {
                    return false;
                }
            }
        }
    };
    
    $ctrl.hasName = function(){
        return function(value){
            return value.name !== undefined;
        };
    };
    
    $ctrl.getBgColor = function(specClass){
        if (specClass !== allStringVal) {
            return { 'background-color': $ctrl.options.color.value(specClass) };
        } else {
            return { 'background-color': 'none'};
        }
        
    };
    
    function areNonAllControlsInactive(controlObj){// returns true if there aren't any active filters other than All
        return Object.keys(controlObj).filter(function(key) {
            return key !== allStringVal && controlObj[key].active === true;
        }).length === 0;
    }
    
    $ctrl.toggleFilters = function(classSpecName){
        
        setFilterState(classSpecName);
        
        let activeRelations = Object.keys($ctrl.taxonomyFilterControls).reduce((acc, key) => {
            if ($ctrl.taxonomyFilterControls[key].active) {
                $ctrl.taxonomyFilterControls[key].relations.forEach(relation => {
                    acc.push(relation);
                });
            }
            return acc;
        },[]);
        
        if (!$ctrl.reportSankey) {// for standalone
            $scope.data = buildGraphRelations(activeRelations);
        } else {
            $ctrl.reportSankey.data(buildGraphRelations(activeRelations));
        }
        
    };
    
    function setFilterState(classSpecName){
        if (classSpecName === allStringVal && $ctrl.taxonomyFilterControls[allStringVal].active === true) {
            for (var item in $ctrl.taxonomyFilterControls) {
                if (item !== allStringVal && $ctrl.taxonomyFilterControls[item].active === true) {
                    $ctrl.taxonomyFilterControls[item].active = false;
                }
            }
        } else if ($ctrl.taxonomyFilterControls[allStringVal].active === true && $ctrl.taxonomyFilterControls[classSpecName].active === true) {
            $ctrl.taxonomyFilterControls[allStringVal].active = false;
        } else if ($ctrl.taxonomyFilterControls[allStringVal].active === false && areNonAllControlsInactive($ctrl.taxonomyFilterControls)) {
            $ctrl.taxonomyFilterControls[allStringVal].active = true;
        }
    }
    
    function balanceRelations(relations) {
        
        var balancedRelations = relations.reduce((providers, relation) => {
            
            relation.source._outbound = ( relation.source._outbound || 0 ) + options.link.value(relation);
            relation.target._inbound = ( relation.target._inbound || 0 ) + options.link.value(relation);
            
            if (providers.indexOf(relation.source) > -1 && relation.source.valueOf() != seed.valueOf() ) {
                relation.source._relations.push(relation);
            }
            
            if (providers.indexOf(relation.target) > -1 && relation.target.valueOf() != seed.valueOf() ) {
                relation.target._relations.push(relation);
            }
        
            if (providers.indexOf(relation.source) == -1 && relation.source.valueOf() != seed.valueOf() ) {
                relation.source._relations = [relation];
                providers.push(relation.source);
            }
                
            if (providers.indexOf(relation.target) == -1 && relation.target.valueOf() != seed.valueOf() ) {
                relation.target._relations = [relation];
                providers.push(relation.target);
            }
            
            return providers;
        }, []).reduce((sets, provider, index) => {//alternate dumping in left or right side
            if (index % 2 === 0) {
                sets.left.push(provider);
            } else {
                sets.right.push(provider);
            }
            return sets;
        }, {
            left: [],
            right: []
        });
        
        balancedRelations.left.sort((a, b) => {
            return ((a._outbound || 0) + (a._inbound || 0)) < ((b._outbound || 0) + (b._inbound || 0)) ? 1 : -1;
        });
        
        balancedRelations.right.sort((a, b) => {
            return ((a._inbound || 0) + (a._outbound || 0)) < ((b._inbound ||0) + (b._outbound || 0)) ? 1 : -1;
        });
        
        return balancedRelations;
        
    }
    
    function filterDisplayRelations(providers) {
        var myVisibleProviders = {};
        myVisibleProviders.left = providers.left.reduce((obj, provider) => {
            
            obj.total += provider._outbound ? provider._outbound : ((provider._outbound || 0) + (provider._inbound || 0));
            
            if ( ((provider._outbound || (provider._outbound || 0) + (provider._inbound || 0)) / obj.total) > .05 )
                obj.providers.push(provider);
            
            return obj;
        }, { 
            total: 0.0, 
            providers: []
        }).providers;
        
        myVisibleProviders.right = providers.right.reduce((obj, provider) => {
            
            obj.total +=  provider._inbound ? provider._inbound : ((provider._outbound || 0) + (provider._inbound || 0));
            
            if ( ((provider._inbound || (provider._outbound || 0) + (provider._inbound || 0)) / obj.total) > .05 )
                obj.providers.push(provider);
            
            return obj;
        }, {
            total: 0.0,
            providers: []
        }).providers;
        
        return myVisibleProviders;
    }
    
    function buildGraphRelations(data) {//data is all qualifying relations before balance and filter
        
        if (seed instanceof api.Community) {
            
            data = data.reduce((acc, relation) => {
                if (seed.npis.indexOf(relation.source.valueOf()) > -1) {// source is the seed
                    let accKey = `${seed.valueOf()}${relation.target.valueOf()}`;
                    if (!acc[accKey]) {
                        acc[accKey] = new api.Relation({
                            source: seed,
                            target: relation.target,
                            values: {
                                sameday: relation.values.sameday,
                                shared: relation.values.shared,
                                unique: relation.values.unique,
                            },
                            relations: [relation]
                        });
                    } else {
                        acc[accKey].values.sameday += relation.values.sameday;
                        acc[accKey].values.shared += relation.values.shared;
                        acc[accKey].values.unique += relation.values.unique;
                        acc[accKey].relations.push(relation);
                    }
                } else {// target is the seed
                    let accKey = `${relation.source.valueOf()}${seed.valueOf()}`;
                    if (!acc[accKey]) {
                        acc[accKey] = new api.Relation({
                            source: relation.source,
                            target: seed,
                            values: {
                                sameday: relation.values.sameday,
                                shared: relation.values.shared,
                                unique: relation.values.unique,
                            },
                            relations: [relation]
                        });
                    } else {
                        acc[accKey].values.sameday += relation.values.sameday;
                        acc[accKey].values.shared += relation.values.shared;
                        acc[accKey].values.unique += relation.values.unique;
                        acc[accKey].relations.push(relation);
                    }
                }
                return acc;
            },{});
            
            data = Object.keys(data).map(key => data[key]);
            
        }
        
        
        var balancedRelations = balanceRelations(data);
        var balancedAndFilteredRelations = filterDisplayRelations(balancedRelations);
        
        [balancedAndFilteredRelations.left, balancedAndFilteredRelations.right].forEach((side, i) => {
            if (i === 0) {//left
                side.forEach(leftProvider => {
                    leftProvider.side = 'left';
                });
            } else {//right
                 side.forEach(rightProvider => {
                    rightProvider.side = 'right';
                });
            }
        });
        
        var dataSubsetReversed = [balancedAndFilteredRelations.left, balancedAndFilteredRelations.right].reduce((acc, providers, i) => {
            
            var reversedRelations = providers.reduce((relsAcc, provider) => {
                var providerRels = provider._relations.map(relation => {
                    if (i == 0 && relation.source == seed) {
                        return {
                            _reversed: true,
                            source: provider,
                            target: seed,
                            __proto__: relation
                        };
                    } else if (i == 1 && relation.target == seed) {
                        return {
                            _reversed: true,
                            source: seed,
                            target: provider,
                            __proto__: relation
                        };
                    } else {
                        return relation;
                    }
                });
                providerRels.forEach(relation => relsAcc.push(relation));
                return relsAcc;
            }, []);
            
            reversedRelations.forEach(relation => acc.push(relation));
            return acc;
            
        }, []);
        
        return dataSubsetReversed;
    
    }
    
    function bindSankeyMouseClick($scope){
            
        $scope.$on('sankey.node.click', function(event, d3Event, datum, context) {
            if (datum instanceof api.Provider) {
                var newTabUrl = $state.href('root.app.provider', {npis: [datum.npi], path:'home'});
                if (newTabUrl) {// for standalone
                    $window.open(newTabUrl, '_blank');
                } 
            } 
        });
        
    }
    
    bindSankeyMouseClick($scope);
    
    $ctrl.$onInit = function() {
        
        if (!$ctrl.reportSankey) {// for standalone
            $scope.data = [];
        }
        
        $scope.provider = seed = $ctrl.community || $ctrl.npis[0];
        
        if (seed instanceof api.Physician){//set physician tour config
            tour.setScript(tourConfigPhys($timeout), $scope);
        } else if (seed instanceof api.Organization) {//set organization tour config
            tour.setScript(tourConfigOrg($timeout), $scope);
        } else {//set community tour config
            tour.setScript(tourConfigCom($timeout), $scope);
        }
        
        if ($ctrl.npis) {
            relationsPromise = api.Relations(seed);
        } else {
            relationsPromise = api.Relations(seed.npis.join());
        }
        
        relationsPromise.then(function(data){
            
            $ctrl.taxonomyFilterControls = data.reduce(function(hash, relation){
                
                var name;
                
                if (relation.source == seed || (seed.npis && seed.npis.indexOf(relation.source.valueOf()) > -1)) {//relation.source in seed
                    name = relation.target.taxonomy.toString();
                    if (!hash[name]) {
                        hash[name] = {
                            relations: [],
                            active: false,
                            name: name
                        };
                    }
                } else {//relation.target in seed
                    name = relation.source.taxonomy.toString();
                    if (!hash[name]) {
                        hash[name] = {
                            relations: [],
                            active: false,
                            name: name
                        };
                    }
                }
                    
                hash[name].relations.push(relation);
                hash[allStringVal].relations.push(relation);
                return hash;
            },{
                [allStringVal]: {
                    relations: [],
                    active: true, //all is active on route load
                    name: allStringVal
                }
            });
            
            var dataSubsetToRender = buildGraphRelations(data);
            

            if($ctrl.reportSankey) {
                $ctrl.reportSankey.options($ctrl.options);
                $ctrl.reportSankey.data(dataSubsetToRender);
            } else {
                $scope.options = $ctrl.options;
                $scope.data = dataSubsetToRender;
            }
            
            $scope.loaded = true;
        }, function(err){
            console.log('Error fetching Flow Graph data: ', err);
            $scope.hasDataFetchError = true;
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Flow Graph data.',
                delay: 30000
            });
            $ctrl.reportSankey.data([]);//needed to get sankey loading spinner to stop
        });
        
    };
}];