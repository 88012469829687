export default function(nameStr, opt) {
  let dashboardRaw;
  let dashboardName;
  let dashboardMeta;
  try {
    dashboardRaw = nameStr.split('||');
    dashboardName = dashboardRaw[0];
    dashboardMeta = JSON.parse(dashboardRaw[1]);
  } catch (err) {
    console.log('error parsing dashboard name', err);
  }
  
  if (opt === 'name') {
    return dashboardName;
  } else if (opt === 'meta') {
    return dashboardMeta;
  } else {
    dashboardMeta.name = dashboardName;
    return dashboardMeta;
  }
  
}