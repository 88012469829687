import React, { useState, useEffect } from 'react';
import { useRouteLoaderData, Link } from "react-router-dom";
import { extent } from 'd3';
import { useTourManager, useLogging } from '../../services/servicesContext';
import { PopoverDetails } from '../../components/popoverDetails/popoverDetails';

export function MarketSharebyZipComponent({ api, download, $timeout }) {

    const npis = (useRouteLoaderData('physician.npis')) ? useRouteLoaderData('physician.npis') : useRouteLoaderData('organization.npis');
    const tourManager = useTourManager();
    const logging = useLogging();

    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const [zipOrder, setZipOrder] = useState("desc");
    const [zipSortField, setZipSortField] = useState("zipMarketShare");

    const [zipMarketShareThreshold, setZipMarketShareThreshold] = useState(5);
    const [zipMarketShareThresholdDirection, setZipMarketShareThresholdDirection] = useState('>=');
    const [zipTotalRelationshipsThreshold, setZipTotalRelationshipsThreshold] = useState(1000);
    const [zipTotalRelationshipsThresholdDirection, setZipTotalRelationshipsThresholdDirection] = useState('>=');
    const [zipSearchText, setZipSearchText] = useState('');

    const [specialtyOrder, setSpecialtyOrder] = useState("desc");
    const [specialtySortField, setSpecialtySortField] = useState("specialtyMarketShare");

    const [specialtySearchText, setSpecialtySearchText] = useState('');
    const [specialtyMarketShareThreshold, setSpecialtyMarketShareThreshold] = useState(5);
    const [specialtyTotalRelationshipsThreshold, setSpecialtyTotalRelationshipsThreshold] = useState(1000);
    const [specialtyTotalRelationshipsThresholdDirection, setSpecialtyTotalRelationshipsThresholdDirection] = useState('>=');
    const [specialtyMarketShareThresholdDirection, setSpecialtyMarketShareThresholdDirection] = useState('>=');

    const [providerOrder, setProviderOrder] = useState("desc");
    const [providerSortField, setProviderSortField] = useState("marketShare");

    const [marketSharebyZipObject, setMarketSharebyZipObject] = useState({});
    const [isCollapsed, setIsCollapse] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const [providerSortPropertyName, setProviderSortPropertyName] = useState('marketShare');
    const [providerSortReverse, setProviderSortReverse] = useState(true);
    
    const [providerMarketShareThreshold, setProviderMarketShareThreshold] = useState(5);
    const [providerMarketShareThresholdDirection, setProviderMarketShareThresholdDirection] = useState('>=');
    const [providerTotalRelationshipsThreshold, setProviderTotalRelationshipsThreshold] = useState(1000);
    const [providerTotalRelationshipsThresholdDirection, setProviderTotalRelationshipsThresholdDirection] = useState('>=');

    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'Market Share by ZIP - 1/6',
            text: "The pupose of the Market Share by ZIP report is to allow you to analyze your facilities physician relationships at a geographic level.<br><br>Use left/right arrow keys to navigate.",
        },{
            id: 2,
            title: 'Market Share by ZIP - 2/6',
            attachTo: { element: ".tourscript-zip-header", on: 'bottom' },
            scrollTo: true,
            text: "Each row is organized by the ZIP code of the provider."
        },{
            id: 3,
            title: 'Market Share by ZIP - 3/6',
            attachTo: { element: ".tourscript-zip-header.open-zip .tourscript-taxonomy-header", on: 'bottom' },
            text: "Drilling down will show the physician specialties that practice in this ZIP code, their market share to your facility, and their total relatioinship volume.",
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    var elementToCheck = document.querySelector('.tourscript-zip-header');

                    if (elementToCheck.classList.contains('open-zip')) {//expanded element exists
                        setTimeout(() => {
                            resolve();
                        });
                    } else {//expanded element doesn't exist
                        setTimeout(() => {
                            elementToCheck.click();
                            setTimeout(()=>{
                                resolve();
                            });
                        });
                    }
                    
                    
                });
            }
        },{
            id: 4,
            title: 'Market Share by ZIP - 4/6',
            attachTo: { element:".tourscript-zip-header.open-zip .open-specialty .tourscript-provider-row", on: 'bottom' },
            text: "One step further, you can see the individual physicians that make up that speciality and their indiviual market shares to the selected facility.",
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    var elementToCheck = document.querySelector(".tourscript-zip-header.open-zip .tourscript-taxonomy-header");
                    if (elementToCheck.classList.contains('open-specialty')) {//if nested expanded element exists
                        setTimeout(() => {
                            resolve();
                        });
                    } else {//if nested expanded doesn't exist
                        setTimeout(() => {
                            elementToCheck.click();
                            setTimeout(()=>{
                                resolve();
                            });
                        });
                    }
                });
            }
        },{
            id: 5,
            title: 'Market Share by ZIP - 5/6',
            attachTo: { element: "div.report-filter-controls", on: 'bottom' },
            scrollTo: true,
            text: "Up top you can filter by individual ZIP Codes you want to see -- The Physician Specialties -- and set minimum/maximum limits for both Market Share Percetage and number of relationships.",
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    var elementToCheck = document.querySelector('div.report-filter-controls.in.collapse');
                    if (elementToCheck) {//if panel open, hightlight
                        setTimeout(()=>{
                            resolve();
                        });
                    } else {//if panel not open, open it, then highlight
                        var elementToClick = document.querySelector('div.showEl');
                        var clickEvent = new Event('click');
                        setTimeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            setTimeout(()=>{
                                resolve();
                            });
                        });
                    }
                });
            },
        },{
            id: 6,
            title: 'Market Share by ZIP - 6/6',
            attachTo: {element: "#download-csv-button", on: 'left' },
            text: "Finally, you can download the individualized results of your analysis to a csv file for further use."
        }]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.${npis.entitytype == '1' ? 'phy' : 'org'}.reports.marketsharebyzip`
        });

        onInit();

        return () => {
            tourManager.clearTour();
        };

    }, [npis])

    const onInit = async () => {
        setLoaded(false);
        await api.Relations(npis.npi, 'inbound', { leakage: true })
            .then(function (dat) {
                
                const _marketSharebyZipObject = {};
                const _data = Object.entries(dat.reduce((acc, rel) => {

                    if (rel.source.entitytype != '1') {//no orgs
                        return acc;
                    }

                    if (rel.values.unique <= 5) {// ignores all relations with low unique patient count, (low quality relations)
                        return acc;
                    }

                    const sourceZip = rel.source.location.postal_code.slice(0, 5);
                    const sourceSpecialty = rel.source.taxonomy.toString();
                    const sourceName = rel.source.name.display;
                    const sourceSharedRelationShips = rel.values.shared;
                    const sourceTotalRelationships = (rel.values.shared + rel.values.leakage.shared);
                    const sourceMarketShare = ((rel.values.shared / sourceTotalRelationships) * 100);

                    if (acc[sourceZip]) {//prior zip defined

                        acc[sourceZip].zipSharedRelationShips += sourceSharedRelationShips;
                        acc[sourceZip].zipTotalRelationships += sourceTotalRelationships;

                        acc[sourceZip].zipMarketShare = ((acc[sourceZip].zipSharedRelationShips / acc[sourceZip].zipTotalRelationships) * 100);

                        if (acc[sourceZip][sourceSpecialty]) {//prior zip and specialty defined

                            acc[sourceZip][sourceSpecialty].specialtySharedRelationShips += sourceSharedRelationShips;
                            acc[sourceZip][sourceSpecialty].specialtyTotalRelationShips += sourceTotalRelationships;

                            acc[sourceZip][sourceSpecialty].specialtyMarketShare = ((acc[sourceZip][sourceSpecialty].specialtySharedRelationShips / acc[sourceZip][sourceSpecialty].specialtyTotalRelationShips) * 100);

                            acc[sourceZip][sourceSpecialty].providers.push({
                                name: sourceName,
                                marketShare: sourceMarketShare,
                                sharedPatientRelationships: sourceSharedRelationShips,
                                totalPatientRelationships: sourceTotalRelationships,
                                __proto__: rel.source
                            });

                        } else {  //prior zip defined but new specialty

                            acc[sourceZip].specialties.push(sourceSpecialty);

                            acc[sourceZip][sourceSpecialty] = {
                                specialtySharedRelationShips: sourceSharedRelationShips,
                                specialtyTotalRelationShips: sourceTotalRelationships,
                                specialtyMarketShare: sourceMarketShare,
                                providers: [
                                    {
                                        name: sourceName,
                                        marketShare: sourceMarketShare,
                                        sharedPatientRelationships: sourceSharedRelationShips,
                                        totalPatientRelationships: sourceTotalRelationships,
                                        __proto__: rel.source
                                    }
                                ]
                            };
                        }
                    } else {//new zip to define
                        acc[sourceZip] = {

                            specialties: [sourceSpecialty],

                            zipTotalRelationships: sourceTotalRelationships,
                            zipSharedRelationShips: sourceSharedRelationShips,
                            zipMarketShare: sourceMarketShare,
                            [sourceSpecialty]: {
                                specialtyTotalRelationShips: sourceTotalRelationships,
                                specialtySharedRelationShips: sourceSharedRelationShips,
                                specialtyMarketShare: sourceMarketShare,
                                providers: [
                                    {
                                        name: sourceName,
                                        marketShare: sourceMarketShare,
                                        sharedPatientRelationships: sourceSharedRelationShips,
                                        totalPatientRelationships: sourceTotalRelationships,
                                        __proto__: rel.source
                                    }
                                ]
                            }
                        };
                    }
                    return acc;
                }, {})).map(([zipCode, zipObj]) => {
                    return {
                        zipCode: zipCode,
                        zipMarketShare: zipObj.zipMarketShare,
                        zipSharedRelationShips: zipObj.zipSharedRelationShips,
                        zipTotalRelationships: zipObj.zipTotalRelationships,
                        specialties: zipObj.specialties.map(specialty => {
                            return {
                                specialty: specialty,
                                specialtyMarketShare: zipObj[specialty].specialtyMarketShare,
                                specialtySharedRelationShips: zipObj[specialty].specialtySharedRelationShips,
                                specialtyTotalRelationShips: zipObj[specialty].specialtyTotalRelationShips,
                                providers: zipObj[specialty].providers
                            };
                        })
                    };
                });

                _marketSharebyZipObject.zipTotalRelationshipsRange = [];
                _marketSharebyZipObject.specialtyTotalRelationshipsRange = [];
                _marketSharebyZipObject.providerTotalRelationshipsRange = [];

                _data.forEach(zip => {
                    _marketSharebyZipObject.zipTotalRelationshipsRange.push(zip.zipTotalRelationships);
                    zip.specialties.forEach(specialty => {
                        _marketSharebyZipObject.specialtyTotalRelationshipsRange.push(specialty.specialtyTotalRelationShips);
                        specialty.providers.forEach(provider => {
                            _marketSharebyZipObject.providerTotalRelationshipsRange.push(provider.totalPatientRelationships);
                        });
                    });
                });

                _marketSharebyZipObject.zipTotalRelationshipsRange = extent(_marketSharebyZipObject.zipTotalRelationshipsRange);
                _marketSharebyZipObject.specialtyTotalRelationshipsRange = extent(_marketSharebyZipObject.specialtyTotalRelationshipsRange);
                _marketSharebyZipObject.providerTotalRelationshipsRange = extent(_marketSharebyZipObject.providerTotalRelationshipsRange);

                                
                // default sort zip marketshare desc
                _data.sort((a, b) => (calcZipMarketShare(a) < calcZipMarketShare(b)) ? 1 : -1);

                // default sort specialties marketshare desc
                _data.forEach(zip => {
                    zip.specialties.sort((a, b) => (calcSpecialtyMarketShare(a) < calcSpecialtyMarketShare(b)) ? 1 : -1);
                });

                // default sort providers marketshare desc
                _data.forEach(zip => {
                    zip.specialties.forEach(specialty => {
                        specialty.providers.sort((a,b) => (a[providerSortPropertyName] < b[providerSortPropertyName]) ? 1 : -1);
                    });
                });
                
                //begin resetting all controls
                setZipOrder("desc");
                setZipSortField("zipMarketShare");

                setZipMarketShareThreshold(5);
                setZipMarketShareThresholdDirection('>=');
                setZipTotalRelationshipsThreshold(1000);
                setZipTotalRelationshipsThresholdDirection('>=');
                setZipSearchText('');

                setSpecialtyOrder("desc");
                setSpecialtySortField("specialtyMarketShare");

                setSpecialtySearchText('');
                setSpecialtyMarketShareThreshold(5);
                setSpecialtyTotalRelationshipsThreshold(1000);
                setSpecialtyTotalRelationshipsThresholdDirection('>=');
                setSpecialtyMarketShareThresholdDirection('>=');

                setProviderOrder("desc");
                setProviderSortField("marketShare");

                setMarketSharebyZipObject({});
                setIsCollapse(true);
                setIsOpen(false);

                setProviderSortPropertyName('marketShare');
                setProviderSortReverse(true);
                
                setProviderMarketShareThreshold(5);
                setProviderMarketShareThresholdDirection('>=');
                setProviderTotalRelationshipsThreshold(1000);
                setProviderTotalRelationshipsThresholdDirection('>=');
                //end resetting all controls

                setLoaded(true);
                setData(_data);
                setMarketSharebyZipObject(_marketSharebyZipObject)
            }, function (err) {
                console.log('error getting relations', err);
                setLoaded(true);
            });
    };

    const zipCodeFilter = function (zipObj) {
        return zipObj.zipCode.includes(zipSearchText);
    };

    const zipMarketShareThresholdFilter = function (zipObj) {
        if (zipMarketShareThresholdDirection === '>=') {
            return calcZipMarketShare(zipObj) >= zipMarketShareThreshold;
        } else {
            return calcZipMarketShare(zipObj) <= zipMarketShareThreshold;
        }
    };

    const zipTotalRelationshipsThresholdFilter = function (zipObj) {
        if (zipTotalRelationshipsThresholdDirection === '>=') {
            return calcZipTotalRelationships(zipObj) >= zipTotalRelationshipsThreshold;
        } else {
            return calcZipTotalRelationships(zipObj) <= zipTotalRelationshipsThreshold;
        }
    };

    const zipHasProviders = function (zipObj) {
        return zipObj.specialties
            .filter(specialtyFilter)
            .filter(specialtyMarketShareThresholdFilter)
            .filter(specialtyTotalRelationshipsThresholdFilter)
            .map(specialty => {
                return specialty.providers
                    .filter(providerMarketShareThresholdFilter)
                    .filter(providerTotalRelationshipsThresholdFilter);
            })
            .flat(Infinity)
            .length > 0;
    };

    const specialtyFilter = function (specialtyObj) {
        return specialtyObj.specialty.toLowerCase().includes(specialtySearchText.toLowerCase());
    };

    const specialtyMarketShareThresholdFilter = function (specialtyObj) {
        if (specialtyMarketShareThresholdDirection === '>=') {
            return calcSpecialtyMarketShare(specialtyObj) >= specialtyMarketShareThreshold;
        } else {
            return calcSpecialtyMarketShare(specialtyObj) <= specialtyMarketShareThreshold;
        }
    };

    const specialtyTotalRelationshipsThresholdFilter = function (specialtyObj) {
        if (specialtyTotalRelationshipsThresholdDirection === '>=') {
            return calcSpecialtyTotalRelationships(specialtyObj) >= specialtyTotalRelationshipsThreshold;
        } else {
            return calcSpecialtyTotalRelationships(specialtyObj) <= specialtyTotalRelationshipsThreshold;
        }
    };

    const specialtyHasProviders = function (specialtyObj) {
        return specialtyObj.providers
            .filter(providerMarketShareThresholdFilter)
            .filter(providerTotalRelationshipsThresholdFilter)
            .length > 0;
    };

    const providerSortBy = function (propertyName) {
        providerSortReverse = (providerSortPropertyName === propertyName) ? !providerSortReverse : false;
        providerSortPropertyName = propertyName;
    };

    const providerMarketShareThresholdFilter = function (providerObj) {
        if (providerMarketShareThresholdDirection === '>=') {
            return providerObj.marketShare >= providerMarketShareThreshold;
        } else {
            return providerObj.marketShare <= providerMarketShareThreshold;
        }
    };

    const providerTotalRelationshipsThresholdFilter = function (providerObj) {
        if (providerTotalRelationshipsThresholdDirection === '>=') {
            return providerObj.totalPatientRelationships >= providerTotalRelationshipsThreshold;
        } else {
            return providerObj.totalPatientRelationships <= providerTotalRelationshipsThreshold;
        }
    };

    const calcZipMarketShare = function (zipObj) {
        var providersTotals = zipObj.specialties
            .filter(specialtyFilter)
            .filter(specialtyMarketShareThresholdFilter)
            .filter(specialtyTotalRelationshipsThresholdFilter)
            .map(specialty => {
                return specialty.providers
                    .filter(providerMarketShareThresholdFilter)
                    .filter(providerTotalRelationshipsThresholdFilter)
                    .map(provider => {
                        return {
                            sharedPatientRelationships: provider.sharedPatientRelationships,
                            totalPatientRelationships: provider.totalPatientRelationships
                        };
                    });
            })
            .flat(Infinity)
            .reduce((acc, provider) => {
                acc[0] += provider.sharedPatientRelationships;
                acc[1] += provider.totalPatientRelationships;
                return acc;
            }, [0, 0]);
        
        if (providersTotals[0] === 0 && providersTotals[1] === 0) {
            return 0;
        } else {
            return ((providersTotals[0]/providersTotals[1]) * 100);
        }
    };

    const calcZipTotalRelationships = function (zipObj) {
        var providersTotal = zipObj.specialties
            .filter(specialtyFilter)
            .filter(specialtyMarketShareThresholdFilter)
            .filter(specialtyTotalRelationshipsThresholdFilter)
            .map(specialty => {
                return specialty.providers
                    .filter(providerMarketShareThresholdFilter)
                    .filter(providerTotalRelationshipsThresholdFilter);
            })
            .flat(Infinity)
            .reduce((acc, provider) => {
                acc += provider.totalPatientRelationships;
                return acc;
            }, 0);

        return providersTotal;
    };

    const calcSpecialtyMarketShare = function (specialtyObj) {
        var providersTotals = specialtyObj.providers
            .filter(providerMarketShareThresholdFilter)
            .filter(providerTotalRelationshipsThresholdFilter)
            .map(provider => {
                return {
                    sharedPatientRelationships: provider.sharedPatientRelationships,
                    totalPatientRelationships: provider.totalPatientRelationships
                };
            })
            .reduce((acc, provider) => {
                acc[0] += provider.sharedPatientRelationships;
                acc[1] += provider.totalPatientRelationships;
                return acc;
            }, [0, 0]);

        if (providersTotals[0] === 0 && providersTotals[1] === 0) {
            return 0;
        } else {
            return ((providersTotals[0]/providersTotals[1]) * 100);
        }
    };

    const calcSpecialtyTotalRelationships = function (specialtyObj) {
        var providersTotal = specialtyObj.providers
            .filter(providerMarketShareThresholdFilter)
            .filter(providerTotalRelationshipsThresholdFilter)
            .reduce((acc, provider) => {
                acc += provider.totalPatientRelationships;
                return acc;
            }, 0);

        return providersTotal;
    };


    const generateReport = function () {
        if (!data) return
        const reportData = data
            .filter(zipCodeFilter)
            .filter(zipMarketShareThresholdFilter)
            .filter(zipTotalRelationshipsThresholdFilter)
            .map(zipObj => {
                return zipObj.specialties
                    .filter(specialtyFilter)
                    .filter(specialtyMarketShareThresholdFilter)
                    .filter(specialtyTotalRelationshipsThresholdFilter)
                    .map(specialty => {
                        return specialty.providers
                            .filter(providerMarketShareThresholdFilter)
                            .filter(providerTotalRelationshipsThresholdFilter)
                            .map(provider => {
                                return {
                                    'NPI': provider.npi,
                                    'Provider Name': provider.name.toString(),
                                    'Classification (Specialization)': provider.taxonomy.toString(),
                                    'ZIP': provider.location.postal_code.slice(0, 5),
                                    'Market Share': (provider.marketShare / 100),
                                    'Total Relationships': provider.totalPatientRelationships
                                };
                            });
                    });
            })
            .flat(Infinity);

        var columnOrder = [
            ['NPI', 0],
            ['Provider Name', 1],
            ['Classification (Specialization)', 2],
            ['ZIP', 3],
            ['Market Share', 4],
            ['Total Relationships', 5]
        ];

        download.downloadCSV(reportData, `Market Share by ZIP Report for ${npis.npi.toString()}`, true, columnOrder);
    };


    const sortByZipColumn = (key, sortorder, e) => {
        if (!data) return;

        const zipCalcLookup = {
            zipCode: d => d.zipCode,
            zipMarketShare: calcZipMarketShare,
            zipTotalRelationships: calcZipTotalRelationships
        };

        const dataCopy = [...data];

        if (sortorder === 'toggle') {
            sortorder = (zipOrder === 'asc') ? 'desc' : 'asc';
        }

        if (sortorder === "asc") {
            dataCopy.sort((a, b) => (zipCalcLookup[key](a) > zipCalcLookup[key](b)) ? 1 : -1);
        }
        if (sortorder === "desc") {
            dataCopy.sort((a, b) => (zipCalcLookup[key](a) < zipCalcLookup[key](b)) ? 1 : -1);
        }

        setData(dataCopy);
        setZipOrder(sortorder);
        setZipSortField(key);
    }

    const sortBySpecialtyColumn = (key, sortorder, e) => {
        if (!data) return;

        const specialtyCalcLookup = {
            specialty: d => d.specialty,
            specialtyMarketShare: calcSpecialtyMarketShare,
            specialtyTotalRelationships: calcSpecialtyTotalRelationships
        };

        const dataCopy = [...data];

        if (sortorder === 'toggle') {
            sortorder = (specialtyOrder === 'asc') ? 'desc' : 'asc';
        }

        if (sortorder === "asc") {
            dataCopy.forEach(zip => {
                zip.specialties.sort((a, b) => (specialtyCalcLookup[key](a) > specialtyCalcLookup[key](b)) ? 1 : -1);
            });

        }
        if (sortorder === "desc") {
            dataCopy.forEach(zip => {
                zip.specialties.sort((a, b) => (specialtyCalcLookup[key](a) < specialtyCalcLookup[key](b)) ? 1 : -1);
            });

        }

        setData(dataCopy);
        setSpecialtyOrder(sortorder);
        setSpecialtySortField(key);

    };

    const sortByProviderColumn = (key, sortorder, e) => {
        if (!data) return;

        const dataCopy = [...data];

        if (sortorder === 'toggle') {
            sortorder = (providerOrder === 'asc') ? 'desc' : 'asc';
        }

        if (sortorder === "asc") {
            dataCopy.forEach(zip => {
                zip.specialties.forEach(specialty => {
                    specialty.providers.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
                });
            });

        }
        if (sortorder === "desc") {
            dataCopy.forEach(zip => {
                zip.specialties.forEach(specialty => {
                    specialty.providers.sort((a, b) => (a[key] < b[key]) ? 1 : -1);
                });
            });

        }

        setData(dataCopy);
        setProviderOrder(sortorder);
        setProviderSortField(key);

    };

    const toggleOpen = (event, id, index = 0, type) => {
        event.stopPropagation();
        if (type === 'tab') {
            const elem = document.getElementById(`accordiongroup-${id}-panel`)
            elem.classList.toggle("in");
        } else {
            const elemList = document.getElementById(`accordiongroup-${id}-${index}-list`)
            elemList.classList.toggle("in");
        }
    }

    const toggleOpenUpdated = (event, zip, specialty) => {
        event.stopPropagation();

        if (zip && !specialty) {
            const updatedData = data.map(zipObj => {
                if (zipObj.zipCode === zip) {//found the zip clicked
                    if (zipObj.open) {//zip already open
                        zipObj.open = false;
                    } else {//else open zip panel
                        zipObj.open = true;
                    }
                }
                return zipObj;
            });
            console.log('updatedData', updatedData);
            setData(updatedData);
        } else if (zip && specialty) {
            const updatedData = data.map(zipObj => {
                if (zipObj.zipCode === zip) {
                    zipObj.specialties.map(specialtyObj => {
                        if (specialtyObj.specialty === specialty) {//found the specialty clicked
                            if (specialtyObj.open) {//specialty already open
                                specialtyObj.open = false;
                            } else {//else open specialty
                                specialtyObj.open = true;
                            }
                        }
                        return specialtyObj;
                    });
                }
                return zipObj;
            });
            console.log('updatedData', updatedData);
            setData(updatedData);
        } else {
            return;
        }
        
    };

    function debounce(func, timeout = 500) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    const changeOperator = (operator, setOperator) => {
        (operator === '>=') ? (operator = '<=') : operator = '>='
        setOperator(operator);
    }

    const minMaxValues = (rangeObject, value) => {
        if (!marketSharebyZipObject || !marketSharebyZipObject[rangeObject]) return
        value === 'max' ? marketSharebyZipObject[rangeObject[1]].toLocaleString() : marketSharebyZipObject[rangeObject[0]].toLocaleString()
        return value
    }

    const closeModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className="loading-lg" style={{ "display": (!loaded ? 'inline' : 'none') }}></div>
            <div className={`row report-filter-controls ${(isCollapsed) ? 'in' : ''} collapse organization-market-share-by-zip`} style={{ backgroundColor: '#eee', padding: '10px 5px', margin: '0 0 10px' }}>
                <div className="col-sm-12">
                    <div className="col-sm-4 form-group">
                        <label>Zip Code : {zipSearchText}</label>
                        <input className="form-control" onChange={(e) => setZipSearchText(e.target.value)} style={{ height: '24px' }} />
                    </div>
                    <div className="col-sm-4 form-group">
                        <div className="col-sm-4">
                            <label>
                                ZIP Code Market Share
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <div className="input-group input-sm">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-xs primary-product-button" onClick={() => changeOperator(zipMarketShareThresholdDirection, setZipMarketShareThresholdDirection)}>
                                        {zipMarketShareThresholdDirection === '>=' ? '>=' : '<='}
                                    </button>
                                </span>
                                <input className="form-control" type="number" value={zipMarketShareThreshold} style={{ height: '22px', display: 'inline-block' }} onChange={(e) => setZipMarketShareThreshold(e.target.value)} />
                                <span className="input-group-btn">
                                    <button className="btn btn-default btn-xs" disabled>%</button>
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <input className="slider" type="range" min="0" max="100" value={zipMarketShareThreshold} onChange={(e) => setZipMarketShareThreshold(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-sm-4 form-group">
                        <div className="col-sm-4">
                            <label>
                                ZIP Code Relationships
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <div className="input-group input-sm">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-xs primary-product-button"
                                        onClick={() => changeOperator(zipTotalRelationshipsThresholdDirection, setZipTotalRelationshipsThresholdDirection)}>
                                        {zipTotalRelationshipsThresholdDirection === '>=' ? '>=' : '<='}
                                    </button>
                                </span>
                                <input className="form-control" type="number" value={zipTotalRelationshipsThreshold} style={{ height: '22px', display: 'inline-block' }} onChange={(e) => setZipTotalRelationshipsThreshold(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            { marketSharebyZipObject.zipTotalRelationshipsRange && <input
                                className="slider"
                                type="range"
                                min={marketSharebyZipObject.zipTotalRelationshipsRange[0]}
                                max={marketSharebyZipObject.zipTotalRelationshipsRange[1]}
                                value={zipTotalRelationshipsThreshold}
                                onChange={(e) => setZipTotalRelationshipsThreshold(e.target.value)}
                            />}
                            <p className="help-block">Min: {marketSharebyZipObject.zipTotalRelationshipsRange && marketSharebyZipObject.zipTotalRelationshipsRange[0].toLocaleString()}, Max: {marketSharebyZipObject.zipTotalRelationshipsRange && marketSharebyZipObject.zipTotalRelationshipsRange[1].toLocaleString()}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <hr className="filter-divider" />
                </div>
                <div className="col-sm-12">
                    <div className="col-sm-4 form-group">
                        <label>Specialty Filter</label>
                        <input className="form-control" onChange={(e) => setSpecialtySearchText(e.target.value)} style={{ height: "24px" }} />
                    </div>

                    <div className="col-sm-4 form-group">
                        <div className="col-sm-4">
                            <label>
                                Specialty Market Share
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <div className="input-group input-sm">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-xs primary-product-button"
                                        onClick={() => changeOperator(specialtyMarketShareThresholdDirection, setSpecialtyMarketShareThresholdDirection)}>
                                        {specialtyMarketShareThresholdDirection === '>=' ? '>=' : '<='}
                                    </button>
                                </span>
                                <input className="form-control" type="number" value={specialtyMarketShareThreshold} style={{ height: '22px', display: 'inline-block' }} onChange={(e) => setSpecialtyMarketShareThreshold(e.target.value)} />
                                <span className="input-group-btn">
                                    <button className="btn btn-default btn-xs" disabled>%</button>
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <input className="slider" type="range" min="0" max="100" value={specialtyMarketShareThreshold} onChange={(e) => setSpecialtyMarketShareThreshold(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-sm-4 form-group">
                        <div className="col-sm-4">
                            <label>
                                Specialty Relationships
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <div className="input-group input-sm">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-xs primary-product-button"
                                        onClick={() => changeOperator(specialtyTotalRelationshipsThresholdDirection, setSpecialtyTotalRelationshipsThresholdDirection)}>
                                        {specialtyTotalRelationshipsThresholdDirection === '>=' ? '>=' : '<='}
                                    </button>
                                </span>
                                <input className="form-control" type="number" value={specialtyTotalRelationshipsThreshold} style={{ height: '22px', display: 'inline-block' }} onChange={(e) => setSpecialtyTotalRelationshipsThreshold(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            { marketSharebyZipObject.specialtyTotalRelationshipsRange &&<input
                                className="slider"
                                type="range"
                                min={marketSharebyZipObject.specialtyTotalRelationshipsRange[0]}
                                max={marketSharebyZipObject.specialtyTotalRelationshipsRange[1]}
                                value={specialtyTotalRelationshipsThreshold}
                                onChange={(e) => setSpecialtyTotalRelationshipsThreshold(e.target.value)}
                            />}
                            <p className="help-block">Min: {marketSharebyZipObject.specialtyTotalRelationshipsRange && marketSharebyZipObject.specialtyTotalRelationshipsRange[0].toLocaleString()}, Max: {marketSharebyZipObject.specialtyTotalRelationshipsRange && marketSharebyZipObject.specialtyTotalRelationshipsRange[1].toLocaleString()}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <hr className="filter-divider" />
                </div>
                <div className="col-sm-12">
                    <div className="col-sm-4 col-sm-offset-4 form-group">
                        <div className="col-sm-4">
                            <label>
                                Provider Market Share
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <div className="input-group input-sm">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-xs primary-product-button"
                                        onClick={() => changeOperator(providerMarketShareThresholdDirection, setProviderMarketShareThresholdDirection)}>
                                        {providerMarketShareThresholdDirection === '>=' ? '>=' : '<='}
                                    </button>
                                </span>
                                <input className="form-control" type="number" value={providerMarketShareThreshold} style={{ height: '22px', display: 'inline-block' }} onChange={(e) => setProviderMarketShareThreshold(e.target.value)} />
                                <span className="input-group-btn">
                                    <button className="btn btn-default btn-xs" disabled>%</button>
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <input className="slider" type="range" min="0" max="100" value={providerMarketShareThreshold} onChange={(e) => setProviderMarketShareThreshold(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-sm-4 form-group">
                        <div className="col-sm-4">
                            <label>
                                Provider Relationships
                            </label>
                        </div>
                        <div className="col-sm-8">
                            <div className="input-group input-sm">
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-xs primary-product-button"
                                        onClick={() => changeOperator(providerTotalRelationshipsThresholdDirection, setProviderTotalRelationshipsThresholdDirection)}>
                                        {providerTotalRelationshipsThresholdDirection === '>=' ? '>=' : '<='}
                                    </button>
                                </span>
                                <input className="form-control" type="number" value={providerTotalRelationshipsThreshold} style={{ height: '22px', display: 'inline-block' }} onChange={(e) => setProviderTotalRelationshipsThreshold(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            { marketSharebyZipObject.providerTotalRelationshipsRange && <input className="slider" type="range" value={providerTotalRelationshipsThreshold}
                                min={marketSharebyZipObject.providerTotalRelationshipsRange && marketSharebyZipObject.providerTotalRelationshipsRange[0]}
                                max={marketSharebyZipObject.providerTotalRelationshipsRange && marketSharebyZipObject.providerTotalRelationshipsRange[1]}
                                onChange={(e) => setProviderTotalRelationshipsThreshold(e.target.value)}
                            />}
                            <p className="help-block">
                                Min: {marketSharebyZipObject.providerTotalRelationshipsRange && marketSharebyZipObject.providerTotalRelationshipsRange[0].toLocaleString()},
                                Max: {marketSharebyZipObject.providerTotalRelationshipsRange && marketSharebyZipObject.providerTotalRelationshipsRange[1].toLocaleString()}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="collapseEl text-center" onClick={() => setIsCollapse(!isCollapsed)}>
                    <i className="fa fa-caret-up fa-lg" aria-hidden="true"></i>
                    <a>Hide</a>
                </div>
            </div>
            <div className='organization-market-share-by-zip'>
                <div className={`collapseEl text-center ${isCollapsed ? 'hideEl' : 'showEl'}`} onClick={() => setIsCollapse(!isCollapsed)}>
                    <i className="fa fa-caret-down fa-lg" aria-hidden="true"></i>
                    <a>Show</a>
                </div>
            </div>

            <div className="row" style={{ margin: 0 }}>
                <button id="download-csv-button" className="btn btn-default pull-right" onClick={() => generateReport()}><i className="fa fa-download"></i> CSV</button>
            </div>
            {/* ================================== end form / begin list ================================== */}
            <div className='row' style={{ padding: '5px 10px' }}>
                <div className="col-sm-1">Show Specialties</div>
                <div
                    className="col-sm-3 pointer"
                    onClick={(e) => sortByZipColumn('zipCode', "toggle", e)}
                >
                    Provider ZIP Code
                    <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                        <span
                            className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(zipSortField === 'zipCode' && zipOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByZipColumn('zipCode', "asc", e)}
                        ></span>
                        <span
                            className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(zipSortField === 'zipCode' && zipOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortByZipColumn('zipCode', "desc", e)}
                        ></span>
                    </div>
                </div>
                <div
                    className="col-sm-4 pointer"
                    onClick={(e) => sortByZipColumn('zipMarketShare', "toggle", e)}
                >
                    Market Share
                    <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                        <span
                            className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(zipSortField === 'zipMarketShare' && zipOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByZipColumn('zipMarketShare', "asc", e)}
                        ></span>
                        <span
                            className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(zipSortField === 'zipMarketShare' && zipOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortByZipColumn('zipMarketShare', "desc", e)}
                        ></span>
                    </div>
                </div>
                <div
                    className="col-sm-4 pointer"
                    onClick={(e) => sortByZipColumn('zipTotalRelationships', "toggle", e)}
                >
                    Total Patient Relationships
                    <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                        <span
                            className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(zipSortField === 'zipTotalRelationships' && zipOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByZipColumn('zipTotalRelationships', "asc", e)}
                        ></span>
                        <span
                            className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(zipSortField === 'zipTotalRelationships' && zipOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortByZipColumn('zipTotalRelationships', "desc", e)}
                        ></span>
                    </div>
                </div>
            </div>

            <div role="tablist" className="panel-group organization-market-share-by-zip">
                {
                    data && data
                        .filter(zipCodeFilter)
                        .filter(zipMarketShareThresholdFilter)
                        .filter(zipTotalRelationshipsThresholdFilter)
                        .filter(zipHasProviders)
                        .map((zipObj) => {
                            return <div id={`accordiongroup-${zipObj.zipCode}-tab`} key={zipObj.zipCode} className={`panel-default panel tourscript-zip-header ${zipObj.open ? 'open-zip': ''}`} onClick={(event) => toggleOpenUpdated(event, zipObj.zipCode)/* toggleOpen(event, zipObj.zipCode, 0, 'tab') */}>
                                <div role="tab" aria-selected="true" className="panel-heading">
                                    <h4 className="panel-title">
                                        <span>
                                            <div className="row">
                                                <div className="col-sm-1"><i className={`glyphicon ${zipObj.open ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right'}`}></i></div>
                                                <div className="col-sm-3">{zipObj.zipCode}</div>
                                                <div className="col-sm-4">{calcZipMarketShare(zipObj).toFixed(2)}%</div>
                                                <div className="col-sm-4">{calcZipTotalRelationships(zipObj).toLocaleString()}</div>
                                            </div>
                                        </span>
                                    </h4>
                                </div>
                                <div>
                                    <div id={`accordiongroup-${zipObj.zipCode}-panel`} role="tabpanel" onClick={(e) => e.stopPropagation()} className={`panel-collapse collapse ${zipObj.open ? 'in' : ''}`} aria-expanded="true">
                                        <div className="panel-body">
                                            <div className="row" style={{ padding: '5px 10px' }}>
                                                <div className="col-sm-1">
                                                    Show Providers
                                                </div>
                                                <div className="col-sm-3 pointer" onClick={(e) => sortBySpecialtyColumn('specialty', 'toggle', e)}>
                                                    Specialty
                                                    <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                                                        <span
                                                            className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(specialtySortField === 'specialty' && specialtyOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortBySpecialtyColumn('specialty', "asc", e)}
                                                        ></span>
                                                        <span
                                                            className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(specialtySortField === 'specialty' && specialtyOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortBySpecialtyColumn('specialty', "desc", e)}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 pointer" onClick={(e) => sortBySpecialtyColumn('specialtyMarketShare', 'toggle', e)}>
                                                    Market Share
                                                    <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                                                        <span
                                                            className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(specialtySortField === 'specialtyMarketShare' && specialtyOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortBySpecialtyColumn('specialtyMarketShare', "asc", e)}
                                                        ></span>
                                                        <span
                                                            className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(specialtySortField === 'specialtyMarketShare' && specialtyOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortBySpecialtyColumn('specialtyMarketShare', "desc", e)}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 pointer" onClick={(e) => sortBySpecialtyColumn('specialtyTotalRelationships', 'toggle', e)}>
                                                    Total Patient Relationships
                                                    <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                                                        <span
                                                            className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(specialtySortField === 'specialtyTotalRelationships' && specialtyOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortBySpecialtyColumn('specialtyTotalRelationships', "asc", e)}
                                                        ></span>
                                                        <span
                                                            className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(specialtySortField === 'specialtyTotalRelationships' && specialtyOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortBySpecialtyColumn('specialtyTotalRelationships', "desc", e)}
                                                        ></span>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                zipObj.specialties
                                                    .filter(specialtyFilter)
                                                    .filter(specialtyMarketShareThresholdFilter)
                                                    .filter(specialtyTotalRelationshipsThresholdFilter)
                                                    .filter(specialtyHasProviders)
                                                    .map((specialty, index) => {
                                                        return <div key={specialty.specialty} className={`${specialty.open ? 'open-specialty' : ''}`}>
                                                            <div role="tablist" className="panel">
                                                                <div className={`panel-default panel tourscript-taxonomy-header ${specialty.open ? 'open-specialty' : ''}`} onClick={(event) => toggleOpenUpdated(event, zipObj.zipCode, specialty.specialty)/* toggleOpen(event, zipObj.zipCode, index, 'list') */}>
                                                                    <div role="tab" id={`accordiongroup-${zipObj.zipCode}-ul-${index}`} aria-selected="true" className="panel-heading">
                                                                        <h4 className="panel-title">
                                                                            <span>
                                                                                <div className="row">
                                                                                    <div className="col-sm-1"><i className={`glyphicon ${specialty.open ? 'glyphicon-chevron-down' : 'glyphicon-chevron-right'}`}></i></div>
                                                                                    <div className="col-sm-3">{specialty.specialty} </div>
                                                                                    <div className="col-sm-4">{calcSpecialtyMarketShare(specialty).toFixed(2)}%</div>
                                                                                    <div className="col-sm-4">{calcSpecialtyTotalRelationships(specialty).toLocaleString()}</div>
                                                                                </div>
                                                                            </span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <div id={`accordiongroup-${zipObj.zipCode}-${index}-list`} aria-labelledby="" aria-hidden="false" role="tabpanel" className={`panel-collapse collapse panel-body ${specialty.open ? 'in' : ''}`} aria-expanded="true">
                                                                    <div className="row provider-section" style={{ margin: '0px' }} onClick={(e) => e.stopPropagation()}>
                                                                        <div className="col-sm-4 pointer" onClick={(e) => sortByProviderColumn('name', 'toggle', e)}>
                                                                            Name
                                                                            <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                                                                                <span
                                                                                    className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(providerSortField === 'name' && providerOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByProviderColumn('name', "asc", e)}
                                                                                ></span>
                                                                                <span
                                                                                    className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(providerSortField === 'name' && providerOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortByProviderColumn('name', "desc", e)}
                                                                                ></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4 pointer" onClick={(e) => sortByProviderColumn('marketShare', 'toggle', e)}>
                                                                            Market Share
                                                                            <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                                                                                <span
                                                                                    className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(providerSortField === 'marketShare' && providerOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByProviderColumn('marketShare', "asc", e)}
                                                                                ></span>
                                                                                <span
                                                                                    className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(providerSortField === 'marketShare' && providerOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortByProviderColumn('marketShare', "desc", e)}
                                                                                ></span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-4 pointer" onClick={(e) => sortByProviderColumn('totalPatientRelationships', 'toggle', e)}>
                                                                            Total Patient Relationships
                                                                            <div className="sort-container-left" style={{ 'marginLeft': '5px' }}>
                                                                                <span
                                                                                    className={`fa fa-caret-up sort-icon-top sort-color-inactive ${(providerSortField === 'totalPatientRelationships' && providerOrder === "asc") ? "sort-color-active" : null}`} onClick={(e) => sortByProviderColumn('totalPatientRelationships', "asc", e)}
                                                                                ></span>
                                                                                <span
                                                                                    className={`fa fa-caret-down sort-icon-bottom sort-color-inactive ${(providerSortField === 'totalPatientRelationships' && providerOrder === "desc") ? "sort-color-active" : null}`} onClick={(e) => sortByProviderColumn('totalPatientRelationships', "desc", e)}
                                                                                ></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="panel-body">
                                                                        <div className="row">
                                                                            <ul className="list-group">
                                                                                {
                                                                                    specialty.providers
                                                                                        .filter(providerMarketShareThresholdFilter)
                                                                                        .filter(providerTotalRelationshipsThresholdFilter)
                                                                                        .map((provider) => {

                                                                                            const formatPhoneNum = provider && provider.location && provider.location.phone_number && provider.location.phone_number.toString();
                                                                                            const phone = `(${formatPhoneNum.substring(0, 3)}) ${formatPhoneNum.substring(3, 6)}-${formatPhoneNum.substring(6, 10)}`
                                                                                            const _provider = {
                                                                                                location: {
                                                                                                    'postal_code': provider && provider.location && provider.location.postal_code,
                                                                                                    'phone_number': phone,
                                                                                                    'city': provider && provider.location && provider.location.city,
                                                                                                    'state': provider && provider.location && provider.location.state
                                                                                                },
                                                                                                'name': { 'display': provider && provider.name }
                                                                                            }
                                                                                            return <li className="list-group-item tourscript-provider-row" key={provider.npi} style={{ margin: "0px" }}>
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-4" onClick={(e) => e.stopPropagation()}>
                                                                                                        <PopoverDetails provider={_provider}><Link to={`/physician/${provider.npi}/home`}> {provider.npi} </Link></PopoverDetails>
                                                                                                        - <span className="pointer">{provider.name} </span>
                                                                                                    </div>
                                                                                                    <div className="col-sm-4">{provider.marketShare.toFixed(2)}% </div>
                                                                                                    <div className="col-sm-4">{provider.totalPatientRelationships.toLocaleString()}</div>
                                                                                                </div>
                                                                                            </li>
                                                                                        }
                                                                                        )}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                }
            </div>
        </>
    )
}
