import angular from 'angular';

import Auth from '@aws-amplify/auth';
import storage from './storage';

export default angular.module('auth', [
    storage
])
.provider('auth', function() {
    
    var srvc = this;
    
    srvc.cookieName = 'session';
    
    this.$get = ['$http', '$httpParamSerializer', '$q', '$window', 'config', 'api', '$interval', 'storage', '$state', 'notify', function($http, $httpParamSerializer, $q, $window, config, api, $interval, storage, $state, notify) {
        
        var currentUserPromise = $q.defer(); // This promise will never be rejected, usefull for things that must have a login but don't need to know if it failed.
        var loggedInPromise;
        
        function resolve(data) {
            
            var user = {
                isInRole: function(rolesArray) {
                    
                    var rolesArray = rolesArray.map(role => role.split(" ").join(""));//remove spaces
                    var userRoles = data.signInUserSession.idToken.payload.accounts.split(';').map(account => account.split(':')[2]);
                    var hasTeamScope = data.signInUserSession.idToken.payload.scopes.split(';').filter(scopeStr => scopeStr.split(':')[1] === 'team').length > 0;
                    
                    if (data && hasTeamScope && rolesArray) {
                        return rolesArray.some(function(role) {
                            return userRoles.indexOf(role) > -1;
                        });
                    }
                    
                },
                dataModel: function() {
                    return config.models().filter(model => {
                        return model.name === storage.getStorage('ttm');
                    })[0];
                },
                __proto__: data
            };
            
            currentUserPromise.resolve(user);
            loggedInPromise.resolve(user);
            
            return currentUserPromise.promise;
        }
        
        function reject(err) {
            loggedInPromise.reject(err);
            return loggedInPromise.promise;
        }
        
        function updateToken() {
            Auth.currentSession()
                .then(res => {
                    console.log('Auth.currentSession() res', res);
                    var idToken = res.getIdToken().getJwtToken();
                    api.options({ headers : {
                        Authorization: `Bearer ${idToken}`
                    }});
                    return true;
                })
                .catch(err => {
                    console.log('Auth.currentSession() error', err);
                });
        }
        
        $interval(updateToken, 1000 * 60 * 4); // Update every 4 minutes
        updateToken();
        
        return srvc.service = {
            
            loggedIn: function() {
                if (loggedInPromise)
                    return loggedInPromise.promise;
                
                loggedInPromise = $q.defer();
                
                return config.init()
                .then( () => Auth.currentAuthenticatedUser() )
                .then(user => {
                    storage.setStorageUserId(user.attributes.sub);
                    return Auth.currentSession()
                    .then(res => {
                        var idToken = res.getIdToken().getJwtToken();
                        api.options({ headers : {
                            Authorization: `Bearer ${idToken}`
                        }});
                        return storage.init();
                    })
                    .then(() => config.init() )
                    .then(() => resolve(user) );
                
                // }, err => err);      // This hides the Reject!
                }, reject);
            },
            currentUser: function() {
                return currentUserPromise.promise;
            },
            login: function(username, password) {
                loggedInPromise = $q.defer();
                return $http.post('/api/login', {username: username, password: password}, {withCredentials: true})
                .then(function(res) {
                    return resolve(res.data);
                }, reject);
            },
            cognitoLogin: function(username, password) {
                loggedInPromise = $q.defer();
                return Auth.signIn(username, password)
                .then(cognitoUser => {
                    storage.setStorageUserId(cognitoUser.attributes.sub);
                    return Auth.currentSession()
                    .then(res => {
                        var idToken = res.getIdToken().getJwtToken();
                        api.options({ headers : {
                            Authorization: `Bearer ${idToken}`
                        }});
                        return storage.init();
                    })
                    .then(() => config.init() )
                    .then(() => resolve(cognitoUser) );
                
                // }, err => err);      // This hides the Reject!
                }, reject);
            },
            cognitoLogout: function() {
                Auth.signOut()
                .then(res => {
                    notify.alert({
                        title: 'You are logged out.',
                        text: 'You have successfully logged out.',
                        type: 'info'
                    });
                    
                    currentUserPromise = $q.defer();
                    loggedInPromise = undefined;
                    
                    $state.go('cognitologin');
                }, err => {
                    console.log('log out error', err);
                    notify.alert({
                        title: 'Log out error.',
                        text: 'You have unsuccessfully logged out.',
                        type: 'error'
                    });
                });
            },
            cognitoForgotPassword: function(emailAddress){
                return Auth.forgotPassword(emailAddress);
            },
            cognitoResetPassword: function(token, password, email){
                return Auth.forgotPasswordSubmit(email, token, password.new);
            },
            allowedRoute: function(route) {
                return $http({
                    method: 'OPTIONS',
                    url: route,
                    headers: {
                        authorization: api.options().headers.authorization
                    }
                }).then( res => {
                    return res.headers().allow.includes('GET');
                } , err => {
                    console.log('failed options call', err);
                    return false;
                });
            }
        };
    }];
}).name;