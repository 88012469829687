import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3v6';
import * as Plot from "@observablehq/plot";

import { useRouteLoaderData } from "react-router-dom";

import { useApi, useTemplate } from '../../services/servicesContext';

import './codinguplift.scss';

export default function CodingUpliftComponentRevised(props) {
    
    const [loading, setLoading] = useState(true);

    const [estPatientData, setEstPatientData] = useState();
    const [newPatientData, setNewPatientData] = useState();

    const newPatientsChartRef = useRef();
    const estPatientsChartRef = useRef();

    const npis = useRouteLoaderData('physician.npis');
    const api = useApi();

    useEffect(() => {
        async function init() {
            try {
                setLoading(true);
                const provider = npis;
                const dataReq = await fetch(`/api/npi/${ provider.npi }/codinguplift/`, api.options());
                const res = await dataReq.json();

                if (res?.data?.estpatients && res.data.estpatients.length > 0) {
                    setEstPatientData(res.data.estpatients);
                }
                if (res?.data?.newpatients && res.data.newpatients.length > 0) {
                    setNewPatientData(res.data.newpatients);
                }
                
                setLoading(false);
            } catch(err) {
                console.log('Error fetching Coding Distribution data: ', err);
                props.notify.error({
                    title: 'ERROR',
                    text: 'Error fetching Coding Distribution data.',
                    delay: 30000
                });
            }

        }
        init();
    }, [npis]);

    useEffect(() => {
        if (newPatientData === undefined) return;

        const nameSet = new Set(newPatientData.map(item => item.name));//set includes provider name and natl avg
        nameSet.delete('National Average');//remove natl avg
        const providerName = [...nameSet][0];//only provider name should be left

        const newPatientsChart = Plot.plot({
            color: {// domain/range order important for color mapping
                legend: true,
                domain: ["National Average", providerName],
                range: ["#3182bd", "#FFBB78"]
            },
            facet: {
                data: newPatientData,
                x: "code"
            },
            x: {
                domain: ["National Average", providerName]
            },
            y: {
                grid: true
            },
            marks: [
                Plot.barY(
                    newPatientData,
                    {
                        marginLeft: 50,
                        x: "name",
                        y: d => d['percent total visits (%)'] * 100,
                        fill: "name",
                        title: d => `${d.code}\n${d.name}\n${(d['percent total visits (%)'] * 100).toFixed(1)} %`
                    }
                ),
                Plot.axisX({
                    label: null,
                    lineWidth: 6,
                    marginBottom: 40,
                }),
                Plot.axisY({
                    anchor: "left",
                    label: "Percent Total Visits (%)",
                    fontSize: 15,
                    labelAnchor: "center"
                }),
            ]
        });

        newPatientsChartRef.current.append(newPatientsChart);
        
        return () => {
            newPatientsChart.remove();
        };

    }, [newPatientData]);

    useEffect(() => {
        if (estPatientData === undefined) return;

        const nameSet = new Set(estPatientData.map(item => item.name));//set includes provider name and natl avg
        nameSet.delete('National Average');//remove natl avg
        const providerName = [...nameSet][0];//only provider name should be left

        const establishedPatientsChart = Plot.plot({
            color: {// domain/range order important for color mapping
                legend: true,
                domain: ["National Average", providerName],
                range: ["#3182bd", "#FFBB78"]
            },
            facet: {
                data: estPatientData,
                x: "code"
            },
            x: {
                domain: ["National Average", providerName]
            },
            y: {
                grid: true
            },
            marks: [
                Plot.barY(
                    estPatientData,
                    {
                        marginLeft: 50,
                        x: "name",
                        y: d => d['percent total visits (%)'] * 100,
                        fill: "name",
                        title: d => `${d.code}\n${d.name}\n${(d['percent total visits (%)'] * 100).toFixed(1)} %`
                    }
                ),
                Plot.axisX({label: null, lineWidth: 6, marginBottom: 40}),
                Plot.axisY({
                    anchor: "left",
                    label: "Percent Total Visits (%)",
                    labelAnchor: "center",
                    fontSize: 15
                }),
            ]
        });

        estPatientsChartRef.current.append(establishedPatientsChart);

        return () => {
            establishedPatientsChart.remove();
        };

    }, [estPatientData]);


    return (<>
        { !loading
        ? <div className="row physician-codinguplift">
            <div id="newpatients" className="col-lg-6">
                <div className="loading-sm" style={{ 'display' : (loading) ? 'inline' : 'none' }}></div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h5>New Patients - Coding Distribution</h5>
                    </div>
                    <div className="panel-body" style={{padding:0}}>
                    { newPatientData && !loading
                        ? <div style={{padding: '5px'}} ref={newPatientsChartRef}></div>
                        : <div style={{textAlign: 'center', marginTop: '25px'}}>No Data Available</div>
                    }
                    </div>
                </div>
            </div>
            <div id="estpatients" className="col-lg-6">
                <div className="loading-sm" style={{ 'display' : (loading) ? 'inline' : 'none' }}></div>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h5>Established Patients - Coding Distribution</h5>
                    </div>
                    <div className="panel-body" style={{padding:0}}>
                    { estPatientData && !loading
                        ? <div style={{padding: '5px'}} ref={estPatientsChartRef}></div>
                        : <div style={{textAlign: 'center', marginTop: '25px'}}>No Data Available</div>
                    }
                    </div>
                </div>
            </div>
        </div>
        : <div className="loading-lg"></div> }
    </>);

}