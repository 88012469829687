import React, { useState, useEffect } from 'react';
import { useRouteLoaderData } from "react-router-dom";
import {  useTourManager, useLogging } from '../../services/servicesContext';
import { PhtableComponent } from '../../components/phTable/phtableComponent';
import { PopoverDetails }  from '../../components/popoverDetails/popoverDetails';
export function ClinicalComponent({ api, /* npis, */ pic, notify, codes, download }) {
    const [ data, setData] = useState(null);
    const [ loading, setLoading] = useState(null);
    const [ diagData, setDiagData] = useState([]);
    const [ procData, setProcData] = useState([]);
    const [ currentTab, setCurrentTab] = useState("1");
    const tourManager = useTourManager();
    const logging = useLogging();
    const npis = useRouteLoaderData('physician.npis');

    useEffect(() => {

        tourManager.createTour([{
            id: 1,
            title: 'Clinical Report - 1/16',
            text: "This is the clinical report.",
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    document.getElementById('1').click();//select the procedures tab
                    setTimeout(() => {
                        if (document.querySelector('.parent-row.top-row-0').classList.contains('collasped')) {// if the first row is expanded, collapse it
                            document.querySelector('.parent-row.top-row-0 i.fa.open.fa-chevron-down').click();
                        }
                        setTimeout(() => {
                            resolve();
                        });
                    });
                });
            }
        },{
            id: 2,
            title: 'Clinical Report - 2/16',
            text: "This is where you can view the claims data by procedure code grouped by service line for a physician.",
        },{
            id: 3,   
            attachTo: { element: ".tourscript-totalColumn", on: 'bottom' },
            title: 'Clinical Report - 3/16',
            text: "by total procedures...",
        },
        {
            id: 4,
            attachTo: { element: ".tourscript-serviceLineColumn", on: 'bottom' },
            title: 'Clinical Report - 4/16',
            text: `grouped by Service Line`
        },
        {
            id: 5,
            attachTo: { element: ".tourscript-codeColumn", on: 'bottom' },
            title: 'Clinical Report - 5/16',
            text: "and its HCPCS code(s).",
        },
        {
            id: 6,
            attachTo: { element: "#service.header-container.table-header", on: 'bottom' },
            title: 'Clinical Report - 6/16',
            text: "The 'Place of Service' details whether the procedure was performed at a physician office building (O) or facility (F) - like a hospital or SNF.  We use the place of service field listed on the claim to make this designation.",
        },
        {
            id: 7,
            attachTo: { element: '.parent-row.top-row-0 i.fa.fa-chevron-right', on: 'bottom'},
            title: 'Clinical Report - 7/16',
            text: "service line groupings aggregate all the procedure codes totals into a single row, clicking the right-facing arrow in the show column shows the code level data"
        },
        {
            id: 8,
            attachTo: {element: 'tr.nested.nested-row-0', on: 'top' },
            title: 'Clinical Report - 8/16',
            text: 'after clicking the arrow, you can view the totals per code',
            scrollTo: true,
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    document.querySelector('.parent-row.top-row-0 i.fa.fa-chevron-right').click();
                    setTimeout(() => {
                        resolve();
                    });
                });
            }
        },
        {
            id: 9,
            attachTo: { element: ".content-container thead", on: 'bottom' },
            scrollTo: true,
            title: 'Clinical Report - 9/16',
            text: "You can sort on any column by simply clicking on the header name.",
        },
        {
            id: 10,
            attachTo: { element: ".content-container .table-controls-input .filter-box", on: 'bottom' },
            scrollTo: true,
            title: 'Clinical Report - 10/16',
            text: "Also, you can filter results here by searching for any value in the row.",
        },
        {
            id: 11,
            attachTo: { element: ".content-container .table-controls-input #tourscript-buttons", on: 'bottom' },
            scrollTo: true,
            title: 'Clinical Report - 11/16',
            text: "Use this button to download the data in the table.",
        },
        {
            id: 12,
            attachTo: { element: ".diagnoses-tab-link", on: 'bottom'},
            scrollTo: true,
            title: 'Clinical Report - 12/16',
            text: 'Clicking the "Diagnoses" tab reveals clinical data by diagnosis codes'
        },
        {
            id: 13,
            title: 'Clinical Report - 13/16',
            text: 'This is where you can view the claims data by diagnosis code grouped by service line for a physician.',
            beforeShowPromise: function() {
                return new Promise((resolve, reject) => {
                    document.querySelector('.diagnoses-tab-link').click();
                    setTimeout(() => {
                        resolve();
                    });
                });
            }
        },
        {
            id: 14,
            attachTo: { element: '.tourscript-totalclaims', to: 'bottom' },
            title: 'Clinical Report 14/16',
            text: 'by total claims'
        },
        {
            id: 15,
            attachTo: { element: '.tourscript-serviceLineColumn', to: 'bottom' },
            title: 'Clinical Report 15/16',
            text: 'grouped by Service Line'
        },
        {
            id: 16,
            attachTo: { element: '.tourscript-codeColumn', to: 'bottom' },
            title: 'Clinical Report 16/16',
            text: 'and its ICD10CM code(s)'
        }]);

        logging.routeLoad({
            pathname: location.pathname,
            npis: [npis.npi],
            statename: `root.app.phy.reports.clinical`
        });

        function init() {    
            var provider = npis;
            getAndFormatProceduresData(provider.npi);
            getAndFormatDiagnosesData(provider.npi);

        }    
        init();
        return () => {
            tourManager.clearTour();
        };
    }, [npis]);


    const gotoTab = (e) => {
        setCurrentTab(e.target.id)
    }

    function getAndFormatProceduresData(npi) {
        fetch('/api/npi/' + npi + '/clinical/', api.options())
        .then(res => res.json())
        .then(function(res) {
            codes.searchCodesGet(res.data.map(d => d.code))
            .then(resCodes => {
                
                var lookup = resCodes.reduce((acc, val) => {
                    if (!acc.codes[val.clean_code]) {
                        acc.codes[val.clean_code] = val;
                    } 
                    if (val.serviceline && !acc.servicelines[val.serviceline]) {
                        acc.servicelines[val.serviceline] = {};
                        acc.servicelines[val.serviceline][val.clean_code] = val;
                    } else if (val.serviceline && acc.servicelines[val.serviceline] && !acc.servicelines[val.serviceline][val.clean_code]) {
                        acc.servicelines[val.serviceline][val.clean_code] = val;
                    }
                    return acc;
                }, {
                    codes:{},
                    servicelines:{}
                });
                
                var servicelineAgg = res.data.reduce(function(acc, datum) {
                    
                    var ret = {
                        'service': datum.place_of_service,
                        'codesets': lookup.codes?.[datum.code]?.codeset || '',
                        'code': datum.code,
                        'departments': lookup.codes?.[datum.code]?.department || '',
                        'servicelines': datum?.serviceline || '',
                        '_servicelines': datum?.serviceline || '',
                        'anatomies': lookup.codes?.[datum.code]?.anatomy || '',
                        'description': lookup.codes?.[datum.code]?.description,
                        'totalClaims': datum.values.srvc_cnt,
                        'totalVisits': datum.values.line_srvc_cnt,
                        'unique': datum.values.unique_cnt,
                        'sameday': datum.values.bene_day_srvc_cnt,
                        'charged': datum.values.average_submitted_chrg_amt
                    };
                    
                    if (!acc[ret['_servicelines']]) {
                        acc[ret['_servicelines']] = {
                            service: [ret.service],
                            codesets: [ret.codesets],
                            code: [ret.code],
                            departments: [ret.departments],
                            servicelines: ret['_servicelines'],
                            anatomies: [ret.anatomies],
                            description: '',
                            totalClaims: [ret.totalClaims],
                            totalVisits: [ret.totalVisits],
                            unique: [ret.unique],
                            sameday: [ret.sameday],
                            charged: [ret.charged],
                            list: [ret]
                        };
                    } else {
                        acc[ret['_servicelines']].service.push(ret.service);
                        acc[ret['_servicelines']].codesets.push(ret.codesets);
                        acc[ret['_servicelines']].code.push(ret.code);
                        acc[ret['_servicelines']].departments.push(ret.departments);
                        acc[ret['_servicelines']].anatomies.push(ret.anatomies);
                        acc[ret['_servicelines']].totalClaims.push(ret.totalClaims);
                        acc[ret['_servicelines']].totalVisits.push(ret.totalVisits);
                        acc[ret['_servicelines']].unique.push(ret.unique);
                        acc[ret['_servicelines']].sameday.push(ret.sameday);
                        acc[ret['_servicelines']].charged.push(ret.charged);
                        acc[ret['_servicelines']].list.push(ret);
                    }
                    
                    return acc;
                    
                }, {});
                
                const procDataObject = Object.values(servicelineAgg).map(value => {
                    return {
                        service: [...new Set(value.service)].join(', '),
                        codesets: [...new Set(value.codesets)].join(', '),
                        code: [...new Set(value.code)].join(', '),
                        description: '',
                        departments: [...new Set(value.departments)].join(', '),
                        servicelines: value.servicelines || 'n/a',
                        anatomies: [...new Set(value.anatomies)].join(', '),
                        totalClaims: value.totalClaims.reduce((acc, val) => (acc + val), 0),
                        totalVisits: value.totalVisits.reduce((acc, val) => (acc + val), 0),
                        unique: value.unique.reduce((acc, val) => (acc + val), 0),
                        sameday: value.sameday.reduce((acc, val) => (acc + val), 0),
                        charged: (value.charged.reduce((acc, val) => (acc + val), 0) / value.charged.length),
                        list: value.list
                    };
                });
                
                setLoading(false);
                setProcData(procDataObject);
            });
        }, function(err) {
            console.log('Error fetching Provider Diagnoses Clinical Report data: ', err);
            setLoading(true);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Provider Diagnoses Clinical Report data.',
                delay: 30000
            });
        });
    }
    function getAndFormatDiagnosesData(npi) {
        fetch('/api/npi/' + npi + '/clinical_diag/', api.options())
        .then(res => res.json())
        .then(function(res) {
            codes.searchCodesGet(res.data.map(d => d.code))
            .then(resCodes => {
                
                var lookup = resCodes.reduce((acc, val) => {
                    if (!acc.codes[val.clean_code]) {
                        acc.codes[val.clean_code] = val;
                    } 
                    if (val.serviceline && !acc.servicelines[val.serviceline]) {
                        acc.servicelines[val.serviceline] = {};
                        acc.servicelines[val.serviceline][val.clean_code] = val;
                    } else if (val.serviceline && acc.servicelines[val.serviceline] && !acc.servicelines[val.serviceline][val.clean_code]) {
                        acc.servicelines[val.serviceline][val.clean_code] = val;
                    }
                    return acc;
                }, {
                    codes:{},
                    servicelines:{}
                });
                
                var servicelineAgg = res.data.reduce(function(acc, datum) {
                    
                    var ret = {
                        'service': datum.place_of_service,
                        'codesets': lookup.codes?.[datum.code]?.codeset || '',
                        'code': datum.code,
                        'departments': lookup.codes?.[datum.code]?.department || '',
                        'servicelines': datum?.serviceline || '',
                        '_servicelines': datum?.serviceline || '',
                        'anatomies': lookup.codes?.[datum.code]?.anatomy || '',
                        'description': lookup.codes?.[datum.code]?.description,
                        'totalClaims': datum.values.srvc_cnt,
                        'totalVisits': datum.values.line_srvc_cnt,
                        'unique': datum.values.unique_cnt,
                        'sameday': datum.values.bene_day_srvc_cnt,
                        'charged': datum.values.average_submitted_chrg_amt
                    };
                    
                    if (!acc[ret['_servicelines']]) {
                        acc[ret['_servicelines']] = {
                            service: [ret.service],
                            codesets: [ret.codesets],
                            code: [ret.code],
                            departments: [ret.departments],
                            servicelines: ret['_servicelines'],
                            anatomies: [ret.anatomies],
                            description: '',
                            totalClaims: [ret.totalClaims],
                            totalVisits: [ret.totalVisits],
                            unique: [ret.unique],
                            sameday: [ret.sameday],
                            charged: [ret.charged],
                            list: [ret]
                        };
                    } else {
                        acc[ret['_servicelines']].service.push(ret.service);
                        acc[ret['_servicelines']].codesets.push(ret.codesets);
                        acc[ret['_servicelines']].code.push(ret.code);
                        acc[ret['_servicelines']].departments.push(ret.departments);
                        acc[ret['_servicelines']].anatomies.push(ret.anatomies);
                        acc[ret['_servicelines']].totalClaims.push(ret.totalClaims);
                        acc[ret['_servicelines']].totalVisits.push(ret.totalVisits);
                        acc[ret['_servicelines']].unique.push(ret.unique);
                        acc[ret['_servicelines']].sameday.push(ret.sameday);
                        acc[ret['_servicelines']].charged.push(ret.charged);
                        acc[ret['_servicelines']].list.push(ret);
                    }
                    
                    return acc;
                    
                }, {});
                
                const diagDataObject = Object.values(servicelineAgg).map(value => {
                    return {
                        service: [...new Set(value.service)].join(', '),
                        codesets: [...new Set(value.codesets)].join(', '),
                        code: [...new Set(value.code)].join(', '),
                        description: '',
                        departments: [...new Set(value.departments)].join(', '),
                        servicelines: value.servicelines || 'n/a',
                        anatomies: [...new Set(value.anatomies)].join(', '),
                        totalClaims: value.totalClaims.reduce((acc, val) => (acc + val), 0),
                        totalVisits: value.totalVisits.reduce((acc, val) => (acc + val), 0),
                        unique: value.unique.reduce((acc, val) => (acc + val), 0),
                        sameday: value.sameday.reduce((acc, val) => (acc + val), 0),
                        charged: (value.charged.reduce((acc, val) => (acc + val), 0) / value.charged.length),
                        list: value.list
                    };
                });
                
                setLoading(false);
                setDiagData(diagDataObject);
            });
        }, function(err) {
            console.log('Error fetching Provider Diagnoses Clinical Report data: ', err);
            setLoading(true);
            notify.error({
                title: 'ERROR',
                text: 'Error fetching Provider Diagnoses Clinical Report data.',
                delay: 30000
            });
        });
        
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }

    const reportConfigProcedures = function() { 
        return {  
          tableConfig :  [
                  {
                    "header": {
                        "id": "col11",
                        "accessor": "anatomies",
                        "content": "Anatomy(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "departments",
                        "content": "Department(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col13",
                        "accessor": "codesets",
                        "content": "Codeset(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                  "header": {
                      "id": "col1",
                      "accessor": "show",
                      "content": "Show",
                      "defaultVisibilty": true,
                      "type" : "collapse-button",
                      "collasped" : true,
                      "classLists" : "fa fa-chevron-right",
                      "sorting": false,
                      "exclude": true,
                      "template": function() { return }   
                  },
                  "column": {
                      "class": "'text-center'",
                      "content": "link",
                      "style": "",
                      "id": 1                    
                  }
              },
              {
                    "header": {
                        "id": "col2",
                        "accessor": "servicelines",
                        "content": "Service Line",
                        "defaultVisibilty": true,
                        "forcedColumn": 1,
                        "tooltip" : true,
                        "type": "Array",
                        "export": true,
                        "template": content => <span className='tourscript-serviceLineColumn'>{content}</span>   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 2                    
                    }
                },
                {
                  "header": {
                      "id": "col3",
                      "accessor": "service",
                      "content": "Place of Service",
                      "defaultVisibilty": true,
                      "export": true,
                     "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 3
                    }
                },
             
                {
                    "header": {
                        "id": "col4",
                        "accessor": "code",
                        "content": "HCPCS Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-codeColumn'>{content}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 4
                   }
                },
                {
                  "header": {
                      "id": "col5",
                      "accessor": "description",
                      "content": "HCPCS Description",
                      "defaultVisibilty": true,
                      "export": true,
                      "template":  function(content) { 
                        var val =  <PopoverDetails providerTooltip={ content || '' }>{(content && content.length > 1) ? `${content.substring(0, 20)} ...`  : ''}</PopoverDetails>
                        return val;
                      }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 5
                 }
              },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "totalVisits",
                        "content": "Total Procedures",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-totalColumn'>{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 6
                        }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "unique",
                        "content": "Unique Patients",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
              {
                "header": {
                    "id": "col9",
                    "accessor": "charged",
                    "content": "Average Submitted Charge",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return currencyFormat(content) }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 9
                    }
            }
              
               ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        pagination: true,
        nested: "list",
        nestedSearch: true,// when filtering the table, results should filter child rows and if parent row ends up with no children then filter the parent out too, exports should output children only
        reportTitle: `Clinical_Procedures_Report_for_${npis && npis.name && npis.name.display} (${npis.npi})`,
        localStorageName: 'phys.reports.clinical.procedure.v-3'
        }
    }


    const reportConfigDiagnosis = function() { 
        return {  
          tableConfig :  [
                {
                    "header": {
                        "id": "col13",
                        "accessor": "codesets",
                        "content": "Codeset(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 13
                        }
                },
                {
                    "header": {
                        "id": "col12",
                        "accessor": "departments",
                        "content": "Department(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 12
                        }
                },
                {
                    "header": {
                        "id": "col11",
                        "accessor": "anatomies",
                        "content": "Anatomy(s)",
                        "defaultVisibilty": false,
                        "export": false,
                        "template": function(content) { return content }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 11
                        }
                },
                {
                  "header": {
                      "id": "col1",
                      "accessor": "show",
                      "content": "Show",
                      "defaultVisibilty": true,
                      "type" : "collapse-button",
                      "collasped" : true,
                      "classLists" : "fa fa-chevron-right",
                      "sorting": false,
                      "exclude": true,
                      "template": function() { return  }   
                  },
                  "column": {
                      "class": "'text-center'",
                      "content": "link",
                      "style": "",
                      "id": 1                    
                  }
              },
              {
                    "header": {
                        "id": "col2",
                        "accessor": "servicelines",
                        "content": "Service Line(s)",
                        "defaultVisibilty": true,
                        "forcedColumn": 1,
                        "tooltip" : true,
                        "type": "Array",
                        "export": true,
                        "template": content => <span className='tourscript-serviceLineColumn'>{content}</span>   
                    },
                    "column": {
                        "class": "'text-center'",
                        "content": "link",
                        "style": "",
                        "id": 2                    
                    }
                },
         
                {
                  "header": {
                      "id": "col3",
                      "accessor": "service",
                      "content": "Place of Service",
                     "defaultVisibilty": true,
                      "export": true,
                     "template": function(content) { return content }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 3
                 }
              },
              
                {
                    "header": {
                        "id": "col4",
                        "accessor": "code",
                        "content": "ICD10CM Code",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className='tourscript-codeColumn'>{content}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                           "content": {},
                            "style": "",
                            "id": 4
                   }
                },
                {
                  "header": {
                      "id": "col5",
                      "accessor": "description",
                      "content": "ICD10CM Description",
                      "defaultVisibilty": true,
                      "export": true,
                      "template":  function(content) { 
                        var val =  <PopoverDetails providerTooltip={ content || '' }>{(content && content.length > 1) ? `${content.substring(0, 20)} ...`  : ''}</PopoverDetails>
                        return val;
                      }
                  },
                  "column": {
                          "class": "'text-center'",
                         "content": {},
                          "style": "",
                          "id": 5
                 }
              },
                {
                    "header": {
                        "id": "col6",
                        "accessor": "totalClaims",
                        "content": "Total Claims",
                        "sort": "desc",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": content => <span className="tourscript-totalclaims">{content.toLocaleString()}</span>
                    },
                    "column": {
                            "class": "'text-center'",
                        "content": {},
                            "style": "",
                            "id": 6
                }
                },
                {
                    "header": {
                        "id": "col7",
                        "accessor": "totalVisits",
                        "content": "Total Visits",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 7
                        }
                },
                {
                    "header": {
                        "id": "col8",
                        "accessor": "unique",
                        "content": "Unique Patients",
                        "defaultVisibilty": true,
                        "export": true,
                        "template": function(content) { return content.toLocaleString() }
                    },
                    "column": {
                            "class": "'text-center'",
                            "content": {},
                            "style": "",
                            "id": 8
                        }
                },
              {
                "header": {
                    "id": "col10",
                    "accessor": "charged",
                    "content": "Average Submitted Charge",
                    "defaultVisibilty": true,
                    "export": true,
                    "template": function(content) { return currencyFormat(content) }
                },
                "column": {
                        "class": "'text-center'",
                        "content": {},
                        "style": "",
                        "id": 10
                    }
            }
        ],
        configure : true,
        configureButton: true,
        showSelected: false,
        filterInput: true,
        csvdownload: true,
        nested: "list",
        nestedSearch: true,
        pagination: true,
        reportTitle: `Clinical_Diagnoses_Report_for_${npis && npis.name && npis.name.display} (${npis.npi})`,
        localStorageName: 'phys.reports.clinical.diagnosis.v-2'
        }
    }

    const renderTabs = () => {
       if (currentTab === "1") return procData ? <PhtableComponent data={procData} reportconfig={reportConfigProcedures} download={download} /> : "No data found"
       if (currentTab === "2") return diagData ? <PhtableComponent data={diagData} reportconfig={reportConfigDiagnosis} download={download} /> : "No data found"   
    }

  return (
   <>
   <ul className="nav nav-tabs">
      <li className={currentTab === "1" ? "active" : ""}><a id="1" className="procedures-tab-link" onClick={(e) => gotoTab(e)}>Procedures</a></li>
      <li className={currentTab === "2" ? "active" : ""}><a id="2" className="diagnoses-tab-link" onClick={(e) => gotoTab(e)}>Diagnoses</a></li>
   </ul>
    <div className="loading-lg" style={{ "display" : (loading ? 'inline' : 'none' )}}></div>
      {renderTabs()}
    </>
  )
}
