import angular from 'angular';
import $ from 'jquery';
import * as d3 from 'd3v3';
import * as crossfilter from 'crossfilter2';
import * as dc from 'dc';

export default angular.module('numberDisplay',[])
.directive('numberDisplay', ['$parse', function ($parse) {
    return {
        require: "^^crossfilter",
        scope: {
            context: '<?'
        },
        link: function (scope, element, attrs, crossfilter) {
            scope.d3 = d3;
            var graph = scope.graph = dc.numberDisplay(element[0]);

            scope.$on('redraw', function () {
                graph.redraw();
            });

            crossfilter.crossfilter().then(function (filter) {

                filter.onChange(function (type) {
                    if (type == 'dataAdded') graph.redraw();
                });

                if (attrs.reduceSum) {
                    var parse = $parse(attrs.reduceSum);

                    scope.group = filter.groupAll().reduce(
                        function (p, v) {
                            p.tot += parse({ row: v, Math: Math });
                            return p;
                        }, function (p, v) {
                            p.tot -= parse({ row: v,  Math: Math });
                            return p;
                        }, function () { return { tot: 0 }; }
                    );

                    graph.valueAccessor(function (d) { return d.tot });
                    graph.group(scope.group);
                } else if (attrs.reduceAverage) { 
                    var parse = $parse(attrs.reduceAverage);

                    scope.group = filter.groupAll().reduce(
                        function (p, v) {
                            p.tot += parse({ row: v, Math: Math });
                            p.cnt += 1;
                            return p;
                        }, function (p, v) {
                            p.tot -= parse({ row: v,  Math: Math });
                            p.cnt -= 1;
                            return p;
                        }, function () { return { tot: 0, cnt: 0 }; }
                    );

                    graph.valueAccessor(function (d) { return d.tot / d.cnt });
                    graph.group(scope.group);
                } else if (attrs.unique) {
                    var parse = $parse(attrs.unique);

                    scope.group = filter.groupAll().reduce(
                        function (p, v) {
                            var key = parse({ row: v });
                            p[key] = (p[key] || 0) + 1;
                            return p;
                        },
                        function (p, v) {
                            var key = parse({ row: v });
                            p[key] = (p[key] || 0) - 1;

                            return p;
                        },
                        function () { return {}; }
                    );

                    graph.valueAccessor(function (d) {
                        return Object.keys(d).reduce(function (l, key) {
                            if (d[key] > 0) l.push(d);
                            return l;
                        }, []).length;
                    });
                    graph.group(scope.group);
                } else {
                    graph.valueAccessor(function (d) { return d; });
                    graph.group(scope.group = filter.groupAll());
                }

                if (attrs.valueAccessor) {
                    var context = scope.$new();
                    context.Math = Math;
                    var valueParse = $parse(attrs.valueAccessor);

                    graph.valueAccessor(function (d) {
                        context.row = d;
                        return valueParse(context, scope.context);
                    });
                }

                if (attrs.opts) {
                    var opts = $parse(attrs.opts)(scope);
                    Object.keys(opts).forEach(function (key) {
                        try {
                            graph[key](opts[key]);
                        } catch (e) {
                            var path = $(element.target).parents().addBack().map(function () {
                                var siblings = $(this).siblings(this.tagName);
                                return this.tagName + (siblings.length ? '[' + $(this).prevAll(this.tagName).length + ']' : '');
                            }).get().join('/');
                        }
                    });
                }

                graph.render();
            });
        }
    };
}]).name;