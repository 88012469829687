import angular from 'angular';
import config from './config';

export default angular.module('template', [config])
.provider('template', function() {
    
    this.title = {
        prefix: '',
        value: '',
        suffix: '',
        delimiter: ''
    };
    
    this.header = {
        title: '',
        url: ''
    };
    
    this.$get = ['$rootScope', 'config', '$window', function($rootScope, config, $window) {
        var ready = false;
        
        config.init().then(function() {
            ready = true;
        });
    
        var srvc = this;
    
        return {
            ready: function() { return ready; },
            title: function(value) {
                if ( typeof value === 'undefined' ) { // getter
                    var title = '';
                    if ( srvc.title.prefix && srvc.title.value) {
                        title += srvc.title.prefix + srvc.title.delimiter;
                    }
                    title += srvc.title.value;
                    if ( srvc.title.suffix && srvc.title.value) {
                        title += srvc.title.delimiter + srvc.title.suffix;
                    }
                    return title;
                } else { // setter
                    srvc.title.value = value;
                }
            },
            meta: function(name, value) {
                
            },
            header: function(value) {
                if (typeof value === 'undefined') {
                    var header = {};
                    header = srvc.header;
                    return header;
                } else {
                    srvc.header = value;
                    return srvc.header;
                }
            },
            nonReportingNpi: function(provider) {
                $rootScope.nonReportingProvider = provider;
            },
            toggleNavSidebar: function() {
                
                $rootScope.toggleNavSidebar = !$rootScope.toggleNavSidebar;
                window.dispatchEvent(new Event('resize'));
            },
            contentResize: function(callback) {
                var toggleDeregister = $rootScope.$watch('toggleNavSidebar', callback);
                angular.element($window).on('resize', callback);
                
                return function() {
                    toggleDeregister();
                    angular.element($window).off('resize', callback);
                };
            },
            openNavSidebar: function() {
                if ($rootScope.toggleNavSidebar) {
                    $rootScope.toggleNavSidebar = false;
                }
            }
        };
        
    }];
}).name;