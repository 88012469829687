// import jQuery from 'jquery';
import * as d3 from 'd3';

export function datumlist(selection) {

    let globalContainer = {};
    let legend;

    function makeLegend(options) {
        // var legend;
        legend;
        // d3.select(this.get(0)).select('ul').remove();
        d3.select(selection).select('ul').remove();
        
        // $(this).data('legend', legend = d3.select(this.get(0)).style('padding-top', options.dimensions.paddingTop + 'px').append('ul'));
        legend = d3
            .select(selection)
            .style('padding-top', options.dimensions.paddingTop + 'px')
            .append('ul');
        
        bindData.call(legend, options);
    }

    function bindData(options) {
        var selection = this.selectAll('.legend-item')
            .data(options.data, options.id);
            
        selection.exit().remove();
        
        var list = selection
            .enter()
                .append('li')
                    .attr('class', 'legend-item');
                    
        list
            .append('div')
                .attr('class', 'legend-color')
                .style('background-color', function(d){
                    return options.color.scale(options.color.value(d));
                });
                
        list
            .append('a')
                .attr('class', 'legend-text')
                .text(options.label)
                .on('mouseenter', function(datum, index){
                    if (options.events.mouseenter)
                        options.events.mouseenter(d3.event, datum);
                })
                .on('mouseleave', function(datum, index){
                    if (options.events.mouseleave)
                        options.events.mouseleave(d3.event, datum);
                })
                .on('click', function(datum, index){
                    if (options.events.click)
                        options.events.click(d3.event, datum);
                });
            
        list.sort(function(a, b){
            return options.sort.order(options.sort.value(a), options.sort.value(b));
        });
    }

    let datumlist = function(action, value) {
        
        var options = globalContainer.options;
        if (!options) {
            options = globalContainer.options = { /* defaults */
                dimensions: {},
                data: [],
                events: {},
                sort: {
                    value: function(d) {
                        return d.sort;    
                    },
                    helper: true
                },
                color: {
                    value: function(d) {
                        return d.color;    
                    },
                    helper: true
                },
                id: function(d, i) {
                    return i;
                },
                label: function(d) {
                    return d.label;    
                }
            };

        }

        if (typeof action === 'object' && !value) {
            Object.keys(action).forEach(function(opt) {
                if (typeof action[opt] === 'object')
                    // $.extend(options[opt], action[opt]);
                    Object.assign(options[opt], action[opt]);
                else
                    options[opt] = action[opt];
            });
            
            // makeLegend.call(this, options);
            makeLegend(options);
        }

        if (action === 'options' && typeof value == 'object') {
            Object.keys(value).forEach(function(opt) {
                if (typeof value[opt] === 'object')
                    // $.extend(options[opt], value[opt]);
                    Object.assign(options[opt], value[opt]);
                else
                    options[opt] = value[opt];
            });
            
            // makeLegend.call(this, options);
            makeLegend(options);
        }

        if (action === 'data' && Array.isArray(value)) {
            options.data = value;
            
            bindData.call(
                legend,
                options
            );
        }

    }; 

    return datumlist;

}