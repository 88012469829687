import angular from 'angular';

import api from './api';
import auth from './auth';

export default angular.module('pic', [
    api, auth
])
.provider('pic', function() {
    var communities = {};
    
    var memberships = {};
    
    this.$get = ['api', 'auth', '$interval', function(api, auth, $interval) {
        
        var update = function update() {
            memberships = {};
            communities = {};
            
            return auth.currentUser()
            .then(function() {
                
                return api.Groups();
                
            }).then(function(groups) {
                
                groups.forEach(function(group) {
                    communities[group.id] = group;
                    
                    group.npis.forEach(function(npi) {
                        if (!memberships[npi]) {
                            memberships[npi] = [ group.id ];
                        } else {
                            memberships[npi].push(group.id);
                        }
                    });
                });
                
            }).catch( function(err) {
                console.log('Pic Service Error:', err);
            });
        };
    
        $interval(update, 1000 * 60 * 5); // Update every 5 minutes
        update();
        
        var ret = function(provider, getAll) {
            
            //no arguments mean get all USERS communities, provider argument means that get provider's watched communities, provider and getAll arguments mean get all provider's communities
            if (!provider && !getAll) {
                var communitiesArr = [];
                
                Object.keys(communities).forEach(function(key) {
                    communitiesArr.push(communities[key]);
                });

                return communitiesArr;
            } 
            
            var index = provider + '';
            if (provider instanceof api.Provider) {
                index = provider.npi;
            }
            var membership = memberships[index];
            
            //provider argument means that get provider's watched communities
            if (!membership) {
                return [];
            } else {
                return  membership.map(function(cid) {
                    return communities[cid];
                }).filter(function(community) { 
                    return getAll || community.watched;
                });
            }

        };
        
        ret.Update = update;
        
        return ret;
    }];
}).name;