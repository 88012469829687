export default function ($timeout){
    return {
        config: {},
        steps: [
            {
                content: "The purpose of the Organization Specialty Flow graph is to the flow of patients from primary care providers (left nodes), to specialists (middle nodes), to a selected Organization type (right nodes)."
            },
            {
                target: 'g.nodes g.node rect[data-side="left"]',
                content: "Primary Care Providers are displayed in the leftmost node (primary care).",
                before: function(){
                    return new Promise((resolve, reject) => {
                        var elementToHide = document.querySelector('g.legend');
                        elementToHide.style.display = 'none';
                        $timeout(()=>{
                            resolve();
                        });
                    });
                },
                after: function(){
                    return new Promise((resolve, reject) => {
                        var elementToReveal = document.querySelector('g.legend');
                        elementToReveal.style.display = 'inline';
                        $timeout(()=>{
                            resolve();
                        });
                    });
                }
            },
            {
                target: 'g.nodes g.node rect[data-side="middle"]',
                content: "Specialists are displayed in the middle node (specialists)."
            },
            {
                target: 'provider-specialtyflow div.form-inline:nth-child(1) button',
                content: "When you click on the button for the Left, Middle, or Right Nodes..."
            },
            {
                target: 'provider-specialtyflow div.form-inline:nth-child(1) ul.dropdown-menu',
                content: "...you see a drop down that lets you select the types of physicians that populate the middle, or left nodes, respectively.",
                before: function(){
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('provider-specialtyflow div.form-inline:nth-child(1) button');
                        var clickEvent = new Event('click');
                        $timeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            $timeout(()=>{
                                resolve();
                            });
                        });
                    });
                },
                after: function(){
                    return new Promise((resolve, reject) => {
                        var elementToClick = document.querySelector('provider-specialtyflow div.form-inline:nth-child(1) button');
                        var clickEvent = new Event('click');
                        $timeout(()=>{
                            elementToClick.dispatchEvent(clickEvent);
                            $timeout(()=>{
                                resolve();
                            });
                        });
                    });
                }
            },
            {
                target: 'g.nodes g.node rect[data-side="right"]',
                content: "The rightmost nodes represent the facilities the specialists send their patients to. The size of the gray areas represents patient volume. The larger they are, the more volume there is."
            },
            {
                target: 'g.legend rect',
                content: "There is also a collapsible legend that provides reference for how the nodes are color-coded by Provider type."
            }
        ]
    };
}
 