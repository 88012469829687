import React, { useState } from "react";
import { usePopper }  from 'react-popper';
import api from '../../services/api';

import './popoverTTM.scss';

export function PopoverTTM(props) {

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [showPopperElem, setShowPopperElem] = useState(false);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right',
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    });

    const popperElemStyles = {
        backgroundColor: 'white',
        boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
        borderRadius: '5px',
        minWidth: '200px',
        maxWidth: '500px',
        margin: '5px',
        paddingLeft: '2px',
    };

    return (<>
        <span
            ref={setReferenceElement}
            onMouseEnter={() => setShowPopperElem(true)}
            onMouseLeave={() => setShowPopperElem(false)}
        >{ props.children }</span>
        { showPopperElem ? <div ref={setPopperElement} id="tooltip" className="data-model-details-popover" style={{...styles.popper, ...popperElemStyles}} {...attributes.popper}>
            <div style={{margin: '5px'}}>
                <span>Relations</span>
                    <ul>
                        { props.details.relations.map((relation, index) => {
                            return <li key={props.name + "_relation_" + index}>
                                    {relation}
                            </li>;
                        })}
                    </ul>
                <span>Clinical</span>
                    <ul>
                        { props.details.clinical.map((clinical, index) => {
                            return <li key={props.name + "_clinical_" + index}>
                                    {clinical}
                            </li>;
                        })}
                    </ul>
                {props.details.comment.split("<br/>").map((comment, index) => {
                     return <div key={props.name + "_comment_" + index}>{comment}</div>
                })}
            </div>
            <div id="arrow" ref={setArrowElement} data-popper-arrow style={{...styles.arrow}} {...attributes.arrow}/>
        </div> : null}
    </>);
}