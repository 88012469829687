import angular from 'angular';

export default ['$scope', 'api', function($scope, api) {
    var $ctrl = this;
    
    $ctrl.$onInit = function() {
        $scope.params = {
          NPI: $ctrl.npis[0].npi,
          CBSA: $ctrl.npis[0].cbsa.point,
          Taxonomy: `${$ctrl.npis[0].taxonomy.classification}${$ctrl.npis[0].taxonomy.specialization ? ' '+$ctrl.npis[0].taxonomy.specialization : ''}`
        };
    };
}];